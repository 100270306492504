import { useState } from "react";
import NotificationToggle from "./notificationtoggle"; // Adjust the path as necessary
import axios from "axios";
import { toast } from "react-toastify";

const Notifications = ({ user }) => {
  const [settings, setSettings] = useState(
    user?.notifications ?? {
      comment: {
        push: false,
        messenger: false,
        email: false,
      },
      challenge: {
        push: false,
        messenger: false,
        email: false,
      },
      event: {
        push: false,
        messenger: false,
        email: false,
      },
      messenger: {
        push: false,
        messenger: false,
        email: false,
      },
    }
  );

  const handleSubmit = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/student/settings/notifications`,
        settings,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.msg === "success") {
          toast.success("Notifications Updated");
          window.location.reload();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderNotificationSection = (category, title, description) => (
    <div
      className="space flex-wrap"
      style={{ gap: "1rem", marginBottom: "1.5rem" }}
    >
      <div style={{ maxWidth: "280px" }}>
        <p className="black14">{title}</p>
        <p className="gray14new" style={{ marginTop: "0.25rem" }}>
          {description}
        </p>
      </div>
      <div>
        <NotificationToggle
          type="push"
          label="Push"
          settings={settings}
          setSettings={setSettings}
          category={category}
        />
        <div style={{ marginTop: "1rem" }}>
          <NotificationToggle
            type="messenger"
            label="Messenger"
            settings={settings}
            setSettings={setSettings}
            category={category}
          />
        </div>
        <div style={{ marginTop: "1rem" }}>
          <NotificationToggle
            type="email"
            label="Email"
            settings={settings}
            setSettings={setSettings}
            category={category}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div style={{ maxWidth: "560px" }}>
        <p className="black14black">Notification Settings</p>
        <p className="gray14new" style={{ marginTop: "0.25rem" }}>
          We may still send you important notifications about your account
          outside of your notification settings.
        </p>
        <hr
          style={{
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        {renderNotificationSection(
          "comment",
          "Comments",
          "These are notifications for comments on your posts and replies to your comments."
        )}
        <hr
          style={{
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        {renderNotificationSection(
          "challenge",
          "Challenges",
          "These are notifications for newly published challenges which might interest you."
        )}{" "}
        <hr
          style={{
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        {renderNotificationSection(
          "event",
          "Events",
          "These are notifications to remind you of updates you might have missed."
        )}
        <hr
          style={{
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        {renderNotificationSection(
          "messenger",
          "Messenger",
          "These are notifications if students or recruiter contact you."
        )}
        <hr
          style={{
            marginTop: "2.5rem",
            marginBottom: "1.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        <div className="d-flex justify-content-end">
          <button className="continuebutton" onClick={() => handleSubmit()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
