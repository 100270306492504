import React from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import SelectUni from "./SelectUni";
import AddUni from "./AddUni";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Password from "./password";
import Success from "./Success";
const Steps = ({
  step,
  setStep,
  selectedItem,
  setSelectedItem,
  selectedUni,
  setSelectedUni,
  unilist,
  setUniList,
  loading,
  setLoading,
  Countries,
  country,
  setCountry,
  CustomUni,
  setCustomUni,
  firstName,
  setFirstName,
  LastName,
  setLastName,
  EmailAddress,
  setEmailAddress,
  optform,
  setOtpForm,
  generateOtp,
  password,
  setPassword,
  agree,
  setAgree,
}) => {
  return (
    <div className="stepsHeightStudentReg " style={{ marginTop: "1rem" }}>
      {step === 1 && (
        <SelectUni
          country={country}
          setCountry={setCountry}
          Countries={Countries}
          loading={loading}
          setLoading={setLoading}
          selectedUni={selectedUni}
          setSelectedUni={setSelectedUni}
          unilist={unilist}
          setUniList={setUniList}
          setStep={setStep}
        />
      )}
      {step === 2 && (
        <AddUni
          CustomUni={CustomUni}
          setCustomUni={setCustomUni}
          setStep={setStep}
        />
      )}
      {step === 3 && (
        <Step1 selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
      )}
      {step === 4 && (
        <Step2 firstName={firstName} setFirstName={setFirstName} />
      )}
      {step === 5 && <Step3 LastName={LastName} setLastName={setLastName} />}
      {step === 6 && (
        <Step4 EmailAddress={EmailAddress} setEmailAddress={setEmailAddress} />
      )}
      {step === 7 && <Password password={password} setPassword={setPassword} />}
      {step === 8 && (
        <Step5
          EmailAddress={EmailAddress}
          optform={optform}
          setOtpForm={setOtpForm}
          generateOtp={generateOtp}
          agree={agree}
          setAgree={setAgree}
        />
      )}
      {step === 9 && <Step6 />}
      {step === 11 && <Success />}
    </div>
  );
};

export default Steps;
