import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import pic1 from "../../assets/images/logosCOmpany/monzo.png";
import pic2 from "../../assets/images/logosCOmpany/zopa.png";
import pic3 from "../../assets/images/logosCOmpany/question.png";
import pic4 from "../../assets/images/logosCOmpany/nory.png";
import pic5 from "../../assets/images/logosCOmpany/tff.png";
const First = () => {
  return (
    <div className="university-first mt-0">
      <div
        className="marginsleftrightCompany overflowshown"
        style={{ position: "relative" }}
      >
        {" "}
        <div className="center">
          <div className="headerCompany " style={{ overflow: "visible" }}>
            <div
              className="center align-items-center"
              style={{
                minHeight: "calc(100vh - 65px)",
                overflow: "hidden",
                position: "relative",
                marginTop: "-100px",
              }}
            >
              <div className="circle-background-large">
                <div class="circle-container"></div>
                <div className="">
                  <div className="center">
                    <div className="hellocareer">
                      <AutoAwesomeIcon
                        style={{
                          height: "20px",
                          width: "20px",
                          color: "#FFD700",
                          transform: "rotate(180deg)",
                        }}
                      />
                      Innovation Track
                    </div>
                  </div>
                  <div className="center">
                    <p
                      className="mainheadingcompany text-center"
                      style={{ maxWidth: "860px", marginTop: "1.875rem" }}
                    >
                      Gain skills that shape tomorrow's world.
                    </p>
                  </div>
                  <div className="center">
                    <p
                      className="text-center gray95020400"
                      style={{
                        maxWidth: "723px",
                        marginTop: "1.5rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      Build your future in fast-growing, dynamic environments
                      with opportunities in start-ups, venture capital, ESG and
                      disruptive leaders.
                    </p>
                  </div>
                  <div className="center">
                    <div style={{ marginTop: "2rem" }}>
                      <div
                        className="center align-items-center flex-wrap"
                        style={{ gap: "0.75rem" }}
                      >
                        <div className="d-flex maxWidthClampedCompanysmalltext">
                          <img
                            src={pic1}
                            alt="company"
                            className="Companypagelogoimages shadowa rounded-5"
                            style={{
                              position: "relative",
                              zIndex: 5,
                              height: "48px",
                              width: "48px",
                            }}
                          />
                          <img
                            src={pic2}
                            alt="company"
                            className="Companypagelogoimages moveLeftCompany shadowa rounded-5"
                            style={{
                              position: "relative",
                              zIndex: 4,
                              left: "-20px",
                              height: "48px",
                              width: "48px",
                            }}
                          />
                          <img
                            src={pic3}
                            alt="company"
                            className="Companypagelogoimages shadowa rounded-5"
                            style={{
                              position: "relative",
                              zIndex: 3,
                              left: "-40px",
                              height: "48px",
                              width: "48px",
                            }}
                          />
                          <img
                            src={pic4}
                            alt="company"
                            className="Companypagelogoimages shadowa rounded-5 moveLeftCompany"
                            style={{
                              position: "relative",
                              zIndex: 2,
                              left: "-55px",
                              height: "48px",
                              width: "48px",
                            }}
                          />
                          <img
                            src={pic5}
                            alt="company"
                            className="Companypagelogoimages shadowa rounded-5 moveLeftCompany"
                            style={{
                              position: "relative",
                              zIndex: 1,
                              left: "-70px",
                              height: "48px",
                              width: "48px",
                            }}
                          />
                        </div>
                        <p className="pink16 text-center">
                          Join 200+ Start-Ups, ESG & Innovation Leaders
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default First;
