import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import sandclock from "../../../assets/images/sandclock.svg";
import axios from "axios";

import EmptyState from "../../../assets/images/EmptyState.svg";
const Step7 = ({
  setSteps,
  user,
  company,
  emailSetting,
  setEmailSetting,
  defaults,
  setDefault,
  isNonMobile1024,
  addedUsers,
  setAddedUsers,
  handleSubmit,
  position,
  title,
}) => {
  const MAX_LENGTH = 80;

  const truncatedTitle =
    title.length > MAX_LENGTH ? `${title.substring(0, MAX_LENGTH)}...` : title;

  const handleSetRequiredDocuments = (name) => {
    setEmailSetting((prevDocuments) => {
      const updated = prevDocuments.map((document) => {
        if (document.name === name) {
          return { ...document, value: !document.value };
        }
        return document;
      });
      return updated;
    });
  };
  const [add, setAdd] = useState(false);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (company && company.uniqueId) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/get/company/users`, {
          uniqueId: company.uniqueId,
          userId: user.uniqueId,
        })
        .then((res) => {
          if (res.data.msg === "success") {
            setUsers(res.data.filtered);
          }
        });
    }
  }, []);

  const [list, setList] = useState([...users]);

  const handleAddUsers = (newId) => {
    setAddedUsers((prev) => {
      const check = [...prev];
      if (check.some((id) => id === newId)) {
        return check.filter((id) => id !== newId);
      } else {
        return [...check, newId];
      }
    });
  };

  useEffect(() => {
    setList(
      users.filter((user) => {
        return `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(search.toLowerCase());
      })
    );
  }, [search, users]);

  const handleSearch = () => {};
  return (
    <div className="d-flex">
      <div className="" style={{ width: "100%" }}>
        <p className="BasicInforMationHeading">Your Hiring Team</p>
        <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
          Get your hiring team organized so that everyone stays in the loop and
          manage how they get updates.
        </p>
        <div
          className="shadowa"
          style={{
            marginTop: "2.5rem",
            minHeight: "26.563rem",
            background: "#FFF",
            padding: "1.5rem",
            borderRadius: "5px",
          }}
        >
          <p className="black16 text-start" style={{ fontWeight: "600" }}>
            Job owner
          </p>
          {user && user.profilephoto && (
            <div
              className="d-flex flex-wrap"
              style={{ marginTop: "1.5rem", gap: "1.5rem" }}
            >
              <img
                src={user.profilephoto}
                className="shadowa"
                alt="profile"
                height="72px"
                width="72px"
                style={{ borderRadius: "72px" }}
              />

              <div>
                <p className="black16">
                  {user.firstName} {user.lastName}
                </p>
                <p className="black14" style={{ marginTop: "0.75rem" }}>
                  {user.jobTitle}
                </p>
              </div>
            </div>
          )}
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate">Messaging availability</p>
            <p
              className="underthumbnailcreate"
              style={{ marginTop: "0.75rem" }}
            >
              Give candidates the option to message you through the job post.
              Messages from candidates will not count agains your message limit.
            </p>
            <p style={{ marginTop: "2.5rem" }} className="thumbnailcreate">
              Email Settings
            </p>
            <div style={{ marginTop: "1.5rem" }}>
              <div
                className="d-flex"
                style={{ flexDirection: "column", gap: "1rem" }}
              >
                {emailSetting.map((doc, index) => (
                  <div key={index} className="d-flex">
                    <div
                      className="d-flex "
                      style={{ gap: "0.75rem", cursor: "pointer" }}
                      onClick={() => handleSetRequiredDocuments(doc.name)}
                    >
                      <div className="d-flex">
                        {doc.value ? (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                                fill="#E31B54"
                              />
                            </svg>
                          </>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                              fill="#344054"
                            />
                          </svg>
                        )}
                      </div>
                      <p className="black14" style={{ fontWeight: "400" }}>
                        {doc.name}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className="shadowa"
          style={{
            marginTop: "2.5rem",
            borderRadius: "5px",
            background: "#FFF",
            padding: "1.5rem",
          }}
        >
          <p className="black16 text-start" style={{ fontWeight: "600" }}>
            Add your team members to the party (or rather to this job post)
          </p>
          <div className="d-flex">
            <p
              style={{
                marginTop: "1.5rem",
                fontSize: "14px",
                alignItems: "center",
                gap: "1rem",
                cursor: "pointer",
                opacity: add ? "0.5" : "1",
              }}
              onClick={() => setAdd(true)}
              className="pink16 d-flex"
            >
              <AddIcon /> Add team members.
            </p>
          </div>
          {add && (
            <div style={{ marginTop: "2.5rem" }}>
              <div className="space flex-wrap">
                <p className="thumbnailcreate">
                  Add Recruiting Team members (optional)
                </p>
              </div>
              <div style={{ position: "relative" }}>
                <form onSubmit={(e) => handleSearch(e)}>
                  <input
                    type="search"
                    placeholder="Choose team member"
                    className="forminput"
                    style={{
                      marginTop: "0.75rem",
                      width: "100%",
                      display: "block",
                      paddingLeft: "2rem", // Adjust padding to accommodate the icon
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </form>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{
                    position: "absolute",
                    left: "0.5rem", // Adjust icon position
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <path
                    d="M17.4999 17.4999L14.5833 14.5832M16.6665 9.58321C16.6665 13.4952 13.4952 16.6665 9.58321 16.6665C5.67119 16.6665 2.49988 13.4952 2.49988 9.58321C2.49988 5.67119 5.67119 2.49988 9.58321 2.49988C13.4952 2.49988 16.6665 5.67119 16.6665 9.58321Z"
                    stroke="#667085"
                    strokeWidth="1.36482"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              {list.length === 0 ? (
                <>
                  <div
                    className="center"
                    style={{
                      marginTop: "0.75rem",
                      marginBottom: "0.75rem",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={EmptyState}
                      alt="EmptyState"
                      height="180px"
                      width="225px"
                    />
                    <p
                      className="pink16"
                      style={{ fontSize: "20px", fontWeight: "500" }}
                    >
                      No Results yet
                    </p>
                    <p className="black14" style={{ fontWeight: "400" }}>
                      <span className="black14" style={{ fontWeight: "500" }}>
                        Invite your colleagues.
                      </span>{" "}
                      It’s for sure even more fun with you team here.
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ padding: "1.25rem" }}>
                    {list.map((doc, index) => (
                      <>
                        <div
                          className="space flex-wrap"
                          key={index}
                          style={{ gap: "1rem" }}
                        >
                          <div
                            className="d-flex flex-wrap"
                            style={{
                              width: isNonMobile1024 ? "40%" : "",
                              gap: "1.25rem",
                            }}
                          >
                            <img
                              src={doc.profilephoto}
                              alt="profilephoto"
                              className="shadowa"
                              height="42px"
                              width="42px"
                              style={{
                                borderRadius: "42px",
                                background: "#FFF",
                                padding: "2px",
                              }}
                            />

                            <div>
                              <p
                                className="black14"
                                style={{ fontWeight: "600" }}
                              >
                                {doc.firstName} {doc.lastName}
                              </p>
                              <p
                                className="black12"
                                style={{ marginTop: "0.25rem" }}
                              >
                                {doc.email}
                              </p>
                            </div>
                          </div>
                          <div style={{ width: isNonMobile1024 ? "40%" : "" }}>
                            <p
                              className="black14"
                              style={{ fontWeight: "600" }}
                            >
                              {doc.jobTitle}
                            </p>
                            <p
                              className="black12"
                              style={{ marginTop: "0.25rem" }}
                            >
                              {company.title}
                            </p>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: isNonMobile1024 ? "5%" : "",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="d-flex"
                              onClick={() => {
                                handleAddUsers(doc.uniqueId);
                              }}
                            >
                              {addedUsers.some(
                                (user) => user === doc.uniqueId
                              ) ? (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                                      fill="#E31B54"
                                    />
                                  </svg>
                                </>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                                    fill="#344054"
                                  />
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                        {index !== list.length - 1 && (
                          <>
                            <hr
                              style={{
                                marginTop: "1.25rem",
                                marginBottom: "1.25rem",
                              }}
                            />
                          </>
                        )}
                      </>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div
          className="d-flex flex-wrap"
          style={{ gap: "1rem", marginTop: "2.5rem" }}
        >
          <div className="d-flex" onClick={() => setDefault(!defaults)}>
            {defaults ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                    fill="#E31B54"
                  />
                </svg>
              </>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                  fill="#344054"
                />
              </svg>
            )}{" "}
          </div>
          <p className="black14">
            Set this as default values for future job posts.
          </p>
        </div>
        <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
        <div
          className="d-flex flex-wrap mb-5"
          style={{ justifyContent: "space-between", gap: "1.25rem" }}
        >
          <button className="backbutton" onClick={() => setSteps(7)}>
            Back
          </button>
          <button
            className="continuebutton"
            onClick={() => {
              handleSubmit();
            }}
          >
            Publish Job
          </button>
        </div>
      </div>
      {isNonMobile1024 && (
        <div
          className="d-flex"
          style={{
            minWidth: "305px",
            justifyContent: "end",
            marginTop: "6rem",
          }}
        >
          <div
            className="Challengescardouterwhitediv"
            style={{ position: "sticky", top: "10%" }}
          >
            <div
              className="Challengescardinnergreydiv"
              style={{ overflow: "hidden" }}
            >
              <div style={{ padding: "1.5rem 0.75rem" }}>
                <p className=" text-start black10">just now</p>
                <p className="pink12" style={{ marginTop: "0.75rem" }}>
                  {company.title}
                </p>
                <p
                  className="jobecardChallengeTitle"
                  style={{ marginTop: "0.625rem" }}
                >
                  {truncatedTitle}
                </p>
              </div>
            </div>
            <div className="space ">
              <div
                className="center shadowa"
                style={{
                  height: "56px",
                  maxHeight: "56px",
                  width: "56px",
                  maxWidth: "56px",
                  padding: "auto",
                  background: "#FFF",
                  alignItems: "center",
                  borderRadius: "6px",
                  position: "relative",
                  marginTop: "-28px",
                  marginLeft: "8px",
                }}
              >
                <img
                  src={company.img}
                  alt="company"
                  style={{ maxWidth: "42px" }}
                />
              </div>
              <div className="jobCardBlueChip">
                <img
                  src={sandclock}
                  alt="sand clock"
                  height="14px"
                  width="14px"
                />
                <p className="black12">{position}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Step7;
