import NonMobile from "../../components/NonMobile/NonMobile";
import logo from "../../assets/images/logo.png";
const Third = ({
  heading,
  description,
  chips,
  type,
  img,
  explain,
  pics,
  extra,
  extra2,
  video,
  scale,
}) => {
  const { isNonMobile1024 } = NonMobile();
  return (
    <div className="newHomeThird" style={{ padding: "0rem" }}>
      <div className="marginsleftrightCompany">
        <div className="center">
          <div
            className="headerCompany d-flex "
            style={{
              position: "relative",
              gap: isNonMobile1024 ? "3.75rem" : "2rem",
              flexDirection: isNonMobile1024
                ? type === "seedha"
                  ? "row"
                  : "row-reverse"
                : "column",
            }}
          >
            <div
              className="d-flex flex-column justify-content-center"
              style={{
                width: isNonMobile1024 ? "59.68%" : "100%",
                maxWidth: isNonMobile1024 ? "59.68%" : "100%",
              }}
            >
              <div className="d-flex">
                <p
                  className="itsearly mt-0"
                  style={{ maxWidth: isNonMobile1024 ? "450px" : "" }}
                  dangerouslySetInnerHTML={{ __html: heading }}
                ></p>
              </div>
              <p
                className="mt-3 gray95016400"
                style={{
                  fontFamily: "Poppins,sans-serif",
                  maxWidth: isNonMobile1024 ? "590px" : "",
                }}
              >
                {description}
              </p>

              <div className="divider" style={{ margin: "1.25rem 0rem" }} />
              {pics.length > 0 && (
                <div
                  className="d-flex flex-wrap align-items-center"
                  style={{ gap: "1.5rem" }}
                >
                  <div className="d-flex align-items-center">
                    {pics?.map((pic, index) => (
                      <img
                        src={pic}
                        alt="pics"
                        key={index}
                        loading="lazy"
                        className="shadowa rounded-5"
                        style={{
                          height: "44px",
                          width: "44px",

                          position: "relative",
                          zIndex: 5 - index,
                          marginLeft: "-15px",
                        }}
                      />
                    ))}
                  </div>
                  <div className="d-flex flex-wrap gap-2 align-items-center">
                    <p className="gray95014400" style={{ fontWeight: 500 }}>
                      {extra}
                    </p>
                    <div
                      className="d-flex gap-1 gray95014400 align-items-center"
                      style={{ fontWeight: 500 }}
                    >
                      <img
                        src={logo}
                        alt="logo"
                        loading="lazy"
                        className="shadowa"
                        style={{ height: "16px", width: "16px" }}
                      />
                      {extra2}
                    </div>
                  </div>
                </div>
              )}
              <div
                className="d-flex flex-wrap "
                style={{
                  gap: "0.625rem 0.5rem",
                }}
              >
                {chips &&
                  chips?.map((chip, index) => (
                    <div className="newskillwrapper" key={index}>
                      {chip?.icon && (
                        <img
                          src={chip.icon}
                          alt="ski.name"
                          height={"16px"}
                          width={"16px"}
                        />
                      )}
                      {chip.name}
                    </div>
                  ))}
              </div>
              <p className="gray70014400 mt-3" style={{ fontSize: "0.75rem" }}>
                {explain}
              </p>
            </div>
            <div
              className="center align-items-center"
              style={{
                width: isNonMobile1024 ? "35.48%" : "100%",
                maxWidth: isNonMobile1024 ? "35.48%" : "100%",
                position: "relative",
                // overflow: "hidden",
              }}
            >
              {video ? (
                <video
                  src={video}
                  autoPlay
                  playsInline
                  muted
                  loop
                  controls={false}
                  style={{
                    width: scale ? "150%" : "120%",
                    height: "100%",
                    maxHeight: "100%",
                    maxWidth: "600px",
                    // transform: isNonMobile1024 ? "scale(1.4)" : "",
                    // transformOrigin: isNonMobile1024 ? "center center" : "",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                >
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={img}
                  alt="1st section "
                  style={{ width: "100%", height: "auto", zoom: 2 }}
                  loading="lazy"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Third;
