import StudentShortProfile from "./StudentShortProfile";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ConnectedList = ({
  loadMoreConnects,
  connects,

  hasMoreConnect,
  loadingCon,
}) => {
  return (
    <div>
      <div
        className="d-flex flex-column "
        style={{
          gap: "1.25rem",
        }}
      >
        {loadingCon ? (
          <div className="center">
            <div className="loader" />
          </div>
        ) : connects.length > 0 ? (
          connects?.map((req, index) => (
            <div
              className="space gap-3 flex-wrap align-items-center"
              key={index}
              style={{
                paddingBottom: "1.25rem",
                borderBottom:
                  index !== connects.length - 1 ? "1px solid #EAECF0" : "",
              }}
            >
              <div>
                <StudentShortProfile student={req} />
              </div>
              <div
                className="d-flex align-items-center"
                style={{ gap: "1.25rem" }}
              >
                <button
                  className="continuebutton"
                  style={{
                    background: "none",
                    color: "#E31b54",
                    border: "1px solid #E31b54",
                    width: "120px",
                    padding: "8px 20px",
                    maxHeight: "40px",
                  }}
                >
                  Message
                </button>
                <MoreVertIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {}}
                />
              </div>
            </div>
          ))
        ) : (
          <p className="text-center black14">No connects atm.</p>
        )}
      </div>
      {hasMoreConnect && (
        <p
          className="pink14 mt-2"
          onClick={() => {
            loadMoreConnects();
          }}
        >
          See more
        </p>
      )}
    </div>
  );
};

export default ConnectedList;
