import React, { useEffect } from "react";

const BookDemo = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    };
  }, []);

  return (
    <div
      className=""
      style={{ position: "relative", width: "100%", height: "100vh" }}
    >
      <iframe
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0J4__eBUR1MWpqnuUNDNP403WNqWqFz4GDjBHI9sglhQYV9omo_HFYSBgPoAYkCuVQKQ0YpuDQ?gv=true"
        style={{ border: "0", width: "100%", height: "100%" }}
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default BookDemo;
