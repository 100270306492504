// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newpopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  max-height: 100vh;
  overflow-y: auto;
  align-items: center;
  z-index: 9999;
}

.newpopup-content {
  background: #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 80vh;
  position: relative;
}
.newpopup-bottom {
  border-radius: 0px 0px 5px 5px;
  border-top: 1px solid var(--Secondary-Color-Gray-200, #eaecf0);
  background: var(--Secondary-Color-Gray-25, #fcfcfd);
  display: flex;
  justify-content: space-between;
}
.newpopup-content-padding {
  padding: 0rem 2.5rem;
}
@media (max-width: 767px) {
  .newpopup-content-padding {
    padding: 0rem 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/StudentDashboard/Challenges/popup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,8BAA8B;EAC9B,8DAA8D;EAC9D,mDAAmD;EACnD,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,oBAAoB;AACtB;AACA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".newpopup {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.7);\n  display: flex;\n  justify-content: center;\n  max-height: 100vh;\n  overflow-y: auto;\n  align-items: center;\n  z-index: 9999;\n}\n\n.newpopup-content {\n  background: #fff;\n  border-radius: 5px;\n  width: 100%;\n  max-width: 800px;\n  height: 100%;\n  max-height: 80vh;\n  position: relative;\n}\n.newpopup-bottom {\n  border-radius: 0px 0px 5px 5px;\n  border-top: 1px solid var(--Secondary-Color-Gray-200, #eaecf0);\n  background: var(--Secondary-Color-Gray-25, #fcfcfd);\n  display: flex;\n  justify-content: space-between;\n}\n.newpopup-content-padding {\n  padding: 0rem 2.5rem;\n}\n@media (max-width: 767px) {\n  .newpopup-content-padding {\n    padding: 0rem 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
