import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-crop/dist/ReactCrop.css";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { useSelector } from "react-redux";

import PrimaryRouteLayout from "./layouts/PrimaryRouteLayout";

import CompanyRegister from "./pages/Company/Register";
import EmailPage from "./pages/Company/EmailPage";
import FindCompany from "./pages/Company/FindCompany";
import CreateCompany from "./pages/Company/CreateCompany";
import Role from "./pages/register/Role";
import Profile from "./pages/user/profile";
import Guide from "./pages/GuideLine/Guideline";

import Test from "./pages/Challenge/Challenges";

import Main from "./pages/Challenge/Main";
import StudentReg from "./components/StudentRegister/Register/main";

import PreviewPage from "./pages/PreviewPage/PreviewPage";

import CompanyHome from "./pages/CompanyHome/Home";
import Register from "./pages/Linkedin/register";
import Login from "./pages/Login/StudentLogin/Login";
import EditChallenge from "./pages/Challenge/EditChallenge";
import Jobpost from "./pages/JobPost/Main";
import CreateJob from "./pages/JobPost/CreateJob/CreateJob";
import Editjob from "./pages/JobPost/CreateJob/EditJob";
import Settings from "./pages/Settings/Settings";

import Company from "./pages/CompanyLogin/Login";
import LoginLinkedin from "./pages/Linkedin/login";

import DashboardMain from "./pages/MainDashBoard/DashboardMain";
import TalentPool from "./pages/TalentPool/Main";
import StudentProfilePage from "./pages/StudentProfilePage/StudentProfilePage";
import ShortList from "./pages/TalentPool/ShortListed/Shortlist";

import Preview from "./pages/CompanyPreview/Preview";
import TargetSchool from "./pages/TalentPool/TargetedSchools/Targeted";
import Team from "./pages/TeamPage/Team";
import InviteRegister from "./pages/TeamPage/InviteRegister";

import Testing from "./pages/Testing";

import CompanyEdit from "./pages/CompanyEdit/Edit";
import Terms from "./pages/Terms/Term";
import Privacy from "./pages/Privacy/Privacy";
import Cookie from "./pages/Cookie/Cookie";
import JobFlow from "./pages/StudentJobFlow/Jobflow";
import SocialMain from "./pages/SocialFeed/SocialMain";
import StudentSettings from "./pages/StudentSettings/Settings";
import NewDashboard from "./pages/Challenge/NewDashboard";

import CompanyChat from "./pages/CompanyChat/Main";
import ForgotPassword from "./pages/CompanyLogin/ForgotPassword";
import CreateNewPassword from "./pages/CompanyLogin/CreateNewPassword";
import EventMain from "./pages/Events/Main";
import CreateEvent from "./pages/CreatEvent/Main";
import EditEvent from "./pages/CreatEvent/EditEvent";
import Progress from "./pages/Progress/main";
import UniversityRegister from "./pages/University/Register/Main";
import SelectedView from "./pages/Events/Upcoming/SelectedView";
import ChallengeDetails from "./pages/Challenge/ChallengeDetails";
import Recruiter from "./pages/Profile/Recruiter";
import Details from "./pages/JobDetails/Details";
import NewProfile from "./pages/NewCompanyProfile/Profile";
import Contact from "./pages/Contact/Contact";
import UniversityHome from "./pages/University/Home/Main";
import Toolkit from "./pages/University/Toolkit/Main";
import CareerContact from "./pages/CareerContact/Contact";
import SupportNew from "./pages/SupportNew/SupportNew";
import BookDemo from "./pages/BookDemo/BookDemo";
import FindUni from "./pages/University/Register/FindUni";
import ScrollToTop from "./pages/ScrollTop/TopScroll";
import LatestStudentDashboard from "./pages/NewStudentDashboard/Main";

import NewStudentChallenges from "./pages/Student/Challenge/Main";
import StudentChallengeMain from "./pages/StudentChallenge/Main";

import NewResult from "./pages/ChallengeResult/NewResult";
import Solutions from "./pages/NewPages/Page1";
import PublicTour from "./pages/PublicTour/Main";
import EventsHome from "./pages/NewPages/Events/EventsHome";
import AnalyticsHome from "./pages/NewPages/Analytics/AnalyticsHome";
import StudentJobDetails from "./pages/StudentJobFlow/JobDetails";
import MainEventsStudent from "./pages/StudentEventFlow/Main";
import MainEventDetails from "./pages/StudentEventDetails/MainEventDetails";
import StudentGroupMain from "./pages/StudentGroups/Main";
import GroupDetails from "./pages/StudentGroups/GroupDetails/Main";
import NewStudentProfile from "./pages/StudentDashboardProfile/NewProfile";
import MainCertificate from "./pages/Certificate/Certificate";
import OnePost from "./pages/SinglePost/Single";
import CorporateTrack from "./pages/CorporateTrack/CorporateTrack";
import InnovativeTrack from "./pages/innovation-track/innovation-track";
import { useState } from "react";
import DummyHero from "./pages/DummyHero/DummyHero";
import NewHome from "./pages/Home/NewHome";
function App() {
  const OrganiserLogin = useSelector((state) => Boolean(state.uniqueId));
  const [student, setStudent] = useState(null);

  return (
    <>
      <ToastContainer />
      <Router>
        <ScrollToTop student={student} setStudent={setStudent} />
        <Routes>
          {/* <RouterProvider router={router} /> */}
          <Route
            path="/"
            element={
              OrganiserLogin ? (
                <Navigate to="/dashboard" />
              ) : (
                <PrimaryRouteLayout />
              )
            }
          >
            <Route index element={<NewHome />} />
            <Route path="/home" element={<NewHome />} />
          </Route>

          <Route
            path="/career-centers/marketing-toolkit/"
            element={<Toolkit />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/employer" element={<CompanyHome />} />
          <Route path="/career-centers" element={<UniversityHome />} />
          <Route
            path="/career-center/register"
            element={<UniversityRegister />}
          />
          <Route path="/career-center/find/:uniqueId" element={<FindUni />} />
          <Route path="/career-center/contact" element={<CareerContact />} />
          <Route path="/employer/register" element={<CompanyRegister />} />
          <Route path="/employer/register/verify" element={<EmailPage />} />
          <Route path="/register/linkedin" element={<Register />} />
          <Route path="/login/linkedin" element={<LoginLinkedin />} />
          <Route path="/Find/:uniqueId" element={<FindCompany />} />

          <Route path="/register" element={<StudentReg />} />

          <Route path="/create/company" element={<CreateCompany />} />

          <Route path="/login" element={<Login />} />
          <Route path="/create/company/role" element={<Role />} />
          <Route path="/support" element={<SupportNew />} />
          <Route path="/employer/support" element={<SupportNew />} />

          <Route path="/guidelines" element={<Guide />} />
          <Route
            path="/challenge/:uniqueId"
            element={<PreviewPage user={student} />}
          />
          <Route path="/preview/:uniqueId" element={<Preview />} />
          <Route
            path="/challenge/attempt/:uniqueId"
            element={<StudentChallengeMain student={student} />}
          />

          <Route path="/challenge/result/:id" element={<NewResult />} />
          <Route path="/test" element={<Testing />} />

          <Route
            path="/company/edit/:uniqueId"
            element={
              OrganiserLogin ? (
                <CompanyEdit />
              ) : (
                <Navigate to="/employer/login" />
              )
            }
          />

          <Route path="/edit/challenge" element={<EditChallenge />} />
          <Route path="/edit/job" element={<Editjob />} />

          <Route path="/employer/login" element={<Company />} />
          <Route path="/corporate-track" element={<CorporateTrack />} />
          <Route path="/innovation-track" element={<InnovativeTrack />} />
          <Route
            path="/student"
            element={
              <LatestStudentDashboard
                student={student}
                setStudent={setStudent}
              />
            }
          >
            <Route index element={<SocialMain user={student} />} />

            <Route path="post/:id" element={<OnePost user={student} />} />
            <Route
              path="user/:link"
              element={
                <NewStudentProfile watcher={student} setWatcher={setStudent} />
              }
            />
            <Route
              path="groups"
              element={<StudentGroupMain student={student} />}
            />
            <Route
              path="group/:link"
              element={<GroupDetails student={student} />}
            />
            <Route
              path="challenges"
              element={<NewStudentChallenges student={student} />}
            />
            <Route path="jobs" element={<JobFlow />} />
            <Route
              path="events"
              element={<MainEventsStudent user={student} />}
            />
            <Route
              path="event/:link"
              element={<MainEventDetails user={student} />}
            />
            <Route path="job/:uniqueId" element={<StudentJobDetails />} />
            <Route
              path="settings"
              element={<StudentSettings user={student} />}
            />
          </Route>

          <Route
            path="/dashboard"
            element={
              OrganiserLogin ? (
                <NewDashboard />
              ) : (
                <Navigate to="/employer/login" />
              )
            }
          >
            <Route index element={<DashboardMain />} />
            <Route path="u/:url" element={<Recruiter />} />
            <Route path="progress" element={<Progress />} />
            <Route path="book-tour" element={<BookDemo />} />
            <Route path="chat" element={<CompanyChat />} />
            <Route path="company/:uniqueId" element={<NewProfile />} />
            <Route path="events" element={<EventMain />} />
            <Route path="events/details" element={<SelectedView />} />
            <Route path="user/:uniqueId" element={<StudentProfilePage />} />
            <Route path="TalentPool" element={<TalentPool />} />
            <Route path="TalentPool/Short-list" element={<ShortList />} />
            <Route path="TalentPool/TargetSchool" element={<TargetSchool />} />
            <Route path="Challenges" element={<Test />} />
            <Route path="challenge/details" element={<ChallengeDetails />} />
            <Route path="Team" element={<Team />} />
            <Route path="Jobposts" element={<Jobpost />} />
            <Route path="job/details/:uniqueId" element={<Details />} />

            <Route path="settings" element={<Settings />} />
          </Route>

          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookie" element={<Cookie />} />

          <Route
            path="/create/challenge"
            element={
              OrganiserLogin ? <Main /> : <Navigate to="/employer/login" />
            }
          />
          <Route path="/employer/book-a-tour" element={<PublicTour />} />

          <Route
            path="/employer/solutions-challenges"
            element={<Solutions />}
          />
          <Route path="/employer/events" element={<EventsHome />} />
          <Route path="/employer/analytics" element={<AnalyticsHome />} />
          <Route
            path="/create/job"
            element={
              OrganiserLogin ? <CreateJob /> : <Navigate to="/employer/login" />
            }
          />
          <Route
            path="/create/event"
            element={
              OrganiserLogin ? (
                <CreateEvent />
              ) : (
                <Navigate to="/employer/login" />
              )
            }
          />
          <Route
            path="/edit/event"
            element={
              OrganiserLogin ? <EditEvent /> : <Navigate to="/employer/login" />
            }
          />
          <Route
            path="/employer/forgot/password"
            element={<ForgotPassword />}
          />
          <Route path="/dummy-hero" element={<DummyHero />} />
          <Route
            path="/employer/recover/:uniqueId"
            element={<CreateNewPassword />}
          />
          <Route
            path="/registration/:company/:dsa/:user"
            element={<InviteRegister />}
          />
          <Route
            path="/create/profile/:uniqueId/:dsa/:company/:user"
            element={<Profile />}
          />
          <Route
            path="/student/certificate/:id"
            element={<MainCertificate />}
          />
          <Route path="/create/company/role/:Role" element={<Profile />} />

          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
