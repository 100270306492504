import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import EmptyState from "../../assets/images/EmptyState.svg";
import "./main.css";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { setEditJob } from "../../state";
import { useMediaQuery } from "@mui/material";
import Analytics from "../JobPost/Analytics";
import { toast } from "react-toastify";
import deleteicon from "../../assets/images/delete.png";
import CloseIcon from "@mui/icons-material/Close";
import ChallengeType from "../MainDashBoard/type";
import SelectedBox from "../../assets/images/icons/selectedbox";
import UnselectedBox from "../../assets/images/icons/unselectedBox";

const Details = () => {
  const { uniqueId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [challenges, setChallenges] = useState([]);
  const [add, setAdd] = useState(false);
  const [search, setSearch] = useState("");
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const email = useSelector((state) => state.uniqueId);
  const [users, setUsers] = useState([]);
  const [list, setList] = useState([]);
  const [addedUsers, setAddedUsers] = useState([]);
  const [popup, setPopUp] = useState(false);
  const [popup2, setPopUp2] = useState(false);

  const [linkedChallenges, setLinkedChallenges] = useState([]);

  const [popup3, setPopUp3] = useState(false);
  const popupref3 = useRef();

  const iconRef = useRef();
  const popupref = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setPopUp2(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setPopUp(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (popupref3.current && !popupref3.current.contains(event.target)) {
        setPopUp3(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [emailSetting, setEmailSetting] = useState([
    {
      name: "Send summary email once application period closes",
      value: true,
    },
    {
      name: "Send monthly summary email",
      value: true,
    },
    {
      name: "Send email when a candidate applies",
      value: false,
    },
  ]);

  const [job, setJob] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page");
  const [selected, setSelected] = useState(
    page === "3" ? "Hiring Team" : page === "2" ? "Applications" : "Job Details"
  );
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/job/details`, { uniqueId })
        .then((res) => {
          if (res.data.msg === "success") {
            setJob(res.data.data);
            setOwner(res.data.user);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });

      setLoading(false);
    };

    if (!uniqueId) {
      navigate(-1);
    } else {
      fetchData();
    }
  }, [uniqueId]);

  const updateAddedUsersInDB = async (updatedUsers) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/updateAddedUsers`,
        { uniqueId, addedUsers: updatedUsers }
      );

      if (response.data.msg === "success") {
        setJob(response.data.job);
      } else {
        console.error("Failed to update added users");
      }
    } catch (error) {
      console.error("Error updating added users:", error);
    }
  };

  const handleAddUsers = (newId) => {
    setAddedUsers((prev) => {
      const check = [...prev];
      let updatedUsers;

      if (check.some((id) => id === newId)) {
        updatedUsers = check.filter((id) => id !== newId);
      } else {
        updatedUsers = [...check, newId];
      }

      // Update the addedUsers in the backend as well
      updateAddedUsersInDB(updatedUsers);

      return updatedUsers;
    });
  };

  useEffect(() => {
    setList(
      users.filter((user) => {
        return `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(search.toLowerCase());
      })
    );
  }, [search, users]);

  useEffect(() => {
    if (job && job.emailSetting) {
      setEmailSetting(job.emailSetting);
    }

    if (job && job.addedUsers.length > 0) {
      setAdd(true);
      setAddedUsers(job.addedUsers);
    }
  }, [job]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: email }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
        } else {
          navigate("/register/organiser");
          return;
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [email]);

  useEffect(() => {
    if (job && user && job?.company && JSON.parse(job.company).uniqueId) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/get/company/users`, {
          uniqueId: JSON.parse(job?.company)?.uniqueId,
          userId: user.uniqueId,
        })
        .then((res) => {
          if (res.data.msg === "success") {
            setUsers(res.data.filtered);
          }
        });
    }
  }, [job]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/get/company/challenges`, {
          uniqueId: JSON.parse(job?.company)?.uniqueId,
        })
        .then((res) => {
          if (res.data.msg === "success") {
            setChallenges(res.data.challenges);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (job && job.company) {
      fetchData();
    }
  }, [job]);

  const handleCopy = () => {
    const link = `${process.env.REACT_APP_FRONTEND_URL}/dashboard/job/details/${job?.uniqueId}`;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copied to clipboard!");
        setPopUp(false);
      })
      .catch((err) => {
        toast.error("Failed to copy the link.");
      });
  };

  const updateEmailSettingInDB = async (updatedSettings) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/updateEmailSetting`,
        { uniqueId, emailSetting: updatedSettings }
      );

      if (response.data.msg === "success") {
        setJob(response.data.job);
      } else {
        console.error("Failed to update email settings");
      }
    } catch (error) {
      console.error("Error updating email settings:", error);
    }
  };

  const handleSetRequiredDocuments = (name) => {
    setEmailSetting((prevDocuments) => {
      const updated = prevDocuments.map((document) => {
        if (document.name === name) {
          return { ...document, value: !document.value };
        }
        return document;
      });

      // Update the settings in the backend as well
      updateEmailSettingInDB(updated);

      return updated;
    });
  };

  const SubmitLinked = async () => {
    if (job.linkedChallenges !== linkedChallenges) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/post/linked/challenges`,
          {
            uniqueId: job?.uniqueId,
            linkedChallenges,
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            setJob(res.data.job);
            toast.success("Linked Challenges Updated Successfully");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setPopUp3(false);
  };

  const handleDeleteChallenge = async (id) => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/delete/job`, { id: id })
      .then((res) => {
        if (res.data.msg === "Job deleted successfully") {
          toast.success("Job post deleted successfully");
          navigate("/dashboard/Jobposts");
        } else if (res.data.msg === "Job not found") {
          navigate("/dashboard/Jobposts");
        } else {
          toast.error("Error Deleting Job post");
        }
      });
  };

  return (
    <>
      {popup3 && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 11,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            ref={popupref3}
            style={{
              maxWidth: "90vw",
              maxHeight: "80vh",

              maxWidth: "700px",
              backgroundColor: "",
              padding: "1.5rem",
            }}
          >
            <div className="space align-items-center" style={{ gap: "1rem" }}>
              <p className="gray90018500">Link Challenges</p>
              <CloseIcon
                className="hoverdarkblacksvg"
                style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
                onClick={() => setPopUp3(false)}
              />
            </div>
            <div style={{ padding: "1rem 0rem" }}>
              <div
                style={{
                  background: "#F5F8FF",
                  borderRadius: "8px",
                  padding: "0.75rem",
                }}
              >
                <p className="gray95016500" style={{ fontWeight: 600 }}>
                  How to link a challenge.
                </p>
                <p className="gray70014400" style={{ marginTop: "0.25rem" }}>
                  You can now link{" "}
                  <span className="gray70014400" style={{ fontWeight: 600 }}>
                    Fast-Track Challenges
                  </span>{" "}
                  to a job post, for students to learn more about the job and
                  practice relevant skills.
                </p>
              </div>
            </div>
            <div
              className="tablescroll"
              style={{
                padding: "1rem 1rem 1rem 0rem",
                maxHeight: "150px",
                overflow: "auto",
              }}
            >
              {challenges.map(
                (challenge, index) =>
                  challenge.type === "Fast-Track" && (
                    <div key={index}>
                      <div
                        className="space align-items-center"
                        style={{ gap: "1.5rem", cursor: "pointer" }}
                        onClick={() => {
                          setLinkedChallenges((prev) => {
                            const check = [...prev];
                            if (check.includes(challenge._id)) {
                              const index = check.indexOf(challenge._id);
                              check.splice(index, 1);
                            } else {
                              check.push(challenge._id);
                            }
                            return check;
                          });
                        }}
                      >
                        <p className="gray95014400" style={{ width: "40%" }}>
                          {challenge.title}
                        </p>
                        <div className="joblivestatusouter">
                          <div
                            style={{
                              height: "8px",
                              width: "8px",
                              borderRadius: "8px",
                              background: challenge?.draft
                                ? "#F97066"
                                : "#17B26A",
                            }}
                          />{" "}
                          {challenge?.draft ? "Draft" : "Live"}
                        </div>
                        <ChallengeType type={challenge.type} />
                        {linkedChallenges.includes(challenge._id) ? (
                          <SelectedBox />
                        ) : (
                          <UnselectedBox />
                        )}
                      </div>
                      <hr style={{ marginTop: "1.25rem" }} />
                    </div>
                  )
              )}
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "2rem", gap: "0.75rem" }}
            >
              <a
                className="backbutton"
                style={{ cursor: "pointer", textDecoration: "none" }}
                href={`${process.env.REACT_APP_FRONTEND_URL}/create/challenge`}
                target="_blank"
              >
                <AddIcon /> Create new challenge
              </a>
              <button
                className="continuebutton"
                onClick={() => {
                  SubmitLinked();
                }}
              >
                <AllInclusiveIcon style={{ height: "20px", width: "20px" }} />{" "}
                Link challenges
              </button>
            </div>
          </div>
        </div>
      )}
      {popup2 && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 11,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            ref={popupref}
            style={{
              maxWidth: "90vw",
              maxHeight: "80vh",

              maxWidth: "700px",
              backgroundColor: "",
              padding: "1.5rem",
            }}
          >
            <div className="d-flex " style={{ gap: "1rem" }}>
              <div className="circle-background">
                <img src={deleteicon} alt="delete" height="48px" width="48px" />
              </div>
              <div>
                <p className="gray90018500">Delete job post</p>
                <p className="gray70014400">
                  Are you sure you want to delete this job post?
                  <br /> This action cannot be undone.
                </p>
              </div>
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "2rem", gap: "0.75rem" }}
            >
              <button
                className="backbutton"
                onClick={() => {
                  setPopUp2(false);
                }}
              >
                Cancel
              </button>
              <button
                className="continuebutton"
                onClick={() => {
                  handleDeleteChallenge(job._id);
                  setPopUp2(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="paddingmaincontent">
        {loading ? (
          <p className="black14">Loading...</p>
        ) : (
          <div>
            <div
              className="space align-items-center flex-wrap"
              style={{ gap: "1rem" }}
            >
              <div>
                <p className="gray95024400">{job?.title} </p>
                <div
                  className="d-flex align-items-center"
                  style={{ marginTop: "0.25rem", gap: "0.5rem" }}
                >
                  <div
                    style={{
                      height: "8px",
                      width: "8px",
                      borderRadius: "8px",
                      background: job?.draft ? "#F97066" : "#17B26A",
                    }}
                  />
                  <p style={{ fontWeight: 700 }} className="gray80014400">
                    Job ID #{job?.uniqueId.split("-")[0]}{" "}
                    <span className="gray80014400" style={{ fontWeight: 500 }}>
                      {" "}
                      | Created {job?.createdAt?.split("T")[0]} by{" "}
                      {owner?.firstName} {owner?.lastName}{" "}
                      {job?.applicationCloseDate && (
                        <>| Closes {job?.applicationCloseDate?.split("T")[0]}</>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className="d-flex flex-wrap align-items-center"
                style={{ gap: "1rem" }}
              >
                <div
                  className="center align-items-center hoverred"
                  style={{
                    background: "rgba(255, 255, 255, 0.60)",
                    padding: "8px",
                    borderRadius: "6px",
                    border: "1px solid #FFF",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <MoreVertIcon
                    className=""
                    onClick={() => {
                      setPopUp(true);
                    }}
                  />
                  {popup && (
                    <div
                      ref={iconRef}
                      className="shadowa"
                      style={{
                        position: "absolute",
                        width: "150px",
                        right: "1px",
                        top: "30px",
                      }}
                    >
                      <button
                        className="dropdowntopbutton"
                        onClick={() => {
                          dispatch(
                            setEditJob({
                              editJob: job,
                            })
                          );
                          navigate("/edit/job");
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="dropdownmiddlebutton"
                        onClick={() => {
                          handleCopy();
                        }}
                      >
                        Share
                      </button>
                      <button
                        className="dropdownbottombutton"
                        onClick={() => {
                          setPopUp2(true);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
                <button
                  className="continuebutton"
                  onClick={() => setPopUp3(true)}
                >
                  <AllInclusiveIcon style={{ height: "20px", width: "20px" }} />
                  Link Challenge
                </button>
              </div>
            </div>
            <div style={{ marginTop: "1.25rem" }}>
              <div
                className={`transition ${isNonMobile && "d-flex"} `}
                style={{
                  justifyContent: "space-between",
                  flexFlow: "wrap",
                  gap: "10px",
                }}
              >
                <div className=" behindbuttons ">
                  <button
                    className={`  border-0 d-flex ${
                      selected === "Job Details"
                        ? "selectedbutton rounded-2 "
                        : "secondbuttontext"
                    } ${isNonMobile ? "" : "p-1  "}`}
                    style={
                      isNonMobile
                        ? {
                            minWidth: "188px",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected("Job Details");
                      navigate(`/dashboard/job/details/${uniqueId}?page=1`);
                    }}
                  >
                    Job Details{" "}
                  </button>
                  <button
                    className={` border-0 d-flex  ${
                      selected === "Applications"
                        ? "selectedbutton rounded-2 "
                        : "secondbuttontext"
                    } ${isNonMobile ? "" : "p-1  "}`}
                    style={isNonMobile ? { minWidth: "188px" } : {}}
                    onClick={() => {
                      setSelected("Applications");
                      navigate(`/dashboard/job/details/${uniqueId}?page=2`);
                    }}
                  >
                    Applications
                  </button>

                  <button
                    className={`   border-0 d-flex ${
                      selected === "Hiring Team"
                        ? "selectedbutton rounded-2 "
                        : "secondbuttontext"
                    } ${isNonMobile ? "" : "p-1  "}`}
                    style={isNonMobile ? { minWidth: "188px" } : {}}
                    onClick={() => {
                      setSelected("Hiring Team");
                      navigate(`/dashboard/job/details/${uniqueId}?page=3`);
                    }}
                  >
                    Hiring Team
                  </button>
                </div>
              </div>
              {selected === "Job Details" && (
                <div>
                  <div
                    className="shadowa"
                    style={{
                      marginTop: "2.5rem",
                      minHeight: "27rem",
                      background: "#FFF",
                      padding: "1.5rem",
                    }}
                  >
                    <div className="space">
                      <p className="black16" style={{ fontWeight: "600" }}>
                        Basic Information
                      </p>
                      <p
                        className="d-flex pink16"
                        onClick={() => {
                          dispatch(setEditJob({ editJob: job }));
                          navigate("/edit/job?step=1");
                        }}
                        style={{
                          fontSize: "14px",
                          gap: "0.75rem",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Edit
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.49173 12.725L7.10006 9.88331C7.18863 9.48842 7.38811 9.12703 7.67506 8.84165L13.2334 3.33331C13.797 2.75913 14.5623 2.4273 15.3667 2.40831C15.9734 2.39962 16.5584 2.63363 16.9917 3.05831C17.9386 4.17704 17.8163 5.84766 16.7167 6.81665L11.1584 12.375C10.873 12.6619 10.5116 12.8614 10.1167 12.95L7.27506 13.5583H7.11673C6.90899 13.5663 6.71057 13.4718 6.58583 13.3054C6.4611 13.1391 6.42593 12.9222 6.49173 12.725ZM8.5584 9.73331C8.44164 9.84647 8.36053 9.9913 8.32506 10.15L7.91673 12.0916L9.8584 11.675C10.0171 11.6395 10.1619 11.5584 10.2751 11.4416L15.8334 5.88331C16.4423 5.39583 16.5631 4.51705 16.1084 3.88331C15.9093 3.69307 15.642 3.59096 15.3667 3.59998C14.8922 3.61904 14.4438 3.82231 14.1167 4.16665L8.5584 9.73331Z"
                            fill="#E31B54"
                          />
                          <path
                            d="M16.5917 9.10831C16.2484 9.1128 15.9712 9.39001 15.9667 9.73331V14.475C15.9779 15.1452 15.7195 15.7919 15.2494 16.2697C14.7793 16.7476 14.1371 17.0167 13.4667 17.0166H5.52507C4.13954 16.9895 3.02933 15.8608 3.02507 14.475V6.56665C3.04781 5.17922 4.17912 4.06646 5.56673 4.06665H10.3084C10.6536 4.06665 10.9334 3.78683 10.9334 3.44165C10.9334 3.09647 10.6536 2.81665 10.3084 2.81665H5.52507C3.44715 2.81652 1.75623 4.48886 1.7334 6.56665V14.475C1.7334 16.5691 3.43099 18.2666 5.52507 18.2666H13.4334C15.5242 18.2621 17.2167 16.5658 17.2167 14.475V9.73331C17.2122 9.39001 16.935 9.1128 16.5917 9.10831Z"
                            fill="#E31B54"
                          />
                        </svg>
                      </p>
                    </div>
                    <div style={{ marginTop: "1.5rem" }}>
                      <p className="thumbnailcreate">Job title</p>
                      <p
                        className="underthumbnailcreate"
                        style={{ marginTop: "0.75rem" }}
                      >
                        {job?.title}
                      </p>
                      <p
                        style={{ marginTop: "1.5rem" }}
                        className="thumbnailcreate"
                      >
                        Position type
                      </p>
                      <p
                        className="underthumbnailcreate"
                        style={{ marginTop: "0.75rem" }}
                      >
                        {job?.position}
                      </p>
                      <p
                        className="thumbnailcreate"
                        style={{ marginTop: "1.5rem" }}
                      >
                        Job description
                      </p>
                      <div
                        className="allpoppins tablescroll"
                        style={{
                          marginTop: "0.75rem",
                          maxHeight: "152px",
                          overflow: "auto",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: job?.jobDescription,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="shadowa"
                    style={{
                      marginTop: "2.5rem",
                      minHeight: "14rem",
                      background: "#FFF",
                      padding: "1.5rem",
                    }}
                  >
                    <div className="space">
                      <p className="black16" style={{ fontWeight: "600" }}>
                        Location Requirement
                      </p>
                      <p
                        className="d-flex pink16"
                        onClick={() => {
                          dispatch(setEditJob({ editJob: job }));
                          navigate("/edit/job?step=3");
                        }}
                        style={{
                          fontSize: "14px",
                          gap: "0.75rem",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Edit
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.49173 12.725L7.10006 9.88331C7.18863 9.48842 7.38811 9.12703 7.67506 8.84165L13.2334 3.33331C13.797 2.75913 14.5623 2.4273 15.3667 2.40831C15.9734 2.39962 16.5584 2.63363 16.9917 3.05831C17.9386 4.17704 17.8163 5.84766 16.7167 6.81665L11.1584 12.375C10.873 12.6619 10.5116 12.8614 10.1167 12.95L7.27506 13.5583H7.11673C6.90899 13.5663 6.71057 13.4718 6.58583 13.3054C6.4611 13.1391 6.42593 12.9222 6.49173 12.725ZM8.5584 9.73331C8.44164 9.84647 8.36053 9.9913 8.32506 10.15L7.91673 12.0916L9.8584 11.675C10.0171 11.6395 10.1619 11.5584 10.2751 11.4416L15.8334 5.88331C16.4423 5.39583 16.5631 4.51705 16.1084 3.88331C15.9093 3.69307 15.642 3.59096 15.3667 3.59998C14.8922 3.61904 14.4438 3.82231 14.1167 4.16665L8.5584 9.73331Z"
                            fill="#E31B54"
                          />
                          <path
                            d="M16.5917 9.10831C16.2484 9.1128 15.9712 9.39001 15.9667 9.73331V14.475C15.9779 15.1452 15.7195 15.7919 15.2494 16.2697C14.7793 16.7476 14.1371 17.0167 13.4667 17.0166H5.52507C4.13954 16.9895 3.02933 15.8608 3.02507 14.475V6.56665C3.04781 5.17922 4.17912 4.06646 5.56673 4.06665H10.3084C10.6536 4.06665 10.9334 3.78683 10.9334 3.44165C10.9334 3.09647 10.6536 2.81665 10.3084 2.81665H5.52507C3.44715 2.81652 1.75623 4.48886 1.7334 6.56665V14.475C1.7334 16.5691 3.43099 18.2666 5.52507 18.2666H13.4334C15.5242 18.2621 17.2167 16.5658 17.2167 14.475V9.73331C17.2122 9.39001 16.935 9.1128 16.5917 9.10831Z"
                            fill="#E31B54"
                          />
                        </svg>
                      </p>
                    </div>
                    <div style={{ marginTop: "1.5rem" }}>
                      <p className="thumbnailcreate">Location type</p>
                      <p
                        className="underthumbnailcreate"
                        style={{ marginTop: "0.75rem" }}
                      >
                        {job?.locationType}
                      </p>
                      {job?.locationType !== "Remote" && (
                        <>
                          <p
                            style={{ marginTop: "1.5rem" }}
                            className="thumbnailcreate"
                          >
                            Onsite location
                          </p>
                          {job?.country &&
                            job?.country.length > 0 &&
                            job?.country.map((job, index) => (
                              <div key={index}>
                                <p
                                  className="underthumbnailcreate"
                                  style={{
                                    marginTop: "0.75rem",
                                    fontWeight: "600",
                                  }}
                                >
                                  {job?.Country?.name}:
                                </p>
                                <p
                                  className="underthumbnailcreate d-flex flex-wrap"
                                  style={{ marginTop: "0.75rem", gap: "1rem" }}
                                >
                                  {job.City &&
                                    job.City.length > 0 &&
                                    job.City.map((city, index) => (
                                      <span
                                        className="underthumbnailcreate"
                                        key={index}
                                      >
                                        {city.name}
                                      </span>
                                    ))}
                                </p>
                              </div>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className="shadowa"
                    style={{
                      marginTop: "2.5rem",
                      minHeight: "14rem",
                      background: "#FFF",
                      padding: "1.5rem",
                    }}
                  >
                    <div className="space">
                      <p className="black16" style={{ fontWeight: "600" }}>
                        Time Requirements
                      </p>
                      <p
                        className="d-flex pink16"
                        onClick={() => {
                          dispatch(setEditJob({ editJob: job }));
                          navigate("/edit/job?step=2");
                        }}
                        style={{
                          fontSize: "14px",
                          gap: "0.75rem",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Edit
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.49173 12.725L7.10006 9.88331C7.18863 9.48842 7.38811 9.12703 7.67506 8.84165L13.2334 3.33331C13.797 2.75913 14.5623 2.4273 15.3667 2.40831C15.9734 2.39962 16.5584 2.63363 16.9917 3.05831C17.9386 4.17704 17.8163 5.84766 16.7167 6.81665L11.1584 12.375C10.873 12.6619 10.5116 12.8614 10.1167 12.95L7.27506 13.5583H7.11673C6.90899 13.5663 6.71057 13.4718 6.58583 13.3054C6.4611 13.1391 6.42593 12.9222 6.49173 12.725ZM8.5584 9.73331C8.44164 9.84647 8.36053 9.9913 8.32506 10.15L7.91673 12.0916L9.8584 11.675C10.0171 11.6395 10.1619 11.5584 10.2751 11.4416L15.8334 5.88331C16.4423 5.39583 16.5631 4.51705 16.1084 3.88331C15.9093 3.69307 15.642 3.59096 15.3667 3.59998C14.8922 3.61904 14.4438 3.82231 14.1167 4.16665L8.5584 9.73331Z"
                            fill="#E31B54"
                          />
                          <path
                            d="M16.5917 9.10831C16.2484 9.1128 15.9712 9.39001 15.9667 9.73331V14.475C15.9779 15.1452 15.7195 15.7919 15.2494 16.2697C14.7793 16.7476 14.1371 17.0167 13.4667 17.0166H5.52507C4.13954 16.9895 3.02933 15.8608 3.02507 14.475V6.56665C3.04781 5.17922 4.17912 4.06646 5.56673 4.06665H10.3084C10.6536 4.06665 10.9334 3.78683 10.9334 3.44165C10.9334 3.09647 10.6536 2.81665 10.3084 2.81665H5.52507C3.44715 2.81652 1.75623 4.48886 1.7334 6.56665V14.475C1.7334 16.5691 3.43099 18.2666 5.52507 18.2666H13.4334C15.5242 18.2621 17.2167 16.5658 17.2167 14.475V9.73331C17.2122 9.39001 16.935 9.1128 16.5917 9.10831Z"
                            fill="#E31B54"
                          />
                        </svg>
                      </p>
                    </div>
                    <div style={{ marginTop: "1.5rem" }}>
                      <p className="thumbnailcreate">Schedule</p>
                      <p
                        className="underthumbnailcreate"
                        style={{ marginTop: "0.75rem" }}
                      >
                        {job?.timeRequirement}
                      </p>

                      <p
                        style={{ marginTop: "1.5rem" }}
                        className="thumbnailcreate"
                      >
                        Employment duration
                      </p>

                      <p
                        className="underthumbnailcreate"
                        style={{ marginTop: "0.75rem" }}
                      >
                        {job?.employmentType}
                      </p>
                    </div>
                  </div>
                  <div
                    className="shadowa"
                    style={{
                      marginTop: "2.5rem",
                      minHeight: "24.75rem",
                      background: "#FFF",
                      padding: "1.5rem",
                    }}
                  >
                    <div className="space">
                      <p className="black16" style={{ fontWeight: "600" }}>
                        Categorize your job
                      </p>
                      <p
                        className="d-flex pink16"
                        onClick={() => {
                          dispatch(setEditJob({ editJob: job }));
                          navigate("/edit/job?step=4");
                        }}
                        style={{
                          fontSize: "14px",
                          gap: "0.75rem",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Edit
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.49173 12.725L7.10006 9.88331C7.18863 9.48842 7.38811 9.12703 7.67506 8.84165L13.2334 3.33331C13.797 2.75913 14.5623 2.4273 15.3667 2.40831C15.9734 2.39962 16.5584 2.63363 16.9917 3.05831C17.9386 4.17704 17.8163 5.84766 16.7167 6.81665L11.1584 12.375C10.873 12.6619 10.5116 12.8614 10.1167 12.95L7.27506 13.5583H7.11673C6.90899 13.5663 6.71057 13.4718 6.58583 13.3054C6.4611 13.1391 6.42593 12.9222 6.49173 12.725ZM8.5584 9.73331C8.44164 9.84647 8.36053 9.9913 8.32506 10.15L7.91673 12.0916L9.8584 11.675C10.0171 11.6395 10.1619 11.5584 10.2751 11.4416L15.8334 5.88331C16.4423 5.39583 16.5631 4.51705 16.1084 3.88331C15.9093 3.69307 15.642 3.59096 15.3667 3.59998C14.8922 3.61904 14.4438 3.82231 14.1167 4.16665L8.5584 9.73331Z"
                            fill="#E31B54"
                          />
                          <path
                            d="M16.5917 9.10831C16.2484 9.1128 15.9712 9.39001 15.9667 9.73331V14.475C15.9779 15.1452 15.7195 15.7919 15.2494 16.2697C14.7793 16.7476 14.1371 17.0167 13.4667 17.0166H5.52507C4.13954 16.9895 3.02933 15.8608 3.02507 14.475V6.56665C3.04781 5.17922 4.17912 4.06646 5.56673 4.06665H10.3084C10.6536 4.06665 10.9334 3.78683 10.9334 3.44165C10.9334 3.09647 10.6536 2.81665 10.3084 2.81665H5.52507C3.44715 2.81652 1.75623 4.48886 1.7334 6.56665V14.475C1.7334 16.5691 3.43099 18.2666 5.52507 18.2666H13.4334C15.5242 18.2621 17.2167 16.5658 17.2167 14.475V9.73331C17.2122 9.39001 16.935 9.1128 16.5917 9.10831Z"
                            fill="#E31B54"
                          />
                        </svg>
                      </p>
                    </div>
                    <div style={{ marginTop: "1.5rem" }}>
                      <p className="thumbnailcreate">Student interest</p>
                      <div
                        className="d-flex flex-wrap"
                        style={{ gap: "6px 1rem", marginTop: "0.75rem" }}
                      >
                        {job?.selectedItem.map((item, index) => (
                          <div
                            className={`interestcont ${
                              job?.selectedItem.some(
                                (selectedIte) => selectedIte.id === item.id
                              )
                                ? "active"
                                : ""
                            }`}
                            key={index}
                            style={{
                              padding: "10px 10px",
                              gap: "0.5rem",
                              cursor: "pointer",
                            }}
                          >
                            {item.icon && (
                              <img
                                src={item.icon}
                                alt="icon"
                                height="20px"
                                width="20px"
                              />
                            )}
                            <p
                              className="interesttext"
                              style={{
                                color: job?.selectedItem.some(
                                  (selectedIte) => selectedIte.id === item.id
                                )
                                  ? "white"
                                  : "",
                                fontWeight: "400",
                                lineHeight: "1.125rem",
                              }}
                            >
                              {item.text}
                            </p>
                          </div>
                        ))}
                      </div>
                      <p
                        style={{ marginTop: "1.5rem" }}
                        className="thumbnailcreate"
                      >
                        Skills
                      </p>
                      <p
                        className="underthumbnailcreate d-flex flex-wrap"
                        style={{ marginTop: "0.75rem", gap: "0.75rem" }}
                      >
                        {job?.relativeSkills.map((item, index) => (
                          <span key={index} style={{ fontFamily: "Poppins" }}>
                            {item}
                          </span>
                        ))}
                      </p>
                      <p
                        style={{ marginTop: "1.5rem" }}
                        className="thumbnailcreate"
                      >
                        Study level
                      </p>
                      <p
                        className="underthumbnailcreate"
                        style={{ marginTop: "0.75rem" }}
                      >
                        {job?.studyLevel}
                      </p>
                      {job?.language && (
                        <>
                          <p
                            style={{ marginTop: "1.5rem" }}
                            className="thumbnailcreate"
                          >
                            language
                          </p>

                          <p
                            className="underthumbnailcreate"
                            style={{ marginTop: "0.75rem" }}
                          >
                            {job?.language[0]?.text}
                          </p>
                        </>
                      )}{" "}
                    </div>
                  </div>
                </div>
              )}
              {selected === "Applications" && (
                <div style={{ marginTop: "2.5rem" }}>
                  <Analytics challenges={[job]} True={true} />
                </div>
              )}

              {selected === "Hiring Team" && (
                <div style={{ marginTop: "2.5rem" }}>
                  {" "}
                  <div
                    className="shadowa"
                    style={{
                      marginTop: "2.5rem",
                      minHeight: "26.563rem",
                      background: "#FFF",
                      padding: "1.5rem",
                      borderRadius: "5px",
                    }}
                  >
                    <p
                      className="black16 text-start"
                      style={{ fontWeight: "600" }}
                    >
                      Job owner
                    </p>
                    {owner && owner.profilephoto && (
                      <div
                        className="d-flex flex-wrap"
                        style={{ marginTop: "1.5rem", gap: "1.5rem" }}
                      >
                        <img
                          src={owner.profilephoto}
                          className="shadowa"
                          alt="profile"
                          height="72px"
                          width="72px"
                          style={{ borderRadius: "72px" }}
                        />

                        <div>
                          <p className="black16">
                            {owner.firstName} {owner.lastName}
                          </p>
                          <p
                            className="black14"
                            style={{ marginTop: "0.75rem" }}
                          >
                            {owner.jobTitle}
                          </p>
                        </div>
                      </div>
                    )}
                    <div style={{ marginTop: "1.5rem" }}>
                      <p className="thumbnailcreate">Messaging availability</p>
                      <p
                        className="underthumbnailcreate"
                        style={{ marginTop: "0.75rem" }}
                      >
                        Give candidates the option to message you through the
                        job post. Messages from candidates will not count agains
                        your message limit.
                      </p>
                      <p
                        style={{ marginTop: "2.5rem" }}
                        className="thumbnailcreate"
                      >
                        Email Settings
                      </p>
                      <div style={{ marginTop: "1.5rem" }}>
                        <div
                          className="d-flex"
                          style={{ flexDirection: "column", gap: "1rem" }}
                        >
                          {emailSetting.map((doc, index) => (
                            <div key={index} className="d-flex">
                              <div
                                className="d-flex "
                                style={{ gap: "0.75rem", cursor: "pointer" }}
                                onClick={() =>
                                  handleSetRequiredDocuments(doc.name)
                                }
                              >
                                <div className="d-flex">
                                  {doc.value ? (
                                    <>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                                          fill="#E31B54"
                                        />
                                      </svg>
                                    </>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                                        fill="#344054"
                                      />
                                    </svg>
                                  )}
                                </div>
                                <p
                                  className="black14"
                                  style={{ fontWeight: "400" }}
                                >
                                  {doc.name}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="shadowa"
                    style={{
                      marginTop: "2.5rem",
                      borderRadius: "5px",
                      background: "#FFF",
                      padding: "1.5rem",
                    }}
                  >
                    <p
                      className="black16 text-start"
                      style={{ fontWeight: "600" }}
                    >
                      Add your team members to the party (or rather to this job
                      post)
                    </p>
                    <div className="d-flex">
                      <p
                        style={{
                          marginTop: "1.5rem",
                          fontSize: "14px",
                          alignItems: "center",
                          gap: "1rem",
                          cursor: "pointer",
                          opacity: add ? "0.5" : "1",
                        }}
                        onClick={() => setAdd(true)}
                        className="pink16 d-flex"
                      >
                        <AddIcon /> Add team members.
                      </p>
                    </div>
                    {add && (
                      <div style={{ marginTop: "2.5rem" }}>
                        <div className="space flex-wrap">
                          <p className="thumbnailcreate">
                            Add Recruiting Team members (optional)
                          </p>
                        </div>
                        <div style={{ position: "relative" }}>
                          <form>
                            <input
                              type="search"
                              placeholder="Choose team member"
                              className="forminput"
                              style={{
                                marginTop: "0.75rem",
                                width: "100%",
                                display: "block",
                                paddingLeft: "2rem", // Adjust padding to accommodate the icon
                              }}
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </form>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            style={{
                              position: "absolute",
                              left: "0.5rem", // Adjust icon position
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                          >
                            <path
                              d="M17.4999 17.4999L14.5833 14.5832M16.6665 9.58321C16.6665 13.4952 13.4952 16.6665 9.58321 16.6665C5.67119 16.6665 2.49988 13.4952 2.49988 9.58321C2.49988 5.67119 5.67119 2.49988 9.58321 2.49988C13.4952 2.49988 16.6665 5.67119 16.6665 9.58321Z"
                              stroke="#667085"
                              strokeWidth="1.36482"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>

                        {list.length === 0 ? (
                          <>
                            <div
                              className="center"
                              style={{
                                marginTop: "0.75rem",
                                marginBottom: "0.75rem",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={EmptyState}
                                alt="EmptyState"
                                height="180px"
                                width="225px"
                              />
                              <p
                                className="pink16"
                                style={{ fontSize: "20px", fontWeight: "500" }}
                              >
                                No Results yet
                              </p>
                              <p
                                className="black14"
                                style={{ fontWeight: "400" }}
                              >
                                <span
                                  className="black14"
                                  style={{ fontWeight: "500" }}
                                >
                                  Invite your colleagues.
                                </span>{" "}
                                It’s for sure even more fun with you team here.
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ padding: "1.25rem" }}>
                              {list.map((doc, index) => (
                                <>
                                  <div
                                    className="space flex-wrap"
                                    key={index}
                                    style={{ gap: "1rem" }}
                                  >
                                    <div
                                      className="d-flex flex-wrap"
                                      style={{
                                        width: isNonMobile1024 ? "40%" : "",
                                        gap: "1.25rem",
                                      }}
                                    >
                                      <img
                                        src={doc.profilephoto}
                                        alt="profilephoto"
                                        className="shadowa"
                                        height="42px"
                                        width="42px"
                                        style={{
                                          borderRadius: "42px",
                                          background: "#FFF",
                                          padding: "2px",
                                        }}
                                      />

                                      <div>
                                        <p
                                          className="black14"
                                          style={{ fontWeight: "600" }}
                                        >
                                          {doc?.firstName} {doc?.lastName}
                                        </p>
                                        <p
                                          className="black12"
                                          style={{ marginTop: "0.25rem" }}
                                        >
                                          {doc?.email}
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        width: isNonMobile1024 ? "40%" : "",
                                      }}
                                    >
                                      <p
                                        className="black14"
                                        style={{ fontWeight: "600" }}
                                      >
                                        {doc?.jobTitle}
                                      </p>
                                      <p
                                        className="black12"
                                        style={{ marginTop: "0.25rem" }}
                                      >
                                        {JSON.parse(job?.company)?.title}
                                      </p>
                                    </div>
                                    <div
                                      className="d-flex"
                                      style={{
                                        width: isNonMobile1024 ? "5%" : "",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        className="d-flex"
                                        onClick={() => {
                                          handleAddUsers(doc.uniqueId);
                                        }}
                                      >
                                        {addedUsers.some(
                                          (user) => user === doc.uniqueId
                                        ) ? (
                                          <>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                            >
                                              <path
                                                d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                                                fill="#E31B54"
                                              />
                                            </svg>
                                          </>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M5.00521 1.66602H15.0052C16.8462 1.66602 18.3385 3.1584 18.3385 4.99935V14.9993C18.3385 16.8403 16.8462 18.3327 15.0052 18.3327H5.00521C3.16426 18.3327 1.67188 16.8403 1.67188 14.9993V4.99935C1.67188 3.1584 3.16426 1.66602 5.00521 1.66602ZM16.4783 16.4725C16.869 16.0818 17.0885 15.5519 17.0885 14.9993V4.99935C17.0885 4.44681 16.869 3.91691 16.4783 3.52621C16.0876 3.13551 15.5577 2.91602 15.0052 2.91602H5.00521C3.85461 2.91602 2.92188 3.84876 2.92188 4.99935V14.9993C2.92188 15.5519 3.14137 16.0818 3.53207 16.4725C3.92277 16.8632 4.45267 17.0827 5.00521 17.0827H15.0052C15.5577 17.0827 16.0876 16.8632 16.4783 16.4725Z"
                                              fill="#344054"
                                            />
                                          </svg>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {index !== list.length - 1 && (
                                    <>
                                      <hr
                                        style={{
                                          marginTop: "1.25rem",
                                          marginBottom: "1.25rem",
                                        }}
                                      />
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Details;
