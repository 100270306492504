import React, { useState } from "react";
import { toast } from "react-toastify";
import { Country } from "country-state-city";

const AddEducation = ({
  newEducation,
  setNewEducation,

  handleConfirm,
}) => {
  const fileInputRef = React.createRef();

  const allCountries = Country.getAllCountries();

  const [search, setSearch] = useState("");
  const [filteredLocations, setFilteredLocations] = useState([]);
  const handleCountrySearch = (e) => {
    setSearch(e.target.value);
    const filteredCountries = allCountries.filter((country) =>
      country.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredLocations(filteredCountries);
  };

  const handleSelectCountry = (location) => {
    setNewEducation((prev) => {
      return {
        ...prev,
        location: location,
      };
    });

    setSearch("");
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      setNewEducation((prev) => {
        return {
          ...prev,
          logo: file,
        };
      });
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div>
      <div className="mt-4">
        <p className="black14 mt-4">Institute name:</p>
        <input
          className="col-md-12 col-12 forminput mt-3"
          value={newEducation?.schoolName}
          placeholder="Enter institute Name"
          onChange={(e) => {
            setNewEducation((prev) => {
              return {
                ...prev,
                schoolName: e.target.value,
              };
            });
          }}
        />
        <p className="black14 mt-4">Institute Logo(optional):</p>
        <div className="mt-3 border p-4 rounded-3">
          {newEducation?.logo && (
            <div className="center">
              <img
                src={
                  newEducation?.logo instanceof File
                    ? URL.createObjectURL(newEducation?.logo)
                    : newEducation?.logo
                }
                alt="selectefFile"
                className="rounded-2"
                style={{ maxHeight: "200px", maxWidth: "200px" }}
              />
            </div>
          )}

          <div style={{ gap: "5px", justifyContent: "center" }}>
            <p
              className="text-center clicktoupload"
              onClick={openFileInput}
              style={{ cursor: "pointer" }}
            >
              {newEducation?.logo
                ? "Click to change Image"
                : "Click to upload Image"}
            </p>
            <p className="svgpngtext">SVG, PNG, JPG or GIF (max. 12MB)</p>
          </div>

          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
        </div>
        <p className="black14 mt-4">Degree/Subject:</p>
        <input
          className="col-md-12 col-12 forminput mt-3"
          value={newEducation?.degree}
          placeholder="Enter Degree/Subject"
          onChange={(e) => {
            setNewEducation((prev) => {
              return {
                ...prev,
                degree: e.target.value,
              };
            });
          }}
        />

        <p className="black14 mt-4">Country:</p>
        <input
          value={search}
          placeholder="Search Country"
          onChange={(e) => handleCountrySearch(e)}
          className="forminput col-md-12 col-12 mt-3"
        />
        <div className="d-flex">
          {" "}
          {newEducation?.location?.name && (
            <div className={`tagscard  mt-3`}>
              {" "}
              {newEducation?.location && newEducation?.location?.flag}
              <p className="itemstexts">{newEducation.location.name}</p>
            </div>
          )}
        </div>
        {filteredLocations.length > 0 && search && (
          <div
            className="rounded-2 d-flex flex-column tablescroll shadowa"
            style={{
              background: "#FFF",
              gap: "1rem",
              minWidth: "300px",
              maxHeight: "200px",
              padding: "1rem",
              overflow: "auto",
              zIndex: 3,
              marginTop: "0.75rem",
            }}
          >
            {filteredLocations.map((location) => (
              <p
                className="d-flex gap-3 black14"
                key={location.name}
                style={{ cursor: "pointer" }}
                onClick={() => handleSelectCountry(location)}
              >
                <span> {location.flag}</span>
                {location.name}
              </p>
            ))}
          </div>
        )}
        <p className="black14 mt-4">From:</p>
        <select
          className="col-md-12 col-12 forminput mt-3"
          value={newEducation.from}
          onChange={(e) => {
            setNewEducation((prev) => {
              return {
                ...prev,
                from: e.target.value,
              };
            });
          }}
        >
          <option value="">Select Year</option>
          {Array.from(
            { length: 50 },
            (_, i) => new Date().getFullYear() - i
          ).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>

        <p className="black14 mt-4">To:</p>
        <select
          className="col-md-12 col-12 forminput mt-3"
          value={newEducation.to}
          onChange={(e) => {
            setNewEducation((prev) => {
              return {
                ...prev,
                to: e.target.value,
              };
            });
          }}
        >
          <option value="" disabled>
            Select Year
          </option>
          <option value={"Present"}>Present</option>
          {Array.from(
            { length: 50 },
            (_, i) => new Date().getFullYear() - i
          ).map((year) => (
            <option
              key={year}
              value={year}
              disabled={year < parseInt(newEducation.from)}
            >
              {year}
            </option>
          ))}
        </select>

        <p className="black14 mt-4">Description:</p>
        <textarea
          className="col-md-12 col-12 forminput mt-3"
          value={newEducation.description}
          placeholder="Explain your experience there"
          style={{ minHeight: "200px" }}
          onChange={(e) => {
            setNewEducation((prev) => {
              return {
                ...prev,
                description: e.target.value,
              };
            });
          }}
        />
        <button
          className="continuebutton mt-4 mb-5"
          onClick={() => handleConfirm()}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default AddEducation;
