const GetTimeRange = ({ datetime, duration }) => {
  const startTime = new Date(datetime);

  // Parse the duration and convert to minutes
  let durationMinutes = 0;
  if (duration === "30 mins") {
    durationMinutes = 30;
  } else if (duration === "1 hour") {
    durationMinutes = 60;
  } else if (duration === "1:30 hour") {
    durationMinutes = 90;
  } else if (duration.includes("hour")) {
    const [hours, mins] = duration.split(":");
    durationMinutes = parseInt(hours) * 60 + parseInt(mins) || 0;
  }

  // Calculate end time by adding duration to start time
  const endTime = new Date(startTime.getTime() + durationMinutes * 60000);

  // Format times as HH.MM
  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes() === 0 ? ":00" : date.getMinutes();
    return `${hours}.${minutes}`;
  };

  return `${formatTime(startTime)}-${formatTime(endTime)}`;
};

export default GetTimeRange;
