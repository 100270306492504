import React, { useState, useRef, useEffect } from "react";

import { useMediaQuery } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import { FixedSizeList as List } from "react-window";
import uniicon from "../../../../assets/images/uniicon.png";
import { setUni } from "../../../../state";
import { useDispatch } from "react-redux";
const SelectUni = ({
  selectedUni,
  setSelectedUni,
  unilist,

  loading,

  Countries,
  country,
  setCountry,
  setStep,
}) => {
  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  const [showUni, setShowUni] = useState(false);
  const [search, setSearch] = useState(null);
  const dispatch = useDispatch();

  const [changeLocation, ShowChangeLocation] = useState(country ? false : true);
  const unilistinputdivRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        unilistinputdivRef.current &&
        !unilistinputdivRef.current.contains(event.target)
      ) {
        setShowUni(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUniClick = () => {
    setShowUni(!showUni);
  };

  const isNonMobile = useMediaQuery("(min-width:768px)");

  const [searchList, setSearchList] = useState(unilist);
  useEffect(() => {
    setSearchList(unilist);
  }, [unilist]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        unilistinputdivRef.current &&
        !unilistinputdivRef.current.contains(event.target)
      ) {
        setShowUni(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [countrySearchList, SetCountriesList] = useState(Countries);
  const filterCountries = () => {
    if (!search) {
      SetCountriesList(Countries);
    } else {
      const filteredUniversities = Countries.filter((uni) =>
        uni.name.toLowerCase().includes(search.toLowerCase())
      );
      SetCountriesList(filteredUniversities);
    }
  };
  useEffect(() => {
    filterCountries();
  }, [search]);

  const filterUniversities = () => {
    if (!search) {
      setSearchList(unilist);
    } else {
      const filteredUniversities = unilist.filter((uni) =>
        uni.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchList(filteredUniversities);
    }
  };

  useEffect(() => {
    filterUniversities();
  }, [search]);

  const isValidImage = async (base64String) => {
    try {
      await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve();
        img.onerror = () => reject();
        img.src = `${base64String}`;
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const ValidateLogo = async (base64String) => {
    return await isValidImage(base64String);
  };

  const renderUniversityItem = ({ index, style }) => {
    const uni = searchList[index];
    return (
      <div
        key={index}
        className="d-flex unilists "
        style={{
          ...style,
          gap: "0.5rem",
          width: "97%",

          alignItems: "center",
        }}
        onClick={() => {
          setSelectedUni(uni);
          setShowUni(false);
          dispatch(setUni({ Uni: uni }));
        }}
      >
        {uni.logo && ValidateLogo(uni.logo) ? (
          <img
            src={`${uni.logo}`}
            alt="University Logo"
            style={{ width: "40px", height: "40px", objectFit: "contain" }}
            className="Interest_img_icon shadowa rounded-5"
          />
        ) : (
          <img
            src={uniicon}
            alt="University Logo"
            style={{ width: "40px", height: "40px", objectFit: "contain" }}
            className="Interest_img_icon shadowa rounded-5"
          />
        )}
        <p className="uniselecttext d-flex" style={{ alignItems: "center" }}>
          {uni.name}
        </p>
      </div>
    );
  };

  const renderUniversityList = () => (
    <List
      height={150}
      itemCount={searchList.length}
      itemSize={isNonMobile ? 55 : 70}
      width={"100%"}
      className="overflowx whitescrollbar"
    >
      {renderUniversityItem}
    </List>
  );

  return (
    <div className="w-full">
      <p className="sectionheadingclamped" style={{ marginBottom: "1.5rem" }}>
        Select your university
      </p>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          position: "relative",

          gap: "1rem",

          alignItems: "center",
        }}
        className="mt-4"
      >
        <div
          className="unilistinputdiv d-flex"
          ref={unilistinputdivRef}
          onClick={handleUniClick}
          style={{ cursor: "pointer", background: "#FFF" }}
        >
          <div className="uniselect">
            {selectedUni &&
            selectedUni.logo &&
            ValidateLogo(selectedUni.logo) ? (
              <img
                src={`${selectedUni.logo}`}
                className="rounded-3"
                alt={uniicon}
                style={{
                  objectFit: "contain",
                  maxWidth: isNonMobile1440 ? "40px" : "32px",
                  maxHeight: isNonMobile1440 ? "40px" : "32px",
                }}
              />
            ) : (
              <div
                className={`rounded-5 d-flex ${
                  isNonMobile1440 ? "p-2" : "p-1"
                } notselecteduni `}
                style={{
                  height: isNonMobile1440 ? "40px" : "32px",
                  width: isNonMobile1440 ? "40px" : "32px",
                  backgroundColor: country ? "#FFF" : "",
                }}
              >
                {country && country.icon ? (
                  <img
                    src={country.icon}
                    alt={uniicon}
                    className="rounded-3"
                    style={{
                      objectFit: "contain",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                ) : (
                  <SchoolIcon
                    style={{
                      color: "white",
                    }}
                  />
                )}
              </div>
            )}

            <p className="uniselecttext">
              {selectedUni
                ? selectedUni.name
                : "Select your university to register"}
            </p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M5 7.5L10 12.5L15 7.5"
              stroke="#1D2939"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        {showUni && (
          <div
            ref={unilistinputdivRef}
            style={{
              position: "absolute",
              overflow: "hidden",
              top: "0",
              zIndex: 10,
            }}
            className="showUnilistmodal "
          >
            <div className="paddingsinsearchlist">
              <div className="searchinput">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
                    stroke="#475467"
                    strokeWidth="1.70603"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                  className="searchinputuni"
                />
              </div>
              <hr style={{ width: "97%" }} />
              <div
                className=""
                style={{ overflow: "auto", maxHeight: "20.438rem" }}
              >
                {changeLocation ? (
                  countrySearchList.map((con, index) => (
                    <div
                      key={index} // Make sure to add a unique key when mapping
                      className="d-flex unilists"
                      style={{ gap: "0.5rem", marginTop: "1.25rem" }}
                      onClick={() => {
                        setCountry(con);
                        ShowChangeLocation(false);
                        setSearch(""); // Assuming 'con' is the selected university in this context
                      }}
                    >
                      <img
                        src={con.icon}
                        alt="uni logo"
                        height="40px"
                        width="40px"
                        className="Interest_img_icon rounded-5"
                      />
                      <p
                        className="uniselecttext d-flex"
                        style={{ alignItems: "center" }}
                      >
                        {con.name}
                      </p>
                    </div>
                  ))
                ) : (
                  <>
                    {loading ? (
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "1rem",
                            fontFamily: "Poppins",
                            fontWeight: "500",
                          }}
                        >
                          Fetching Universities
                        </p>
                        <div class="loader"></div>
                      </div>
                    ) : (
                      <>{renderUniversityList()}</>
                    )}
                  </>
                )}
              </div>
              <hr style={{ width: "97%" }} />
            </div>
            <div className="bottomsearchlist">
              <div
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div className="countrydisplay">
                  {country ? (
                    <>
                      <img
                        src={country.icon}
                        alt="country flag"
                        width="16px"
                        height="16px"
                      />
                      {country.name}
                    </>
                  ) : (
                    "select country"
                  )}
                </div>
                <button
                  className="changelocationbutton btn p-0"
                  onClick={() => ShowChangeLocation(true)}
                >
                  {" "}
                  Change Location
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <p className="didnt" style={{ marginTop: "0.75rem" }}>
        Cant find your university?{" "}
        <span
          onClick={() => {
            setStep(2);
          }}
        >
          Click here to add
        </span>
      </p>
    </div>
  );
};

export default SelectUni;
