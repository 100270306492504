import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/Company";
const InviteRegister = () => {
  const { dsa } = useParams();
  const { company } = useParams();
  const { user } = useParams();
  const Navigate = useNavigate();
  const [role, setRole] = useState(() => {
    if (dsa === "fadsdgaskgjfdasfdasjfkalas") {
      return "Recruiter";
    } else if (dsa === "jkfdhgjkhdjkhgdsjkjdksjfkjds") {
      return "Team";
    } else if (dsa === "jkfdhgjkhdjkhgdsjkjfadsdfasfasdksjfkjds") {
      return "Representative";
    }
  });

  useEffect(() => {
    if (!dsa || !company || !user || !role) {
      Navigate("/employer/register");
    }
  }, []);

  return (
    <div>
      <Banner role={role} company={company} user={user} dsa={dsa} />
    </div>
  );
};

export default InviteRegister;
