import ChallengeType from "../MainDashBoard/type";

const Third = () => {
  const types = ["Fast-Track", "Virtual Experience", "Insights"];
  return (
    <div style={{ padding: "8.125rem 0rem" }}>
      <div className="marginsleftrightCompany">
        <div className="center">
          <div className="headerCompany">
            <div className="center align-items-center flex-column">
              <p
                className="text-center itsearly "
                style={{ maxWidth: "534px", width: "100%" }}
              >
                Attract and engage talent with{" "}
                <span className="pinkspan">3 different</span> challenge types.
              </p>
              <p
                className="text-center gray95020400"
                style={{
                  maxWidth: "520px",
                  margin: "1rem 0rem",
                  fontFamily: "Poppins",
                }}
              >
                With{" "}
                <span
                  className="gray95020400"
                  style={{ fontFamily: "Poppins", fontWeight: 500 }}
                >
                  challenges
                </span>{" "}
                you attract and engage more early talent without visiting more
                campuses.
              </p>
              <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                {types.map((ty, index) => (
                  <div key={index}>
                    <ChallengeType type={ty} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Third;
