import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Details from "./Details";
import Notifications from "./Notifications";
import Privacy from "./Privacy";
import Password from "./Password";
import axios from "axios";

const StudentSettings = ({ user }) => {
  const [selected, setSelected] = useState("My Details");
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:767px)");

  const [User, setUser] = useState(null);

  return (
    <div className="marginsleftrightCompany marginsStudent">
      <div className="center">
        <div className="headerCompany">
          <div className="paddingmaincontent">
            <p className="studentHeading">Settings</p>
            <p
              className="gray60011400"
              style={{ fontSize: "14px", marginTop: "0.25rem" }}
            >
              Here you can change your personal settings.
            </p>
            <div>
              <div
                className={`transition ${isNonMobile && "d-flex"}  `}
                style={{
                  justifyContent: "space-between",
                  flexFlow: "wrap",
                  gap: "10px",
                  overflow: "auto",
                  marginTop: "30px",
                }}
              >
                <div
                  className=" behindbuttons "
                  style={{ minWidth: "430px", overflow: "auto" }}
                >
                  <button
                    className={`  border-0  ${
                      selected === "My Details"
                        ? "selectedbutton rounded-2 "
                        : "secondbuttontext"
                    } ${isNonMobile ? "" : "p-1  "}`}
                    style={
                      isNonMobile
                        ? {
                            minWidth: "188px",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected("My Details");
                    }}
                  >
                    My Details
                  </button>
                  <button
                    className={` border-0   ${
                      selected === "Notifications"
                        ? "selectedbutton rounded-2 "
                        : "secondbuttontext"
                    } ${isNonMobile ? "" : "p-1  "}`}
                    style={isNonMobile ? { minWidth: "188px" } : {}}
                    onClick={() => {
                      setSelected("Notifications");
                    }}
                  >
                    Notifications
                  </button>

                  <button
                    className={`   border-0 ${
                      selected === "Privacy"
                        ? "selectedbutton rounded-2 "
                        : "secondbuttontext"
                    } ${isNonMobile ? "" : "p-1  "}`}
                    style={isNonMobile ? { minWidth: "188px" } : {}}
                    onClick={() => {
                      setSelected("Privacy");
                    }}
                  >
                    Privacy
                  </button>
                  <button
                    className={`   border-0 ${
                      selected === "Password"
                        ? "selectedbutton rounded-2 "
                        : "secondbuttontext"
                    } ${isNonMobile ? "" : "p-1  "}`}
                    style={isNonMobile ? { minWidth: "188px" } : {}}
                    onClick={() => {
                      setSelected("Password");
                    }}
                  >
                    Password
                  </button>
                </div>
              </div>
            </div>
            {!user && (
              <div className="center">
                <div className="mt-5 loader" />{" "}
              </div>
            )}
            <div style={{ marginTop: "2.5rem" }}>
              {selected === "My Details" && user && <Details user={user} />}
              {selected === "Notifications" && user && (
                <Notifications user={user} />
              )}
              {selected === "Privacy" && user && <Privacy user={user} />}
              {selected === "Password" && <Password />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StudentSettings;
