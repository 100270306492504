import { useState, useEffect } from "react";
import "./createjob.css";
import CreateJobHeader from "./header";
import Step1 from "./Step1";
import { useMediaQuery } from "@mui/material";

import ReactQuill from "react-quill";
import { motion, useAnimate } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import axios from "axios";
import { useSelector } from "react-redux";
import Step7 from "./Step7";
import NewChips from "./NewChipsStep";
const CreateJob = () => {
  const edit = false;
  const [step, setSteps] = useState(1);
  const [url, seturl] = useState("");
  const isNonMobile = useMediaQuery("(min-width:768px)");
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState(null);

  const [country, setCountry] = useState([{ Country: "", City: [] }]);
  const [user, setUser] = useState("");
  const email = useSelector((state) => state.uniqueId);
  const [position, setPosition] = useState("");
  useEffect(() => {
    setCountry([{ Country: company?.country[0]?.Country ?? "", City: [] }]);
  }, [company]);

  const [workStudy, setWorkStudy] = useState(false);
  const [jobDescription, setJobDescription] = useState("");
  const [timeRequirement, setTimeRequirement] = useState("Full time");
  const [employmentType, setEmploymentType] = useState("Permanent");
  const [estimatedStartDate, setEstimatedStartDate] = useState("");
  const [estimatedEndDate, setEstimatedEndDate] = useState("");
  const [duration, setDuration] = useState("");
  const [hours, setHours] = useState("");
  const [perHour, setPerHour] = useState("Week");
  const [locationType, setLocationType] = useState("Onsite");
  const [addedUsers, setAddedUsers] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [studyLevel, setStudyLevel] = useState("All");
  const [relativeSkills, setRelativeSkills] = useState([]);
  const [language, setLanguage] = useState();
  const [applicationOpenDate, setApplicationOpenDate] = useState("");
  const [applicationCloseDate, setApplicationCloseDate] = useState("");
  const [method, setMethod] = useState("On a separate website");
  const [defaults, setDefault] = useState(false);
  const [requiredDocuments, setRequiredDocuments] = useState([
    {
      name: "varyance student profile",
      value: true,
    },
    {
      name: "CV",
      value: false,
    },
    {
      name: "Cover letter",
      value: false,
    },
    {
      name: "Transcript",
      value: false,
    },
    {
      name: "Other",
      value: false,
    },
  ]);
  const [emailSetting, setEmailSetting] = useState([
    {
      name: "Send summary email once application period closes",
      value: true,
    },
    {
      name: "Send monthly summary email",
      value: true,
    },
    {
      name: "Send email when a candidate applies",
      value: false,
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let id;
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: email }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
          id = userInfoResponse.data.user.company;
        } else {
          navigate("/register/organiser");
          return; // Exit early if user not found
        }

        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: id }
        );
        setCompany(companyInfoResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [email]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [step]);

  const navigate = useNavigate();
  const handleDraftSubmit = async () => {
    try {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/create/Job/draft`, {
          title,
          position,
          workStudy,
          jobDescription,
          timeRequirement,
          employmentType,
          duration,
          hours,
          url,
          perHour,
          locationType,
          addedUsers,
          selectedItem,
          studyLevel,
          relativeSkills,
          language,
          applicationOpenDate,
          applicationCloseDate,
          method,
          defaults,
          requiredDocuments,
          emailSetting,
          user,
          country,
          company,
          estimatedStartDate,
          estimatedEndDate,
        })
        .then((res) => {
          if (res.data.msg === "success") {
            navigate("/dashboard/jobposts");
            toast.success("Job Post saved in Draft");
          } else {
            toast.error("Error Creating Job Post");
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/create/Job`, {
          title,
          position,
          workStudy,
          jobDescription,
          timeRequirement,
          employmentType,
          duration,
          hours,
          perHour,
          url,
          locationType,
          addedUsers,
          selectedItem,
          studyLevel,
          relativeSkills,
          language,
          applicationOpenDate,
          applicationCloseDate,
          method,
          defaults,
          requiredDocuments,
          emailSetting,
          user,
          country,
          company,
          estimatedStartDate,
          estimatedEndDate,
        })
        .then((res) => {
          if (res.data.msg === "success") {
            navigate("/dashboard/jobposts");
            toast.success("Job Post Created Successfully");
          } else {
            toast.error("Error Creating Job Post");
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  let lastStep = 7;
  let width = Math.floor(step * (100 / lastStep));

  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (scope?.current) {
      animate(scope?.current, { width: `${width}%` }, { duration: 0.3 });
    }
  }, [step]);
  return (
    <div className="fullscreenheightwidth">
      <CreateJobHeader handleDraftSubmit={handleDraftSubmit} edit={edit} />
      <motion.div
        ref={scope}
        className="ProgressBarForm"
        style={{
          width: `${width}%`,
          position: "relative",
          zIndex: 10,
          top: "",
        }}
      ></motion.div>
      <div className="d-flex">
        <div
          className="basicInforMationPADDINGS center"
          style={{
            width: "100%",
          }}
        >
          {" "}
          <div
            style={{
              maxWidth: step <= 5 ? "700px" : "",
              minWidth: step <= 5 && isNonMobile ? "700px" : "",
            }}
          >
            {step === 1 && (
              <Step1
                title={title}
                setTitle={setTitle}
                position={position}
                setPosition={setPosition}
                workStudy={workStudy}
                setWorkStudy={setWorkStudy}
                jobDescription={jobDescription}
                setJobDescription={setJobDescription}
                ReactQuill={ReactQuill}
                navigate={navigate}
                setSteps={setSteps}
                toast={toast}
              />
            )}
            {step === 2 && (
              <Step2
                timeRequirement={timeRequirement}
                setTimeRequirement={setTimeRequirement}
                employmentType={employmentType}
                setEmploymentType={setEmploymentType}
                duration={duration}
                setDuration={setDuration}
                isNonMobile={isNonMobile}
                estimatedStartDate={estimatedStartDate}
                setEstimatedStartDate={setEstimatedStartDate}
                estimatedEndDate={estimatedEndDate}
                setEstimatedEndDate={setEstimatedEndDate}
                setSteps={setSteps}
                hours={hours}
                setHours={setHours}
                perHour={perHour}
                setPerHour={setPerHour}
              />
            )}
            {step === 3 && (
              <Step3
                setSteps={setSteps}
                locationType={locationType}
                setLocationType={setLocationType}
                isNonMobile={isNonMobile}
                country={country}
                setCountry={setCountry}
              />
            )}
            {step === 4 && (
              <Step4
                setSteps={setSteps}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                setLearns={setRelativeSkills}
                learns={relativeSkills}
                tools={studyLevel}
                setTools={setStudyLevel}
                setSelectedLanguage={setLanguage}
              />
            )}
            {step === 5 && (
              <NewChips
                setSteps={setSteps}
                setLearns={setRelativeSkills}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                learns={relativeSkills}
                tools={studyLevel}
                setTools={setStudyLevel}
                setSelectedLanguage={setLanguage}
              />
            )}
            {step === 6 && (
              <Step5
                setSteps={setSteps}
                applicationOpenDate={applicationOpenDate}
                setApplicationOpenDate={setApplicationOpenDate}
                applicationCloseDate={applicationCloseDate}
                setApplicationCloseDate={setApplicationCloseDate}
                method={method}
                setMethod={setMethod}
                requiredDocuments={requiredDocuments}
                setRequiredDocuments={setRequiredDocuments}
                seturl={seturl}
                url={url}
              />
            )}
            {step === 7 && (
              <Step6
                setSteps={setSteps}
                title={title}
                position={position}
                company={company}
                locationType={locationType}
                country={country}
                timeRequirement={timeRequirement}
                employmentType={employmentType}
                relativeSkills={relativeSkills}
                studyLevel={studyLevel}
                selectedItem={selectedItem}
                language={language}
                jobDescription={jobDescription}
                isNonMobile1024={isNonMobile1024}
              />
            )}
            {step === 8 && (
              <Step7
                setSteps={setSteps}
                user={user}
                title={title}
                position={position}
                company={company}
                emailSetting={emailSetting}
                setEmailSetting={setEmailSetting}
                defaults={defaults}
                setDefault={setDefault}
                isNonMobile1024={isNonMobile1024}
                addedUsers={addedUsers}
                setAddedUsers={setAddedUsers}
                handleSubmit={handleSubmit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateJob;
