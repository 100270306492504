import { useState } from "react";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

const Questions = () => {
  const questions = [
    {
      text: "Which of your challenges best prepares me for this internship - skill wise?",
      upvotes: 28,
      true: false,
    },
    {
      text: "How does your company handle diversity and inclusion in the workplace?",
      upvotes: 25,
      true: false,
    },
    {
      text: "With rising demand for sustainability, how will you innovate its supply chain and product to set new industry standards as a responsible company while staying profitable?",
      upvotes: 24,
      true: false,
    },
    {
      text: "What specific skills or experiences do you look for in internship candidates and which Challenges should I work on?",
      upvotes: 15,
      true: false,
    },
    {
      text: "What is the hiring process like, and how can we best prepare for it?",
      upvotes: 15,
      true: false,
    },
    {
      text: "What kind of projects or initiatives is the company currently focusing on?",
      upvotes: 15,
      true: false,
    },
    {
      text: "What makes your internship program stand out from those offered by other companies?",
      upvotes: 11,
      true: false,
    },
  ];

  return (
    <div style={{ maxHeight: "100%" }}>
      <p className="filmo16">Most asked Questions</p>
      {questions.length > 0 &&
        questions.map((que, index) => (
          <div key={index} style={{ marginTop: "1.125rem" }}>
            <div className="selectedview-questions-inner">
              <p className="headersmalltext">{que.text}</p>
            </div>
            <div style={{ marginTop: "0.5rem" }}>
              <div
                className="d-flex align-items-center "
                style={{ gap: "0.5rem" }}
              >
                <FavoriteBorderOutlinedIcon
                  style={{ height: "20px", width: "20px" }}
                />

                <p className="namecountrya">{que.upvotes} Upvotes</p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Questions;
