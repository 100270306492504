import { useState } from "react";
import First from "./First";

import Second from "./Second";
import "./home.css";
import { useNavigate } from "react-router-dom";
import Third from "./Third";
import finance from "../../assets/images/icons/Finance.png";

import book from "../../assets/images/book.png";
import trophy from "../../assets/images/trophy.png";
import user from "../../assets/images/user.png";
import search from "../../assets/images/searcha.png";
import briefcase from "../../assets/images/briefcase.png";
import building from "../../assets/images/building.png";

import video1 from "../../assets/videos/animation1.mp4";
import video2 from "../../assets/videos/animation2.mp4";
import video3 from "../../assets/videos/animation3.mp4";
import video4 from "../../assets/videos/animation4.mp4";

import p1 from "../../assets/images/p1.png";
import p2 from "../../assets/images/newp2.png";
import p3 from "../../assets/images/newp3.png";
import p4 from "../../assets/images/newp4.png";
import p5 from "../../assets/images/newp5.png";

import p6 from "../../assets/images/newu1.png";
import p7 from "../../assets/images/newu2.png";
import p8 from "../../assets/images/newu3.png";
import p9 from "../../assets/images/newu4.png";
import p10 from "../../assets/images/newu5.png";

import SliderSec from "./Slider";

import NewComp from "./NewComp";
import HomeFooter from "../../components/Home/HomeFooter";
import UpDownMarquee from "./UpDownMarque";
import MarqueSection from "./Marquee";

const NewHome = () => {
  const [selectedUni, setSelectedUni] = useState("");

  const navigate = useNavigate();

  const skills = [
    {
      name: "Internship",
      icon: trophy,
    },
    {
      name: "Full-Time",
      icon: briefcase,
    },
    {
      name: "Thesis",
      icon: user,
    },
    {
      name: "Research Grant",
      icon: building,
    },
    {
      name: "Insights",
      icon: search,
    },
    { name: "Pre-Skilling", icon: book },
  ];

  const secondskills = [
    {
      name: "Finance",
      icon: finance,
    },
    {
      name: "Critical Thinking",
    },
    {
      name: "Data Interpretation",
    },
    {
      name: "Company Analysis",
    },
    {
      name: "DFC Analysis",
    },
    { name: "M&A Screening" },
    { name: "M&A Process Understanding" },
  ];
  const sections = [
    {
      heading: "Complete challenges to fast-track your application",
      description:
        "Challenges let you explore potential employers, expand your skills and ultimately gives you an edge for your job or internship application.",
      chips: skills,
      type: "seedha",

      video: video1,
    },
    {
      heading: `Learn and expand <span class="pinkspan">skills</span> relevant to your future career`,
      type: "ulta",
      description:
        "Earn points for skills gained, showcase your abilities to future employers, and receive badges for outstanding achievements in your career field.",
      chips: secondskills,

      explain: "*Example Skills for a Finance Challenge",
      video: video2,
    },
    {
      heading: `Connect <span class="pinkspan">directly</span> with current interns and campus recruiters`,
      description:
        "Skip formal emails and connect directly with recruiters and alumni from your dream company to start the conversation.",
      type: "seedha",

      pics: [p1, p2, p3, p4, p5],
      extra: "Connected via",
      extra2: "Messenger",
      video: video3,
    },
    {
      heading: "Join student organisations and groups based on your interests",
      description:
        "Millions of students want to break into similar career fields. Join the conversation to get job insights or simply sell your dusty GMAT books.",
      type: "ulta",

      pics: [p6, p7, p8, p9, p10],
      extra: "Join via",
      extra2: "Groups",
      video: video4,
    },
  ];

  return (
    <NewComp>
      <First setSelectedUni={setSelectedUni} selectedUni={selectedUni} />
      <Second
        navigate={navigate}
        setSelectedUni={setSelectedUni}
        selectedUni={selectedUni}
      />
      {sections.map((section, index) => (
        <div key={index}>
          <Third
            scale={index === 0 ? true : false}
            heading={section?.heading}
            description={section?.description}
            chips={section?.chips}
            type={section?.type}
            img={section?.img}
            explain={section?.explain}
            pics={section?.pics ?? []}
            extra={section?.extra}
            extra2={section?.extra2}
            video={section?.video}
          />
        </div>
      ))}
      <SliderSec />
      <MarqueSection
        selectedUni={selectedUni}
        setSelectedUni={setSelectedUni}
      />

      <div className="" style={{ background: "#0C111D" }}>
        <div className="marginsleftrightCompany">
          <div className="center">
            <div className="headerCompany">
              <HomeFooter />
            </div>
          </div>
        </div>
      </div>
    </NewComp>
  );
};
export default NewHome;
