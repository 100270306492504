import { useEffect, useState } from "react";
import Comment from "./Comments";

const PostComments = ({
  comments,

  getTimeElapsed,
  student,
  token,
  post,
  setComments,
  setTotalComments,

  handleReportComment,
}) => {
  const [commentstoshow, setCommentsToShow] = useState(
    comments ? comments : []
  );

  useEffect(() => {
    if (comments && commentstoshow) {
      setCommentsToShow(comments);
    }
  }, [comments, commentstoshow]);

  return (
    <div>
      {commentstoshow && (
        <div
          className="d-flex flex-column"
          style={{
            marginTop: "20px",
            gap: "1.25rem",
          }}
        >
          {commentstoshow.map((comment, index) => (
            <div key={index}>
              <Comment
                comment={comment}
                getTimeElapsed={getTimeElapsed}
                student={student}
                token={token}
                post={post}
                setComments={setComments}
                setCommentsToShow={setCommentsToShow}
                setTotalComments={setTotalComments}
                handleReportComment={handleReportComment}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PostComments;
