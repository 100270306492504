import img1 from "../../assets/images/employer1.png";
import img2 from "../../assets/images/eventsliderimg.png";
import img3 from "../../assets/images/jobslideimg.png";
import img4 from "../../assets/images/messengerslideimg.png";
import EventIcon from "../../assets/images/icons/EventIcon";
import FeedIcon from "../../assets/images/icons/Feed";
import NonMobile from "../../components/NonMobile/NonMobile";
import Slider from "react-slick";
import JobIcon from "../../assets/images/icons/JobsIcon";
import ChatIcon from "../../assets/images/icons/ChatIcon";
import Section2Component from "../../components/CompanyHome/section2component";
const TitleDescMarquee = ({ toRemove = "" }) => {
  const {
    isNonMobile1024,
    isNonMobile500,
    isNonMobile1271,
    isNonMobile1250,
    isNonMobile1190,
    isNonMobile1100,
  } = NonMobile();

  const slides1 = [
    {
      img: img1,
      icon: <FeedIcon color={"#079455"} />,
      title: "Analytics",
      desc: "Capture student engagement data across all touchpoints.",
      button: {
        name: "Learn more",
        link: "/employer/analytics",
      },
    },
    {
      img: img2,
      icon: <EventIcon color={"#FAA24B"} />,
      title: "Events",
      desc: "Host events where students engage, learn and connect with your company.",
      button: {
        name: "Learn more",
        link: "/employer/events",
      },
    },
    {
      img: img3,
      icon: <JobIcon color={"#383874"} />,
      title: "Job Posts",
      desc: "Post jobs that connect top talent with your company's opportunities.",
      button: {
        name: "Learn more",
        link: "/employer/events",
      },
    },
    {
      img: img4,
      icon: <ChatIcon />,
      title: "Messenger",
      desc: "Chat with students instantly to build connections and answer questions.",
      button: {
        name: "Learn more",
        link: "/employer/events",
      },
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,

    centerPadding: isNonMobile1271
      ? "15%"
      : isNonMobile1250
      ? "11%"
      : isNonMobile1190
      ? "9%"
      : isNonMobile1100
      ? "5%"
      : "0%",
  };

  return (
    <div className="overflowshown">
      <div className="marginsleftrightCompany ">
        <div className="center">
          <div className="headerCompany">
            <div className="d-flex flex-column gap-3 align-items-center">
              <h2
                className="text-center filmo30400"
                style={{ maxWidth: "620px" }}
              >
                Strengthen your{" "}
                <span className="filmo30400" style={{ color: "#E31b54" }}>
                  early talent strategy
                </span>{" "}
                by using our full suite of solutions
              </h2>
              <p
                className="gray95020400 text-center"
                style={{ fontFamily: "Poppins", maxWidth: "700px" }}
              >
                Combine challenges, job posts, events, and analytics to connect
                with students at every step of their journey, giving them a real
                sense of your company and the opportunities you offer.{" "}
              </p>
            </div>
            <div style={{ marginTop: "1.5rem" }}>
              {isNonMobile1024 ? (
                <Slider {...settings} className="dsadasasd">
                  {slides1
                    .filter((sl) => sl.title !== toRemove)
                    .map((slide, index) => (
                      <div key={index} className="d-flex">
                        <div
                          className="studentslidercard1 d-flex"
                          style={{
                            borderRadius: "8px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={slide?.img}
                            alt="1"
                            style={{
                              height: "100%",
                              width: "57.24%",
                              maxHeight: "483px",
                              objectFit: "cover",
                            }}
                          />
                          <div
                            className="center align-items-center"
                            style={{
                              background: "#FFF",
                              height: "100%",
                              padding: "2.5rem",
                            }}
                          >
                            <div className="d-flex flex-column ">
                              {slide.icon}
                              <p
                                className="gray95020400"
                                style={{ marginTop: "1.25rem" }}
                              >
                                {" "}
                                {slide.title}
                              </p>
                              <p
                                className="gray60011400"
                                style={{
                                  marginTop: "0.75rem",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                }}
                              >
                                {" "}
                                {slide.desc}
                              </p>
                              <a
                                className="pink16"
                                href={slide.button.link}
                                style={{
                                  textDecoration: "none",
                                  marginTop: "0.75rem",
                                }}
                              >
                                {" "}
                                {slide.button.name}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div style={{ width: "1rem" }} />
                      </div>
                    ))}
                </Slider>
              ) : (
                <Section2Component
                  slides={slides1.filter((sl) => sl.title !== toRemove)}
                  isNonMobile500={isNonMobile500}
                  maxHeight={"500px"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleDescMarquee;
