import axios from "axios";
import { useEffect, useState } from "react";
import CompanyImage from "../../components/Company/CompanyImage";
import EastIcon from "@mui/icons-material/East";

const TrendingGroups = ({ setEmptyGroups, link = null }) => {
  const [groups, setGroups] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchGroups = async () => {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/get/trending/groups`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.msg === "success") {
          setGroups(res.data?.groups?.filter((group) => group?.link !== link));
          setEmptyGroups(
            res.data?.groups?.filter((group) => group?.link !== link)?.length >
              0
              ? false
              : true
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <div
      style={{
        background: "#FFF",
        padding: "1rem",
        borderRadius: "5px",
        width: "100%",
        height: "fit-content",
      }}
    >
      <p className="gray95016500">Trending Groups</p>

      <div
        className="d-flex flex-column "
        style={{ gap: "1.5rem", marginTop: "1.5rem" }}
      >
        {groups.map((group, index) => (
          <div key={index} className="d-flex gap-2">
            <CompanyImage src={group?.profilePhoto} />
            <div>
              <p className="gray95014400" style={{ fontWeight: 500 }}>
                {group?.title}
              </p>

              <div className="d-flex flex-wrap gap-2 align-items-center mt-2">
                <div className="d-flex align-items-center">
                  {group?.profilePhotos.map((photo, index) => (
                    <img
                      src={photo}
                      alt="memberspic"
                      key={index}
                      style={{
                        height: "24px",
                        width: "24px",
                        position: "relative",
                        marginLeft: index === 0 ? "" : "-10px",
                        zIndex: index,
                        borderRadius: "50%",
                        background: "#FFF",
                        border: "1px solid #FFF",
                      }}
                    />
                  ))}
                </div>
                <p className="gray70014400" style={{ fontSize: "12px" }}>
                  {group?.members?.length + 1 >= 1000
                    ? ((group?.members?.length + 1) / 1000).toFixed(1) + "k"
                    : group?.members?.length + 1}{" "}
                  {group?.members?.length + 1 === 1 ? "Member" : "Members"}
                </p>
              </div>
              <a
                href={`/student/group/${group?.link}`}
                style={{ textDecoration: "none" }}
              >
                <p
                  className="mt-2 pink14 hovercolorback"
                  style={{ fontWeight: 500 }}
                >
                  View Group
                </p>
              </a>
            </div>
          </div>
        ))}
      </div>
      {loading && (
        <div className="center">
          <div className="loader" />
        </div>
      )}
      {link && groups.length >= 4 && (
        <a style={{ textDecoration: "none" }} href={`/student/Groups`}>
          <div
            className="d-flex gap-1 pink14 hovercolorback align-items-center"
            style={{ fontWeight: 500, marginTop: "1.25rem" }}
          >
            See All Groups
            <EastIcon style={{ height: "20px", width: "30px" }} />
          </div>
        </a>
      )}
    </div>
  );
};
export default TrendingGroups;
