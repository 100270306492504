import { useEffect, useState } from "react";
import Graduate from "../../assets/images/icons/Graduate.png";
import SelectOption from "../../components/Input/Select";
const Step3 = ({
  isNonMobile,

  intake,
  setIntake,
}) => {
  const currentYear = new Date().getFullYear();

  const options = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [month, setMonth] = useState(intake?.Month ?? "");
  const [year, setYear] = useState(intake.Year ?? "");

  useEffect(() => {
    setIntake({ Month: month, Year: year });
  }, [month, year]);

  return (
    <div className={`${isNonMobile ? "mt-4" : "mt-3"} `}>
      <div className="d-flex">
        <div
          className="center align-items-center p-2 rounded-5"
          style={{ background: "#F2F4F7" }}
        >
          <img
            src={Graduate}
            alt="Graduate"
            style={{ height: "36px", width: "36px" }}
          />
        </div>
      </div>
      <p className={`gray95024400 ${isNonMobile ? "mt-4" : "mt-3"}`}>Intake</p>
      <p className="gray70014400 mt-2">
        Choose the date you have started your studies.
      </p>
      <div
        className="d-flex"
        style={{ gap: "1.5rem", flexDirection: isNonMobile ? "row" : "column" }}
      >
        <div
          style={{
            position: "relative",
            width: isNonMobile ? "calc(100% - 12px)" : "100%",
          }}
        >
          <SelectOption
            value={month}
            setValue={setMonth}
            options={options}
            placeholder={"Month"}
          />
        </div>
        <div
          style={{
            position: "relative",
            width: isNonMobile ? "calc(100% - 12px)" : "100%",
          }}
        >
          <SelectOption
            value={year}
            setValue={setYear}
            options={Array.from(
              { length: currentYear - 2013 + 1 },
              (_, i) => 2013 + i
            )}
            placeholder={"Year"}
          />
        </div>
      </div>
    </div>
  );
};
export default Step3;
