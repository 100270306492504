import { useEffect, useState } from "react";
import SuitCase from "../../assets/images/icons/Suitcase";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import VolunteerList from "./VolunteerList";
import AddVolunteer from "./AddVolunteer";
import CompanyImage from "../../components/Company/CompanyImage";
const Volunteer = ({
  user,

  setWatcher,
  watcher,
  axios,
  toast,
}) => {
  const [volunteer, setVolunteer] = useState(
    user?.volunteer ? user?.volunteer : []
  );

  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (user) {
      setVolunteer(user?.volunteer);
    }
  }, [user, popup]);
  const [addVolunteer, setAddVolunteer] = useState(false);

  const [newVolunteer, setNewVolunteer] = useState({
    logo: null,
    title: "",
    companyName: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(-1);

  const handleUpdate = async () => {
    if (volunteer.length === 0) {
      toast.error("Please add at least one Volunteering or Leaderships.");
      return;
    }
    const formData = new FormData();
    volunteer.forEach((exp, index) => {
      formData.append(`volunteer[${index}][title]`, exp?.title);
      formData.append(`volunteer[${index}][companyName]`, exp?.companyName);
      formData.append(`volunteer[${index}][description]`, exp?.description);
      if (exp?.logo) {
        formData.append(`volunteer[${index}][logo]`, exp?.logo); // Only if logo is present
      }
    });
    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/update/student/volunteer`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Volunteering & Leaderships updated successfully");
          setPopup(false);
          setWatcher((prev) => {
            return {
              ...prev,
              volunteer: res.data.volunteer,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update ");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleConfirm = () => {
    if (!newVolunteer.title) {
      toast.error("Please enter Position name");
    } else if (!newVolunteer.description) {
      toast.error("Please enter description");
    } else if (!newVolunteer.companyName) {
      toast.error("Please provide Company Name");
    } else {
      setVolunteer((prevExperience) => {
        const updatedExperience = [...prevExperience];
        if (index === -1) {
          updatedExperience.push(newVolunteer);

          return updatedExperience;
        } else {
          updatedExperience[index] = newVolunteer;

          setIndex(-1);

          return updatedExperience;
        }
      });
      setNewVolunteer({
        logo: null,
        title: "",
        companyName: "",
        description: "",
      });
      setAddVolunteer(false);
      setIndex(-1);
    }
  };

  useEffect(() => {
    setNewVolunteer({
      logo: null,
      title: "",
      companyName: "",
      description: "",
    });
    setAddVolunteer(false);
    setIndex(-1);
  }, [popup]);

  return (
    <div>
      <div
        className=""
        style={{
          padding: "1.25rem",
          marginTop: "1.875rem",
          background: "#FFF",
          borderRadius: "5px",
        }}
      >
        {popup && (
          <div
            className="center zindex1000"
            style={{
              width: "100%",
              height: "100%",

              position: "fixed",
              alignItems: "center",
              top: 0,
              left: 0,
              right: 0,
              overflowY: "auto",
              zIndex: 1001,
              backgroundColor: "rgba(45, 50, 130, 0.30)",
              backdropFilter: "blur(3px)",
              padding: "1rem",
            }}
            onClick={() => {
              setPopup(false);
            }}
          >
            <div
              className="shadowa newPopUpContainer"
              style={{
                background: "#FFF",
                padding: "0.5rem",
                maxWidth: "715px",
                width: "100%",
              }}
            >
              <div
                className="  newPopUpContainer tablescroll"
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  maxHeight: "90vh",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="space align-items-center">
                  <p className="gray90020400">Volunteering & Leaderships</p>
                  <CloseIcon
                    className="hoverdarkblacksvg"
                    style={{ cursor: "pointer", height: "24px", width: "24px" }}
                    onClick={() => {
                      setPopup(false);
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    background: "#EAECF0",
                    margin: "0.75rem 0rem 1.5rem 0rem",
                  }}
                />
                {addVolunteer ? (
                  <AddVolunteer
                    newVolunteer={newVolunteer}
                    setNewVolunteer={setNewVolunteer}
                    addVolunteer={addVolunteer}
                    setAddVolunteer={setAddVolunteer}
                    handleConfirm={handleConfirm}
                    toast={toast}
                  />
                ) : (
                  <VolunteerList
                    setAddVolunteer={setAddVolunteer}
                    loading={loading}
                    volunteer={volunteer}
                    setNewVolunteer={setNewVolunteer}
                    setIndex={setIndex}
                    handleUpdate={handleUpdate}
                    setVolunteer={setVolunteer}
                    EditIcon={EditIcon}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <div className="space gap-3 align-items-center flex-wrap">
          <p className="gray90020400">Volunteering & Leaderships</p>
          {user?._id === watcher?._id && (
            <EditIcon
              className="hoverred"
              style={{ cursor: "pointer", height: "20px", width: "20px" }}
              onClick={() => {
                setPopup(true);
              }}
            />
          )}
        </div>
        <div
          style={{
            width: "100%",
            height: "1px",
            background: "#EAECF0",
            margin: "0.75rem 0rem 1.5rem 0rem",
          }}
        />

        {user?.volunteer?.length > 0 ? (
          <div className="d-flex flex-column gap-4">
            {user?.volunteer.map((vol, index) => (
              <div key={index}>
                <div className="d-flex flex-wrap gap-3">
                  {vol?.logo && (
                    <CompanyImage
                      src={
                        vol?.logo instanceof File
                          ? URL.createObjectURL(vol?.logo)
                          : vol?.logo
                      }
                    />
                  )}
                  <div>
                    <p className="gray95016500">{vol?.title}</p>
                    <p className="mt-2 a314400">{vol?.companyName}</p>
                    <p className="mt-3 a314400">{vol?.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <div className="center">
              <SuitCase />
            </div>
            <p
              className="gray40016500 text-center my-2"
              style={{ color: "#FD6F8E" }}
            >
              No Volunteering & Leaderships Yet
            </p>

            <div className="center">
              <p
                className="text-center gray60011400"
                style={{ maxWidth: "340px", fontSize: "14px" }}
              >
                <span
                  className="gray60011400"
                  style={{ fontSize: "14px", fontWeight: 500 }}
                >
                  Add your Volunteering & Leaderships.
                </span>{" "}
                It's for sure will help you to find Jobs.
              </p>
            </div>
            <p
              className="pink16 text-center mt-3 hovercolorback"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setPopup(true);
              }}
            >
              Add
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default Volunteer;
