import "./header.css";
import Netherlands from "../../assets/images/countries/Netherlands.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const NewHeader = ({ selected, setSelected }) => {
  const buttons = [
    { name: "Students", link: "/" },

    { name: "Employer-Hub", link: "/employer" },

    { name: "Career Centers", link: "/career-centers" },

    { name: "Student Organisations", link: "/" },
  ];

  return (
    <div>
      <div
        className=""
        style={{ background: "#F2F3F7", borderBottom: "1px solid #EAECF0" }}
      >
        <div className="marginsleftrightCompany">
          <div className="center">
            <div className="headerCompany space align-items-center">
              <div className="d-flex p-0 m-0">
                {buttons.map((btn, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                    }}
                  >
                    <a href={btn.link} style={{ textDecoration: "none" }}>
                      <button
                        className={`new-header-button ${
                          selected === btn.name ? "selectedbuttonheader" : ""
                        }`}
                        onClick={() => {
                          setSelected(btn.name);
                        }}
                      >
                        {btn.name}
                      </button>
                    </a>

                    {selected === btn.name && (
                      <div
                        style={{
                          position: "absolute",
                          background: "#FFF",
                          bottom: "-1px",
                          height: "1px",
                          width: "100%",
                        }}
                      ></div>
                    )}
                  </div>
                ))}
              </div>
              <div
                className="d-flex align-items-center gray60011400 hoverred"
                style={{ gap: "0.75rem", cursor: "pointer" }}
              >
                Employer Onboarding Netherlands
                <img
                  src={Netherlands}
                  alt="netherlands"
                  style={{ height: "20px", width: "20px", borderRadius: "50%" }}
                />
                <ArrowForwardIosIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHeader;
