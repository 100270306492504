import ChallengeIcon from "../../assets/images/icons/ChallengeIcon";

const First = ({ isNon530 }) => {
  return (
    <div className="marginsleftrightCompany">
      <div className="center">
        <div className="headerCompany" style={{ padding: "5rem 0rem" }}>
          <div className="d-flex">
            <div
              className="newdashboardsidechips"
              style={{ background: "rgba(249, 250, 251, 0.30)" }}
            >
              <ChallengeIcon /> Attract & Engage
            </div>
          </div>
          <div style={{ marginTop: "1.875rem" }}>
            <h3
              className="mainheadingcompany text-start"
              style={{ maxWidth: "772px" }}
            >
              Attract early talent with{" "}
              <span className="gradientvirtual">virtual challenges</span>
            </h3>
            <p
              className="gray95016500"
              style={{
                margin: "1.5rem 0rem",
                fontWeight: 400,
                maxWidth: "872px",
              }}
            >
              Engage early talent in{" "}
              <span className="gray95016500">a new and exciting way</span> by
              showcasing real-world case studies. Design challenges and give
              potential candidates the opportunity to{" "}
              <span className="gray95016500">
                explore their future career at your company
              </span>
              .
            </p>
            <div className="d-flex flex-wrap" style={{ gap: "1.25rem" }}>
              <a href="/" style={{ textDecoration: "none" }}>
                <button className="continuebutton">Get a demo</button>
              </a>
              <a href="/" style={{ textDecoration: "none" }}>
                <button className="backbutton">
                  Start Onboarding {isNon530 && "(Free)"}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default First;
