import NonMobile from "../../components/NonMobile/NonMobile";

const UpDownMarquee = ({ images1, images2, children }) => {
  const { isNonMobile1024 } = NonMobile();
  return (
    <div
      style={{
        padding: "3.75rem 0rem",
        background: "rgba(242, 244, 247, 0.8)",
      }}
    >
      <div className="marginsleftrightCompany marginsStudent">
        <div className="center">
          <div
            className="headerCompany d-flex"
            style={{
              background: "#FFF",
              flexDirection: isNonMobile1024 ? "row" : "column-reverse",
            }}
          >
            <div
              className=""
              style={{
                width: isNonMobile1024 ? "43.709%" : "100%",
                padding: isNonMobile1024 ? "0rem 2.5rem" : "1.25rem 1.25rem",
              }}
            >
              <div
                className="d-flex"
                style={{
                  width: "100%",
                  gap: "0.75rem",
                  height: "460px",
                }}
              >
                <div style={{ width: "calc(50% - 0.375rem)", height: "100%" }}>
                  <div className="marquee-container">
                    <div className="marquee">
                      {images1.map((item, index) => (
                        <img
                          src={item}
                          key={index}
                          alt={`img-${index}`}
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "12px",
                            aspectRatio: "1",
                          }}
                        />
                      ))}
                      {images1.map((item, index) => (
                        <img
                          src={item}
                          key={index}
                          alt={`img-${index}`}
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "12px",
                            aspectRatio: "1",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "calc(50% - 0.375rem)",
                    height: "100%",
                    justifyContent: "start",
                  }}
                >
                  <div className="marquee-container">
                    <div className="marquee2">
                      {images2.map((item, index) => (
                        <img
                          src={item}
                          key={index}
                          alt={`img-${index}`}
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "12px",
                            aspectRatio: "1",
                          }}
                        />
                      ))}
                      {images2.map((item, index) => (
                        <img
                          src={item}
                          key={index}
                          alt={`img-${index}`}
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "12px",
                            aspectRatio: "1",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="center align-items-center "
              style={{
                width: isNonMobile1024 ? "56.290%" : "100%",
                padding: isNonMobile1024 ? "0rem 2.5rem" : "1.25rem 1.25rem",
              }}
            >
              <div style={{ width: "100%" }}>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpDownMarquee;
