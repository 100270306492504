import img1 from "../../assets/images/row211.png";
import img2 from "../../assets/images/row12.png";
import img3 from "../../assets/images/row213.png";
import img4 from "../../assets/images/row14.png";
import img5 from "../../assets/images/row221.png";
import img6 from "../../assets/images/Placeholder.png";
import img7 from "../../assets/images/row224.png";
import img8 from "../../assets/images/row24.png";
import linkedin from "../../assets/images/icons/linkedin.png";
import NonMobile from "../../components/NonMobile/NonMobile";
import UpDownMarquee from "../Home/UpDownMarque";
import SelectedGreen from "../../assets/images/icons/SelectedGreen";
const CompanyAction = () => {
  const images1 = [img1, img2, img3, img4];
  const images2 = [img5, img6, img8, img7];

  const { isNonMobile500 } = NonMobile();
  return (
    <div>
      <UpDownMarquee images1={images1} images2={images2}>
        <div style={{}}>
          <p className="pink14">EMPLOYER ONBOARDING</p>
          <p className="filmo30400" style={{ marginTop: "0.75rem" }}>
            Hi, Campus Recruiter!
          </p>
          <p className="gray60016400" style={{ marginTop: "0.75rem" }}>
            Discover how varyance helps you connect with{" "}
            <span className="gray60016400" style={{ fontWeight: "500" }}>
              early talent
            </span>
            . Showcase your company culture, build meaningful connections, and
            stand out as an employer of choice.
          </p>
          <div style={{ marginTop: "2rem" }}>
            <div
              className="d-flex align-items-center"
              style={{ gap: "0.75rem" }}
            >
              <SelectedGreen />
              <p className="gray95014400 ">
                Onboarding Duration:{" "}
                <span className="gray95014400" style={{ fontWeight: 500 }}>
                  2-3min
                </span>
              </p>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ gap: "0.75rem", marginTop: "0.75rem" }}
            >
              <SelectedGreen />
              <div className="d-flex gap-2 align-items-center">
                <p className="gray95014400 ">
                  Requirements:{" "}
                  <span className="gray95014400" style={{ fontWeight: 500 }}>
                    Company Email
                  </span>
                </p>
                <div
                  style={{
                    width: "1px",
                    height: "12px",
                    background: "#D0D5DD",
                  }}
                />

                <img
                  src={linkedin}
                  alt="google"
                  style={{ height: "20px", width: "20px" }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 flex-wrap" style={{ marginTop: "3rem" }}>
            <a href="/contact" style={{ textDecoration: "none" }}>
              <button
                className="continuebutton"
                style={{ width: isNonMobile500 ? "" : "100%" }}
              >
                Get a demo
              </button>
            </a>
            <a href="/employer/register" style={{ textDecoration: "none" }}>
              <button
                className="backbutton"
                style={{ width: isNonMobile500 ? "" : "100%" }}
              >
                Start Onboarding (Free)
              </button>
            </a>
          </div>
        </div>
      </UpDownMarquee>
    </div>
  );
};

export default CompanyAction;
