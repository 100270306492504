const EventTypes = ({ type, TRUE, noback }) => {
  return (
    <div
      className="EventsTypeContainer"
      style={{
        background: noback
          ? "transparent"
          : type === "Live Stream"
          ? "rgba(0, 119, 182, 0.10)"
          : type === "Physical Event"
          ? "rgba(120, 53, 205, 0.10)"
          : "rgba(252, 131, 9, 0.10)",
        color:
          type === "Live Stream"
            ? "#0077B6"
            : type === "Physical Event"
            ? "#7835CD"
            : "#FC8309",
        padding: TRUE ? "8px" : noback ? "0rem" : "",
        borderRadius: TRUE ? "50%" : "",
      }}
    >
      <div style={{ minWidth: "22px" }}>
        {type === "Live Stream" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <path
              d="M3.88216 17.3343C3.72971 17.1826 3.53415 17.0815 3.32216 17.045C3.25403 17.0358 3.18496 17.0358 3.11683 17.045C2.8478 17.0447 2.58828 17.1445 2.38883 17.325C1.96633 17.7478 1.96633 18.4329 2.38883 18.8557C2.59059 19.0605 2.86666 19.175 3.15416 19.173C3.44188 19.1761 3.71835 19.0614 3.9195 18.8557C4.14618 18.6017 4.24266 18.2571 4.18083 17.9223C4.14146 17.7012 4.03754 17.4966 3.88216 17.3343Z"
              fill="#0077B6"
            />
            <path
              d="M2.91149 14.5903C2.65791 14.5366 2.3954 14.6274 2.22921 14.8264C2.06303 15.0253 2.02037 15.2998 2.11835 15.5397C2.21633 15.7797 2.4389 15.9459 2.69683 15.9717C4.01693 16.166 5.05082 17.2075 5.23549 18.529C5.28248 18.8736 5.57838 19.1295 5.92616 19.1263H6.02883C6.40974 19.0688 6.67252 18.7142 6.61683 18.333C6.3534 16.3907 4.82582 14.8631 2.88349 14.5997L2.91149 14.5903Z"
              fill="#0077B6"
            />
            <path
              d="M3.36883 10.3717C3.10996 10.3117 2.83943 10.4034 2.67047 10.6085C2.50151 10.8136 2.46325 11.0967 2.57171 11.3393C2.68017 11.5819 2.91665 11.7422 3.18216 11.753C6.47049 12.1585 9.05847 14.7542 9.45416 18.0437C9.5012 18.3934 9.80126 18.6535 10.1542 18.6503H10.2382C10.4227 18.6271 10.5904 18.5313 10.7042 18.3842C10.818 18.2371 10.8686 18.0508 10.8448 17.8663C10.355 13.9635 7.28104 10.8895 3.37816 10.3997L3.36883 10.3717Z"
              fill="#0077B6"
            />
            <path
              d="M3.12616 12.4903C2.86729 12.4303 2.59676 12.5221 2.4278 12.7272C2.25884 12.9323 2.22059 13.2154 2.32905 13.458C2.43751 13.7006 2.67398 13.8608 2.9395 13.8717C4.05983 14.0157 5.10056 14.5279 5.89816 15.3277C6.69731 16.122 7.20953 17.1596 7.35416 18.277C7.39683 18.6253 7.69392 18.8863 8.04483 18.8837H8.12883C8.31351 18.8582 8.48041 18.7602 8.59254 18.6113C8.70467 18.4623 8.75276 18.2749 8.72617 18.0903C8.52429 16.6767 7.86929 15.3667 6.8595 14.357C5.85264 13.3433 4.54124 12.6876 3.12616 12.4903Z"
              fill="#0077B6"
            />
            <path
              d="M16.5288 4.33301H6.35549C4.12717 4.33815 2.32349 6.14601 2.32349 8.37434C2.32349 8.76094 2.63689 9.07434 3.02349 9.07434C3.41009 9.07434 3.72349 8.76094 3.72349 8.37434C3.72101 7.67468 3.99722 7.00282 4.49108 6.5072C4.98495 6.01158 5.65582 5.733 6.35549 5.73301H16.5288C17.9693 5.75846 19.1237 6.93361 19.1235 8.37434V14.6463C19.1136 16.0798 17.9621 17.2437 16.5288 17.269H13.1688C12.7822 17.269 12.4688 17.5824 12.4688 17.969C12.4688 18.3556 12.7822 18.669 13.1688 18.669H16.5288C18.7572 18.669 20.565 16.8653 20.5702 14.637V8.37434C20.5702 6.14237 18.7608 4.33301 16.5288 4.33301Z"
              fill="#0077B6"
            />
          </svg>
        )}
        {type === "Physical Event" && (
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path
              d="M16.2986 15.0518C15.9581 14.8807 15.5432 15.0134 15.3653 15.3505C15.2008 15.6938 15.3368 16.1058 15.6733 16.2838C16.4946 16.7038 16.9613 17.2171 16.9613 17.7118C16.9613 18.7665 14.7586 19.9425 11.8093 19.9425C8.85993 19.9425 6.64793 18.7758 6.64793 17.7491C6.64793 17.2638 7.1146 16.7411 7.93593 16.3211C8.18224 16.2224 8.35153 15.9928 8.3731 15.7283C8.39468 15.4638 8.26484 15.2099 8.0378 15.0725C7.81075 14.9351 7.52555 14.938 7.30127 15.0798C6.16811 15.5069 5.37811 16.5433 5.2666 17.7491C5.2666 19.8211 8.0666 21.3798 11.7999 21.3798C15.5333 21.3798 18.3333 19.8211 18.3333 17.7491C18.232 16.5329 17.4403 15.4832 16.2986 15.0518Z"
              fill="#7835CD"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.0999 18.1598V14.1278C8.02637 13.7575 5.77296 11.0574 5.95845 7.96719C6.14394 4.87696 8.70414 2.46582 11.7999 2.46582C14.8957 2.46582 17.4559 4.87696 17.6414 7.96719C17.8269 11.0574 15.5735 13.7575 12.4999 14.1278V18.1598C12.4999 18.5464 12.1865 18.8598 11.7999 18.8598C11.4133 18.8598 11.0999 18.5464 11.0999 18.1598ZM15.9292 6.61088C15.2363 4.94295 13.6061 3.85736 11.7999 3.86114C9.34329 3.86627 7.35307 5.85649 7.34794 8.31314C7.34416 10.1193 8.42975 11.7495 10.0977 12.4424C11.7656 13.1353 13.6868 12.7542 14.9639 11.4771C16.241 10.2 16.6221 8.27881 15.9292 6.61088Z"
              fill="#7835CD"
            />
            <path
              d="M9.5506 9.34914C9.9372 9.34914 10.2506 9.03574 10.2506 8.64914C10.2506 7.57697 11.1198 6.7078 12.1919 6.7078C12.5785 6.7078 12.8919 6.3944 12.8919 6.0078C12.8919 5.6212 12.5785 5.3078 12.1919 5.3078C11.3033 5.31028 10.452 5.66566 9.82539 6.29579C9.19876 6.92591 8.84812 7.77915 8.8506 8.6678C8.85306 8.85099 8.9282 9.02569 9.05948 9.15346C9.19075 9.28124 9.36742 9.35163 9.5506 9.34914Z"
              fill="#7835CD"
            />
          </svg>
        )}
        {type === "Job Closings" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <path
              d="M15.5997 8.21587H7.19974C6.81314 8.21587 6.49974 8.52927 6.49974 8.91587C6.49974 9.30247 6.81314 9.61587 7.19974 9.61587H15.5997C15.9863 9.61587 16.2997 9.30247 16.2997 8.91587C16.2997 8.52927 15.9863 8.21587 15.5997 8.21587Z"
              fill="#FC8309"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.9637 3.93187H16.0664C18.6437 3.93187 20.7331 6.02121 20.7331 8.59854V16.9985C20.7331 19.5759 18.6437 21.6652 16.0664 21.6652H6.73307C5.4954 21.6652 4.30841 21.1735 3.43324 20.2984C2.55807 19.4232 2.06641 18.2362 2.06641 16.9985V8.59854C2.06641 6.02121 4.15574 3.93187 6.73307 3.93187H6.81707V2.2332C6.81707 1.8466 7.13047 1.5332 7.51707 1.5332C7.90367 1.5332 8.21707 1.8466 8.21707 2.2332V3.93187H14.5637V2.2332C14.5637 1.8466 14.8771 1.5332 15.2637 1.5332C15.6503 1.5332 15.9637 1.8466 15.9637 2.2332V3.93187ZM16.0664 20.2652C17.8705 20.2652 19.3331 18.8027 19.3331 16.9985V8.59854C19.3331 6.79441 17.8705 5.33187 16.0664 5.33187H6.73307C4.92894 5.33187 3.46641 6.79441 3.46641 8.59854V16.9985C3.46641 18.8027 4.92894 20.2652 6.73307 20.2652H16.0664Z"
              fill="#FC8309"
            />
          </svg>
        )}
      </div>
      {!TRUE && type}
    </div>
  );
};

export default EventTypes;
