import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const Password = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = () => {
    if (password !== confirmPassword) {
      toast.error("New Password does not match with Confirm Password");
      return;
    } else if (password.length < 8) {
      return toast.error("Password should be atleast 8 characters long");
      return;
    } else if (password === currentPassword) {
      return toast.error("New Password cannot be same as current password");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/change/user/Password`,
          {
            currentPassword,
            password,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            toast.success("Password changed successfully");
            setCurrentPassword("");
            setPassword("");
            setConfirmPassword("");
          } else if (res.data.msg === "wrongpass") {
            toast.error("Wrong current Password");
          } else {
            toast.error("Error Changing Password");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  return (
    <div>
      <div style={{ maxWidth: "700px" }}>
        <p className="black14black">Want to Change Your Password?</p>
        <p className="gray14new" style={{ marginTop: "0.25rem" }}>
          Your password must be at least 8 characters.
        </p>
        <hr
          style={{
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />

        <p className="black14black">Current Password</p>
        <div className="position-relative">
          <input
            value={currentPassword}
            type={showPassword ? "text" : "password"}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="rounded-3 newinputs  col-md-12 col-12 "
            style={{ marginTop: "0.75rem" }}
          />
          <div
            className="position-absolute end-10 top-50 translate-middle-y"
            style={{
              right: "10px",
              cursor: "pointer",
            }}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <RemoveRedEyeOutlinedIcon />
            )}
          </div>
        </div>
        <div style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}>
          <p className="black14black">New Password</p>
          <div className="position-relative">
            <input
              className="newinputs col-md-12 col-12 mt-2"
              style={{ maxWidth: "700px" }}
              value={password}
              type={showNewPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="position-absolute end-10 top-50 translate-middle-y"
              style={{
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => setNewShowPassword(!showNewPassword)}
            >
              {showNewPassword ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <RemoveRedEyeOutlinedIcon />
              )}
            </div>
          </div>
        </div>
        <p className="black14black">Confirm New Password</p>
        <div className="position-relative">
          <input
            className="newinputs col-md-12 col-12 mt-2"
            style={{ maxWidth: "700px" }}
            value={confirmPassword}
            type={showConfirmPassword ? "text" : "password"}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />{" "}
          <div
            className="position-absolute end-10 top-50 translate-middle-y"
            style={{
              right: "10px",
              cursor: "pointer",
            }}
            onClick={() => setConfirmShowPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <RemoveRedEyeOutlinedIcon />
            )}
          </div>
        </div>
        <hr
          style={{
            marginTop: "2.5rem",
            marginBottom: "1.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        <div className="d-flex justify-content-end">
          <button
            className="continuebutton"
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default Password;
