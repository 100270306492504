import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";

import SecondSection from "./secondSection";

const StudentProfilePage = ({ recipient, TRUE }) => {
  let userId;
  const { uniqueId } = useParams();
  userId = recipient ?? uniqueId;
  const [user, setUser] = useState(null);
  const [organiser, setOrganiser] = useState(null);
  const [company, setCompany] = useState(null);
  const [AllChallenges, setAllChallenges] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const id = useSelector((state) => state.uniqueId);
  const navigate = useNavigate();
  const [jobposts, setJobposts] = useState([]);
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const owner = false;

  const [similarUsers, setSimilarUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userId) {
          navigate(-1);
          return;
        }

        const userResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/user/details/uniqueId/company`,
          { userId: userId, msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk" }
        );

        if (userResponse.data.message !== "Found") {
          navigate(-1);
          return;
        }

        setUser(userResponse.data.user);

        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message !== "Found") {
          navigate("/employer/register");
          return;
        }

        setOrganiser(userInfoResponse.data.user);

        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: userInfoResponse.data.user.company }
        );

        if (companyInfoResponse.data.msg === "Logout") {
          navigate("/employer/register");
          return;
        }

        setCompany(companyInfoResponse.data);

        const similar = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/get/similar/users`,
          {
            Skills: userResponse.data.user.Skills,
            msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk",
            userId: userResponse.data.user._id,
          }
        );
        if (similar.data.msg === "Found") {
          setSimilarUsers(similar.data.users);
        }

        const challengesResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/company/challenges`,
          { uniqueId: companyInfoResponse.data.uniqueId }
        );

        if (challengesResponse.data.msg === "success") {
          const filteredChallenges = challengesResponse.data.challenges.filter(
            (element) =>
              element.countDates.some(
                (date) => date.userId === userResponse.data.user._id
              )
          );
          setAllChallenges(challengesResponse.data.challenges);

          setChallenges(filteredChallenges);

          const responsea = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/get/company/jobs`,
            {
              uniqueId: companyInfoResponse.data.uniqueId,
            }
          );
          if (responsea.data.msg === "success") {
            setJobposts(responsea.data.challenges);
          } else if (responsea.data.msg === "Record not found") {
            setJobposts([]);
          } else {
            setJobposts([]);
          }
        } else if (challengesResponse.data.msg === "Record not found") {
          console.log("No challenges found");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id, userId]);

  return (
    <div className="paddingmaincontent pb-5">
      {user && (
        <div
          style={
            {
              // marginLeft: isNonMobile450
              //   ? TRUE
              //     ? "1.25rem"
              //     : "1.875rem"
              //   : "1.5rem",
              // marginRight: isNonMobile450 ? "1.875rem" : "1.5rem",
            }
          }
        >
          <SecondSection
            user={user}
            TRUE={TRUE}
            owner={owner}
            isNonMobile450={isNonMobile450}
            isNonMobile1024={isNonMobile1024}
            challenges={challenges}
            navigate={navigate}
            AllChallenges={AllChallenges}
            jobposts={jobposts}
            similarUsers={similarUsers}
            recipient={recipient}
            isNonMobile={isNonMobile}
          />
        </div>
      )}
    </div>
  );
};

export default StudentProfilePage;
