import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CompanyImage from "../../components/Company/CompanyImage";
const ExperienceList = ({
  setAddExperience,
  loading,
  setExperience,
  experience,
  setEdit,
  setIndex,
  handleUpdateExperience,
}) => {
  return (
    <div>
      {experience.map((exp, index) => (
        <div key={index}>
          {exp?.company && (
            <div style={{ marginTop: "0.5rem" }}>
              <div
                className="d-flex justify-content-end"
                style={{ gap: "1rem" }}
              >
                <EditIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEdit(exp);
                    setIndex(index);
                    setAddExperience(true);
                  }}
                />
                <DeleteIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setExperience(experience.filter((_, i) => i !== index));
                  }}
                />
              </div>
              <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                {exp?.logo && (
                  <CompanyImage
                    src={
                      exp?.logo instanceof File
                        ? URL.createObjectURL(exp?.logo)
                        : exp?.logo
                    } // Access logo property from each experience object
                  />
                )}
                <div style={{ width: "100%" }}>
                  <div className="space flex-wrap" style={{ gap: "1rem" }}>
                    <p className="black16 text-start">{exp?.position}</p>
                    <p className="gray14400">
                      {exp?.from}-{exp?.to}
                    </p>
                  </div>
                  <div className="d-flex flex-wrap align-items-center gap-2 mt-2">
                    <p
                      className="gray14400 d-flex flex-wrap align-items-center"
                      style={{
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      {exp?.location?.flag} {exp?.location?.name}
                    </p>
                    <div
                      className=""
                      style={{
                        background: "#667085",
                        height: "4px",
                        width: "4px",
                        borderRadius: "125px",
                      }}
                    />
                    <p
                      className="gray14400 d-flex flex-wrap"
                      style={{
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      {exp?.company}
                    </p>
                  </div>
                  {exp?.description && (
                    <p className="gray14400" style={{ marginTop: "0.75rem" }}>
                      {exp?.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
      <div className="mt-3">
        <div className="d-flex">
          <p
            className=" text-start backbutton hoverred mt-3"
            onClick={() => setAddExperience(true)}
            style={{ cursor: "pointer" }}
          >
            Add Experience
          </p>
        </div>
        <hr className="mt-5" />
        <button
          className="continuebutton mt-2"
          onClick={() => handleUpdateExperience()}
          disabled={loading}
        >
          Update experience
        </button>
      </div>
    </div>
  );
};
export default ExperienceList;
