// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileJobDetails {
  position: absolute;
  bottom: -100%;
  height: 1px;
  overflow: hidden;
  /* display: none; */
  width: 100%;
  background: #2d32824d;
  z-index: 5;
  transition: all 0.3s ease-in-out;
}

.mobileJobDetails.showjobdetails {
  display: block;
  top: 60px;

  left: 0;
  height: calc(100vh - 60px);
}
`, "",{"version":3,"sources":["webpack://./src/pages/StudentJobFlow/main.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;EACX,qBAAqB;EACrB,UAAU;EACV,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,SAAS;;EAET,OAAO;EACP,0BAA0B;AAC5B","sourcesContent":[".mobileJobDetails {\n  position: absolute;\n  bottom: -100%;\n  height: 1px;\n  overflow: hidden;\n  /* display: none; */\n  width: 100%;\n  background: #2d32824d;\n  z-index: 5;\n  transition: all 0.3s ease-in-out;\n}\n\n.mobileJobDetails.showjobdetails {\n  display: block;\n  top: 60px;\n\n  left: 0;\n  height: calc(100vh - 60px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
