import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import UserDetails from "./UserDetails";
import EmptySpace from "../../assets/images/EmptyState.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserInfo from "./UserInfo";
import "./profile.css";
import SimilarUsers from "./SimilarUsers";
const NewStudentProfile = ({ watcher, setWatcher }) => {
  const { link } = useParams();
  const navigate = useNavigate();

  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const [user, setUser] = useState(null);
  const [connects, setConnects] = useState([]);

  const [loading, setLoading] = useState(true);

  const [emptySimilar, setEmptySimilar] = useState(false);

  const fetchUser = async (id) => {
    if (!id) {
      setLoading(true);
    }

    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/get/student/details/${link}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Attach the token in the headers
        },
      })
      .then((res) => {
        if (res.data.msg === "success") {
          setUser(res.data.user);
          setConnects(res.data.lastFive);
        }
      })
      .catch((err) => {
        console.log(err);
        // navigate(-1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (watcher && watcher.link) {
      if (link !== watcher?.link) {
        fetchUser(false);
      } else {
        setUser(watcher);
        fetchUser(true);
        setLoading(false);
      }
    }
    if (!link) {
      navigate(-1);
    }
  }, [link, watcher]);

  return (
    <div className="marginsleftrightCompany marginsStudent">
      <div className="center">
        <div className="headerCompany" style={{ padding: "1.875rem 0rem" }}>
          {loading ? (
            <div className="center">
              <div
                className="loader"
                style={{ position: "fixed", top: "50%", left: "50%" }}
              ></div>
            </div>
          ) : (
            <div className="d-flex" style={{ gap: "1.875rem" }}>
              <div style={{ width: "100%" }}>
                {user ? (
                  <div>
                    <UserDetails
                      user={user}
                      connects={connects}
                      watcher={watcher}
                      setWatcher={setWatcher}
                      setUser={setUser}
                    />
                    <div style={{ marginTop: "1.5rem" }}>
                      <UserInfo
                        user={user}
                        connects={connects}
                        watcher={watcher}
                        isNonMobile1024={isNonMobile1024}
                        setWatcher={setWatcher}
                        setUser={setUser}
                        isNonMobile450={isNonMobile450}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="">
                    <div className="center">
                      <img
                        src={EmptySpace}
                        alt="Emptyspace"
                        style={{ maxWidth: "200px" }}
                        className="img-fluid"
                      />
                    </div>
                    <p className="black14 text-center">
                      No User Found with the assosiated ID
                    </p>
                  </div>
                )}
              </div>
              {isNonMobile1024 && !emptySimilar && (
                <div
                  className="d-flex"
                  style={{ minWidth: "300px", maxWidth: "300px" }}
                >
                  <SimilarUsers setEmptySimilar={setEmptySimilar} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewStudentProfile;
