import React, { useEffect, useState } from "react";
import "./NewDashboard.css";
import NewSideBar from "./NewSideBar";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../assets/images/logo.png";
import clock from "../../assets/images/clock.png";
import dart from "../../assets/images/dart.png";
import NewHeader from "./NewHeader";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import MobileHeader from "./MobileNewHeader";
import BottomNav from "./BottomNav";
import io from "socket.io-client";

import Intercoms from "../Intercom/Main";

const socket = io(`${process.env.REACT_APP_BACKEND_URL}`);

const NewDashboard = () => {
  const [selected, setSelected] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile1200 = useMediaQuery("(min-width:1200px)");
  const isNonMobile1450 = useMediaQuery("(min-width:450px)");
  const isNonMobile768 = useMediaQuery("(min-width:560px)");
  const isNonMobile350 = useMediaQuery("(min-width:350px)");
  const [user, setUser] = useState(null);
  const id = useSelector((state) => state.uniqueId);
  const [company, setCompany] = useState();
  const [secondSelected, setSecondSelected] = useState("");
  const [isClose, setIsClose] = useState(false);
  const path = location.pathname;
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    socket.emit("register", { userId: user?._id, type: "organiser" });

    return () => {
      socket.off("receiveMessage");
    };
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
        } else {
          navigate("/employer/register");
          return;
        }

        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: userInfoResponse.data.user.company }
        );
        if (companyInfoResponse.data.msg === "Logout") {
          navigate("/employer/register");
          return;
        }
        setCompany(companyInfoResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const path = location.pathname.toLowerCase();
    if (path === "/dashboard") {
      setSelected("Dashboard");
    } else if (path === "/dashboard/challenges") {
      setSelected("Challenges");
    } else if (path === "/dashboard/jobposts") {
      setSelected("Job Posts");
    } else if (path === "/dashboard/talentpool") {
      setSelected("Talent Pool");
    } else if (path === "/dashboard/team") {
      setSelected("Team");
    } else if (path === "/dashboard/settings") {
      setSelected("Settings");
    } else if (path === "/dashboard/talentpool/short-list") {
      setSelected("Talent Pool");
      setSecondSelected("Shortlisted");
    } else if (path === "/dashboard/talentpool/targetschool") {
      setSelected("Talent Pool");
      setSecondSelected("Target Schools");
    } else if (path === "/dashboard/chat") {
      setSelected("Messages");
      setSecondSelected("");
    } else if (path === "/dashboard/events") {
      setSelected("Events");
      setSecondSelected("");
    } else if (path === "/dashboard/progress") {
      setSelected("Progress");
      setSecondSelected("");
    } else if (path === "/dashboard/events/details") {
      setSelected("Events");
      setSecondSelected("");
    } else if (path === "/dashboard/challenge/details") {
      setSelected("Challenges");
      setSecondSelected("");
    } else if (path.includes("/dashboard/job/details")) {
      setSelected("Job Posts");
      setSecondSelected("");
    } else {
      setSelected("");
    }
  }, [location]);

  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (user?.firstTime) {
      setPopup(true);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/firstTime`, {
          id: user?.uniqueId,
        })
        .then((res) => {
          console.log(res.data);
        });
    }
  }, [user]);

  const fetchData = async (add) => {
    if (company && user) {
      try {
        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/dummy/${
              add ? "add" : "delete"
            }`,
            {
              company,
              user,
            }
          )
          .then((res) => {
            if (res.data.msg === "success") {
              window.location.reload();
            }
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  return (
    <>
      {popup && (
        <div
          className="center align-items-center"
          style={{
            position: "fixed",
            top: 0,
            height: "100vh",
            width: "100vw",
            left: 0,
            zIndex: 10,
            padding: "1rem",

            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
          }}
        >
          <div
            className=" newPopUpContainer"
            style={{
              maxWidth: "90vw",
              maxHeight: "80vh",
              padding: "1.5rem",
              maxWidth: "700px",
              backgroundColor: "",
            }}
          >
            <div className="space align-items-center">
              <div
                className="d-flex align-items-center"
                style={{ gap: "1rem" }}
              >
                <img src={logo} alt=" logo img" height="36px" width="36px" />
                <h4 className="Welcome-New m-0">
                  Welcome to varyance, {user?.firstName}!
                </h4>
              </div>
              <CloseIcon
                className="hoverdarkblacksvg"
                style={{ cursor: "pointer", height: "24px", width: "24px" }}
                onClick={() => {
                  setPopup(false);
                  if (company?.createdBy === user?.email) fetchData(true);
                }}
              />
            </div>
            <div
              className=""
              style={{
                marginTop: "1rem",
                borderRadius: "20px",

                padding: isNonMobile768 ? "1.5rem" : "",
                paddingRight: "0rem",
              }}
            >
              <div
                className=""
                style={{ marginLeft: isNonMobile768 ? "30px" : "" }}
              >
                <div className="d-flex " style={{ gap: "0.75rem" }}>
                  <img
                    src={clock}
                    alt=" clock"
                    height={"20px"}
                    width={"20px"}
                  />
                  <p className="pink14">Compass Tour</p>
                </div>
                <p
                  className="black14"
                  style={{ fontWeight: 500, marginTop: "0.75rem" }}
                >
                  We're here to support your first varynce journey. Contact us
                  for an overview of features or to collaborate on your first
                  challenge.
                </p>
              </div>
              <div
                className=""
                style={{
                  marginTop: "1.5rem",
                  marginLeft: isNonMobile768 ? "30px" : "",
                }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "0.75rem" }}
                >
                  <img src={dart} alt=" clock" height={"20px"} width={"20px"} />
                  <p className="pink14">Demo Data</p>
                </div>
                <p
                  className="black14"
                  style={{ fontWeight: 500, marginTop: "0.75rem" }}
                >
                  Demo data has been uploaded to help you explore our features.
                  You can delete it when you're ready to start.
                </p>
              </div>
            </div>
            <div
              className="d-flex "
              style={{ marginTop: "2rem", justifyContent: "end" }}
            >
              <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                <button
                  className="continuebutton"
                  onClick={() => {
                    setPopup(false);
                    if (company?.createdBy === user?.email) fetchData(true);
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="new-dashboard">
        <Intercoms user={user} />

        {isNonMobile768 ? (
          <div
            className={`sidebar__wrapper ${
              isNonMobile1200 && !isClose ? "open" : "close"
            }  `}
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
          >
            {isNonMobile1200 && showIcon && (
              <button
                className="sidebar__openclosebtn shadowa"
                onClick={() => {
                  setIsClose((prev) => !prev);
                  setShowIcon(false);
                }}
                style={{
                  position: "absolute",
                  right: 0,
                  marginRight: "-1.5rem",
                  zIndex: 4,
                  marginTop: "80px",
                }}
              >
                {isClose ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </button>
            )}
            <NewSideBar
              setSelected={setSelected}
              selected={selected}
              company={company}
              setSecondSelected={setSecondSelected}
              secondSelected={secondSelected}
              isNonMobile1200={isNonMobile1200}
              setIsClose={setIsClose}
              isClose={isClose}
            />
          </div>
        ) : (
          <BottomNav
            setSelected={setSelected}
            selected={selected}
            navigate={navigate}
            setSecondSelected={setSecondSelected}
          />
        )}
        <div className="content-wrapper">
          {isNonMobile768 ? (
            <NewHeader
              text={selected}
              navigate={navigate}
              isNonMobile1024={isNonMobile1024}
              isNonMobile1450={isNonMobile1450}
              user={user}
              isNonMobile1200={isNonMobile1200}
              isClose={isClose}
              company={company}
              isNonMobile350={isNonMobile350}
              path={path}
            />
          ) : (
            <MobileHeader navigate={navigate} user={user} />
          )}
          <div
            className={`main-content ${
              isNonMobile1200 && !isClose ? "open" : "close"
            } ${isNonMobile768 ? "testingshadow" : ""} `}
            style={{
              position: isNonMobile768 ? "fixed" : "fixed",
              overflow: "auto",
              background: path.includes("/chat") ? "#FFF" : "",
            }}
          >
            <div
              style={{
                position: "block",
                overflow: "auto",
                paddingBottom: "70px",
              }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewDashboard;
