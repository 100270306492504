import { useState } from "react";

import { useMediaQuery } from "@mui/material";
import Navbar from "../TestAgain/navbar";
import axios from "axios";
import Questions from "../TestAgain/Questions";
import Document from "../TestAgain/Documents";
import Terms from "../TestAgain/Terms";
import Welcome from "../TestAgain/Welcome";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Completed from "../TestAgain/Completed";
import Tasknav from "../TestAgain/TaskCountnav";
import PreviewTaskHeader from "./PreviewTaskHeader";
const PreviewTask = ({
  setShowPreview,
  taskPreview,
  title,
  taskCounter,
  total,
  company,
}) => {
  const [steps, setSteps] = useState(0);
  const [questionNumber, setQuestionNumber] = useState(0);
  const isNonMobile700 = useMediaQuery("(min-width:700px)");
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  const [selectedAnswers, setSelectedAnswers] = useState([[{}]]);

  const handleBack = () => {
    if (steps === 0) {
      setShowPreview(false);
    } else if (steps === 1) {
      setSteps(0);
    } else if (steps === 2) {
      setSteps(1);
    } else if (steps === 3) {
      if (questionNumber === 0) {
        setSteps(2);
      } else {
        setQuestionNumber(questionNumber - 1);
      }
    }
  };

  const handleNext = () => {
    if (steps === 0) {
      setSteps(1);
    } else if (steps === 1) {
      setSteps(2);
    } else if (steps === 2) {
      setSteps(3);
    } else if (steps === 3) {
      if (questionNumber === taskPreview.questions.length - 1) {
        setSteps(4);
      } else {
        setQuestionNumber(questionNumber + 1);
      }
    } else if (steps === 4) {
      setShowPreview(false);
    }
  };

  return (
    <div
      className="testpagebackground tablescroll"
      style={{ overflow: "auto" }}
    >
      <div>
        <PreviewTaskHeader
          questionNumber={questionNumber}
          taskPreview={taskPreview}
          steps={steps}
          isNonMobile700={isNonMobile700}
          setShowPreview={setShowPreview}
          title={title}
          total={total}
          taskCounter={taskCounter}
        />
      </div>
      <div
        style={{
          paddingTop: "52px",
          paddingBottom: "81px",
        }}
      >
        <div
          className="challengeattemptheader space"
          style={{ marginTop: "2.5rem" }}
        >
          <div>
            {steps !== 4 && (
              <p className="black14">
                Task {taskCounter + 1} : {title}
              </p>
            )}
            <p style={{ marginTop: "0.75rem", fontSize: "1.25rem" }}>
              {steps === 0 && taskCounter === 0 && ` Welcome to the challenge`}
              {steps === 0 && taskCounter > 0 && ` Welcome to the next Task`}
              {steps === 1 && "Important key terms and frameworks"}
              {steps === 2 && "Relevant Resources"}
              {steps === 3 && "Questions"}
            </p>
          </div>
          <div className="companylogotestpagediv center shadowa">
            <img src={company?.img} alt="company logo" className="companyImg" />
          </div>
        </div>
        <div className="stepsContainer ">
          <div className="challengeattemptheader d-flex space">
            <div className={`w-100 ${isNonMobile1024 && "paddingrighttest"}`}>
              {steps !== 4 && (
                <hr
                  style={{
                    marginTop: isNonMobile1024 ? "0rem" : "1rem",
                    marginBottom: "1.5rem",
                  }}
                />
              )}
              <div>
                {steps === 0 && taskPreview && (
                  <Welcome
                    selectedVideo={taskPreview?.introVideo}
                    isNonMobile1024={isNonMobile1024}
                  />
                )}
                {steps === 1 && taskPreview && (
                  <Terms terms={taskPreview.terms} />
                )}
                {console.log(taskPreview)}
                {steps === 2 && taskPreview && (
                  <Document
                    doc1={taskPreview.caseDocuments}
                    doc2={taskPreview.additionalDocuments}
                    doc3={taskPreview.external}
                    axios={axios}
                  />
                )}
                {steps === 3 && taskPreview && (
                  <Questions
                    questions={taskPreview.questions}
                    questionNumber={questionNumber}
                    setQuestionNumber={setQuestionNumber}
                    isNonMobile1440={isNonMobile1440}
                    selectedAnswers={selectedAnswers}
                    setSelectedAnswers={setSelectedAnswers}
                    taskCounter={taskCounter}
                  />
                )}
                {steps === 4 && <Completed />}
              </div>
            </div>
            {isNonMobile1024 && taskPreview && (
              <div
                className="widthofsidenavtest testnavbarstyles shadowa tablescroll"
                style={{ marginTop: "1.5rem", padding: "1.5rem" }}
              >
                <Navbar
                  questions={taskPreview.questions}
                  steps={steps}
                  questionNumber={questionNumber}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="challengeattemptheader "
        style={{
          position: "absolute",
          width: "100vw",
          bottom: 0,
          left: 0,
          paddingBottom: "0.75rem",
          zIndex: 1,
          background: "#fff",
        }}
      >
        <hr style={{ marginTop: "0rem", marginBottom: "0.75rem" }} />
        <div className="space  flex-wrap" style={{ gap: "1.5rem" }}>
          {taskPreview && (
            <div className="d-flex" style={{ alignItems: "center" }}>
              <Tasknav
                steps={steps}
                taskCounter={taskCounter}
                total={total}
                isNonMobile1024={isNonMobile1024}
              />
            </div>
          )}
          <div
            className="d-flex flex-wrap"
            style={{
              gap: "1.5rem",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              background: "#FFF",
            }}
          >
            {steps === 3 && questionNumber < 1 ? (
              <></>
            ) : (
              <>
                {steps !== 4 && (
                  <button
                    className="backbutton"
                    style={{ maxHeight: "40px" }}
                    onClick={() => handleBack()}
                  >
                    Back
                  </button>
                )}
              </>
            )}
            <button
              className="continuebutton"
              style={{ maxHeight: "40px" }}
              onClick={() => handleNext()}
            >
              {steps !== 4 ? "Next" : "Submit"} <NavigateNextIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PreviewTask;
