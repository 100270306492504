// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certificate-outer {
  /* border-radius: 12px; */
  background: #fff;
  overflow: hidden;
  width: 1300px;
  min-width: 1300px;
  height: -moz-fit-content;
  height: fit-content;
}
.certificate-top-inner {
  width: 100%;
  background: var(
    --varyance-red,
    linear-gradient(136deg, #c01048 -4.38%, #a11043 67.26%, #89123e 102.63%)
  );
  padding: 60px 50px;
  position: relative;
  /* Trans Card: Icons */
  box-shadow: 0px 17.683px 14.146px 0px rgba(108, 73, 172, 0.04),
    0px 9.913px 7.93px 0px rgba(108, 73, 172, 0.04),
    0px 5.265px 4.212px 0px rgba(108, 73, 172, 0.03),
    0px 2.191px 1.753px 0px rgba(108, 73, 172, 0.02);
}

.certificate-v {
  color: rgba(255, 255, 255, 0.04);
  height: -moz-fit-content;
  height: fit-content;
  font-size: 573.43px;
  font-style: normal;
  font-weight: 400;
  line-height: 73%; /* 100% */
}
.certificate-user-name {
  color: var(--Base-White, #fff);

  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 78px */
}
.certificate-bottom-inner {
  padding: 60px 50px;
}
.certificate-percentage {
  color: var(--Secondary-Color-Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 100% */
  letter-spacing: -0.44px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ChallengeResult/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,iBAAiB;EACjB,wBAAmB;EAAnB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX;;;GAGC;EACD,kBAAkB;EAClB,kBAAkB;EAClB,sBAAsB;EACtB;;;oDAGkD;AACpD;;AAEA;EACE,gCAAgC;EAChC,wBAAmB;EAAnB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB,EAAE,SAAS;AAC7B;AACA;EACE,8BAA8B;;EAE9B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,+CAA+C;EAC/C,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;EAC5B,uBAAuB;AACzB","sourcesContent":[".certificate-outer {\n  /* border-radius: 12px; */\n  background: #fff;\n  overflow: hidden;\n  width: 1300px;\n  min-width: 1300px;\n  height: fit-content;\n}\n.certificate-top-inner {\n  width: 100%;\n  background: var(\n    --varyance-red,\n    linear-gradient(136deg, #c01048 -4.38%, #a11043 67.26%, #89123e 102.63%)\n  );\n  padding: 60px 50px;\n  position: relative;\n  /* Trans Card: Icons */\n  box-shadow: 0px 17.683px 14.146px 0px rgba(108, 73, 172, 0.04),\n    0px 9.913px 7.93px 0px rgba(108, 73, 172, 0.04),\n    0px 5.265px 4.212px 0px rgba(108, 73, 172, 0.03),\n    0px 2.191px 1.753px 0px rgba(108, 73, 172, 0.02);\n}\n\n.certificate-v {\n  color: rgba(255, 255, 255, 0.04);\n  height: fit-content;\n  font-size: 573.43px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 73%; /* 100% */\n}\n.certificate-user-name {\n  color: var(--Base-White, #fff);\n\n  font-size: 60px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 130%; /* 78px */\n}\n.certificate-bottom-inner {\n  padding: 60px 50px;\n}\n.certificate-percentage {\n  color: var(--Secondary-Color-Gray-950, #0c111d);\n  font-family: Poppins;\n  font-size: 60px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 60px; /* 100% */\n  letter-spacing: -0.44px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
