import { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./post.css";
import PostMedia from "./MediaGrid";
import Interaction from "./Interaction";
import WriteComment from "./WriteComment";
import PostComments from "./AllComments";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Report from "./Report";
import ThreeUsers from "../../assets/images/ThreeUsers";
import LockIcon from "../../assets/images/Lock";
import { useMediaQuery } from "@mui/material";
const PostContainer = ({
  post,
  student,
  setSelectedPost,

  getTimeElapsed,
  noMedia = false,
  setPosts,
  setEdit,
  isNon384,

  isNon850 = false,
}) => {
  const [user, setUser] = useState({});

  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const isNon414 = useMediaQuery("(min-width:414px)");
  const [likes, setLikes] = useState([]);
  const [commentShow, setCommentShow] = useState(noMedia ? true : false);
  const [totalComments, setTotalComments] = useState(0);
  const [commentOption, setCommentOption] = useState("Most recent");
  const [page, setPage] = useState(1);
  const [popup, setPopup] = useState(false);
  const popupref = useRef(null);
  const [loadingComments, setLoadingComments] = useState(false);
  const [loadingapi, setLoadingApi] = useState(false);

  const reportOptions = [
    "Nudity",
    "Violence",
    "Harassment",
    "Suicide or self-injury",
    "False information",
    "Spam",
    "Unauthorized sales",
    "Hate speech",
    "Terrorism",
    "Something Else",
  ];

  const [selectedReportOption, setSelectedOption] = useState("Nudity");

  const [reportPopup, setReportPopup] = useState(false);
  const [Data, setData] = useState(null);
  const [text, setText] = useState("");
  const handleReportComment = async (data) => {
    setData(data);
    setReportPopup(true);
  };

  const LoadMoreComments = async () => {
    setLoadingComments(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/get/more-comments`,
        { postId: post._id, page: page },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        if (res.data?.msg === "success") {
          setComments([...comments, ...res.data.comments]);
          setPage((prev) => prev + 1);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    setLoadingComments(false);
  };

  const SetSelectedPost = () => {
    setSelectedPost({ post: post, user: user });
  };

  useEffect(() => {
    if (post) {
      setComments(post?.comments);
      setLikes(post?.likes);
      setTotalComments(post.totalComments ? post?.totalComments : 0);
      setCommentOption("Most recent");
      setCommentShow(false);
    }
  }, [post]);

  useEffect(() => {
    const getuser = async () => {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/get/user/details/uniqueId`,
          { userId: post?.owner },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.message === "Found") {
            setUser(res.data.user);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (post) {
      getuser();
    }
  }, [post]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1440 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSavePost = async (test) => {
    if (!loadingapi) {
      setLoadingApi(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/post/${
            test ? "save" : "hide"
          }`,
          { postId: post._id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            toast.success(test ? "Post saved" : "Post hidden");
            if (!test) {
              setPosts((prev) => {
                return prev.filter((p) => p._id !== post._id);
              });
              setSelectedPost(null);
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      setLoadingApi(false);
      setPopup(false);
    }
  };

  const [reportloading, setReportLoading] = useState(false);

  const handleReportPost = async () => {
    if (!reportloading) {
      setReportLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/post/report`,
          {
            postId: post._id,
            reason:
              selectedReportOption === "Something Else"
                ? text
                  ? text
                  : selectedReportOption
                : selectedReportOption,
            Data: Data,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            toast.success(`${Data ? "Comment" : "Post"} reported`);
          } else if (res.data.msg === "Already") {
            toast.warning("Already Reported");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setReportPopup(false);
      setData(null);
      setSelectedOption(reportOptions[0]);
      setReportLoading(false);
    }
  };

  const [deleteloading, setDeleteLoading] = useState(false);

  const handleDeletePost = async () => {
    setDeleteLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/post/delete`,
        {
          postId: post._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Post deleted");
          setPosts((prev) => {
            return prev.filter((p) => p._id !== post._id);
          });
          setSelectedPost(null);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setDeletePopup(false);
        setDeleteLoading(false);
      });
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const collapsedHeight = 80;

  useEffect(() => {
    if (contentRef.current) {
      // Check if content is overflowing
      contentRef.current.style.height = `auto`;
      if (contentRef.current.scrollHeight > collapsedHeight) {
        setIsOverflowing(true);
        contentRef.current.style.height = `${collapsedHeight}px`;
      }
    }
  }, [post?.content]);

  const [deletePopup, setDeletePopup] = useState(false);

  return (
    <>
      {reportPopup && (
        <Report
          reportloading={reportloading}
          setPopup={setPopup}
          handleReportPost={handleReportPost}
          setText={setText}
          text={text}
          selectedReportOption={selectedReportOption}
          setSelectedOption={setSelectedOption}
          reportOptions={reportOptions}
          isNon384={isNon384}
          setData={setData}
          setReportPopup={setReportPopup}
        />
      )}
      {deletePopup && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.6)", // Darkened background a bit for better visibility
            zIndex: 1012, // Ensure it stays on top of all other content
          }}
        >
          <div
            style={{
              minWidth: "40vw",
              maxWidth: isNon850 ? "80vw" : "90vw",
              maxHeight: "90vh",
              overflowY: "auto",
              borderRadius: "5px",
              background: "#FFF",
              padding: "1.5rem",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="space gap-3">
              <div>
                <p className="gray90018500">Delete Post</p>
                <p className="mt-1 gray70014400">
                  After deleting your post you can't be able to undo. This post
                  will remove from your activity.
                </p>
              </div>
              <CloseIcon
                className="hoverdarkblacksvg"
                style={{ cursor: "pointer" }}
                onClick={() => setDeletePopup(false)}
              />
            </div>
            <div className="mt-4 d-flex flex-wrap justify-content-between justify-content-md-end gap-3">
              <button
                className="backbutton"
                onClick={() => setDeletePopup(false)}
                style={{ width: isNon414 ? "" : "100%" }}
                disabled={deleteloading}
              >
                Cancel
              </button>
              <button
                className="continuebutton"
                onClick={() => {
                  handleDeletePost();
                }}
                style={{ width: isNon414 ? "" : "100%" }}
                disabled={deleteloading}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className="postcontainer-outer  "
        style={{
          minHeight: noMedia && isNon850 ? "calc(70vh)" : "",
        }}
      >
        <div className="postcontainer-inner">
          {user && (
            <div className={`space`} style={{ gap: "1rem" }}>
              <div className="d-flex" style={{ gap: "1rem" }}>
                {user?.profilePhoto && (
                  <div className="profilephotocontainer justify-content-center align-items-center ">
                    <img
                      src={user?.profilePhoto}
                      alt="profile"
                      className=""
                      height={"40px"}
                      width={"40px"}
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                  </div>
                )}
                <div>
                  <a
                    href={`/student/user/${user?.link}`}
                    target="_black"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      className="postUserName text-start hoverdarkred"
                      style={{ cursor: "pointer" }}
                    >
                      {user?.firstName} {user?.LastName}
                    </p>
                  </a>
                  <p className="gray50012500" style={{ fontWeight: 400 }}>
                    {getTimeElapsed(post?.createdAt)}
                  </p>{" "}
                  {post?.edited ? <p className="gray50012500">Edited</p> : null}
                </div>
              </div>
              <div>
                <MoreVertIcon
                  className="threedot"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPopup(!popup);
                  }}
                />

                {popup && (
                  <div style={{ position: "relative" }}>
                    <div
                      className="shadowa"
                      style={{
                        position: "absolute",
                        zIndex: 5,
                        right: "0",
                        width: "180px",
                      }}
                      ref={popupref}
                    >
                      {post.owner === student._id ? (
                        <>
                          <button
                            className="dropdowntopbutton"
                            onClick={() => {
                              setEdit(post);
                              setPopup(false);
                              setSelectedPost(null);
                            }}
                          >
                            Edit Post
                          </button>
                          <button
                            className="dropdownmiddlebutton"
                            onClick={() => {
                              const link = `${process.env.REACT_APP_FRONTEND_URL}/student/post/${post?._id}`;
                              navigator.clipboard
                                .writeText(link)
                                .then(() => {
                                  toast.success("Link copied!");
                                })
                                .catch((err) => {
                                  toast.error("Failed to copy the link.");
                                });
                              setPopup(false);
                            }}
                          >
                            Copy Link
                          </button>
                          <button
                            className="dropdownbottombutton"
                            onClick={() => {
                              setDeletePopup(true);
                              setPopup(false);
                            }}
                          >
                            Delete Post
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            className="dropdowntopbutton"
                            onClick={() => {
                              handleSavePost({ test: true });
                            }}
                          >
                            Save Post
                          </button>
                          <button
                            className="dropdownmiddlebutton"
                            onClick={() => {
                              const link = `${process.env.REACT_APP_FRONTEND_URL}/student/post/${post?._id}`;
                              navigator.clipboard
                                .writeText(link)
                                .then(() => {
                                  toast.success("Link copied!");
                                })
                                .catch((err) => {
                                  toast.error("Failed to copy the link.");
                                });
                              setPopup(false);
                            }}
                          >
                            Copy Link
                          </button>
                          <button
                            className="dropdownmiddlebutton"
                            onClick={() => {
                              handleSavePost();
                            }}
                          >
                            Hide Post
                          </button>
                          <button
                            className="dropdownbottombutton"
                            onClick={() => {
                              setReportPopup(true);
                              setPopup(false);
                              setData(null);
                            }}
                          >
                            Report
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {post?.content && (
            <p
              style={{
                marginTop: "0.75rem",
                whiteSpace: "pre-line",
                marginTop: "0.75rem",
                whiteSpace: "pre-line",
                overflow: "hidden",
                height: isExpanded ? "auto" : collapsedHeight,
              }}
              ref={contentRef}
              className="posttext"
            >
              {post.content.split(/(\s+)/).map((word, index) =>
                word.startsWith("#") ? (
                  <span
                    key={index}
                    className="posttext"
                    style={{ color: "#E31b54" }}
                  >
                    {word}
                  </span>
                ) : (
                  word
                )
              )}
            </p>
          )}
          {isOverflowing && (
            <button
              className="pink14 hoverdarkred"
              onClick={toggleExpand}
              style={{
                marginTop: "0.5rem",
                background: "none",
                border: "none",
                outline: "none",
                padding: "0rem",
                fontWeight: 500,
              }}
            >
              {isExpanded ? "Show Less" : "Show More"}
            </button>
          )}
          {post?.postType === "grouptofeed" && post?.groupData?.title && (
            <a
              href={`/student/group/${post?.groupData?.link}`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <div style={{ margin: "1rem 0rem", cursor: "pointer" }}>
                <div style={{ borderRadius: "5px", overflow: "hidden" }}>
                  <img
                    src={post?.groupData?.banner}
                    alt="Selected file"
                    style={{
                      objectFit: "cover",
                      maxHeight: "200px",
                      width: "100%",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  />
                  <div style={{ background: "#F9FAFB", padding: "1.25rem" }}>
                    <p className="gray90014400">{post?.groupData?.title}</p>
                    <div
                      className="d-flex align-items-center gap-2"
                      style={{ marginTop: "1rem" }}
                    >
                      <div
                        className="center align-items-center"
                        style={{ minWidth: "20px" }}
                      >
                        {post?.groupData?.type === "Public" ? (
                          <ThreeUsers />
                        ) : (
                          <LockIcon />
                        )}
                      </div>
                      <p className="gray70014400">
                        {post?.groupData?.type === "Public"
                          ? "Public"
                          : "Private"}{" "}
                        Group
                      </p>
                    </div>

                    <div
                      className="d-flex align-items-center gap-2"
                      style={{ marginTop: "1rem" }}
                    >
                      <div className="center align-items-center">
                        {post?.groupData?.membersPics?.length > 0 &&
                          post?.groupData?.membersPics.map((pic, index) => (
                            <img
                              src={pic}
                              alt="memberspic"
                              key={index}
                              style={{
                                height: "24px",
                                width: "24px",
                                position: "relative",
                                marginLeft: index === 0 ? "" : "-10px",
                                zIndex: index,
                                borderRadius: "50%",
                                background: "#FFF",
                                border: "1px solid #FFF",
                              }}
                            />
                          ))}
                      </div>
                      <p className="gray70014400">
                        {post?.groupData?.members + 1 >= 1000
                          ? ((post?.groupData?.members + 1) / 1000).toFixed(1) +
                            "k"
                          : post?.groupData?.members + 1}{" "}
                        {post?.groupData?.members + 1 === 1
                          ? "Member"
                          : "Members"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          )}
          {!noMedia && post?.media && post?.media?.length > 0 && (
            <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <PostMedia
                media={post?.media}
                SetSelectedPost={SetSelectedPost}
              />
            </div>
          )}
          <div style={{ marginTop: "1rem" }}>
            <Interaction
              post={post}
              token={localStorage.getItem("token")}
              id={student?._id}
              comments={comments}
              likes={likes}
              setLikes={setLikes}
              totalComments={totalComments}
              setCommentShow={setCommentShow}
            />
          </div>
          <div style={{ marginTop: "1.25rem" }}>
            <WriteComment
              post={post}
              user={student}
              token={localStorage.getItem("token")}
              comments={comments}
              setComments={setComments}
              setTotalComments={setTotalComments}
              setCommentShow={setCommentShow}
              setLoading={setLoading}
              loading={loading}
            />
          </div>
          {comments.length > 0 && (
            <div
              className={`allComments ${
                commentShow || noMedia ? "showcomment" : "hidecomment"
              }`}
              style={{
                margin: commentShow ? "1.25rem 0rem" : "0rem",
                overflow: "hidden", // Always hidden to allow the maxHeight transition
              }}
            >
              <PostComments
                comments={comments}
                post={post}
                noMedia={noMedia}
                student={student}
                commentOption={commentOption}
                setCommentOption={setCommentOption}
                token={localStorage.getItem("token")}
                getTimeElapsed={getTimeElapsed}
                setComments={setComments}
                setTotalComments={setTotalComments}
                handleReportComment={handleReportComment}
              />
              {comments.length !== totalComments && (
                <div style={{ marginTop: "1.25rem" }}>
                  {loadingComments ? (
                    <div
                      className="center align-items-center"
                      style={{
                        width: "100%",
                        height: "100%",
                        zIndex: 2,
                        background: "rgba(255,255,255,0.5)",
                      }}
                    >
                      <div className="line-loader"></div>
                    </div>
                  ) : (
                    <p
                      className="gray70014400 hoverred"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        LoadMoreComments();
                      }}
                    >
                      Load More comments
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default PostContainer;
