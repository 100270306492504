import Marquee from "react-fast-marquee";
import img from "../../assets/images/icons/corporatemarquee.png";
const Second = () => {
  return (
    <div style={{ padding: "8.125rem 0rem" }}>
      <div className="marginsleftrightCompany">
        <div className="center">
          <div className="headerCompany">
            <div className="center align-items-center flex-column">
              <div className="d-flex">
                <div className="center align-items-center" style={{}}>
                  <p className="pink16">
                    {" "}
                    Explore{" "}
                    <span className="pink16" style={{ fontWeight: 600 }}>
                      {" "}
                      Challenges
                    </span>
                  </p>
                </div>
              </div>
              <p
                className="text-center itsearly "
                style={{ maxWidth: "500px", width: "100%" }}
              >
                Learn and expand skills relevant to your future career.
              </p>
              <p
                className="text-center gray95016400"
                style={{
                  margin: "1rem 0rem",
                  fontFamily: "Poppins",
                  maxWidth: "858px",
                }}
              >
                With challenges, you'll gain the skills needed to excel in
                corporate environments across various industries. Get hands-on
                experience with real-world tasks{" "}
                <span
                  className="gray95016500"
                  style={{ fontFamily: "Poppins", fontWeight: 500 }}
                >
                  designed by industry leaders{" "}
                </span>
                that prepare you to thrive in enterprise-level roles.
              </p>
              <div
                className="d-flex flex-wrap gray95016500"
                style={{ gap: "1.25rem", fontWeight: 600 }}
              >
                <p className="gray95016500" style={{ fontWeight: 600 }}>
                  24+ Career Paths
                </p>
                |{" "}
                <p className="gray95016500" style={{ fontWeight: 600 }}>
                  {" "}
                  300+ Skills
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "5rem",

          overflow: "hidden",
        }}
      >
        <Marquee pauseOnHover speed={80}>
          <img src={img} alt="all skills" style={{ maxHeight: "202px" }} />
        </Marquee>
      </div>
    </div>
  );
};
export default Second;
