import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const Center = ({ email, setDone, sendVerifyEmail, loading }) => {
  return (
    <div
      className="container d-flex"
      style={{
        height: "76vh",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <div
        className="d-flex"
        style={{
          justifyContent: "center",
          marginTop: "-100px",
        }}
      >
        <div className="row">
          <div className="d-flex " style={{ justifyContent: "center" }}>
            <div className="emailicon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.0007 4.66699H7.00065C4.42332 4.66699 2.33398 6.75633 2.33398 9.33366V19.8337C2.33398 22.411 4.42332 24.5003 7.00065 24.5003H21.0007C23.578 24.5003 25.6673 22.411 25.6673 19.8337V9.33366C25.6673 6.75633 23.578 4.66699 21.0007 4.66699ZM7.00065 6.52199H21.0006C22.244 6.52437 23.3369 7.34639 23.684 8.54033L14.8873 15.062C14.6462 15.2977 14.3194 15.4248 13.9824 15.4138C13.6454 15.4027 13.3275 15.2546 13.1023 15.0037L4.34065 8.55199C4.68103 7.36167 5.7627 6.53618 7.00065 6.52199ZM4.18898 19.8337C4.18898 21.3865 5.44781 22.6453 7.00065 22.6453H21.0007C22.5489 22.6389 23.8007 21.382 23.8006 19.8337V10.4653L15.8673 16.2987C15.3603 16.7707 14.6934 17.0333 14.0007 17.0337C13.2805 17.0222 12.5903 16.7436 12.064 16.252L4.18898 10.4187V19.8337Z"
                  fill="#E31B54"
                />
              </svg>
            </div>
          </div>
          <h2 className="text-center mt-3">Check your email</h2>
          <p className="text-center mt-3 sentemail">
            We sent a verification link to
            <br />
            <span> {email ? email : "olivia@untitledui.com"}</span>
          </p>
          <div>
            <p className="text-center mt-3 didnt">
              Didn’t receive the email?
              <span
                onClick={() => {
                  if (!loading) {
                    sendVerifyEmail();
                  }
                }}
                className="hoverdarkred"
              >
                Click to resend
              </span>
            </p>
          </div>
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <button
              className="btn col-md-2 d-flex backto"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
              onClick={() => setDone(false)}
            >
              <KeyboardBackspaceIcon className="mt-0 mb-0 m-2 svgcolorbackto" />
              <p className="backtologina"> Back to Register</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Center;
