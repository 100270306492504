const FeedIcon = ({ color }) => {
  return (
    <svg
      className="strokesvg"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91199 2.34933H7.47363C8.53498 2.34933 9.39538 3.1888 9.39538 4.22433V6.03267C9.39538 7.0682 8.53498 7.90767 7.47363 7.90767H3.91199C2.85063 7.90767 1.99023 7.0682 1.99023 6.03267V4.22433C1.99023 3.1888 2.85063 2.34933 3.91199 2.34933ZM7.47363 6.65767C7.82549 6.65318 8.10961 6.37597 8.11421 6.03267V4.22433C8.10961 3.88103 7.82549 3.60382 7.47363 3.59933H3.91199C3.56012 3.60382 3.276 3.88103 3.2714 4.22433V6.03267C3.276 6.37597 3.56012 6.65318 3.91199 6.65767H7.47363Z"
        fill={color || "#344054"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5976 2.34933H16.587C17.6483 2.34933 18.5087 3.1888 18.5087 4.22433V9.916C18.5087 10.9515 17.6483 11.791 16.587 11.791H13.5976C13.0872 11.7932 12.5971 11.5964 12.2362 11.2443C11.8753 10.8922 11.6736 10.414 11.6758 9.916V4.22433C11.6758 3.1888 12.5362 2.34933 13.5976 2.34933ZM16.587 10.541C16.9408 10.541 17.2276 10.2612 17.2276 9.916V4.22433C17.223 3.88103 16.9388 3.60382 16.587 3.59933H13.5976C13.2438 3.59933 12.957 3.87916 12.957 4.22433V9.916C12.9547 10.0824 13.0214 10.2427 13.1421 10.3604C13.2627 10.4781 13.427 10.5433 13.5976 10.541H16.587Z"
        fill={color || "#344054"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5976 14.016H16.587C17.6484 14.016 18.5088 14.8555 18.5088 15.891V17.141C18.5088 18.1765 17.6484 19.016 16.587 19.016H13.5976C12.5363 19.016 11.6759 18.1765 11.6759 17.141V15.891C11.6759 14.8555 12.5363 14.016 13.5976 14.016ZM16.587 17.766C16.9389 17.7615 17.223 17.4843 17.2276 17.141V15.891C17.223 15.5477 16.9389 15.2705 16.587 15.266H13.5976C13.2438 15.266 12.957 15.5458 12.957 15.891V17.141C12.957 17.4862 13.2438 17.766 13.5976 17.766H16.587Z"
        fill={color || "#344054"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91199 10.1243H7.47363C8.53498 10.1243 9.39538 10.9638 9.39538 11.9993V17.141C9.39538 18.1765 8.53498 19.016 7.47363 19.016H3.91199C2.85063 19.016 1.99023 18.1765 1.99023 17.141V11.9993C1.99023 10.9638 2.85063 10.1243 3.91199 10.1243ZM7.47363 17.766C7.82549 17.7615 8.10961 17.4843 8.11421 17.141V11.9993C8.10961 11.656 7.82549 11.3788 7.47363 11.3743H3.91199C3.56012 11.3788 3.276 11.656 3.2714 11.9993V17.141C3.276 17.4843 3.56012 17.7615 3.91199 17.766H7.47363Z"
        fill={color || "#344054"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91199 2.34933H7.47363C8.53498 2.34933 9.39538 3.1888 9.39538 4.22433V6.03267C9.39538 7.0682 8.53498 7.90767 7.47363 7.90767H3.91199C2.85063 7.90767 1.99023 7.0682 1.99023 6.03267V4.22433C1.99023 3.1888 2.85063 2.34933 3.91199 2.34933ZM7.47363 6.65767C7.82549 6.65318 8.10961 6.37597 8.11421 6.03267V4.22433C8.10961 3.88103 7.82549 3.60382 7.47363 3.59933H3.91199C3.56012 3.60382 3.276 3.88103 3.2714 4.22433V6.03267C3.276 6.37597 3.56012 6.65318 3.91199 6.65767H7.47363Z"
        stroke={color || "#344054"}
        strokeWidth="0.50735"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5976 2.34933H16.587C17.6483 2.34933 18.5087 3.1888 18.5087 4.22433V9.916C18.5087 10.9515 17.6483 11.791 16.587 11.791H13.5976C13.0872 11.7932 12.5971 11.5964 12.2362 11.2443C11.8753 10.8922 11.6736 10.414 11.6758 9.916V4.22433C11.6758 3.1888 12.5362 2.34933 13.5976 2.34933ZM16.587 10.541C16.9408 10.541 17.2276 10.2612 17.2276 9.916V4.22433C17.223 3.88103 16.9388 3.60382 16.587 3.59933H13.5976C13.2438 3.59933 12.957 3.87916 12.957 4.22433V9.916C12.9547 10.0824 13.0214 10.2427 13.1421 10.3604C13.2627 10.4781 13.427 10.5433 13.5976 10.541H16.587Z"
        stroke={color || "#344054"}
        strokeWidth="0.50735"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5976 14.016H16.587C17.6484 14.016 18.5088 14.8555 18.5088 15.891V17.141C18.5088 18.1765 17.6484 19.016 16.587 19.016H13.5976C12.5363 19.016 11.6759 18.1765 11.6759 17.141V15.891C11.6759 14.8555 12.5363 14.016 13.5976 14.016ZM16.587 17.766C16.9389 17.7615 17.223 17.4843 17.2276 17.141V15.891C17.223 15.5477 16.9389 15.2705 16.587 15.266H13.5976C13.2438 15.266 12.957 15.5458 12.957 15.891V17.141C12.957 17.4862 13.2438 17.766 13.5976 17.766H16.587Z"
        stroke={color || "#344054"}
        strokeWidth="0.50735"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91199 10.1243H7.47363C8.53498 10.1243 9.39538 10.9638 9.39538 11.9993V17.141C9.39538 18.1765 8.53498 19.016 7.47363 19.016H3.91199C2.85063 19.016 1.99023 18.1765 1.99023 17.141V11.9993C1.99023 10.9638 2.85063 10.1243 3.91199 10.1243ZM7.47363 17.766C7.82549 17.7615 8.10961 17.4843 8.11421 17.141V11.9993C8.10961 11.656 7.82549 11.3788 7.47363 11.3743H3.91199C3.56012 11.3788 3.276 11.656 3.2714 11.9993V17.141C3.276 17.4843 3.56012 17.7615 3.91199 17.766H7.47363Z"
        stroke={color || "#344054"}
        strokeWidth="0.50735"
      />
    </svg>
  );
};

export default FeedIcon;
