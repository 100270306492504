const ThreeUsers = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3329 7.29999C13.3057 8.66113 12.1944 9.75026 10.8329 9.74999C9.48776 9.72783 8.40134 8.64509 8.37461 7.29999V6.35832C8.37445 5.80364 8.59816 5.27237 8.99505 4.88489C9.39195 4.49741 9.92843 4.28651 10.4829 4.29999H11.2663C11.8136 4.29777 12.3393 4.51365 12.7271 4.8999C13.1149 5.28616 13.3329 5.81097 13.3329 6.35832V7.29999ZM10.4579 5.52499C9.99771 5.52499 9.62461 5.89808 9.62461 6.35832L9.65794 7.29999C9.65794 7.96733 10.1989 8.50832 10.8663 8.50832C11.5336 8.50832 12.0746 7.96733 12.0746 7.29999V6.35832C12.0746 5.89808 11.7015 5.52499 11.2413 5.52499H10.4579Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5663 12.75L19.6579 14.05C19.7543 14.4568 19.6602 14.8854 19.4024 15.2146C19.1446 15.5437 18.751 15.7377 18.3329 15.7417H14.5913C14.2649 16.0896 13.81 16.2884 13.3329 16.2917H8.33295C7.83176 16.2984 7.35198 16.0889 7.01628 15.7167C6.93874 15.7253 6.86049 15.7253 6.78295 15.7167H2.95795C2.55126 15.7013 2.17226 15.5066 1.92296 15.1849C1.67365 14.8632 1.57966 14.4476 1.66628 14.05L1.79128 12.75C1.91288 12.1297 2.45095 11.6784 3.08295 11.6667H6.66628C6.81585 11.6584 6.96566 11.6781 7.10795 11.725C7.42766 11.3256 7.91306 11.0952 8.42461 11.1H13.2413C13.7229 11.0982 14.1817 11.3048 14.4996 11.6667C14.5577 11.6584 14.6166 11.6584 14.6746 11.6667H18.2746C18.9066 11.6784 19.4447 12.1297 19.5663 12.75ZM14.6246 14.4667H18.3996C18.4251 14.4176 18.4282 14.3598 18.4079 14.3083V14.1417L18.3246 12.9583L18.2746 12.8917H14.4996C14.1473 12.8929 13.8111 12.7445 13.5746 12.4833C13.4919 12.3844 13.3702 12.3266 13.2413 12.325H8.42462C8.28501 12.3268 8.15353 12.391 8.06628 12.5C7.8284 12.7824 7.47717 12.9442 7.10795 12.9417C6.95676 12.9423 6.80683 12.9141 6.66628 12.8583H3.12462C3.07462 12.8583 3.07462 12.875 3.07462 12.925L2.99128 14.1083V14.275C2.96846 14.3253 2.96846 14.383 2.99128 14.4333H6.81628C6.90498 14.4252 6.99425 14.4252 7.08295 14.4333C7.42161 14.4396 7.74208 14.5878 7.96628 14.8417C8.05823 14.9493 8.19147 15.0128 8.33295 15.0167H13.3663C13.508 15.0139 13.6417 14.9501 13.733 14.8417C13.9673 14.6013 14.2889 14.466 14.6246 14.4667Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3079 8.54999C18.3079 9.56251 17.4871 10.3833 16.4746 10.3833C15.9869 10.3855 15.5185 10.1934 15.1729 9.8493C14.8272 9.50525 14.6329 9.03766 14.6329 8.54999V7.84999C14.6329 7.44111 14.7954 7.04898 15.0845 6.75986C15.3736 6.47074 15.7657 6.30832 16.1746 6.30832H16.7663C17.6177 6.30832 18.3079 6.99855 18.3079 7.84999V8.54999ZM15.9684 7.67708C15.9137 7.73178 15.8829 7.80596 15.8829 7.88332V8.58332C15.9184 8.88087 16.1708 9.10489 16.4704 9.10489C16.7701 9.10489 17.0224 8.88087 17.0579 8.58332V7.88332C17.0579 7.72224 16.9274 7.59165 16.7663 7.59165H16.1746C16.0973 7.59165 16.0231 7.62238 15.9684 7.67708Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1847 9.8493C5.83908 10.1934 5.37062 10.3855 4.88295 10.3833C4.39672 10.3833 3.9304 10.1902 3.58659 9.84635C3.24277 9.50253 3.04961 9.03622 3.04961 8.54999V7.84999C3.04961 6.99855 3.73984 6.30832 4.59128 6.30832H5.18295C6.03439 6.30832 6.72461 6.99855 6.72461 7.84999V8.54999C6.72462 9.03766 6.53032 9.50525 6.1847 9.8493ZM4.59128 7.59165C4.4302 7.59165 4.29961 7.72224 4.29961 7.88332V8.58332C4.33511 8.88087 4.58746 9.10489 4.88711 9.10489C5.18677 9.10489 5.43911 8.88087 5.47461 8.58332V7.88332C5.47461 7.72224 5.34403 7.59165 5.18295 7.59165H4.59128Z"
        fill="#344054"
      />
    </svg>
  );
};

export default ThreeUsers;
