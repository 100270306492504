const AddPublication = ({
  newPublication,
  setNewPublication,

  handleConfirm,
}) => {
  return (
    <div>
      <p className="black14 mt-4">Title:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newPublication?.title}
        placeholder="Enter Publication Title"
        onChange={(e) => {
          setNewPublication((prev) => {
            return {
              ...prev,
              title: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Published Date:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newPublication?.date}
        type="date"
        placeholder="Enter Published Date"
        onChange={(e) => {
          setNewPublication((prev) => {
            return {
              ...prev,
              date: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Link(URL):</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newPublication?.link}
        placeholder="Enter Link(URL)"
        onChange={(e) => {
          setNewPublication((prev) => {
            return {
              ...prev,
              link: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Description:</p>
      <textarea
        className="col-md-12 col-12 forminput mt-3"
        value={newPublication?.description}
        type="text"
        placeholder="Explain the achievement"
        onChange={(e) => {
          setNewPublication((prev) => {
            return {
              ...prev,
              description: e.target.value,
            };
          });
        }}
        maxLength={200}
      />
      <button
        className="continuebutton mt-4 mb-5"
        onClick={() => handleConfirm()}
      >
        Confirm
      </button>
    </div>
  );
};

export default AddPublication;
