import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const SelectOption = ({ value, setValue, options, placeholder }) => {
  const [popup, setPopup] = useState(false);
  return (
    <div>
      <div
        className="forminput mt-4 space gap-2"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setPopup(!popup);
        }}
      >
        {value ? (
          <p className="gray95014400">{value}</p>
        ) : (
          <p className="gray50014400">{placeholder}</p>
        )}
        <KeyboardArrowDownIcon style={{ height: "16px", width: "16px" }} />
      </div>
      {popup && (
        <div
          className="mt-1 shadowa d-flex flex-column"
          style={{
            borderRadius: "5px 5px 0px 0px",
            background: "#FFF",
            width: "100%",
            maxHeight: "165px",
            overflowY: "auto",
            position: "absolute",
            zIndex: 5,
          }}
        >
          {options.map((opt, index) => (
            <div
              key={index}
              value={opt}
              className="gray60011400 py-2 px-3 hoverred"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                cursor: "pointer",

                border: "none",
                borderBottom:
                  index !== options.length - 1 ? "1px solid #DEE3E6" : "",
              }}
              onClick={() => {
                setValue(opt);
                setPopup(false);
              }}
            >
              {opt}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectOption;
