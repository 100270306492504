import Calender from "../../../assets/images/icons/Calender";
import ThreeUsers from "../../../assets/images/ThreeUsers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Information from "./GroupInfo";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import CreateGroup from "../CreateGroup";
import Report from "../../SocialFeed/Report";
import ShareGroup from "./ShareToFeed";
import LockIcon from "../../../assets/images/Lock";

const Group = ({
  group,
  student,
  setPosts,
  posts,
  requests,
  setGroup,
  isNon384,
}) => {
  const [members, setMembers] = useState([]);

  function formatDate(inputDate) {
    // Create a new Date object from the input string
    const date = new Date(inputDate);

    // Format options for day of the week, month, day, and year
    const options = {
      // weekday: "short", // 'Wed'
      year: "numeric", // '2025'
      month: "short", // 'Jan'
      day: "2-digit", // '03'
    };

    // Format the date
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate.replace(/,/g, ","); // Ensures correct format with commas
  }
  const [loading, setLoading] = useState(false);

  const [popup, setPopup] = useState(false);

  const handleJoinGroup = async () => {
    if (isMember(student?._id)) return toast.error("Already a member");
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/join/group`,
        {
          groupId: group?._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          if (group.type === "Public") {
            toast.success("Joined Successfully");
            group.members.push(student?._id);
            setMembers((prev) => [
              ...prev,
              {
                _id: student?._id,
                firstName: student.firstName,
                lastName: student.LastName, // Note: "lastName" instead of "LastName"
                profilePhoto: student.profilePhoto,
                uniName: student.uni.name,
              },
            ]);
          } else {
            toast.success("Requested Successfully");
            requests.push(group?._id);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setLoading(false);
  };

  const isMember = (id) => {
    if (group?.owner === id) {
      return true;
    } else if (group?.members?.includes(id)) {
      return true;
    } else {
      return false;
    }
  };

  const isAdmin = (id) => {
    if (group?.owner === id) {
      return true;
    } else if (group?.admin?.includes(id)) {
      return true;
    } else {
      return false;
    }
  };
  const isJustMember = (id) => {
    if (group?.members?.includes(id)) {
      return true;
    } else {
      return false;
    }
  };

  const popupref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [editPopUp, setEditPopUp] = useState(false);
  const [report, setReport] = useState(false);

  const [reportloading, setReportLoading] = useState(false);
  const [text, setText] = useState("");
  const [selectedReportOption, setSelectedOption] = useState("Nudity");
  const reportOptions = [
    "Nudity",
    "Violence",
    "Harassment",
    "Suicide or self-injury",
    "False information",
    "Spam",
    "Unauthorized sales",
    "Hate speech",
    "Terrorism",
    "Something Else",
  ];

  const [Data, setData] = useState(null);
  const [leaveLoading, setLeaveLoading] = useState(false);
  const handleLeaveGroup = async () => {
    if (student?._id === group?.owner)
      return toast.error("You cannot leave your own group");
    if (!leaveLoading) {
      setLeaveLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/leave/group`,
          {
            groupId: group._id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLeaveLoading(false);
          if (res.data.msg === "success") {
            toast.success("Group Left Successfully");
            setGroup((prev) => {
              return {
                ...prev,
                members: prev.members.filter((id) => id !== student._id),
                admins: prev.admins.filter((id) => id !== student._id),
              };
            });
            setMembers((prev) => {
              return prev.filter((member) => member._id !== student._id);
            });
          } else {
            toast.error("Error Leaving Group");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error Leaving Group");
          setLeaveLoading(false);
        });
    }
  };

  const handleReportPost = async () => {
    if (!reportloading) {
      setReportLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/group/report`,
          {
            groupId: group._id,
            reason:
              selectedReportOption === "Something Else"
                ? text
                  ? text
                  : selectedReportOption
                : selectedReportOption,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            toast.success(`${Data ? "Comment" : "Post"} reported`);
          } else if (res.data.msg === "Already") {
            toast.warning("Already Reported");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setReport(false);
      setData(null);
      setSelectedOption(reportOptions[0]);
      setReportLoading(false);
    }
  };

  const [shareToFeed, setShareToFeed] = useState(false);
  const [loadingShare, setLoadingShare] = useState(false);
  const handleShare = async (txt) => {
    if (!loadingShare) {
      setLoadingShare(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/share/group/feed`,
          {
            groupId: group?._id,
            content: txt,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            toast.success("Shared to feed");
          }
          setLoadingShare(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error Sharing to feed");
          setLoadingShare(false);
        });
      setShareToFeed(false);
    }
  };

  return (
    <div>
      <div style={{ background: "#FFF", borderRadius: "5px" }}>
        {shareToFeed && (
          <ShareGroup
            group={group}
            setShareToFeed={setShareToFeed}
            handleShare={handleShare}
            loadingShare={loadingShare}
          />
        )}

        {report && (
          <Report
            reportloading={reportloading}
            setPopup={setReport}
            handleReportPost={handleReportPost}
            setText={setText}
            text={text}
            selectedReportOption={selectedReportOption}
            setSelectedOption={setSelectedOption}
            reportOptions={reportOptions}
            isNon384={isNon384}
            setData={setData}
            setReportPopup={setReport}
          />
        )}
        {editPopUp && (
          <CreateGroup
            setPopup={setEditPopUp}
            group={group}
            setGroups={setGroup}
          />
        )}
        <div style={{ position: "relative" }}>
          <img
            src={group?.banner}
            alt="Selected file"
            style={{
              objectFit: "cover",
              maxHeight: "200px",
              minHeight: "200px",
              width: "100%",
              borderRadius: "5px 5px 0px 0px",
            }}
          />
        </div>
        <div className="paddingAdjust" style={{}}>
          <img
            src={group?.profilePhoto}
            alt="Selected file"
            className="shadowa"
            style={{
              objectFit: "cover",
              maxHeight: "80px",
              width: "80px",
              borderRadius: "50%",
              position: "relative",
              marginTop: "-80px",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />

          <p
            className="gray90018500"
            style={{
              fontSize: "20px",
              fontWeight: 400,
              fontFamily: "FilmotypeFashion",
            }}
          >
            {group?.title}
          </p>

          <div
            className="d-flex align-items-center gap-2"
            style={{ marginTop: "1rem" }}
          >
            <div
              className="center align-items-center"
              style={{ minWidth: "20px" }}
            >
              {group?.type === "Public" ? <ThreeUsers /> : <LockIcon />}
            </div>
            <p className="gray70014400">
              {group?.type === "Public" ? "Public" : "Private"} Group
            </p>
          </div>
          <div
            className="d-flex align-items-center gap-2"
            style={{ marginTop: "1rem" }}
          >
            <div
              className="center align-items-center"
              style={{ minWidth: "20px" }}
            >
              <Calender height={"18px"} width={"18px"} />
            </div>
            <p className="gray70014400">
              Created on{" "}
              <span className="gray70014400" style={{ fontWeight: 500 }}>
                {formatDate(group?.createdAt)}
              </span>
            </p>
          </div>
          <div
            className="d-flex align-items-center gap-2"
            style={{ marginTop: "1rem" }}
          >
            <div className="center align-items-center">
              {group?.membersPics?.length > 0 &&
                group.membersPics.map((pic, index) => (
                  <img
                    src={pic}
                    alt="memberspic"
                    key={index}
                    style={{
                      height: "24px",
                      width: "24px",
                      position: "relative",
                      marginLeft: index === 0 ? "" : "-10px",
                      zIndex: index,
                      borderRadius: "50%",
                      background: "#FFF",
                      border: "1px solid #FFF",
                    }}
                  />
                ))}
            </div>
            <p className="gray70014400">
              {group?.members?.length + 1 >= 1000
                ? ((group?.members?.length + 1) / 1000).toFixed(1) + "k"
                : group?.members?.length + 1}{" "}
              {group?.members?.length + 1 === 1 ? "Member" : "Members"}
            </p>
          </div>
          <div
            className="d-flex align-items-center "
            style={{ marginTop: "1.5rem", gap: "1.5rem" }}
          >
            <button
              className="continuebutton"
              onClick={() => {
                handleJoinGroup(true);
              }}
              disabled={
                loading ||
                leaveLoading ||
                isMember(student?._id) ||
                requests.includes(group?._id)
              }
            >
              {isMember(student?._id)
                ? "Invite Connect"
                : loading
                ? "Joining..."
                : requests.includes(group?._id)
                ? "Requested"
                : "Join Group"}
            </button>
            <div style={{ position: "relative" }}>
              <button
                className="backbutton hoverred"
                style={{ padding: "10px", maxHeight: "40px" }}
                onClick={() => {
                  setPopup(!popup);
                }}
              >
                <MoreVertIcon className="" style={{ cursor: "pointer" }} />
                {popup && (
                  <div
                    className="shadowa"
                    style={{
                      position: "absolute",
                      background: "#FFF",
                      borderRadius: "5px",
                      overflow: "hidden",
                      right: 0,
                      top: "100%",
                      width: "180px",
                    }}
                    ref={popupref}
                  >
                    {isAdmin(student?._id) && (
                      <button
                        className="dropdownmiddlebutton"
                        onClick={() => {
                          setEditPopUp(true);
                        }}
                      >
                        Edit Details
                      </button>
                    )}
                    {isMember(student?._id) && (
                      <button
                        className="dropdownmiddlebutton"
                        onClick={() => {
                          setShareToFeed(true);
                        }}
                      >
                        Share to feed
                      </button>
                    )}
                    <button className="dropdownmiddlebutton">
                      Send in Message
                    </button>
                    <button
                      className="dropdownmiddlebutton"
                      onClick={async () => {
                        try {
                          // Check if the Clipboard API is supported
                          if (
                            navigator.clipboard &&
                            navigator.clipboard.writeText
                          ) {
                            // Copy the current URL to the clipboard
                            await navigator.clipboard.writeText(
                              window.location.href
                            );
                            toast.success("Link copied to clipboard");
                          } else {
                            // Fallback: Create a temporary input to copy the URL for older browsers
                            const input = document.createElement("input");
                            input.value = window.location.href;
                            document.body.appendChild(input);
                            input.select();
                            document.execCommand("copy");
                            document.body.removeChild(input);
                            toast.success(
                              "Link copied to clipboard (fallback)"
                            );
                          }
                        } catch (error) {
                          // Handle any errors
                          toast.error(
                            "Failed to copy the link. Please try again."
                          );
                          console.error("Clipboard error: ", error);
                        }
                      }}
                    >
                      Copy Link
                    </button>
                    {isJustMember(student?._id) && (
                      <button
                        className="dropdownmiddlebutton"
                        disabled={leaveLoading}
                        onClick={() => {
                          handleLeaveGroup();
                        }}
                      >
                        Leave Group
                      </button>
                    )}
                    {!isAdmin(student?._id) && (
                      <button
                        className="dropdownmiddlebutton"
                        onClick={() => {
                          setReport(true);
                        }}
                        disabled={reportloading}
                      >
                        Report
                      </button>
                    )}
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <Information
          group={group}
          isMember={isMember}
          student={student}
          setPosts={setPosts}
          posts={posts}
          members={members}
          setMembers={setMembers}
        />
      </div>
    </div>
  );
};

export default Group;
