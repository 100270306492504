import { useMediaQuery } from "@mui/material";
import Chart from "../../../assets/images/icons/Chart.png";
import First from "./First";
import SecondSec from "../Second";
import vid from "../../../assets/images/ani3.webm";
import Third from "./Third";
import Track from "../../../assets/images/icons/Track.png";
import search from "../../../assets/images/searcha.png";
import marketing from "../../../assets/images/icons/marketing.png";
import image1 from "../../../assets/images/analytics1.png";

import image2 from "../../../assets/images/analytics2.png";
import image3 from "../../../assets/images/analytics3.png";
import SameSection from "../SameSection";

import HomeFooter from "../../../components/Home/HomeFooter";
import NewComp from "../../Home/NewComp";
import TitleDescMarquee from "../../CompanyHome/MarqueeSection";
import FourSquare from "../../../assets/images/icons/foursquare";
import Lightning from "../../../assets/images/icons/Lightning";
import CompanyAction from "../../CompanyHome/Marquee";
const AnalyticsHome = () => {
  const isNon1024 = useMediaQuery("(min-width:1024px)");

  const isNon530 = useMediaQuery("(min-width:530px)");

  const samesections = [
    {
      icon: (
        <div
          className="center align-items-center p-2 rounded-5"
          style={{
            background: "rgba(65, 107, 255, 0.10)",
            height: "40px",
            width: "40px",
          }}
        >
          <FourSquare />
        </div>
      ),
      title: (
        <>
          Assess the
          <br /> candidate journey
        </>
      ),
      description:
        "Monitor every step of the candidate journey, from their first challenge to Q&A events, all the way through to job applications and on-platform interviews. Gain insights into engagement touchpoints to optimize different channels.",
      img: image1,
      chips: [
        {
          icon: Chart,
          text: "Touchpoint Analytics",
        },
        {
          icon: Track,
          text: "Total Engagement",
        },
        {
          icon: marketing,
          text: "Challenge Completions",
        },
        {
          icon: search,
          text: "Shortlist",
        },
      ],
    },
    {
      icon: (
        <div
          className="center align-items-center p-2 rounded-5"
          style={{
            background: "rgba(252, 131, 9, 0.10)",
            height: "40px",
            width: "40px",
          }}
        >
          <Lightning />
        </div>
      ),
      title: (
        <>
          How is student
          <br /> engagement analyzed?
        </>
      ),
      description: (
        <>
          <span className="gray95016500">Participation Rates:</span> We monitor
          how many students sign up and participate, giving you insight into
          initial interest levels. <br /> <br />
          <span className="gray95016500">Completion Rates:</span> By tracking
          how many students complete the tasks and challenges, we assess their
          commitment and follow-through.
          <br /> <br />
          <span className="gray95016500">Interaction Analytics:</span> Actions
          like company page visits and job applications also contribute to the
          overall score.
        </>
      ),

      img: image2,
      chips: [],
      img2: image3,
    },
  ];

  return (
    <NewComp>
      <div
        style={{
          maxWidth: "100vw",
          minWidth: "100vw",
        }}
      >
        <div style={{ position: "relative" }}>
          <First isNon530={isNon530} />
          <SecondSec classes={"secondAnalyticsHome"} vid={vid} />
          <Third />
          <div style={{ background: "rgba(242, 244, 247, 0.8)" }}>
            <div className={`marginsleftrightCompany marginsStudent`}>
              {samesections.map((same, index) => (
                <div key={index}>
                  <SameSection
                    isNonMobile1000={isNon1024}
                    icon={same.icon}
                    title={same.title}
                    description={same.description}
                    chips={same.chips}
                    img={same?.img}
                    img2={same?.img2}
                  />
                </div>
              ))}
            </div>
          </div>
          {/* <Fifthnew />
          <Second isNon={isNon} /> */}
          <div
            style={{
              background: "rgba(242, 244, 247, 0.8)",
              padding: "80px 0px",
            }}
          >
            <TitleDescMarquee toRemove="Analytics" />
          </div>
          <CompanyAction />

          <div className="" style={{ background: "#0C111D" }}>
            <div className="marginsleftrightCompany  pt-5">
              {/* <FourthSection /> */}
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <div className="headerCompany">
                  <HomeFooter />
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </NewComp>
  );
};

export default AnalyticsHome;
