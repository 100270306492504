import upload from "../../assets/images/icons/upload.png";
import ReactQuill from "react-quill";
import { useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { setup, isSupported } from "@loomhq/record-sdk";
import { oembed } from "@loomhq/loom-embed";
import axios from "axios";
import loom from "../../assets/images/loom.svg";
const BUTTON_ID = "dasdafafdsffsadfdadsfdasfgdak";
const TaskStep1 = ({
  taskCount,
  newTasks,
  setNewTasks,
  setTaskSteps,
  toast,
}) => {
  const isNonMob800 = useMediaQuery("(min-width:800px)");
  const handleFileUpload = (e) => {
    const file = e.target.files?.[0];

    // Check if the file is an MP4 video
    if (file && file.type === "video/mp4") {
      setNewTasks((prevTasks) => {
        return prevTasks.map((task, index) => {
          if (index === taskCount) {
            return { ...task, introVideo: file };
          }
          return task;
        });
      });
    } else {
      toast.error("Please upload a valid .mp4 video file.");
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.target.files?.[0];

    // Check if the file is an MP4 video
    if (file && file.type === "video/mp4") {
      setNewTasks((prevTasks) => {
        return prevTasks.map((task, index) => {
          if (index === taskCount) {
            return { ...task, introVideo: e.dataTransfer.files[0] };
          }
          return task;
        });
      });
    } else {
      toast.error("Please upload a valid .mp4 video file.");
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "align",
  ];

  const Check = () => {
    if (!newTasks[taskCount].taskTitle) {
      toast.error("Please Enter Task Title");
      return false;
    } else if (!newTasks[taskCount].introVideo) {
      toast.error("Please Upload Introduction Video");
      return false;
    } else if (
      newTasks[taskCount].taskBackground.replace(/(<([^>]+)>)/gi, "").length ===
      0
    ) {
      toast.error("Please Enter Task Background");
      return false;
    } else {
      setTaskSteps(2);
    }
  };

  useEffect(() => {
    async function setupLoom() {
      try {
        const jwsResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/get/loom/key`
        );

        const jws = jwsResponse.data.jws;
        const app_id = jwsResponse.data.App_id;
        const { supported, error } = await isSupported();
        if (!supported) {
          console.warn(`Error setting up Loom: ${error}`);
          return;
        }

        const button = document.getElementById(BUTTON_ID);
        if (!button) {
          console.warn(`Button with id ${BUTTON_ID} not found.`);
          return;
        }

        const { configureButton } = await setup({
          publicAppId: app_id,
          jws,
        });
        const sdkButton = configureButton({
          element: button,
          hooks: {
            onInsertClicked: (shareLink) => {
              console.log("clicked insert");
              console.log(shareLink);

              setNewTasks((prevTasks) => {
                return prevTasks.map((task, index) => {
                  if (index === taskCount) {
                    return { ...task, introVideo: shareLink.embedUrl };
                  }
                  return task;
                });
              });
            },
            onStart: () => console.log("start"),
            onCancel: () => console.log("cancelled"),
            onComplete: () => console.log("Completed"),
          },
        });

        sdkButton.on("insert-click", async (video) => {
          const { html } = await oembed(video.sharedUrl, { width: 400 });
        });
      } catch (error) {
        console.error("Error setting up Loom:", error);
      }
    }
    setupLoom();
  }, []);

  return (
    <div className="pb-5 mb-5" style={{ width: "100%" }}>
      <div className="">
        <p className="BasicInforMationHeading ">Task Overview</p>
        <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
          Give students basic informations about this task
        </p>
        <div style={{ marginTop: "2.5rem" }}>
          <p className="thumbnailcreate">Task Title</p>
          <input
            className="col-md-12 col-12 forminput "
            style={{ marginTop: "0.75rem" }}
            value={newTasks[taskCount].taskTitle}
            onChange={(e) => {
              const updatedTasks = [...newTasks];
              updatedTasks[taskCount] = {
                ...updatedTasks[taskCount],
                taskTitle: e.target.value,
              };
              setNewTasks(updatedTasks);
            }}
          />
          <p className="thumbnailcreate" style={{ marginTop: "2.5rem" }}>
            Upload Task Introduction Video
          </p>
          <div
            className=" d-flex  p-3 border rounded-3"
            style={{
              minHeight: "300px",
              justifyContent: "center",
              backgroundColor: "#FFF",
              alignItems: "center",

              marginTop: "0.75rem",
            }}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            <div>
              {" "}
              <div className="d-flex" style={{ justifyContent: "center" }}>
                {newTasks[taskCount].introVideo ? (
                  <>
                    {newTasks[taskCount].introVideo instanceof File ? (
                      <video
                        controls
                        onClick={(e) => e.stopPropagation()}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          cursor: "pointer",
                        }}
                      >
                        <source
                          src={URL.createObjectURL(
                            newTasks[taskCount].introVideo
                          )}
                          type={newTasks[taskCount].introVideo.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <iframe
                        title="Embedded Video"
                        className="img-fluid"
                        src={
                          newTasks[taskCount].introVideo.includes("http")
                            ? newTasks[taskCount].introVideo
                            : `${process.env.REACT_APP_BACKEND_URL}/uploads/${newTasks[taskCount].introVideo}`
                        }
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          maxHeight: "200px",
                        }}
                      ></iframe>
                    )}
                  </>
                ) : (
                  <img src={upload} alt="upload" height="40px" width="40px" />
                )}{" "}
              </div>
              <div
                className="d-flex"
                style={{
                  gap: "5px",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <p
                    style={{
                      color: "#E31B54",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      document.getElementById("videoUpload").click()
                    }
                  >
                    Click to{" "}
                    {newTasks[taskCount].introVideo ? "change" : "upload"}
                  </p>
                  <input
                    type="file"
                    id="videoUpload"
                    accept="video/mp4" // Restricts file input to only .mp4 files
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    or drag and drop
                  </p>
                </div>
                <div
                  className=" d-flex"
                  style={{
                    justifyContent: "center",
                    marginTop: "2rem",
                    alignItems: "center",
                  }}
                >
                  <button
                    id={BUTTON_ID}
                    className="clicktoupload"
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    or Click here
                  </button>

                  <p className="black14" style={{ cursor: "auto" }}>
                    to Record with
                  </p>
                  <img src={loom} alt="loom logo" width="53px" height="15px" />
                </div>
              </div>
            </div>
          </div>
          <p className="thumbnailcreate" style={{ marginTop: "2.5rem" }}>
            Task Background
          </p>
          <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
            What is this task about? Give some context about the case, the
            student’s role and learnings.
          </p>
          <ReactQuill
            value={newTasks[taskCount].taskBackground}
            onChange={(value) => {
              const updatedTasks = [...newTasks];
              updatedTasks[taskCount] = {
                ...updatedTasks[taskCount],
                taskBackground: value,
              };
              setNewTasks(updatedTasks);
            }}
            modules={modules}
            formats={formats}
            className="col-md-12  reactquillfontfix whitescrollbar"
            style={{
              backgroundColor: "#FFF",
              marginTop: "0.75rem",
            }}
          />
          <p className="characterslimittext" style={{ marginTop: "0.5rem" }}>
            {" "}
            {newTasks[taskCount].taskBackground &&
              newTasks[taskCount].taskBackground.replace(/(<([^>]+)>)/gi, "")
                .length}{" "}
            Characters
          </p>
          <hr style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }} />

          <div
            className="d-flex flex-wrap"
            style={{ justifyContent: "end", gap: "1.25rem" }}
          >
            <button className="backbutton" onClick={() => setTaskSteps(0)}>
              Back {isNonMob800 && "to Challenge Overview"}
            </button>
            <button className="continuebutton" onClick={() => Check()}>
              Continue
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M6.66669 17.2917C6.83257 17.2925 6.99178 17.2264 7.10835 17.1083L13.775 10.4417C14.0187 10.1977 14.0187 9.80235 13.775 9.55833L7.10835 2.89167C6.8621 2.6622 6.47835 2.66897 6.24034 2.90698C6.00233 3.14499 5.99555 3.52874 6.22502 3.775L12.45 10L6.22502 16.225C5.98131 16.469 5.98131 16.8643 6.22502 17.1083C6.34159 17.2264 6.5008 17.2925 6.66669 17.2917Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaskStep1;
