const SelectedCircle = () => {
  return (
    <div
      className="center align-items-center"
      style={{ width: "20px", height: "20px" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <mask
          id="mask0_2604_12901"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2604_12901)">
          <path
            d="M9.99984 14.167C11.1526 14.167 12.1353 13.7607 12.9478 12.9482C13.7603 12.1357 14.1665 11.1531 14.1665 10.0003C14.1665 8.84755 13.7603 7.86491 12.9478 7.05241C12.1353 6.23991 11.1526 5.83366 9.99984 5.83366C8.84706 5.83366 7.86442 6.23991 7.05192 7.05241C6.23942 7.86491 5.83317 8.84755 5.83317 10.0003C5.83317 11.1531 6.23942 12.1357 7.05192 12.9482C7.86442 13.7607 8.84706 14.167 9.99984 14.167ZM9.99984 18.3337C8.84706 18.3337 7.76373 18.1149 6.74984 17.6774C5.73595 17.2399 4.854 16.6462 4.104 15.8962C3.354 15.1462 2.76025 14.2642 2.32275 13.2503C1.88525 12.2364 1.6665 11.1531 1.6665 10.0003C1.6665 8.84755 1.88525 7.76421 2.32275 6.75033C2.76025 5.73644 3.354 4.85449 4.104 4.10449C4.854 3.35449 5.73595 2.76074 6.74984 2.32324C7.76373 1.88574 8.84706 1.66699 9.99984 1.66699C11.1526 1.66699 12.2359 1.88574 13.2498 2.32324C14.2637 2.76074 15.1457 3.35449 15.8957 4.10449C16.6457 4.85449 17.2394 5.73644 17.6769 6.75033C18.1144 7.76421 18.3332 8.84755 18.3332 10.0003C18.3332 11.1531 18.1144 12.2364 17.6769 13.2503C17.2394 14.2642 16.6457 15.1462 15.8957 15.8962C15.1457 16.6462 14.2637 17.2399 13.2498 17.6774C12.2359 18.1149 11.1526 18.3337 9.99984 18.3337ZM9.99984 16.667C11.8609 16.667 13.4373 16.0212 14.729 14.7295C16.0207 13.4378 16.6665 11.8614 16.6665 10.0003C16.6665 8.13921 16.0207 6.56283 14.729 5.27116C13.4373 3.97949 11.8609 3.33366 9.99984 3.33366C8.13873 3.33366 6.56234 3.97949 5.27067 5.27116C3.979 6.56283 3.33317 8.13921 3.33317 10.0003C3.33317 11.8614 3.979 13.4378 5.27067 14.7295C6.56234 16.0212 8.13873 16.667 9.99984 16.667Z"
            fill="#E31B54"
          />
        </g>
      </svg>
    </div>
  );
};
export default SelectedCircle;
