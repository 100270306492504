import axios from "axios";
import { useEffect, useState } from "react";
import CompanyImage from "../../components/Company/CompanyImage";
import EastIcon from "@mui/icons-material/East";
const RecommendedJobs = ({ setEmptyJob, uniqueId = null }) => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTimeElapsed = (createdAt) => {
    const currentDate = new Date();
    const creationDate = new Date(createdAt);

    const timeDifference = currentDate - creationDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return `${years}yr ago`;
    } else if (months > 0) {
      return `${months}mon ago`;
    } else if (days > 0) {
      return `${days}d ago`;
    } else if (hours > 0) {
      return `${hours}h ago`;
    } else if (minutes > 0) {
      return `${minutes} min ago`;
    } else {
      return `${seconds} sec ago`;
    }
  };

  const fetchJobs = async () => {
    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/getJobs`,
        { limit: 5 },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setJobs(res.data?.jobs.filter((job) => job?.uniqueId !== uniqueId));
        setEmptyJob(
          res.data?.jobs?.filter((job) => job?.uniqueId !== uniqueId).length > 0
            ? false
            : true
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <div style={{ background: "#FFF", padding: "1rem", borderRadius: "5px" }}>
      <p className="gray95016500">Recommended Jobs</p>

      <div className="d-flex flex-column mt-3" style={{ gap: "0.75rem" }}>
        {jobs.map((job, index) => (
          <a
            key={index}
            style={{ textDecoration: "none" }}
            href={`/student/job/${job?.uniqueId}`}
          >
            <div
              className="d-flex hovershadowa"
              style={{
                gap: "0.75rem",
                padding: "0.75rem",
                borderRadius: "5px",
                border: "1px solid #EAECF0",
                background: "#F9FAFB",
                cursor: "pointer",
              }}
            >
              <CompanyImage src={JSON.parse(job?.company)?.img} />
              <div>
                {" "}
                <p className="gray95014400" style={{ fontWeight: 500 }}>
                  {job?.title}
                </p>
                <p className="gray60011400 mt-1">
                  {getTimeElapsed(job?.createdAt)}
                </p>
              </div>
            </div>
          </a>
        ))}
      </div>

      {loading && (
        <div className="center">
          <div className="loader" />
        </div>
      )}

      {jobs.length >= 4 && (
        <a style={{ textDecoration: "none" }} href={`/student/Jobs`}>
          <div
            className="d-flex gap-1 pink14 hovercolorback align-items-center"
            style={{ fontWeight: 500, marginTop: "1.25rem" }}
          >
            View All
            <EastIcon style={{ height: "20px", width: "30px" }} />
          </div>
        </a>
      )}
    </div>
  );
};

export default RecommendedJobs;
