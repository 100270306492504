import Create from "./Create";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./step4";
import PreviewPage from "./PreviewPage";
import axios from "axios";

import Header from "./header";
import { toast } from "react-toastify";
import { motion, useAnimate } from "framer-motion";

import Before from "./BeforeStep6";
import PreviewTask from "./PreviewTask";
import NewSkillSelect from "./NewSkillsSelect";

const Main = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPreviewa, setShowPreviewa] = useState(false);
  const [step, setStep] = useState(1);
  const [skills, setSkills] = useState([]);
  const [preview, setPreview] = useState(false);
  const [settings, setSettings] = useState({
    keyterms: false,
    files: false,
    externalLinks: false,
  });
  const [learns, setLearns] = useState([]);
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [defaults, setDefaults] = useState(false);
  const [type, setType] = useState("Fast-Track");
  const [duration, setDuration] = useState("45");
  const [studentInfo, setStudentInfo] = useState("");
  const [difficulty, setDifficulty] = useState("Basic");
  const [tools, setTools] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [company, setCompany] = useState(null);
  const [user, setUser] = useState("");
  const email = useSelector((state) => state.uniqueId);
  const [tastTitle, setTaskTitle] = useState("");
  const [taskBackground, setTaskBackground] = useState("");
  const [terms, setTerms] = useState("");
  const [task, setTasks] = useState("");
  const [external, setExternal] = useState("");
  const [search, setSearch] = useState("Please Select");
  const [introVideo, setIntroVideo] = useState("");
  const [documents, setSelectedDocuments] = useState([]);

  const [loading, setLoading] = useState(false);

  const [time, setTime] = useState(5);
  const [information, setInformation] = useState("");
  const [taskHeader, setTaskHeader] = useState(false);
  const [taskCount, setTaskCount] = useState(0);
  const [taskSteps, setTaskSteps] = useState(-1);
  const [newTasks, setNewTasks] = useState([]);

  const edit = false;
  const [questions, setQuestions] = useState([
    {
      question: "",
      answer: "",
      description: "",
      type: "Multiple Choice Question",
      explaination: "",
      files: [],
      options: [
        {
          value: "",
          isCorrect: false,
        },
        {
          value: "",
          isCorrect: false,
        },
      ],
      isMultiSelect: false,
    },
  ]);

  const [alltask, setAllTasks] = useState([]);

  const handleSubmit = async () => {
    let error = -1;
    if (newTasks.length < 1) {
      toast.error("Please add at least one task");
      error = 1;
      return;
    } else if (newTasks.length > 6) {
      toast.error("Only upto 6 tasks allowed");
      error = 1;
      return;
    }
    newTasks.map((task, index) => {
      if (!task.taskTitle) {
        toast.error(`Please Enter Task Title of task ${index + 1}`);
        error = 1;
        return;
      } else if (!task.introVideo) {
        toast.error(`Please Upload Introduction Video of task ${index + 1}`);
        error = 1;
        return;
      } else if (
        !task.taskBackground ||
        task.taskBackground.replace(/(<([^>]+)>)/gi, "").length === 0
      ) {
        toast.error(`Please Enter Task Background of task ${index + 1}`);
        error = 1;
        return;
      } else if (!task?.task || task?.task.length === 0) {
        toast.error(`Please provide Task information of task ${index + 1}`);
        error = 1;
        return;
      } else if (task.questions.length < 2) {
        toast.error(`Please provide at least 2 questions of task ${index + 1}`);
        error = 1;
        return;
      } else if (task.settings.keyterms && !task.terms) {
        toast.error(`Please provide key terms of task ${index + 1}`);
        error = 1;
        return;
      } else if (task.settings.files && task.caseDocuments.length < 1) {
        toast.error(`Please provide at least 1 document of task ${index + 1}`);
        error = 1;
        return;
      } else if (task.settings.externalLinks && task.external.length < 1) {
        toast.error(
          `Please provide at least 1 external link of task ${index + 1}`
        );
        error = 1;
        return;
      } else if (task.questions.length > 0) {
        task.questions.map((question, ind) => {
          if (!question.question) {
            toast.error(
              `Please Enter Question ${ind + 1} of task ${index + 1}`
            );
            error = 1;
            return;
          } else if (
            (question.type === "Multiple Choice Question" &&
              question.isMultiSelect &&
              question.options.length < 3) ||
            (question.type === "Multiple Choice Question" &&
              !question.isMultiSelect &&
              question.options.length < 2)
          ) {
            toast.error(
              `Please Enter correct Options of Question ${ind + 1} of task ${
                index + 1
              }`
            );
            error = 1;
            return;
          } else if (
            question.type === "Multiple Choice Question" &&
            question.options.some((option) => !option.value)
          ) {
            toast.error(
              `Please Input Option Field of Question ${ind + 1} of task ${
                index + 1
              }`
            );
            error = 1;
            return;
          } else if (
            question.type === "Multiple Choice Question" &&
            question.isMultiSelect &&
            question.options.filter((option) => option.isCorrect).length < 2
          ) {
            toast.error(
              `Please Select 2 Correct Options of Question ${ind + 1} of task ${
                index + 1
              }`
            );
            error = 1;
            return;
          } else if (
            question.type === "Multiple Choice Question" &&
            !question.isMultiSelect &&
            question.options.filter((option) => option.isCorrect).length < 1
          ) {
            toast.error(
              `Please Select 1 Correct Options of Question ${ind + 1} of task ${
                index + 1
              }`
            );
            error = 1;
            return;
          } else if (question.type === "Text field" && !question.explaination) {
            toast.error(
              `Please Enter Explaination of Question ${ind + 1} of task ${
                index + 1
              }`
            );
            error = 1;
            return;
          } else if (
            question.type === "File Upload" &&
            question.files.length < 1
          ) {
            toast.error(
              `Please upload a file of Question ${ind + 1} of task ${index + 1}`
            );
            error = 1;
            return;
          }
        });
      }
    });
    if (error !== 1) {
      setLoading(true);
      const formData = new FormData();

      if (!selectedVideo instanceof File) {
        const check = await downloadVideoFromEmbed(selectedVideo);
        formData.append("selectedVideo", check);
      } else {
        formData.append("selectedVideo", selectedVideo);
      }
      formData.append("selectedFile", selectedFile);
      let fileData = [];
      await Promise.all(
        newTasks.map(async (task, index) => {
          if (!task.introVideo instanceof File) {
            const check = await downloadVideoFromEmbed(task.introVideo);

            formData.append(`introVideo_${index}`, check);
          } else {
            formData.append(`introVideo_${index}`, task.introVideo);
          }
          for (let x = 0; x < task.caseDocuments.length; x++) {
            formData.append(`documents_${index}[]`, task.caseDocuments[x]);
          }
          for (let x = 0; x < task.additionalDocuments.length; x++) {
            formData.append(
              `additional_documents_${index}[]`,
              task.additionalDocuments[x]
            );
          }
          task.questions.forEach((question, qIndex) => {
            if (question.type === "File Upload" && question.files.length > 0) {
              formData.append(`file_${index}[]`, question.files[0]);

              fileData.push({
                index: index,
                qIndex: qIndex,
                file: question.files[0].name,
              });
            }
          });
        })
      );

      formData.append("title", title);
      formData.append("fileData", JSON.stringify(fileData));
      formData.append("information", information);
      formData.append("description", description);
      formData.append("skills", JSON.stringify(skills));
      formData.append("learns", JSON.stringify(learns));
      formData.append("selectedItem", JSON.stringify(selectedItem));
      formData.append("selectedLanguage", search);
      formData.append("defaults", defaults);
      formData.append("type", type);
      formData.append("duration", duration);
      formData.append("difficulty", difficulty);
      formData.append("tools", JSON.stringify(tools));
      formData.append("company", JSON.stringify(company));
      formData.append("studentInfo", studentInfo);

      // Convert alltask to a JSON string before appending
      formData.append("alltask", JSON.stringify(newTasks));
      formData.append("email", email);
      formData.append("user", JSON.stringify(user));
      try {
        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/createChallenge`,
            formData
          )
          .then((response) => {
            if (
              response.data.message === "Data received and stored successfully"
            ) {
              toast.success("Challenge created successfully");
              navigate("/dashboard/Challenges");
            } else {
              toast.error("Error Creating Challenge");
            }
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  async function downloadVideoFromEmbed(embedLink) {
    try {
      const response = await axios.get(embedLink, {
        responseType: "arraybuffer",
      });
      return response.data;
    } catch (error) {
      console.error("Error downloading video:", error);
      throw error;
    }
  }

  const handleAddAnotherTask = () => {
    const newTask = {
      taskTitle: tastTitle,
      taskBackground: taskBackground,
      terms: terms,
      task: task,
      time: time,
      settings,
      external: external,
      introVideo: introVideo,
      documents: documents,
      questions: questions, // You might need to adjust this based on your requirements
    };

    // Update the state to include the new task
    setAllTasks((prevTasks) => [...prevTasks, newTask]);

    // Reset input fields or any other necessary state
    setTaskTitle("");
    setTaskBackground("");
    setSettings({
      keyterms: false,
      files: false,
      externalLinks: false,
    });
    setTerms("");
    setTasks("");
    setExternal("");
    setTime(5);
    setIntroVideo("");
    setSelectedDocuments("");
    setQuestions([
      {
        question: "",
        answer: "",
        description: "",
        type: "Multiple Choice Question",
        explaination: "",
        files: [],
        options: [
          {
            value: "",
            isCorrect: false,
          },
          {
            value: "",
            isCorrect: false,
          },
        ],
        isMultiSelect: false,
      },
    ]);

    // Move to the next step
    setStep(6); // Assuming you want to move to Step 7, adjust accordingly if needed
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const updateAllTasks = async () => {
    const newTask = {
      taskTitle: tastTitle,
      taskBackground: taskBackground,
      terms: terms,
      task: task,
      external: external,
      time: time,
      introVideo: introVideo,
      documents: documents,
      questions: questions, // You might need to adjust this based on your requirements
    };

    return new Promise((resolve) => {
      setAllTasks((prevTasks) => {
        const updatedAlltask = [...prevTasks, newTask];
        resolve(updatedAlltask);
        return updatedAlltask;
      });
    });
  };

  const handleSave = async () => {
    try {
      if (
        !tastTitle ||
        !taskBackground ||
        !task ||
        !introVideo ||
        questions.length <= 1
      ) {
        toast.error("Please enter all the required task details correctly");
        return;
      } else {
        const updatedAlltask = await updateAllTasks();
        // Update the state to include the new task

        // Create a FormData object to append files and other data
        const formData = new FormData();

        // Append files to FormData
        formData.append("selectedFile", selectedFile);
        formData.append("selectedVideo", selectedVideo);

        // Map over alltask array to handle introVideo and documents
        await Promise.all(
          updatedAlltask.map(async (task, index) => {
            formData.append(`introVideo_${index}`, task.introVideo);

            for (let x = 0; x < task.documents.length; x++) {
              formData.append(`documents_${index}[]`, task.documents[x]);
            }
          })
        );

        // Append other data to FormData
        formData.append("title", title);
        formData.append("information", information);
        formData.append("description", description);
        formData.append("skills", JSON.stringify(skills));
        formData.append("learns", JSON.stringify(learns));
        formData.append("selectedItem", JSON.stringify(selectedItem));
        formData.append("selectedLanguage", JSON.stringify(selectedLanguage));
        formData.append("defaults", defaults);
        formData.append("type", type);
        formData.append("duration", duration);
        formData.append("difficulty", difficulty);
        formData.append("tools", JSON.stringify(tools));
        formData.append("company", JSON.stringify(company));

        // Convert alltask to a JSON string before appending
        formData.append("alltask", JSON.stringify(updatedAlltask));
        formData.append("email", email);
        formData.append("user", JSON.stringify(user));
        // Make a POST request to the backend endpoint
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/createChallenge`,
          formData
        );

        // Handle the response as needed (e.g., show success message, redirect, etc.)

        setLoading(false);
        // Optionally, you can update the step or perform other actions
        navigate("/dashboard/Challenges");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      // Handle the error (e.g., show error message)
    }
  };

  const handleDraftSave = async () => {
    try {
      const formData = new FormData();

      // Handle selectedVideo logic
      if (!(selectedVideo instanceof File)) {
        const check = await downloadVideoFromEmbed(selectedVideo);
        formData.append("selectedVideo", check);
      } else {
        formData.append("selectedVideo", selectedVideo);
      }

      formData.append("selectedFile", selectedFile);

      let fileData = [];

      await Promise.all(
        newTasks.map(async (task, index) => {
          // Handle introVideo logic
          if (!(task.introVideo instanceof File)) {
            const check = await downloadVideoFromEmbed(task.introVideo);
            formData.append(`introVideo_${index}`, check);
          } else {
            formData.append(`introVideo_${index}`, task.introVideo);
          }

          // Handle caseDocuments
          task.caseDocuments.forEach((doc, docIndex) => {
            formData.append(`documents_${index}[]`, doc);
          });

          // Handle additionalDocuments
          task.additionalDocuments.forEach((doc, docIndex) => {
            formData.append(`additional_documents_${index}[]`, doc);
          });

          // Handle questions with file uploads
          task.questions.forEach((question, qIndex) => {
            if (question.type === "File Upload" && question.files.length > 0) {
              formData.append(`file_${index}[]`, question.files[0]);

              fileData.push({
                index: index,
                qIndex: qIndex,
                file: question.files[0].name,
              });
            }
          });
        })
      );

      // Append remaining form data
      formData.append("title", title);
      formData.append("fileData", JSON.stringify(fileData));
      formData.append("information", information);
      formData.append("description", description);
      formData.append("skills", JSON.stringify(skills));
      formData.append("learns", JSON.stringify(learns));
      formData.append("selectedItem", JSON.stringify(selectedItem));
      formData.append("selectedLanguage", search);
      formData.append("studentInfo", studentInfo);
      formData.append("defaults", defaults);
      formData.append("type", type);
      formData.append("duration", duration);
      formData.append("difficulty", difficulty);
      formData.append("tools", JSON.stringify(tools));
      formData.append("company", JSON.stringify(company));
      formData.append("alltask", JSON.stringify(newTasks));
      formData.append("email", email);
      formData.append("user", JSON.stringify(user));

      // Send POST request to save draft
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/save/draft/Challenge`,
        formData
      );

      setLoading(false);
      navigate("/dashboard/Challenges");
    } catch (error) {
      console.error("Error saving draft:", error);
      // Handle the error
    }
  };

  useEffect(() => {
    console.log("Step changed, scrolling the page to top...");
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [step]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let id;
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: email }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
          id = userInfoResponse.data.user.company;
        } else {
          navigate("/register/organiser");
          return; // Exit early if user not found
        }

        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: id }
        );
        setCompany(companyInfoResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [email]);

  const [showPreview, setShowPreview] = useState(false);
  const [taskPreview, setSelectedTaskPreview] = useState(null);
  const [index, setIndex] = useState(0);
  const handleSetPreview = (task, index) => {
    if (
      task?.caseDocuments?.length > 0 &&
      task?.introVideo &&
      task?.questions?.length > 1 &&
      task?.task &&
      task?.taskBackground &&
      task?.taskTitle &&
      task?.external?.length > 0
    ) {
      setSelectedTaskPreview(task);
      setShowPreview(true);
      setIndex(index);
    } else {
      toast.error("Task not complete yet");
    }
  };

  let lastStep = 9;
  let width = Math.floor(step * (100 / lastStep));

  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (scope?.current) {
      animate(scope?.current, { width: `${width}%` }, { duration: 0.3 });
    }
  }, [step]);

  return (
    <div className="">
      {showPreview ? (
        <div>
          <PreviewTask
            title={title}
            taskPreview={taskPreview}
            total={taskCount}
            company={company}
            taskCounter={index}
            setShowPreview={setShowPreview}
          />
        </div>
      ) : (
        <>
          {!showPreviewa && !preview && (
            <>
              <Header
                text={step >= 6 ? "/challenge design" : "Create challenge"}
                handleDraftSave={handleDraftSave}
                taskHeader={taskHeader}
                taskCount={taskCount}
                taskSteps={taskSteps}
                edit={edit}
              />
              {
                <div style={{ backgroundColor: "#F2F3F7" }}>
                  <motion.div
                    ref={scope}
                    className="ProgressBarForm"
                    style={{
                      width: `${width}%`,
                      position: "relative",
                      zIndex: 10,
                      top: "",
                    }}
                  ></motion.div>
                </div>
              }
            </>
          )}

          <div
            className={`d-flex${step < 6 && "pb-5"} center`}
            style={
              showPreviewa
                ? {}
                : { backgroundColor: "#F2F3F7", minHeight: "100vh" }
            }
          >
            <div
              className={`${step === 4 ? "w-100" : ""}`}
              style={{
                maxWidth: step <= 5 ? "700px" : "",
                paddingTop: step <= 5 ? "2rem" : "",
                margin: step <= 5 ? "1rem" : "",
              }}
            >
              {showPreviewa ? (
                <>
                  <PreviewPage
                    selectedFile={selectedFile}
                    tester={true}
                    setShowPreviewa={setShowPreviewa}
                    setTaskSteps={setTaskSteps}
                    step={step}
                    setStep={setStep}
                    skills={skills}
                    newTasks={newTasks}
                    learns={learns}
                    title={title}
                    description={description}
                    selectedItem={selectedItem}
                    selectedLanguage={selectedLanguage}
                    defaults={defaults}
                    type={type}
                    duration={duration}
                    difficulty={difficulty}
                    tools={tools}
                    selectedVideo={selectedVideo}
                    company={company}
                    information={information}
                    studentInfo={studentInfo}
                  />
                </>
              ) : (
                <>
                  {step === 2 && (
                    <Create
                      step={step}
                      setStep={setStep}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      title={title}
                      setTitle={setTitle}
                      description={description}
                      setDescription={setDescription}
                      setSearch={setSearch}
                      search={search}
                      setSelectedLanguage={setSelectedLanguage}
                    />
                  )}
                  {step === 3 && (
                    <Step2
                      setSkills={setSkills}
                      skills={skills}
                      learns={learns}
                      setSearch={setSearch}
                      search={search}
                      setLearns={setLearns}
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                      selectedLanguage={selectedLanguage}
                      setSelectedLanguage={setSelectedLanguage}
                      defaults={defaults}
                      setDefaults={setDefaults}
                      setStep={setStep}
                    />
                  )}
                  {step === 4 && (
                    <NewSkillSelect
                      setStep={setStep}
                      setLearns={setLearns}
                      learns={learns}
                      selectedItem={selectedItem}
                    />
                  )}
                  {step === 1 && (
                    <Step3 type={type} setType={setType} setStep={setStep} />
                  )}
                  {step === 5 && (
                    <Step4
                      information={information}
                      setInformation={setInformation}
                      duration={duration}
                      setDuration={setDuration}
                      difficulty={difficulty}
                      setDifficulty={setDifficulty}
                      tools={tools}
                      setTools={setTools}
                      setStep={setStep}
                      selectedFile={selectedVideo}
                      setSelectedFile={setSelectedVideo}
                      studentInfo={studentInfo}
                      setStudentInfo={setStudentInfo}
                      setTaskSteps={setTaskSteps}
                    />
                  )}

                  {step === 6 && (
                    <Before
                      showPreviewa={showPreviewa}
                      setShowPreviewa={setShowPreviewa}
                      edit={edit}
                      settings={settings}
                      setSettings={setSettings}
                      handleSubmit={handleSubmit}
                      company={company}
                      handleSetPreview={handleSetPreview}
                      selectedFile={selectedFile}
                      alltask={alltask}
                      title={title}
                      description={description}
                      type={type}
                      step={step}
                      setStep={setStep}
                      tastTitle={tastTitle}
                      setTaskTitle={setTaskTitle}
                      taskBackground={taskBackground}
                      setTaskBackground={setTaskBackground}
                      terms={terms}
                      setTerms={setTerms}
                      task={task}
                      setTasks={setTasks}
                      external={external}
                      setExternal={setExternal}
                      introVideo={introVideo}
                      setIntroVideo={setIntroVideo}
                      documents={documents}
                      setSelectedDocuments={setSelectedDocuments}
                      handleSave={handleSave}
                      questions={questions}
                      setQuestions={setQuestions}
                      handleAddAnotherTask={handleAddAnotherTask}
                      setPreview={setPreview}
                      preview={preview}
                      loading={loading}
                      setLoading={setLoading}
                      setTime={setTime}
                      time={time}
                      taskHeader={taskHeader}
                      setTaskHeader={setTaskHeader}
                      taskSteps={taskSteps}
                      setTaskSteps={setTaskSteps}
                      taskCount={taskCount}
                      setTaskCount={setTaskCount}
                      newTasks={newTasks}
                      setNewTasks={setNewTasks}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Main;
