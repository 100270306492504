import axios from "axios";
import { useEffect, useState } from "react";

const SimilarUsers = ({ setEmptySimilar }) => {
  const [similar, setSimilar] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchSimilar = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/get/similar/profiles`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.msg === "success") {
          setSimilar(res.data.users);
          setEmptySimilar(res?.data?.users.length > 0 ? false : true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSimilar();
  }, []);

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  return (
    <div
      style={{
        background: "#FFF",
        padding: "1rem",
        borderRadius: "5px",
        width: "100%",
        height: "fit-content",
      }}
    >
      <p className="gray95016500">Similar Profiles</p>
      <div
        className="d-flex flex-column "
        style={{ gap: "1.5rem", marginTop: "1.5rem" }}
      >
        {similar?.map((sim, index) => (
          <div key={index}>
            <div className="d-flex gap-2">
              <img
                className="shadowa"
                src={sim?.profilePhoto}
                alt=""
                style={{
                  height: "48px",
                  width: "48px",
                  border: "1px solid #FFF",
                  borderRadius: "50%",
                }}
              />
              <div>
                <p className="gray95014400" style={{ fontWeight: 500 }}>
                  {truncateString(`${sim?.firstName} ${sim?.LastName}`, 50)}{" "}
                </p>
                <p className="mt-1 gray70014400" style={{ fontSize: "12px" }}>
                  {truncateString(`${sim?.uni?.name}`, 50)}
                </p>
                <a
                  style={{ textDecoration: "none" }}
                  href={`/student/user/${sim?.link}`}
                >
                  <p
                    className="pink14 hovercolorback mt-2"
                    style={{ fontWeight: 500 }}
                  >
                    View Profile
                  </p>
                </a>
              </div>
            </div>
            {index !== similar?.length - 1 && (
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  background: "#EAECF0",
                  marginTop: "0.75rem",
                }}
              />
            )}
          </div>
        ))}
      </div>
      {loading && (
        <div className="center mt-3">
          <div className="loader" />{" "}
        </div>
      )}
    </div>
  );
};

export default SimilarUsers;
