import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../assets/images/logo.png";
import "./main.css";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import chart from "../../assets/images/Chart.png";
import { useMediaQuery } from "@mui/material";
import Certificate from "./Certificate";

import html2pdf from "html2pdf.js";
const NewResult = () => {
  const { id } = useParams();
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(true);
  const [average, setAverage] = useState(null);
  const navigate = useNavigate();
  const isNonMobile1024 = useMediaQuery("(min-width: 1024px)");
  const certificateRef = useRef();

  const downloadCertificateAsPDF = () => {
    const certificateElement = certificateRef.current; // Use the ref instead of querying the DOM

    // Options for html2pdf
    const options = {
      filename: "certificate.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2, useCORS: true },

      jsPDF: {
        unit: "px", // Set unit to pixels
        format: [1300, 1000], // Use custom format if needed (1300px wide)
        orientation: "landscape", // Landscape orientation
      },
    };

    // Convert HTML to PDF
    html2pdf().from(certificateElement).set(options).save();
  };

  const fetchResults = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/get/challenge/results`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const { data } = response;
      if (data.msg === "success") {
        setResult(data.result);
        setAverage(data.average);
      } else {
        navigate(-1); // Navigate back on failure
      }
    } catch (error) {
      console.error("Error fetching results", error);
      navigate(-1);
    } finally {
      setLoading(false); // Ensure loading state is turned off
    }
  };

  useEffect(() => {
    if (id) {
      fetchResults();
    }
  }, [id]);

  if (loading) {
    return (
      <div
        className="center align-items-center flex-column"
        style={{ height: "100vh", width: "100vw" }}
      >
        <p className="text-center m-3 black16">
          Fetching Results
          <br /> Please wait ...
        </p>
        <Loader />
      </div>
    );
  }

  if (!result || !result.preview) {
    return (
      <div
        className="center align-items-center flex-column"
        style={{ height: "100vh", width: "100vw" }}
      >
        <p className="text-center m-3 black16">
          No results found. Please try again.
        </p>
      </div>
    );
  }

  return (
    <div
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        background: "#F2F3F7",
      }}
    >
      <div style={{ height: "100%", width: "100%" }}>
        <div
          className="shadowa"
          style={{
            background: "#FFF",
            position: "sticky",
            top: 0,
            width: "100vw",
          }}
        >
          <div className="marginsleftrightCompany">
            <div className="center">
              <div
                className="headerCompany space align-items-center"
                style={{ padding: "0.5rem 0rem" }}
              >
                <img
                  className="shadowa rounded-1"
                  src={logo}
                  alt="logo"
                  height="36px"
                  width="36px"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/")}
                />
                <CloseIcon
                  className="hoverdarkblacksvg"
                  style={{
                    cursor: "pointer",
                    height: "1.5rem",
                    width: "1.5rem",
                  }}
                  onClick={() => navigate(-1)}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="center align-items-center pb-5  pt-5 "
          style={{
            minHeight: "calc(100vh - 52px)",
            width: "100vw",
          }}
        >
          <div className="marginsleftrightCompany" style={{ width: "100%" }}>
            <div className="center" style={{ width: "100%" }}>
              <div
                className="headerCompany space"
                style={{
                  gap: "1.875rem",
                  flexDirection: isNonMobile1024 ? "row" : "column",
                }}
              >
                <div
                  className="space flex-column"
                  style={{ width: isNonMobile1024 ? "71.88%" : "100%" }}
                >
                  <div>
                    {" "}
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "1.5rem" }}
                    >
                      {result.preview?.company && (
                        <div>
                          <img
                            src={JSON.parse(result.preview.company)?.img}
                            alt="company logo"
                            style={{
                              borderRadius: "5px",
                              height: "85px",
                              width: "85px",
                              objectFit: "cover",
                              background: "#FFF",
                              padding: "3px",
                            }}
                          />
                        </div>
                      )}

                      <div>
                        {result.percentage > 33 ? (
                          <p className="gray95014400">
                            <span
                              className="gray95014400"
                              style={{ fontWeight: 500 }}
                            >
                              Congratulations!
                            </span>{" "}
                            You just completed a challenge.
                          </p>
                        ) : (
                          <p className="gray95014400">
                            Oops, Better Luck Next Time
                          </p>
                        )}
                        <p
                          style={{
                            marginTop: "0.5rem",
                            color: "#040404",
                            fontWeight: 400,
                            fontSize: "1.25rem",
                            lineHeight: "2rem",
                          }}
                        >
                          {result.preview.title}
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                      <p className="">
                        {JSON.parse(result.preview.company)?.title}
                      </p>
                      <p className="gray60011400 mt-3">
                        Want to learn more?{" "}
                        <span
                          className="pink14 "
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/student/Challenges");
                            window.scrollTo(0, 0);
                          }}
                        >
                          Explore more challenges
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    className=""
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fit, minmax(190px, 1fr))",
                      gap: "1.5rem",
                    }}
                  >
                    <div
                      className="resultscardsdiv mt-5 shadowa"
                      style={{ minHeight: "184px", maxWidth: "190px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="16"
                        viewBox="0 0 19 16"
                        fill="none"
                      >
                        <path
                          d="M8.92482 11.7833L5.81648 8.68333C5.68468 8.55199 5.6106 8.37357 5.6106 8.1875C5.6106 8.00143 5.68468 7.82301 5.81648 7.69167C6.09368 7.42605 6.53095 7.42605 6.80815 7.69167L8.71649 9.60833V1.2C8.71649 0.813401 9.02989 0.5 9.41648 0.5C9.80309 0.5 10.1165 0.813401 10.1165 1.2V9.60833L12.0248 7.69167C12.302 7.42605 12.7393 7.42605 13.0165 7.69167C13.1483 7.82301 13.2224 8.00143 13.2224 8.1875C13.2224 8.37357 13.1483 8.55199 13.0165 8.68333L9.90815 11.7833C9.77987 11.9174 9.60204 11.9928 9.41648 11.9917C9.23053 11.9948 9.05192 11.9191 8.92482 11.7833Z"
                          fill="#E31B54"
                        />
                        <path
                          d="M13.7665 5.01667H14.7748C16.8717 5.06223 18.537 6.79463 18.4998 8.89167V12.0667C18.537 14.1637 16.8717 15.8961 14.7748 15.9417L4.05816 15.875C1.96129 15.8294 0.295947 14.097 0.333157 12V8.825C0.308567 7.81347 0.687524 6.83374 1.38639 6.10204C2.08525 5.37035 3.04657 4.94684 4.05816 4.925H5.06649C5.45309 4.925 5.76649 5.2384 5.76649 5.625C5.76649 6.0116 5.45309 6.325 5.06649 6.325H4.05816C3.41704 6.34447 2.81039 6.61977 2.37357 7.08946C1.93676 7.55915 1.70614 8.18415 1.73316 8.825V11.975C1.70614 12.6158 1.93676 13.2408 2.37357 13.7105C2.81039 14.1802 3.41704 14.4555 4.05816 14.475L14.7748 14.5917C15.4159 14.5722 16.0226 14.2969 16.4594 13.8272C16.8962 13.3575 17.1268 12.7325 17.0998 12.0917V8.91667C17.1268 8.27582 16.8962 7.65082 16.4594 7.18113C16.0226 6.71144 15.4159 6.43614 14.7748 6.41667H13.7665C13.3799 6.41667 13.0665 6.10327 13.0665 5.71667C13.0665 5.33007 13.3799 5.01667 13.7665 5.01667Z"
                          fill="#E31B54"
                        />
                      </svg>
                      <div>
                        <p
                          className="pink14 hovercolorback"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            downloadCertificateAsPDF();
                          }}
                        >
                          Download Certicate
                        </p>
                        <p
                          className="gray60011400"
                          style={{
                            marginTop: "0.25rem",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.25rem",
                          }}
                        >
                          PDF
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ width: isNonMobile1024 ? "25.55%" : "100%" }}
                  className="resultsrightdiv"
                >
                  <p className="gray70014400" style={{ fontWeight: 500 }}>
                    Total Score
                  </p>
                  <div
                    className="d-flex align-items-end"
                    style={{ gap: "6px", marginTop: "0.25rem" }}
                  >
                    <p className="gray70032400">{result.percentage}%</p>
                    <p
                      className="gray70014400"
                      style={{
                        color: result.percentage >= average ? "#00B929" : "red",
                      }}
                    >
                      <ArrowDropUpIcon
                        style={{
                          height: "25px",
                          width: "25px",
                          color:
                            result.percentage >= average ? "#00B929" : "red",
                          transform:
                            result.percentage >= average
                              ? ""
                              : "rotate(180deg)",
                        }}
                      />
                      {result.percentage >= average
                        ? result.percentage - average
                        : average - result.percentage}
                      %
                    </p>
                  </div>
                  <p className="gray60011400" style={{ marginTop: "6px" }}>
                    Compared to {average}% average.
                  </p>
                  <div className="center" style={{ marginTop: "1rem" }}>
                    <img src={chart} alt="chart" className="img-fluid" />
                  </div>
                  {result && average && (
                    <p className="expanded mt-5">
                      {result.percentage > 33 && "Expanded"} Expanded Skills
                    </p>
                  )}
                  {result && average && (
                    <p className="goodjob ">
                      {result.percentage > 33 && "Good job"}
                      {result.percentage < 33 && "Better luck next time"}
                    </p>
                  )}
                  {result && average && (
                    <div
                      className="whitescrollbar "
                      style={{
                        overflow: "auto",
                        marginTop: "17px",
                        maxHeight: "176px",
                        width: "100%",
                      }}
                    >
                      {result.preview.learns.map((ski, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center"
                          style={{
                            gap: "8px",
                            marginTop: "12px",
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <circle cx="7" cy="7" r="7" fill="white" />
                              <circle cx="7" cy="7" r="2" fill="#17D74D" />
                            </svg>
                          </div>
                          <p
                            className="gray70014400"
                            style={{
                              width: "92px",
                              fontSize: "12",
                              lineHeight: "16px",
                            }}
                          >
                            {ski}
                          </p>
                          <p className="scoregot" style={{ width: "62px" }}>
                            + {result.score}
                          </p>

                          <p
                            className="totalgainedpoints"
                            style={{ width: "31px" }}
                          >
                            {result?.user &&
                              result?.user?.Skills.length > 0 &&
                              result?.user.Skills.find(
                                (skillObj) => skillObj.skill === ski
                              )?.points}
                          </p>
                        </div>
                      ))}
                      {result &&
                        result.preview.skills.map((ski, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{
                              gap: "8px",
                              marginTop: "12px",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                              >
                                <circle cx="7" cy="7" r="7" fill="white" />
                                <circle cx="7" cy="7" r="2" fill="#17D74D" />
                              </svg>
                            </div>
                            <p className="skillname" style={{ width: "92px" }}>
                              {ski}
                            </p>
                            <p className="scoregot" style={{ width: "62px" }}>
                              + {result.score}
                            </p>

                            <p
                              className="totalgainedpoints"
                              style={{ width: "31px" }}
                            >
                              {result?.user &&
                                result?.user.Skills.length > 0 &&
                                result?.user.Skills.find(
                                  (skillObj) => skillObj.skill === ski
                                )?.points + result.score}
                            </p>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute", // Ensure it is placed outside the visible view
          top: "-9999px", // Move it out of the visible viewport
          left: "-9999px", // Ensure it's far off-screen
        }}
      >
        <Certificate
          company={JSON.parse(result?.preview?.company)}
          result={result}
          average={average}
          certificateRef={certificateRef}
        />
      </div>
    </div>
  );
};

export default NewResult;
