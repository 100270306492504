// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-card-container {
  padding: 16px;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}
.lesswidth .newTabButton {
  width: 150px;
}

@media screen and (max-width: 600px) {
  .lesswidth .newTabButton {
    width: 120px;
  }
}

@media screen and (max-width: 500px) {
  .lesswidth .newTabButton {
    width: 33% !important;
  }
  .lesswidth .newTabOuter {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/StudentGroups/main.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;AAClC;AACA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".group-card-container {\n  padding: 16px;\n  border-radius: 5px;\n  background: #fff;\n  transition: all 0.3s ease-in-out;\n}\n.lesswidth .newTabButton {\n  width: 150px;\n}\n\n@media screen and (max-width: 600px) {\n  .lesswidth .newTabButton {\n    width: 120px;\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .lesswidth .newTabButton {\n    width: 33% !important;\n  }\n  .lesswidth .newTabOuter {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
