import { useEffect, useState } from "react";
import Group from "./GroupDetail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import TrendingGroups from "../TrendingGroups";
const GroupDetails = ({ student }) => {
  const { link } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [posts, setPosts] = useState([]);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNon384 = useMediaQuery("(min-width:384px)");

  const [emptygroups, setEmptyGroups] = useState(false);
  const fetchGroup = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/get/group/detail/${link}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          setGroup(res.data.group);
          setRequests(res.data.reques);
        } else {
          navigate(-1);
        }
      })
      .catch((err) => {
        console.log(err);
        navigate(-1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (link) {
      fetchGroup();
    } else {
      navigate(-1);
    }
  }, [link]);
  return (
    <div
      className="marginsleftrightCompany marginsStudent"
      style={{ paddingTop: "1.875rem" }}
    >
      <div className="center">
        <div className="headerCompany">
          {loading ? (
            <div
              className="center"
              style={{ position: "fixed", top: "50%", left: "50%" }}
            >
              <div className="loader" />
            </div>
          ) : (
            <div
              className="d-flex "
              style={{ marginTop: "1.875rem", gap: "1.875rem" }}
            >
              <div style={{ width: "100%" }}>
                {group ? (
                  <Group
                    group={group}
                    student={student}
                    setPosts={setPosts}
                    setGroup={setGroup}
                    posts={posts}
                    requests={requests}
                    isNon384={isNon384}
                  />
                ) : (
                  <p className="black14 ">No Group Found</p>
                )}
              </div>

              {isNonMobile1024 && !emptygroups && (
                <div
                  className="d-flex"
                  style={{ minWidth: "300px", maxWidth: "300px" }}
                >
                  <TrendingGroups setEmptyGroups={setEmptyGroups} link={link} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupDetails;
