import { useEffect, useRef, useState } from "react";
import Experience from "./Experience";
import Education from "./Education";
import axios from "axios";
import Certificates from "./Certificates";
import Awards from "./Awards";
import Volunteer from "./Volunteer";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Publications from "./Publications";
import TestScore from "./TestScore";
const About = ({
  user,
  isNonMobile1024,
  watcher,
  setWatcher,
  isNonMobile450,
}) => {
  const aboutTextRef = useRef(null);
  const [readMore, setReadMore] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);

  useEffect(() => {
    if (aboutTextRef.current) {
      // Check if the actual height exceeds the max height for about
      if (aboutTextRef.current.scrollHeight > 120) {
        setShowReadMore(true);
      }
    }
  }, [user]);

  const [popup, setPopup] = useState(false);
  const [about, setAbout] = useState(user?.about);

  useEffect(() => {
    setAbout(user?.about);
  }, [user]);

  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    if (about) {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/update/student/about`,
          { about: about },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            toast.success("About Updated Successfully");
            setWatcher((prev) => {
              return {
                ...prev,
                about: res.data.about,
              };
            });
            setPopup(false);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Please enter something");
    }
  };

  return (
    <div>
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            overflowY: "auto",
            zIndex: 1001,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
          onClick={() => {
            setPopup(false);
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            style={{
              background: "#FFF",
              padding: "0.5rem",
              maxWidth: "715px",
              width: "100%",
            }}
          >
            <div
              className="  newPopUpContainer tablescroll"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "90vh",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="space align-items-center">
                <p className="gray90020400">About</p>
                <CloseIcon
                  className="hoverdarkblacksvg"
                  style={{ cursor: "pointer", height: "24px", width: "24px" }}
                  onClick={() => {
                    setPopup(false);
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  background: "#EAECF0",
                  margin: "0.75rem 0rem 1.5rem 0rem",
                }}
              />
              <p className="black14 mt-4">About:</p>
              <textarea
                className="col-md-12 col-12 forminput mt-3"
                value={about}
                placeholder="Enter Profile About"
                onChange={(e) => {
                  setAbout(e.target.value);
                }}
                maxLength={3000}
              />
              <button
                className="continuebutton mt-4 mb-5"
                onClick={() => handleConfirm()}
                disabled={loading}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className=""
        style={{ padding: "1.25rem", background: "#FFF", borderRadius: "5px" }}
      >
        <>
          <p className="gray90020400">About</p>
          <div
            style={{
              width: "100%",
              height: "1px",
              background: "#EAECF0",
              margin: "0.75rem 0rem 1.5rem 0rem",
            }}
          />
          {(user?.about || user?._id === watcher?._id) && (
            <div className="d-flex flex-column gap-1">
              <div className="space justify-content-end">
                <p
                  className="pink14 hovercolorback "
                  style={{ cursor: "pointer" }}
                  onClick={() => setPopup(true)}
                >
                  Edit
                </p>
              </div>
              <p
                ref={aboutTextRef}
                className="gray70014400"
                style={{
                  maxHeight: readMore ? "" : "120px",
                  overflow: readMore ? "" : "hidden",
                }}
              >
                {user?.about}
              </p>
            </div>
          )}
          {showReadMore && ( // Show the "Read More" button only if necessary
            <p
              className="pink14 hovercolorback"
              onClick={() => setReadMore(!readMore)}
              style={{ cursor: "pointer", marginTop: "0.75rem" }}
            >
              Read {readMore ? "Less" : "More"}
            </p>
          )}
        </>

        <div
          style={{
            marginTop: user?.about ? "1.5rem" : "0rem",
          }}
        >
          <p
            className="gray60011400"
            style={{ fontSize: "14px", fontWeight: 500 }}
          >
            Interests
          </p>
          <div
            className="d-flex flex-wrap"
            style={{ gap: "0.75rem", marginTop: "0.5rem" }}
          >
            {user?.selectedItem?.map((item, index) => (
              <div key={index} className="newdashboardsidechips">
                <img
                  src={item?.icon}
                  alt="item"
                  style={{ height: "16px", width: "16px" }}
                />
                {item?.text}
              </div>
            ))}
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              background: "#EAECF0",
              margin: "1.5rem 0rem",
            }}
          />
          <div className="space flex-wrap gap-3">
            <div
              style={{ width: isNonMobile1024 ? "calc(50% - 12px)" : "100%" }}
            >
              <p className="gray70014400" style={{ fontWeight: 500 }}>
                Level of Study
              </p>
              <div
                className="mt-2 d-flex flex-wrap "
                style={{ gap: "0.75rem" }}
              >
                <div className="chipsdiv">
                  {user?.levelofstudy} of {user?.study}
                </div>
                <div className="chipsdiv">Intake {user?.intake?.Year}</div>
              </div>
            </div>
            <div
              style={{ width: isNonMobile1024 ? "calc(50% - 12px)" : "100%" }}
            >
              <p className="gray70014400" style={{ fontWeight: 500 }}>
                Languages
              </p>
              <div
                className="mt-2 d-flex flex-wrap "
                style={{ gap: "0.75rem" }}
              >
                {user?.languages?.map((lang, index) => (
                  <div className="chipsdiv" key={index}>
                    {lang?.text}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(user?.experience?.length > 0 || user?._id === watcher?._id) && (
        <div>
          <Experience
            user={user}
            isNonMobile1024={isNonMobile1024}
            axios={axios}
            setWatcher={setWatcher}
            watcher={watcher}
          />
        </div>
      )}
      {(user?.education?.length > 0 || user?._id === watcher?._id) && (
        <div style={{ marginTop: "1.25rem" }}>
          <Education
            user={user}
            isNonMobile1024={isNonMobile1024}
            setWatcher={setWatcher}
            watcher={watcher}
            axios={axios}
            isNonMobile450={isNonMobile450}
          />
        </div>
      )}
      {(user?.certificates?.length > 0 || user?._id === watcher?._id) && (
        <div style={{ marginTop: "1.25rem" }}>
          <Certificates
            user={user}
            isNonMobile1024={isNonMobile1024}
            setWatcher={setWatcher}
            watcher={watcher}
            axios={axios}
          />
        </div>
      )}

      {(user?.awards?.length > 0 || user?._id === watcher?._id) && (
        <div style={{ marginTop: "1.25rem" }}>
          <Awards
            user={user}
            isNonMobile1024={isNonMobile1024}
            setWatcher={setWatcher}
            watcher={watcher}
            axios={axios}
          />
        </div>
      )}
      {(user?.volunteer?.length > 0 || user?._id === watcher?._id) && (
        <div style={{ marginTop: "1.25rem" }}>
          <Volunteer
            user={user}
            isNonMobile1024={isNonMobile1024}
            setWatcher={setWatcher}
            watcher={watcher}
            axios={axios}
            toast={toast}
          />
        </div>
      )}
      {(user?.publication?.length > 0 || user?._id === watcher?._id) && (
        <div style={{ marginTop: "1.25rem" }}>
          <Publications
            user={user}
            isNonMobile1024={isNonMobile1024}
            setWatcher={setWatcher}
            watcher={watcher}
            axios={axios}
            toast={toast}
          />
        </div>
      )}
      {(user?.testscore?.length > 0 || user?._id === watcher?._id) && (
        <div style={{ marginTop: "1.25rem" }}>
          <TestScore
            user={user}
            isNonMobile1024={isNonMobile1024}
            setWatcher={setWatcher}
            watcher={watcher}
            axios={axios}
            toast={toast}
          />
        </div>
      )}
    </div>
  );
};

export default About;
