const Third = ({ divWidth, newTask }) => {
  return (
    <div
      className="keytermsdiv"
      style={{
        width: divWidth,
        // height: "100%",
        height: "fit-content",
        marginRight: "48px",
        // maxHeight: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p className="gray14500">Context</p>
      <p style={{ marginTop: "0.75rem", color: "#0C111D" }} className="filmo20">
        Here's some background information
      </p>
      <div
        className="w-100"
        style={{
          height: "1px",
          backgroundColor: "#EAECF0",
          marginTop: "1rem",
          marginBottom: "1.5rem",
        }}
      />
      <div>
        <div
          className="allpoppins "
          dangerouslySetInnerHTML={{ __html: newTask?.taskBackground }}
        />
      </div>
    </div>
  );
};
export default Third;
