import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./body.css";
import upload from "../../assets/images/icons/upload.png";

import { Country, City } from "country-state-city";
import { useMediaQuery } from "@mui/material";
import TextArea from "../../components/Company/textarea";
import { toast } from "react-toastify";

import axios from "axios";
import { setId } from "../../state";
import { useDispatch } from "react-redux";
import ImageCropper from "../../components/Company/create/ImageCropper";
import SelectedBox from "../../assets/images/icons/selectedbox";
import UnselectedBox from "../../assets/images/icons/unselectedBox";
const Body = ({ Role, info, Company, dsa, user }) => {
  const email = useSelector((state) => state.email);
  const company = useSelector((state) => state.company);
  const [companyName, setCompanyName] = useState("");
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:768px)");
  const [firstName, setFirstName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [search, setSearch] = useState("");
  const [lastName, setLastName] = useState("");
  const [department, setDepartment] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedimage, setcroppedImage] = useState(null);
  const [isChangeImage, setChangeImage] = useState(false);
  const [tagline, setTagline] = useState("");
  const [agree, setAgree] = useState(false);
  const dispatch = useDispatch();
  const [location, setLocation] = useState("");
  const [country, setCountry] = useState({ Country: "", City: "" });
  const [citySearch, setCitySearch] = useState("");
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  const allCountries = Country.getAllCountries();

  useEffect(() => {
    setFirstName(info.info.lastName);
    setLastName(info.info.firstName);
    setcroppedImage(info.info.picture);
    setSelectedFile(info.info.picture);
  }, [info]);

  const goBack = () => {
    navigate(-1);
  };

  const handleCountrySearch = (e) => {
    setSearch(e.target.value);
    const filteredCountries = allCountries.filter((country) =>
      country.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredLocations(filteredCountries);
  };

  useEffect(() => {
    const GET_INFO = async () => {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`, {
          id: Company ?? company,
        })
        .then((res) => {
          if (res.data.message === "Logout") {
            navigate("/employer/register");
          } else {
            setCompanyName(res.data.title);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    GET_INFO();
  }, [company, Company]);

  const setIds = async (uniqueId) => {
    dispatch(setId({ uniqueId: uniqueId }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!firstName) {
      toast.error("Please Enter First Name", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else if (!lastName) {
      toast.error("Please Enter Last Name", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    // else if (!jobTitle) {
    //   toast.error("Please Enter Job title", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    //   return;
    // }
    else if (!department) {
      toast.error("Please Enter Department", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    //  else if (!phone) {
    //   toast.error("Please Enter Phone No.", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    //   return;
    // }
    else if (!country.Country.name) {
      toast.error("Please Select Country", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else if (!country.City.name) {
      toast.error("Please Select City", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else if (!croppedimage) {
      toast.error("Please upload Profile photo", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    if (!selectedFile) {
      toast.error("Please upload profile photo", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    // if (!tagline) {
    //   toast.error("Please write Tagline", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    //   return;
    // }

    if (selectedFile && typeof selectedFile !== "string") {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Url = reader.result;

        const payload = {
          firstName,
          lastName,
          jobTitle,
          phone,
          country,
          selectedFile: croppedimage,
          tagline,
          Role,
          department,
          email,
          company,
        };

        if (dsa) {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/create/profile/user/invite`,
              { ...payload, user }
            )
            .then((res) => {
              if (res.data.message === "Created") {
                setIds(res.data.savedUser.uniqueId);

                navigate("/guidelines");
              } else if (res.data.msg === "already") {
                toast.error("Profile created Already");
                navigate("/dashboard");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/create/profile/user`,
              payload
            )
            .then((res) => {
              if (res.data.message === "Created") {
                setIds(res.data.savedUser.uniqueId);

                navigate("/guidelines");
              } else if (res.data.msg === "already") {
                toast.error("Profile created Already");
                navigate("/dashboard");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
        reader.abort();
      };

      reader.readAsDataURL(selectedFile);
    } else {
      const payload = {
        firstName,
        lastName,
        jobTitle,
        phone,
        country,
        selectedFile: croppedimage,
        tagline,
        Role,
        department,
        email,
        company,
      };
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/create/profile/user`,
          payload
        )
        .then((res) => {
          if (res.data.message === "Created") {
            setIds(res.data.savedUser.uniqueId);
            navigate("/guidelines");
          } else if (res.data.msg === "already") {
            toast.error("Profile created Already");
            navigate("/dashboard");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const handleSelectCountry = (location) => {
    setCountry((prevCountry) => {
      const updatedCountry = prevCountry;
      updatedCountry.Country = location;
      updatedCountry.City = "";

      return updatedCountry;
    });
    setSearch("");
  };

  const handleSelectCity = (location) => {
    setCountry((prevCountry) => {
      const updatedCountry = prevCountry;
      updatedCountry.City = location;

      return updatedCountry;
    });
    setCitySearch("");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const img = new Image();
      img.onload = function () {
        if (img.width >= 300 && img.height >= 300) {
          setSelectedFile(file);
          setChangeImage(true);
        } else {
          toast.error("Image dimensions must be at least 300x300px.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return;
        }
      };
      img.src = URL.createObjectURL(file);
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileChange({ target: { files: event.dataTransfer.files } });
  };

  const handleCitySearch = (e) => {
    console.log(e.target.value);
    setCitySearch(e.target.value);
    const filtered = City.getCitiesOfCountry(country.Country.isoCode).filter(
      (country) =>
        country.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredCities(filtered);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const fileInputRef = React.createRef();
  useEffect(() => {
    if (!email) {
      navigate("/employer/register");
    }
  }, []);

  return (
    <>
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <div className="row d-flex">
          <p className="registeration">Create Profile</p>
        </div>
      </div>
      <div className="mt-5 d-flex">
        <div className="col-md-6 col-12" style={{ maxWidth: "567px" }}>
          <div className="row">
            <div>
              <p className="formtext">First Name *</p>

              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="forminput col-md-12 col-12 mt-2"
              />
            </div>
            <div>
              <p className="formtext " style={{ marginTop: "1rem" }}>
                Last Name *
              </p>

              <input
                value={lastName}
                placeholder=""
                onChange={(e) => setLastName(e.target.value)}
                className="forminput col-md-12 col-12 mt-2"
              />
            </div>
            <div>
              <p className="formtext" style={{ marginTop: "1rem" }}>
                Job Title
              </p>

              <input
                value={jobTitle}
                placeholder=""
                onChange={(e) => setJobTitle(e.target.value)}
                className="forminput col-md-12 col-12 mt-2"
              />
            </div>
            <div>
              <p className="formtext" style={{ marginTop: "1rem" }}>
                Department *
              </p>

              <input
                value={department}
                placeholder=""
                onChange={(e) => setDepartment(e.target.value)}
                className="forminput col-md-12 col-12 mt-2"
              />
            </div>
            <div>
              <p className="formtext" style={{ marginTop: "1rem" }}>
                Phone Number
              </p>

              <input
                value={phone}
                // type="text"
                placeholder=""
                onChange={(e) => {
                  const input = e.target.value;
                  const regex = /^[0-9\b]+$/; // Only allows numeric input or backspace (\b)
                  if (regex.test(input) || input === "") {
                    setPhone(e.target.value);
                  }
                }}
                className="forminput col-md-12 col-12 mt-2"
              />
            </div>
            <div>
              <p className="formtext " style={{ marginTop: "1rem" }}>
                Country *
              </p>
              <input
                value={search}
                placeholder=""
                onChange={(e) => handleCountrySearch(e)}
                className="forminput col-md-12 col-12 mt-2"
              />
              {country.Country?.name && (
                <div className="d-flex" style={{ marginTop: "0.75rem" }}>
                  <p
                    className="black14 p-2 d-flex gap-3 rounded-5 shadowa"
                    style={{ background: "#FFF" }}
                  >
                    <span> {country.Country?.flag}</span>
                    {country.Country.name}
                  </p>
                </div>
              )}
              {filteredLocations.length > 0 && search && (
                <div
                  className="rounded-2 d-flex flex-column tablescroll shadowa"
                  style={{
                    position: "absolute",
                    background: "#FFF",
                    gap: "1rem",
                    minWidth: "300px",
                    maxHeight: "200px",
                    padding: "1rem",
                    overflow: "auto",
                    zIndex: 3,
                    marginTop: "0.75rem",
                  }}
                >
                  {filteredLocations.map((location) => (
                    <p
                      className="d-flex gap-3 black14"
                      key={location.name}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSelectCountry(location)}
                    >
                      <span> {location.flag}</span>
                      {location.name}
                    </p>
                  ))}
                </div>
              )}
              {country.Country?.name !== "" && (
                <>
                  <p className="formtext " style={{ marginTop: "1rem" }}>
                    City *
                  </p>
                  <input
                    value={citySearch}
                    placeholder=""
                    onChange={(e) => handleCitySearch(e)}
                    className="forminput col-md-12 col-12 mt-2"
                  />
                  {country.City?.name && (
                    <div className="d-flex" style={{ marginTop: "0.75rem" }}>
                      <p
                        className="black14 p-2 d-flex gap-3 rounded-5 shadowa"
                        style={{ background: "#FFF" }}
                      >
                        {country.City.name}
                      </p>
                    </div>
                  )}
                  {!search && filteredCities.length > 0 && citySearch && (
                    <div
                      className="rounded-2 d-flex flex-column tablescroll shadowa"
                      style={{
                        position: "absolute",
                        background: "#FFF",
                        gap: "1rem",
                        minWidth: "300px",
                        maxHeight: "200px",
                        padding: "1rem",
                        overflow: "auto",
                        zIndex: 3,
                        marginTop: "0.75rem",
                      }}
                    >
                      {filteredCities.map((location, index) => (
                        <p
                          className="d-flex gap-3 black14"
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSelectCity(location)}
                        >
                          <span> {location.flag}</span>
                          {location.name}
                        </p>
                      ))}
                    </div>
                  )}
                </>
              )}
              {/* <Location
                setCountry={setCountry}
                setLocation={setLocation}
                location={location}
                country={country}
              /> */}
            </div>

            <>
              <div>
                <hr style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }} />
                <p className="formtext">Profile Photo </p>
                <div
                  className="drop-area mt-2"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={isChangeImage ? null : openFileInput}
                >
                  {selectedFile && isChangeImage && (
                    <>
                      <ImageCropper
                        selectedFile={selectedFile}
                        setcroppedImage={setcroppedImage}
                        setChangeImage={setChangeImage}
                      />
                    </>
                  )}

                  {croppedimage && !isChangeImage && (
                    <img
                      src={croppedimage}
                      alt="croped image"
                      style={{ maxWidth: "150px", maxHeight: "150px" }}
                    />
                  )}

                  {/* {selectedFile && !isChangeImage && !croppedimage && (
                      <img
                        src={selectedFile}
                        alt="sdadsadsa"
                        style={{ maxWidth: "150px", maxHeight: "150px" }}
                      />
                    )} */}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  {!selectedFile && (
                    <>
                      <img
                        src={upload}
                        alt="upload image"
                        style={{ maxHeight: "40px", maxWidth: "40px" }}
                      />
                      <div className="d-flex" style={{ gap: "5px" }}>
                        <p
                          style={{
                            color: "#E31B54",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Click to upload
                        </p>
                        <p
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          or drag and drop
                        </p>
                      </div>{" "}
                    </>
                  )}
                  {selectedFile && !isChangeImage && (
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#E31B54",
                      }}
                    >
                      Click to change
                    </p>
                  )}
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#475467",
                    }}
                  >
                    SVG, PNG, JPG, or GIF
                  </p>
                </div>
              </div>

              <>
                <div>
                  <p className="formtext" style={{ marginTop: "1rem" }}>
                    Tagline
                  </p>

                  <TextArea tagline={tagline} setTagline={setTagline} />
                </div>
                <div
                  className="d-flex mt-2"
                  style={{ justifyContent: "space-between" }}
                >
                  <p
                    className="col-md-10 "
                    style={{
                      color: "#667085",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Use your tagline to briefly describe what your role is. This
                    can be changed later
                  </p>

                  <p
                    className="col-md-2 text-end"
                    style={{
                      color: "#667085",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {tagline.length}/120
                  </p>
                </div>
              </>
            </>

            {/* <div className="mt-3 d-flex">
              <div
                className=""
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={() => setAgree(!agree)}
              >
                {agree ? <SelectedBox /> : <UnselectedBox />}
               
              </div>
              <div className="">
                <p
                  style={{
                    color: "#667085",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  I verify that I am an authorized representative of this
                  organization and have the right to act on its behalf in the
                  creation and management of this page.The organization and I
                  agree to the additional{" "}
                  <a
                    href="/terms"
                    target="_blank"
                    style={{
                      color: "#E31B54",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      marginLeft: "3px",
                      textDecoration: "none",
                      cursor: "pointer",
                      // Add margin-top to separate paragraphs
                    }}
                  >
                    Terms for Pages
                  </a>
                </p>{" "}
              </div>
            </div> */}
          </div>
        </div>
        {isNonMobile && (
          <div
            className="d-flex "
            style={{ justifyContent: "end", width: "50vw" }}
          >
            <div
              className="companyCardtoDisplay shadowa"
              style={{ overflow: "hidden", display: "block" }}
            >
              <div
                className=" m-5 mt-4 mb-0 d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div className="profilephoto" style={{ justifyContent: "end" }}>
                  <svg
                    className=""
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    viewBox="0 0 116 116"
                    fill="none"
                  >
                    <rect
                      width="115.95"
                      height="115.95"
                      rx="57.975"
                      fill="#FFF5F6"
                    />
                    {croppedimage && (
                      <>
                        <image
                          href={croppedimage}
                          x="0"
                          y="0"
                          width="100%"
                          height="100%"
                          clipPath="url(#rounded-clip)"
                        />
                        <defs>
                          <clipPath id="rounded-clip">
                            <rect width="100%" height="100%" rx="57.975" />
                          </clipPath>
                        </defs>{" "}
                      </>
                    )}
                  </svg>
                </div>
              </div>
              <p className="profileName m-5 mt-3 mb-0">
                {firstName ? firstName : "John"} {lastName ? lastName : "Smith"}
              </p>
              {companyName && (
                <p className="m-5 mt-0 mb-0 CreateProfileCompanyName">
                  {companyName}
                </p>
              )}
              <p className="m-5 mb-0 mt-3 tagline" style={{ display: "block" }}>
                {tagline ? tagline : "Tagline"}
              </p>
              <div
                className="d-flex m-5 mt-3 mb-3"
                style={{ flexWrap: "wrap" }}
              >
                {country.Country.name && (
                  <div className={`tagscard  `}>
                    {" "}
                    {country.Country && country.Country.flag}
                    <p className="itemstexts">{country.Country.name}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="1"
        viewBox="0 0 1360 1"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1360 1H0V0H1360V1Z"
          fill="#EAECF0"
        />
      </svg>
      <div className="d-flex mt-3" style={{ justifyContent: "end" }}>
        <button className="m-3 mt-0 mb-0 backbutton" onClick={goBack}>
          Back
        </button>
        <button
          className="continuebutton"
          type="submit"
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Continue
        </button>
      </div>
    </>
  );
};
export default Body;
