import { useState } from "react";
import ConnectedList from "./ConnectedList";
import RequestList from "./RequestList";
import { toast } from "react-toastify";
import axios from "axios";
import Search from "../../components/Search/Search";

const Connections = ({
  user,
  loadMoreRequests,
  requests,
  hasMore,
  watcher,
  setWatcher,

  setRequests,
  loadMoreConnects,
  connects,
  setConnects,
  hasMoreConnect,
  setSearch,
  search,
  loadingCon,
  loadingReq,
}) => {
  const buttons = [`Connected`, `Requests`];
  const [selected, setSelected] = useState("Connected");

  const [loading, setLoading] = useState(false);

  const handleConnect = async (msg, auser) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/handle/user/${auser?._id}/${msg}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          if (msg === "acceptreq") {
            toast.success("Request Accepted");

            setConnects((prev) => {
              return [requests.find((id) => id._id === auser?._id), ...prev];
            });
            setRequests((prev) => {
              const old = prev;
              return old.filter((id) => id?._id !== auser._id);
            });

            setWatcher((prev) => {
              return {
                ...prev,
                requests: watcher?.requests.filter((id) => id !== auser?._id),
              };
            });
            watcher.connects.push(auser?._id);
            user.connects.push(auser?._id);
          } else if (msg === "ignorereq") {
            toast.success("Request Removed");
            setRequests((prev) => {
              const old = prev;
              return old.filter((id) => id?._id !== auser._id);
            });

            setWatcher((prev) => {
              return {
                ...prev,
                requests: watcher?.requests.filter((id) => id !== auser?._id),
              };
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Handling Connection");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatNumber = (num) => {
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k";
    }
    return num;
  };

  return (
    <div
      className=""
      style={{
        padding: "1.25rem",
        marginTop: "1.875rem",
        background: "#FFF",
        borderRadius: "5px",
      }}
    >
      <p className="gray90020400">Followers</p>
      <div
        style={{
          width: "100%",
          height: "1px",
          background: "#EAECF0",
          margin: "0.75rem 0rem 1.5rem 0rem",
        }}
      />
      <div className="space align-items-center gap-4">
        <div
          className="d-flex gap-2"
          style={{ borderBottom: "1px solid #EAECF0", margin: "1.5rem 0rem" }}
        >
          {buttons.map((btn, index) => (
            <div
              key={index}
              className={`secondselectbutton ${
                selected === btn ? "secondselectbuttonselected" : ""
              }`}
              onClick={() => setSelected(btn)}
            >
              {btn}
              <span
                className="white16 center align-items-center"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E31b54",
                  borderRadius: "50%",
                  color: "white",
                  fontSize: "0.75rem",
                }}
              >
                {btn === "Connected"
                  ? formatNumber(user?.connects?.length)
                  : formatNumber(user?.requests?.length)}
              </span>
            </div>
          ))}
        </div>
        <Search search={search} setSearch={setSearch} />
      </div>
      <div>
        {selected === "Connected" && (
          <ConnectedList
            loadMoreConnects={loadMoreConnects}
            connects={connects}
            setConnects={setConnects}
            hasMoreConnect={hasMoreConnect}
            loadingCon={loadingCon}
          />
        )}

        {selected === "Requests" && (
          <RequestList
            requests={requests}
            loadMoreRequests={loadMoreRequests}
            hasMore={hasMore}
            handleConnect={handleConnect}
            loading={loading}
            loadingReq={loadingReq}
          />
        )}
      </div>
    </div>
  );
};
export default Connections;
