import Header from "../../../components/Company/field/Header";
import PrimaryFooter from "../../../layouts/components/Footer/PrimaryFooter";
import Find from "./Find";

const FindUni = () => {
  return (
    <div>
      <div
        className="backgroundimg d-flex"
        style={{ justifyContent: "space-between", flexDirection: "column" }}
      >
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany">
              <Header />
            </div>
          </div>
        </div>
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany center align-items-center">
              <div>
                <Find />
              </div>
            </div>
          </div>
        </div>
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany mb-5">
              <PrimaryFooter />
            </div>{" "}
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default FindUni;
