import { useMediaQuery } from "@mui/material";

import HomeFourthSection from "./HomeFourthSection";
const HomeThirdSection = ({
  selectedUni,
  setSelectedUni,
  country,
  setCountry,
  Countries,
  unilist,
  loading,
  checker,
  truncateString,
  setChecker,
  TRUE = false,
}) => {
  const isNonMobile = useMediaQuery("(min-width:768px)");
  const isNonMobile1025 = useMediaQuery("(min-width:1025px)");

  const isNonMobile450 = useMediaQuery("(min-width:450px)");

  return (
    <>
      <div>
        <HomeFourthSection
          unilist={unilist}
          selectedUni={selectedUni}
          setSelectedUni={setSelectedUni}
          country={country}
          setCountry={setCountry}
          Countries={Countries}
          loading={loading}
          checker={checker}
          setChecker={setChecker}
          truncateString={truncateString}
          isNonMobile1025={isNonMobile1025}
        />
        {TRUE && (
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div
              className="lastSection headerCompany d-flex"
              style={{
                justifyContent: isNonMobile ? "space-between" : "center",
                flexDirection: isNonMobile ? "row" : "column",
              }}
            >
              <p className={`startjourney ${isNonMobile && "section"}`}>
                Start your journey today
                <br />
                and explore your future career.
              </p>
              <div
                className="d-flex "
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: isNonMobile ? "" : "1rem",
                }}
              >
                <div
                  className={`${isNonMobile450 ? "d-flex" : ""} col-12`}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <a href="/login" style={{ textDecoration: "none" }}>
                    <button
                      className={`backbutton ${isNonMobile450 ? "" : "col-12"}`}
                    >
                      Login
                    </button>
                  </a>

                  {isNonMobile450 && (
                    <a href="/login" style={{ textDecoration: "none" }}>
                      <button
                        className={`continuebutton  ${
                          isNonMobile450 ? "" : "col-12 mt-3"
                        }`}
                      >
                        Register
                      </button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HomeThirdSection;
