import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import AllPost from "../SocialFeed/AllPosts";
import { useMediaQuery } from "@mui/material";
import RecommendChal from "../SocialFeed/RecommendedChallenges";

const OnePost = ({ user }) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [post, setPost] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [loading, setLoading] = useState(true);
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile450 = useMediaQuery("(min-width:450px)");

  const fetchPost = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/get/single/post/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res?.data?.msg === "success") {
          setPost(res?.data?.post);
          setHidden(res?.data?.hidden);
        } else {
          setPost(null);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchPost();
    } else {
    }
  }, [id]);

  const [emptyChal, setEmptyChal] = useState(false);

  return (
    <div className="marginsleftrightCompany">
      <div className="center">
        <div
          className="headerCompany d-flex "
          style={{ paddingTop: "1.5rem", gap: "30px" }}
        >
          <div style={{ width: "100%" }}>
            {loading ? (
              <div
                className="loader"
                style={{ position: "fixed", top: "50%", left: "50%" }}
              ></div>
            ) : (
              <div>
                {post.length > 0 ? (
                  <div>
                    <AllPost
                      posts={post}
                      setPosts={setPost}
                      hasMore={false}
                      isNonMobile450={isNonMobile450}
                      isNonMobile1024={isNonMobile1024}
                      user={user}
                      SinglePost={true}
                    />
                  </div>
                ) : (
                  <div style={{ position: "fixed", top: "50%", left: "50%" }}>
                    <p className="black14 text-center">No Post Found</p>
                    <button
                      className="mt-3 continuebutton"
                      onClick={() => navigate("/student")}
                    >
                      Return to feed
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          {isNonMobile1024 && !emptyChal && (
            <div
              className="d-flex flex-column gap-4 "
              style={{
                width: "300px",
                minWidth: "300px",
                maxWidth: "300px",
              }}
            >
              {!emptyChal && (
                <RecommendChal
                  emptyChal={emptyChal}
                  setEmptyChal={setEmptyChal}
                />
              )}
            </div>
          )}
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default OnePost;
