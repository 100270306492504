import AddIcon from "@mui/icons-material/Add";
import TabButtons from "../../components/TabButtons/TabButtons";
import Search from "../../components/Search/Search";
import { useEffect, useState } from "react";
import CreateGroup from "./CreateGroup";
import axios from "axios";
import EmptyState from "../../assets/images/EmptyState.png";
import ExploreGroups from "./Explore";
import "./main.css";
const StudentGroupMain = ({ student }) => {
  const [selected, setSelected] = useState("Explore Groups");
  const buttons = [{ name: "Explore Groups" }, { name: "My Groups" }];
  const [search, setSearch] = useState("");
  const [groups, setGroups] = useState([]);
  const [popup, setPopup] = useState(false);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  const [groupsToShow, setGroupsToShow] = useState(groups);
  const [myGroups, setMyGroups] = useState(groupsToShow);

  useEffect(() => {
    if (groups.length > 0) {
      const filteredGroups = groups.filter((group) => {
        return (
          group?.members.includes(student?._id) ||
          group?.owner === student?._id ||
          requests.includes(group?._id)
        );
      });
      setMyGroups(filteredGroups);
    }
  }, [student?._id, requests, groups]);

  const fetchGroups = async () => {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/get/groups`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.msg === "success") {
          setGroups(res.data.groups);
          setRequests(res.data.requests);

          setGroupsToShow(res.data.groups);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (groups.length > 0 && student) {
      const filteredMyGroups = groups.filter((group) => {
        return (
          group?.members.includes(student._id) || group?.owner === student._id
        );
      });

      const filteredExploreGroups = groups.filter((group) => {
        return (
          !group?.members.includes(student._id) && group?.owner !== student._id
        );
      });

      if (search.trim() !== "") {
        const lowerCaseSearch = search.toLowerCase();

        setGroupsToShow(
          filteredExploreGroups.filter((group) =>
            group.title.toLowerCase().includes(lowerCaseSearch)
          )
        );

        setMyGroups(
          filteredMyGroups.filter((group) =>
            group.title.toLowerCase().includes(lowerCaseSearch)
          )
        );
      } else {
        setGroupsToShow(filteredExploreGroups);
        setMyGroups(filteredMyGroups);
      }
    }
  }, [groups, student, requests, search]);

  return (
    <div className="marginsleftrightCompany" style={{ paddingTop: "1.875rem" }}>
      <div className="center">
        <div className="headerCompany ">
          {popup && <CreateGroup setPopup={setPopup} setGroups={setGroups} />}
          <div
            className="space flex-wrap align-items-center"
            style={{ gap: "1rem" }}
          >
            <div>
              <p className="studentHeading">Job Search</p>
              <p
                className="gray60011400"
                style={{ fontSize: "14px", marginTop: "0.25rem" }}
              >
                Create events for students around the globe and attract talent.
              </p>
            </div>
            <div className="d-flex">
              <button
                className="continuebutton "
                style={{
                  background: "transparent",
                  color: "#E31b54",
                  border: "1px solid #E31b54",
                  padding: "12px 20px",
                }}
                onClick={() => {
                  setPopup(true);
                }}
              >
                <AddIcon /> Create Group
              </button>
            </div>
          </div>
          <div
            className="space flex-wrap align-items-center"
            style={{ marginTop: "1.25rem", gap: "1rem" }}
          >
            <TabButtons
              buttons={buttons}
              selected={selected}
              setSelected={setSelected}
            />
            <div
              className="d-flex flex-wrap align-items-center"
              style={{ gap: "1rem" }}
            >
              <Search search={search} setSearch={setSearch} />
            </div>
          </div>
          <div style={{ marginTop: "1.875rem" }}>
            {loading ? (
              <div className="center">
                <div className="loader" />{" "}
              </div>
            ) : groups.length === 0 ? (
              <div className="center flex-column align-items-center">
                <img
                  src={EmptyState}
                  className="img-fluid"
                  alt="empty state"
                  style={{ maxWidth: "300px" }}
                />
                <p className="black14">No Groups at the moment</p>
              </div>
            ) : (
              <>
                {selected === "Explore Groups" && (
                  <ExploreGroups
                    groups={groupsToShow}
                    setGroups={setGroups}
                    student={student}
                    requests={requests}
                  />
                )}
                {selected === "My Groups" && (
                  <ExploreGroups
                    groups={myGroups}
                    setGroups={setGroups}
                    student={student}
                    requests={requests}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentGroupMain;
