import { useEffect, useRef, useState } from "react";

const Details = ({ group }) => {
  const [readMore, setReadMore] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [readMoreRules, setReadMoreRules] = useState(false);
  const [showReadMoreRules, setShowReadMoreRules] = useState(false);
  const aboutTextRef = useRef(null);
  const rulesTextRef = useRef(null);

  useEffect(() => {
    if (aboutTextRef.current) {
      // Check if the actual height exceeds the max height for about
      if (aboutTextRef.current.scrollHeight > 120) {
        setShowReadMore(true);
      }
    }

    if (rulesTextRef.current) {
      // Check if the actual height exceeds the max height for rules
      if (rulesTextRef.current.scrollHeight > 120) {
        setShowReadMoreRules(true);
      }
    }
  }, [group]);

  return (
    <div>
      {group?.about && (
        <div
          className="paddingAdjust"
          style={{ background: "#FFF", borderRadius: "5px" }}
        >
          <p className="gray90020400">About Group</p>
          <div
            style={{
              width: "100%",
              height: "1px",
              background: "#EAECF0",
              margin: "0.75rem 0rem",
            }}
          />
          <p
            ref={aboutTextRef}
            className="gray70014400"
            style={{
              maxHeight: readMore ? "" : "120px",
              overflow: readMore ? "" : "hidden",
            }}
          >
            {group?.about}
          </p>
          {showReadMore && ( // Show the "Read More" button only if necessary
            <p
              className="pink14 hovercolorback"
              onClick={() => setReadMore(!readMore)}
              style={{ cursor: "pointer", marginTop: "0.75rem" }}
            >
              Read {readMore ? "Less" : "More"}
            </p>
          )}
        </div>
      )}
      {group?.rules && (
        <div
          className="paddingAdjust"
          style={{
            background: "#FFF",

            borderRadius: "5px",
            marginTop: "2rem",
          }}
        >
          <p className="gray90020400">Group Rules</p>
          <div
            style={{
              width: "100%",
              height: "1px",
              background: "#EAECF0",
              margin: "0.75rem 0rem",
            }}
          />
          <p
            ref={rulesTextRef}
            className="gray70014400"
            style={{
              maxHeight: readMoreRules ? "" : "120px",
              overflow: readMoreRules ? "" : "hidden",
            }}
          >
            {group?.rules}
          </p>
          {showReadMoreRules && ( // Show the "Read More" button for rules only if necessary
            <p
              className="pink14 hovercolorback"
              onClick={() => setReadMoreRules(!readMoreRules)}
              style={{ cursor: "pointer", marginTop: "0.75rem" }}
            >
              Read {readMoreRules ? "Less" : "More"}
            </p>
          )}
        </div>
      )}
      {group?.Admins && group.Admins?.length > 0 && (
        <div
          className="paddingAdjust"
          style={{
            background: "#FFF",

            borderRadius: "5px",
            marginTop: "2rem",
          }}
        >
          <p className="gray90020400">Admin Details</p>
          <div
            style={{
              width: "100%",
              height: "1px",
              background: "#EAECF0",
              margin: "0.75rem 0rem",
            }}
          />
          {group?.Admins?.map((admin, index) => (
            <div key={index} className="d-flex" style={{ gap: "0.75rem" }}>
              <img
                src={admin?.profilePhoto}
                alt="profile"
                style={{
                  height: "48px",
                  width: "48px",
                  border: "2px solid #FFF",
                  borderRadius: "50%",
                }}
              />
              <div>
                <p className="gray95016500">
                  {admin?.firstName} {admin?.lastName}
                </p>
                <p className="mt-2 gray50014400">{admin?.uniName}</p>
                <a
                  href={`/student/user/${admin?.link}`}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <p
                    className="mt-2 pink14 hovercolorback"
                    style={{ cursor: "pointer" }}
                  >
                    View Profile
                  </p>
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Details;
