import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { toast } from "react-toastify";

import InterestItems from "../../../components/InterestItems/InterestItems";
const Step4 = ({ setSteps, selectedItem, setSelectedItem }) => {
  const handleSubmit = () => {
    if (selectedItem.length < 1) {
      toast.error("Please select at least one of the interests");
      return;
    } else {
      setSteps(5);
    }
  };

  return (
    <div className="">
      <p className="BasicInforMationHeading">Categorize job</p>
      <p className="UnderBasicHeading">
        Add filters based on student interests and must-have qualifications to
        refine candidate matches.
      </p>
      <div className=" " style={{ marginTop: "2.5rem" }}>
        <p className="thumbnailcreate "> Student Interests</p>
        <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
          Categorize challenge based on student interests. Add a maximum of 3
          interest fields.
        </p>
        <div style={{ marginTop: "0.75rem" }}>
          <InterestItems
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            TRUE={true}
          />
        </div>

        <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
        <div
          className="d-flex flex-wrap mb-5"
          style={{ justifyContent: "space-between", gap: "1.25rem" }}
        >
          <button className="backbutton" onClick={() => setSteps(3)}>
            Back
          </button>
          <button
            className="continuebutton"
            onClick={() => {
              handleSubmit();
            }}
          >
            Continue <ChevronRightIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
export default Step4;
