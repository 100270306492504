import { useEffect, useState } from "react";
import NewHeader from "../CompanyHome/NewHeader";
import SecondHeader from "../CompanyHome/NewHeaderSecond";

import "./home.css";
import { useLocation } from "react-router-dom";
import NonMobile from "../../components/NonMobile/NonMobile";

const NewComp = ({ children }) => {
  const [selected, setSelected] = useState("Students");

  const { isNonMobile1024 } = NonMobile();
  const location = useLocation();
  const [solution1, setSolution1] = useState(false);
  const path = location.pathname.toLowerCase();

  useEffect(() => {
    if (path === "/") {
      setSelected("Students");
    } else if (path.toLowerCase().includes("/employer")) {
      setSelected("Employer-Hub");
    } else if (path.toLowerCase().includes("/career-center")) {
      setSelected("Career Centers");
    }
  }, [path]);
  const [selected2, setSelected2] = useState("");

  return (
    <div style={{ width: "100vw", overflowX: "clip", position: "relative" }}>
      {isNonMobile1024 && (
        <div
          onMouseEnter={() => {
            setSolution1(false);
          }}
        >
          <NewHeader selected={selected} setSelected={setSelected} />
        </div>
      )}
      <SecondHeader
        selected={selected}
        setSelected={setSelected}
        solution1={solution1}
        setSolution1={setSolution1}
        path={path}
        selected2={selected2}
        setSelected2={setSelected2}
      />

      {children}
    </div>
  );
};
export default NewComp;
