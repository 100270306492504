const SuitCase = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <path
        d="M41.2109 52.9301H58.7891V58.7894H41.2109V52.9301Z"
        fill="#EAECF0"
      />
      <path
        d="M81.1287 58.7894H64.6484V61.7191C64.6484 63.338 63.3377 64.6488 61.7188 64.6488H38.2812C36.6623 64.6488 35.3516 63.338 35.3516 61.7191V58.7894H18.8713C15.0833 58.7894 11.7332 56.3747 10.5339 52.7812L0 21.1742V85.1566C0 90.0028 3.94287 93.9456 8.78906 93.9456H91.2109C96.0571 93.9456 100 90.0028 100 85.1566V21.1765L89.4653 52.7812C88.2668 56.3747 84.9167 58.7894 81.1287 58.7894Z"
        fill="#EAECF0"
      />
      <path
        d="M61.7171 6.05505H38.2796C33.4334 6.05505 29.4905 9.99792 29.4905 14.8441V17.7738H5.03906L16.0887 50.9273C16.4893 52.1259 17.6077 52.9301 18.8696 52.9301H35.3499V50.0004C35.3499 48.3814 36.6606 47.0707 38.2796 47.0707H61.7171C63.336 47.0707 64.6468 48.3814 64.6468 50.0004V52.9301H81.127C82.3889 52.9301 83.5074 52.1259 83.9079 50.9273L94.9583 17.7738H70.5061V14.8441C70.5061 9.99792 66.5633 6.05505 61.7171 6.05505ZM35.3499 17.7738V14.8441C35.3499 13.2274 36.6629 11.9144 38.2796 11.9144H61.7171C63.3337 11.9144 64.6468 13.2274 64.6468 14.8441V17.7738H35.3499Z"
        fill="#EAECF0"
      />
    </svg>
  );
};

export default SuitCase;
