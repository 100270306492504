import "./Main.css";
import First from "./FirstSection";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import Fifth from "./Fifth";
import NonMobile from "../../../components/NonMobile/NonMobile";

import HomeFooter from "../../../components/Home/HomeFooter";
import NewComp from "../../Home/NewComp";
const UniversityHome = () => {
  const { isNonMobile450 } = NonMobile();

  return (
    <NewComp>
      <div style={{ width: "100vw", overflowX: "clip" }}>
        <div className="university-first ">
          <First />
        </div>
        <div style={{ marginTop: "4.375rem", padding: "2.5rem 0rem" }}>
          <Second />
        </div>
        <div
          style={{
            background: "rgba(242, 244, 247, 0.80)",
            padding: "80px 0px",
          }}
        >
          <Third isNonMobile450={isNonMobile450} />
        </div>
        <div style={{ padding: "5rem 0rem 8.125rem 0rem" }}>
          <Fourth />
        </div>
        <div
          style={{
            paddingTop: "3.75rem",
          }}
        >
          <Fifth />
        </div>
        <div className="" style={{ background: "#0C111D" }}>
          <div className="marginsleftrightCompany  pt-5">
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <div className="headerCompany">
                <HomeFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewComp>
  );
};
export default UniversityHome;
