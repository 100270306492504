import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { toast } from "react-toastify";
const Step2 = ({
  timeRequirement,
  setTimeRequirement,
  employmentType,
  setEmploymentType,
  duration,
  setDuration,
  setSteps,
  isNonMobile,
  estimatedStartDate,
  setEstimatedStartDate,
  estimatedEndDate,
  setEstimatedEndDate,
  hours,
  setHours,
  perHour,
  setPerHour,
}) => {
  const durationOptions = [
    "1-3 months",
    "4-6 months",
    "7-9 months",
    "10-12 months",
  ];

  const handleSubmit = () => {
    if (employmentType === "Temporary") {
      if (!duration) {
        toast.error("Please Select Duration");
        return;
      } else if (!estimatedStartDate) {
        toast.error("Please Select Estimated Start Date");
        return;
      } else if (!estimatedEndDate) {
        toast.error("Please Select Estimated End Date");
        return;
      } else {
        setSteps(3);
      }
    } else {
      setSteps(3);
    }
  };

  return (
    <div className="">
      <p className="BasicInforMationHeading">Time Requirements</p>
      <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
        What is the anticipated workload for candidates?
      </p>
      <div
        className="w-full d-flex"
        style={{ marginTop: "2.5rem", gap: "1.25rem" }}
      >
        <div
          className={`rolecards  ${
            timeRequirement === "Full time" ? "selectedrolecard" : ""
          }`}
          style={{
            width: "48%",
            padding: "1.25rem",
          }}
          onClick={() => setTimeRequirement("Full time")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M14.4173 12.6502C13.9248 12.1912 13.1573 12.2048 12.6813 12.6808C12.2053 13.1568 12.1917 13.9243 12.6507 14.4168L18.9507 20.7168V28.5335C18.9507 29.2239 19.5103 29.7835 20.2007 29.7835C20.891 29.7835 21.4507 29.2239 21.4507 28.5335V20.2002C21.4504 19.8688 21.3185 19.551 21.084 19.3168L14.4173 12.6502Z"
              fill={timeRequirement === "Full time" ? "#E31B54" : "#000000"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.6673 3.3335H23.734C27.2702 3.3335 30.6616 4.73825 33.1621 7.23874C35.6626 9.73922 37.0673 13.1306 37.0673 16.6668V23.7335C37.0673 31.0973 31.0978 37.0668 23.734 37.0668H16.6673C9.30352 37.0668 3.33398 31.0973 3.33398 23.7335V16.6668C3.33398 9.30303 9.30352 3.3335 16.6673 3.3335ZM23.734 34.5668C29.7133 34.5577 34.5581 29.7128 34.5673 23.7335V16.6668C34.5581 10.6876 29.7133 5.84267 23.734 5.8335H16.6673C10.688 5.84267 5.84316 10.6876 5.83398 16.6668V23.7335C5.84316 29.7128 10.688 34.5577 16.6673 34.5668H23.734Z"
              fill={timeRequirement === "Full time" ? "#E31B54" : "#000000"}
            />
          </svg>

          <p
            className={`rolecardsheading ${
              timeRequirement === "Full time"
                ? "recruiter-text"
                : "default-text"
            }`}
            style={{ marginTop: "1.25rem" }}
          >
            Full time
          </p>
          <p
            className={`rolecardstext mt-2 ${
              timeRequirement === "Full time"
                ? "recruiter-text"
                : "default-text"
            }`}
          >
            More than 30 hours per week
          </p>
        </div>
        <div
          className={`rolecards  ${
            timeRequirement === "Part time" ? "selectedrolecard" : ""
          }`}
          style={{
            width: "48%",
            padding: "1.25rem",
          }}
          onClick={() => setTimeRequirement("Part time")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.2498 9.04991C30.1172 11.2788 32.5002 15.4029 32.4998 19.8666C32.5061 24.3362 30.1223 28.4681 26.2498 30.6999V35.5666C26.2498 37.251 24.8843 38.6166 23.1998 38.6166H16.7998C15.1154 38.6166 13.7498 37.251 13.7498 35.5666V30.6999C9.86548 28.4759 7.46912 24.3425 7.46912 19.8666C7.46912 15.3906 9.86548 11.2572 13.7498 9.03324V4.16658C13.7498 2.48211 15.1154 1.11658 16.7998 1.11658H23.1998C24.8843 1.11658 26.2498 2.48211 26.2498 4.16658V9.04991ZM16.7998 3.61658C16.4961 3.61658 16.2498 3.86282 16.2498 4.16658V7.93324C18.6927 7.17718 21.307 7.17718 23.7498 7.93324V4.16658C23.7498 3.86282 23.5036 3.61658 23.1998 3.61658H16.7998ZM23.7498 35.5666C23.7498 35.8703 23.5036 36.1166 23.1998 36.1166H16.7998C16.4961 36.1166 16.2498 35.8703 16.2498 35.5666V31.7999C18.6927 32.556 21.307 32.556 23.7498 31.7999V35.5666ZM9.99983 19.8666C9.99983 25.3894 14.477 29.8666 19.9998 29.8666C22.652 29.8666 25.1955 28.813 27.0709 26.9376C28.9463 25.0623 29.9998 22.5187 29.9998 19.8666C29.9998 14.3437 25.5227 9.86658 19.9998 9.86658C14.477 9.86658 9.99983 14.3437 9.99983 19.8666Z"
              fill={timeRequirement === "Part time" ? "#E31B54" : "#000000"}
            />
            <path
              d="M19.7998 14.3166C19.6142 13.8942 19.211 13.6085 18.751 13.5733C18.291 13.5381 17.849 13.7591 17.6012 14.1482C17.3534 14.5373 17.34 15.0313 17.5665 15.4332L19.6665 19.6166L17.7998 21.4832C17.5616 21.7154 17.4272 22.0339 17.4272 22.3666C17.4272 22.6992 17.5616 23.0178 17.7998 23.2499C18.0319 23.4876 18.351 23.6201 18.6832 23.6166C19.0149 23.6181 19.3334 23.486 19.5665 23.2499L22.0665 20.7499C22.4423 20.3709 22.536 19.7952 22.2998 19.3166L19.7998 14.3166Z"
              fill={timeRequirement === "Part time" ? "#E31B54" : "#000000"}
            />
          </svg>
          <p
            className={`rolecardsheading ${
              timeRequirement === "Part time"
                ? "recruiter-text"
                : "default-text"
            }`}
            style={{ marginTop: "1.25rem" }}
          >
            Part time
          </p>
          <p
            className={`rolecardstext mt-2 ${
              timeRequirement === "Part time"
                ? "recruiter-text"
                : "default-text"
            }`}
          >
            Less than 30 hours per week
          </p>
        </div>
      </div>
      {timeRequirement === "Part time" && (
        <div style={{ marginTop: "2.5rem" }}>
          <p className="thumbnailcreate "> Hours (optional)</p>
          <div
            className="d-flex w-full"
            style={{
              justifyContent: isNonMobile ? "space-between" : "",
              flexDirection: isNonMobile ? "row" : "column",
            }}
          >
            <div
              className="d-flex"
              style={{ width: isNonMobile ? "40.3%" : "100%" }}
            >
              <input
                type="number"
                placeholder="Enter Number of hours"
                className=" forminput "
                style={{
                  marginTop: "0.75rem",
                  width: "100%",
                  display: "block",
                }}
                value={hours}
                onChange={(e) => setHours(e.target.value)}
              />
            </div>
            <div
              className={`${isNonMobile && "center"}`}
              style={{ alignItems: "center" }}
            >
              <p
                className="black14"
                style={{ marginTop: "0.75rem", fontWeight: "400" }}
              >
                hours per
              </p>
            </div>
            <div
              className="d-flex"
              style={{ width: isNonMobile ? "40.3%" : "100%" }}
            >
              <select
                className=" forminput "
                style={{
                  marginTop: "0.75rem",
                  width: "100%",
                  display: "block",
                }}
                value={perHour}
                onChange={(e) => setPerHour(e.target.value)}
                onSelect={(e) => setPerHour(e.target.value)}
              >
                <option value="Day">Day</option>
                <option value="Week">Week</option>
                <option value="Month">Month</option>
              </select>
            </div>
          </div>
        </div>
      )}
      <div style={{ marginTop: "2.5rem" }}>
        <p className="thumbnailcreate ">Employment type</p>
        <div
          className="d-flex"
          style={{ marginTop: "0.75rem", flexDirection: "column", gap: "1rem" }}
        >
          <div className="d-flex">
            <div
              className="d-flex"
              style={{ gap: "0.75rem", cursor: "pointer" }}
              onClick={() => setEmploymentType("Permanent")}
            >
              <div className="d-flex">
                {employmentType === "Permanent" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.97591 1.6665C5.37591 1.6665 1.64258 5.39984 1.64258 9.99984C1.64258 14.5998 5.37591 18.3332 9.97591 18.3332C14.5759 18.3332 18.3092 14.5998 18.3092 9.99984C18.3092 5.39984 14.5842 1.6665 9.97591 1.6665ZM10.0009 13.5248C8.05091 13.5248 6.47591 11.9498 6.47591 9.99984C6.47591 8.04984 8.05091 6.47484 10.0009 6.47484C11.9509 6.47484 13.5259 8.04984 13.5259 9.99984C13.5259 11.9498 11.9509 13.5248 10.0009 13.5248Z"
                      fill="#E31B54"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
              <p className="black14" style={{ fontWeight: "400" }}>
                Permanent (equal to or longer than 12 month contract)
              </p>
            </div>
          </div>
          <div className="d-flex">
            <div
              className="d-flex"
              style={{ gap: "0.75rem", cursor: "pointer" }}
              onClick={() => setEmploymentType("Temporary")}
            >
              <div className="d-flex">
                {employmentType === "Temporary" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.97591 1.6665C5.37591 1.6665 1.64258 5.39984 1.64258 9.99984C1.64258 14.5998 5.37591 18.3332 9.97591 18.3332C14.5759 18.3332 18.3092 14.5998 18.3092 9.99984C18.3092 5.39984 14.5842 1.6665 9.97591 1.6665ZM10.0009 13.5248C8.05091 13.5248 6.47591 11.9498 6.47591 9.99984C6.47591 8.04984 8.05091 6.47484 10.0009 6.47484C11.9509 6.47484 13.5259 8.04984 13.5259 9.99984C13.5259 11.9498 11.9509 13.5248 10.0009 13.5248Z"
                      fill="#E31B54"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
              <p className="black14" style={{ fontWeight: "400" }}>
                Temporary
              </p>
            </div>
          </div>
        </div>
      </div>
      {employmentType === "Temporary" && (
        <>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="thumbnailcreate ">Duration</p>
            <div
              className="d-flex flex-wrap"
              style={{ marginTop: "0.75rem", gap: "1.5rem" }}
            >
              {durationOptions.map((item, index) => (
                <div
                  key={index}
                  className="d-flex"
                  style={{ gap: "0.75rem", cursor: "pointer" }}
                  onClick={() => setDuration(item)}
                >
                  <div className="d-flex">
                    {item === duration ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.97591 1.6665C5.37591 1.6665 1.64258 5.39984 1.64258 9.99984C1.64258 14.5998 5.37591 18.3332 9.97591 18.3332C14.5759 18.3332 18.3092 14.5998 18.3092 9.99984C18.3092 5.39984 14.5842 1.6665 9.97591 1.6665ZM10.0009 13.5248C8.05091 13.5248 6.47591 11.9498 6.47591 9.99984C6.47591 8.04984 8.05091 6.47484 10.0009 6.47484C11.9509 6.47484 13.5259 8.04984 13.5259 9.99984C13.5259 11.9498 11.9509 13.5248 10.0009 13.5248Z"
                          fill="#E31B54"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                  <p className="black14" style={{ fontWeight: "400" }}>
                    {item}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div
            className="d-flex"
            style={{
              marginTop: "1.5rem",
              flexDirection: isNonMobile ? "row" : "column",
              gap: "1.5rem",
            }}
          >
            <div style={{ width: "48%" }}>
              <p className="thumbnailcreate ">Estimated Start Date</p>
              <input
                type="date"
                className=" forminput "
                style={{
                  marginTop: "0.75rem",
                  width: "100%",
                  display: "block",
                }}
                value={estimatedStartDate}
                onChange={(e) => setEstimatedStartDate(e.target.value)}
              />
            </div>
            <div style={{ width: "48%" }}>
              <p className="thumbnailcreate ">Estimated End Date</p>
              <input
                type="date"
                className=" forminput "
                style={{
                  marginTop: "0.75rem",
                  width: "100%",
                  display: "block",
                }}
                value={estimatedEndDate}
                onChange={(e) => setEstimatedEndDate(e.target.value)}
              />
            </div>
          </div>
        </>
      )}
      <div>
        <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
        <div
          className="d-flex flex-wrap mb-5"
          style={{ justifyContent: "space-between", gap: "1.25rem" }}
        >
          <button className="backbutton" onClick={() => setSteps(1)}>
            Back
          </button>
          <button
            className="continuebutton"
            onClick={() => {
              handleSubmit();
            }}
          >
            Continue <ChevronRightIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
export default Step2;
