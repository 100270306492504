import img1 from "../../../assets/images/slider1.png";
import img2 from "../../../assets/images/slider2.png";
import img3 from "../../../assets/images/slider3.png";
import Marquee from "react-fast-marquee";

const Second = ({ isNon }) => {
  const initialSliders = [
    {
      img: img1,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="22"
          viewBox="0 0 26 22"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6657 0.393311H4.33239C2.02789 0.501397 0.241968 2.44805 0.332389 4.75331V12.0333C0.241968 14.3386 2.02789 16.2852 4.33239 16.3933H9.31906C9.18244 17.244 8.97733 18.0823 8.70572 18.9L8.25239 20.1533C8.1161 20.488 8.14554 20.8673 8.33185 21.177C8.51815 21.4866 8.83952 21.6903 9.19906 21.7266H16.7991C17.1586 21.6903 17.48 21.4866 17.6663 21.177C17.8526 20.8673 17.882 20.488 17.7457 20.1533L17.2924 18.9C17.0208 18.0823 16.8157 17.244 16.6791 16.3933H21.6657C23.9702 16.2852 25.7561 14.3386 25.6657 12.0333V4.75331C25.7561 2.44805 23.9702 0.501397 21.6657 0.393311ZM10.5324 19.7266V19.5666C10.8215 18.7503 11.0444 17.9121 11.1991 17.06H14.6924C14.8533 17.9106 15.0761 18.7484 15.3591 19.5666V19.7266H10.5324ZM21.6657 14.3933C22.8627 14.2804 23.7507 13.2326 23.6657 12.0333V4.75331C23.7507 3.554 22.8627 2.50619 21.6657 2.39331H4.33239C3.13538 2.50619 2.24741 3.554 2.33239 4.75331V12.0333C2.24741 13.2326 3.13538 14.2804 4.33239 14.3933H21.6657Z"
            fill="#0077B6"
          />
        </svg>
      ),
      title: "Presentations",
      text: " Presentations for students, faculty, and staff",
    },
    {
      img: img2,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.667 3.16675H20.9337C25.3519 3.16675 28.9337 6.74847 28.9337 11.1667V21.4334C28.9337 25.8517 25.3519 29.4334 20.9337 29.4334H10.667C6.24871 29.4334 2.66699 25.8517 2.66699 21.4334V11.1667C2.66699 6.74847 6.24871 3.16675 10.667 3.16675ZM26.9337 11.1667C26.9263 7.85608 24.2443 5.17408 20.9337 5.16675H10.667C7.35633 5.17408 4.67432 7.85608 4.66699 11.1667V21.4334C4.67432 24.7441 7.35633 27.4261 10.667 27.4334H20.9337C24.2443 27.4261 26.9263 24.7441 26.9337 21.4334V11.1667Z"
            fill="#7835CD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.067 16.3001C10.0743 13.1367 12.6369 10.5741 15.8003 10.5667C18.9637 10.5741 21.5263 13.1367 21.5337 16.3001C21.5337 19.4665 18.9668 22.0334 15.8003 22.0334C12.6339 22.0334 10.067 19.4665 10.067 16.3001ZM19.5337 16.3001C19.5337 14.2382 17.8622 12.5667 15.8003 12.5667C13.7415 12.5741 12.0743 14.2413 12.067 16.3001C12.067 18.3619 13.7385 20.0334 15.8003 20.0334C17.8622 20.0334 19.5337 18.3619 19.5337 16.3001Z"
            fill="#7835CD"
          />
          <path
            d="M20.6803 9.17342C20.6803 8.37444 21.328 7.72675 22.127 7.72675C22.926 7.72675 23.5737 8.37444 23.5737 9.17342C23.5737 9.97239 22.926 10.6201 22.127 10.6201C21.328 10.6201 20.6803 9.97239 20.6803 9.17342Z"
            fill="#7835CD"
          />
        </svg>
      ),
      title: "Social Media Kit",
      text: "Social media post templates for Instagram, Facebook, and LinkedIn",
    },
    {
      img: img3,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
        >
          <path
            d="M19.5998 9.39318C20.5299 9.39318 21.4218 9.76263 22.0794 10.4203C22.7371 11.0779 23.1065 11.9698 23.1065 12.8998V20.4998C23.1065 21.0521 23.5542 21.4998 24.1065 21.4998C24.6588 21.4998 25.1065 21.0521 25.1065 20.4998V12.8998C25.1065 11.4394 24.5263 10.0387 23.4936 9.00604C22.4609 7.97334 21.0603 7.39318 19.5998 7.39318H8.93318V4.33984C8.93318 3.78756 8.48546 3.33984 7.93318 3.33984C7.38089 3.33984 6.93318 3.78756 6.93318 4.33984V7.39318H3.83984C3.28756 7.39318 2.83984 7.84089 2.83984 8.39318C2.83984 8.94546 3.28756 9.39318 3.83984 9.39318H19.5998Z"
            fill="#FC7E00"
          />
          <path
            d="M28.1598 23.6065H12.3998C11.4698 23.6065 10.5779 23.2371 9.92026 22.5794C9.26263 21.9218 8.89318 21.0299 8.89318 20.0998V12.4998C8.89318 11.9476 8.44546 11.4998 7.89318 11.4998C7.34089 11.4998 6.89318 11.9476 6.89318 12.4998V20.0998C6.89318 23.1411 9.3586 25.6065 12.3998 25.6065H23.0665V28.6598C23.0665 29.2121 23.5142 29.6598 24.0665 29.6598C24.6188 29.6598 25.0665 29.2121 25.0665 28.6598V25.6065H28.1198C28.6721 25.6065 29.1198 25.1588 29.1198 24.6065C29.1198 24.0542 28.6721 23.6065 28.1198 23.6065H28.1598Z"
            fill="#FC7E00"
          />
        </svg>
      ),
      title: "Banners & Posters",
      text: "Access ready-to-use print media templates",
    },
  ];

  return (
    <>
      <div className="">
        <div className="">
          <Marquee>
            <div className="d-flex" style={{ position: "relative" }}>
              {initialSliders.map((slide, index) => (
                <div key={index} className="d-flex">
                  <img
                    src={slide.img}
                    alt="slider1"
                    style={{
                      maxHeight: "483px",
                      width: isNon ? "25vw" : "70vw",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                  />
                  <div
                    className="center align-items-center"
                    style={{
                      width: isNon ? "25vw" : "70vw",
                      minHeight: "100%",
                      maxHeight: "483px",
                      padding: "1rem",
                    }}
                  >
                    <div>
                      <div className="center">{slide.svg}</div>
                      <p
                        className="gray95020400 text-center"
                        style={{ fontFamily: "Poppins", marginTop: "1.25rem" }}
                      >
                        {slide.title}
                      </p>
                      <div className="center d-flex">
                        <p
                          style={{
                            marginTop: "0.75rem",
                            fontSize: "1rem",
                            maxWidth: "80%",
                          }}
                          className="gray70014400 text-center"
                        >
                          {slide.text}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </>
  );
};

export default Second;
