import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const Register = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Function to extract code from URL
    const getCodeFromURL = async () => {
      // Get the current URL
      const urlParams = new URLSearchParams(window.location.search);
      // Extract the 'code' parameter
      const code = urlParams.get("code");
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/linkedin/redirect`, {
          code: code,
        })
        .then((res) => {
          if (res.data?.Err) {
            navigate("/employer/register");
          } else {
            axios
              .post(
                `${process.env.REACT_APP_BACKEND_URL}/organisation/register/linkedin`,
                {
                  data: res.data,
                }
              )
              .then((res) => {
                if (res.data.msg === "already") {
                  toast.error("Already registered,Please signin instead");
                  navigate("/employer/register");
                } else if (res.data.msg === "success") {
                  navigate(`/Find/${res.data.uniqueId}`);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          navigate("/employer/register");
        });
    };

    getCodeFromURL();
  }, []);

  return (
    <div
      className="d-flex"
      style={{
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="loader"></div>
    </div>
  );
};

export default Register;
