import { useState } from "react";
import Graduate from "../../assets/images/icons/Graduate.png";

import SelectOption from "../../components/Input/Select";
const Step2 = ({ isNonMobile, study, setStudy }) => {
  const options = [
    "Business, Economics & Management",
    "STEM (Science, Technology, Engineering, and Mathematics)",
    "Computer Science & IT",
    "Social Sciences",
    "Law & Legal Studies",
    "Political Science & International Relations",
    "Health Sciences, Psychology & Medicine",
    "Education",

    "Environmental Studies",
    "Communication & Media",
    "Arts & Humanities",
    "Language & Literature",

    "Others",
  ];
  const [dropdown, setDropDown] = useState(false);
  return (
    <div
      className={`${isNonMobile ? "mt-4" : "mt-3"} `}
      style={{ position: "relative" }}
    >
      <div className="d-flex">
        <div
          className="center align-items-center p-2 rounded-5"
          style={{ background: "#F2F4F7" }}
        >
          <img
            src={Graduate}
            alt="Graduate"
            style={{ height: "36px", width: "36px" }}
          />
        </div>
      </div>
      <p className={`gray95024400 ${isNonMobile ? "mt-4" : "mt-3"}`}>
        What do you study?
      </p>
      <p className="gray70014400 mt-2">
        Enter your major, discipline, or focus of study
      </p>
      <SelectOption
        value={study}
        setValue={setStudy}
        options={options}
        placeholder={
          " Select your major (e.g. General Management / Finance / Law)"
        }
      />
    </div>
  );
};
export default Step2;
