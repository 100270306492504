import CompanyImage from "../../components/Company/CompanyImage";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const AwardList = ({
  setAddAward,
  loading,
  awards,
  setNewAward,
  setIndex,
  handleUpdate,
  setAwards,
}) => {
  return (
    <div>
      {awards?.map((cert, index) => (
        <div key={index} style={{ marginTop: "1.5rem" }}>
          <div className="d-flex justify-content-end" style={{ gap: "1rem" }}>
            <EditIcon
              className="hoverred"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIndex(index);
                setNewAward(cert);
                setAddAward(true);
              }}
            />
            <DeleteIcon
              className="hoverred"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAwards(awards.filter((_, i) => i !== index));
              }}
            />
          </div>
          <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
            {cert?.logo && (
              <CompanyImage
                src={
                  cert?.logo instanceof File
                    ? URL.createObjectURL(cert?.logo)
                    : cert?.logo
                }
              />
            )}
            <div>
              {cert?.title && (
                <p className="black16 text-start">{cert?.title}</p>
              )}
              {cert?.companyName && (
                <p className="gray14400" style={{ marginTop: "0.5rem" }}>
                  {cert?.companyName}
                </p>
              )}
              {cert?.package && (
                <p
                  className="gray14400"
                  style={{ fontWeight: "600", marginTop: "0.75rem" }}
                >
                  {cert?.package}
                </p>
              )}
              {cert?.issue && (
                <p className="gray14400" style={{ marginTop: "0.75rem" }}>
                  Issued date: {cert?.issue}
                </p>
              )}
              {cert?.description && (
                <p className="gray14400" style={{ marginTop: "0.75rem" }}>
                  {cert?.description}
                </p>
              )}
            </div>
          </div>
        </div>
      ))}
      <div className="mt-3">
        <div className="d-flex">
          <p
            className=" text-start backbutton hoverred mt-3"
            onClick={() => setAddAward(true)}
            style={{ cursor: "pointer" }}
          >
            Add New
          </p>
        </div>
        <hr className="mt-5" />
        <button
          className="continuebutton mt-2"
          onClick={() => handleUpdate()}
          disabled={loading}
        >
          Update Awards & Scholarships
        </button>
      </div>
    </div>
  );
};

export default AwardList;
