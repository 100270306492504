// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.postingtab__container {
  padding: 1.25rem;

  border-radius: 5px;
  background: hsl(0, 0%, 100%);
  width: 100%;

  /* blue */
}

.newprofilephoto {
  border-radius: 72.316px;
  border: 1px solid #fff;
  background: var(lightgray 50% / cover no-repeat, #e0e0e0);

  /* Trans Card: Icons */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.postingtab__input {
  display: flex;
  height: 65px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0;
  border-radius: 5px;
  background: var(--Gray-50, #f9fafb);
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border: 1px solid #eaecf0;
  outline: none;
}
.postingtab__input::placeholder {
  color: #667085;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SocialFeed/postingtab.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;;EAEhB,kBAAkB;EAClB,4BAA4B;EAC5B,WAAW;;EAEX,SAAS;AACX;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,yDAAyD;;EAEzD,sBAAsB;EACtB;;;kDAGgD;AAClD;AACA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,SAAW;EACX,kBAAkB;EAClB,mCAAmC;EACnC,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;EAChC,yBAAyB;EACzB,aAAa;AACf;AACA;EACE,cAAc;EACd,uBAAkB;EAAlB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".postingtab__container {\n  padding: 1.25rem;\n\n  border-radius: 5px;\n  background: hsl(0, 0%, 100%);\n  width: 100%;\n\n  /* blue */\n}\n\n.newprofilephoto {\n  border-radius: 72.316px;\n  border: 1px solid #fff;\n  background: var(lightgray 50% / cover no-repeat, #e0e0e0);\n\n  /* Trans Card: Icons */\n  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),\n    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),\n    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),\n    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);\n}\n.postingtab__input {\n  display: flex;\n  height: 65px;\n  padding: 12px;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  flex: 1 0 0;\n  border-radius: 5px;\n  background: var(--Gray-50, #f9fafb);\n  color: var(--Gray-500, #667085);\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px; /* 142.857% */\n  border: 1px solid #eaecf0;\n  outline: none;\n}\n.postingtab__input::placeholder {\n  color: #667085;\n  width: fit-content;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
