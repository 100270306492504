import { useState } from "react";
import InterestItems from "../../components/InterestItems/InterestItems";
import UniSelect from "./UniSelect";
import NonMobile from "../../components/NonMobile/NonMobile";
const Second = ({ navigate, setSelectedUni, selectedUni }) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const { isNonMobile1024, isNonMobile768, isNonMobile500 } = NonMobile();
  const handleContinueClick = () => {
    navigate("/register", { state: { selectedItems: selectedItems } });
  };
  return (
    <div className="newHomeSecond">
      <div className="marginsleftrightCompany">
        <div className="center">
          <div
            className="headerCompany d-flex "
            style={{
              position: "relative",
              gap: isNonMobile1024 ? "3.75rem" : "2rem",
              flexDirection: isNonMobile1024 ? "row" : "column",
            }}
          >
            <div
              style={{
                width: isNonMobile1024 ? "35.48%" : "100%",
                maxWidth: isNonMobile1024 ? "35.48%" : "100%",
                position: "relative",
              }}
            >
              <div className="d-flex">
                {" "}
                <p
                  className="itsearly mt-0"
                  style={{ maxWidth: isNonMobile1024 ? "400px" : "" }}
                >
                  Explore <span className="pinkspan">career fields</span>
                  {!isNonMobile1024 && <br />} you are interested in.
                </p>
              </div>
              <p
                className="gray80016500"
                style={{ marginTop: "1.5rem", fontWeight: "400" }}
              >
                Learn more about your{" "}
                <span className="gray80016500" style={{ fontWeight: 700 }}>
                  chosen career
                </span>{" "}
                path or simply{" "}
                <span className="gray80016500" style={{ fontWeight: 700 }}>
                  explore
                </span>{" "}
                what else is there in the market for you. With challenges you
                now can discover interesting and fun areas which you might not
                have considered (yet).
              </p>
            </div>
            <div
              style={{
                width: isNonMobile1024 ? "59.68%" : "100%",
                maxWidth: isNonMobile1024 ? "59.68%" : "100%",
                position: "relative",
              }}
            >
              <InterestItems
                selectedItem={selectedItems}
                setSelectedItem={setSelectedItems}
              />
              <div
                className="divider"
                style={{ margin: "1.5rem 0rem 1.25rem 0rem" }}
              />
              <p className="gray95014400">
                Select min. 1 career field to continue onboarding:
              </p>
              <div
                className="d-flex  align-items-center gap-3 mt-3"
                style={{ flexWrap: isNonMobile768 ? "nowrap" : "wrap" }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <UniSelect
                    button={false}
                    setSelectedUni={setSelectedUni}
                    selectedUni={selectedUni}
                    fullwidth={true}
                  />
                </div>
                <button
                  className="continuebutton"
                  style={{
                    height: "48px",
                    minWidth: "113px",
                    fontWeight: 500,
                    width: isNonMobile500 ? "" : "100%",
                  }}
                  onClick={() => {
                    handleContinueClick();
                  }}
                >
                  Join Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
