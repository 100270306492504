import coverphoto from "../../assets/images/test.png";
const CoverPhoto = ({ user }) => {
  return (
    <img
      src={user?.coverPhoto ? user.coverPhoto : coverphoto}
      alt="coverphoto"
      style={{
        width: "100%",
        minHeight: "200px",
        height: "200px",
        maxHeight: "200px",
        borderRadius: "4px 4px 0px 0px",
        objectFit: "cover",
      }}
    />
  );
};
export default CoverPhoto;
