import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const GroupCard = ({ group, setGroups, student, requests }) => {
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  const [loading, setLoading] = useState(false);
  const handleJoinGroup = async () => {
    if (group.owner !== student?._id) {
      if (group?.members?.includes(student?._id)) {
        return toast.error("Already Joined");
      }

      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/join/group`,
          {
            groupId: group?._id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            if (group.type === "Public") {
              toast.success("Joined Successfully");
              setGroups((prev) => {
                const list = [...prev];
                const index = list.findIndex((item) => item._id === group._id);
                list[index].members.push(student?._id);
                return list;
              });
            } else {
              toast.success("Requested Successfully");
              requests.push(group?._id);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      setLoading(false);
    } else {
      toast.warning("You cannot join your own group");
    }
  };

  return (
    <div className="group-card-container" style={{ height: "100%" }}>
      <img
        src={group.profilePhoto}
        alt="profile"
        style={{ height: "40px", width: "40px", borderRadius: "50%" }}
        className="shadowa"
      />
      <div className="d-flex">
        <a
          href={`/student/group/${group.link}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <p
            className="gray95016500 hoverred"
            style={{ marginTop: "1rem", cursor: "pointer" }}
          >
            {group?.title}
          </p>
        </a>
      </div>
      {group?.about && (
        <p
          className="gray60011400"
          style={{ marginTop: "1rem", fontSize: "14px" }}
        >
          {truncateString(group?.about, 80)}
        </p>
      )}
      <div
        style={{
          width: "100%",
          margin: "1rem 0rem",
          height: "1px",
          background: "#E4E4E4",
        }}
      />
      <div
        className="space flex-wrap align-items-center"
        style={{ gap: "1rem" }}
      >
        <p
          className="gray50012500"
          style={{ fontSize: "14px", fontWeight: 400 }}
        >
          {group.members.length < 1000
            ? group.members.length + 1
            : ((group.members.length + 1) / 1000).toFixed(2) + "k"}{" "}
          Members
        </p>
        <button
          className="continuebutton pink14"
          style={{
            background: group?.members?.includes(student?._id)
              ? "#079455"
              : group.owner === student?._id
              ? "#079455"
              : "transparent",
            color: "#E31b54",
            border: group?.members?.includes(student?._id)
              ? "1px solid #079455"
              : group.owner === student?._id
              ? "1px solid #079455"
              : "1px solid #E31b54",
            color: group?.members?.includes(student?._id)
              ? "#FFF"
              : group.owner === student?._id
              ? "#FFF"
              : "",
            padding: group?.members?.includes(student?._id)
              ? "10px 20px"
              : requests.includes(group?._id)
              ? "10px 20px"
              : group.owner === student?._id
              ? "10px 20px"
              : "10px 40px",
            maxHeight: "44px",
            minHeight: "44px",
            maxWidth: "100px",
          }}
          disabled={
            loading ||
            group?.members?.includes(student?._id) ||
            requests.includes(group?._id)
          }
          onClick={() => handleJoinGroup()}
        >
          {loading
            ? "Joining..."
            : group?.members?.includes(student?._id)
            ? "Joined"
            : requests.includes(group?._id)
            ? "Requested"
            : group.owner === student?._id
            ? "Owner"
            : "Join"}
        </button>
      </div>
    </div>
  );
};
export default GroupCard;
