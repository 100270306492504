import { toast } from "react-toastify";
import Graduate from "../../assets/images/icons/Graduate.png";
const Step4 = ({ isNonMobile, setStep, intake, graduation, setGraduation }) => {
  const handleNext = () => {
    if (graduation.Month === "" || graduation.Year === "") {
      toast.error("Please enter your intake details");
    } else {
      setStep(5);
    }
  };
  const currentYear = intake.Year;

  return (
    <div className={`${isNonMobile ? "mt-4" : "mt-3"} `}>
      <div className="d-flex">
        <div
          className="center align-items-center p-2 rounded-5"
          style={{ background: "#F2F4F7" }}
        >
          <img
            src={Graduate}
            alt="Graduate"
            style={{ height: "36px", width: "36px" }}
          />
        </div>
      </div>
      <p className={`gray95024400 ${isNonMobile ? "mt-4" : "mt-3"}`}>
        Expected Year of Graduation
      </p>
      <div className="row mt-4">
        <div className="col-md-6">
          <select
            className="forminput col-md-12"
            value={graduation.Month}
            onChange={(e) =>
              setGraduation({ Month: e.target.value, Year: graduation.Year })
            }
          >
            <option value="" disabled selected className="forminput">
              Select Month
            </option>
            <option value="January" className="forminput">
              January
            </option>
            <option value="February" className="forminput">
              February
            </option>
            <option value="March" className="forminput">
              March
            </option>
            <option value="April" className="forminput">
              April
            </option>
            <option value="May" className="forminput">
              May
            </option>
            <option value="June" className="forminput">
              June
            </option>
            <option value="July" className="forminput">
              July
            </option>
            <option value="August" className="forminput">
              August
            </option>
            <option value="September" className="forminput">
              September
            </option>
            <option value="October" className="forminput">
              October
            </option>
            <option value="November" className="forminput">
              November
            </option>
            <option value="December" className="forminput">
              December
            </option>
          </select>
        </div>
        <div className="col-md-6">
          <select
            className="forminput col-md-12"
            value={graduation.Year}
            onChange={(e) =>
              setGraduation({ Month: graduation.Month, Year: e.target.value })
            }
          >
            <option value="" disabled selected className="forminput">
              Select Year
            </option>
            {Array.from({ length: 10 }, (_, index) => (
              <option
                key={index}
                className="forminput"
                value={currentYear - 1 + 2 + index}
              >
                {currentYear - 1 + 2 + index}
              </option>
            ))}
          </select>
        </div>
      </div>
      <button className="continuebutton mt-5" onClick={() => handleNext()}>
        Next
      </button>
    </div>
  );
};
export default Step4;
