import GroupCard from "./GroupCard";
import { useMediaQuery } from "@mui/material";
import EmptyState from "../../assets/images/EmptyState.png";
import TrendingGroups from "./TrendingGroups";
import { useState } from "react";
const ExploreGroups = ({ groups, setGroups, student, requests }) => {
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const [emptygroups, setEmptyGroups] = useState(false);
  return (
    <>
      {groups.length === 0 ? (
        <div className="center flex-column align-items-center">
          <img
            src={EmptyState}
            className="img-fluid"
            alt="empty state"
            style={{ maxWidth: "300px" }}
          />
          <p className="black14">No Groups </p>
        </div>
      ) : (
        <div className="d-flex " style={{ gap: "1.875rem" }}>
          <div style={{ width: "100%" }}>
            <div
              className="d-grid"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                gap: "1.5rem",
                width: "100%",
              }}
            >
              {groups.map((grp, index) => (
                <div key={index}>
                  <GroupCard
                    group={grp}
                    setGroups={setGroups}
                    student={student}
                    requests={requests}
                  />
                </div>
              ))}
            </div>
          </div>
          {isNonMobile1024 && !emptygroups && (
            <div
              className="d-flex"
              style={{
                minWidth: "300px",
                maxWidth: "300px",
              }}
            >
              <TrendingGroups setEmptyGroups={setEmptyGroups} />
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default ExploreGroups;
