import Suitcase from "../../assets/images/briefcase.png";

import pic1 from "../../assets/images/logosCOmpany/master.png";
import pic2 from "../../assets/images/logosCOmpany/freshfields.png";
import pic3 from "../../assets/images/logosCOmpany/KPMGcircular.png";
import pic4 from "../../assets/images/logosCOmpany/amazon.png";
import pic5 from "../../assets/images/logosCOmpany/bcgcircular.png";

const First = () => {
  return (
    <div className="university-first mt-0">
      <div
        className="marginsleftrightCompany overflowshown"
        style={{ position: "relative" }}
      >
        <div className="center">
          <div className="headerCompany " style={{ overflow: "visible" }}>
            <div
              className="center align-items-center"
              style={{
                minHeight: "calc(100vh - 65px)",
                overflow: "hidden",
                position: "relative",
                marginTop: "-100px",
              }}
            >
              <div className="circle-background-large">
                <div class="circle-container"></div>
                <div className="">
                  <div className="center">
                    <div className="hellocareer">
                      <img
                        src={Suitcase}
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                      Corporate Track
                    </div>
                  </div>
                  <div className="center">
                    <p
                      className="mainheadingcompany text-center"
                      style={{ maxWidth: "860px", marginTop: "1.875rem" }}
                    >
                      Gain the right skills for corporate success
                    </p>
                  </div>
                  <div className="center">
                    <p
                      className="text-center gray95020400"
                      style={{
                        maxWidth: "723px",
                        marginTop: "1.5rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      Unlock opportunities in leading corporations and
                      high-stakes fields like investment banking, big law,
                      consulting firms and other industry leaders.
                    </p>
                  </div>
                  <div className="center">
                    <div style={{ marginTop: "2rem" }}>
                      <div
                        className="center align-items-center flex-wrap"
                        style={{ gap: "0.75rem" }}
                      >
                        <div className="d-flex maxWidthClampedCompanysmalltext">
                          <img
                            src={pic1}
                            alt="company"
                            className="Companypagelogoimages shadowa rounded-5"
                            style={{
                              position: "relative",
                              zIndex: 5,
                              height: "48px",
                              width: "48px",
                            }}
                          />
                          <img
                            src={pic2}
                            alt="company"
                            className="Companypagelogoimages moveLeftCompany shadowa rounded-5"
                            style={{
                              position: "relative",
                              zIndex: 4,
                              left: "-20px",
                              height: "48px",
                              width: "48px",
                            }}
                          />
                          <img
                            src={pic3}
                            alt="company"
                            className="Companypagelogoimages shadowa rounded-5"
                            style={{
                              position: "relative",
                              zIndex: 3,
                              height: "48px",
                              width: "48px",
                              left: "-40px",
                            }}
                          />
                          <img
                            src={pic4}
                            alt="company"
                            className="Companypagelogoimages shadowa rounded-5 moveLeftCompany"
                            style={{
                              position: "relative",
                              zIndex: 2,
                              height: "48px",
                              width: "48px",
                              left: "-55px",
                            }}
                          />
                          <img
                            src={pic5}
                            alt="company"
                            className="Companypagelogoimages shadowa rounded-5 moveLeftCompany"
                            style={{
                              position: "relative",
                              zIndex: 1,
                              height: "48px",
                              width: "48px",
                              left: "-70px",
                            }}
                          />
                        </div>
                        <p className="pink16 text-center">
                          Join 200+ Industry Leaders
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default First;
