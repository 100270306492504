import { useState } from "react";
import Slider from "react-slick";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { toast } from "react-toastify";
import { Country, City } from "country-state-city";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
const Step3 = ({
  setSteps,
  locationType,
  setLocationType,
  isNonMobile,

  country,
  setCountry,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [search, setSearch] = useState([]);
  const [citySearch, setCitySearch] = useState([]);

  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  const handleSubmit = () => {
    if (locationType === "Onsite" || locationType === "Hybrid") {
      let check = -1;
      country.forEach((country, i) => {
        if (country.Country === "") {
          toast.error(`Please Add Country ${i + 1} Name`);
          check = 1;
          return;
        } else if (country.City.length < 1) {
          toast.error(
            "Please Add At least 1 Job Location in country: " +
              country.Country.name
          );
          check = 1;
          return;
        }
      });
      if (check === -1) {
        setSteps(4);
      }
    } else {
      setSteps(4);
    }
  };

  const allCountries = Country.getAllCountries();

  const handleSearchCountry = (e, index) => {
    setSearch((prevSearch) => {
      const newSearch = [...prevSearch];
      newSearch[index] = e.target.value;
      return newSearch;
    });

    const filteredCountries = allCountries.filter((country) =>
      country.name.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setFilteredLocations((prevLocations) => {
      const newLocations = [...prevLocations];
      newLocations[index] = filteredCountries;
      return newLocations;
    });
  };

  const handleRemoveCity = (i, index, name) => {
    setCountry((prev) => {
      const newCountry = [...prev];
      newCountry[index].City = newCountry[index].City.filter(
        (city) => city.name !== name
      );
      return newCountry;
    });
  };

  const handleSearchCity = (e, index) => {
    setCitySearch((prevSearch) => {
      const newSearch = [...prevSearch];
      newSearch[index] = e.target.value;

      return newSearch;
    });
    const isoCode = country[index].Country.isoCode;

    if (isoCode) {
      const filtered = City.getCitiesOfCountry(isoCode).filter((city) =>
        city.name.toLowerCase().includes(e.target.value.toLowerCase())
      );

      setFilteredCities((prevLocations) => {
        const newLocations = [...prevLocations];
        newLocations[index] = filtered;
        console.log(newLocations);
        return newLocations;
      });
    }
  };

  const handleDeleteCountry = (index) => {
    setCountry((prevCountry) => {
      const updatedCountry = prevCountry.filter((_, idx) => idx !== index);
      return updatedCountry;
    });
  };

  return (
    <div className="">
      <p className="BasicInforMationHeading">Job Location</p>
      <p className="UnderBasicHeading">
        Specifiy job location to customize candidate matches.
      </p>
      <div className=" " style={{ marginTop: "2.5rem" }}>
        <div>
          {isNonMobile ? (
            <div className=" d-flex " style={{ gap: "26px" }}>
              <div
                className={`rolecards  ${
                  locationType === "Onsite" ? "selectedrolecard" : ""
                }`}
                style={{
                  maxWidth: "318px",
                  width: "32%",
                  padding: "20px",
                }}
                onClick={() => setLocationType("Onsite")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    d="M28.034 25.8085C27.4259 25.5029 26.685 25.74 26.3673 26.3418C26.0737 26.9549 26.3164 27.6905 26.9173 28.0085C28.384 28.7585 29.2173 29.6751 29.2173 30.5585C29.2173 32.4418 25.284 34.5418 20.0173 34.5418C14.7507 34.5418 10.8007 32.4585 10.8007 30.6251C10.8007 29.7585 11.634 28.8251 13.1007 28.0751C13.5405 27.8988 13.8428 27.4889 13.8813 27.0166C13.9198 26.5443 13.688 26.0908 13.2825 25.8455C12.8771 25.6001 12.3678 25.6052 11.9673 25.8585C9.94381 26.6211 8.53311 28.4719 8.33398 30.6251C8.33398 34.3251 13.334 37.1085 20.0007 37.1085C26.6673 37.1085 31.6673 34.3251 31.6673 30.6251C31.4866 28.4532 30.0727 26.5789 28.034 25.8085Z"
                    fill={locationType === "Onsite" ? "#E31B54" : "#000000"}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.7507 31.3585V24.1585C13.2621 23.4972 9.2382 18.6756 9.56943 13.1574C9.90066 7.6391 14.4724 3.3335 20.0007 3.3335C25.5289 3.3335 30.1006 7.6391 30.4319 13.1574C30.7631 18.6756 26.7392 23.4972 21.2507 24.1585V31.3585C21.2507 32.0488 20.691 32.6085 20.0007 32.6085C19.3103 32.6085 18.7507 32.0488 18.7507 31.3585ZM27.3744 10.7354C26.137 7.75693 23.2259 5.81839 20.0007 5.82513C15.6138 5.8343 12.0598 9.38827 12.0507 13.7751C12.0439 17.0004 13.9825 19.9115 16.9609 21.1488C19.9394 22.3862 23.37 21.7057 25.6506 19.4251C27.9312 17.1445 28.6117 13.7138 27.3744 10.7354Z"
                    fill={locationType === "Onsite" ? "#E31B54" : "#000000"}
                  />
                  <path
                    d="M15.984 15.6251C16.6743 15.6251 17.234 15.0655 17.234 14.3751C17.234 12.4605 18.7861 10.9085 20.7007 10.9085C21.391 10.9085 21.9507 10.3488 21.9507 9.65847C21.9507 8.96811 21.391 8.40847 20.7007 8.40847C19.1138 8.41288 17.5936 9.0475 16.4747 10.1727C15.3557 11.2979 14.7296 12.8216 14.734 14.4085C14.7384 14.7356 14.8725 15.0475 15.107 15.2757C15.3414 15.5039 15.6569 15.6296 15.984 15.6251Z"
                    fill={locationType === "Onsite" ? "#E31B54" : "#000000"}
                  />
                </svg>
                <p
                  className={`rolecardsheading ${
                    locationType === "Onsite"
                      ? "recruiter-text"
                      : "default-text"
                  }`}
                  style={{ marginTop: "1.25rem" }}
                >
                  Onsite
                </p>
                <p
                  className={`rolecardstext mt-2 ${
                    locationType === "Onsite"
                      ? "recruiter-text"
                      : "default-text"
                  }`}
                >
                  Employee works in person from a specific location.
                </p>
              </div>
              <div
                className={`rolecards  ${
                  locationType === "Remote" ? "selectedrolecard" : ""
                }`}
                style={{
                  maxWidth: "318px",
                  width: "32%",
                  padding: "20px",
                }}
                onClick={() => setLocationType("Remote")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.6668 12.5C30.6668 14.8011 28.8013 16.6666 26.5001 16.6666C24.199 16.6666 22.3335 14.8011 22.3335 12.5V10.1666C22.3335 8.23363 23.9005 6.66663 25.8335 6.66663H27.1668C29.0998 6.66663 30.6668 8.23363 30.6668 10.1666V12.5ZM25.8335 9.16663C25.2812 9.16663 24.8335 9.61434 24.8335 10.1666V12.5C24.8335 13.4204 25.5797 14.1666 26.5001 14.1666C27.4206 14.1666 28.1668 13.4204 28.1668 12.5V10.1666C28.1668 9.61434 27.7191 9.16663 27.1668 9.16663H25.8335Z"
                    fill={locationType === "Remote" ? "#E31B54" : "#000000"}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.0001 20.7833L34.1334 21.6666C35.9221 21.7382 37.3348 23.2098 37.3334 24.9999V29.9999C37.3334 31.8409 35.841 33.3333 34.0001 33.3333H7.3334C5.49245 33.3333 4.00007 31.8409 4.00007 29.9999V24.9999C3.99283 23.8853 4.54322 22.8408 5.46674 22.2166C5.30793 21.8803 5.20653 21.5197 5.16674 21.1499L4.85007 17.9499C4.76059 17.0124 5.07187 16.0808 5.70695 15.3854C6.34203 14.69 7.24164 14.2957 8.1834 14.2999C8.59437 14.3031 9.00121 14.3822 9.3834 14.5333L11.5501 15.3666C12.5077 15.7334 13.3166 16.4074 13.8501 17.2833L14.9667 19.0833C15.4406 19.8564 15.579 20.7892 15.3501 21.6666H18.3834L18.5667 20.7833C18.9685 18.6682 20.7976 17.1243 22.9501 17.0833H29.6167C31.7692 17.1243 33.5983 18.6682 34.0001 20.7833ZM23.0334 19.5999C22.0795 19.6376 21.2778 20.3286 21.1001 21.2666L21.0167 21.7166H31.6167V21.2666C31.464 20.3211 30.6576 19.6199 29.7001 19.5999H23.0334ZM7.5834 17.0833C7.73429 16.9082 7.95232 16.8052 8.1834 16.7999C8.28204 16.7765 8.38477 16.7765 8.4834 16.7999L10.6501 17.6333C11.0982 17.801 11.4789 18.1114 11.7334 18.5166L12.8334 20.3333C12.9946 20.5816 12.9946 20.9016 12.8334 21.1499C12.6993 21.4115 12.4273 21.5734 12.1334 21.5666H8.51674C8.09924 21.5613 7.75014 21.2478 7.70007 20.8333L7.3334 17.6999C7.32542 17.4682 7.41631 17.244 7.5834 17.0833ZM34.0001 30.8333C34.4603 30.8333 34.8334 30.4602 34.8334 29.9999V24.9999C34.8334 24.5397 34.4603 24.1666 34.0001 24.1666H7.3334C6.87317 24.1666 6.50007 24.5397 6.50007 24.9999V29.9999C6.50007 30.4602 6.87317 30.8333 7.3334 30.8333H34.0001Z"
                    fill={locationType === "Remote" ? "#E31B54" : "#000000"}
                  />
                </svg>
                <p
                  className={`rolecardsheading  ${
                    locationType === "Remote"
                      ? "recruiter-text"
                      : "default-text"
                  }`}
                  style={{ marginTop: "1.25rem" }}
                >
                  Remote
                </p>
                <p
                  className={`rolecardstext mt-2 ${
                    locationType === "Remote"
                      ? "recruiter-text"
                      : "default-text"
                  }`}
                >
                  Employee works from home.
                </p>
              </div>
              <div
                className={`rolecards  ${
                  locationType === "Hybrid" ? "selectedrolecard" : ""
                }`}
                style={{
                  maxWidth: "318px",
                  width: "32%",
                  padding: "20px",
                }}
                onClick={() => setLocationType("Hybrid")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                >
                  <path
                    d="M20.3809 10.0002C12.1446 10.0002 5.6958 13.3336 5.6958 17.7169C5.6958 21.8002 11.2925 25.0336 18.7103 25.4669L16.221 27.9336C15.7324 28.4216 15.7324 29.2122 16.221 29.7002C16.7159 30.1896 17.5137 30.1896 18.0086 29.7002L22.5695 25.1502C23.0581 24.6622 23.0581 23.8716 22.5695 23.3836L18.0086 18.8336C17.51 18.3531 16.7195 18.3531 16.221 18.8336C15.7324 19.3216 15.7324 20.1122 16.221 20.6002L18.5933 22.9502C12.3952 22.5336 8.2018 20.0002 8.2018 17.7169C8.2018 15.4336 13.2138 12.4336 20.3809 12.4336C27.5481 12.4336 32.5601 15.2169 32.5601 17.7169C32.5601 19.3836 30.4383 21.0502 27.1471 22.0669C26.4828 22.2694 26.1088 22.9708 26.3118 23.6336C26.5148 24.2963 27.2179 24.6694 27.8822 24.4669C32.3763 23.0836 35.0661 20.5669 35.0661 17.8002C35.0661 13.3336 28.6173 10.0002 20.3809 10.0002Z"
                    fill={locationType === "Hybrid" ? "#E31B54" : "#000000"}
                  />
                </svg>
                <p
                  className={`rolecardsheading  ${
                    locationType === "Hybrid"
                      ? "recruiter-text"
                      : "default-text"
                  }`}
                  style={{ marginTop: "1.25rem" }}
                >
                  Hybrid
                </p>
                <p
                  className={`rolecardstext mt-2 ${
                    locationType === "Hybrid"
                      ? "recruiter-text"
                      : "default-text"
                  }`}
                >
                  Employee works a combination of onsite and remote.
                </p>
              </div>
            </div>
          ) : (
            <Slider {...settings}>
              <div className="d-flex w-full centerimportant">
                <div
                  className={`rolecards  ${
                    locationType === "Onsite" ? "selectedrolecardmobile" : ""
                  }`}
                  style={{
                    maxWidth: "390px",
                    height: "210px",
                    padding: "29px 24px",
                  }}
                  onClick={() => setLocationType("Onsite")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M28.034 25.8085C27.4259 25.5029 26.685 25.74 26.3673 26.3418C26.0737 26.9549 26.3164 27.6905 26.9173 28.0085C28.384 28.7585 29.2173 29.6751 29.2173 30.5585C29.2173 32.4418 25.284 34.5418 20.0173 34.5418C14.7507 34.5418 10.8007 32.4585 10.8007 30.6251C10.8007 29.7585 11.634 28.8251 13.1007 28.0751C13.5405 27.8988 13.8428 27.4889 13.8813 27.0166C13.9198 26.5443 13.688 26.0908 13.2825 25.8455C12.8771 25.6001 12.3678 25.6052 11.9673 25.8585C9.94381 26.6211 8.53311 28.4719 8.33398 30.6251C8.33398 34.3251 13.334 37.1085 20.0007 37.1085C26.6673 37.1085 31.6673 34.3251 31.6673 30.6251C31.4866 28.4532 30.0727 26.5789 28.034 25.8085Z"
                      fill={locationType === "Onsite" ? "#E31B54" : "#000000"}
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.7507 31.3585V24.1585C13.2621 23.4972 9.2382 18.6756 9.56943 13.1574C9.90066 7.6391 14.4724 3.3335 20.0007 3.3335C25.5289 3.3335 30.1006 7.6391 30.4319 13.1574C30.7631 18.6756 26.7392 23.4972 21.2507 24.1585V31.3585C21.2507 32.0488 20.691 32.6085 20.0007 32.6085C19.3103 32.6085 18.7507 32.0488 18.7507 31.3585ZM27.3744 10.7354C26.137 7.75693 23.2259 5.81839 20.0007 5.82513C15.6138 5.8343 12.0598 9.38827 12.0507 13.7751C12.0439 17.0004 13.9825 19.9115 16.9609 21.1488C19.9394 22.3862 23.37 21.7057 25.6506 19.4251C27.9312 17.1445 28.6117 13.7138 27.3744 10.7354Z"
                      fill={locationType === "Onsite" ? "#E31B54" : "#000000"}
                    />
                    <path
                      d="M15.984 15.6251C16.6743 15.6251 17.234 15.0655 17.234 14.3751C17.234 12.4605 18.7861 10.9085 20.7007 10.9085C21.391 10.9085 21.9507 10.3488 21.9507 9.65847C21.9507 8.96811 21.391 8.40847 20.7007 8.40847C19.1138 8.41288 17.5936 9.0475 16.4747 10.1727C15.3557 11.2979 14.7296 12.8216 14.734 14.4085C14.7384 14.7356 14.8725 15.0475 15.107 15.2757C15.3414 15.5039 15.6569 15.6296 15.984 15.6251Z"
                      fill={locationType === "Onsite" ? "#E31B54" : "#000000"}
                    />
                  </svg>
                  <p
                    className={`rolecardsheading mt-2 ${
                      locationType === "Onsite"
                        ? "recruiter-text"
                        : "default-text"
                    }`}
                  >
                    Onsite
                  </p>
                  <p
                    className={`rolecardstext mt-2 ${
                      locationType === "Onsite"
                        ? "recruiter-text"
                        : "default-text"
                    }`}
                  >
                    Employee works in person from a specific location.
                  </p>
                </div>
              </div>
              <div className="d-flex w-full centerimportant">
                <div
                  className={`rolecards  ${
                    locationType === "Remote" ? "selectedrolecardmobile" : ""
                  }`}
                  style={{
                    maxWidth: "390px",
                    height: "210px",
                    padding: "29px 24px",
                  }}
                  onClick={() => setLocationType("Remote")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M30.6668 12.5C30.6668 14.8011 28.8013 16.6666 26.5001 16.6666C24.199 16.6666 22.3335 14.8011 22.3335 12.5V10.1666C22.3335 8.23363 23.9005 6.66663 25.8335 6.66663H27.1668C29.0998 6.66663 30.6668 8.23363 30.6668 10.1666V12.5ZM25.8335 9.16663C25.2812 9.16663 24.8335 9.61434 24.8335 10.1666V12.5C24.8335 13.4204 25.5797 14.1666 26.5001 14.1666C27.4206 14.1666 28.1668 13.4204 28.1668 12.5V10.1666C28.1668 9.61434 27.7191 9.16663 27.1668 9.16663H25.8335Z"
                      fill={locationType === "Remote" ? "#E31B54" : "#000000"}
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M34.0001 20.7833L34.1334 21.6666C35.9221 21.7382 37.3348 23.2098 37.3334 24.9999V29.9999C37.3334 31.8409 35.841 33.3333 34.0001 33.3333H7.3334C5.49245 33.3333 4.00007 31.8409 4.00007 29.9999V24.9999C3.99283 23.8853 4.54322 22.8408 5.46674 22.2166C5.30793 21.8803 5.20653 21.5197 5.16674 21.1499L4.85007 17.9499C4.76059 17.0124 5.07187 16.0808 5.70695 15.3854C6.34203 14.69 7.24164 14.2957 8.1834 14.2999C8.59437 14.3031 9.00121 14.3822 9.3834 14.5333L11.5501 15.3666C12.5077 15.7334 13.3166 16.4074 13.8501 17.2833L14.9667 19.0833C15.4406 19.8564 15.579 20.7892 15.3501 21.6666H18.3834L18.5667 20.7833C18.9685 18.6682 20.7976 17.1243 22.9501 17.0833H29.6167C31.7692 17.1243 33.5983 18.6682 34.0001 20.7833ZM23.0334 19.5999C22.0795 19.6376 21.2778 20.3286 21.1001 21.2666L21.0167 21.7166H31.6167V21.2666C31.464 20.3211 30.6576 19.6199 29.7001 19.5999H23.0334ZM7.5834 17.0833C7.73429 16.9082 7.95232 16.8052 8.1834 16.7999C8.28204 16.7765 8.38477 16.7765 8.4834 16.7999L10.6501 17.6333C11.0982 17.801 11.4789 18.1114 11.7334 18.5166L12.8334 20.3333C12.9946 20.5816 12.9946 20.9016 12.8334 21.1499C12.6993 21.4115 12.4273 21.5734 12.1334 21.5666H8.51674C8.09924 21.5613 7.75014 21.2478 7.70007 20.8333L7.3334 17.6999C7.32542 17.4682 7.41631 17.244 7.5834 17.0833ZM34.0001 30.8333C34.4603 30.8333 34.8334 30.4602 34.8334 29.9999V24.9999C34.8334 24.5397 34.4603 24.1666 34.0001 24.1666H7.3334C6.87317 24.1666 6.50007 24.5397 6.50007 24.9999V29.9999C6.50007 30.4602 6.87317 30.8333 7.3334 30.8333H34.0001Z"
                      fill={locationType === "Remote" ? "#E31B54" : "#000000"}
                    />
                  </svg>
                  <p
                    className={`rolecardsheading mt-2 ${
                      locationType === "Remote"
                        ? "recruiter-text"
                        : "default-text"
                    }`}
                  >
                    Remote
                  </p>
                  <p
                    className={`rolecardstext mt-2 ${
                      locationType === "Remote"
                        ? "recruiter-text"
                        : "default-text"
                    }`}
                  >
                    Employee works from home.
                  </p>
                </div>
              </div>

              <div className="d-flex w-full centerimportant">
                <div
                  className={`rolecards   ${
                    locationType === "Hybrid" ? "selectedrolecardmobile" : ""
                  }`}
                  style={{
                    maxWidth: "390px",
                    height: "210px",

                    padding: "29px 24px",
                  }}
                  onClick={() => setLocationType("Hybrid")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                  >
                    <path
                      d="M20.3809 10.0002C12.1446 10.0002 5.6958 13.3336 5.6958 17.7169C5.6958 21.8002 11.2925 25.0336 18.7103 25.4669L16.221 27.9336C15.7324 28.4216 15.7324 29.2122 16.221 29.7002C16.7159 30.1896 17.5137 30.1896 18.0086 29.7002L22.5695 25.1502C23.0581 24.6622 23.0581 23.8716 22.5695 23.3836L18.0086 18.8336C17.51 18.3531 16.7195 18.3531 16.221 18.8336C15.7324 19.3216 15.7324 20.1122 16.221 20.6002L18.5933 22.9502C12.3952 22.5336 8.2018 20.0002 8.2018 17.7169C8.2018 15.4336 13.2138 12.4336 20.3809 12.4336C27.5481 12.4336 32.5601 15.2169 32.5601 17.7169C32.5601 19.3836 30.4383 21.0502 27.1471 22.0669C26.4828 22.2694 26.1088 22.9708 26.3118 23.6336C26.5148 24.2963 27.2179 24.6694 27.8822 24.4669C32.3763 23.0836 35.0661 20.5669 35.0661 17.8002C35.0661 13.3336 28.6173 10.0002 20.3809 10.0002Z"
                      fill={locationType === "Hybrid" ? "#E31B54" : "#000000"}
                    />
                  </svg>
                  <p
                    className={`rolecardsheading mt-2 ${
                      locationType === "Hybrid"
                        ? "recruiter-text"
                        : "default-text"
                    }`}
                  >
                    Hybrid
                  </p>
                  <p
                    className={`rolecardstext mt-2 ${
                      locationType === "Hybrid"
                        ? "recruiter-text"
                        : "default-text"
                    }`}
                  >
                    Employee works a combination of onsite and remote.
                  </p>
                </div>
              </div>
            </Slider>
          )}
        </div>
      </div>
      <div className=" " style={{ marginTop: "2.5rem" }}>
        {(locationType === "Onsite" || locationType === "Hybrid") && (
          <>
            <p className="thumbnailcreate">Onsite Location</p>
            <p
              className="underthumbnailcreate"
              style={{ marginTop: "0.25rem" }}
            >
              Here you can add office Country.
            </p>
            {country &&
              country.length > 0 &&
              country.map((count, index) => (
                <div key={index}>
                  {country.length > 1 && (
                    <div className="space" style={{ marginTop: "0.75rem" }}>
                      <p className="thumbnailcreate">Country {index + 1}</p>
                      {index > 0 && (
                        <p
                          className="pink16 hoverdarkred"
                          style={{ fontSize: "14px", cursor: "pointer" }}
                          onClick={() => handleDeleteCountry(index)}
                        >
                          Delete <ClearIcon />
                        </p>
                      )}
                    </div>
                  )}
                  <div style={{ position: "relative" }} key={index}>
                    <input
                      type="search"
                      placeholder="Search country"
                      className="forminput"
                      style={{
                        marginTop: "0.75rem",
                        width: "100%",
                        display: "block",
                        paddingLeft: "2rem", // Adjust padding to accommodate the icon
                      }}
                      value={search[index] || ""}
                      onChange={(e) => handleSearchCountry(e, index)}
                    />

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      style={{
                        position: "absolute",
                        left: "0.5rem", // Adjust icon position
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <path
                        d="M17.4999 17.4999L14.5833 14.5832M16.6665 9.58321C16.6665 13.4952 13.4952 16.6665 9.58321 16.6665C5.67119 16.6665 2.49988 13.4952 2.49988 9.58321C2.49988 5.67119 5.67119 2.49988 9.58321 2.49988C13.4952 2.49988 16.6665 5.67119 16.6665 9.58321Z"
                        stroke="#667085"
                        strokeWidth="1.36482"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  {filteredLocations[index] &&
                    filteredLocations[index].length > 0 &&
                    search[index] && (
                      <div
                        className="shadowa tablescroll d-flex flex-column rounded-3"
                        style={{
                          marginTop: "0.75rem",
                          position: "absolute",
                          zIndex: 3,
                          minWidth: "300px",
                          maxHeight: "15rem",
                          background: "#FFF",
                          padding: "1.5rem",
                          overflow: "auto",
                          gap: "0.5rem",
                        }}
                      >
                        {filteredLocations[index].map((contri, i) => (
                          <div
                            className="d-flex"
                            key={i}
                            style={{ gap: "1rem", cursor: "pointer" }}
                            onClick={() => {
                              setCountry((prevCountry) => {
                                const updated = [...prevCountry];
                                let text = -1;
                                updated.map((con) => {
                                  if (con.Country.name === contri.name) {
                                    text = 1;
                                  }
                                  return null; // Added to avoid warning about unused return value
                                });
                                if (text === -1) {
                                  updated[index].Country = contri;
                                  updated[index].City = [];
                                } else {
                                  toast.error("Country already Selected");
                                }
                                return updated;
                              });
                              setSearch((prevSearch) => {
                                const updated = [...prevSearch];
                                updated[index] = "";
                                return updated;
                              });
                            }}
                          >
                            {contri.flag}
                            <p className="black14">{contri.name}</p>
                          </div>
                        ))}
                      </div>
                    )}

                  {count.Country?.name && (
                    <>
                      {" "}
                      <div className="d-flex">
                        <div
                          className="d-flex p-2 shadowa rounded-5"
                          style={{
                            gap: "1rem",
                            background: "#FFF",
                            marginTop: "0.75rem",
                          }}
                        >
                          {count.Country.flag}
                          <p className="black14">{count.Country.name}</p>
                        </div>{" "}
                      </div>
                      <div style={{ position: "relative" }}>
                        <input
                          type="search"
                          placeholder="Search City"
                          className="forminput"
                          style={{
                            marginTop: "0.75rem",
                            width: "100%",
                            display: "block",
                            paddingLeft: "2rem", // Adjust padding to accommodate the icon
                          }}
                          value={citySearch[index] || ""}
                          onChange={(e) => handleSearchCity(e, index)}
                        />

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          style={{
                            position: "absolute",
                            left: "0.5rem", // Adjust icon position
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <path
                            d="M17.4999 17.4999L14.5833 14.5832M16.6665 9.58321C16.6665 13.4952 13.4952 16.6665 9.58321 16.6665C5.67119 16.6665 2.49988 13.4952 2.49988 9.58321C2.49988 5.67119 5.67119 2.49988 9.58321 2.49988C13.4952 2.49988 16.6665 5.67119 16.6665 9.58321Z"
                            stroke="#667085"
                            strokeWidth="1.36482"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      {filteredCities[index] &&
                        filteredCities[index].length > 0 &&
                        citySearch[index] && (
                          <div
                            className="shadowa tablescroll d-flex flex-column rounded-3"
                            style={{
                              marginTop: "0.75rem",
                              position: "absolute",
                              zIndex: 3,
                              minWidth: "300px",
                              maxHeight: "15rem",
                              background: "#FFF",
                              padding: "1.5rem",
                              overflow: "auto",
                              gap: "0.5rem",
                            }}
                          >
                            {filteredCities[index].map((contri, i) => (
                              <div
                                className="d-flex"
                                key={i}
                                style={{ gap: "1rem", cursor: "pointer" }}
                                onClick={() => {
                                  setCountry((prevCountry) => {
                                    const updated = [...prevCountry];
                                    const cityExists = updated[index].City.some(
                                      (cit) => cit.name === contri.name
                                    );
                                    if (!cityExists) {
                                      updated[index].City.push(contri);
                                    }
                                    return updated;
                                  });

                                  setCitySearch((prevSearch) => {
                                    const updated = [...prevSearch];
                                    updated[index] = "";
                                    return updated;
                                  });
                                }}
                              >
                                {contri.flag}
                                <p className="black14">{contri.name}</p>
                              </div>
                            ))}
                          </div>
                        )}
                      {count.City.length > 0 && (
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "6px 1rem" }}
                        >
                          {count.City.map((city, i) => (
                            <div
                              className="d-flex p-2 shadowa rounded-5"
                              key={i}
                              style={{
                                gap: "1rem",
                                background: "#FFF",
                                marginTop: "0.75rem",
                              }}
                            >
                              <p className="black14">{city.name}</p>
                              <ClearIcon
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleRemoveCity(i, index, city.name)
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}

            <div className="d-flex">
              <p
                className="pink16 d-flex hoverdarkred"
                style={{ marginTop: "2.5rem", gap: "1rem", cursor: "pointer" }}
                onClick={() => {
                  setCountry((prev) => {
                    const updated = [...prev, { Country: "", City: [] }];
                    return updated;
                  });
                }}
              >
                <AddIcon />
                Add another country
              </p>
            </div>
          </>
        )}
      </div>
      <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
      <div
        className="d-flex flex-wrap mb-5"
        style={{ justifyContent: "space-between", gap: "1.25rem" }}
      >
        <button className="backbutton" onClick={() => setSteps(2)}>
          Back
        </button>
        <button
          className="continuebutton"
          onClick={() => {
            handleSubmit();
          }}
        >
          Continue <ChevronRightIcon />
        </button>
      </div>
    </div>
  );
};

export default Step3;
