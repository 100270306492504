const LockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
    >
      <path
        d="M8.21757 10.5851C8.21757 9.8211 7.59823 9.20176 6.83424 9.20176C6.07024 9.20176 5.4509 9.8211 5.4509 10.5851C5.44976 10.909 5.56511 11.2225 5.7759 11.4684C5.9883 11.6898 6.06193 12.0099 5.96757 12.3018L5.79257 12.8184C5.72489 13.0508 5.76963 13.3014 5.91352 13.496C6.05741 13.6905 6.28393 13.8067 6.5259 13.8101H7.14257C7.39256 13.81 7.62717 13.6894 7.77263 13.486C7.91809 13.2827 7.95655 13.0217 7.8759 12.7851L7.66757 12.2684C7.57321 11.9765 7.64684 11.6564 7.85924 11.4351C8.07478 11.2026 8.20163 10.9017 8.21757 10.5851Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7926 5.76843V3.16843C10.793 2.4892 10.5171 1.83902 10.0282 1.36745C9.53937 0.895882 8.87968 0.643526 8.2009 0.668431H5.46757C4.78879 0.643526 4.1291 0.895882 3.64024 1.36745C3.15139 1.83902 2.87545 2.4892 2.8759 3.16843V5.76843C1.14967 6.37621 0.0998356 8.12593 0.375904 9.9351L1.16757 14.7434C1.55804 16.6588 3.2633 18.0202 5.21757 17.9768H8.4509C10.4191 18.0262 12.1366 16.6501 12.5176 14.7184L13.3092 9.9101C13.5703 8.10502 12.515 6.36824 10.7926 5.76843ZM4.1259 3.16843C4.15745 2.45812 4.75712 1.90687 5.46757 1.9351H8.2009C8.91135 1.90687 9.51102 2.45812 9.54257 3.16843V5.47676H4.1259V3.16843ZM11.0009 14.4684L11.8342 9.64343C11.9169 9.01922 11.7185 8.39047 11.2926 7.92676C10.7746 7.33931 10.0257 7.00752 9.24257 7.01843H4.4259C3.6454 7.0033 2.897 7.32883 2.3759 7.9101C1.96009 8.38495 1.77688 9.02007 1.8759 9.64343L2.66757 14.4684C2.92595 15.657 4.00232 16.4872 5.21757 16.4351H8.4509C9.66616 16.4872 10.7425 15.657 11.0009 14.4684Z"
        fill="#344054"
      />
    </svg>
  );
};
export default LockIcon;
