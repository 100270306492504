const Section2Component = ({ slides, maxHeight, noimages = false }) => {
  return (
    <div
      className="d-flex flex-column "
      style={{ position: "relative", overflow: "hidden", gap: "1.5rem" }}
    >
      {slides.map((slide, index) => (
        <div
          key={index}
          style={{
            width: "100%",
            background: noimages ? "#FFF" : "",
            padding: noimages ? "1.25rem" : "",
            borderRadius: noimages ? "10px" : "",
          }}
        >
          {!noimages ? (
            <div style={{ borderRadius: "10px", overflow: "hidden" }}>
              <img
                src={slide.img}
                alt="slide"
                className="img-fluid"
                style={{
                  objectFit: "cover",
                  minHeight: "304px",
                  maxHeight: maxHeight ? maxHeight : "",
                  width: "100%",
                }}
              />
            </div>
          ) : (
            <div className="d-flex" style={{ marginBottom: "3.5rem" }}>
              {slide?.icon && (
                <div
                  className="center align-items-center"
                  style={{
                    background: "#F2F4F7",
                    borderRadius: "50%",
                    padding: "10px",
                  }}
                >
                  <img
                    src={slide.icon}
                    alt={`${index}-slide-icon`}
                    style={{ height: "20px", width: "20px" }}
                  />
                </div>
              )}
            </div>
          )}
          <div style={{ marginTop: "1rem" }}>
            <h4 className="newhomesmallheading mt-0">{slide.title}</h4>
            <p
              style={{ marginTop: noimages ? "1rem" : "0.5rem" }}
              className="newhomesmalltext"
            >
              {slide.desc}
            </p>
            {slide?.button && (
              <a
                className="pink16"
                href={slide.button.link}
                style={{
                  textDecoration: "none",
                  marginTop: "0.75rem",
                }}
              >
                {" "}
                {slide.button.name}
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
export default Section2Component;
