import { useMediaQuery } from "@mui/material";
import img1 from "../../../assets/images/challenge1.png";
import img2 from "../../../assets/images/challenge2.png";
import img3 from "../../../assets/images/challenge3.png";
import img4 from "../../../assets/images/challenge4.png";
import logo from "../../../assets/images/logo.png";
import linkedin from "../../../assets/images/icons/linkedin.png";
import ChallengeType from "../../MainDashBoard/type";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const LearnMoreChallenge = ({ setPopup, popup }) => {
  const isNon850 = useMediaQuery("(min-width:850px)");

  const slides = [
    {
      img: img1,
      type: "Fast-Track",
      description:
        "Gain insights in an evaluated and time- constrained challenge and gain a competitive edge for your next career step.",
    },
    {
      img: img2,
      type: "Virtual Experience",
      description:
        "Gain insights in an evaluated and time- constrained challenge and gain a competitive edge for your next career step.",
    },
    {
      img: img3,
      type: "Insights",
      description:
        "Gain insights about the daily tasks of career starters at the firm and  enhance your career readiness",
    },
    {
      img: img4,
      type: "Earn a Certificate",
      description:
        "Share your certificate on your varyance and Linkedin profiles and add it to your CV to showcase your skills to potential employers.",
    },
  ];
  const [index, setIndex] = useState(0);

  const handleContinue = () => {
    if (index !== slides.length - 1) {
      setIndex(index + 1);
    }
  };

  const handleBack = () => {
    if (index !== 0) {
      setIndex(index - 1);
    }
  };

  return (
    <div>
      {popup && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.6)", // Darkened background a bit for better visibility
            zIndex: 1012, // Ensure it stays on top of all other content
          }}
        >
          <div
            style={{
              width: "600px",
              maxWidth: isNon850 ? "80vw" : "90vw",
              maxHeight: "90vh",
              overflowY: "auto",
              borderRadius: "5px",
              background: "#FFF",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                maxHeight: "200px",
                overflow: "hidden",
              }}
            >
              <img
                src={slides[index].img}
                alt="first img"
                style={{
                  width: "100%",
                  maxHeight: "200px",
                  minHeight: "200px",
                  objectFit: "cover",
                  objectPosition: "top",
                  position: "relative",
                }}
              />
              <div
                className="center align-items-center"
                style={{
                  position: "absolute",
                  top: "20px",

                  right: "20px",
                  background: "rgba(12,17,29,0.6)",
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                  zIndex: 9999,
                }}
              >
                <CloseIcon
                  className="threedot"
                  style={{ cursor: "pointer", color: "#FFF" }}
                  onClick={() => setPopup(false)}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 1) 100%)",
                }}
              />
            </div>
            <div style={{ padding: "0.5rem 1.5rem 1.5rem 1.5rem" }}>
              <div className="center mt-2">
                {index <= 2 ? (
                  <ChallengeType type={slides[index].type} notest={true} />
                ) : (
                  <div className="d-flex gap-3">
                    <img
                      className="rounded-1 shadowa"
                      src={logo}
                      alt="sd"
                      height="40px"
                      width="40px"
                    />
                    <img
                      className="rounded-1 shadowa"
                      src={linkedin}
                      alt="sd"
                      height="40px"
                      width="40px"
                    />
                  </div>
                )}
              </div>
              <p
                className="text-center gray95016400"
                style={{ marginTop: "0.75rem" }}
              >
                {slides[index].type}
              </p>
              <div className="center mt-1">
                <p
                  className="gray70014400 text-center"
                  style={{ maxWidth: "360px" }}
                >
                  {slides[index].description}
                </p>
              </div>
              <div className="center" style={{ marginTop: "1.25rem" }}>
                <div className="d-flex flex-wrap" style={{ gap: "1.5rem" }}>
                  {index > 0 && (
                    <button
                      className="backbutton"
                      style={{ width: "122px" }}
                      onClick={() => handleBack()}
                    >
                      <ArrowBackIosNewIcon
                        style={{
                          height: "20px",
                          width: "20px",
                          color: "#475467",
                        }}
                      />
                      Previous
                    </button>
                  )}
                  {index !== slides.length - 1 && (
                    <button
                      className="continuebutton"
                      style={{ width: "130px" }}
                      onClick={() => {
                        handleContinue();
                      }}
                    >
                      Continue
                      <ArrowForwardIosIcon
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
              <div className="center" style={{ marginTop: "1.25rem" }}>
                <div className="d-flex gap-2">
                  {slides.map((sli, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        setIndex(i);
                      }}
                      style={{
                        height: "6px",
                        width: i === index ? "20px" : "6px",
                        background: i === index ? "#E31b54" : "#D0D5DD",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LearnMoreChallenge;
