import { useEffect, useRef, useState } from "react";
import logo from "../../assets/images/logo.png";
import Popup from "../StudentDashboard/Challenges/popup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CompanyImage from "../../components/Company/CompanyImage";

const NewStudentHeader = ({
  student,
  setStudent,
  setSelected,
  selected,
  navigate,
  isNon,
  buttons,
}) => {
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (student && !student.levelofstudy) {
      setPopup(true);
    }
  }, [student]);

  const [dropdown, setDropDown] = useState(false);

  const handleLogout = () => {
    // Remove token from local storage
    localStorage.removeItem("token");

    // Optionally, you can redirect the user or update state here
    window.location.href = "/login"; // Redirect to login page
  };

  const popupref = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setDropDown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div style={{ position: "relative", zIndex: 1002 }}>
        {popup && (
          <Popup user={student} setPopup={setPopup} setStudent={setStudent} />
        )}
      </div>
      <div className="LatestStudentHeader shadowa">
        <div
          className="marginsleftrightCompany"
          style={{ padding: "10px 0px" }}
        >
          <div className="center">
            <div className="headerCompany space align-items-center">
              <img
                src={logo}
                alt="logo"
                className="shadowa "
                height={"36px"}
                width={"36px"}
                style={{
                  borderRadius: "4px",
                  background: "transparent",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              />
              {isNon && (
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "1rem" }}
                >
                  {buttons.map((btn, index) => (
                    <button
                      key={index}
                      className="student-header-button"
                      style={{ color: selected === btn.name ? "#E31b54" : "" }}
                      onClick={() => {
                        setSelected(btn.name);
                        if (btn.name === "Feed") {
                          navigate("/student");
                        } else {
                          navigate(`/student/${btn.name}`);
                        }
                      }}
                    >
                      {btn.icon}
                      {btn.name}
                    </button>
                  ))}
                </div>
              )}
              <div
                className="d-flex align-items-center"
                style={{ gap: "0.75rem" }}
              >
                <button className="student-header-button-support">
                  <InfoOutlinedIcon style={{ height: "20px", width: "20px" }} />
                  Support
                </button>
                <div style={{ position: "relative" }}>
                  {student?.profilePhoto && (
                    <img
                      src={student.profilePhoto}
                      alt="profilePhoto"
                      className="student-profile-pic shadowa"
                      style={{
                        height: "36px",
                        width: "36px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDropDown(!dropdown);
                      }}
                    />
                  )}
                  {dropdown && (
                    <div
                      className="shadowa"
                      style={{
                        position: "absolute",
                        right: 0,
                        background: "#FFF",
                        padding: "1rem",
                        minWidth: "314px",
                        borderRadius: "5px",
                      }}
                      ref={popupref}
                    >
                      <div className="d-flex gap-3">
                        {student?.profilePhoto && (
                          <img
                            src={student.profilePhoto}
                            alt="profilePhoto"
                            className="student-profile-pic shadowa"
                            style={{
                              height: "48px",
                              width: "48px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setDropDown(true);
                            }}
                          />
                        )}
                        {student && (
                          <div>
                            <p
                              className="gray90014400"
                              style={{ fontWeight: 500 }}
                            >
                              {student?.firstName} {student?.LastName}
                            </p>
                            <div className="d-flex">
                              <a
                                href={`/student/user/${student?.link}`}
                                style={{ textDecoration: "none" }}
                              >
                                <p
                                  className="pink12 mt-2 hoverdarkred"
                                  style={{ fontWeight: 500 }}
                                >
                                  Visit Profile
                                </p>
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          background: "#EAECF0",
                          height: "1px",
                          width: "100%",
                          margin: "1rem 0rem 0.5rem 0rem",
                        }}
                      />
                      <p className="gray50012500">Account</p>
                      <div
                        className="mt-2 d-flex flex-column"
                        style={{ gap: "0.75rem" }}
                      >
                        <a
                          href="/student/settings"
                          className="gray90014400 hoverred"
                          style={{ fontWeight: 500, textDecoration: "none" }}
                        >
                          Edit Profile
                        </a>
                        <a
                          href="/student/settings"
                          className="gray90014400 hoverred"
                          style={{ fontWeight: 500, textDecoration: "none" }}
                        >
                          Settings & Privacy
                        </a>
                        <a
                          className="gray90014400 hoverred"
                          style={{ fontWeight: 500, textDecoration: "none" }}
                        >
                          Support
                        </a>
                      </div>
                      <div
                        style={{
                          background: "#EAECF0",
                          height: "1px",
                          width: "100%",
                          margin: "1rem 0rem",
                        }}
                      />
                      <div className="d-flex gap-3">
                        <CompanyImage
                          src={student?.uni?.logo}
                          height={"36px"}
                          width={"36px"}
                        />
                        <div>
                          <p
                            className="gray90014400"
                            style={{ fontWeight: 500 }}
                          >
                            {student?.uni?.name}
                          </p>
                          <p
                            className="pink12 mt-2 hoverdarkred"
                            style={{ fontWeight: 500 }}
                          >
                            Visit Campus
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          background: "#EAECF0",
                          height: "1px",
                          width: "100%",
                          margin: "1rem 0rem",
                        }}
                      />
                      <p className="gray50012500">Social</p>
                      <div
                        className="d-flex flex-column mt-2 "
                        style={{ gap: "0.75rem" }}
                      >
                        <a
                          className="gray90014400 hoverred"
                          style={{ fontWeight: 500, textDecoration: "none" }}
                        >
                          Create Invite Page
                        </a>{" "}
                        <a
                          className="gray90014400 hoverred"
                          style={{ fontWeight: 500, textDecoration: "none" }}
                        >
                          Share varyance.io
                        </a>
                      </div>

                      <div
                        style={{
                          background: "#EAECF0",
                          height: "1px",
                          width: "100%",
                          margin: "1rem 0rem",
                        }}
                      />

                      <button
                        className="gray90014400 hoverred p-0"
                        style={{
                          fontWeight: 500,
                          textDecoration: "none",
                          background: "none",
                          border: "none",
                          outline: "none",
                        }}
                        onClick={() => {
                          handleLogout();
                        }}
                      >
                        Signout
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewStudentHeader;
