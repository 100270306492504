import { useState, useEffect, useRef } from "react";
import activity from "../../assets/images/Activity.svg";
import user from "../../assets/images/Uservip.svg";
import eye from "../../assets/images/Eye.svg";
import "./analytics.css";

import Chart from "chart.js/auto";

const Analytics = ({
  challenges,
  jobposts,
  analytics,
  isNonMob1441,

  isNonMob1024,
  setEngaged,
  engaged,
  setComplete,
  complete,
  setTouch,
  touch,
}) => {
  const [chartData, setChartData] = useState({});
  const [previousEngaged, setPreviousEngaged] = useState(0);
  const [previousComplete, setPreviousComplete] = useState(0);
  const [previousTouch, setPreviousTouch] = useState(0);

  const chartRef = useRef(null);

  const processDateData = (dates, currentDay) => {
    const currentMonth = new Date().getMonth() + 1;
    const data = Array.from({ length: currentDay }, (_, index) => {
      const day = index + 1;

      const count = dates.filter(
        (date) =>
          new Date(date.date).getDate() === day &&
          new Date(date.date).getMonth() + 1 === currentMonth
      ).length;
      return count;
    });

    return data;
  };

  useEffect(() => {
    if (challenges.length > 0 || jobposts.length > 0) {
      let totalClicks = 0;
      let totalImpressions = 0;

      let totalCount = 0;

      const today = new Date();
      const currentDay = today.getDate();
      const check = [...challenges, ...jobposts] || [];
      const impressionDates = check.reduce((allDates, challenge) => {
        return [...allDates, ...challenge.impressionDates];
      }, []);
      const clickDates = check.reduce((allDates, challenge) => {
        return [...allDates, ...challenge.clickDates];
      }, []);
      const data = processDateData(
        [...impressionDates, ...clickDates],
        currentDay
      );

      setChartData({
        labels: Array.from(
          { length: data.length },
          (_, index) => `${index + 1}`
        ),
        datasets: [
          {
            label: false,
            fill: true,
            lineTension: 0.1,
            backgroundColor: "transparent",
            borderColor: "#E31B54",
            borderWidth: 2,
            data: data,
          },
        ],
      });

      const endDate = new Date();
      // Calculate the start date for the analytics period
      const startDate = new Date();

      startDate.setDate(
        startDate.getDate() -
          (analytics === "7 days" ? 7 : analytics === "30 days" ? 30 : 365)
      );

      challenges &&
        challenges.length > 0 &&
        challenges.forEach((challenge) => {
          // Filter clickDates based on analytics period
          const filteredClicks = challenge.clickDates.filter(
            (date) => new Date(date.date) >= startDate
          );
          // Calculate total clicks from filtered clicks
          totalClicks += filteredClicks.length;

          // Filter impressionDates based on analytics period
          const filteredImpressions = challenge.impressionDates.filter(
            (date) => new Date(date.date) >= startDate
          );

          const filteredCount = challenge.countDates.filter(
            (date) => new Date(date.date) >= startDate
          );
          totalCount += filteredCount.length;
          // Calculate total impressions from filtered impressions
          totalImpressions += filteredImpressions.length;
        });

      jobposts &&
        jobposts.length > 0 &&
        jobposts.forEach((jobpost) => {
          // Filter clickDates based on analytics period
          const filteredClicks = jobpost.clickDates.filter(
            (date) => new Date(date.date) >= startDate
          );
          // Calculate total clicks from filtered clicks
          totalClicks += filteredClicks.length;

          // Filter impressionDates based on analytics period
          const filteredImpressions = jobpost.impressionDates.filter(
            (date) => new Date(date.date) >= startDate
          );
          // Calculate total impressions from filtered impressions
          totalImpressions += filteredImpressions.length;
        });

      const previousStartDate = new Date(startDate);
      const previousEndDate = new Date(endDate);
      previousStartDate.setDate(
        previousStartDate.getDate() -
          (analytics === "7 days" ? 14 : analytics === "30 days" ? 60 : 365)
      );
      previousEndDate.setDate(
        previousEndDate.getDate() -
          (analytics === "7 days" ? 7 : analytics === "30 days" ? 30 : 365)
      );

      let previousTotalClicks = 0;
      let previousTotalImpressions = 0;
      let previousTotalCount = 0;

      challenges &&
        challenges.length > 0 &&
        challenges.forEach((challenge) => {
          // Filter clickDates based on previous analytics period
          const filteredClicks = challenge.clickDates.filter(
            (date) =>
              new Date(date.date) >= previousStartDate &&
              new Date(date.date) <= previousEndDate
          );
          // Calculate total clicks from filtered clicks
          previousTotalClicks += filteredClicks.length;

          // Filter impressionDates based on previous analytics period
          const filteredImpressions = challenge.impressionDates.filter(
            (date) =>
              new Date(date.date) >= previousStartDate &&
              new Date(date.date) <= previousEndDate
          );

          const filteredCount = challenge.countDates.filter(
            (date) =>
              new Date(date.date) >= previousStartDate &&
              new Date(date.date) <= previousEndDate
          );
          previousTotalCount += filteredCount.length;
          // Calculate total impressions from filtered impressions
          previousTotalImpressions += filteredImpressions.length;
        });

      jobposts &&
        jobposts.length > 0 &&
        jobposts.forEach((jobpost) => {
          // Filter clickDates based on previous analytics period
          const filteredClicks = jobpost.clickDates.filter(
            (date) =>
              new Date(date.date) >= previousStartDate &&
              new Date(date.date) <= previousEndDate
          );
          // Calculate total clicks from filtered clicks
          previousTotalClicks += filteredClicks.length;

          // Filter impressionDates based on previous analytics period
          const filteredImpressions = jobpost.impressionDates.filter(
            (date) =>
              new Date(date.date) >= previousStartDate &&
              new Date(date.date) <= previousEndDate
          );
          // Calculate total impressions from filtered impressions
          previousTotalImpressions += filteredImpressions.length;
        });

      setPreviousEngaged(previousTotalClicks);
      setPreviousTouch(previousTotalImpressions + previousTotalClicks);
      setPreviousComplete(previousTotalCount);

      setEngaged(totalClicks);
      setTouch(totalImpressions + totalClicks);
      setComplete(totalCount);
    } else {
      // If there are no challenges or jobposts, set engaged and touch to 0
      setEngaged(0);
      setTouch(0);
      setComplete(0);
    }
  }, [challenges, jobposts, analytics]);

  useEffect(() => {
    if (chartRef.current) {
      // Destroy existing Chart instance
      const existingChart = Chart.getChart(chartRef.current);
      if (existingChart) {
        existingChart.destroy();
      }
      chartRef.current.width = 128;
      chartRef.current.height = 10;
      // Create a new Chart instance
      const newChart = new Chart(chartRef.current, {
        type: "line",
        data: chartData,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
          },
        },
      });
    }
  }, [chartData]);

  const calculateFillPercentage = (value) => {
    let divisor = 10;
    while (value > divisor) {
      divisor *= 10;
    }
    return Math.min(value, divisor) * (100 / divisor);
  };

  const engagedPercentage = calculateFillPercentage(engaged);
  const completedPercentage = calculateFillPercentage(complete);
  const touchPercentage = calculateFillPercentage(touch);
  const cards = [
    {
      title: "Engaged Talent",
      value: engaged,
      icon: activity,
      compare:
        engaged - previousEngaged >= 0
          ? `+${engaged - previousEngaged}`
          : engaged - previousEngaged,
      analytics: () => {
        return (
          <div
            style={{
              width: `${100}%`,
              height: "6px",
              borderRadius: "100px",
              background: `linear-gradient(90deg, #E31B54 ${engagedPercentage}%, #DBDFF1 ${engagedPercentage}%)`,
            }}
          ></div>
        );
      },
    },
    {
      title: "Completed Challenges",
      value: complete,
      icon: user,
      compare:
        complete - previousComplete >= 0
          ? `+${complete - previousComplete}`
          : complete - previousComplete,
      analytics: () => {
        return (
          <div
            style={{
              width: `${100}%`,
              height: "6px",
              borderRadius: "100px",
              background: `linear-gradient(90deg, #E31B54 ${completedPercentage}%, #DBDFF1 ${completedPercentage}%)`,
            }}
          ></div>
        );
      },
    },
    {
      title: "Touchpoints",
      value: touch,
      icon: eye,
      compare: previousTouch,
      analytics: () => {
        return (
          <>
            <div
              style={{
                width: `${100}%`,
                height: "6px",
                borderRadius: "100px",
                background: `linear-gradient(90deg, #E31B54 ${touchPercentage}%, #DBDFF1 ${touchPercentage}%)`,
              }}
            ></div>
          </>
        );
      },
    },
  ];
  return (
    <div
      className={`d-grid`}
      style={{
        gap: "2rem",
        gridTemplateColumns: `repeat(auto-fill, minmax(${
          isNonMob1441 ? "30%" : isNonMob1024 ? "30%" : "100%"
        }, 1fr))`,
      }}
    >
      {cards &&
        cards.map((ana, index) => (
          <div
            key={index}
            className="d-flex flex-wrap"
            style={{ gap: "0.75rem" }}
          >
            <div className="shadowa center anayliticsimgcotainer">
              <img src={ana.icon} alt="activity" className="img-fluid" />
            </div>
            <div className="" style={{ width: "82%" }}>
              <p className="black14">{ana.title}</p>
              <div
                className="d-flex flex-wrap "
                style={{
                  gap: "6px",
                  alignItems: "end",
                  position: "relative",
                  display: "block",
                }}
              >
                <div className="space flex-wrap" style={{ width: "100%" }}>
                  <p
                    className="black16"
                    style={{ fontSize: "22px", fontWeight: "600" }}
                  >
                    {ana.value}
                  </p>
                  <p className="gray14">{ana.compare}</p>
                </div>
                <div
                  className=""
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  {ana.analytics()}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
export default Analytics;
