import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setEmail } from "../../../state";
import { useDispatch } from "react-redux";

import "./company.css";
import { setCompany as set } from "../../../state";
const Find = () => {
  const { uniqueId } = useParams();
  const [company, setCompany] = useState("");
  const [text, setText] = useState("");
  const [texterr, setTextErr] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelect = (id) => {
    dispatch(set({ company: id.uniqueId }));
    navigate("/create/company/role");
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/company/register/find`, {
        uniqueId,
      })
      .then((res) => {
        if (res.data === "null") {
          navigate("/employer/register");
        } else {
          dispatch(setEmail({ email: res.data.email }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (text) {
      setTextErr(false);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/get/companies`, { text })
        .then((res) => {
          setCompany(res.data);
          setText("");
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      setTextErr(true);
    }
  };

  useEffect(() => {
    if (text && text.length >= 2) {
      setTextErr(false);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/get/companies`, { text })
        .then((res) => {
          setCompany(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [text]);

  return (
    <div
      className="container d-flex"
      style={{
        height: "80vh",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <div
        className="d-flex"
        style={{
          justifyContent: "center",
          marginTop: "-100px",
        }}
      >
        <div className="row">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="searchicon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.73872 8.94722C6.40262 4.95182 10.3012 2.34472 14.6292 2.33316C19.2938 2.30859 23.4414 5.29641 24.8954 9.72862C26.3495 14.1608 24.7778 19.0249 21.0051 21.7683C17.2325 24.5116 12.1208 24.5076 8.35256 21.7582L4.99256 25.1182C4.65094 25.4594 4.09752 25.4594 3.7559 25.1182C3.4147 24.7765 3.4147 24.2231 3.7559 23.8815L7.0109 20.6265C3.97045 17.5463 3.07482 12.9426 4.73872 8.94722ZM6.29873 16.5303C7.69578 19.8969 10.9843 22.0895 14.6292 22.0848V22.0382C19.5762 22.0319 23.5922 18.0367 23.6242 13.0898C23.6289 9.44485 21.4363 6.15638 18.0697 4.75932C14.703 3.36227 10.8263 4.1321 8.2489 6.70949C5.67151 9.28688 4.90168 13.1636 6.29873 16.5303Z"
                  fill="black"
                />
              </svg>{" "}
            </div>
          </div>
          <h2 className="text-center mt-3">Find your company</h2>
          <p className="text-center mt-3 welcome">
            Welcome to varyance. In this step you onboard your company.
            <br />
          </p>
          <form onSubmit={(e) => handleSearch(e)}>
            {" "}
            <div
              className="d-flex mt-3"
              style={{ justifyContent: "center", height: "3rem" }}
            >
              <div
                className={`newinputs  ${texterr ? "border-danger" : ""}`}
                style={{
                  width: "100%",
                  maxWidth: "39.5rem",
                  flexDirection: "row",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.6152 6.39046C15.4267 3.53659 12.642 1.67438 9.55055 1.66612C6.21872 1.64858 3.25614 3.78273 2.21754 6.9486C1.17894 10.1145 2.30158 13.5888 4.99632 15.5484C7.69106 17.5079 11.3423 17.505 14.0339 15.5411L16.4339 17.9411C16.6779 18.1848 17.0732 18.1848 17.3172 17.9411C17.5609 17.6971 17.5609 17.3018 17.3172 17.0578L14.9922 14.7328C17.164 12.5327 17.8037 9.24432 16.6152 6.39046ZM15.5009 11.8069C14.503 14.2116 12.1541 15.7778 9.55055 15.7745V15.7411C6.01698 15.7366 3.14843 12.883 3.12555 9.34946C3.12218 6.7459 4.68837 4.39699 7.0931 3.3991C9.49783 2.4012 12.2669 2.95108 14.1079 4.79207C15.9489 6.63307 16.4988 9.40218 15.5009 11.8069Z"
                    fill="#667085"
                  />
                </svg>
                <input
                  value={text}
                  placeholder="Company name"
                  className="searchCompanyInput"
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                  style={{ width: "100%", border: "none" }}
                />
              </div>
            </div>
          </form>
          {company !== "" && (
            <div className="mt-3 d-flex" style={{ justifyContent: "center" }}>
              <div
                className="companyCard   "
                style={{
                  overflow: "auto",
                  paddingRight: "0.75rem",
                  paddingTop: "0.75rem",
                  paddingBottom: "0.75rem",
                  width: "100%",
                  maxWidth: "39.5rem",
                }}
              >
                <div
                  style={{ overflow: "auto", maxHeight: "40vh" }}
                  className="graybarekit"
                >
                  {company.length === 0 && (
                    <div
                      className="d-flex innercard p-4"
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h5 className="noresult">No Results</h5>
                      <button
                        className="backbutton hoverdarkred"
                        style={{ fontFamily: "Poppins", boxShadow: "none" }}
                        onClick={() => navigate("/create/company")}
                      >
                        Create new company
                      </button>
                    </div>
                  )}

                  {company.length !== 0 &&
                    company.map((com, index) => (
                      <div
                        key={index}
                        className={` m-2 ${index === 0 && "mt-0"} ${
                          index === company.length - 1 && "mb-0"
                        }  p-2  rounded-3 d-flex innercard cardcompanystore`}
                        style={{ justifyContent: "space-between" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "1rem" }}
                        >
                          <img
                            src={com?.img}
                            alt="company image"
                            height="54px"
                            width="54px"
                            style={{ padding: "6px" }}
                            className="shadowa  rounded-3 "
                          />

                          <div className="">
                            <p className="companyTitle"> {com?.title}</p>
                            <p className="companyLocation">
                              {" "}
                              {com?.country[0]?.name}
                            </p>
                          </div>
                        </div>
                        <button
                          className=" backbutton"
                          onClick={() => {
                            if (com?.pre) {
                              navigate("/create/company", { state: { com } });
                            } else {
                              handleSelect(com);
                            }
                          }}
                        >
                          {com?.pre ? "Create company" : "Select"}
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}

          <div>
            <p className="text-center mt-3 cantfind">
              Can't find your company?
              <span
                className="hoverdarkred"
                onClick={() => navigate("/create/company")}
              >
                {" "}
                Create new company
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Find;
