import popcorn from "../../assets/images/icons/popcorn.png";
import Graduate from "../../assets/images/icons/Graduate.png";
import key from "../../assets/images/icons/key.png";
import Book from "../../assets/images/icons/Book.png";
import trolly from "../../assets/images/icons/trolly.png";
import world from "../../assets/images/icons/Aviation.png";
import ship from "../../assets/images/icons/Supply.png";
import pill from "../../assets/images/icons/Pharma.png";
import tv from "../../assets/images/icons/Technology.png";
import masks from "../../assets/images/icons/Government.png";
import celebrate from "../../assets/images/icons/marketing.png";
import { toast } from "react-toastify";

const GroupCategory = ({ categories, setCategories, TRUE = true }) => {
  const chips = [
    {
      name: "Student Central",
      icon: popcorn,
    },
    {
      name: "University Applications",
      icon: Graduate,
    },
    {
      name: "Apartments",
      icon: key,
    },
    {
      name: "Tutoring",
      icon: Book,
    },
    {
      name: "Marketplace",
      icon: trolly,
    },
    {
      name: "Exchange Students",
      icon: world,
    },
    {
      name: "Student Discounts & Deals",
      icon: ship,
    },
    {
      name: "University Clubs (not career related)",
      icon: pill,
    },
    {
      name: "Sports",
      icon: tv,
    },
    {
      name: "Events",
      icon: masks,
    },
    {
      name: "Language Exchange",
      icon: celebrate,
    },
  ];

  function selectItem(item, i) {
    if (TRUE) {
      let itemId = item.name;
      let updatedItem = [...chips];

      if (categories.some((selectedIte) => selectedIte.name === item.name)) {
        updatedItem[i].select = false;
        let newIdList = categories.filter(
          (selectedItem) => selectedItem.name !== itemId
        );
        setCategories(newIdList);
      } else {
        if (categories.length < 3) {
          updatedItem[i].select = true;
          setCategories((prev) => [...prev, item]);
        } else {
          toast.error("You can't select more than 3 items");
        }
      }
    } else {
      const itemIndex = categories.findIndex((it) => it.name === item.name);
      if (itemIndex !== -1) {
        const updatedItems = [...categories]; // Create a new array
        updatedItems.splice(itemIndex, 1); // Remove the item
        setCategories(updatedItems); // Update the state
      } else {
        const updatedItems = [...categories, item]; // Add the item to the array
        setCategories(updatedItems); // Update the state
      }
    }
  }

  return (
    <div className="d-flex flex-wrap" style={{ gap: "0.75rem 1rem" }}>
      {chips.map((chip, index) => (
        <div
          className={` ChipCover newdashboardsidechips ${
            categories?.some((selectedIte) => selectedIte.name === chip.name)
              ? "selected_interests_new"
              : ""
          }`}
          key={index}
          onClick={() => selectItem(chip, index)}
          style={{ border: "1px solid var(--Gray-200, #d0d5dd)" }}
        >
          {chip.icon !== null && (
            <img
              src={chip.icon}
              alt={chip.name}
              height="20px"
              width="20px"
              className="interest_icon"
              style={{ maxHeight: "1.25rem", maxWidth: "1.25rem" }}
            />
          )}
          <p
            className={`ChipsText  ${
              categories.some((selectedIte) => selectedIte.name === chip.name)
                ? "chipstextactivated"
                : ""
            } `}
          >
            {chip.name}
          </p>
        </div>
      ))}
    </div>
  );
};
export default GroupCategory;
