import CompanyImage from "../../components/Company/CompanyImage";
import DeleteIcon from "@mui/icons-material/Delete";

const VolunteerList = ({
  setAddVolunteer,
  loading,
  volunteer,
  setNewVolunteer,
  setIndex,
  handleUpdate,
  EditIcon,
  setVolunteer,
}) => {
  return (
    <div>
      <div className="d-flex flex-column gap-4">
        {volunteer.length > 0 &&
          volunteer.map((vol, index) => (
            <div key={index}>
              <div
                className="d-flex justify-content-end"
                style={{ gap: "1rem" }}
              >
                <EditIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIndex(index);
                    setNewVolunteer(vol);
                    setAddVolunteer(true);
                  }}
                />
                <DeleteIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setVolunteer(volunteer.filter((_, i) => i !== index));
                  }}
                />
              </div>
              <div className="d-flex flex-wrap gap-3">
                {vol?.logo && (
                  <CompanyImage
                    src={
                      vol?.logo instanceof File
                        ? URL.createObjectURL(vol?.logo)
                        : vol?.logo
                    }
                  />
                )}
                <div>
                  <p className="gray95016500">{vol?.title}</p>
                  <p className="mt-2 a314400">{vol?.companyName}</p>
                  <p className="mt-3 a314400">{vol?.description}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="mt-3">
        <div className="d-flex">
          <p
            className=" text-start backbutton hoverred mt-3"
            onClick={() => setAddVolunteer(true)}
            style={{ cursor: "pointer" }}
          >
            Add New
          </p>
        </div>
        <hr className="mt-5" />
        <button
          className="continuebutton mt-2"
          onClick={() => handleUpdate()}
          disabled={loading}
        >
          Update Awards & Scholarships
        </button>
      </div>
    </div>
  );
};

export default VolunteerList;
