import Second from "../../components/Company/Dashboard/Second";

import { useDispatch } from "react-redux";
import { setStep } from "../../state";
const Test = () => {
  const dispatch = useDispatch();
  dispatch(setStep({ step: 1 }));
  return (
    <div
      className="paddingmaincontent"
      style={{
        paddingBottom: "2rem",
        minHeight: "100vh",
      }}
    >
      <>
        <Second />
      </>
    </div>
  );
};
export default Test;
