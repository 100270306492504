const Members = ({
  group,
  members,
  loadingMember,
  hasMoreMembers,
  fetchMoreMembers,
}) => {
  return (
    <div style={{ background: "#FFF", borderRadius: "5px", padding: "1.5rem" }}>
      <div
        className="space flex-wrap align-items-center"
        style={{ gap: "1rem" }}
      >
        <div className="d-flex align-items-center" style={{ gap: "0.75rem" }}>
          <p className="gray90020400">All Members</p>
          <p
            className="graybody14500"
            style={{
              color: "#FFF",
              background: "#e31b54",
              borderRadius: "16px",
              padding: "2px 8px",
            }}
          >
            {group?.members?.length + 1 > 1000
              ? ((group.members.length + 1) / 1000).toFixed(1) + "k"
              : group?.members?.length + 1}
          </p>
        </div>
      </div>

      <div
        style={{ height: "1px", width: "100%", margin: "0.75rem 0 1.875rem 0" }}
      />

      <div>
        {members.length > 0 ? (
          <div className="d-flex flex-column gap-3">
            {members.map((admin, index) => (
              <div key={index} className="d-flex" style={{ gap: "0.75rem" }}>
                <img
                  src={admin?.profilePhoto}
                  alt="profile"
                  style={{
                    height: "48px",
                    width: "48px",
                    border: "2px solid #FFF",
                    borderRadius: "50%",
                  }}
                />
                <div>
                  <p className="gray95016500">
                    {admin?.firstName} {admin?.lastName}
                  </p>
                  <p className="mt-2 gray50014400">{admin?.uniName}</p>
                  <p
                    className="mt-2 pink14 hovercolorback"
                    style={{ cursor: "pointer" }}
                  >
                    View Profile
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          !loadingMember && <p>No members found.</p>
        )}

        {loadingMember && (
          <div className="center mt-2 mb-2">
            <div className="loader" />
          </div>
        )}

        {!loadingMember && hasMoreMembers && (
          <p
            className="pink14 hovercolorback mt-3"
            style={{ cursor: "pointer" }}
            onClick={fetchMoreMembers}
          >
            See more
          </p>
        )}
      </div>
    </div>
  );
};

export default Members;
