import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Header from "./Header";
import { toast } from "react-toastify";

import ReactQuill from "react-quill";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const CompanyEdit = () => {
  const { uniqueId } = useParams();
  const [company, setCompany] = useState();

  const [organizer, setOrganizer] = useState(null);
  const [addHover, setAddHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const id = useSelector((state) => state.uniqueId);

  const navigate = useNavigate();
  const [Index, setIndex] = useState(-1);

  const [headline, setHeadline] = useState("");
  const [file, setFile] = useState(null);
  const [color, setColor] = useState("#E31b54");
  const [details, setDetails] = useState("");
  const [gallery, setGallery] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);
  const fileInput2Ref = useRef(null);
  const fileInput3Ref = useRef(null);
  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("owner", organizer?.email);
    formData.append("company", company.uniqueId);
    formData.append("headline", headline);
    formData.append("details", details);
    formData.append("color", color);
    if (file) {
      formData.append("file", file);
    }
    if (selectedFile instanceof File) {
      formData.append("img", selectedFile);
    }
    if (gallery) {
      gallery.forEach((image) => {
        formData.append("gallery", image);
      });
    }

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/edit/company/info`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.msg === "success") {
          navigate(-1);
        } else {
          toast.error("Error Changing Company details");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setOrganizer(userInfoResponse.data.user);
        } else {
          return;
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
    ];
    const maxFileSize = 12 * 1024 * 1024; // 12MB in bytes

    if (selectedFile) {
      if (allowedTypes.includes(selectedFile.type)) {
        if (selectedFile.size <= maxFileSize) {
          setFile(selectedFile);
        } else {
          toast.error("File size exceeds 12MB limit");
        }
      } else {
        toast.error(
          "Invalid file type. Only SVG, PNG, JPEG, and GIF are allowed."
        );
      }
    }
  };

  useEffect(() => {
    if (company) {
      setHeadline(company?.tagline);
      setDetails(company?.details);
      setFile(company?.banner);
      setGallery(company?.gallery);
      setColor(company?.color ? company?.color : "#E31b54");
      setSelectedFile(company?.img ? company?.img : null);
    }
  }, [company]);

  const handleClick2 = () => {
    if (fileInput2Ref.current) {
      fileInput2Ref.current.click();
    }
  };
  const handleClick3 = () => {
    if (fileInput3Ref.current) {
      fileInput3Ref.current.click();
    }
  };

  const handleFileChange2 = (e) => {
    const selectedFiles = e.target.files;
    const allowedTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
    ];
    const maxFileSize = 12 * 1024 * 1024; // 12MB in bytes

    if (selectedFiles) {
      let addedFiles = 0;
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (gallery.length + addedFiles >= 5) {
          toast.error("Maximum of 5 images allowed in the gallery");
          break;
        }
        if (allowedTypes.includes(file.type)) {
          if (file.size <= maxFileSize) {
            setGallery((prevGallery) => [...prevGallery, file]);
            addedFiles++;
          } else {
            toast.error("File size exceeds 12MB limit");
          }
        } else {
          toast.error(
            "Invalid file type. Only SVG, PNG, JPEG, and GIF are allowed."
          );
        }
      }
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "align",
  ];

  const handleFileChange3 = (event) => {
    const file = event.target.files?.[0];

    if (file && file.type.startsWith("image/")) {
      const img = new Image();

      img.onload = function () {
        setSelectedFile(file);
      };
      img.src = URL.createObjectURL(file);
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: uniqueId }
        );
        console.log(companyInfoResponse.data, "test");
        if (companyInfoResponse.data.message === "Logout") {
          navigate(-1);
          return;
        }

        setCompany(companyInfoResponse?.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [uniqueId]);

  useEffect(() => {
    if (company && organizer) {
      if (company?.createdBy === organizer?.email) {
      } else {
        navigate(-1);
      }
    }
  }, [company, organizer]);

  const handleDelete = (index) => {
    setGallery((prevGallery) => prevGallery.filter((_, i) => i !== index));
  };

  return (
    <div style={{ background: "#F2F3F7" }}>
      <Header navigate={navigate} text={`Edit Company Page`} />
      <div className="marginsleftrightCompany ">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className={`headerCompany center`}>
            <div
              style={{ maxWidth: "700px", width: "100%", paddingTop: "2.5rem" }}
            >
              <p className="black24">Company page</p>
              <p
                className="gray14"
                style={{ marginTop: "0.5rem", color: "#1D2939" }}
              >
                Overview page for interested students.
              </p>
              <p
                className="black14"
                style={{ marginTop: "2.5rem", fontWeight: "500" }}
              >
                Company Logo
              </p>

              <div
                className="mt-2 center flex-column rounded-3 border border-1"
                style={{
                  minHeight: "180px",
                  maxHeight: "180px",
                  padding: "2rem",
                  background: "#FFF",
                  cursor: "pointer",
                  alignItems: "center",
                  position: "relative",
                }}
                onClick={handleClick3}
              >
                <input
                  type="file"
                  accept="image/svg+xml,image/png,image/jpeg,image/gif"
                  onChange={handleFileChange3}
                  style={{ display: "none" }}
                  ref={fileInput3Ref}
                />

                {selectedFile ? (
                  <img
                    src={
                      selectedFile && selectedFile instanceof File
                        ? URL.createObjectURL(selectedFile)
                        : selectedFile.includes("http")
                        ? selectedFile
                        : selectedFile.includes("data")
                        ? selectedFile
                        : `${process.env.REACT_APP_BACKEND_URL}/${selectedFile}`
                    }
                    alt="Selected file"
                    style={{ maxHeight: "100px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                ) : (
                  <div
                    className="center shadowa rounded-3"
                    style={{
                      padding: "10px",
                      background: "#FFF",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613"
                        stroke="#475467"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                )}
                <p
                  className="pink16 hoverdarkred"
                  style={{ marginTop: "0.75rem" }}
                >
                  Click to {file ? "replace" : "upload"}
                </p>
                <p className="gray14">SVG, PNG, JPG or GIF (max. 12MB)</p>
              </div>

              <p
                className="black14"
                style={{ marginTop: "2.5rem", fontWeight: "500" }}
              >
                Sub-Headline
              </p>
              <p className="gray14" style={{ marginTop: "0.25rem" }}>
                Max. 100 Characters
              </p>
              <input
                className="newinputs col-md-12 mt-2"
                value={headline}
                onChange={(e) => {
                  if (e.target.value.length <= 100) {
                    setHeadline(e.target.value);
                  }
                }}
              />
              <p className="gray14 mt-2">{headline.length} Characters</p>
              <p
                className="black14"
                style={{ marginTop: "2.5rem", fontWeight: "500" }}
              >
                Company Color
              </p>
              <p className="gray14" style={{ marginTop: "0.25rem" }}>
                Select your company's color to update the buttons and text
                colors on your challenges.
              </p>
              <input
                type="color"
                name="color"
                value={color}
                className="newinputs col-md-12 mt-2"
                style={{ height: "50px", width: "50px" }}
                onChange={(e) => {
                  setColor(e.target.value);
                }}
              />
              <p
                className="black14"
                style={{ marginTop: "2.5rem", fontWeight: "500" }}
              >
                Banner Image
              </p>

              <div
                className="mt-2 center flex-column rounded-3 border border-1"
                style={{
                  minHeight: "180px",
                  maxHeight: "180px",
                  padding: "2rem",
                  background: "#FFF",
                  cursor: "pointer",
                  alignItems: "center",
                  position: "relative",
                }}
                onClick={handleClick}
              >
                <input
                  type="file"
                  accept="image/svg+xml,image/png,image/jpeg,image/gif"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />

                {file ? (
                  <img
                    src={
                      file && file instanceof File
                        ? URL.createObjectURL(file)
                        : file.includes("http")
                        ? file
                        : `${process.env.REACT_APP_BACKEND_URL}/${file}`
                    }
                    alt="Selected file"
                    style={{ maxHeight: "100px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                ) : (
                  <div
                    className="center shadowa rounded-3"
                    style={{
                      padding: "10px",
                      background: "#FFF",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613"
                        stroke="#475467"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                )}
                <p
                  className="pink16 hoverdarkred"
                  style={{ marginTop: "0.75rem" }}
                >
                  Click to {file ? "replace" : "upload"}
                </p>
                <p className="gray14">SVG, PNG, JPG or GIF (max. 12MB)</p>
              </div>
              <p
                className="black14"
                style={{ marginTop: "2.5rem", fontWeight: "500" }}
              >
                Company Details
              </p>
              <p className="gray14" style={{ marginTop: "0.25rem" }}>
                Write about your company, culture and more.
              </p>
              <div className="jobcreatereactquill">
                <ReactQuill
                  value={details}
                  onChange={(value) => setDetails(value)}
                  modules={modules}
                  formats={formats}
                  className="col-md-12  reactquillfontfix whitescrollbar "
                  style={{
                    backgroundColor: "#FFF",
                    marginTop: "0.75rem",
                  }}
                />
              </div>
              <p
                className="black14"
                style={{ marginTop: "2.5rem", fontWeight: "500" }}
              >
                Gallery (Optional)
              </p>
              <p className="gray14" style={{ marginTop: "0.25rem" }}>
                Add a minimum of 3 images to publish a gallery.
              </p>
              <div>
                <input
                  type="file"
                  accept="image/svg+xml,image/png,image/jpeg,image/gif"
                  onChange={handleFileChange2}
                  style={{ display: "none" }}
                  ref={fileInput2Ref}
                  multiple
                />
                <div
                  className="mt-2 center flex-column rounded-3 border border-1"
                  style={{
                    minHeight: "180px",
                    maxHeight: "180px",
                    padding: "2rem",
                    background: "#FFF",
                    cursor: "pointer",
                    alignItems: "center",
                    position: "relative",
                  }}
                  onClick={handleClick2}
                >
                  <div
                    className="center shadowa rounded-3"
                    style={{
                      padding: "10px",
                      background: "#FFF",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613"
                        stroke="#475467"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p
                    className="pink16 hoverdarkred"
                    style={{ marginTop: "0.75rem" }}
                  >
                    Click to upload
                  </p>
                  <p className="gray14">SVG, PNG, JPG or GIF (max. 12MB)</p>
                </div>
              </div>
              <div
                className="d-flex flex-wrap mt-2 mb-5"
                style={{ gap: "1rem" }}
              >
                {console.log(gallery)}
                {gallery.map((file, index) => (
                  <div
                    className={` ${Index === index && "outerglass p-0"}`}
                    onMouseEnter={() => setIndex(index)}
                    onMouseLeave={() => setIndex(-1)}
                    style={{ cursor: "pointer" }}
                    key={index}
                  >
                    <img
                      key={index}
                      src={
                        file && file instanceof File
                          ? URL.createObjectURL(file)
                          : file.includes("http")
                          ? file
                          : `${process.env.REACT_APP_BACKEND_URL}/${file}`
                      }
                      alt={`Selected file ${index + 1}`}
                      className="rounded-3 shadowa"
                      style={{
                        maxHeight: "100px",
                        height: "100px",
                        width: "100px",
                        maxWidth: "100px",
                        marginRight: "5px",
                      }}
                    />
                    {Index === index && (
                      <DeleteForeverIcon
                        style={{ color: "#E31b54" }}
                        onClick={() => handleDelete(index)}
                      />
                    )}
                  </div>
                ))}
                {gallery.length < 5 && (
                  <div
                    className={`center rounded-3 shadowa`}
                    onMouseEnter={() => setAddHover(true)}
                    onMouseLeave={() => setAddHover(false)}
                    onClick={handleClick2}
                    style={{
                      minHeight: "100px",
                      minWidth: "100px",
                      alignItems: "center",
                      maxHeight: "100px",
                      cursor: "pointer",

                      maxWidth: "100px",
                      background: addHover
                        ? "#fff5f6"
                        : "rgba(255, 255, 255, 0.4)",
                    }}
                  >
                    <div>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 100 100"
                        fill="none"
                      >
                        <path
                          d="M82.2917 45.8333H52.0833V15.625C52.0833 13.8991 50.6842 12.5 48.9583 12.5C47.2324 12.5 45.8333 13.8991 45.8333 15.625V45.8333H15.625C13.8991 45.8333 12.5 47.2324 12.5 48.9583C12.5 50.6842 13.8991 52.0833 15.625 52.0833H45.8333V82.2917C45.8333 84.0176 47.2324 85.4167 48.9583 85.4167C50.6842 85.4167 52.0833 84.0176 52.0833 82.2917V52.0833H82.2917C84.0176 52.0833 85.4167 50.6842 85.4167 48.9583C85.4167 47.2324 84.0176 45.8333 82.2917 45.8333Z"
                          fill={addHover ? "#E31B54" : "white"}
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
              <hr style={{ marginTop: "8.125rem" }} />
              <div className="space flex-wrap w-full mb-5 pb-5">
                <button className="backbutton" onClick={() => navigate(-1)}>
                  Back
                </button>
                <button
                  className="continuebutton"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanyEdit;
