import Table from "./Table";
const Analytics = ({ events, setData, owners }) => {
  return (
    <div>
      <div>
        <Table events={events} setData={setData} owners={owners} />
      </div>
    </div>
  );
};
export default Analytics;
