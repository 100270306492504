import React from "react";
import "./header.css";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const Header = ({ setShowPreviewa }) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{ background: "#FFF", minWidth: "100vw" }}
        className="shadowa"
      >
        <div className="marginsleftrightCompany py-2 ">
          <div className="center">
            <div className="headerCompany space">
              <img
                src={logo}
                alt="logo"
                heigh="36px"
                width="36px"
                style={{
                  maxHeight: "36px",
                  maxWidth: "36px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              />
              <div className="d-flex" style={{ gap: "1rem" }}>
                <button
                  className="backbutton"
                  onClick={() => setShowPreviewa(false)}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
