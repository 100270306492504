import InfiniteScroll from "react-infinite-scroll-component";
import EmptyState from "../../assets/images/EmptyState.png";
import Event from "./Event";
const AllEvents = ({
  events,
  fetchMoreEvents,
  hasMore,
  loading,
  user,
  setEvents,
}) => {
  const monthOrder = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const groupedEvents = events
    .filter((event) => {
      const eventDate = new Date(event?.datetime);
      eventDate.setHours(0, 0, 0, 0); // Remove time component from `eventDate`
      const isFutureEvent = eventDate >= now;

      return isFutureEvent;
    })
    .reduce((acc, event) => {
      const eventDate = new Date(event?.datetime);
      const month = eventDate
        .toLocaleString("default", { month: "long" })
        .toLowerCase();

      if (!acc[month]) {
        acc[month] = [];
      }

      acc[month].push(event);
      return acc;
    }, {});

  const sortedGroupedEvents = monthOrder.reduce((acc, month) => {
    if (groupedEvents[month]) {
      acc[month] = groupedEvents[month];
    }
    return acc;
  }, {});

  return (
    <div>
      <InfiniteScroll
        dataLength={events.length}
        next={fetchMoreEvents}
        hasMore={hasMore}
        loader={
          <div className="center flex-column align-items-center mt-4">
            <div className="loader"></div>
            <p className="black16 text-center">Loading...</p>
          </div>
        }
        endMessage={
          <p className="black16 text-center mt-4">
            {events.length > 0
              ? "No more Events at the moment"
              : "No Events at the moment"}
          </p>
        }
      >
        <div className="d-flex flex-column" style={{ gap: "1.875rem" }}>
          {events.length > 0 ? (
            Object.keys(sortedGroupedEvents).map((month) => (
              <div key={month} className="upcoming-outer">
                <p className="welcome text-start">
                  {month.charAt(0).toUpperCase() + month.slice(1)}
                </p>
                {sortedGroupedEvents[month].map((event, index) => (
                  <div key={index}>
                    <Event event={event} user={user} setEvents={setEvents} />
                  </div>
                ))}
              </div>
            ))
          ) : loading ? (
            <> </>
          ) : (
            <div className="center">
              <img
                src={EmptyState}
                className="img-fluid"
                alt="empty state"
                style={{ maxWidth: "300px" }}
              />
            </div>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default AllEvents;
