import React, { useState } from "react";
import fasttrack from "../../../../assets/images/fasttrack.svg";

import logo from "../../../../assets/images/logo.png";
import linkedin from "../../../../assets/images/icons/linkedin.png";
import lamp from "../../../../assets/images/Lamp.png";

import { useMediaQuery } from "@mui/material";
import "./fourthsection.css";
import Slider from "react-slick";

const FourthSection = ({ difficulty, totalSkills, totalLearns }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    speed: 500,
    slidesToShow: 1, // Adjust this based on how many slides you want to show
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    customPaging: (i) => <CustomDots active={i === currentSlide} />,
    appendDots: (dots) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        {dots}
      </div>
    ),
    centerPadding: "0px", // Ensure no extra padding is added
  };

  const isNonMon768 = useMediaQuery("(min-width:768px)");

  let points =
    (difficulty === "Basic" && 10) ||
    (difficulty === "Intermediate" && 20) ||
    (difficulty === "Advanced" && 30);

  let totalpoints = points * (totalLearns + totalSkills);

  const items = [
    {
      title: "Fast Track",
      icon1: fasttrack,
      icon2: null,
      text: "Gain insights in an evaluated and time-constrained challenge and compete against students globally.",
    },
    {
      title: "Earn Certificate",
      text: "You can share your certificate on your varyance and LinkedIn profile and use it on your resume.",
      icon1: logo,
      icon2: linkedin,
    },
    {
      title: `Skill Points`,
      text: `You will receive a total of <span  class="text-start black16" style="color:#E31B54;">${totalpoints}</span> Skill-Points for successfully completing this challenge.`,
      icon1: lamp,
    },
  ];

  return (
    <div
      className="newcarousel"
      style={{
        paddingTop: "4rem",
        paddingBottom: "4rem",
      }}
    >
      <div
        className="marginsleftrightCompany"
        style={{
          overflow: "visible", // Allow overflow to be visible
        }}
      >
        <div
          className="d-flex"
          style={{
            justifyContent: "center",
          }}
        >
          <div className="headerCompany">
            <div className="dsasdad">
              <Slider {...settings} infinite={false}>
                {items.map((item, index) => (
                  <div className="newPreviewPageCards" key={index}>
                    <div
                      className={`${isNonMon768 ? "" : "center flex-column "}`}
                      style={{ height: "100%" }}
                    >
                      <div className="d-flex" style={{ gap: "0.75rem" }}>
                        <img
                          src={item.icon1}
                          alt="fasttrack"
                          height="40px"
                          width="40px"
                        />
                        {item?.icon2 && (
                          <img
                            src={item?.icon2}
                            alt="fasttrack"
                            height="40px"
                            width="40px"
                          />
                        )}
                      </div>
                      <div style={{ marginTop: "1.5rem" }}>
                        <p className="registeration">{item.title}</p>

                        <div
                          className="black16 text-start"
                          style={{ marginTop: "1rem", maxWidth: "458px" }}
                          dangerouslySetInnerHTML={{
                            __html: item?.text,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}{" "}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourthSection;

const CustomDots = ({ onClick, active }) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: active ? "40px" : "8px",
        height: "8px",
        backgroundColor: active ? "#e31b54" : "#ccc",
        borderRadius: "4px",
        margin: "0 4px",
        cursor: "pointer",
        transition: "all 0s ease-in-out",
      }}
    />
  );
};
