import Graduate from "../../assets/images/icons/Graduate.png";
import UnSelectedCircle from "../../assets/images/icons/UnselectedCirlce";
import SelectedCircle from "../../assets/images/icons/selectedCircle";
const step1 = ({ levelofstudy, setLevelOfStudy, isNonMobile }) => {
  const study = [
    "First Year",
    "2nd Year",
    "3rd Year",
    "Bachelor",
    "Masters Program",
    "PHD",
    "State Examination",
    "MBA",
    "Doctorate",
    "Postdoctoral Studies",
    "Other",
  ];

  return (
    <div className={`${isNonMobile ? "mt-4" : "mt-3"} `}>
      <div className="d-flex">
        <div
          className="center align-items-center p-2 rounded-5"
          style={{ background: "#F2F4F7" }}
        >
          <img
            src={Graduate}
            alt="Graduate"
            style={{ height: "36px", width: "36px" }}
          />
        </div>
      </div>
      <p className={`gray95024400 ${isNonMobile ? "mt-4" : "mt-3"}`}>
        Level of Study
      </p>
      <p className="gray70014400 mt-2">Select your level of study</p>
      <div className="mt-4 d-flex flex-column gap-2">
        {study.map((stu, index) => (
          <div key={index} className="d-flex align-items-center">
            <div
              className="d-flex  gap-2 gray80014400 hoverred align-items-center"
              style={{
                cursor: "pointer",
                fontWeight: "500",
                color: levelofstudy === stu ? "#E31b54" : "",
              }}
              onClick={() => {
                setLevelOfStudy(stu);
              }}
            >
              {levelofstudy === stu ? <SelectedCircle /> : <UnSelectedCircle />}

              {stu}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default step1;
