import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MediaType from "./MediaType";
import "./media.css";
import { useMediaQuery } from "@mui/material";
const PostMedia = ({ media, SetSelectedPost }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const isNonMobile = useMediaQuery("(min-width:767px)");

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const settings = {
    initialSlide: currentSlide,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <div className="slick-next">→</div>,
    prevArrow: <div className="slick-prev">←</div>,
  };

  return (
    <div>
      <div className="d-flex media-container" style={{ gap: "1.25rem" }}>
        <div
          style={{
            width: media.length > 1 ? "calc(50% - 10px)" : "100%",
            maxWidth: media.length > 1 ? "calc(50% - 10px)" : "100%",
          }}
          className="media-thumbnail"
          onClick={() => {
            if (media.length > 1) {
              SetSelectedPost();
            }
          }}
        >
          <MediaType media={media[0]} />
        </div>
        {media.length > 1 && (
          <div
            className="d-flex flex-column"
            style={{
              width: "calc(50% - 10px)",
              maxHeight: isNonMobile ? "384px" : "310px",
              minHeight: isNonMobile ? "384px" : "310px",
              gap: media.length > 2 ? "1.25rem" : "0rem",
              height: isNonMobile ? "384px" : "310px",
              overflow: "hidden",
            }}
          >
            {media.slice(1, 3).map((item, index) => (
              <div
                key={index}
                className="media-thumbnail "
                // onClick={() => openModal(index)}
                style={{
                  maxHeight: media.length > 2 ? "182px" : "384px",
                  height: media.length > 2 ? "182px" : "384px",
                }}
                onClick={() => {
                  if (media.length > 1) {
                    SetSelectedPost();
                  }
                }}
              >
                <MediaType media={item} />
                {media.length > 3 && index === 1 && (
                  <div
                    className="media-overlay black16"
                    style={{ borderRadius: "5px" }}
                    onClick={() => {
                      if (media.length > 1) {
                        SetSelectedPost();
                      }
                    }}
                  >
                    +{media.length - 3}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {modalIsOpen && (
        <div className="modal">
          <button onClick={closeModal}>×</button>
          <Slider {...settings}>
            {media.map((item, index) => (
              <div key={index}>
                <MediaType media={item} fullView={true} />
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default PostMedia;
