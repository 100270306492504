// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chipsdiv {
  display: flex;
  padding: 8px 14px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: var(--Gray-25, #fcfcfd);
  color: var(--gray-500-p, #667085);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.secondselectbutton {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 12px;
  color: var(--Secondary-Color-Gray-600, #475467);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding-bottom: 0.75rem;
  cursor: pointer;
}
.secondselectbuttonselected {
  color: var(--Primary-Color-Red-600, #e31b54);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border-bottom: 1px solid #e31b54;
}
`, "",{"version":3,"sources":["webpack://./src/pages/StudentDashboardProfile/profile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;EACnB,yBAAyB;EACzB,mCAAmC;EACnC,iCAAiC;EACjC,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC;;AAEA;EACE,aAAa;;EAEb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,+CAA+C;EAC/C,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;EAChC,uBAAuB;EACvB,eAAe;AACjB;AACA;EACE,4CAA4C;EAC5C,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;EAChC,gCAAgC;AAClC","sourcesContent":[".chipsdiv {\n  display: flex;\n  padding: 8px 14px;\n  align-items: center;\n  gap: 6px;\n  border-radius: 30px;\n  border: 1px solid #eff0f1;\n  background: var(--Gray-25, #fcfcfd);\n  color: var(--gray-500-p, #667085);\n  font-family: Poppins;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 16px; /* 133.333% */\n}\n\n.secondselectbutton {\n  display: flex;\n\n  justify-content: center;\n  align-items: center;\n  gap: 12px;\n  color: var(--Secondary-Color-Gray-600, #475467);\n  text-align: center;\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px; /* 142.857% */\n  padding-bottom: 0.75rem;\n  cursor: pointer;\n}\n.secondselectbuttonselected {\n  color: var(--Primary-Color-Red-600, #e31b54);\n  text-align: center;\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px; /* 142.857% */\n  border-bottom: 1px solid #e31b54;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
