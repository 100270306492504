import { useState } from "react";
import Calender from "../../assets/images/icons/Calender";
import coverphoto from "../../assets/images/test.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { toast } from "react-toastify";

const UserDetails = ({ user, connects, watcher, setUser, setWatcher }) => {
  function formatDate(inputDate) {
    // Create a new Date object from the input string
    const date = new Date(inputDate);

    // Format options for day of the week, month, day, and year
    const options = {
      // weekday: "short", // 'Wed'
      year: "numeric", // '2025'
      month: "short", // 'Jan'
      day: "2-digit", // '03'
    };

    // Format the date
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate.replace(/,/g, ","); // Ensures correct format with commas
  }

  const [loading, setLoading] = useState(false);
  const handleConnect = async (msg) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/handle/user/${user?._id}/${msg}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          if (msg === "connect") {
            user?.requests?.push(watcher?._id);
            toast.success("Connect Request Sent Successfully");
          } else if (msg === "cancelreq") {
            setUser((prev) => {
              return {
                ...prev,
                requests: user?.requests.filter((id) => id !== watcher?._id),
              };
            });
            toast.success("Connect Request Cancelled");
          } else if (msg === "acceptreq") {
            toast.success("Request Accepted");
            setUser((prev) => {
              return {
                ...prev,
                requests: user?.requests.filter((id) => id !== watcher?._id),
              };
            });

            setWatcher((prev) => {
              return {
                ...prev,
                requests: watcher?.requests.filter((id) => id !== user?._id),
              };
            });
            watcher.connects.push(user?._id);
            user.connects.push(watcher?._id);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Handling Connection");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div
        style={{ borderRadius: "5px", overflow: "hidden", background: "#FFF" }}
      >
        <img
          src={user?.coverPhoto ? user?.coverPhoto : coverphoto}
          style={{
            objectFit: "cover",
            maxHeight: "200px",
            minHeight: "200px",
            width: "100%",
            borderRadius: "5px 5px 0px 0px",
          }}
        />
        <div style={{ padding: "1.5rem", paddingTop: "0rem" }}>
          <img
            className="shadowa"
            src={user?.profilePhoto}
            alt="Selected file"
            style={{
              objectFit: "cover",
              maxHeight: "80px",
              minHeight: "80px",
              border: "1px solid #FFF",
              width: "80px",
              borderRadius: "50%",
              position: "relative",
              marginTop: "-40px",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <p className="gray90020400" style={{ marginTop: "1rem" }}>
            {user?.firstName} {user?.LastName}
          </p>

          <p className="gray95014400">{user?.bio}</p>
          <div
            className="d-flex gap-2 align-items-center flex-wrap"
            style={{ marginTop: "1rem" }}
          >
            <Calender height={"16px"} width={"16px"} />
            <p className="gray70014400">
              Joined on{" "}
              <span className="gray70014400" style={{ fontWeight: 500 }}>
                {formatDate(user?.createdAt)}
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center gap-2">
            {connects && connects.length > 0 && (
              <div className="center align-items-center">
                {connects.map((connect, index) => (
                  <img
                    key={index}
                    src={connect.profilePhoto}
                    alt="profile photo"
                    style={{
                      height: "24px",
                      width: "24px",
                      position: "relative",
                      marginLeft: index === 0 ? "" : "-10px",
                      zIndex: index,
                      borderRadius: "50%",
                      background: "#FFF",
                      border: "1px solid #FFF",
                    }}
                  />
                ))}
              </div>
            )}
            <p className="gray70014400" style={{ marginTop: "1rem" }}>
              {user?.connects?.length >= 1000
                ? (user?.connects?.length / 1000).toFixed(1) + "k"
                : user?.connects?.length}{" "}
              {user?.connects?.length === 1 ? "Connect" : "Connects"}
            </p>
          </div>
          <div
            className="d-flex mt-4 align-items-center"
            style={{ gap: "1.5rem" }}
          >
            {user?._id !== watcher?._id ? (
              <button
                className="continuebutton"
                onClick={() => {
                  if (user?.connects.includes(watcher?._id)) {
                    console.log("MEssage");
                  } else if (user?.requests.includes(watcher?._id)) {
                    handleConnect("cancelreq");
                  } else if (watcher?.requests.includes(user?._id)) {
                    handleConnect("acceptreq");
                  } else {
                    handleConnect("connect");
                  }
                }}
                disabled={loading}
              >
                {user?.connects?.includes(watcher?._id)
                  ? "Message"
                  : user?.requests?.includes(watcher?._id)
                  ? "Cancel Request"
                  : watcher?.requests?.includes(user?._id)
                  ? "Accept Request"
                  : "Connect"}
              </button>
            ) : (
              <button className="backbutton">Edit Profile</button>
            )}
            <button
              className="backbutton hoverred"
              style={{ padding: "10px", maxHeight: "40px" }}
              onClick={() => {}}
            >
              <MoreVertIcon className="" style={{ cursor: "pointer" }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
