const SelectedGreen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_3118_107551)">
        <path
          d="M9.99805 0C4.48426 0 -0.00195312 4.48622 -0.00195312 10C-0.00195312 15.5138 4.48426 20 9.99805 20C15.5118 20 19.998 15.5138 19.998 10C19.998 4.48622 15.5118 0 9.99805 0ZM15.587 7.36842L9.19604 13.7093C8.8201 14.0852 8.2186 14.1103 7.8176 13.7343L4.43414 10.6516C4.03313 10.2757 4.00807 9.64912 4.35895 9.24812C4.73489 8.84712 5.36146 8.82206 5.76246 9.198L8.44416 11.6541L14.1584 5.93985C14.5595 5.53885 15.186 5.53885 15.587 5.93985C15.988 6.34085 15.988 6.96742 15.587 7.36842Z"
          fill="#5FDCB3"
        />
      </g>
      <defs>
        <clipPath id="clip0_3118_107551">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SelectedGreen;
