import React from "react";
const AddVolunteer = ({
  newVolunteer,
  setNewVolunteer,

  handleConfirm,
  toast,
}) => {
  const fileInputRef = React.createRef();

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      setNewVolunteer((prev) => {
        return {
          ...prev,
          logo: file,
        };
      });
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div>
      <p className="black14 mt-4">Organization Name*:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newVolunteer?.companyName}
        placeholder="Enter Organization Name"
        onChange={(e) => {
          setNewVolunteer((prev) => {
            return {
              ...prev,
              companyName: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Organization Logo(optional):</p>
      <div className="mt-3 border p-4 rounded-3">
        {newVolunteer?.logo && (
          <div className="center">
            <img
              src={
                newVolunteer?.logo instanceof File
                  ? URL.createObjectURL(newVolunteer?.logo)
                  : newVolunteer?.logo
              }
              alt="selectefFile"
              className="rounded-2"
              style={{ maxHeight: "200px", maxWidth: "200px" }}
            />
          </div>
        )}
        <div style={{ gap: "5px", justifyContent: "center" }}>
          <p
            className="text-center clicktoupload"
            onClick={openFileInput}
            style={{ cursor: "pointer" }}
          >
            {newVolunteer?.logo
              ? "Click to change Image"
              : "Click to upload Image"}
          </p>
          <p className="svgpngtext">SVG, PNG, JPG or GIF (max. 12MB)</p>
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
      </div>
      <p className="black14 mt-4">Position*:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newVolunteer?.title}
        placeholder="Enter Position"
        onChange={(e) => {
          setNewVolunteer((prev) => {
            return {
              ...prev,
              title: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Description:</p>

      <textarea
        className="col-md-12 col-12 forminput mt-3"
        value={newVolunteer.description}
        placeholder="Explain your experience there"
        style={{ minHeight: "200px" }}
        onChange={(e) => {
          setNewVolunteer((prev) => {
            return {
              ...prev,
              description: e.target.value,
            };
          });
        }}
      />
      <button
        className="continuebutton mt-4 mb-5"
        onClick={() => handleConfirm()}
      >
        Confirm
      </button>
    </div>
  );
};

export default AddVolunteer;
