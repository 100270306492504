import "./step2.css";

import { toast } from "react-toastify";

import InterestItems from "../../components/InterestItems/InterestItems";

const Step2 = ({
  selectedItem,
  setSelectedItem,

  setStep,
}) => {
  const handleFormSubmit = () => {
    if (selectedItem.length < 1) {
      toast.error("Please Add At least 1 Student Interests");
      return;
    } else {
      setStep(4);
    }
  };

  return (
    <div className="" style={{ width: "100%" }}>
      <div className=" ">
        <p
          className="BasicInforMationHeading"
          style={{ fontSize: "24px", fontWeight: "400", color: "#0C111D" }}
        >
          Challenge Categories
        </p>
        <p className="UnderBasicHeading">
          Categorize the challenge to attract the right students.
        </p>
        <p className="thumbnailcreate" style={{ marginTop: "2.5rem" }}>
          Student Interests
        </p>
        <p className="underthumbnailcreate" style={{ marginTop: "0.25rem" }}>
          Categorize challenge based on student interests. Add a maximum of 3
          interest fields.
        </p>
        <div style={{ marginTop: "0.75rem" }}>
          <InterestItems
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            TRUE={true}
          />
        </div>

        <hr
          style={{
            marginTop: "5.063rem",
            marginBottom: "1.25rem",
            zIndex: 0,
          }}
        />
        <div
          className="d-flex  "
          style={{ justifyContent: "space-between", gap: "10px" }}
        >
          <button className="backbutton" onClick={() => setStep(2)}>
            Back
          </button>
          <button className="continuebutton" onClick={() => handleFormSubmit()}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
export default Step2;
