import { useState } from "react";

const SameSection = ({
  isNonMobile1000,
  icon,
  title,
  description,
  chips,
  img,
  img2,
}) => {
  const test = false;

  return (
    <section
      id={`${title === "Virtual Experience" ? "Virtual-Experience" : title}`}
    >
      <div
        className="sameSectiom-outer d-flex transition"
        style={{
          justifyContent: "center",
        }}
      >
        <div className="headerCompany">
          <div
            className="d-flex"
            style={{
              background: "#FFF",
              flexDirection: isNonMobile1000 ? "row" : "column",
            }}
          >
            <div
              className="ShortListTalentouter"
              style={{
                width: isNonMobile1000 ? (test ? "30%" : "50%") : "100%",
              }}
            >
              <div>
                <div className="d-flex">{icon}</div>
                <p
                  className="ShortListTalenttitle"
                  style={{ marginTop: isNonMobile1000 ? "2.5rem" : "1.5rem" }}
                >
                  {title}
                </p>
                <p
                  className="gray95016500 text-start"
                  style={{
                    marginTop: isNonMobile1000 ? "1.5rem" : "1rem",
                    fontWeight: 400,
                  }}
                >
                  {description}
                </p>
                <div
                  className="d-flex flex-wrap"
                  style={{ marginTop: "2.5rem", gap: "0.75rem" }}
                >
                  {chips &&
                    chips.map((ch, index) => (
                      <div className="newdashboardsidechips" key={index}>
                        <img
                          src={ch.icon}
                          alt={ch.text}
                          height={"16px"}
                          width={"16px"}
                        />
                        {ch.text}
                      </div>
                    ))}
                </div>
                {img2 && (
                  <img
                    src={img2}
                    alt="img2"
                    style={{ width: "100%", maxWidth: "300px" }}
                  />
                )}
              </div>
            </div>
            <div
              className=""
              style={{
                width: isNonMobile1000 ? (test ? "70%" : "50%") : "100%",
              }}
            >
              {" "}
              <div style={{ position: "relative", height: "100%" }}>
                <img
                  src={img}
                  alt="mainimg"
                  className="img-fluid"
                  style={{ minHeight: "100%", objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </section>
  );
};
export default SameSection;
