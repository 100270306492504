import Carousel from "react-multi-carousel";
import ChallengeType from "../MainDashBoard/type";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const Forth = ({ company, challenges, navigate, color, organizer }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1440 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="" style={{ width: "100%", marginTop: "2.5rem" }}>
      {challenges && challenges.length > 0 && (
        <div className="" style={{ overflow: "visible" }} id="challenges">
          <div
            className="space flex-wrap align-items-center"
            style={{ gap: "1rem", alignItems: "center" }}
          >
            <div>
              <p className="registeration">{company?.title} Challenges</p>
              <p style={{ marginTop: "0.75rem" }} className="gray95014400">
                Showcase your skills with our interactive challenges and
                discover what it's like to work with us.{" "}
              </p>
            </div>
            {organizer.company === company.uniqueId && (
              <p
                className="black16 hovercolorback"
                style={{ color: color, cursor: "pointer" }}
                onClick={() => {
                  navigate("/dashboard/challenges");
                }}
              >
                See all Challenges
                <ArrowOutwardIcon style={{ height: "14px", width: "14px" }} />
              </p>
            )}
          </div>

          <div
            style={{
              marginTop: "2.5rem",
              gap: "1.25rem",
              overflow: "visible",
            }}
          >
            <Carousel responsive={responsive} className="p-2 pb-4 ">
              {challenges &&
                challenges.length > 0 &&
                challenges.map((challe, index) => (
                  <div
                    key={index}
                    className="ChallengesCardNew-Outer shadowa "
                    style={{ borderRadius: "5px", cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/preview/${challe.uniqueId}`);
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}${challe.selectedFile}`}
                      alt="challenge image"
                      className="img-fluid"
                      style={{
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        maxHeight: "6.875rem",
                        minHeight: "6.875rem",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />

                    <div className="ChallengesCardNew-Inner space flex-column">
                      <div>
                        {challe.company && (
                          <div
                            className="shadowa center align-items-center"
                            style={{
                              padding: "2px",
                              background: "#FFF",
                              borderRadius: "5px",
                              height: "48px",
                              width: "48px",
                              position: "absolute",
                              marginTop: "-46px",
                            }}
                          >
                            <img
                              src={company.img}
                              alt="card-bg"
                              style={{
                                maxHeight: "44px",
                                maxWidth: "44px",
                                height: "44px",
                                borderRadius: "3px",
                                objectFit: "cover",
                                width: "100%",
                              }}
                            />
                          </div>
                        )}
                        <p
                          className="gray60011400"
                          style={{ fontSize: "10px", marginTop: "0.5rem" }}
                        >
                          {JSON.parse(challe?.company)?.title}
                        </p>
                        <p
                          style={{ marginTop: "0.25rem" }}
                          className="gray95016500"
                        >
                          {challe.title}
                        </p>
                      </div>
                      <div>
                        <div className=" space align-items-center">
                          <ChallengeType type={challe?.type} />
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: "0.25rem" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.54938 7.79185C1.54938 4.89236 3.89989 2.54185 6.79938 2.54185C9.69888 2.54185 12.0494 4.89236 12.0494 7.79185C12.0494 10.6913 9.69888 13.0419 6.79938 13.0419C3.89989 13.0419 1.54938 10.6913 1.54938 7.79185ZM2.42438 7.79185C2.42438 10.2081 4.38314 12.1669 6.79938 12.1669C7.95971 12.1669 9.0725 11.7059 9.89298 10.8854C10.7134 10.065 11.1744 8.95217 11.1744 7.79185C11.1744 5.37561 9.21563 3.41685 6.79938 3.41685C4.38314 3.41685 2.42438 5.37561 2.42438 7.79185Z"
                                fill="#344054"
                              />
                              <path
                                d="M5.42855 5.56935C5.25617 5.40873 4.98755 5.41347 4.82094 5.58007C4.65433 5.74668 4.64959 6.0153 4.81022 6.18769L6.36188 7.73935V10.1252C6.36188 10.3668 6.55776 10.5627 6.79938 10.5627C7.04101 10.5627 7.23688 10.3668 7.23688 10.1252V7.55852C7.23678 7.44253 7.19062 7.33132 7.10855 7.24935L5.42855 5.56935Z"
                                fill="#344054"
                              />
                              <path
                                d="M12.3586 3.41685C11.6384 2.45639 10.6818 1.69876 9.58188 1.21769C9.47454 1.16903 9.3521 1.16574 9.2423 1.20856C9.13249 1.25138 9.04461 1.3367 8.99855 1.44519C8.94818 1.55231 8.94401 1.67542 8.98702 1.78571C9.03004 1.896 9.11645 1.98378 9.22605 2.02852C10.1868 2.45123 11.0225 3.11417 11.6527 3.95352C11.7353 4.06368 11.865 4.12852 12.0027 4.12852C12.0974 4.12895 12.1897 4.0982 12.2652 4.04102C12.3624 3.97213 12.4273 3.86661 12.4449 3.74882C12.4625 3.63103 12.4313 3.51115 12.3586 3.41685Z"
                                fill="#344054"
                              />
                              <path
                                d="M1.94022 3.94185C2.57044 3.10251 3.40615 2.43956 4.36688 2.01685C4.47648 1.97211 4.5629 1.88433 4.60591 1.77404C4.64892 1.66376 4.64476 1.54064 4.59438 1.43352C4.54832 1.32503 4.46044 1.23972 4.35064 1.19689C4.24083 1.15407 4.1184 1.15736 4.01105 1.20602C2.91195 1.69121 1.95732 2.4529 1.24022 3.41685C1.11421 3.60468 1.15215 3.85759 1.32772 4.00019C1.40325 4.05737 1.49549 4.08811 1.59022 4.08769C1.72298 4.09478 1.85177 4.04112 1.94022 3.94185Z"
                                fill="#344054"
                              />
                            </svg>
                            <p className="gray75010500">
                              {challe?.duration} mins
                            </p>
                          </div>
                        </div>
                        <div className="ChallengenewCarddescription ">
                          <p className="gray40012400">{challe.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      )}
    </div>
  );
};
export default Forth;
