import { useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import AllTouchpoints from "../AllTouchpoints";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setEmployer } from "../../../state";
import { setCompany as set } from "../../../state";
import axios from "axios";

import Talent from "../../../components/Company/Dashboard/EngagedTalent";
const ShortList = () => {
  const isNonMob1024 = useMediaQuery("(min-width:1024px)");

  const [results, setResults] = useState([]);
  const [user, setUser] = useState([]);
  const [company, setCompany] = useState();
  const [challenges, setChallenges] = useState([]);
  const id = useSelector((state) => state.uniqueId);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [livechallengesLength, setLiveChallengesLength] = useState(0);
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const [shortListChallenge, setShortListChallenge] = useState([]);
  const [shortListTalent, setShortListTalent] = useState([]);
  const [checker, setChecker] = useState([]);
  const [selected, setSelected] = useState(" Talent Pool");
  const [realRes, setRealRes] = useState([]);
  const com = useSelector((state) => state.company);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [DATA, setData] = useState(false);
  const [bulkAction, setBulkAction] = useState("");
  const [searchFilter, setSearchFilter] = useState("");

  const isNonMob1441 = useMediaQuery("(min-width:1500px)");

  const [selectedStudents, setSelectedStudents] = useState([]);

  const [analytics, setAnalytics] = useState("12 months");

  const [jobposts, setJobposts] = useState([]);
  const [blocked, setBlocked] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
          dispatch(setEmployer({ employer: userInfoResponse.data.user }));
        } else {
          navigate("/employer/register");
          return;
        }

        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: userInfoResponse.data.user.company }
        );
        if (companyInfoResponse.data.msg === "Logout") {
          navigate("/employer/register");
          return;
        }

        setCompany(companyInfoResponse.data);

        dispatch(set({ company: companyInfoResponse.data.uniqueId }));

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/company/challenges`,
          {
            uniqueId: companyInfoResponse.data.uniqueId,
          }
        );
        if (response.data.msg === "success") {
          setLiveChallengesLength(response.data.challenges.length);
          setChallenges(response.data.challenges);
        } else if (response.data.msg === "Record not found") {
          console.log("No record");
        } else {
          toast.error("Error Fetching Challenges");
        }

        const resultres = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/challenges/results`,
          {
            uniqueId: companyInfoResponse.data.uniqueId,
          }
        );

        const shortlistC = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/company/get/shortlist/student`,
          {
            companyId: companyInfoResponse.data.uniqueId,
            msg: "sdakfksjfbjkasfjkfdas",
            type: "Challenge",
            owner: userInfoResponse.data.user._id,
          }
        );
        console.log(shortlistC.data);

        if (shortlistC.data.msg === "success") {
          setShortListChallenge(shortlistC.data.list);
        }

        if (
          resultres.data.msg === "success" &&
          shortlistC.data.msg === "success"
        ) {
          setResults(() => {
            let check = resultres.data.results.filter((item) =>
              shortlistC.data.list.some((block) =>
                block.list.some((bl) => bl.user === item.user._id)
              )
            );
            return check;
          });
        } else {
          setResults([]);
          setRealRes([]);
        }

        const shortlistT = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/company/get/shortlist/student`,
          {
            companyId: companyInfoResponse.data.uniqueId,
            msg: "sdakfksjfbjkasfjkfdas",
            type: "Talentpool",
            owner: userInfoResponse.data.user._id,
          }
        );
        if (shortlistT.data.msg === "success") {
          setChecker([]);
          setShortListTalent(shortlistT.data.list);
          // setTotal(shortlistT?.data?.list[0]?.list?.length);
          shortlistT?.data?.list?.map((list) => {
            list.list.map((li) => {
              setChecker((prev) => [...prev, li.user]);
            });
          });
        } else {
          setChecker([]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, DATA]);

  const handleBulkActionChallenge = async () => {
    if (bulkAction === "Shortlist") {
      if (selectedStudents.length > 0) {
        selectedStudents.map(async (id) => {
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Challenge",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Added successfully");
        setSelectedStudents([]);
      }
    } else if (bulkAction === "Remove") {
      if (selectedStudents.length > 0) {
        selectedStudents.map(async (id) => {
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/remove/shortlist/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Talentpool",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Removed successfully");
        setSelectedStudents([]);
      }
    }
  };

  const handleBulkAction = () => {
    if (bulkAction === "Shortlist") {
      if (selectedStudents.length > 0) {
        selectedStudents.map(async (id) => {
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Talentpool",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Added successfully");
        setSelectedStudents([]);
      }
    } else if (bulkAction === "Remove") {
      if (selectedStudents.length > 0) {
        selectedStudents.map(async (id) => {
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/remove/shortlist/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Challenge",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Removed successfully");
        setSelectedStudents([]);
      }
    }
  };

  const handleRemove = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/remove/shortlist/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Talentpool",
          owner: user._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Removed");
          setData((prev) => !prev);
        }
      });
  };

  const handleShortList = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Talentpool",
          owner: user._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Added to Shortlist");
          setData((prev) => !prev);
        }
      });
  };

  const [total, setTotal] = useState(0);
  const [totalchal, setTotalChallenges] = useState(0);

  useEffect(() => {
    setTotalChallenges(results.length);
  }, [results, DATA]);

  return (
    <div className="paddingmaincontent">
      <div
        className={`transition ${isNonMobile && "d-flex"} `}
        style={{
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "10px",
        }}
      >
        <div className=" behindbuttons ">
          <button
            className={` border-0  d-flex  ${
              selected === " Talent Pool"
                ? "selectedbutton rounded-2 "
                : "secondbuttontext"
            } ${isNonMobile ? "" : "p-1  "}`}
            style={isNonMobile ? { minWidth: "188px" } : {}}
            onClick={() => {
              setSelected(" Talent Pool");
            }}
          >
            Talent Pool{" "}
            {total > 0 && <div className="countheading">{total}</div>}
          </button>
          <button
            className={`  border-0 d-flex ${
              selected === "Challenges"
                ? "selectedbutton rounded-2 "
                : "secondbuttontext"
            } ${isNonMobile ? "" : "p-1  "}`}
            style={
              isNonMobile
                ? {
                    minWidth: "188px",
                  }
                : {}
            }
            onClick={() => {
              setSelected("Challenges");
            }}
          >
            Challenges{" "}
            {totalchal > 0 && <div className="countheading">{totalchal}</div>}
          </button>
          <button
            className={`   border-0 ${
              selected === "Applications"
                ? "selectedbutton rounded-2 "
                : "secondbuttontext"
            } ${isNonMobile ? "" : "p-1  "}`}
            style={isNonMobile ? { minWidth: "188px" } : {}}
            onClick={() => {
              setSelected("Applications");
            }}
          >
            Applications
          </button>
        </div>
      </div>
      <div style={{ marginTop: "2.5rem" }}>
        {selected === "Challenges" && (
          <>
            {results && (
              <Talent
                results={results}
                livechallengesLength={livechallengesLength}
                selectedStudents={selectedStudents}
                setSelectedStudent={setSelectedStudents}
                DATA={DATA}
                setData={setData}
                company={company}
                user={user}
                bulkAction={bulkAction}
                setBulkAction={setBulkAction}
                handleBulkAction={handleBulkAction}
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                challenges={challenges}
              />
            )}
          </>
        )}

        {selected === " Talent Pool" && (
          <AllTouchpoints
            handleBulkAction={handleBulkActionChallenge}
            isNonMob768={isNonMobile}
            checker={checker}
            challenges={challenges}
            isNonMob1024={isNonMob1024}
            jobposts={jobposts}
            analytics={analytics}
            selectedStudents={selectedStudents}
            setSelectedStudents={setSelectedStudents}
            bulkAction={bulkAction}
            setBulkAction={setBulkAction}
            handleShortList={handleShortList}
            handleRemove={handleRemove}
            blocked={blocked}
            isNonMob1441={isNonMob1441}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            setTotal={setTotal}
            Reset={true}
          />
        )}
        {selected === "Applications" && (
          <Talent
            results={[]}
            TRUE={true}
            livechallengesLength={livechallengesLength}
            selectedStudents={selectedStudents}
            setSelectedStudent={setSelectedStudent}
            DATA={DATA}
            setData={setData}
            company={company}
            user={user}
            bulkAction={bulkAction}
            setBulkAction={setBulkAction}
            handleBulkAction={handleBulkAction}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            challenges={challenges}
          />
        )}
      </div>
    </div>
  );
};
export default ShortList;
