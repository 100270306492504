import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SuitCase from "../../assets/images/icons/Suitcase";

import AddPublication from "./AddPublication";
import PublicationList from "./PublicationList";
const Publications = ({
  user,

  setWatcher,
  watcher,
  axios,
  toast,
}) => {
  const [publication, setPublication] = useState(
    user?.publication ? user?.publication : []
  );

  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (user) {
      setPublication(user?.publication);
    }
  }, [user, popup]);

  const [addPublication, setAddPublication] = useState(false);
  const [newPublication, setNewPublication] = useState({
    title: "",
    date: "",
    description: "",
    link: "",
  });

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(-1);
  const handleConfirm = () => {
    if (!newPublication.title) {
      toast.error("Please enter Publication Title");
    } else if (!newPublication.date) {
      toast.error("Please enter Published Date");
    } else if (!newPublication.link) {
      toast.error("Please provide link for Publication");
    } else if (!newPublication.description) {
      toast.error("Please Provide Description");
    } else {
      setPublication((prevExperience) => {
        const updatedExperience = [...prevExperience];
        if (index === -1) {
          updatedExperience.push(newPublication);

          return updatedExperience;
        } else {
          updatedExperience[index] = newPublication;

          setIndex(-1);

          return updatedExperience;
        }
      });
      setNewPublication({
        title: "",
        date: "",
        description: "",
        link: "",
      });
      setAddPublication(false);
      setIndex(-1);
    }
  };
  useEffect(() => {
    setNewPublication({
      title: "",
      date: "",
      description: "",
      link: "",
    });
    setAddPublication(false);
    setIndex(-1);
  }, [popup]);

  const handleUpdate = async () => {
    if (publication.length === 0) {
      toast.error("Please add at least one publication.");
      return;
    }
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/update/student/publication`,
        { publication: publication },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Publications updated successfully");
          setPopup(false);
          setWatcher((prev) => {
            return {
              ...prev,
              publication: res.data.publication,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update Publications");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className=""
      style={{
        padding: "1.25rem",
        marginTop: "1.875rem",
        background: "#FFF",
        borderRadius: "5px",
      }}
    >
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            overflowY: "auto",
            zIndex: 1001,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
          onClick={() => {
            setPopup(false);
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            style={{
              background: "#FFF",
              padding: "0.5rem",
              maxWidth: "715px",
              width: "100%",
            }}
          >
            <div
              className="  newPopUpContainer tablescroll"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "90vh",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="space align-items-center">
                <p className="gray90020400">Publications</p>
                <CloseIcon
                  className="hoverdarkblacksvg"
                  style={{ cursor: "pointer", height: "24px", width: "24px" }}
                  onClick={() => {
                    setPopup(false);
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  background: "#EAECF0",
                  margin: "0.75rem 0rem 1.5rem 0rem",
                }}
              />
              {addPublication ? (
                <AddPublication
                  newPublication={newPublication}
                  setNewPublication={setNewPublication}
                  addPublication={addPublication}
                  setAddPublication={setAddPublication}
                  handleConfirm={handleConfirm}
                  toast={toast}
                />
              ) : (
                <PublicationList
                  setAddPublication={setAddPublication}
                  loading={loading}
                  publication={publication}
                  setNewPublication={setNewPublication}
                  setIndex={setIndex}
                  handleUpdate={handleUpdate}
                  setPublication={setPublication}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="space gap-3 align-items-center flex-wrap">
        <p className="gray90020400">Publications</p>
        {user?._id === watcher?._id && (
          <EditIcon
            className="hoverred"
            style={{ cursor: "pointer", height: "20px", width: "20px" }}
            onClick={() => {
              setPopup(true);
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "100%",
          height: "1px",
          background: "#EAECF0",
          margin: "0.75rem 0rem 1.5rem 0rem",
        }}
      />

      {user?.publication?.length > 0 ? (
        <div className="d-flex flex-column gap-4">
          {user?.publication.map((pub, index) => (
            <div key={index}>
              <div className=" align-items-center space flex-wrap gap-3">
                <div>
                  <p className="gray95016500">{pub?.title}</p>
                  <p className="mt-2 a314400">{pub?.date}</p>
                  <p className="gray50014400 mt-2">{pub?.description}</p>
                </div>

                <button
                  className="backbutton"
                  onClick={() =>
                    window.open(
                      `${pub?.link.startsWith("http") ? "" : "https://"}${
                        pub?.link
                      }`,
                      "_blank"
                    )
                  }
                >
                  Show Publication
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div className="center">
            <SuitCase />
          </div>
          <p
            className="gray40016500 text-center my-2"
            style={{ color: "#FD6F8E" }}
          >
            No Publications Yet
          </p>

          <div className="center">
            <p
              className="text-center gray60011400"
              style={{ maxWidth: "340px", fontSize: "14px" }}
            >
              <span
                className="gray60011400"
                style={{ fontSize: "14px", fontWeight: 500 }}
              >
                Add your Publications.
              </span>{" "}
              It's for sure will help you to find Jobs.
            </p>
          </div>
          <p
            className="pink16 text-center mt-3 hovercolorback"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPopup(true);
            }}
          >
            Add Publications
          </p>
        </div>
      )}
    </div>
  );
};

export default Publications;
