const BlackWhiteLogo = () => {
  return (
    <div
      style={{
        height: "1.5rem",
        width: "1.5rem",
        background: "rgba(255, 255, 255, 0.80)",
      }}
      className="rounded-1 d-flex flex-column justify-content-end"
    >
      <p
        className=" text-end"
        style={{ fontSize: "12px", paddingRight: "2px", color: "#0C111D" }}
      >
        /v
      </p>
    </div>
  );
};

export default BlackWhiteLogo;
