import { useEffect, useState } from "react";
import axios from "axios";

const Interaction = ({
  post,
  token,
  id,

  likes,
  totalComments,
  setLikes,
  setCommentShow,
}) => {
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    if (post && id) {
      likes.includes(id) ? setLiked(true) : setLiked(false);
    }
  }, [likes]);

  const handleLikePost = async () => {
    let newLikeStatus = !liked;
    setLiked(newLikeStatus);

    if (newLikeStatus) {
      likes.push(id);
    } else {
      setLikes(likes.filter((like) => like !== id));
    }
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/post/like`,
        {
          postId: post._id,
          newLikeStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div className="space" style={{ gap: "2rem" }}>
      <div className="d-flex align-items-center" style={{ gap: "1.25rem" }}>
        <div className="d-flex align-items-center" style={{ gap: "0.625rem" }}>
          <div
            className="d-flex align-items-center hoverredsvgstroke"
            style={{ minWidth: "24px", minHeight: "24px", cursor: "pointer" }}
            onClick={() => {
              handleLikePost();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill={liked ? "#E31b54" : "none"}
            >
              <path
                d="M12.62 20.8101C12.28 20.9301 11.72 20.9301 11.38 20.8101C8.48 19.8201 2 15.6901 2 8.6901C2 5.6001 4.49 3.1001 7.56 3.1001C9.38 3.1001 10.99 3.9801 12 5.3401C13.01 3.9801 14.63 3.1001 16.44 3.1001C19.51 3.1001 22 5.6001 22 8.6901C22 15.6901 15.52 19.8201 12.62 20.8101Z"
                stroke={liked ? "#E31b54" : "#475467"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <p className="gray70014400">
            {likes.length > 1000 ? likes.length / 1000 + "k" : likes.length}{" "}
            Like{likes.length === 1 ? "" : "s"}
          </p>
        </div>
        <div
          className={`d-flex align-items-center  gray70014400 ${
            totalComments > 0 && "hoverred hoverredsvgstroke"
          }  `}
          style={{ gap: "0.625rem", cursor: totalComments > 0 && "pointer" }}
          onClick={() => {
            if (totalComments > 0) {
              setCommentShow((prev) => !prev);
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M7.66797 18.4297H11.668L16.118 21.3897C16.778 21.8297 17.668 21.3597 17.668 20.5597V18.4297C20.668 18.4297 22.668 16.4297 22.668 13.4297V7.42969C22.668 4.42969 20.668 2.42969 17.668 2.42969H7.66797C4.66797 2.42969 2.66797 4.42969 2.66797 7.42969V13.4297C2.66797 16.4297 4.66797 18.4297 7.66797 18.4297Z"
              stroke="#475467"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {totalComments > 1000 ? totalComments / 1000 + "k" : totalComments}{" "}
          Comment{totalComments === 1 ? "" : "s"}
        </div>
      </div>
    </div>
  );
};

export default Interaction;
