import "./taskstep5.css";
const TaskStep5 = ({ taskCount, newTasks, setNewTasks, setTaskSteps }) => {
  const handleSliderChange = (event) => {
    const updatedTasks = [...newTasks];
    updatedTasks[taskCount].time = event.target.value;
    setNewTasks(updatedTasks);
  };

  return (
    <div className="" style={{ width: "100%" }}>
      <div className="">
        <p className="BasicInforMationHeading">Define challenge tasks</p>
        <p className="UnderBasicHeading" style={{ marginTop: "0.25rem" }}>
          What is this challenge about?
        </p>
        <hr style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }} />
        <p className="BasicInforMationHeading">Expected Duration</p>
        <div
          className="d-flex flex-wrap"
          style={{ gap: "1rem", marginTop: "4rem" }}
        >
          <p className="underthumbnailcreate">Expected time to complete</p>
          <div className="slider-container">
            <input
              type="range"
              min="5"
              max="60"
              step="5"
              value={newTasks[taskCount].time}
              onChange={handleSliderChange}
              className="custom-slider shadowa"
              style={{
                background: `linear-gradient(to right, #E31B53 0%, #E31B53 ${
                  ((newTasks[taskCount].time - 5) / 55) * 100
                }%, white ${
                  ((newTasks[taskCount].time - 5) / 55) * 100
                }%, white 100%)`,
              }}
            />
            <div
              className="slider-value p-1 rounded-3 border border-1 shadowa"
              style={{
                top: "-35px",
                left: `calc(${
                  ((newTasks[taskCount].time - 5) / 55) * 100
                }% - 10px)`,
                transform: "translateX(-50%)",
                color: "#344054",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "Poppins",
                position: "absolute",
                background: "#FFF",
              }}
            >
              {newTasks[taskCount].time} {" mins"}
            </div>
          </div>
        </div>
        <div>
          <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
          <div
            className="d-flex flex-wrap"
            style={{ justifyContent: "end", gap: "1.25rem" }}
          >
            <button className="backbutton" onClick={() => setTaskSteps(3)}>
              Back
            </button>
            <button className="continuebutton" onClick={() => setTaskSteps(0)}>
              Continue
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M6.66669 17.2917C6.83257 17.2925 6.99178 17.2264 7.10835 17.1083L13.775 10.4417C14.0187 10.1977 14.0187 9.80235 13.775 9.55833L7.10835 2.89167C6.8621 2.6622 6.47835 2.66897 6.24034 2.90698C6.00233 3.14499 5.99555 3.52874 6.22502 3.775L12.45 10L6.22502 16.225C5.98131 16.469 5.98131 16.8643 6.22502 17.1083C6.34159 17.2264 6.5008 17.2925 6.66669 17.2917Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskStep5;
