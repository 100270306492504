import UniSelect from "../../pages/Home/UniSelect";
const HomeFourthSection = ({ selectedUni, setSelectedUni }) => {
  return (
    <div className="d-flex" style={{ justifyContent: "center" }}>
      <div
        className="forthsectionpadding headerCompany d-flex"
        style={{ justifyContent: "center" }}
      >
        <div className="fourthsectionhome ">
          <div>
            <p className=" sparkInterest text-center">
              Register <span>Now</span>
            </p>
            <p className="undercase2 text-center">
              <span className="undercase2 ">Start your journey. </span>
              <br />
              Join students{" "}
              <span className="undercase3">around the world </span>
              <br /> and kickstart{" "}
              <span className="undercase2">your career.</span>
            </p>{" "}
            <div className="center mt-3">
              <UniSelect
                setSelectedUni={setSelectedUni}
                selectedUni={selectedUni}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFourthSection;
