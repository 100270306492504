import "./preview.css";
import { useMediaQuery } from "@mui/material";

import { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate, useParams } from "react-router-dom";

import Header from "../../components/CompanyHome/Header";
import LogHeader from "./LoggedInHeader";

import ThirdSection from "../Challenge/PreviewPage/ThirdSection/ThirdSection";
import FourthSection from "../Challenge/PreviewPage/FourthSection/FourthSection";
import FifthSection from "../Challenge/PreviewPage/FifthSection/FifthSection";
import HomeFooter from "../../components/Home/HomeFooter";

import NewFirstSection from "../Challenge/PreviewPage/NewFirstSection";
import NewSecondSection from "../Challenge/PreviewPage/SecondSection/NewSecondSection";
import TaskSection from "../Challenge/PreviewPage/Tasksection/TaskSection";
import { toast } from "react-toastify";
const PreviewPage = ({ user }) => {
  const Userloggedin = !!localStorage.getItem("token");

  const check = false;

  const [challengeUser, setChallengeUser] = useState({});
  const [Test, setTest] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [skills, setSkills] = useState([]);
  const [learns, setLearns] = useState([]);
  const [title, setTitle] = useState("");
  const [newTasks, setNewTasks] = useState([]);
  const [description, setDescription] = useState("");
  const [studentInfo, setStudentInfo] = useState("");
  const [information, setInformation] = useState("");
  const [type, setType] = useState("Virtual Experience");

  const [difficulty, setDifficulty] = useState("Basic");
  const [tools, setTools] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [company, setCompany] = useState();
  const [totalTime, setTotalTime] = useState(0);
  const { uniqueId } = useParams();

  const [canTake, setCanTake] = useState(true);
  const [reason, setReason] = useState("");
  const StartChallenge = async () => {
    if (!canTake) {
      navigate("/challenge/attempt/" + uniqueId);
    } else {
      toast.error("You can no longer take part in this challenge");
    }
  };

  useEffect(() => {
    if (uniqueId) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/get/specific/challenge`, {
          params: { uniqueId: uniqueId },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.msg == "success") {
            setSelectedFile(res.data.challenge.selectedFile);
            setSkills(res.data.challenge.skills);
            setLearns(res.data.challenge.learns);
            setTitle(res.data.challenge.title);
            setDescription(res.data.challenge.description);
            setType(res.data.challenge.type);

            setDifficulty(res.data.challenge.difficulty);
            setTools(res.data.challenge.tools);
            setInformation(res.data.challenge.information);
            setSelectedVideo(res.data.challenge.selectedVideo);
            setCompany(JSON.parse(res.data.challenge.company));
            setChallengeUser(JSON.parse(res.data.challenge.user));
            setStudentInfo(res.data.challenge.studentInfo);
            setNewTasks(res.data.challenge.alltask);

            setCanTake(res.data.canTake.canTake);
            setReason(res.data.canTake.reason);

            let total = 0;
            res.data.challenge.alltask.map((task) => {
              total = total + parseInt(task.time);
            });

            setTotalTime(total);
          } else {
            navigate("/student/challenges");
          }
        })
        .catch((err) => console.log(err));
    } else {
      navigate("/student/challenges");
    }
  }, []);

  const isNonMob1024 = useMediaQuery("(min-width:1024px)");
  const maxLines = 4;
  const navigate = useNavigate();

  const getClampedText = (text, maxLines) => {
    const lineHeight = 1.2;
    const maxHeight = maxLines * lineHeight;

    return {
      maxHeight: `${maxHeight}em`,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: maxLines,
    };
  };
  const [color, setColor] = useState("#E31b54");

  useEffect(() => {
    if (company?.color) {
      setColor(company?.color);
    }
  }, [company]);

  return (
    <div style={{ maxWidth: "100vw", minHeight: "100vh", overflowX: "clip" }}>
      <div>
        {Userloggedin ? (
          <LogHeader user={user} />
        ) : (
          <Header setTest={setTest} Test={Test} headerback={true} />
        )}
        <div style={{ marginTop: "50px" }}>
          <NewFirstSection
            check={check}
            selectedFile={
              selectedFile && selectedFile?.includes("/uploads/")
                ? selectedFile
                : `/uploads/${selectedFile}`
            }
            company={company}
            StartChallenge={StartChallenge}
            type={type}
            title={title}
            description={description}
            test={true}
            Userloggedin={Userloggedin}
            uniqueId={uniqueId}
            // checker={checker}
            color={color}
          />
          <div>
            <ThirdSection
              selectedVideo={`${selectedVideo}`}
              user={challengeUser}
              type={type}
              company={company}
              learns={learns}
              color={color}
            />
          </div>
          <div>
            <NewSecondSection
              difficulty={difficulty}
              title={title}
              information={information}
              tools={tools}
              studentInfo={studentInfo}
              company={company}
              color={color}
              newTasks={newTasks}
            />
            <div
              className="marginsleftrightCompany"
              id="skills"
              style={{ padding: "4rem 0rem" }}
            >
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <div
                  className={`headerCompany`}
                  style={{ padding: "3.75rem 0rem" }}
                >
                  <div>
                    <p
                      className="black16 text-start"
                      style={{
                        color: color,
                      }}
                    >
                      Skills you will learn
                    </p>
                    <div
                      className="space "
                      style={{
                        gap: isNonMob1024 ? "2.875rem" : "1rem",

                        flexDirection: isNonMob1024 ? "row" : "column",
                      }}
                    >
                      <div style={{ width: isNonMob1024 ? "36.3%" : "100%" }}>
                        <div>
                          <p
                            className="sectionSectiontitle"
                            style={{ wordBreak: "keep-all" }}
                          >
                            Advance skills for your future career
                          </p>
                          <p
                            className="welcometo"
                            style={{ marginTop: "0.75rem" }}
                          >
                            Learn, apply and expand conceptual and technical
                            skills in this challenge relevant to your career
                            start.
                          </p>
                        </div>
                      </div>

                      <div style={{ width: isNonMob1024 ? "59%" : "100%" }}>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "0.875rem 1rem" }}
                        >
                          {learns &&
                            learns.map((ski, i) => (
                              <div
                                key={i}
                                className=" skillsshow d-flex"
                                style={{ cursor: "pointer" }}
                              >
                                <p className="skillsshowtext"> {ski}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="newtasksbackground">
            <TaskSection
              newTasks={newTasks}
              StartChallenge={StartChallenge}
              color={color}
              Userloggedin={Userloggedin}
              check={check}
              uniqueId={uniqueId}
              test={true}
            />
          </div>
          <div style={{ maxWidth: "100vw" }}>
            <FourthSection
              type={type}
              test={true}
              totalTime={totalTime}
              difficulty={difficulty}
              totalSkills={skills.length}
              totalLearns={learns.length}
            />
          </div>
          <div>
            <FifthSection
              company={company}
              StartChallenge={StartChallenge}
              test={true}
              type={type}
              Userloggedin={Userloggedin}
              uniqueId={uniqueId}
              color={color}
            />
          </div>
          <div className="marginsleftrightCompany mt-5 pt-5">
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <div className="headerCompany">
                {Userloggedin ? (
                  <>
                    {" "}
                    <div
                      className="d-flex mb-5"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="d-flex" style={{ width: "90vw" }}>
                        <div
                          className="center"
                          style={{
                            height: "100%",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <div
                            className=" shadowa center"
                            style={{
                              borderRadius: "72px",
                              height: "80px",
                              width: "80px",
                              padding: "1px",
                              background: "#FFF",
                              alignItems: "center",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={user?.profilePhoto}
                              alt="profile"
                              className="img-fluid"
                              style={{ borderRadius: "72px" }}
                            />
                          </div>
                        </div>
                        <div className="m-3 mt-0 mb-0">
                          <p className="maybeLater">
                            Maybe later?
                            <button
                              className="backtosearch btn p-0 my-2"
                              onClick={() => navigate("/student/challenges")}
                            >
                              Back to Search.
                            </button>
                          </p>
                          <p
                            className="col-md-5 black14"
                            style={{ fontWeight: "400" }}
                          >
                            Solve real-world challenges designed by investment
                            banks, law firms, consulting firms and industry
                            leaders across the globe.
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <HomeFooter />
                )}
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};
export default PreviewPage;
