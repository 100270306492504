import { toast } from "react-toastify";
import React from "react";
const AddCertificate = ({
  newCertificate,
  setNewCertificate,

  handleConfirm,
}) => {
  const fileInputRef = React.createRef();

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      setNewCertificate((prev) => {
        return {
          ...prev,
          logo: file,
        };
      });
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div>
      <p className="black14 mt-4">Title</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newCertificate?.name}
        placeholder="Enter Certificates title"
        onChange={(e) => {
          setNewCertificate((prev) => {
            return {
              ...prev,
              name: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Institute Logo(optional):</p>
      <div className="mt-3 border p-4 rounded-3">
        {newCertificate?.logo && (
          <div className="center">
            <img
              src={
                newCertificate?.logo instanceof File
                  ? URL.createObjectURL(newCertificate?.logo)
                  : newCertificate?.logo
              }
              alt="selectefFile"
              className="rounded-2"
              style={{ maxHeight: "200px", maxWidth: "200px" }}
            />
          </div>
        )}

        <div style={{ gap: "5px", justifyContent: "center" }}>
          <p
            className="text-center clicktoupload"
            onClick={openFileInput}
            style={{ cursor: "pointer" }}
          >
            {newCertificate?.logo
              ? "Click to change Image"
              : "Click to upload Image"}
          </p>
          <p className="svgpngtext">SVG, PNG, JPG or GIF (max. 12MB)</p>
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
      </div>
      <p className="black14 mt-4">Issued Date:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newCertificate?.issued}
        type="date"
        placeholder="Enter Issued Date"
        onChange={(e) => {
          setNewCertificate((prev) => {
            return {
              ...prev,
              issued: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Credential ID:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newCertificate?.credentialId}
        type="text"
        placeholder="Enter credential id"
        onChange={(e) => {
          setNewCertificate((prev) => {
            return {
              ...prev,
              credentialId: e.target.value,
            };
          });
        }}
      />

      <p className="black14 mt-4">Link url:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newCertificate?.link}
        type="text"
        placeholder="Provide Link"
        onChange={(e) => {
          setNewCertificate((prev) => {
            return {
              ...prev,
              link: e.target.value,
            };
          });
        }}
      />

      <button
        className="continuebutton mt-4 mb-5"
        onClick={() => handleConfirm()}
      >
        Confirm
      </button>
    </div>
  );
};
export default AddCertificate;
