import React, { useState } from "react";
import on from "../../assets/images/on.svg";
import off from "../../assets/images/off.png";
import axios from "axios";
import { toast } from "react-toastify";

const NotificationToggle = ({
  category,
  type,
  label,
  settings,
  setSettings,
}) => (
  <div
    className="d-flex "
    style={{
      cursor: "pointer",
      gap: "1rem",
      marginTop: type !== "push" ? "1rem" : "0",
    }}
    onClick={() => {
      setSettings((prevSettings) => ({
        ...prevSettings,
        [category]: {
          ...prevSettings[category],
          [type]: !prevSettings[category][type],
        },
      }));
    }}
  >
    {settings[category][type] ? (
      <img src={on} alt="" width="36px" height="20px" />
    ) : (
      <img src={off} alt="" width="36px" height="20px" />
    )}
    <p className="gray14new">{label}</p>
  </div>
);

const Privacy = ({ user }) => {
  const [settings, setSettings] = useState(
    user?.privacy ?? {
      Education: { Everyone: false, Alumni: false, NoStudents: false },
      Experience: { Everyone: false, Alumni: false, NoStudents: false },
      OtherProfileSections: {
        Everyone: false,
        Alumni: false,
        NoStudents: false,
      },
      YourGroups: { Everyone: false, Alumni: false, NoStudents: false },
      YourProfile: {
        AllCompanies: false,
        FollowedCompanies: false,
        InteractedCompanies: false,
      },
      ChallengePerformance: {
        AllCompanies: false,
        FollowedCompanies: false,
        InteractedCompanies: false,
      },
      YourCV: { SubmitIndividually: false, AutoSubmit: false },
    }
  );

  const renderSection = (category, title, description, options) => (
    <div
      className="space flex-wrap"
      style={{ gap: "1rem", marginBottom: "1.5rem" }}
    >
      <div style={{ maxWidth: "280px" }}>
        <p className="black14">{title}</p>
        <p className="gray14new" style={{ marginTop: "0.25rem" }}>
          {description}
        </p>
      </div>
      <div style={{ maxWidth: "287px", minWidth: "280px" }}>
        {options.map((option) => (
          <NotificationToggle
            key={option.type}
            type={option.type}
            label={option.label}
            settings={settings}
            setSettings={setSettings}
            category={category}
          />
        ))}
      </div>
    </div>
  );

  const handleSubmit = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/student/settings/privacy`,
        settings,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.msg === "success") {
          toast.success("Settings saved successfully");
          window.location.reload();
        } else {
          toast.error("Error while saving settings");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <p className="black14black" style={{ fontWeight: "500" }}>
        Privacy Settings <span className="pink14">for Other Students</span>
      </p>
      <p className="gray14new" style={{ marginTop: "0.25rem" }}>
        We may still send you important notifications about your account outside
        of your notification settings.
      </p>
      <hr
        style={{
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
          border: "none",
          borderTop: "1px solid #E4E6E8",
        }}
      />
      <div style={{ maxWidth: "700px" }}>
        {renderSection(
          "Education",
          "Education",
          "Who will see your “Education” profile section.",
          [
            { type: "Everyone", label: "Everyone" },
            { type: "Alumni", label: "Alumni" },
            { type: "NoStudents", label: "No Students" },
          ]
        )}
        <hr
          style={{
            marginTop: "2.5rem",
            marginBottom: "2.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        {renderSection(
          "Experience",
          "Experience",
          "Who will see your “Experience” profile section.",
          [
            { type: "Everyone", label: "Everyone" },
            { type: "Alumni", label: "Alumni" },
            { type: "NoStudents", label: "No Students" },
          ]
        )}
        <hr
          style={{
            marginTop: "2.5rem",
            marginBottom: "2.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        {renderSection(
          "OtherProfileSections",
          "Other Profile Sections",
          "Who will see other profile sections (Certificates, Awards & Scholarships, Volunteering & Leadership, Publications, Test Scores).",
          [
            { type: "Everyone", label: "Everyone" },
            { type: "Alumni", label: "Alumni" },
            { type: "NoStudents", label: "No Students" },
          ]
        )}
        <hr
          style={{
            marginTop: "2.5rem",
            marginBottom: "2.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        {renderSection(
          "YourGroups",
          "Your Groups",
          "Who can invite you to groups.",
          [
            { type: "Everyone", label: "Everyone" },
            { type: "Alumni", label: "Alumni" },
            { type: "NoStudents", label: "No Students" },
          ]
        )}

        <p className="black14black">
          Privacy Settings <span className="pink14">for Companies</span>
        </p>
        <p className="gray14new" style={{ marginTop: "0.25rem" }}>
          How will potential employers see your profile?
        </p>
        <hr
          style={{
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        <hr
          style={{
            marginTop: "2.5rem",
            marginBottom: "2.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        {renderSection("YourProfile", "Your Profile", "Companies will see", [
          { type: "AllCompanies", label: "All Companies" },
          { type: "FollowedCompanies", label: "Only Companies which I follow" },
          {
            type: "InteractedCompanies",
            label:
              "Only Companies which I have interacted with via Messenger, Challenge, Events or Job Search.",
          },
        ])}
        <hr
          style={{
            marginTop: "2.5rem",
            marginBottom: "2.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        {renderSection(
          "ChallengePerformance",
          "Challenge Performance",
          "Once you have completed enough Fast-Track Challenges in a certain field (e.g. Finance) you will show up for companies as a recommendation.",
          [
            { type: "AllCompanies", label: "All Companies" },
            {
              type: "FollowedCompanies",
              label: "Only Companies which I follow",
            },
            {
              type: "InteractedCompanies",
              label:
                "Only Companies which I have interacted with via Messenger, Challenge, Events or Job Search.",
            },
          ]
        )}
        <hr
          style={{
            marginTop: "2.5rem",
            marginBottom: "2.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        {renderSection(
          "YourCV",
          "Your CV",
          "This is your default setting. You will be asked upon challenge completion again.",
          [
            {
              type: "SubmitIndividually",
              label:
                "I will submit individually where required (e.g. Event Registration or Advanced Challenges)",
            },
            {
              type: "AutoSubmit",
              label: "Auto-Submit for Advanced Challenges Challenges.",
            },
          ]
        )}
        <hr
          style={{
            marginTop: "7rem",
            marginBottom: "1.5rem",
            border: "none",
            borderTop: "1px solid #E4E6E8",
          }}
        />
        <div className="d-flex justify-content-end mb-5 pb-5">
          <button className="continuebutton" onClick={() => handleSubmit()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default Privacy;
