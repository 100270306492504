const MobileNav = ({ buttons, setSelected, selected, navigate }) => {
  return (
    <div
      className="topshadow"
      style={{
        width: "100vw",
        background: "#FFF",
        padding: "1rem 2rem",
      }}
    >
      <div className="space align-items-center" style={{ gap: "0.5rem" }}>
        {buttons.map((btn, index) => (
          <button
            key={index}
            className="student-header-button"
            style={{ color: selected === btn.name ? "#E31b54" : "" }}
            onClick={() => {
              setSelected(btn.name);
              if (btn.name === "Feed") {
                navigate("/student");
              } else {
                navigate(`/student/${btn.name}`);
              }
            }}
          >
            {btn.icon}
            {btn.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MobileNav;
