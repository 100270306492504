import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import Certificate from "../ChallengeResult/Certificate";
import html2canvas from "html2canvas";
const MainCertificate = () => {
  const { id } = useParams();
  const certificateRef = useRef();
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(true);
  const [average, setAverage] = useState(null);
  const imageRef = useRef();
  const [certificateImage, setCertificateImage] = useState(null);

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const navigate = useNavigate();
  const fetchResults = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/get/challenge/results/cert`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        }
      );
      const { data } = response;
      if (data.msg === "success") {
        setResult(data.result);
        setAverage(data.average);
      } else {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error fetching results", error);
      navigate(-1);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchResults();
    }
  }, [id]);

  const generateCertificateImage = () => {
    const certificateElement = certificateRef.current;

    if (certificateElement) {
      html2canvas(certificateElement, { scale: 2, useCORS: true }).then(
        (canvas) => {
          const imgData = canvas.toDataURL("image/png"); // Convert canvas to image data
          setCertificateImage(imgData);
        }
      );
    }
  };

  useEffect(() => {
    if (result && result.preview) {
      generateCertificateImage();
    }
  }, [result]);

  if (loading) {
    return (
      <div
        className="center align-items-center flex-column"
        style={{ height: "100vh", width: "100vw" }}
      >
        <p className="text-center m-3 black16">
          Fetching Results
          <br /> Please wait ...
        </p>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          position: "absolute", // Ensure it is placed outside the visible view
          top: "-9999px", // Move it out of the visible viewport
          left: "-9999px", // Ensure it's far off-screen
        }}
      >
        <Certificate
          company={JSON.parse(result?.preview?.company)}
          result={result}
          average={average}
          certificateRef={certificateRef}
        />
      </div>
      {certificateImage ? (
        <div>
          <img
            ref={imageRef}
            src={certificateImage}
            alt="Certificate"
            style={{ width: "100vw", height: "auto" }}
            onContextMenu={handleContextMenu}
          />
        </div>
      ) : (
        <p>Generating certificate...</p>
      )}
    </div>
  );
};

export default MainCertificate;
