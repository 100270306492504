import { useEffect, useState } from "react";
import SuitCase from "../../assets/images/icons/Suitcase";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import AddEducation from "./AddExperience";
import EducationList from "./EducationList";
import { toast } from "react-toastify";
import CompanyImage from "../../components/Company/CompanyImage";

const Education = ({ user, dasdads, setWatcher, watcher, axios }) => {
  const [education, setEducation] = useState(
    user?.education ? user?.education : []
  );
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (user) {
      setEducation(user?.education);
    }
  }, [user, popup]);

  const [addEducation, setAddEducation] = useState(false);

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(-1);

  const [newEducation, setNewEducation] = useState({
    logo: null,
    degree: "",
    from: "",
    to: "",
    location: "",
    schoolName: "",
    description: "",
  });

  const handleUpdateEducation = async () => {
    if (education.length === 0) {
      toast.error("Please add at least one Education.");
      return;
    }

    const formData = new FormData();

    education.forEach((exp, index) => {
      formData.append(`education[${index}][schoolName]`, exp?.schoolName);
      formData.append(`education[${index}][degree]`, exp?.degree);
      formData.append(`education[${index}][from]`, exp?.from);
      formData.append(`education[${index}][to]`, exp?.to);
      formData.append(
        `education[${index}][location]`,
        JSON.stringify(exp?.location)
      );
      formData.append(`education[${index}][description]`, exp?.description);
      if (exp?.logo) {
        formData.append(`education[${index}][logo]`, exp.logo); // Only if logo is present as a file
      }
    });

    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/update/student/education`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Education updated successfully");
          setPopup(false);
          setWatcher((prev) => {
            return {
              ...prev,
              education: res.data.educations,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update Education");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleConfirm = () => {
    if (!newEducation?.schoolName) {
      toast.error("Please enter company name");
    } else if (!newEducation?.from) {
      toast.error("Please enter from date");
    } else if (!newEducation?.to) {
      toast.error("Please enter to date");
    } else if (!newEducation?.degree) {
      toast.error("Please enter position");
    } else if (!newEducation?.description) {
      toast.error("Please Provide your experience at the institution");
    } else if (!newEducation?.location) {
      toast.error("Please select location");
    } else {
      setEducation((prevExperience) => {
        const updatedExperience = [...prevExperience];
        if (index === -1) {
          updatedExperience.push(newEducation);

          return updatedExperience;
        } else {
          updatedExperience[index] = newEducation;

          setIndex(-1);

          return updatedExperience;
        }
      });
      setNewEducation({
        logo: null,
        degree: "",
        from: "",
        to: "",
        location: "",
        schoolName: "",
        description: "",
      });
      setAddEducation(false);
      setIndex(-1);
    }
  };

  useEffect(() => {
    setNewEducation({
      logo: null,
      degree: "",
      from: "",
      to: "",
      location: "",
      schoolName: "",
      description: "",
    });
    setAddEducation(false);
    setIndex(-1);
  }, [popup]);

  return (
    <div
      className=""
      style={{
        padding: "1.25rem",
        marginTop: "1.875rem",
        background: "#FFF",
        borderRadius: "5px",
      }}
    >
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            overflowY: "auto",
            zIndex: 1001,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
          onClick={() => {
            setPopup(false);
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            style={{
              background: "#FFF",
              padding: "0.5rem",
              maxWidth: "715px",
              width: "100%",
            }}
          >
            <div
              className="  newPopUpContainer tablescroll"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "90vh",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="space align-items-center">
                <p className="gray90020400">Education</p>
                <CloseIcon
                  className="hoverdarkblacksvg"
                  style={{ cursor: "pointer", height: "24px", width: "24px" }}
                  onClick={() => {
                    setPopup(false);
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  background: "#EAECF0",
                  margin: "0.75rem 0rem 1.5rem 0rem",
                }}
              />
              {addEducation ? (
                <AddEducation
                  newEducation={newEducation}
                  setNewEducation={setNewEducation}
                  addEducation={addEducation}
                  setAddEducation={setAddEducation}
                  handleConfirm={handleConfirm}
                />
              ) : (
                <EducationList
                  setAddEducation={setAddEducation}
                  loading={loading}
                  education={education}
                  setNewEducation={setNewEducation}
                  setIndex={setIndex}
                  handleUpdateEducation={handleUpdateEducation}
                  setEducation={setEducation}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="space gap-3 align-items-center flex-wrap">
        <p className="gray90020400">Education</p>
        {user?._id === watcher?._id && (
          <EditIcon
            className="hoverred"
            style={{ cursor: "pointer", height: "20px", width: "20px" }}
            onClick={() => {
              setPopup(true);
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "100%",
          height: "1px",
          background: "#EAECF0",
          margin: "0.75rem 0rem 1.5rem 0rem",
        }}
      />
      {user?.education?.length > 0 ? (
        <>
          {user?.education?.map((exp, index) => (
            <div key={index} style={{ marginTop: "1.5rem" }}>
              <div className={` d-flex flex-column`} style={{ gap: "1rem" }}>
                {exp?.logo && (
                  <CompanyImage src={exp?.logo} /> // Access logo property from each experience object
                )}
                <div style={{ width: "100%" }}>
                  <div className="space flex-wrap" style={{ gap: "1rem" }}>
                    <p className="black16 text-start">{exp?.schoolName}</p>
                    <p className="gray14400">
                      {exp?.from}-{exp?.to}
                    </p>
                  </div>
                  <div
                    className="gray14400 d-flex flex-wrap"
                    style={{ gap: "0.5rem", alignItems: "center" }}
                  >
                    {exp?.location?.flag} {exp?.location?.name}
                    <div
                      className=""
                      style={{
                        background: "#667085",
                        height: "4px",
                        width: "4px",
                        borderRadius: "125px",
                      }}
                    />
                    {exp?.degree}
                  </div>
                  {exp?.description && (
                    <p className="gray14400" style={{ marginTop: "0.75rem" }}>
                      {exp?.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div>
          <div className="center">
            <SuitCase />
          </div>
          <p
            className="gray40016500 text-center my-2"
            style={{ color: "#FD6F8E" }}
          >
            No Education Yet
          </p>

          <div className="center">
            <p
              className="text-center gray60011400"
              style={{ maxWidth: "340px", fontSize: "14px" }}
            >
              <span
                className="gray60011400"
                style={{ fontSize: "14px", fontWeight: 500 }}
              >
                Add your Education.
              </span>{" "}
              It's for sure will help you to find Jobs.
            </p>
          </div>
          <p
            className="pink16 text-center mt-3 hovercolorback"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPopup(true);
            }}
          >
            Add Education
          </p>
        </div>
      )}
    </div>
  );
};
export default Education;
