import { useEffect, useRef, useState } from "react";
import EmptySpace from "../../../assets/images/EmptyState.svg";
import ChallengeType from "../../MainDashBoard/type";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";

const MyChallenges = ({
  startChallenge,
  completed,
  loading2,
  blocked,
  search,
  navigate,
}) => {
  const [challengestoshow, setChallengesToShow] = useState([
    ...startChallenge,
    ...completed,
  ]);

  useEffect(() => {
    if (search) {
      setChallengesToShow(() => {
        let arr = [];
        startChallenge.map((challenge) => {
          if (
            challenge?.details.title
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            arr.push(challenge);
          }
        });
        completed.map((challenge) => {
          if (
            challenge?.preview.title
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            arr.push(challenge);
          }
        });
        return arr;
      });
    } else {
      setChallengesToShow([...startChallenge, ...completed]);
    }
  }, [search]);

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  const [showIcon, setShowIcon] = useState(false);
  const [Index, setIndex] = useState(-1);
  const iconRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 6;
  let indexOfLastEntry = currentPage * entriesPerPage;
  let indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const [currentEntries, setCurrentEntries] = useState(
    challengestoshow.slice(indexOfFirstEntry, indexOfLastEntry)
  );

  let totalEntries = challengestoshow.length;
  let totalPages = Math.ceil(totalEntries / entriesPerPage);

  useEffect(() => {
    setChallengesToShow([...startChallenge, ...completed]);
  }, [startChallenge, completed]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowIcon(false);
        setIndex(-1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    setCurrentEntries(
      challengestoshow.slice(indexOfFirstEntry, indexOfLastEntry)
    );
  }, [currentPage, challengestoshow, indexOfFirstEntry, indexOfLastEntry]);

  const renderPageButtons = () => {
    const maxButtons = 5; // Maximum number of buttons to display
    const buttons = [];

    // Calculate the range of buttons to display around the current page
    let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxButtons - 1);

    // Adjust startPage and endPage if there are not enough buttons
    if (endPage - startPage + 1 < maxButtons) {
      if (currentPage <= Math.floor(maxButtons / 2) + 1) {
        endPage = Math.min(totalPages, maxButtons);
      } else {
        startPage = Math.max(1, totalPages - maxButtons + 1);
      }
    }

    // Render the buttons in the calculated range
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          style={
            i === currentPage
              ? { backgroundColor: "#E31B54", color: "#FFF" }
              : { color: "#000", backgroundColor: "transparent" } // Adjust colors as needed
          }
          onClick={() => handlePageChange(i)}
          className={`btn normalbuttons ${i === currentPage && " rounded-3"}`}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div>
      {loading2 ? (
        <p>Loading Data...</p>
      ) : (
        <div>
          <div
            className="tablescroll maindashboardtopcards "
            style={{ marginTop: "0.75rem", overflowX: "auto", width: "100%" }}
          >
            <table
              className="tableofchallenges"
              style={{ width: "100%", overflowX: "scroll" }}
            >
              <thead className="thead " style={{ width: "100%" }}>
                <tr className="theadtr">
                  <th className="theadth " style={{ minWidth: "200px" }}>
                    Company Name{" "}
                  </th>
                  <th className="theadth"> Challenge Title</th>
                  <th className="theadth ">Challenge Type</th>
                  <th className="theadth ">Status</th>
                  <th className="theadth ">Action</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {currentEntries &&
                  currentEntries.length > 0 &&
                  currentEntries.map((challenge, index) => (
                    <tr key={index} className="tbodytr">
                      <td
                        className="tbodytrtd d-flex align-items-start"
                        style={{ gap: "6px" }}
                      >
                        <img
                          src={
                            challenge?.preview
                              ? JSON?.parse(challenge?.preview?.company).img
                              : JSON?.parse(challenge?.details?.company).img
                          }
                          alt="company logo"
                          height={"30px"}
                          className="shadowa"
                          width={"30px"}
                          style={{
                            background: "#FFF",
                            padding: "1px",
                            borderRadius: "5px",
                          }}
                        />
                        <p
                          className="gray95014400 {
 "
                        >
                          {challenge?.preview
                            ? JSON.parse(challenge?.preview?.company).title
                            : JSON.parse(challenge.details.company).title}{" "}
                        </p>
                      </td>
                      <td
                        className="tbodytrtd gray95014400 "
                        style={{ minWidth: "200px" }}
                      >
                        {truncateString(
                          challenge?.preview
                            ? challenge?.preview?.title
                            : challenge.details?.title,
                          35
                        )}
                      </td>
                      <td
                        className="tbodytrtd d-flex"
                        style={{
                          minWidth:
                            challenge?.preview?.type === "Virtual Experience"
                              ? "185px"
                              : challenge.details?.type === "Virtual Experience"
                              ? "185px"
                              : "",
                        }}
                      >
                        <ChallengeType
                          type={
                            challenge?.preview
                              ? challenge?.preview?.type
                              : challenge.details?.type
                          }
                        />
                      </td>
                      <td className="tbodytrtd">
                        <p
                          className="d-flex align-items-center gap-2 gray95014400"
                          style={{ minWidth: "100px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="6"
                            viewBox="0 0 6 6"
                            fill="none"
                          >
                            <circle
                              cx="3"
                              cy="3"
                              r="3"
                              fill={
                                challenge?.details
                                  ? blocked.some(
                                      (b) => b === challenge.details.uniqueId
                                    )
                                    ? "red"
                                    : challenge?.details?.type === "Fast-Track"
                                    ? "red"
                                    : "#F97066"
                                  : "green"
                              }
                            />
                          </svg>

                          {challenge?.details
                            ? blocked.some(
                                (b) => b === challenge.details.uniqueId
                              )
                              ? "Blocked"
                              : challenge?.details?.type === "Fast-Track"
                              ? "Cancelled"
                              : "In Progress"
                            : "Completed"}
                        </p>
                      </td>
                      <td className="tbodytrtd">
                        <div
                          className="center"
                          style={{ position: "relative" }}
                        >
                          <div style={{ position: "absolute" }}>
                            {showIcon && Index === index && (
                              <div
                                style={{
                                  position: "absolute",
                                  background: "transparent",
                                  zIndex: 10,
                                  right: 0,
                                  top: 0,
                                  marginTop: "20px",
                                }}
                                ref={iconRef}
                              >
                                <div
                                  className="d-flex shadowa"
                                  style={{
                                    flexDirection: "column",
                                    background: "transparent",
                                  }}
                                >
                                  {challenge?.details ? (
                                    blocked.some(
                                      (b) => b === challenge.details.uniqueId
                                    ) ? null : challenge?.details?.type ===
                                      "Fast-Track" ? null : (
                                      <button
                                        className="d-flex popuptext "
                                        style={{
                                          padding: "11px 1rem",
                                          maxHeight: "2rem",
                                          borderRadius: "5px 5px 0px 0px",
                                          gap: "10px",
                                          border: "none",
                                          borderBottom: "1px solid #d9d9d9",
                                          background: "#FFF",

                                          width: "160px",
                                          minHeight: "42px",
                                        }}
                                        onClick={() => {
                                          setShowIcon(false);

                                          setIndex(-1);
                                          navigate(
                                            `/challenge/attempt/${challenge.details.uniqueId}`
                                          );
                                        }}
                                      >
                                        Continue
                                      </button>
                                    )
                                  ) : (
                                    <a
                                      href={`/challenge/result/${challenge?.uniqueId}`}
                                      target="_blank"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <button
                                        className="d-flex popuptext "
                                        style={{
                                          padding: "11px 1rem",
                                          maxHeight: "2rem",
                                          borderRadius: "5px 5px 0px 0px",
                                          gap: "10px",
                                          background: "#FFF",
                                          border: "none",
                                          borderBottom: "1px solid #d9d9d9",
                                          width: "160px",
                                          minHeight: "42px",
                                        }}
                                        onClick={() => {
                                          setShowIcon(false);

                                          setIndex(-1);
                                        }}
                                      >
                                        View Stats
                                      </button>
                                    </a>
                                  )}
                                  <a
                                    href={`/challenge/${
                                      challenge?.preview
                                        ? challenge?.preview?.uniqueId
                                        : challenge.details?.uniqueId
                                    }`}
                                    target="_blank"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <button
                                      className="d-flex popuptext"
                                      style={{
                                        padding: "11px 1rem",
                                        maxHeight: "2rem",
                                        // borderRadius: "5px 5px 0px 0px",
                                        gap: "10px",
                                        borderTop: "1px solid #d9d9d9",
                                        background: "#FFF",
                                        border: "none",
                                        width: "160px",
                                        minHeight: "42px",
                                      }}
                                      onClick={() => {
                                        setShowIcon(false);

                                        setIndex(-1);
                                      }}
                                    >
                                      Preview
                                    </button>
                                  </a>
                                  <button
                                    className="d-flex popuptext"
                                    style={{
                                      padding: "11px 1rem",
                                      maxHeight: "2rem",
                                      borderRadius: "0px 0px 5px 5px",
                                      gap: "10px",
                                      background: "#FFF",
                                      border: "none",
                                      width: "160px",
                                      minHeight: "42px",
                                      borderTop: "1px solid #d9d9d9",
                                    }}
                                    onClick={() => {
                                      setShowIcon(false);
                                      setIndex(-1);
                                      const link = `${
                                        process.env.REACT_APP_FRONTEND_URL
                                      }/challenge/${
                                        challenge?.preview
                                          ? challenge?.preview?.uniqueId
                                          : challenge.details?.uniqueId
                                      }`;

                                      // Copy the link to clipboard
                                      navigator.clipboard
                                        .writeText(link)
                                        .then(() => {
                                          setShowIcon(false);
                                          setIndex(-1);
                                          // Optionally, show a toast notification to indicate the link was copied
                                          toast.success(
                                            "Link copied to clipboard"
                                          );
                                        })
                                        .catch((err) => {
                                          toast.error(
                                            "Failed to copy the link"
                                          );
                                        });
                                    }}
                                  >
                                    Copy Link
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>

                          <MoreVertIcon
                            className="hoverred"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setIndex(index);
                              setShowIcon(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                {currentEntries.length > 0 && currentEntries.length < 5 && (
                  <>
                    {Array.from({ length: 5 - currentEntries.length }).map(
                      (_, index) => (
                        <tr
                          key={index}
                          className="tbodytr"
                          style={{ border: "none" }}
                        >
                          <td className="tbodytrtd"></td>
                          <td className="tbodytrtd"></td>
                          <td className="tbodytrtd"></td>
                          <td className="tbodytrtd"></td>
                          <td className="tbodytrtd"></td>
                        </tr>
                      )
                    )}
                  </>
                )}
              </tbody>
            </table>

            {currentEntries.length === 0 && (
              <div
                className="center col-md-12 col-12"
                style={{
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                  height: "400px",
                  background: "#FFF",
                }}
              >
                <img
                  src={EmptySpace}
                  alt="Emptyspace"
                  height="225px"
                  width="180px"
                  className="img-fluid"
                />
                <p className="pink16">No Data yet</p>
                <p className="black14" style={{ fontWeight: "500" }}>
                  <span className="black14">Please come back later.</span> The
                  first students will engage with your company in no time!
                </p>
              </div>
            )}
          </div>
          <div
            className="d-flex"
            style={{ marginTop: "1rem", justifyContent: "end" }}
          >
            <div
              className="d-flex"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              {renderPageButtons()}
              {indexOfLastEntry < challengestoshow.length && (
                <button
                  onClick={handleNextPage}
                  disabled={indexOfLastEntry >= challengestoshow.length}
                  className="backbutton"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default MyChallenges;
