import AccessTimeIcon from "@mui/icons-material/AccessTime";
import OwnerComponent from "./Upcoming/OwnerComponent";
import SpeakersComponent from "./Upcoming/SpeakersComponent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Chart } from "chart.js";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Questions from "./Questions";
import Vertical from "./VerticalIcon";
const SelectedEvent = ({
  selectedEvent,
  getDayOfWeek,
  getDayOfMonth,
  isWithinNextThreeDays,
  getTimeRange,
  isNonMobile1023,
}) => {
  const participants = [
    { id: 1, gender: "Male" },
    { id: 2, gender: "Female" },
    { id: 3, gender: "Male" },
    { id: 4, gender: "Others" },
  ];

  const selectedEventRef = useRef(null);
  const secondDivRef = useRef(null);
  const [secondDivHeight, setSecondDivHeight] = useState("630px");

  const updateHeight = () => {
    if (secondDivRef.current) {
      const height = secondDivRef.current.getBoundingClientRect().height;
      setSecondDivHeight(height);
    }
  };

  useEffect(() => {
    updateHeight(); // Initial height setting
    window.addEventListener("resize", updateHeight); // Update on resize

    return () => {
      window.removeEventListener("resize", updateHeight); // Cleanup listener
    };
  }, [isNonMobile1023]);

  useEffect(() => {
    updateHeight();
  }, []);

  const chartRef = useRef(null);
  const genderCount = participants.reduce(
    (acc, { gender }) => {
      if (gender === "Male") acc.male += 1;
      else if (gender === "Female") acc.female += 1;
      else acc.others += 1;
      return acc;
    },
    { male: 0, female: 0, others: 0 }
  );

  const centerTextPlugin = {
    id: "centerText",
    beforeDraw(chart) {
      const ctx = chart.ctx;
      const { width, height, options } = chart;

      // Retrieve the plugin options with default values
      const centerText = options.plugins.centerText || {};
      const staticText = centerText.staticText || "No text provided";
      const participantText = centerText.participantText || "0";
      const staticTextColor = centerText.staticTextColor || "#000";
      const participantTextColor = centerText.participantTextColor || "#000";
      const staticFontSize = centerText.staticFontSize || 14;
      const participantFontSize = centerText.participantFontSize || 18;
      const staticFontFamily = centerText.staticFontFamily || "Arial";
      const participantFontFamily = centerText.participantFontFamily || "Arial";

      const participantFontWeight = centerText.participantFontWeight || "600";

      ctx.save();

      // Calculate text dimensions
      ctx.font = `bold ${staticFontSize}px ${staticFontFamily}`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = staticTextColor;

      const lineHeight = staticFontSize * 1.2; // Adjust line height as needed

      // Draw static text
      ctx.fillText(
        staticText,
        width / 2 - 70,
        height / 2 - lineHeight / 2 + 25
      );

      // Draw participant text with different style
      ctx.font = `${participantFontWeight} ${participantFontSize}px ${participantFontFamily}`;
      ctx.fillStyle = participantTextColor;
      ctx.fillText(
        participantText,
        width / 2 - 80,
        height / 2 + lineHeight / 2 + participantFontSize / 2 + 25
      );

      ctx.restore();
    },
  };

  Chart.register(centerTextPlugin);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    // Create chart instance
    const myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: [
          `Male ${genderCount.male}  ${(
            (genderCount.male * 100) /
            participants.length
          ).toFixed(2)}%`,
          `Female ${genderCount.female}  ${(
            (genderCount.female * 100) /
            participants.length
          ).toFixed(2)}%`,
          `Others ${genderCount.others}  ${(
            (genderCount.others * 100) /
            participants.length
          ).toFixed(2)}%`,
        ],
        datasets: [
          {
            data: [genderCount.male, genderCount.female, genderCount.others],
            backgroundColor: ["#A145FF", "#FA6298", "#FAA24B"],
            hoverBackgroundColor: ["#903EE5", "#E15888", "#E19143"],
          },
        ],
      },
      options: {
        cutout: "90%", // Adjusts the inner circle size
        rotation: -90, // Starts from the top
        circumference: 180, // Semi-circle
        borderRadius: 20,
        elements: {
          center: {
            text: "Red is 2/3 the total numbers",
            color: "#000", // Default is #000000
            fontStyle: "Arial", // Default is Arial
            sidePadding: 20, // Default is 20 (as a percentage)
            minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
            lineHeight: 25, // Default is 25 (in px), used for when text wraps
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 0,
        },
        plugins: {
          centerText: {
            staticText: "Total Participants",
            participantText: participants.length.toString(),
            staticTextColor: "#E31B54",
            participantTextColor: "#475467", // Example color for participant number
            staticFontSize: 14,
            participantFontSize: 48,
            staticFontFamily: "Poppins",
            participantFontFamily: "Poppins",
            staticFontWeight: 500,
            participantFontWeight: 600,
          },
          legend: {
            display: true,
            position: "right",
            labels: {
              usePointStyle: true, // Use point style instead of boxes
              padding: 15,
              boxHeight: 10,
              color: "#344054",
              font: {
                size: 16,
                family: "Poppins, sans-serif",
                weight: "400",
                lineHeight: 20,
              },
            },
          },
        },
      },
    });

    // Cleanup to remove old chart instance
    return () => myChart.destroy();
  }, [genderCount]);

  return (
    <div
      style={{
        display: "flex",
        gap: "1.5rem",
        flexDirection: isNonMobile1023 ? "row" : "column",

        height: "100%",
      }}
    >
      <div
        className="d-flex flex-column"
        style={{
          width: isNonMobile1023 ? "55.9649122807%" : "100%",
        }}
        ref={secondDivRef}
      >
        <div className="selectedEvent-inner shadowa">
          <div className="space">
            <div className="d-flex" style={{ gap: "0.5rem" }}>
              <p
                className="upcomingDay"
                style={{
                  color: isWithinNextThreeDays(selectedEvent?.datetime)
                    ? "#F97066"
                    : "",
                }}
              >
                {getDayOfWeek(selectedEvent?.datetime)}
              </p>
              <p
                className="upcomingDate"
                style={{
                  color: isWithinNextThreeDays(selectedEvent?.datetime)
                    ? "#F97066"
                    : "",
                }}
              >
                {getDayOfMonth(selectedEvent?.datetime)}
              </p>
            </div>

            <Vertical selectedEvent={selectedEvent} />
          </div>
          <p
            style={{
              marginTop: "1.25rem",
              fontWeight: 400,
              width: "80%",
              maxWidth: "80%",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#0C111D",
            }}
          >
            {selectedEvent?.title}
          </p>
          <div
            className="d-flex"
            style={{ marginTop: "0.75rem", gap: "1.25rem" }}
          >
            <div
              className="d-flex align-items-center"
              style={{ gap: "0.75rem" }}
            >
              <AccessTimeIcon style={{ height: "20px", width: "20px" }} />
              <p className="headersmalltext">
                {getTimeRange(selectedEvent?.datetime, selectedEvent?.duration)}
              </p>
            </div>
            <div
              className="upcoming-type-outer"
              style={{
                color:
                  selectedEvent?.type?.toLowerCase() === "varyance livestream"
                    ? "#079455"
                    : selectedEvent?.type?.toLowerCase() === "physical event"
                    ? "#7835CD"
                    : "inherit",
                background:
                  selectedEvent?.type?.toLowerCase() === "varyance livestream"
                    ? "rgba(7, 148, 85, 0.10)"
                    : selectedEvent?.type?.toLowerCase() === "physical event"
                    ? "rgba(120, 53, 205, 0.10)"
                    : "inherit",
              }}
            >
              {selectedEvent?.type?.toLowerCase() === "varyance livestream" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M2.63047 11.9527C2.52157 11.8443 2.38189 11.7721 2.23047 11.746C2.1818 11.7394 2.13247 11.7394 2.0838 11.746C1.89164 11.7458 1.70626 11.8171 1.5638 11.946C1.26201 12.248 1.26201 12.7374 1.5638 13.0393C1.70791 13.1857 1.9051 13.2674 2.11047 13.266C2.31598 13.2682 2.51346 13.1863 2.65713 13.0393C2.81905 12.8579 2.88796 12.6118 2.8438 12.3727C2.81568 12.2147 2.74145 12.0686 2.63047 11.9527Z"
                    fill="#079455"
                  />
                  <path
                    d="M1.93713 9.99268C1.756 9.95431 1.56849 10.0192 1.44979 10.1613C1.33108 10.3034 1.30061 10.4994 1.3706 10.6708C1.44058 10.8422 1.59956 10.9609 1.7838 10.9793C2.72673 11.1182 3.46522 11.8621 3.59713 12.806C3.63069 13.0522 3.84205 13.235 4.09046 13.2327H4.1638C4.43588 13.1915 4.62358 12.9383 4.5838 12.666C4.39563 11.2786 3.30451 10.1875 1.91713 9.99935L1.93713 9.99268Z"
                    fill="#079455"
                  />
                  <path
                    d="M2.2638 6.97935C2.07889 6.93648 1.88566 7.00202 1.76497 7.14852C1.64428 7.29502 1.61696 7.49723 1.69443 7.67051C1.7719 7.84379 1.94081 7.95826 2.13047 7.96602C4.47927 8.25563 6.32783 10.1097 6.61047 12.4593C6.64407 12.7092 6.85839 12.8949 7.11047 12.8927H7.17047C7.30227 12.8761 7.42204 12.8077 7.50334 12.7026C7.58464 12.5975 7.62078 12.4644 7.6038 12.3327C7.2539 9.54491 5.05824 7.34925 2.27047 6.99935L2.2638 6.97935Z"
                    fill="#079455"
                  />
                  <path
                    d="M2.09047 8.49268C1.90556 8.44982 1.71232 8.51535 1.59164 8.66185C1.47095 8.80836 1.44362 9.01056 1.5211 9.18384C1.59857 9.35712 1.76748 9.4716 1.95713 9.47935C2.75737 9.58222 3.50075 9.94805 4.07047 10.5193C4.64128 11.0867 5.00716 11.8278 5.11047 12.626C5.14094 12.8748 5.35315 13.0612 5.6038 13.0593H5.6638C5.79571 13.0412 5.91492 12.9711 5.99502 12.8648C6.07511 12.7584 6.10947 12.6245 6.09047 12.4927C5.94627 11.4829 5.47841 10.5472 4.75713 9.82601C4.03795 9.10194 3.10123 8.63359 2.09047 8.49268Z"
                    fill="#079455"
                  />
                  <path
                    d="M11.6638 2.66602H4.39713C2.80547 2.66969 1.51713 3.96102 1.51713 5.55268C1.51713 5.82882 1.74099 6.05268 2.01713 6.05268C2.29327 6.05268 2.51713 5.82882 2.51713 5.55268C2.51536 5.05292 2.71265 4.57302 3.06541 4.21901C3.41817 3.865 3.89737 3.66601 4.39713 3.66602H11.6638C12.6927 3.6842 13.5173 4.52359 13.5171 5.55268V10.0327C13.5101 11.0566 12.6876 11.8879 11.6638 11.906H9.2638C8.98765 11.906 8.7638 12.1299 8.7638 12.406C8.7638 12.6822 8.98765 12.906 9.2638 12.906H11.6638C13.2555 12.906 14.5468 11.6177 14.5505 10.026V5.55268C14.5505 3.95842 13.2581 2.66602 11.6638 2.66602Z"
                    fill="#079455"
                  />
                </svg>
              )}
              {selectedEvent?.type?.toLowerCase() === "physical event" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M11.2135 10.324C10.9703 10.2017 10.6739 10.2966 10.5468 10.5373C10.4294 10.7826 10.5265 11.0768 10.7668 11.204C11.3535 11.504 11.6868 11.8706 11.6868 12.224C11.6868 12.9773 10.1135 13.8173 8.00683 13.8173C5.90016 13.8173 4.32016 12.984 4.32016 12.2506C4.32016 11.904 4.6535 11.5306 5.24016 11.2306C5.4161 11.1601 5.53701 10.9961 5.55243 10.8072C5.56784 10.6183 5.4751 10.4369 5.31292 10.3388C5.15075 10.2406 4.94703 10.2427 4.78683 10.344C3.97743 10.649 3.41315 11.3893 3.3335 12.2506C3.3335 13.7306 5.3335 14.844 8.00016 14.844C10.6668 14.844 12.6668 13.7306 12.6668 12.2506C12.5945 11.3819 12.029 10.6321 11.2135 10.324Z"
                    fill="#7835CD"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.50016 12.544V9.66397C5.30476 9.39947 3.69518 7.47084 3.82767 5.26353C3.96016 3.05623 5.78888 1.33398 8.00016 1.33398C10.2114 1.33398 12.0402 3.05623 12.1727 5.26353C12.3051 7.47084 10.6956 9.39947 8.50016 9.66397V12.544C8.50016 12.8201 8.27631 13.044 8.00016 13.044C7.72402 13.044 7.50016 12.8201 7.50016 12.544ZM10.9496 4.29474C10.4547 3.10336 9.29026 2.32794 8.00016 2.33064C6.24542 2.33431 4.82383 3.75589 4.82016 5.51064C4.81747 6.80073 5.59288 7.96517 6.78426 8.46012C7.97564 8.95506 9.3479 8.68285 10.2601 7.77061C11.1724 6.85838 11.4446 5.48612 10.9496 4.29474Z"
                    fill="#7835CD"
                  />
                  <path
                    d="M6.3935 6.25064C6.66964 6.25064 6.8935 6.02678 6.8935 5.75064C6.8935 4.9848 7.51433 4.36397 8.28016 4.36397C8.55631 4.36397 8.78016 4.14011 8.78016 3.86397C8.78016 3.58783 8.55631 3.36397 8.28016 3.36397C7.64541 3.36574 7.03736 3.61959 6.58977 4.06967C6.14218 4.51976 5.89173 5.12922 5.8935 5.76397C5.89525 5.89482 5.94892 6.0196 6.04269 6.11087C6.13646 6.20214 6.26265 6.25242 6.3935 6.25064Z"
                    fill="#7835CD"
                  />
                </svg>
              )}
              {selectedEvent?.type?.toLowerCase() === "varyance livestream"
                ? "Livestream"
                : selectedEvent?.type?.toLowerCase() === "physical event"
                ? "In-Person Event"
                : selectedEvent?.type}
            </div>
          </div>
          <div
            className="space align-items-center flex-wrap"
            style={{ marginTop: "1.25rem", gap: "1rem" }}
          >
            <OwnerComponent ownerId={selectedEvent?.owner} />
            {selectedEvent?.addedSpeakers?.length > 0 && (
              <SpeakersComponent addedSpeakers={selectedEvent?.addedSpeakers} />
            )}
            <button
              className="continuebutton"
              onClick={() => {
                toast.error(
                  "You can only enter live stream 1 hour before event start"
                );
              }}
            >
              Go to Livestream
            </button>
          </div>
        </div>
        <div
          className="selectedEvent-inner shadowa"
          style={{ marginTop: "1.5rem" }}
        >
          <div className="space">
            <p
              style={{
                fontWeight: 400,
                fontSize: "1rem",
                lineHeight: "1.5rem",
              }}
            >
              Event Analytics
            </p>
            <div
              className="d-flex hoverdarkred pink14 align-items-center"
              style={{ gap: "0.5rem", fontWeight: 500, cursor: "pointer" }}
            >
              Change Parameter
              <KeyboardArrowDownIcon style={{ color: "#E31b54" }} />
            </div>
          </div>
          <div style={{ marginTop: "2.5rem" }}>
            <div
              style={{
                position: "relative",

                width: "100%",
              }}
            >
              <canvas ref={chartRef} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="selectedEvent-inner shadowa"
        ref={selectedEventRef}
        style={{
          width: isNonMobile1023 ? "41.9%" : "100%",
          height: isNonMobile1023 ? secondDivHeight : "100%",
          overflowY: isNonMobile1023 ? "scroll" : "",
        }}
      >
        <Questions />
      </div>
    </div>
  );
};
export default SelectedEvent;
