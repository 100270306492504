import React, { useEffect, useState } from "react";
import Header from "../../components/Role/component/Header";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Body from "./Body";
import Footer from "../../components/Company/Footer/footer";
import axios from "axios";
import { setEmail } from "../../state/index";
import { useDispatch } from "react-redux";
import { setCompany as set } from "../../state";
const Profile = () => {
  let { Role } = useParams();
  const { dsa, uniqueId, company, user } = useParams();

  const email = useSelector((state) => state.email);
  const dispatch = useDispatch();
  if (company) {
    dispatch(set({ company: company }));
  }
  const navigate = useNavigate();
  if (dsa === "fadsdgaskgjfdasfdasjfkalas") {
    Role = "Recruiter";
  }
  if (dsa === "jkfdhgjkhdjkhgdsjkjdksjfkjds") {
    Role = "Representative";
  }
  if (dsa === "jkfdhgjkhdjkhgdsjkjfadsdfasfasdksjfkjds") {
    Role = "Team";
  }

  useEffect(() => {
    if (uniqueId) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/company/register/find`, {
          uniqueId,
        })
        .then((res) => {
          if (res.data === "null") {
            navigate("/employer/register");
          } else {
            dispatch(setEmail({ email: res.data.email }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [uniqueId]);

  useEffect(() => {
    if (!dsa) {
      if (Role) {
        if (
          Role !== "Recruiter" &&
          Role !== "Team" &&
          Role !== "Representative"
        ) {
          navigate("/employer/register");
        }
      }
    }
    if (!email) {
      navigate("/employer/register");
    }
  }, []);

  const [info, setInfo] = useState({
    info: {
      firstName: "",
      lastName: "",
      picture: null,
    },
  });

  useEffect(() => {
    if (email) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/get/CompanyReg/info`, {
          email: email,
        })
        .then((res) => {
          if (res.data.msg === "success" && res.data.find.info.firstName) {
            setInfo(res.data.find);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [email]);

  return (
    <div>
      <div
        className="createcompany d-flex "
        style={{
          position: "relative",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <Header />
          </div>
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany mt-5 pt-5">
              <Body
                Role={Role}
                info={info}
                dsa={dsa}
                Company={company}
                user={user}
              />
            </div>
          </div>
        </div>
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany MarginTopFooter mt-5">
              {/* <Footer /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
