import CropFreeIcon from "@mui/icons-material/CropFree";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import { IoLayersOutline } from "react-icons/io5";
import { CiStar } from "react-icons/ci";
import { HiOutlineUsers } from "react-icons/hi2";
import { useMediaQuery } from "@mui/material";
const NewSection = () => {
  const isNon1024 = useMediaQuery("(max-width:1024px)");
  const isNon = useMediaQuery("(max-width:768px)");
  const list = [
    {
      chiptext: "Scalable",
      chipicon: <CropFreeIcon style={{ height: "16px", width: "16px" }} />,
      background: "rgba(120, 53, 205, 0.10)",
      color: "#7835CD",
      text1: "Infinite Participation",
      text2: "Scale effortlessly with unconstrained challenge participation.",
    },
    {
      chiptext: "Low effort",
      chipicon: (
        <QueryBuilderOutlinedIcon style={{ height: "16px", width: "16px" }} />
      ),
      background: "rgba(7, 148, 85, 0.10)",
      color: "#079455",
      text1: "Asynchronous",
      text2: "Available on-demand for students worldwide, 24/7.",
    },
    {
      chiptext: "Inclusive",
      chipicon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
        >
          <path
            d="M9.30838 9.31163C9.30838 8.70044 8.81291 8.20497 8.20171 8.20497C7.59052 8.20497 7.09505 8.70044 7.09505 9.31163C7.09413 9.57075 7.18641 9.82156 7.35505 10.0183C7.52496 10.1954 7.58387 10.4515 7.50838 10.685L7.36838 11.0983C7.31424 11.2842 7.35003 11.4847 7.46514 11.6403C7.58025 11.796 7.76146 11.889 7.95505 11.8916H8.44838C8.64837 11.8916 8.83606 11.795 8.95243 11.6324C9.0688 11.4697 9.09956 11.2609 9.03505 11.0716L8.86838 10.6583C8.79289 10.4248 8.8518 10.1687 9.02171 9.99163C9.19415 9.80566 9.29563 9.56493 9.30838 9.31163Z"
            fill="#FC7E00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.1284 5.22497C11.0548 5.18823 11.9504 5.56103 12.5771 6.24425C13.2038 6.92746 13.4981 7.85185 13.3817 8.77163L12.7484 12.6183C12.4436 14.1636 11.0696 15.2645 9.49505 15.225H6.90838C5.34496 15.2597 3.98075 14.1706 3.66838 12.6383L3.03505 8.79163C2.81419 7.3443 3.65406 5.94453 5.03505 5.4583V3.3783C5.03468 2.83491 5.25543 2.31477 5.64652 1.93751C6.03761 1.56026 6.56536 1.35838 7.10838 1.3783H9.29505C9.83807 1.35838 10.3658 1.56026 10.7569 1.93751C11.148 2.31477 11.3687 2.83491 11.3684 3.3783C11.3684 3.65444 11.1445 3.8783 10.8684 3.8783C10.5922 3.8783 10.3684 3.65444 10.3684 3.3783C10.3431 2.81005 9.86341 2.36905 9.29505 2.39163H7.10838C6.54002 2.36905 6.06029 2.81005 6.03505 3.3783V5.22497H10.1284ZM11.535 12.4183L12.2017 8.5583C12.2679 8.05893 12.1092 7.55593 11.7684 7.18497C11.354 6.71501 10.7549 6.44957 10.1284 6.4583H6.27505C5.65064 6.4462 5.05192 6.70662 4.63505 7.17163C4.30239 7.55151 4.15583 8.05961 4.23505 8.5583L4.86838 12.4183C5.07508 13.3692 5.93618 14.0333 6.90838 13.9916H9.49505C10.4672 14.0333 11.3283 13.3692 11.535 12.4183Z"
            fill="#FC7E00"
          />
        </svg>
      ),
      background: "rgba(252, 126, 0, 0.10)",
      color: "#FC7E00",
      text1: "Equal Access",
      text2:
        "Provide all talents with opportunities, from top schools to local universities.",
    },
    {
      chiptext: "Talent Fit",
      chipicon: <IoLayersOutline style={{ height: "16px", width: "16px" }} />,
      background: "#EEF4FF",
      color: "#3538CD",
      text1: "Pre-Skilling",
      text2: "Pre-skilling ensures new hires are job-ready from day one.",
    },
    {
      chiptext: "Differentiation",
      chipicon: <CiStar style={{ height: "16px", width: "16px" }} />,
      background: "rgba(227, 27, 84, 0.10)",
      color: "#E31B54",
      text1: "Share EVP",
      text2:
        "Get top candidates who resonate with your values, culture, and goals.",
    },
    {
      chiptext: "Personal",
      chipicon: <HiOutlineUsers style={{ height: "16px", width: "16px" }} />,
      background: "rgba(0, 119, 182, 0.10)",
      color: "#0077B6",
      text1: "Approachable",
      text2: "Put a face to your employer brandand build a connection early.",
    },
  ];

  return (
    <div
      className="d-flex "
      style={{
        justifyContent: "center",
      }}
    >
      {" "}
      <div className="headerCompany ">
        <p className="text-center ShortListTalenttitle">
          Design once, attract thousands.
        </p>
        <div className="center">
          <p
            className="gray95016500 text-center"
            style={{ marginTop: "1rem", fontWeight: 400, maxWidth: "467px" }}
          >
            With{" "}
            <span className="gray95016500 " style={{ fontWeight: 600 }}>
              challenges
            </span>{" "}
            you attract and engage more early talent without visiting more
            campuses.
          </p>
        </div>
        <div style={{ marginTop: "3.75rem" }}>
          <div
            className="space flex-wrap"
            style={{ gap: "1.5rem", display: "flex" }}
          >
            {list.map((item, index) => (
              <div
                key={index}
                className="center align-items-center"
                style={{
                  background: "#FFF",
                  width: !isNon1024
                    ? "calc((100% - 2 * 1.5rem) / 3)"
                    : isNon
                    ? "100%"
                    : "calc((100% - 1 * 1.5rem) / 2)", // Width calculation for 3 items
                  maxWidth: !isNon1024
                    ? "calc((100% - 2 * 1.5rem) / 3)"
                    : isNon
                    ? "100%"
                    : "calc((100% - 1 * 1.5rem) / 2)",
                  minHeight: "232px",
                  borderRadius: "5px",
                  padding: "1.25rem",
                  boxSizing: "border-box", // Ensure padding is included in width
                }}
              >
                <div>
                  <div className="center">
                    <div
                      className="center align-items-center companywebchip"
                      style={{
                        gap: "0.5rem",
                        borderRadius: "30px",
                        padding: "6px 10px",
                        background: item.background,
                        color: item.color,
                      }}
                    >
                      {item.chipicon}
                      {item.chiptext}
                    </div>
                  </div>
                  <p
                    className="gray95020400 text-center"
                    style={{
                      fontWeight: 500,
                      marginTop: "1.25rem",
                      fontFamily: "Poppins",
                    }}
                  >
                    {item.text1}
                  </p>
                  <p
                    style={{ marginTop: "0.75rem", fontSize: "1rem" }}
                    className="gray70014400 text-center"
                  >
                    {item.text2}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSection;
