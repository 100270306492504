import "./postingtab.css";
import { GrAttachment } from "react-icons/gr";
import EmojiPicker from "emoji-picker-react";
import { CiImageOn } from "react-icons/ci";
import { CiFaceSmile } from "react-icons/ci";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { toast } from "react-toastify";
import { useState, useRef, useMemo, useEffect } from "react";
import axios from "axios";
import ThreeUsers from "../../assets/images/ThreeUsers";
import LockIcon from "../../assets/images/Lock";
const PostingTab = ({
  photo,
  isNonMobile450,
  isNonMobile1024,
  hasMore,
  post,
  setEdit,
  setPosts,
  groupID = false,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [content, setContent] = useState(post ? post.content : "");
  const [postImages, setPostImages] = useState(post ? post.media : []);
  const [loading, setLoading] = useState(false);
  const [detectedUrls, setDetectedUrls] = useState([]);

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const handleChange = (e) => {
    const text = e.target.value;
    setContent(text);
    e.target.style.height = "auto"; // Reset the height
    e.target.style.height = Math.min(e.target.scrollHeight, 350) + "px";

    const urlMatches = text.match(urlRegex);
    if (urlMatches) {
      setDetectedUrls(urlMatches);
    } else {
      setDetectedUrls([]);
    }
  };

  const [hoverIndex, setHoverIndex] = useState(-1);
  const popupref = useRef(null);
  const fileInput2Ref = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onEmojiClick = (emojiData, event) => {
    setContent((prevContent) => prevContent + emojiData.emoji);
  };

  const handleClick2 = () => {
    if (fileInput2Ref.current) {
      fileInput2Ref.current.click();
    }
  };

  const renderPreview = (url) => {
    if (youtubeRegex.test(url)) {
      const videoId = youtubeRegex.exec(url)[1];
      return (
        <iframe
          key={url}
          className="img-fluid rounded-2 shadowa mt-2 mb-2"
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video player"
          style={{ maxWidth: "300px" }}
        ></iframe>
      );
    }
  };

  const handleFileChange2 = (e) => {
    const selectedFiles = e.target.files;
    const allowedTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "video/mp4",
      "video/webm",
      "video/ogg",
    ];
    const maxFileSize = 20 * 1024 * 1024; // 12MB in bytes

    if (selectedFiles) {
      let addedFiles = 0;
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (postImages.length + addedFiles >= 10) {
          toast.error(
            "Maximum of 10 files (images/videos) allowed in a single post"
          );
          break;
        }
        if (allowedTypes.includes(file.type)) {
          if (file.size <= maxFileSize) {
            setPostImages((prevPostImages) => [...prevPostImages, file]);
            addedFiles++;
          } else {
            toast.error("File size exceeds 20MB limit");
          }
        } else {
          toast.error(
            "Invalid file type. Only SVG, PNG, JPEG, GIF, MP4, WebM, and OGG are allowed."
          );
        }
      }
    }
  };

  const handleDelete = (index) => {
    setPostImages((prevGallery) => prevGallery.filter((_, i) => i !== index));
  };

  const mediaUrls = useMemo(() => {
    return postImages.map((file) => {
      if (file && file instanceof File) {
        return {
          url: URL.createObjectURL(file),
          type: file.type.startsWith("image") ? "image" : "video",
        };
      } else if (file.includes("http")) {
        return {
          url: file,
          type: file.match(/\.(jpeg|jpg|gif|png|svg)$/) ? "image" : "video",
        };
      } else {
        return {
          url: `${process.env.REACT_APP_BACKEND_URL}/${file}`,
          type: file.match(/\.(jpeg|jpg|gif|png|svg)$/) ? "image" : "video",
        };
      }
    });
  }, [postImages]);

  const handlePost = async () => {
    if (!content && postImages.length === 0) {
      toast.error(
        "Please enter some content or upload images/videos to publish"
      );
      return;
    }

    const formData = new FormData();
    formData.append("content", content);

    // Append images/videos if present
    for (let i = 0; i < postImages.length; i++) {
      formData.append("images", postImages[i]);
    }
    if (post) {
      formData.append("post_id", post._id);
    }
    if (groupID) {
      formData.append("groupID", groupID);
    }

    setLoading(true);

    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/${
            post ? "edit" : "create"
          }/post`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            setContent("");
            setDetectedUrls([]);
            setPostImages([]);
            if (res.data.check === false) {
              toast.success(`Post ${post ? "Edited" : "Created"} successfully`);
              if (!post) {
                setPosts((prevPosts) => [
                  res.data.savedPost,
                  ...(hasMore
                    ? prevPosts.slice(0, prevPosts.length - 1)
                    : prevPosts),
                ]);
              } else {
                setPosts((prevPosts) => {
                  return prevPosts.map((post) => {
                    if (post._id === res.data.updatedPost._id) {
                      return res.data.updatedPost;
                    } else {
                      return post;
                    }
                  });
                });
                setEdit(null);
              }
            } else {
              toast.success(
                `Post ${post ? "Edited" : "Request sent"} successfully`
              );
              if (post) {
                setPosts((prevPosts) => {
                  return prevPosts.map((post) => {
                    if (post._id === res.data.updatedPost._id) {
                      return {
                        ...post,
                        content: res?.data?.updatedPost?.content,
                        edited: true,
                        media: res?.data?.updatedPost?.media,
                      };
                    } else {
                      return post;
                    }
                  });
                });
                setEdit(null);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error creating post:", error.message);
    }
  };

  return (
    <div className="postingtab__container">
      <div
        className={`d-flex ${isNonMobile450 ? "flex-row" : "flex-column"}`}
        style={{ gap: "1rem" }}
      >
        {photo && (
          <img
            src={photo}
            alt="profile"
            className="newprofilephoto"
            height="36px"
            width="36px"
          />
        )}
        <textarea
          placeholder="Publish your thoughts"
          value={content}
          onChange={(e) => handleChange(e)}
          style={{ maxHeight: "350px", height: "fit-content" }}
          maxLength={3000}
          className="postingtab__input hovershadowa"
        />
      </div>
      {detectedUrls.length > 0 && (
        <div className="d-flex flex-wrap center" style={{ gap: "1rem" }}>
          {detectedUrls.map((url) => renderPreview(url))}
        </div>
      )}
      {post?.postType === "grouptofeed" && post?.groupData?.title && (
        <a
          href={`/student/group/${post?.groupData?.link}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <div style={{ margin: "1rem 0rem", cursor: "pointer" }}>
            <div style={{ borderRadius: "5px", overflow: "hidden" }}>
              <img
                src={post?.groupData?.banner}
                alt="Selected file"
                style={{
                  objectFit: "cover",
                  maxHeight: "200px",
                  width: "100%",
                  borderRadius: "5px 5px 0px 0px",
                }}
              />
              <div style={{ background: "#F9FAFB", padding: "1.25rem" }}>
                <p className="gray90014400">{post?.groupData?.title}</p>
                <div
                  className="d-flex align-items-center gap-2"
                  style={{ marginTop: "1rem" }}
                >
                  <div
                    className="center align-items-center"
                    style={{ minWidth: "20px" }}
                  >
                    {post?.groupData?.type === "Public" ? (
                      <ThreeUsers />
                    ) : (
                      <LockIcon />
                    )}
                  </div>
                  <p className="gray70014400">
                    {post?.groupData?.type === "Public" ? "Public" : "Private"}{" "}
                    Group
                  </p>
                </div>

                <div
                  className="d-flex align-items-center gap-2"
                  style={{ marginTop: "1rem" }}
                >
                  <div className="center align-items-center">
                    {post?.groupData?.membersPics?.length > 0 &&
                      post?.groupData?.membersPics.map((pic, index) => (
                        <img
                          src={pic}
                          alt="memberspic"
                          key={index}
                          style={{
                            height: "24px",
                            width: "24px",
                            position: "relative",
                            marginLeft: index === 0 ? "" : "-10px",
                            zIndex: index,
                            borderRadius: "50%",
                            background: "#FFF",
                            border: "1px solid #FFF",
                          }}
                        />
                      ))}
                  </div>
                  <p className="gray70014400">
                    {post?.groupData?.members + 1 >= 1000
                      ? ((post?.groupData?.members + 1) / 1000).toFixed(1) + "k"
                      : post?.groupData?.members + 1}{" "}
                    {post?.groupData?.members + 1 === 1 ? "Member" : "Members"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </a>
      )}
      <div
        style={{
          position: "absolute",
          maxWidth: "70vw",
          zIndex: 2,
        }}
        ref={popupref}
      >
        {showEmojiPicker && (
          <div style={{ position: "relative" }}>
            <button
              onClick={() => setShowEmojiPicker(false)}
              className="rounded-2 shadowa p-0"
              style={{
                position: "absolute",
                top: -12,
                right: 0,
                background: "transparent",
                border: "none",
                cursor: "pointer",
                padding: "0.5rem",
                zIndex: 3,
                background: "#EAECF0",
              }}
            >
              <CloseRoundedIcon style={{ height: "20px", width: "20px" }} />
            </button>
            <EmojiPicker onEmojiClick={onEmojiClick} emojiStyle="apple" />
          </div>
        )}
      </div>
      <div
        className="d-flex flex-wrap"
        style={{ gap: "1rem", marginBottom: "0.5rem", marginTop: "1rem" }}
      >
        {mediaUrls.map((media, index) => (
          <div
            key={index}
            className={` ${
              (hoverIndex === index || !isNonMobile1024) &&
              "outerglass p-0 d-flex m-0 gap-0"
            }`}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(-1)}
            style={{
              cursor: "pointer",
              background: "#F9FAFB",
            }}
          >
            {media.type === "image" ? (
              <img
                src={media.url}
                alt={`Selected file ${index + 1}`}
                className="rounded-3 shadowa"
                style={{
                  maxHeight: "100px",
                  height: "100px",
                  width: "100px",
                  maxWidth: "100px",
                  marginRight: "5px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <video
                src={media.url}
                controls
                className="rounded-3 shadowa"
                style={{
                  maxHeight: "100px",
                  height: "100px",
                  width: "100px",
                  maxWidth: "100px",
                  marginRight: "5px",
                }}
              />
            )}
            {(hoverIndex === index || !isNonMobile1024) && (
              <div className="d-flex align-items-center">
                <DeleteForeverIcon
                  style={{ color: "#E31b54" }}
                  onClick={() => handleDelete(index)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <hr
        style={{
          marginTop: "1rem",
          marginBottom: "1rem",
          borderColor: "#EAECF0",
        }}
      />
      <div
        className="space flex-wrap"
        style={{ alignItems: "center", gap: "1rem" }}
      >
        <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
          {isNonMobile1024 && (
            <CiFaceSmile
              style={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
                color: "#344054",
              }}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            />
          )}

          <CiImageOn
            color="#344054"
            style={{
              cursor: "pointer",
              stroke: "1px",
              height: "20px",
              width: "20px",
            }}
            onClick={handleClick2}
          />
        </div>
        <div>
          <input
            type="file"
            accept="image/svg+xml,image/png,image/jpeg,image/gif,video/mp4,video/webm,video/ogg"
            onChange={handleFileChange2}
            style={{ display: "none" }}
            ref={fileInput2Ref}
            multiple
          />
        </div>
        <button
          className="continuebutton"
          onClick={() => handlePost()}
          disabled={loading}
        >
          {post ? "Save Changes" : "Post"}
        </button>
      </div>
    </div>
  );
};

export default PostingTab;
