import logo from "../../assets/images/logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SelectedBox from "../../assets/images/icons/selectedbox";
import UnselectedBox from "../../assets/images/icons/unselectedBox";
import support from "../../assets/images/supportimage.png";
import { useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
const SupportNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.toLowerCase();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [workmail, setWorkMail] = useState("");
  const [issue, setIssue] = useState("");
  const [agree, setAgree] = useState("");
  const isNonMobile = useMediaQuery("(min-width: 1024px)");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [showDropDown, setShowDropDown] = useState(false);
  const [companySize, setCompanySize] = useState("");
  const iconRef = useRef();
  const options =
    path === "/employer/support"
      ? [
          "Onboarding Issues",
          "I have not receive an email",
          "I need help designing my first challenge",
          "I need help with a feature",
          "Other problem",
        ]
      : [
          "My email isn't working",
          "My university is not listed",
          "I need an account recovery",
          "Other",
        ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Validate email format

    if (!loading) {
      if (!firstName) {
        return toast.error("Please Enter First Name");
      } else if (!workmail) {
        return toast.error(
          `Please Enter ${
            path === "/employer/support" ? "Work Email" : "University Email"
          }`
        );
      } else if (!issue) {
        return toast.error("Please Provide Description of the issue ");
      } else if (!companySize) {
        return toast.error("Please Select Option");
      } else if (!agree) {
        return toast.error("Please Accept terms so that we can help you");
      } else if (!emailRegex.test(workmail)) {
        toast.error("Please enter a valid email address");
        return;
      } else {
        setLoading(true);
        const data = {
          firstName,
          lastName,
          workmail,
          issue,
          companySize,
          company: path === "/employer/support" ? true : false,
        };
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/support`, data)
          .then((res) => {
            console.log(res.data);
            if (res?.data?.msg === "success") {
              setSuccess(true);
              setFirstName("");
              setLastName("");
              setWorkMail("");
              setIssue("");
              setCompanySize("");
            } else {
              toast.error("Error Sending Request");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
        setLoading(false);
      }
    }
  };

  return (
    <div className="backgroundimg">
      <div
        className="shadowa"
        style={{
          background: "#FFF",
          position: "sticky",
          top: 0,
          width: "100vw",
        }}
      >
        <div className="marginsleftrightCompany">
          <div className="center">
            <div
              className="headerCompany space align-items-center"
              style={{ padding: "0.5rem 0rem" }}
            >
              <img
                className="shadowa rounded-1"
                src={logo}
                alt="logo"
                height="36px"
                width="36px"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              />
              <CloseIcon
                className="hoverdarkblacksvg"
                style={{ cursor: "pointer", height: "1.5rem", width: "1.5rem" }}
                onClick={() => navigate(-1)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="marginsleftrightCompany" style={{ marginTop: "2.5rem" }}>
        <div className="center">
          <div className="headerCompany space">
            <div
              style={{
                width: isNonMobile ? "38.034%" : "100%",
                padding: "2rem 0rem",
                position: "relative",
              }}
            >
              {!success ? (
                <div>
                  <p className="pink14">Support</p>
                  <h4 style={{ margin: "1rem 0rem" }} className="registeration">
                    Get in touch with.
                  </h4>
                  <p className="gray60011400" style={{ fontSize: "14px" }}>
                    Fill out the form below, and we'll respond promptly to
                    assist you.
                  </p>
                  <form onSubmit={HandleSubmit}>
                    <div
                      className="d-flex flex-wrap"
                      style={{ marginTop: "1.5rem", gap: "1rem" }}
                    >
                      <input
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First Name"
                        className="newinputs"
                        type="text"
                        style={{ width: "calc(50% - 0.5rem)" }}
                      />
                      <input
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Last Name"
                        type="text"
                        className="newinputs"
                        style={{ width: "calc(50% - 0.5rem)" }}
                      />
                    </div>
                    <input
                      value={workmail}
                      onChange={(e) => setWorkMail(e.target.value)}
                      placeholder={
                        path === "/employer/support"
                          ? "Work Email"
                          : "University Email"
                      }
                      className="newinputs col-12 col-md-12"
                      style={{ margin: "1.25rem 0rem" }}
                      type="email"
                    />
                    <div
                      className="newinputs col-md-12 col-12 space"
                      style={{ marginTop: "1.5rem", cursor: "pointer" }}
                      onClick={() => setShowDropDown(!showDropDown)}
                      placeholder="Select Option"
                    >
                      {companySize ? companySize : "Select Option"}
                      <ArrowDropDownIcon
                        style={{ width: "20px", height: "20px" }}
                      />
                    </div>
                    {showDropDown && (
                      <div
                        className="rounded-2 shadowa"
                        style={{
                          position: "absolute",
                          width: "100%",
                          background: "#FFF",
                        }}
                        ref={iconRef}
                      >
                        {options.map((opt, index) => (
                          <div
                            key={index}
                            className="formtext "
                            style={{
                              borderBottom:
                                index !== options.length - 1 &&
                                "1px solid #E0E0E0",
                              cursor: "pointer",
                              padding: "10px 16px",
                            }}
                            onClick={() => {
                              setCompanySize(opt);
                              setShowDropDown(false);
                            }}
                          >
                            {opt}
                          </div>
                        ))}
                      </div>
                    )}
                    <textarea
                      value={issue}
                      onChange={(e) => setIssue(e.target.value)}
                      className="newinputs col-md-12 col-12 "
                      style={{
                        margin: "1.25rem 0rem 1.5rem 0rem",
                        minHeight: "140px",
                      }}
                      placeholder="Describe your issue or inquiry for quick assistance."
                    ></textarea>
                    <div className="d-flex " style={{ gap: "0.75rem" }}>
                      <div
                        className="center "
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setAgree(!agree);
                        }}
                      >
                        {agree ? <SelectedBox /> : <UnselectedBox />}
                      </div>
                      <p
                        className="gray90018500"
                        style={{ fontSize: "14px", lineHeight: "20px" }}
                      >
                        By submitting this form, you’re giving us permission to
                        reach out to you via email to help with your request.
                      </p>
                    </div>
                    <button
                      className="continuebutton col-md-12 col-12"
                      style={{ marginTop: "1.5rem" }}
                      type="submit"
                      onClick={(e) => {
                        HandleSubmit(e);
                      }}
                      disabled={!agree || loading}
                    >
                      Send message
                    </button>
                  </form>
                </div>
              ) : (
                <div className="">
                  <h4 style={{ margin: "1rem 0rem" }} className="registeration">
                    Request Submitted Successfully
                  </h4>
                  <p className="gray60011400" style={{ fontSize: "14px" }}>
                    Thank you for your request. Our support team will get back
                    to you shortly.
                  </p>
                </div>
              )}
            </div>

            {isNonMobile && (
              <div
                className="center flex-column"
                style={{ width: "51.709%", position: "relative" }}
              >
                <img src={support} alt="support" className="img-fluid" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportNew;
