import React from "react";
import Automotive from "../../assets/images/icons/Automotive.png";
import Brand from "../../assets/images/icons/Brand.png";
import Consulting from "../../assets/images/icons/Consulting.png";
import Economics from "../../assets/images/icons/Economics.png";
import realstate from "../../assets/images/icons/realstate.png";
import Finance from "../../assets/images/icons/Finance.png";
import marketing from "../../assets/images/icons/marketing.png";
import Government from "../../assets/images/icons/Government.png";
import FMCG from "../../assets/images/icons/FMCG.png";
import Law from "../../assets/images/icons/Law.png";
import Pharma from "../../assets/images/icons/Pharma.png";
import Startup from "../../assets/images/icons/Startup.png";
import Supply from "../../assets/images/icons/Supply.png";
import Technology from "../../assets/images/icons/Technology.png";
import Engineering from "../../assets/images/icons/Engineering.png";
import Computer from "../../assets/images/icons/Computer.png";
import HR from "../../assets/images/icons/HR.png";
import Aviation from "../../assets/images/icons/Aviation.png";
import { motion } from "framer-motion";

import "./interest.css";
import { toast } from "react-toastify";
const InterestItems = ({
  selectedItem,
  setSelectedItem,
  TRUE,
  group = false,
}) => {
  let interestitems = [
    {
      id: "Brand Management",
      icon: Brand,
      text: "Brand Management",
      select: false,
    },
    { id: "Finance", icon: Finance, text: "Finance", select: false },
    { id: "Consulting", icon: Consulting, text: "Consulting", select: false },
    { id: "Economics", icon: Economics, text: "Economics", select: false },
    { id: "Corporate Law", icon: Law, text: "Corporate Law", select: false },
    {
      id: "HR",
      icon: HR,
      text: "HR",
      select: false,
    },
    {
      id: "Aviation",
      icon: Aviation,
      text: "Aviation",
      select: false,
    },
    { id: "Supply Chain", icon: Supply, text: "Supply Chain", select: false },
    { id: "Pharma", icon: Pharma, text: "Pharma", select: false },
    {
      id: "Technology",
      icon: Technology,
      text: "Technology & AI",
      select: false,
    },
    { id: "Government", icon: Government, text: "Government", select: false },
    { id: "marketing", icon: marketing, text: "Marketing & PR", select: false },
    {
      id: "Real Estate",
      icon: realstate,
      text: "Real Estate",
      select: false,
    },
    { id: "Start-Up", icon: Startup, text: "Start-Up", select: false },
    { id: "Automotive", icon: Automotive, text: "Automotive", select: false },
    {
      id: "FMCG",
      icon: FMCG,
      text: "FMCG",
      select: false,
    },

    {
      id: "Engineering",
      icon: Engineering,
      text: "Engineering",
      select: false,
    },
    {
      id: "Computer Science",
      icon: Computer,
      text: "Computer Science",
      select: false,
    },
  ];

  function selectItem(item, i) {
    if (TRUE) {
      let itemId = item.id;
      let updatedItem = [...interestitems];

      if (selectedItem.some((selectedIte) => selectedIte.id === item.id)) {
        updatedItem[i].select = false;
        let newIdList = selectedItem.filter(
          (selectedItem) => selectedItem.id !== itemId
        );
        setSelectedItem(newIdList);
      } else {
        if (selectedItem.length < 3) {
          updatedItem[i].select = true;
          setSelectedItem((prev) => [...prev, item]);
        } else {
          toast.error("You can't select more than 3 items");
        }
      }
    } else {
      const itemIndex = selectedItem.findIndex((it) => it.id === item.id);
      if (itemIndex !== -1) {
        const updatedItems = [...selectedItem]; // Create a new array
        updatedItems.splice(itemIndex, 1); // Remove the item
        setSelectedItem(updatedItems); // Update the state
      } else {
        const updatedItems = [...selectedItem, item]; // Add the item to the array
        setSelectedItem(updatedItems); // Update the state
      }
    }
  }

  return (
    <div className="d-flex flex-wrap" style={{ gap: "1rem 6px" }}>
      {interestitems?.map((item, i) => (
        <motion.div
          className={`ChipCover ${group ? "newdashboardsidechips" : ""} ${
            selectedItem?.some((selectedIte) => selectedIte.id === item.id)
              ? "selected_interests_new"
              : ""
          }`}
          onClick={() => selectItem(item, i)}
          whileTap={{ scale: 0.9 }}
          key={i}
          style={{ border: group ? "1px solid var(--Gray-200, #d0d5dd)" : "" }}
        >
          {item.icon !== null && (
            <img
              src={item.icon}
              alt={item.text}
              height="20px"
              width="20px"
              className="interest_icon"
              style={{ maxHeight: "1.25rem", maxWidth: "1.25rem" }}
            />
          )}
          <p
            className={`ChipsText  ${
              selectedItem.some((selectedIte) => selectedIte.id === item.id)
                ? "chipstextactivated"
                : ""
            } `}
          >
            {item.text}
          </p>
        </motion.div>
      ))}
    </div>
  );
};

export default InterestItems;
