import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CompanyImage from "../../components/Company/CompanyImage";
const EducationList = ({
  setAddEducation,
  loading,
  education,
  setNewEducation,
  setIndex,
  handleUpdateEducation,
  setEducation,
}) => {
  return (
    <div>
      {education?.length > 0 && (
        <>
          {education?.map((exp, index) => (
            <div key={index} style={{ marginTop: "1.5rem" }}>
              <div
                className="d-flex justify-content-end"
                style={{ gap: "1rem" }}
              >
                <EditIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIndex(index);
                    setNewEducation(exp);
                    setAddEducation(true);
                  }}
                />
                <DeleteIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEducation(education.filter((_, i) => i !== index));
                  }}
                />
              </div>
              <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                {exp?.logo && (
                  <CompanyImage
                    src={
                      exp?.logo instanceof File
                        ? URL.createObjectURL(exp?.logo)
                        : exp?.logo
                    }
                  />
                )}
                <div style={{ width: "100%" }}>
                  <div className="space flex-wrap" style={{ gap: "0.5rem" }}>
                    <p className="black16 text-start">{exp?.schoolName}</p>
                    <p className="gray14">
                      {exp?.from}-{exp?.to}
                    </p>
                  </div>
                  <div className="d-flex flex-wrap gap-2 align-items-center">
                    {" "}
                    <p
                      className="gray14 d-flex flex-wrap"
                      style={{ gap: "0.5rem", alignItems: "center" }}
                    >
                      {exp?.location?.flag} {exp?.location?.name}
                    </p>
                    <div
                      className=""
                      style={{
                        background: "#667085",
                        height: "4px",
                        width: "4px",
                        borderRadius: "125px",
                      }}
                    />
                    <p
                      className="gray14 d-flex flex-wrap"
                      style={{ gap: "0.5rem", alignItems: "center" }}
                    >
                      {exp?.degree}
                    </p>
                  </div>
                  {exp?.description && (
                    <p className="gray14" style={{ marginTop: "0.75rem" }}>
                      {exp?.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      <div className="mt-3">
        <div className="d-flex">
          <p
            className=" text-start backbutton hoverred mt-3"
            onClick={() => setAddEducation(true)}
            style={{ cursor: "pointer" }}
          >
            Add Education
          </p>
        </div>
        <hr className="mt-5" />
        <button
          className="continuebutton mt-2"
          onClick={() => handleUpdateEducation()}
          disabled={loading}
        >
          Update Education
        </button>
      </div>
    </div>
  );
};

export default EducationList;
