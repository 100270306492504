import CloseIcon from "@mui/icons-material/Close";
import { Spiral as Hamburger } from "hamburger-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
const Header = ({
  company,
  title,
  setPreview,
  isNonMobile1024,
  Test,
  type,
  actual,
  setTest,
  referenceDiv2,
}) => {
  const handleToggle = (e) => {
    e.stopPropagation();
    setTest((prevState) => !prevState);
  };
  const navigate = useNavigate();
  const popupref = useRef(null);
  const [popup, setpopup] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setpopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-100 previewwholeheader shadowa">
      <div className="space align-items-center">
        <div className="d-flex align-items-center" style={{ gap: "1.25rem" }}>
          <img
            src={company.img}
            alt="company logo"
            height={"auto"}
            width={"36px"}
            style={{ borderRadius: "2px", maxWidth: "36px" }}
          />
          <p>{title}</p>
        </div>
        {isNonMobile1024 ? (
          <div style={{ position: "relative" }}>
            <CloseIcon
              className="hoverdarkblacksvg"
              style={{ cursor: "pointer", height: "22px", width: "22px" }}
              onClick={() => {
                if (actual) {
                  setpopup(true);
                } else {
                  setPreview(false);
                }
              }}
            />
            {popup && (
              <div
                className="newChallengepopup shadowa mt-2"
                ref={popupref}
                style={{
                  position: "absolute",

                  zIndex: 10,
                  right: 0,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clipPath="url(#clip0_414_9677)">
                    <path
                      d="M9.99935 6.66797V10.0013M9.99935 13.3346H10.0077M18.3327 10.0013C18.3327 14.6037 14.6017 18.3346 9.99935 18.3346C5.39698 18.3346 1.66602 14.6037 1.66602 10.0013C1.66602 5.39893 5.39698 1.66797 9.99935 1.66797C14.6017 1.66797 18.3327 5.39893 18.3327 10.0013Z"
                      stroke="#DC6803"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_414_9677">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p
                  className="gray95014400"
                  style={{ fontWeight: 600, marginTop: "0.5rem" }}
                >
                  {type} Challenge
                </p>
                <p className="gray70014400" style={{ margin: "1rem 0rem" }}>
                  {type === "Fast Track"
                    ? " When you exit this challenge you will not be able to continue this challenge on a later stage."
                    : "This challenge is self-paced. You can continue later."}
                </p>
                <button
                  className="continuebutton w-100"
                  onClick={() => {
                    navigate("/student/Challenges");
                  }}
                >
                  Exit {type !== "Fast Track" && "(continue later)"}
                </button>
              </div>
            )}
          </div>
        ) : (
          <div onClick={handleToggle} ref={referenceDiv2}>
            <Hamburger toggled={Test} size={20} direction="right" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
