import { useEffect, useState } from "react";
import SuitCase from "../../assets/images/icons/Suitcase";
import CloseIcon from "@mui/icons-material/Close";
import AddExperience from "../StudentProfilePage/addExperience";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import ExperienceList from "./ExperienceList";
import CompanyImage from "../../components/Company/CompanyImage";
const Experience = ({ user, isNonMobile1024, axios, setWatcher, watcher }) => {
  const [popup, setPopup] = useState(false);

  const [experience, setExperience] = useState(
    user?.experience ? user?.experience : null
  );

  useEffect(() => {
    setNewExperience({
      logo: null,
      position: "",
      from: "",
      to: "",
      location: "",
      company: "",
      description: "",
    });
    setIndex(-1);
    setAddExperience(false);
  }, [popup]);

  useEffect(() => {
    if (!experience) {
      setExperience(user?.experience);
    }
  }, [user, popup]);

  const [index, setIndex] = useState(-1);

  const [newExperience, setNewExperience] = useState({
    logo: null,
    position: "",
    from: "",
    to: "",
    location: "",
    company: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);
  const [addExperience, setAddExperience] = useState(false);

  const handleUpdateExperience = async () => {
    if (experience.length === 0) {
      toast.error("Please add at least one experience.");
      return;
    }

    const formData = new FormData();

    experience.forEach((exp, index) => {
      formData.append(`experiences[${index}][company]`, exp.company);
      formData.append(`experiences[${index}][from]`, exp.from);
      formData.append(`experiences[${index}][to]`, exp.to);
      formData.append(`experiences[${index}][position]`, exp.position);
      formData.append(
        `experiences[${index}][location]`,
        JSON.stringify(exp.location)
      );
      formData.append(`experiences[${index}][description]`, exp.description);
      if (exp.logo) {
        formData.append(`experiences[${index}][logo]`, exp.logo); // Only if logo is present
      }
    });

    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/update/student/experiences`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Experiences updated successfully");
          setWatcher((prev) => {
            return {
              ...prev,
              experience: res.data.experienceData,
            };
          });
          setPopup(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update experiences");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleConfirm = async () => {
    if (!newExperience?.company) {
      toast.error("Please enter company name");
    } else if (!newExperience?.from) {
      toast.error("Please enter from date");
    } else if (!newExperience?.to) {
      toast.error("Please enter to date");
    } else if (!newExperience?.position) {
      toast.error("Please enter position");
    } else if (!newExperience?.description) {
      toast.error("Please Provide your experience at the company");
    } else if (!newExperience?.location) {
      toast.error("Please select location");
    } else {
      setExperience((prevExperience) => {
        const updatedExperience = [...prevExperience];
        if (index === -1) {
          updatedExperience.push(newExperience);

          return updatedExperience;
        } else {
          updatedExperience[index] = newExperience;

          setIndex(-1);

          return updatedExperience;
        }
      });
      setNewExperience({
        logo: null,
        position: "",
        from: "",
        to: "",
        location: "",
        company: "",
        description: "",
      });
      setAddExperience(false);
    }
  };

  return (
    <div
      className=""
      style={{
        padding: "1.25rem",
        marginTop: "1.875rem",
        background: "#FFF",
        borderRadius: "5px",
      }}
    >
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            overflowY: "auto",
            zIndex: 1001,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
          onClick={() => {
            setPopup(false);
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            style={{
              background: "#FFF",
              padding: "0.5rem",
              maxWidth: "715px",
              width: "100%",
            }}
          >
            <div
              className="  newPopUpContainer tablescroll"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "90vh",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="space align-items-center">
                <p className="gray90020400">Experience</p>
                <CloseIcon
                  className="hoverdarkblacksvg"
                  style={{ cursor: "pointer", height: "24px", width: "24px" }}
                  onClick={() => {
                    setPopup(false);
                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  height: "1px",
                  background: "#EAECF0",
                  margin: "0.75rem 0rem 1.5rem 0rem",
                }}
              />
              {addExperience ? (
                <AddExperience
                  newExperience={newExperience}
                  setNewExperience={setNewExperience}
                  addExperience={addExperience}
                  setAddExperience={setAddExperience}
                  handleConfirm={handleConfirm}
                />
              ) : (
                <ExperienceList
                  setAddExperience={setAddExperience}
                  loading={loading}
                  experience={experience}
                  setEdit={setNewExperience}
                  setIndex={setIndex}
                  handleUpdateExperience={handleUpdateExperience}
                  setExperience={setExperience}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="space gap-3 align-items-center flex-wrap">
        <p className="gray90020400">Experience</p>
        {user?._id === watcher?._id && (
          <EditIcon
            className="hoverred"
            style={{ cursor: "pointer", height: "20px", width: "20px" }}
            onClick={() => {
              setPopup(true);
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "100%",
          height: "1px",
          background: "#EAECF0",
          margin: "0.75rem 0rem 1.5rem 0rem",
        }}
      />
      {user?.experience?.length > 0 ? (
        <>
          {user?.experience.map((exp, index) => (
            <div key={index}>
              {exp?.company && (
                <div style={{ marginTop: "1.5rem" }}>
                  <div className="d-flex flex-column" style={{ gap: "1rem" }}>
                    {exp?.logo && (
                      <CompanyImage
                        src={
                          exp?.logo instanceof File
                            ? URL.createObjectURL(exp?.logo)
                            : exp?.logo
                        }
                      />
                    )}
                    <div style={{ width: "100%" }}>
                      <div className="space flex-wrap" style={{ gap: "1rem" }}>
                        <p className="black16 text-start">{exp?.position}</p>
                        <p className="gray14400">
                          {exp?.from}-{exp?.to}
                        </p>
                      </div>
                      <div className="d-flex flex-wrap align-items-center gap-2 mt-2">
                        <p
                          className="gray14400 d-flex flex-wrap align-items-center"
                          style={{
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          {exp?.location?.flag} {exp?.location?.name}
                        </p>
                        <div
                          className=""
                          style={{
                            background: "#667085",
                            height: "4px",
                            width: "4px",
                            borderRadius: "125px",
                          }}
                        />
                        <p
                          className="gray14400 d-flex flex-wrap"
                          style={{
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          {exp?.company}
                        </p>
                      </div>
                      {exp?.description && (
                        <p
                          className="gray14400"
                          style={{ marginTop: "0.75rem" }}
                        >
                          {exp?.description}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </>
      ) : (
        <div>
          <div className="center">
            <SuitCase />
          </div>
          <p
            className="gray40016500 text-center my-2"
            style={{ color: "#FD6F8E" }}
          >
            No Experience Yet
          </p>

          <div className="center">
            <p
              className="text-center gray60011400"
              style={{ maxWidth: "340px", fontSize: "14px" }}
            >
              <span
                className="gray60011400"
                style={{ fontSize: "14px", fontWeight: 500 }}
              >
                Add your Education.
              </span>{" "}
              It's for sure will help you to find Jobs.
            </p>
          </div>
          <p
            className="pink16 text-center mt-3 hovercolorback"
            style={{ cursor: "pointer" }}
            onClick={() => setPopup(true)}
          >
            Add Experience
          </p>
        </div>
      )}
    </div>
  );
};

export default Experience;
