import logo from "../../assets/images/logo.png";
import { useMediaQuery } from "@mui/material";
import "./login.css";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setId } from "../../state";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
const Company = () => {
  const isNonMobile768 = useMediaQuery("(min-width: 768px)");

  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter email address ");
      return;
    }
    if (!password) {
      toast.error("Please enter Password");
      return;
    }
    setLoader(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/company/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.msg === "success") {
          dispatch(setId({ uniqueId: res.data.user.uniqueId }));

          navigate("/dashboard");
        } else if (res.data.msg === "notreg") {
          setError("Email Not Registered");
        } else if (res.data.msg === "wrongpass") {
          setError("Incorrect Password");
        }
        setLoader(false);
      })
      .catch((err) => {
        setError("Error Logging in");
        console.log(err);
        setLoader(false);
      });
  };

  const handleLinkedInSignUp = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/linkedin/login/authorize`
      );
      const { authorizationUrl } = response.data;
      window.location.href = authorizationUrl; // Redirect the user to the LinkedIn authorization URL
    } catch (error) {
      console.error("Error signing up with LinkedIn:", error);
    }
  };

  return (
    <div className="loginbackground d-flex">
      <div style={{ width: isNonMobile768 ? "50%" : "100%" }}>
        <div
          className="marginsleftrightCompany"
          style={{ marginTop: "2.5rem" }}
        >
          <img
            src={logo}
            alt="logo"
            height="36px"
            width="36px"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
          <div
            style={{
              width: "100%",
              marginTop: "2.5rem",
            }}
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <p className="registeration">Log In</p>
              <p style={{ marginTop: "6px" }} className="headersmalltext">
                Welcome back! Please enter your details.
              </p>
              <div style={{ marginTop: "1.25rem" }}>
                <label htmlFor="email" className="Email">
                  Email
                </label>
                <input
                  value={email}
                  id="email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="rounded-3   col-md-12 col-12  newinputs "
                  style={{ marginTop: "12px" }}
                />
              </div>
              <div style={{ marginTop: "1.25rem" }}>
                <label htmlFor="password" className="Email">
                  Password
                </label>
                <div className="position-relative">
                  <input
                    id="password"
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    className="rounded-3 newinputs mt-3 col-md-12 col-12 "
                    style={{ marginTop: "14px" }}
                  />
                  <div
                    className="position-absolute end-10 top-50 translate-middle-y"
                    style={{
                      right: "10px",
                      cursor: "pointer",
                      maxWidth: "440px",
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </div>
                </div>
              </div>
              {error && (
                <p
                  className="headersmalltext"
                  style={{ marginTop: "0.5rem", color: "red" }}
                >
                  {error}
                </p>
              )}
              <div
                className="d-flex"
                style={{
                  alignItems: "center",
                  marginTop: "0.5rem",
                  rowGap: "0.5rem",
                  justifyContent: "end",
                }}
              >
                <p
                  className="red14 hoverdarkred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/employer/forgot/password");
                  }}
                >
                  Forgot password?
                </p>
              </div>
              <button
                className="continuebutton col-md-12 col-12"
                style={{ marginTop: "1.25rem" }}
                type="submit"
                disabled={loader}
              >
                Sign in
              </button>
            </form>
            <button
              className=" linkedinButton col-md-12 col-12"
              style={{ marginTop: "1.25rem", maxHeight: "40px" }}
              onClick={() => handleLinkedInSignUp()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <g clipPath="url(#clip0_5582_23599)">
                  <path
                    d="M22.7283 0.168945H2.27167C1.80179 0.168945 1.35116 0.355603 1.01891 0.687855C0.686657 1.02011 0.5 1.47074 0.5 1.94061V22.3973C0.5 22.8672 0.686657 23.3178 1.01891 23.65C1.35116 23.9823 1.80179 24.1689 2.27167 24.1689H22.7283C23.1982 24.1689 23.6488 23.9823 23.9811 23.65C24.3133 23.3178 24.5 22.8672 24.5 22.3973V1.94061C24.5 1.47074 24.3133 1.02011 23.9811 0.687855C23.6488 0.355603 23.1982 0.168945 22.7283 0.168945ZM7.65333 20.6139H4.045V9.15228H7.65333V20.6139ZM5.84667 7.56395C5.43736 7.56164 5.03792 7.43814 4.69873 7.20903C4.35955 6.97993 4.09584 6.65548 3.94088 6.27663C3.78591 5.89779 3.74665 5.48154 3.82803 5.0804C3.90941 4.67926 4.1078 4.31122 4.39816 4.02273C4.68851 3.73423 5.05782 3.53821 5.45947 3.45941C5.86112 3.3806 6.27711 3.42254 6.65495 3.57993C7.03279 3.73732 7.35554 4.00311 7.58247 4.34376C7.80939 4.6844 7.93032 5.08464 7.93 5.49395C7.93386 5.76798 7.88251 6.03998 7.77901 6.29375C7.67551 6.54751 7.52198 6.77785 7.32757 6.97101C7.13316 7.16418 6.90185 7.31623 6.64742 7.4181C6.393 7.51996 6.12067 7.56957 5.84667 7.56395ZM20.9533 20.6239H17.3467V14.3623C17.3467 12.5156 16.5617 11.9456 15.5483 11.9456C14.4783 11.9456 13.4283 12.7523 13.4283 14.4089V20.6239H9.82V9.16061H13.29V10.7489H13.3367C13.685 10.0439 14.905 8.83895 16.7667 8.83895C18.78 8.83895 20.955 10.0339 20.955 13.5339L20.9533 20.6239Z"
                    fill="#0A66C2"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5582_23599">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.5 0.168945)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Continue with LinkedIn
            </button>
            <p className="didnt text-center " style={{ marginTop: "1.5rem" }}>
              Don't have an account?
              <span
                className="hoverdarkred"
                onClick={() => navigate("/employer/register")}
              >
                {" "}
                Sign up
              </span>
            </p>
          </div>
        </div>
      </div>
      {isNonMobile768 && (
        <div style={{ width: "50%" }} className="companyrightimg"></div>
      )}
    </div>
  );
};

export default Company;
