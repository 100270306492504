import "./fifthSection.css";
import { useNavigate } from "react-router-dom";
const FifthSection = ({
  company,

  Userloggedin,

  checker,
  StartChallenge,
  color,
  no,
  setPreview,
  test,
}) => {
  const navigate = useNavigate();
  return (
    <div className="marginsleftrightCompany" style={{ marginTop: "7.188rem" }}>
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <div className="headerCompany ">
          <div
            className="center"
            style={{ alignItems: "center", height: "26rem" }}
          >
            <div
              className="d-flex"
              style={{
                flexDirection: "column",
                alignItems: "center",
                gap: "3rem",
              }}
            >
              {company && company.img && (
                <img
                  src={company.img}
                  alt="company logo"
                  className="img-fluid rounded-2 shadowa"
                  style={{
                    maxHeight: "4.5rem",
                    maxWidth: "4.5rem",
                    background: "#FFF",
                  }}
                />
              )}
              <div style={{ maxWidth: "550px" }}>
                <p className="registeration text-center">
                  Explore your future career at {company?.title}
                </p>

                <div className="center">
                  <p
                    className="black16"
                    style={{ maxWidth: "458px", marginTop: "0.75rem" }}
                  >
                    Start this challenge today and advance skills relevant
                    to your future career
                  </p>
                </div>
              </div>

              <button
                className="continuebutton"
                onClick={() => {
                  if (no) {
                    setPreview(true);
                  } else {
                    if (Userloggedin && !checker) {
                      StartChallenge();
                    } else if (Userloggedin && checker) {
                      navigate("/dashboard/Challenges");
                    } else {
                      navigate("/login");
                    }
                  }
                }}
                style={{ background: color ? color : "" }}
              >
                {test
                  ? Userloggedin
                    ? "Start Challenge"
                    : "Signin to start"
                  : "Start Challenge"}
              </button>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default FifthSection;
