import React, { useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Country, City } from "country-state-city";

import { toast } from "react-toastify";
import axios from "axios";

import upload from "../../assets/images/icons/upload.png";

import InterestItems from "../../components/InterestItems/InterestItems";
const Details = ({ user }) => {
  const [firstName, setFirstName] = useState(user?.firstName ?? "");
  const [lastName, setLastName] = useState(user?.LastName ?? "");
  const [levelofstudy, setLevelOfStudy] = useState(user?.levelofstudy ?? "");
  const [study, setStudy] = useState(user?.study ?? "");
  const [country, setCountry] = useState(
    user?.country ?? { Country: "", City: "" }
  );

  const [selectedItem, setSelectedItem] = useState(user?.selectedItem ?? []);
  const [selectedFile, setSelectedFile] = useState(user?.cv ?? null);

  const studys = [
    "First Year",
    "2nd Year",
    "3rd Year",
    "Bachelor",
    "Masters Program",
    "PHD",
    "State Examination",
    "MBA",
    "Doctorate",
    "Postdoctoral Studies",
  ];

  const options = [
    "Humanity",
    "Business",
    "Law",
    "Engineering",
    "MINT",
    "Social Sciences",
    "Psychology",
    "Political Sciences",

    "Others",
  ];

  const allCountries = Country.getAllCountries();
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [search, setSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");

  const handleCountrySearch = (e) => {
    setSearch(e.target.value);
    const filteredCountries = allCountries.filter((country) =>
      country.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredLocations(filteredCountries);
  };

  const handleSelectCountry = (location) => {
    setCountry((prevCountry) => {
      const updatedCountry = prevCountry;
      updatedCountry.Country = location;
      updatedCountry.City = "";

      return updatedCountry;
    });
    setSearch("");
  };

  const handleSelectCity = (location) => {
    setCountry((prevCountry) => {
      const updatedCountry = prevCountry;
      updatedCountry.City = location;

      return updatedCountry;
    });
    setCitySearch("");
  };

  const handleCitySearch = (e) => {
    setCitySearch(e.target.value);
    const filtered = City.getCitiesOfCountry(country.Country.isoCode).filter(
      (country) =>
        country.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredCities(filtered);
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const fileInputRef = React.createRef();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
      // Here you can handle the file upload process
      // For example, you could upload the file to a server
      fileInputRef.current.value = null;
    } else {
      toast.error("Please upload a PDF file.");
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("levelofstudy", levelofstudy);
    formData.append("study", study);
    formData.append("country", JSON.stringify(country));
    formData.append("selectedItem", JSON.stringify(selectedItem));

    if (selectedFile instanceof File) {
      formData.append("selectedFile", selectedFile);
    } else {
      formData.append("selectedFile", JSON.stringify(selectedFile));
    }

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/student/settings/details`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.msg === "success") {
          toast.success("Details updated successfully");
          window.location.reload();
        } else {
          toast.error("Error updating details");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <p className="black14black">Personal info</p>
      <p className="gray14new" style={{ marginTop: "0.25rem" }}>
        Update your photo and personal details here.
      </p>
      <hr
        style={{
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
          border: "none",
          borderTop: "1px solid #E4E6E8",
        }}
      />
      <p className="black14black">First Name *</p>
      <input
        className="newinputs col-md-12 col-12 mt-2"
        style={{ maxWidth: "700px" }}
        value={firstName}
        onChange={(e) => {
          setFirstName(e.target.value);
        }}
      />
      <div style={{ marginTop: "2.5rem" }}>
        <p className="black14black">Last Name *</p>
        <input
          className="newinputs col-md-12 col-12 mt-2"
          style={{ maxWidth: "700px" }}
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: "2.5rem" }}>
        <p className="black14black">
          Level of Study{" "}
          <HelpOutlineIcon style={{ height: "16px", width: "16px" }} />
        </p>
        <p className="gray14new" style={{ marginTop: "0.25rem" }}>
          This will be displayed on your profile.
        </p>
        <div
          className="col-md-12 d-flex flex-wrap"
          style={{ gap: "0.75rem", marginTop: "0.75rem", maxWidth: "700px" }}
        >
          {studys &&
            studys.map((tol, index) => (
              <div
                className="difficultybuttons"
                key={index}
                style={{
                  cursor: "pointer",
                  backgroundColor: levelofstudy === tol ? "#FFF5F6" : "",
                  color: levelofstudy === tol ? "#E31B54" : "",
                  border: levelofstudy === tol ? "1px solid #FFE4E8" : "",
                }}
                onClick={() => setLevelOfStudy(tol)}
              >
                {tol}
              </div>
            ))}
        </div>
      </div>
      <div style={{ marginTop: "2.5rem" }}>
        <p className="black14black">
          Change your Course{" "}
          <HelpOutlineIcon style={{ height: "16px", width: "16px" }} />
        </p>
        <p className="gray14new" style={{ marginTop: "0.25rem" }}>
          This will be displayed on your profile.
        </p>
        <select
          className="forminput col-md-12 col-12 step2Options"
          value={study}
          style={{ marginTop: "0.75rem", maxWidth: "700px" }}
          onChange={(e) => setStudy(e.target.value)}
        >
          <option value="" disabled className="options">
            e.g. General Management / Finance / Law
          </option>
          {options.map((opt, index) => (
            <option key={index} value={opt} className="options">
              {opt}
            </option>
          ))}
        </select>
      </div>
      <div style={{ marginTop: "2.5rem" }}>
        <p className="black14black">Country</p>

        <input
          value={search}
          placeholder="Search Country"
          onChange={(e) => handleCountrySearch(e)}
          className="forminput col-md-12 col-12 "
          style={{ marginTop: "0.75rem", maxWidth: "700px" }}
        />
        {country.Country?.name && (
          <div className="d-flex" style={{ marginTop: "0.75rem" }}>
            <p
              className="black14 p-2 d-flex gap-3 rounded-5 shadowa"
              style={{ background: "#FFF" }}
            >
              <span> {country.Country?.flag}</span>
              {country.Country.name}
            </p>
          </div>
        )}
        {filteredLocations.length > 0 && search && (
          <div
            className="rounded-2 d-flex flex-column tablescroll shadowa"
            style={{
              position: "absolute",
              background: "#FFF",
              gap: "1rem",
              minWidth: "300px",
              maxHeight: "200px",
              padding: "1rem",
              overflow: "auto",
              zIndex: 3,
              marginTop: "0.75rem",
            }}
          >
            {filteredLocations.map((location) => (
              <p
                className="d-flex gap-3 black14"
                key={location.name}
                style={{ cursor: "pointer" }}
                onClick={() => handleSelectCountry(location)}
              >
                <span> {location.flag}</span>
                {location.name}
              </p>
            ))}
          </div>
        )}
        {country.Country?.name !== "" && (
          <>
            <p className="formtext " style={{ marginTop: "1rem" }}>
              City *
            </p>
            <input
              value={citySearch}
              placeholder="Search city"
              onChange={(e) => handleCitySearch(e)}
              className="forminput col-md-12 col-12 mt-2"
              style={{ marginTop: "0.75rem", maxWidth: "700px" }}
            />
            {country.City?.name && (
              <div className="d-flex" style={{ marginTop: "0.75rem" }}>
                <p
                  className="black14 p-2 d-flex gap-3 rounded-5 shadowa"
                  style={{ background: "#FFF" }}
                >
                  {country.City.name}
                </p>
              </div>
            )}
            {filteredCities.length > 0 && citySearch && (
              <div
                className="rounded-2 d-flex flex-column tablescroll shadowa"
                style={{
                  position: "absolute",
                  background: "#FFF",
                  gap: "1rem",
                  minWidth: "300px",
                  maxHeight: "200px",
                  padding: "1rem",
                  overflow: "auto",
                  zIndex: 3,
                  marginTop: "0.75rem",
                }}
              >
                {filteredCities.map((location) => (
                  <p
                    className="d-flex gap-3 black14"
                    key={location.name}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSelectCity(location)}
                  >
                    <span> {location.flag}</span>
                    {location.name}
                  </p>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <div style={{ marginTop: "2.5rem" }}>
        <p className="black14black">Change your Industry Preferences</p>
        <p className="gray14new" style={{ marginTop: "0.25rem" }}>
          Preferences will define your experience on the platform in terms of
          recommended career tracks.
        </p>
        <div
          className=""
          style={{
            marginTop: "0.75rem",
            maxWidth: "700px",
          }}
        >
          <InterestItems
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        </div>
      </div>
      <div style={{ marginTop: "2.5rem", maxWidth: "700px" }}>
        <p className="black14black">
          Upload your CV{" "}
          <HelpOutlineIcon style={{ height: "16px", width: "16px" }} />
        </p>
        <p className="gray14new" style={{ marginTop: "0.25rem" }}>
          Your CV is required for most recruiting solutions on our platform.
          Update your CV before starting a new challenge or participating in an
          event, e.g. update already completed challenges, fundamentals or other
          experiences).
        </p>
        <div
          className="col-md-12 col-12  border rounded-3 d-flex circlebackground"
          style={{
            backgroundColor: "#FFF",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "180px",
            maxHeight: "180px",
            marginTop: "0.75rem",
          }}
        >
          <div>
            <div
              className="d-flex mt-3 mb-3"
              style={{ justifyContent: "center" }}
            >
              <div>
                <div style={{ gap: "5px", justifyContent: "center" }}>
                  <div className="center">
                    {" "}
                    <img src={upload} height="40px" width="40px" alt="upload" />
                  </div>
                  <p
                    className="text-center clicktoupload"
                    onClick={openFileInput}
                    style={{ cursor: "pointer" }}
                  >
                    {selectedFile ? "Click to change CV" : "Click to upload CV"}
                  </p>
                  <p className="svgpngtext">Upload your CV as a PDF.</p>
                </div>

                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex" style={{ marginTop: "0.75rem" }}>
          {selectedFile && (
            <div
              className="d-flex rounded-3 "
              style={{ background: "#FFF", padding: "6px 12px" }}
            >
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="40"
                  viewBox="0 0 32 40"
                  fill="none"
                >
                  <path
                    d="M0 4C0 1.79086 1.79086 0 4 0H20L32 12V36C32 38.2091 30.2091 40 28 40H4C1.79086 40 0 38.2091 0 36V4Z"
                    fill="#C01048"
                  />
                  <path
                    opacity="0.3"
                    d="M20 0L32 12H24C21.7909 12 20 10.2091 20 8V0Z"
                    fill="white"
                  />
                </svg>
              </div>{" "}
              <p
                style={{
                  color: "#E31B54",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "14px",
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="  m-2 d-flex"
              >
                {selectedFile?.originalName
                  ? selectedFile.originalName.slice(0, 20) + "..."
                  : selectedFile?.name
                  ? selectedFile.name.length > 20
                    ? selectedFile.name.slice(0, 20) + "..."
                    : selectedFile.name
                  : ""}
              </p>
              <div
                className=" d-flex"
                style={{
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setSelectedFile(null);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M9.30108 8.28369L14.3648 3.23713C14.5763 3.02359 14.5763 2.67765 14.3648 2.46411C14.157 2.24675 13.8138 2.24022 13.598 2.44952L8.53429 7.49607L3.5357 2.44952C3.43322 2.34024 3.29063 2.27832 3.14145 2.27832C2.99228 2.27832 2.84969 2.34024 2.74721 2.44952C2.55964 2.65584 2.55964 2.97248 2.74721 3.17879L7.7458 8.21805L2.6821 13.2573C2.47055 13.4709 2.47055 13.8168 2.6821 14.0303C2.78282 14.1343 2.92133 14.1923 3.0655 14.1908C3.21244 14.2028 3.35811 14.1556 3.47059 14.0595L8.53429 9.01296L13.598 14.1179C13.6987 14.2219 13.8372 14.2798 13.9814 14.2783C14.1254 14.279 14.2636 14.2211 14.3648 14.1179C14.5763 13.9043 14.5763 13.5584 14.3648 13.3448L9.30108 8.28369Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
      <hr
        style={{
          marginTop: "7rem",
          marginBottom: "1.5rem",
          border: "none",
          borderTop: "1px solid #E4E6E8",
        }}
      />
      <div
        className="d-flex mb-5 pb-3"
        style={{ justifyContent: "end", maxWidth: "700px" }}
      >
        <button className="continuebutton " onClick={() => handleSubmit()}>
          Save
        </button>
      </div>
    </div>
  );
};
export default Details;
