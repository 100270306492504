import { useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import AllTouchpoints from "./AllTouchpoints";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setEmployer } from "../../state";
import { setCompany as set } from "../../state";
import axios from "axios";
import Talent from "../../components/Company/Dashboard/EngagedTalent";
const TalentPool = () => {
  const isNonMob1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const isNonMob1441 = useMediaQuery("(min-width:1441px)");

  const [selected, setSelected] = useState("All Touchpoints");
  const email = useSelector((state) => state.email);
  const [user, setUser] = useState([]);
  const [results, setResults] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const dispatch = useDispatch();
  const [analytics, setAnalytics] = useState("12 months");
  const navigate = useNavigate();
  const step = useSelector((state) => state.step);
  const [company, setCompany] = useState();
  const id = useSelector((state) => state.uniqueId);
  const [challenges, setChallenges] = useState([]);
  const [jobposts, setJobposts] = useState([]);
  const [blocked, setBlocked] = useState(null);
  const [bulkAction, setBulkAction] = useState("");
  const [DATA, setData] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [realRes, setRealRes] = useState([]);
  const [livechallengesLength, setLength] = useState(0);
  const handleShortList = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Talentpool",
          owner: user._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Added to Shortlist");
          setData((prev) => !prev);
        }
      });
  };

  const handleBulkAction = () => {
    if (bulkAction === "Shortlist") {
      if (selectedStudents.length > 0) {
        selectedStudents.map(async (id) => {
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Talentpool",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Added successfully");
        setSelectedStudents([]);
      }
    } else if (bulkAction === "Remove") {
      if (selectedStudents.length > 0) {
        selectedStudents.map((id) => {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/blocked/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Talentpool",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Removed successfully");
        setSelectedStudents([]);
      }
    }
  };

  const handleRemove = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/blocked/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Talentpool",
          owner: user._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Removed");
          setData((prev) => !prev);
        }
      });
  };

  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotalChallenges(results.length);
  }, [results]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
          dispatch(setEmployer({ employer: userInfoResponse.data.user }));
        } else {
          navigate("/employer/register");
          return; // Exit early if user not found
        }

        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: userInfoResponse.data.user.company }
        );
        if (companyInfoResponse.data.msg === "Logout") {
          navigate("/employer/register");
          return;
        }

        setCompany(companyInfoResponse.data);

        const resultres = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/challenges/results`,
          {
            uniqueId: companyInfoResponse.data.uniqueId,
          }
        );

        dispatch(set({ company: companyInfoResponse.data.uniqueId }));
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/company/challenges`,
          {
            uniqueId: companyInfoResponse.data.uniqueId,
          }
        );
        const blockedC = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/company/get/blocked/student`,
          {
            companyId: companyInfoResponse.data.uniqueId,
            msg: "sdakfksjfbjkasfjkfdas",
            userId: userInfoResponse.data.user._id,
            type: "Challenge",
          }
        );

        if (resultres.data.msg === "success") {
          if (blockedC.data.msg === "success") {
            let challen = [...resultres.data.results];

            challen = challen.filter((item) => {
              return !blockedC?.data?.list.some((block) =>
                block.list.some((bl) => bl.user === item.user._id)
              );
            });

            console.log({ challen });
            setResults(challen);
            setRealRes(challen);
          } else {
            setResults(resultres.data.results);
            setRealRes(resultres.data.results);
          }
        } else {
          setResults([]);
          setRealRes([]);
        }

        const blocked = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/company/get/blocked/student`,
          {
            companyId: companyInfoResponse.data.uniqueId,
            msg: "sdakfksjfbjkasfjkfdas",
            type: "Talentpool",
            userId: userInfoResponse.data.user._id,
          }
        );

        if (blocked.data.msg === "success") {
          setBlocked(blocked.data.list);
        }

        if (response.data.msg === "success") {
          setChallenges(response.data.challenges);
          setLength(response.data.challenges.length);
        } else if (response.data.msg === "Record not found") {
          console.log("");
        } else {
          toast.error("Error Fetching Challenges");
        }
        const responsea = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/get/company/jobs`,
          {
            uniqueId: companyInfoResponse.data.uniqueId,
          }
        );
        if (responsea.data.msg === "success") {
          setJobposts(responsea.data.challenges);
        } else if (responsea.data.msg === "Record not found") {
          setJobposts([]);
        } else {
          setJobposts([]);
          toast.error("Error Fetching Job posts");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [email, DATA]);

  const handleBulkActionChallenge = async () => {
    if (bulkAction === "Shortlist") {
      console.log("TEST");
      if (selectedStudent.length > 0) {
        selectedStudent.map(async (id) => {
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Challenge",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Added successfully");
        setSelectedStudent([]);
      }
    } else if (bulkAction === "Remove") {
      if (selectedStudent.length > 0) {
        selectedStudent.map(async (id) => {
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/api/company/blocked/student`,
              {
                id: id,
                companyId: company.uniqueId,
                msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
                type: "Challenge",
                owner: user._id,
              }
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setData((prev) => !prev);
              }
            });
        });
        toast.success("Removed successfully");
        setSelectedStudent([]);
      }
    }
  };

  const [totalchal, setTotalChallenges] = useState(0);

  return (
    <div className="paddingmaincontent">
      <div>
        <div
          className={`transition ${isNonMobile && "d-flex"} `}
          style={{
            justifyContent: "space-between",
            flexFlow: "wrap",
            gap: "10px",
          }}
        >
          <div className=" behindbuttons ">
            <button
              className={`  border-0 d-flex ${
                selected === "All Touchpoints"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={
                isNonMobile
                  ? {
                      minWidth: "188px",
                    }
                  : {}
              }
              onClick={() => {
                setSelected("All Touchpoints");
              }}
            >
              All Touchpoints
              {total > 0 && <div className="countheading">{total}</div>}
            </button>
            <button
              className={` border-0  d-flex ${
                selected === "Challenges"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={isNonMobile ? { minWidth: "188px" } : {}}
              onClick={() => {
                setSelected("Challenges");
              }}
            >
              Challenges
              {totalchal > 0 && <div className="countheading">{totalchal}</div>}
            </button>
            <button
              className={`   border-0 ${
                selected === "Applications"
                  ? "selectedbutton rounded-2 "
                  : "secondbuttontext"
              } ${isNonMobile ? "" : "p-1  "}`}
              style={isNonMobile ? { minWidth: "188px" } : {}}
              onClick={() => {
                setSelected("Applications");
              }}
            >
              Applications
            </button>
          </div>
        </div>
        <div style={{ marginTop: "4.5rem" }}>
          {selected === "All Touchpoints" && (
            <AllTouchpoints
              checker={[]}
              TRUE={true}
              handleBulkAction={handleBulkAction}
              isNonMob768={isNonMobile}
              challenges={challenges}
              jobposts={jobposts}
              analytics={analytics}
              selectedStudents={selectedStudents}
              setSelectedStudents={setSelectedStudents}
              bulkAction={bulkAction}
              setBulkAction={setBulkAction}
              handleShortList={handleShortList}
              handleRemove={handleRemove}
              blocked={blocked}
              isNonMob1441={isNonMob1441}
              isNonMob1024={isNonMob1024}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
              setTotal={setTotal}
            />
          )}
          {selected === "Challenges" && (
            <Talent
              results={results}
              livechallengesLength={livechallengesLength}
              selectedStudents={selectedStudent}
              setSelectedStudent={setSelectedStudent}
              DATA={DATA}
              TRUE={true}
              setData={setData}
              company={company}
              user={user}
              bulkAction={bulkAction}
              setBulkAction={setBulkAction}
              handleBulkAction={handleBulkActionChallenge}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
              challenges={challenges}
            />
          )}
          {selected === "Applications" && (
            <Talent
              results={[]}
              TRUE={true}
              livechallengesLength={livechallengesLength}
              selectedStudents={selectedStudents}
              setSelectedStudent={setSelectedStudent}
              DATA={DATA}
              setData={setData}
              company={company}
              user={user}
              bulkAction={bulkAction}
              setBulkAction={setBulkAction}
              handleBulkAction={handleBulkAction}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
              challenges={challenges}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default TalentPool;
