const Navigations = () => {
  const buttons = [
    {
      name: "Students",
      buttons: [
        { name: "Platform", function: () => {}, link: "/" },
        {
          name: "Corporate Track",
          function: () => {},
          link: "/corporate-track",
        },
        {
          name: "Innovation Track",
          function: () => {},
          link: "/innovation-track",
        },
        {
          name: "Magazin",
          function: () => {},
          link: "https://varyance.io/magazine/",
        },
      ],
      buttons2: [
        {
          name: "Login",
          function: () => {},
          link: "/login",
        },
        {
          name: "Join Now",
          function: () => {},
          link: "/register",
        },
      ],
    },
    {
      name: "Employer-Hub",
      buttons: [
        { name: "Overview", function: () => {}, link: "/employer" },
        {
          name: "Solutions",
          function: () => {},
          link: "/employer/solutions-challenges",
        },
        { name: "Accelerate DE&I", function: () => {}, link: "/" },
      ],
      buttons2: [
        {
          name: "Onboard (free)",
          function: () => {},
          link: "/employer/register",
        },

        {
          name: "Let's talk",
          function: () => {},
          link: "/contact",
        },
      ],
    },
    {
      name: "Career Centers",
      buttons: [
        { name: "Overview", function: () => {}, link: "/career-centers" },
        {
          name: "Marketing Toolkit",
          function: () => {},
          link: "/career-centers/marketing-toolkit/",
        },
        { name: "Webinars", function: () => {}, link: "/" },
      ],
      buttons2: [
        {
          name: "Onboard (free)",
          function: () => {},
          link: "/career-center/register",
        },
        {
          name: "Let's talk",
          function: () => {},
          link: "/career-center/contact",
        },
      ],
    },
    {
      name: "Student Organisations",
      buttons: [
        { name: "Overview", function: () => {}, link: "/" },
        {
          name: "CRM & Events",
          function: () => {},
          link: "/",
        },
      ],

      buttons2: [
        {
          name: "Onboard (free)",
          function: () => {},
          link: "/",
        },
        {
          name: "Let's talk",
          function: () => {},
          link: "/",
        },
      ],
    },
  ];

  return {
    buttons,
  };
};
export default Navigations;
