import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CompanyImage from "../../components/Company/CompanyImage";

const CertificateList = ({
  setAddCertificate,
  loading,
  certificates,
  setNewCertificate,
  setIndex,
  handleUpdateEducation,
  setCertificates,
}) => {
  return (
    <div>
      {certificates?.map((cert, index) => (
        <div key={index} style={{ marginTop: "1.5rem" }}>
          <div className="d-flex justify-content-end" style={{ gap: "1rem" }}>
            <EditIcon
              className="hoverred"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIndex(index);
                setNewCertificate(cert);
                setAddCertificate(true);
              }}
            />
            <DeleteIcon
              className="hoverred"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCertificates(certificates.filter((_, i) => i !== index));
              }}
            />
          </div>
          <div className="space flex-wrap col-md-12 col-12">
            <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
              {cert?.logo && (
                <CompanyImage
                  src={
                    cert?.logo instanceof File
                      ? URL.createObjectURL(cert?.logo)
                      : cert?.logo
                  }
                />
              )}
              <div>
                {cert?.name && (
                  <p className="black16 text-start">{cert?.name}</p>
                )}
                <p className="gray14" style={{ marginTop: "0.75rem" }}>
                  Issued Date: {cert?.issued}
                  <br />
                  Credential ID: {cert?.credentialId}
                </p>
              </div>
            </div>
            <div className="center" style={{ alignItems: "center" }}>
              <button
                className="backbutton"
                onClick={() =>
                  window.open(
                    `${cert?.link.startsWith("http") ? "" : "https://"}${
                      cert?.link
                    }`,
                    "_blank"
                  )
                }
              >
                Show Credential
              </button>
            </div>
          </div>
        </div>
      ))}
      <div className="mt-3">
        <div className="d-flex">
          <p
            className=" text-start backbutton hoverred mt-3"
            onClick={() => setAddCertificate(true)}
            style={{ cursor: "pointer" }}
          >
            Add Certificates
          </p>
        </div>
        <hr className="mt-5" />
        <button
          className="continuebutton mt-2"
          onClick={() => handleUpdateEducation()}
          disabled={loading}
        >
          Update Certificates
        </button>
      </div>
    </div>
  );
};

export default CertificateList;
