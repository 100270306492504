const Lightning = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.908 5.75C11.2649 5.13183 11.9242 4.75073 12.638 4.75L12.648 4.77C13.7525 4.77 14.648 5.66543 14.648 6.77V10.88H16.688C17.4268 10.8777 18.1068 11.2829 18.4563 11.9338C18.8058 12.5848 18.768 13.3754 18.358 13.99L13.808 20.85C13.3177 21.5795 12.4086 21.9035 11.5675 21.6484C10.7264 21.3933 10.1504 20.619 10.148 19.74V16.89H8.00796C7.29201 16.8928 6.62915 16.5128 6.26994 15.8935C5.91073 15.2741 5.90997 14.51 6.26796 13.89L10.908 5.75ZM12.998 6.39286C12.9021 6.2988 12.7723 6.24731 12.638 6.25C12.4549 6.25062 12.2881 6.35535 12.208 6.52L7.56796 14.64C7.48308 14.7951 7.48645 14.9835 7.57682 15.1355C7.6672 15.2875 7.83113 15.3805 8.00796 15.38H10.648C11.2002 15.38 11.648 15.8277 11.648 16.38V19.73C11.6506 19.9472 11.7932 20.1379 12.0009 20.2018C12.2085 20.2656 12.4336 20.1882 12.558 20.01L17.128 13.14C17.2321 12.9859 17.2422 12.7867 17.154 12.6229C17.0658 12.4591 16.894 12.3579 16.708 12.36H14.148C13.5957 12.36 13.148 11.9123 13.148 11.36V6.75C13.148 6.61564 13.0939 6.48692 12.998 6.39286Z"
        fill="#FC8309"
      />
    </svg>
  );
};
export default Lightning;
