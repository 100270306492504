import { useState } from "react";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Step7 = ({ isNonMobile, setStep, password, setPassword }) => {
  const [confirm, setConfirm] = useState("");
  const [visi, setVisi] = useState(false);
  const [visid, setVisid] = useState(false);
  const handleNext = () => {
    if (password !== confirm) {
      toast.error("Passwords do not match");
    } else if (password.length < 6) {
      toast.error("Please enter at least 6 characters");
    } else {
      setStep(8);
    }
  };

  return (
    <div className={`${isNonMobile ? "m-4 p-4" : "m-2 p-2"} `}>
      <button
        className="btn d-flex"
        style={{ justifyContent: "center", alignItems: "center" }}
        onClick={() => {
          setStep(6);
        }}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M10.6669 14.3329C10.5342 14.3335 10.4068 14.2807 10.3135 14.1862L4.98021 8.85291C4.78524 8.65769 4.78524 8.34145 4.98021 8.14624L10.3135 2.81291C10.5106 2.62933 10.8176 2.63475 11.008 2.82516C11.1984 3.01557 11.2038 3.32257 11.0202 3.51957L6.04021 8.49957L11.0202 13.4796C11.2152 13.6748 11.2152 13.991 11.0202 14.1862C10.927 14.2807 10.7996 14.3335 10.6669 14.3329Z"
              fill="black"
            />
          </svg>
        </div>
        <p className="backbuttonstudent">Back</p>
      </button>
      <p className="mainheading">Set Password</p>
      <div className="mt-4">
        <b style={{ fontFamily: "Poppins", fontSize: "16px" }}>Password :</b>
        <div className="d-flex " style={{ gap: "20px" }}>
          <input
            type={visi ? "text" : "password"}
            className="forminput col-md-10 col-10 mt-4 mb-3 "
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="btn" onClick={() => setVisi(!visi)}>
            <VisibilityIcon />
          </button>
        </div>
        <b style={{ fontFamily: "Poppins", fontSize: "16px" }}>
          Confirm Password :
        </b>
        <div className="d-flex " style={{ gap: "20px" }}>
          <input
            type={visid ? "text" : "password"}
            className="forminput col-md-10 col-10 mt-1"
            placeholder="Confirm Password"
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
          />
          <button className="btn" onClick={() => setVisid(!visid)}>
            <VisibilityIcon />
          </button>
        </div>
      </div>
      <button className="continuebutton mt-5" onClick={() => handleNext()}>
        Next
      </button>
    </div>
  );
};
export default Step7;
