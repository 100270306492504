import { useEffect, useRef } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReactQuill from "react-quill";
import axios from "axios";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { toast } from "react-toastify";
const MAX_FILE_SIZE_MB = 30;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes

const Sixth = ({
  divWidth,
  newTask,
  color,
  taskCount,
  frombefore,
  total,
  question,
  setQuestion,
  answers,
  setAnswers,
  show,
  setShow,
  files,
  setFiles,
  UpdateRecord,
  SubmitDone,
  setTaskCount,
  actual,
  timeleft,
  scrollToTop,
  isNonMobile1024,
  isNonMobile768,
  setPreview,
  taskCounter,
}) => {
  useEffect(() => {
    if (frombefore) {
      setQuestion(Number(frombefore?.question));
      setAnswers(JSON.parse(frombefore?.answer));
      setShow(JSON.parse(frombefore?.show));
      setFiles(frombefore?.files);
    }
  }, [frombefore]);

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "align",
  ];

  const handleSelect = (quest, opt, index) => {
    let check = -1;

    // Check if the taskCount and quest already exist in the show array
    if (!quest.isMultiSelect) {
      show.some((sh, idx) => {
        if (sh.taskCount === taskCount && sh.question === question) {
          check = idx;
          return true;
        }
        return false;
      });

      // Non-multi-select: Select or unselect the option
      if (check !== -1 && show[check].value === opt) {
        // Unselect the option if it's already selected
        const updatedShow = [...show];
        updatedShow.splice(check, 1); // Remove the entry
        setShow(updatedShow);
      } else if (check !== -1) {
        // Update the value if a different option is selected
        const updatedShow = [...show];

        updatedShow[check] = {
          taskCount,
          question: question,
          value: opt,
          index: index,
        };
        setShow(updatedShow);
      } else {
        // Add a new entry
        setShow([
          ...show,
          { taskCount, question: question, value: opt, index: index },
        ]);
      }
    } else {
      // Multi-select: Limit to quest.options.length - 1 selections
      const selectedOptions = show.filter(
        (sh) => sh.taskCount === taskCount && sh.question === question
      );

      if (
        selectedOptions.length === quest.options.length - 1 &&
        !selectedOptions.some((sh) => sh.value.value === opt.value)
      ) {
        // Prevent selecting more than quest.options.length - 1 options
        toast.error(
          "You can select only " + (quest.options.length - 1) + " options"
        );
        return;
      }

      if (selectedOptions.some((sh) => sh.value.value === opt.value)) {
        // Unselect the option if it's already selected
        const updatedShow = show.filter(
          (sh) =>
            !(
              sh.taskCount === taskCount &&
              sh.question === question &&
              sh.value.value === opt.value
            )
        );

        setShow(updatedShow);
      } else {
        // Add the new option to selected ones
        setShow([
          ...show,
          { taskCount, question: question, value: opt, index: index },
        ]);
      }
    }
  };

  const StepsCheck = async () => {
    if (question < newTask.questions.length - 1) {
      setQuestion(question + 1);
    } else if (taskCount < total - 1) {
      setTaskCount(taskCount + 1);
      setQuestion(0);
      scrollToTop();
    } else {
      SubmitDone(answers, show, files, timeleft);
    }
  };

  const handleNext = () => {
    if (actual) {
      if (newTask.questions[question].type === "Multiple Choice Question") {
        if (
          show.some(
            (sh) => sh.taskCount === taskCount && sh.question === question
          )
        ) {
          StepsCheck();
        } else {
          return toast.error("Please Select an option");
        }
      } else if (newTask.questions[question].type === "Text field") {
        if (
          answers.some(
            (sh) =>
              sh.taskCount === taskCount &&
              sh.question === question &&
              sh.value.replace(/(<([^>]+)>)/gi, "").length !== 0
          )
        ) {
          StepsCheck();
        } else {
          return toast.error("Please Provide an answer");
        }
      } else if (newTask.questions[question].type === "File Upload") {
        if (
          files.some(
            (sh) =>
              Number(sh.taskCount) === taskCount &&
              Number(sh.question) === question
          )
        ) {
          StepsCheck();
        } else {
          return toast.error("Please upload the answer file");
        }
      }
      UpdateRecord(question, answers, show, files, timeleft);
    } else {
      if (question < newTask.questions.length - 1) {
        setQuestion(question + 1);
      } else if (taskCount < total - 1) {
        if (taskCounter === taskCount) {
          setPreview(false);
        } else {
          setTaskCount(taskCount + 1);
          setQuestion(0);
          scrollToTop();
        }
      } else {
        setPreview(false);
      }
    }
  };

  const FileColour = (filename) => {
    const splitFileName = filename.split(".");
    const fileExtension = splitFileName[splitFileName.length - 1];
    if (fileExtension.toUpperCase() === "TXT") {
      return "#344054";
    } else if (fileExtension.toUpperCase() === "PPT") {
      return "#E62E05";
    } else if (fileExtension.toUpperCase() === "DOCX") {
      return "#155EEF";
    } else if (fileExtension.toUpperCase() === "XLSX") {
      return "#099250";
    } else {
      return "#344054";
    }
  };

  const FileBackground = (filename) => {
    const splitFileName = filename.split(".");
    const fileExtension = splitFileName[splitFileName.length - 1];
    if (fileExtension.toUpperCase() === "TXT") {
      return "rgba(52, 64, 84, 0.05)";
    } else if (fileExtension.toUpperCase() === "PPT") {
      return "rgba(230, 46, 5, 0.05)";
    } else if (fileExtension.toUpperCase() === "DOCX") {
      return "rgba(21, 94, 239, 0.05)";
    } else if (fileExtension.toUpperCase() === "XLSX") {
      return "rgba(2, 119, 189, 0.05)";
    } else {
      return "rgba(52, 64, 84, 0.05)";
    }
  };

  const handleDownload = (filename, originalname) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/download/${filename}`, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = originalname;
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading document:", error);
      });
  };

  const fileInputRef = useRef();

  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const FileType = (filename) => {
    const splitFileName = filename.split(".");
    const fileExtension = splitFileName[splitFileName.length - 1];
    return fileExtension.toUpperCase();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if the file size exceeds the limit
      if (file.size > MAX_FILE_SIZE_BYTES) {
        toast.error(`File size exceeds ${MAX_FILE_SIZE_MB}MB limit.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

      // Process file data (e.g., save to state)
      setFiles((prevAnswers) => {
        const existingAnswerIndex = prevAnswers.findIndex(
          (answer) =>
            Number(answer.taskCount) === taskCount &&
            Number(answer.question) === question
        );

        if (existingAnswerIndex !== -1) {
          // Update the existing answer
          const updatedAnswers = [...prevAnswers];
          updatedAnswers[existingAnswerIndex].file = file;
          return updatedAnswers;
        } else {
          // Add a new answer
          return [...prevAnswers, { taskCount, question, file }];
        }
      });
    } else {
      toast.error("Please select a valid file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const getFileIcon = (fileName) => {
    if (fileName && fileName.split(".")) {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileIcons = {
        pdf: "#155EEF",
        docx: "#155EEF",
        txt: "#344054",
        xlsl: "#099250",
        ppt: "#E62E05",
      };

      const color = fileIcons[fileExtension] || "#E62E05";

      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="40"
          viewBox="0 0 33 40"
          fill="none"
        >
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H20L32 12V36C32 38.2091 30.2091 40 28 40H4C1.79086 40 0 38.2091 0 36V4Z"
            fill={color}
          />
          <path
            opacity="0.3"
            d="M20 0L32 12H24C21.7909 12 20 10.2091 20 8V0Z"
            fill="white"
          />
          <text
            x="50%"
            y="70%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#fff"
            fontSize="8"
          >
            {fileExtension.toUpperCase()}
          </text>
        </svg>
      );
    }
  };

  return (
    <div style={{ height: "90%", minWidth: divWidth }}>
      <div
        className="keytermsdiv"
        style={{
          width: divWidth,
          //   height: "100%",
          minWidth: divWidth,
          height: "fit-content",
          minHeight: "100%",

          marginRight: "48px",
          // maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p className="gray14500">Ready?</p>
        <p
          style={{ marginTop: "0.75rem", color: "#0C111D" }}
          className="filmo20"
        >
          We have some questions for you.
        </p>
        <div
          className="w-100"
          style={{
            height: "1px",
            backgroundColor: "#EAECF0",
            marginTop: "1rem",
            marginBottom: "1.5rem",
          }}
        />
        <div>
          <div className="d-flex justify-content-end w-100">
            <div className="d-flex flex-wrap" style={{ gap: "0.75rem" }}>
              {newTask.questions.map((quest, index) => (
                <div
                  key={index}
                  className="questionNumber-whole"
                  style={{
                    color: question === index ? "#FFF" : color,
                    background:
                      question === index
                        ? color
                        : color && typeof color === "string"
                        ? `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(
                            color.slice(3, 5),
                            16
                          )}, ${parseInt(color.slice(5, 7), 16)}, 0.05)`
                        : "rgba(0, 0, 0, 0.05)",
                  }}
                >
                  Q{index + 1}
                </div>
              ))}
            </div>
          </div>
          <div>
            {newTask.questions[question].type === "Multiple Choice Question" &&
              newTask.questions[question]?.isMultiSelect && (
                <div
                  className="d-flex flex-wrap"
                  style={{
                    marginTop: "0.75rem",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="center"
                    style={{
                      height: "24px",
                      width: "24px",
                      background: "#FEF0C7",
                      padding: "1px",
                      borderRadius: "125px",
                      alignItems: "center",
                    }}
                  >
                    <ErrorOutlineIcon style={{ color: "#DC6803" }} />
                  </div>
                  <p className="black12">Multiple right answers possible!</p>
                </div>
              )}
            <p className="filmo16"> Question {question + 1}:</p>
            <p
              className="black14500"
              style={{ marginTop: "1.25rem", userSelect: "none" }}
            >
              {newTask.questions[question].question}
            </p>

            <div style={{ marginTop: "1.25rem" }}>
              {newTask.questions[question].type ===
                "Multiple Choice Question" && (
                <div
                  className="mt-4 flex-wrap d-flex"
                  style={{
                    gap: "20px",
                    flexDirection: isNonMobile768 ? "row" : "column",
                  }}
                >
                  {newTask.questions[question] &&
                    newTask.questions[question].options &&
                    newTask.questions[question].options.map((opt, index) => {
                      const isMultiSelect =
                        newTask.questions[question].isMultiSelect;

                      const isSelected = isMultiSelect
                        ? show.some(
                            (selec) =>
                              selec.taskCount === taskCount &&
                              selec.question === question &&
                              selec.index === index && // Check based on index
                              selec.value.value === opt.value
                          )
                        : show.some(
                            (selec) =>
                              selec.taskCount === taskCount &&
                              selec.question === question &&
                              selec.index === index && // Check based on index
                              selec.value.value === opt.value
                          );

                      return (
                        <div
                          className="rounded-3 p-4 d-flex"
                          key={index}
                          style={{
                            background: isSelected ? "#EDFCF2" : "#FFF",
                            border: isSelected
                              ? "1px solid #079455"
                              : "1px solid #B0B4C5",
                            cursor: "pointer",
                            minHeight: "152px",
                            width: isNonMobile768 ? "47%" : "100%",
                            justifyContent: "space-between",
                          }}
                          onClick={() => {
                            handleSelect(
                              newTask.questions[question],
                              opt,
                              index
                            );
                          }}
                        >
                          <p className="black14500">{opt.value}</p>
                          <div style={{ minWidth: "24px", minHeight: "25px" }}>
                            {" "}
                            {isSelected ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                              >
                                <path
                                  d="M11.97 2.5C6.44997 2.5 1.96997 6.98 1.96997 12.5C1.96997 18.02 6.44997 22.5 11.97 22.5C17.49 22.5 21.97 18.02 21.97 12.5C21.97 6.98 17.5 2.5 11.97 2.5ZM12 16.73C9.65997 16.73 7.76997 14.84 7.76997 12.5C7.76997 10.16 9.65997 8.27 12 8.27C14.34 8.27 16.23 10.16 16.23 12.5C16.23 14.84 14.34 16.73 12 16.73Z"
                                  fill="#079455"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                              >
                                <path
                                  d="M12 21.5C16.9706 21.5 21 17.4706 21 12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 17.4706 7.02944 21.5 12 21.5Z"
                                  fill="#F6F7FB"
                                  stroke="#AFB2C5"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
              {newTask.questions[question].type !==
                "Multiple Choice Question" &&
                newTask.questions[question]?.description && (
                  <p className="black14500" style={{ fontWeight: 400 }}>
                    Description: {newTask.questions[question]?.description}
                  </p>
                )}
              {newTask.questions[question].type === "Text field" && (
                <>
                  <p className="black14500" style={{ marginTop: "1rem" }}>
                    Type your answer below
                  </p>
                  <ReactQuill
                    value={
                      answers.find(
                        (answer) =>
                          answer.taskCount === taskCount &&
                          answer.question === question
                      )?.value || ""
                    }
                    onChange={(value) => {
                      setAnswers((prevAnswers) => {
                        const existingAnswerIndex = prevAnswers.findIndex(
                          (answer) =>
                            answer.taskCount === taskCount &&
                            answer.question === question
                        );

                        if (existingAnswerIndex !== -1) {
                          // Update the existing answer
                          const updatedAnswers = [...prevAnswers];
                          updatedAnswers[existingAnswerIndex].value = value;
                          return updatedAnswers;
                        } else {
                          // Add a new answer
                          return [
                            ...prevAnswers,
                            { taskCount, question, value },
                          ];
                        }
                      });
                    }}
                    modules={modules}
                    formats={formats}
                    className="col-md-12 smallql reactquillfontfix whitescrollbar "
                    style={{
                      backgroundColor: "#FFF",
                      marginTop: "0.75rem",
                    }}
                  />
                </>
              )}
              {newTask.questions[question].type === "File Upload" && (
                <>
                  <div
                    className={`grid-container columns-${
                      isNonMobile1024 ? "3" : isNonMobile768 ? "2" : "1"
                    }`}
                    style={{ marginTop: "1rem" }}
                  >
                    {newTask.questions[question].files &&
                      newTask.questions[question].files.length > 0 &&
                      newTask.questions[question].files.map((item, index) => (
                        <div
                          key={index}
                          className="grid-item shadowa"
                          style={{
                            background: FileBackground(
                              item?.name ? item?.name : item?.originalname
                            ),
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{ flexDirection: "column" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="40"
                              viewBox="0 0 32 40"
                              fill="none"
                            >
                              <path
                                d="M0 4C0 1.79086 1.79086 0 4 0H20L32 12V36C32 38.2091 30.2091 40 28 40H4C1.79086 40 0 38.2091 0 36V4Z"
                                fill={FileColour(
                                  item?.name ? item?.name : item?.originalname
                                )}
                              />
                              <path
                                opacity="0.3"
                                d="M20 0L32 12H24C21.7909 12 20 10.2091 20 8V0Z"
                                fill="white"
                              />
                              <text
                                x="15%"
                                y="70%"
                                dominantBaseline="middle"
                                fill="white"
                                className="black12"
                                style={{ fontSize: "9px", fontWeight: "700" }}
                              >
                                {FileType(
                                  item?.name ? item?.name : item?.originalname
                                )}
                              </text>
                            </svg>

                            <p
                              className="black14"
                              style={{ marginTop: "0.75rem" }}
                            >
                              {" "}
                              {truncateString(
                                item?.name ? item?.name : item?.originalname,
                                70
                              )}
                            </p>
                            <button
                              className="pink16 d-flex hovercolorback p-0"
                              style={{
                                gap: "0.75rem",
                                fontSize: "14px",
                                marginTop: "0.75rem",
                                background: "transparent",
                                border: "none",
                              }}
                              onClick={() =>
                                handleDownload(
                                  item?.name ? item?.name : item?.filename,
                                  item?.name ? item?.name : item.originalname
                                )
                              }
                            >
                              Download File <NavigateNextIcon />
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                  <p className="black14500" style={{ marginTop: "2.5rem" }}>
                    File Upload
                  </p>
                  <div
                    style={{
                      marginTop: "0.75rem",
                      background: "#FFF",
                      height: "180px",
                      border: "1px solid #D0D5DD",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={actual ? openFileInput : null}
                    className="center align-items-center flex-column"
                  >
                    <div className="pink14">
                      Click to{" "}
                      {files.some(
                        (item) =>
                          Number(item.taskCount) === taskCount &&
                          Number(item.question) === question
                      )
                        ? "change "
                        : "upload"}
                    </div>{" "}
                    <p className="gray12">
                      Please rename the file to [file name.your name]
                    </p>
                    {actual && (
                      <input
                        type="file"
                        accept="*/*"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                    )}
                  </div>

                  {files.some(
                    (item) =>
                      Number(item.taskCount) === taskCount &&
                      Number(item.question) === question
                  ) && (
                    <div
                      className="d-flex flex-wrap"
                      style={{ gap: "1.5rem 1rem", marginTop: "0.5rem" }}
                    >
                      {files.map(
                        (file, index) =>
                          Number(file.taskCount) === taskCount &&
                          Number(file.question) === question && (
                            <div
                              key={index}
                              className="d-flex rounded-3 "
                              style={{
                                background: "#FFF",
                                padding: "6px 12px",
                              }}
                            >
                              <div className="">
                                {file?.file?.originalname
                                  ? getFileIcon(file.file?.originalname)
                                  : getFileIcon(file.file?.name)}
                              </div>{" "}
                              <p
                                style={{
                                  color: "#E31B54",
                                  fontFamily: "Poppins",
                                  fontWeight: "600",
                                  fontSize: "14px",

                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                className="  m-2 d-flex"
                              >
                                {file?.file?.originalname
                                  ? file.file?.originalname.length > 20
                                    ? file.file?.originalname.slice(0, 20) +
                                      "..."
                                    : file.file?.originalname
                                  : file.file?.name.length > 20
                                  ? file.file?.name.slice(0, 20) + "..."
                                  : file.file?.name}
                              </p>
                            </div>
                          )
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-end"
          style={{
            marginRight: isNonMobile1024 ? "48px" : "",
            marginTop: "2rem",
          }}
        >
          <button
            className="continuebutton"
            onClick={() => {
              handleNext();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
export default Sixth;
