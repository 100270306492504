import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const PublicationList = ({
  setAddPublication,
  loading,
  publication,
  setNewPublication,
  setIndex,
  handleUpdate,
  setPublication,
}) => {
  return (
    <div>
      <div className="d-flex flex-column gap-4">
        {publication.length > 0 &&
          publication.map((pub, index) => (
            <div key={index}>
              <div
                className="d-flex justify-content-end"
                style={{ gap: "1rem" }}
              >
                <EditIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIndex(index);
                    setNewPublication(pub);
                    setAddPublication(true);
                  }}
                />
                <DeleteIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPublication(publication.filter((_, i) => i !== index));
                  }}
                />
              </div>
              <div className=" align-items-center space flex-wrap gap-3">
                <div>
                  <p className="gray95016500">{pub?.title}</p>
                  <p className="mt-2 a314400">{pub?.date}</p>
                  <p className="gray50014400 mt-2">{pub?.description}</p>
                </div>

                <button className="backbutton">Show Publication</button>
              </div>
            </div>
          ))}
      </div>
      <div className="mt-3">
        <div className="d-flex">
          <p
            className=" text-start backbutton hoverred mt-3"
            onClick={() => setAddPublication(true)}
            style={{ cursor: "pointer" }}
          >
            Add New
          </p>
        </div>
        <hr className="mt-5" />
        <button
          className="continuebutton mt-2"
          onClick={() => handleUpdate()}
          disabled={loading}
        >
          Update Publications
        </button>
      </div>
    </div>
  );
};

export default PublicationList;
