const AddTest = ({
  newTestscore,
  setNewTestscore,

  handleConfirm,
}) => {
  return (
    <div>
      <p className="black14 mt-4">Test Name:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newTestscore?.title}
        placeholder="Enter Test Name"
        onChange={(e) => {
          setNewTestscore((prev) => {
            return {
              ...prev,
              title: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Test Score:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newTestscore?.score}
        type="number"
        placeholder="Enter Test Score "
        onChange={(e) => {
          setNewTestscore((prev) => {
            return {
              ...prev,
              score: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Test Date:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newTestscore?.date}
        type="date"
        placeholder="Enter Date "
        onChange={(e) => {
          setNewTestscore((prev) => {
            return {
              ...prev,
              date: e.target.value,
            };
          });
        }}
      />
      <button
        className="continuebutton mt-4 mb-5"
        onClick={() => handleConfirm()}
      >
        Confirm
      </button>
    </div>
  );
};

export default AddTest;
