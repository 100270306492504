import CloseIcon from "@mui/icons-material/Close";

import ThreeUsers from "../../../assets/images/ThreeUsers";

import LockIcon from "../../../assets/images/Lock";
import { useState } from "react";
const ShareGroup = ({ group, setShareToFeed, handleShare, loadingShare }) => {
  const [text, setText] = useState("");

  return (
    <div
      className="center zindex1000"
      style={{
        width: "100%",
        height: "100%",

        position: "fixed",
        alignItems: "center",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 11,
        backgroundColor: "rgba(45, 50, 130, 0.30)",
        backdropFilter: "blur(3px)",
        padding: "1rem",
        zIndex: 9999,
      }}
      onClick={() => {
        setShareToFeed(false);
      }}
    >
      <div
        className=" shadowa newPopUpContainer"
        style={{ width: "600px", overflow: "auto" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="space align-items-center" style={{ gap: "1rem" }}>
          <p className="gray90018500 ">Share Group as Feed</p>

          <CloseIcon
            className="hoverdarkblacksvg"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShareToFeed(false);
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "1px",
            background: "#EAECF0",
            margin: "1.25rem 0rem",
          }}
        />
        <textarea
          placeholder="Publish your thoughts"
          value={text}
          style={{ width: "100%", marginBottom: "1rem", outline: "none" }}
          onChange={(e) => setText(e.target.value)}
          className="postingtab__input"
        />
        <div style={{ borderRadius: "5px", overflow: "hidden" }}>
          <img
            src={group?.banner}
            alt="Selected file"
            style={{
              objectFit: "cover",
              maxHeight: "200px",
              minHeight: "200px",
              width: "100%",
              borderRadius: "5px 5px 0px 0px",
            }}
          />
          <div style={{ background: "#F9FAFB", padding: "1.25rem" }}>
            <p className="gray90014400">{group?.title}</p>
            <div
              className="d-flex align-items-center gap-2"
              style={{ marginTop: "1rem" }}
            >
              <div
                className="center align-items-center"
                style={{ minWidth: "20px" }}
              >
                {group?.type === "Public" ? <ThreeUsers /> : <LockIcon />}
              </div>
              <p className="gray70014400">
                {group?.type === "Public" ? "Public" : "Private"} Group
              </p>
            </div>

            <div
              className="d-flex align-items-center gap-2"
              style={{ marginTop: "1rem" }}
            >
              <div className="center align-items-center">
                {group?.membersPics?.length > 0 &&
                  group.membersPics.map((pic, index) => (
                    <img
                      src={pic}
                      alt="memberspic"
                      key={index}
                      style={{
                        height: "24px",
                        width: "24px",
                        position: "relative",
                        marginLeft: index === 0 ? "" : "-10px",
                        zIndex: index,
                        borderRadius: "50%",
                        background: "#FFF",
                        border: "1px solid #FFF",
                      }}
                    />
                  ))}
              </div>
              <p className="gray70014400">
                {group?.members?.length + 1 >= 1000
                  ? ((group?.members?.length + 1) / 1000).toFixed(1) + "k"
                  : group?.members?.length + 1}{" "}
                {group?.members?.length + 1 === 1 ? "Member" : "Members"}
              </p>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ padding: "1.25rem", paddingBottom: "0rem" }}
        >
          <button
            className="continuebutton"
            onClick={() => {
              handleShare(text);
            }}
            disabled={loadingShare}
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareGroup;
