import React from "react";
import logo from "../../assets/images/logo.png";
import linkedin from "../../assets/images/blackwhitelinkedin.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NonMobile from "../NonMobile/NonMobile";
import "./footer.css";
import BlackWhiteLogo from "../../assets/images/icons/newlogo";
import instagram from "../../assets/images/blacknwhiteinsta.png";
const HomeFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isNonMobile1024, isNonMobile1250, isNonMobile810, isNonMobile500 } =
    NonMobile();
  const path = location.pathname.toLowerCase();
  const buttons = [
    {
      title: "Platform",
      buttons: [
        {
          name: "Home",
          link: "/",
        },
        {
          name: "Corporate Track",
          link: "/corporate-track",
        },
        {
          name: "Innovation Track",
          link: "/innovation-track",
        },
        {
          name: "Registration",
          link: "/register",
        },
        {
          name: "Magazine",
          link: "https://varyance.io/magazine",
        },
      ],
    },
    {
      title: "Employer Hub",
      buttons: [
        {
          name: "Overview",
          link: "/employer",
        },
        {
          name: "Challenges",
          link: "/employer/solutions-challenges",
        },
        {
          name: "Events",
          link: "/employer/events",
        },
        {
          name: "Analytics",
          link: "/employer/analytics",
        },
        {
          name: "Accelerate DE&I",
          link: "/",
        },
      ],
    },
    {
      title: "Career centers",
      buttons: [
        {
          name: "Overview",
          link: "/career-centers",
        },
        {
          name: "Marketing Toolkit",
          link: "/career-centers/marketing-toolkit/",
        },
        {
          name: "Webinars",
          link: "/",
        },
        {
          name: "Contact",
          link: "/career-center/contact",
        },
      ],
    },
    {
      title: "STUDENT organizations",
      buttons: [
        {
          name: "Tools",
          link: "/",
        },
        {
          name: "CRM & Events",
          link: "/",
        },
        {
          name: "Invite Page",
          link: "/",
        },
        {
          name: "Talk to us",
          link: "/",
        },
      ],
    },
  ];

  const footerlinks = [
    {
      name: "Terms of Service",
      link: "/terms",
    },
    {
      name: "Privacy Policy",
      link: "/privacy",
    },
    {
      name: "Cookies",
      link: "/cookie",
    },
  ];

  const handleClick = () => {
    if (path === "/") {
      navigate("/");
    } else {
      navigate("/employer");
    }
    window.scrollTo(0, 0);
  };

  const Comp1 = () => {
    return <p className="gray30014400">© 2024 Varyance LTD.</p>;
  };

  const Comp2 = () => {
    return (
      <div className="d-flex flex-wrap gap-4 align-items-center">
        {footerlinks.map((link, index) => (
          <a
            className="gray30014400 footerhover"
            href={link.link}
            key={index}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            {link.name}
          </a>
        ))}
      </div>
    );
  };
  const Comp3 = () => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <a href="/" style={{ textDecoration: "none" }}>
          <BlackWhiteLogo />
        </a>
        <a href="https://www.linkedin.com/company/varyance-io" target="_blank">
          <img src={linkedin} alt=" linkedin" height={"24px"} width={"24px"} />
        </a>
        <a href="https://www.instagram.com/varyance.io/" target="_blank">
          <img src={instagram} alt=" linkedin" height={"24px"} width={"24px"} />
        </a>
      </div>
    );
  };

  return (
    <div className="marginfromlinetop">
      <div
        className="space   gap-5"
        style={{ flexDirection: isNonMobile1250 ? "row" : "column" }}
      >
        <div className="d-flex flex-column gap-4" style={{ maxWidth: "262px" }}>
          <a href="/">
            <img src={logo} className="rounded-2 headerlogohome" />
          </a>
          <div>
            <a
              className="white24400"
              href="/"
              style={{ textDecoration: "none" }}
            >
              varyance.io
            </a>
            <p className="white14400 mt-2">
              Virtual challenges designed by investment banks, big law,
              consulting firms, founders & other industry leaders.
            </p>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div
            className=" space "
            style={{
              gap: isNonMobile1024 ? "2.5rem 2rem" : "2.5rem 1rem",
              flexWrap: isNonMobile810 ? "" : "wrap",
              width: "100%",
            }}
          >
            {buttons.map((button, index) => (
              <div
                className="d-flex flex-column gap-4"
                key={index}
                style={{
                  width: isNonMobile810 ? "" : isNonMobile500 ? "45%" : "100%",
                }}
              >
                <p className="secondfirsttext" style={{ color: "#98A2B3" }}>
                  {button.title}
                </p>

                <div className="d-flex flex-column gap-3">
                  {button.buttons.map((btn, i) => (
                    <a
                      className="gray5016400 footerhover"
                      key={i}
                      href={btn.link}
                      style={{
                        textDecoration: "none",
                        fontSize: "1rem",
                        lineHeight: "1.5rem",
                      }}
                      target={btn.link.includes("http") ? "_blank" : ""}
                    >
                      {btn.name}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="divider"
        style={{
          margin: isNonMobile1024
            ? "5rem 0rem 2.5rem 0rem"
            : "2rem 0rem 1.5rem 0rem",
        }}
      />

      {isNonMobile810 ? (
        <div className="space align-items-center" style={{ gap: "1.25rem" }}>
          <Comp1 />
          <Comp2 />
          <Comp3 />
        </div>
      ) : (
        <div
          className="d-flex flex-column align-items-center"
          style={{ gap: "1.25rem" }}
        >
          <Comp3 />
          <Comp1 />
          <Comp2 />
        </div>
      )}
    </div>
  );
};

export default HomeFooter;
