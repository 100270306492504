import { useEffect, useRef, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";
import EmptySpace from "../../assets/images/EmptyState.svg";

const Applied = ({ loading, applied }) => {
  const [challengestoshow, setChallengesToShow] = useState([...applied]);

  const [showIcon, setShowIcon] = useState(false);
  const [Index, setIndex] = useState(-1);
  const iconRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 6;
  let indexOfLastEntry = currentPage * entriesPerPage;
  let indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const [currentEntries, setCurrentEntries] = useState(
    challengestoshow.slice(indexOfFirstEntry, indexOfLastEntry)
  );

  let totalEntries = challengestoshow.length;
  let totalPages = Math.ceil(totalEntries / entriesPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setChallengesToShow([...applied]);
  }, [applied]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowIcon(false);
        setIndex(-1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    setCurrentEntries(
      challengestoshow.slice(indexOfFirstEntry, indexOfLastEntry)
    );
  }, [currentPage, challengestoshow, indexOfFirstEntry, indexOfLastEntry]);

  const renderPageButtons = () => {
    const maxButtons = 5; // Maximum number of buttons to display
    const buttons = [];

    // Calculate the range of buttons to display around the current page
    let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxButtons - 1);

    // Adjust startPage and endPage if there are not enough buttons
    if (endPage - startPage + 1 < maxButtons) {
      if (currentPage <= Math.floor(maxButtons / 2) + 1) {
        endPage = Math.min(totalPages, maxButtons);
      } else {
        startPage = Math.max(1, totalPages - maxButtons + 1);
      }
    }

    // Render the buttons in the calculated range
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          style={
            i === currentPage
              ? { backgroundColor: "#E31B54", color: "#FFF" }
              : { color: "#000", backgroundColor: "transparent" } // Adjust colors as needed
          }
          onClick={() => handlePageChange(i)}
          className={`btn normalbuttons ${i === currentPage && " rounded-3"}`}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div>
      {loading ? (
        <p className="black16">Loading</p>
      ) : (
        <div>
          <div
            className="tablescroll maindashboardtopcards "
            style={{ marginTop: "0.75rem", overflowX: "auto", width: "100%" }}
          >
            <table
              className="tableofchallenges"
              style={{ width: "100%", overflowX: "scroll" }}
            >
              <thead className="thead " style={{ width: "100%" }}>
                <tr className="theadtr">
                  <th className="theadth ">Company Name </th>
                  <th className="theadth"> Job Title</th>
                  <th className="theadth ">Job Type</th>
                  <th className="theadth ">Application Date</th>
                  <th className="theadth ">Action</th>
                </tr>
              </thead>

              <tbody className="tbody">
                {currentEntries &&
                  currentEntries.length > 0 &&
                  currentEntries.map((challenge, index) => (
                    <tr key={index} className="tbodytr ">
                      <td
                        className="tbodytrtd gray95014400 d-flex align-items-start"
                        style={{ gap: "6px" }}
                      >
                        {" "}
                        <img
                          src={JSON.parse(challenge?.company)?.img}
                          alt="company logo"
                          height={"24px"}
                          className="shadowa"
                          width={"24px"}
                          style={{
                            background: "#FFF",
                            padding: "1px",
                            borderRadius: "5px",
                          }}
                        />
                        {JSON.parse(challenge?.company)?.title}
                      </td>
                      <td
                        className="tbodytrtd gray95014400 "
                        style={{ minWidth: "200px" }}
                      >
                        {challenge.title}
                      </td>
                      <td className="tbodytrtd d-flex">
                        <div className="newdashboardsidechips center">
                          <AccessTimeIcon
                            style={{ height: "16px", width: "16px" }}
                          />
                          {challenge.timeRequirement}
                        </div>
                      </td>
                      <td className="tbodytrtd">
                        {challenge.applicationOpenDate}
                      </td>
                      <td className="tbodytrtd">
                        <div className="center" style={{}}>
                          <div style={{ position: "absolute" }}>
                            {showIcon && Index === index && (
                              <div
                                style={{
                                  position: "absolute",
                                  background: "transparent",
                                  zIndex: 10,

                                  marginLeft: "-140px",
                                }}
                                ref={iconRef}
                              >
                                <div
                                  className="d-flex shadowa"
                                  style={{
                                    flexDirection: "column",
                                    background: "transparent",
                                  }}
                                >
                                  <a
                                    href={`/student/Job/${challenge?.uniqueId}`}
                                    target="_blank"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <button
                                      className="d-flex popuptext"
                                      style={{
                                        padding: "11px 1rem",
                                        maxHeight: "2rem",
                                        // borderRadius: "5px 5px 0px 0px",
                                        gap: "10px",
                                        borderTop: "1px solid #d9d9d9",
                                        background: "#FFF",
                                        border: "none",
                                        width: "160px",
                                        minHeight: "42px",
                                      }}
                                      onClick={() => {
                                        setShowIcon(false);

                                        setIndex(-1);
                                      }}
                                    >
                                      Job Details
                                    </button>
                                  </a>
                                  <button
                                    className="d-flex popuptext"
                                    style={{
                                      padding: "11px 1rem",
                                      maxHeight: "2rem",
                                      borderRadius: "0px 0px 5px 5px",
                                      gap: "10px",
                                      background: "#FFF",
                                      border: "none",
                                      width: "160px",
                                      minHeight: "42px",
                                      borderTop: "1px solid #d9d9d9",
                                    }}
                                    onClick={() => {
                                      setShowIcon(false);
                                      setIndex(-1);
                                      const link = `${process.env.REACT_APP_FRONTEND_URL}/student/Job/${challenge?.uniqueId}`;

                                      // Copy the link to clipboard
                                      navigator.clipboard
                                        .writeText(link)
                                        .then(() => {
                                          setShowIcon(false);
                                          setIndex(-1);
                                          // Optionally, show a toast notification to indicate the link was copied
                                          toast.success(
                                            "Link copied to clipboard"
                                          );
                                        })
                                        .catch((err) => {
                                          toast.error(
                                            "Failed to copy the link"
                                          );
                                        });
                                    }}
                                  >
                                    Copy Link
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>

                          <MoreVertIcon
                            className="hoverred"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setIndex(index);
                              setShowIcon(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                {currentEntries.length > 0 && currentEntries.length < 5 && (
                  <>
                    {Array.from({ length: 5 - currentEntries.length }).map(
                      (_, index) => (
                        <tr
                          key={index}
                          className="tbodytr"
                          style={{ border: "none" }}
                        >
                          <td className="tbodytrtd"></td>
                          <td className="tbodytrtd"></td>
                          <td className="tbodytrtd"></td>
                          <td className="tbodytrtd"></td>
                          <td className="tbodytrtd"></td>
                        </tr>
                      )
                    )}
                  </>
                )}
              </tbody>
            </table>
            {currentEntries.length === 0 && (
              <div
                className="center col-md-12 col-12"
                style={{
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                  height: "400px",
                  background: "#FFF",
                }}
              >
                <img
                  src={EmptySpace}
                  alt="Emptyspace"
                  height="225px"
                  width="180px"
                  className="img-fluid"
                />
                <p className="pink16">No Data yet</p>
                <p className="black14" style={{ fontWeight: "500" }}>
                  <span className="black14">Please come back later.</span> Start
                  Applying for Jobs to see them here.
                </p>
              </div>
            )}
          </div>
          <div
            className="d-flex"
            style={{ marginTop: "1rem", justifyContent: "end" }}
          >
            <div
              className="d-flex"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              {renderPageButtons()}
              {indexOfLastEntry < challengestoshow.length && (
                <button
                  onClick={handleNextPage}
                  disabled={indexOfLastEntry >= challengestoshow.length}
                  className="backbutton"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Applied;
