const MegaIconText = ({ icon, title, text, link }) => {
  return (
    <a
      href={link}
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
    >
      <div
        className="d-flex MegaIconText"
        style={{ gap: "1rem", cursor: "pointer" }}
      >
        {icon}
        <div className="d-flex flex-column gap-2">
          <p className=" hovertitle">{title}</p>
          <p className="gray70014400">{text}</p>
        </div>
      </div>
    </a>
  );
};
export default MegaIconText;
