import CompanyImage from "../../components/Company/CompanyImage";
import "./index.css";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { QRCodeCanvas } from "qrcode.react";
import logo from "../../assets/images/logo.png";
import ChallengeType from "../MainDashBoard/type";

const Certificate = ({ company, result, average, certificateRef }) => {
  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const options = {
      weekday: "short", // 'Wed'
      year: "numeric", // '2025'
      month: "short", // 'Jan'
      day: "2-digit", // '03'
    };

    // Format the date
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate.replace(/,/g, ","); // Ensures correct format with commas
  }

  return (
    <div
      ref={certificateRef}
      className="certificate-outer"
      style={{ width: "100%" }}
    >
      <div className="certificate-top-inner">
        <CompanyImage src={company.img} height={"100px"} width={"100px"} />
        <p className="white20500" style={{ marginTop: "1.5rem" }}>
          Certificate of Completion {formatDate(result?.createdAt)}
        </p>
        <p className="certificate-user-name" style={{ marginTop: "2rem" }}>
          {result?.user?.firstName} {result?.user?.LastName}
        </p>

        <p
          className="gray70032400"
          style={{
            marginTop: "1.5rem",
            color: "#FFF",
            fontFamily: "FilmotypeFashion",
            maxWidth: "800px",
          }}
        >
          {result?.preview?.title}
        </p>
        <p
          className="certificate-v"
          style={{ position: "absolute", top: "32px", right: "58px" }}
        >
          /v
        </p>
      </div>
      <div className="certificate-bottom-inner">
        <div className="space flex-wrap gap-4">
          <div>
            <p className="gray95016500">
              Over the period of {formatDate(result?.createdAt)},{" "}
              {result?.user?.firstName} {result?.user?.LastName} has completed
              pratical tasks in :
            </p>
            <div
              className="d-flex flex-wrap gap-2"
              style={{ marginTop: "1.5rem", maxWidth: "626px" }}
            >
              <ChallengeType type={result?.preview?.type} />
              {result?.preview?.learns?.map((learn, index) => (
                <div key={index} className="newdashboardsidechips">
                  {learn}
                </div>
              ))}
            </div>
          </div>
          <div>
            <p
              className="graybody14500"
              style={{ fontWeight: 500, color: "#475467" }}
            >
              Total Score
            </p>
            <div
              className="d-flex align-items-end"
              style={{ gap: "6px", marginTop: "0.25rem" }}
            >
              <p className="certificate-percentage">{result.percentage}%</p>
              <p
                className="gray70014400"
                style={{
                  color: result.percentage >= average ? "#00B929" : "red",
                }}
              >
                <ArrowDropUpIcon
                  style={{
                    height: "25px",
                    width: "25px",
                    color: result.percentage >= average ? "#00B929" : "red",
                    transform:
                      result.percentage >= average ? "" : "rotate(180deg)",
                  }}
                />
                {result.percentage >= average
                  ? result.percentage - average
                  : average - result.percentage}
                %
              </p>
            </div>
            <p className="gray60011400" style={{ marginTop: "6px" }}>
              Compared to {average}% average.
            </p>
          </div>
        </div>
        <div
          className="space align-items-end flex-wrap gap-4"
          style={{ marginTop: "4rem" }}
        >
          <div style={{}}>
            <QRCodeCanvas
              value={`${process.env.REACT_APP_FRONTEND_URL}/student/certificate/${result?.uniqueId}`}
              size={100}
            />
            <div className="d-flex gap-2 mt-3">
              <p className="gray60011400">Certificate Verification Link:</p>
              <a
                href={`${process.env.REACT_APP_FRONTEND_URL}/student/certificate/${result?.uniqueId}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <p className="gray60011400">Click here</p>
              </a>
            </div>
          </div>
          <img
            src={logo}
            alt="logo"
            style={{ width: "64px", height: "64px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Certificate;
