import Carousel from "react-multi-carousel";
import JobCard from "../JobPost/jobCard";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const Fifth = ({ jobposts, color, company, navigate, organizer }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1440 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div
      className=""
      style={{ marginTop: "2.5rem", maxWidth: "100%" }}
      id="jobposts"
    >
      <div
        className="space flex-wrap align-items-center"
        style={{ gap: "1rem", alignItems: "center" }}
      >
        <div>
          <p className="registeration">{company?.title} Jobposts</p>
          <p style={{ marginTop: "0.75rem" }} className="gray95014400">
            Explore job opportunities with us and discover how you can
            contribute to our exciting roles.
          </p>
        </div>
        {organizer.company === company.uniqueId && (
          <p
            className="black16 hovercolorback"
            style={{ color: color, cursor: "pointer" }}
            onClick={() => {
              navigate("/dashboard/jobposts");
            }}
          >
            See all Job posts{" "}
            <ArrowOutwardIcon style={{ height: "14px", width: "14px" }} />
          </p>
        )}
      </div>
      <div style={{ marginTop: "2.5rem", gap: "1.25rem" }}>
        <Carousel responsive={responsive} className="p-2 pb-3 ">
          {jobposts &&
            jobposts.length > 0 &&
            jobposts.map((challe, index) => (
              <div key={index} style={{ position: "relative", zIndex: index }}>
                <JobCard
                  challe={challe}
                  index={index}
                  TRUE={true}
                  navigate={navigate}
                  checker={
                    organizer.company === company?.uniqueId ? true : false
                  }
                />
              </div>
            ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Fifth;
