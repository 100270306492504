// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EventsTypeContainer {
  display: flex;
  max-height: 33.6px;
  padding: 8.4px 14px;
  align-items: center;
  gap: 11.2px;
  border-radius: 42px;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
}
`, "",{"version":3,"sources":["webpack://./src/pages/NewPages/Events/main.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,WAAW;EACX,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC","sourcesContent":[".EventsTypeContainer {\n  display: flex;\n  max-height: 33.6px;\n  padding: 8.4px 14px;\n  align-items: center;\n  gap: 11.2px;\n  border-radius: 42px;\n  color: #000;\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 160%; /* 22.4px */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
