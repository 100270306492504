import { useEffect, useState, useRef } from "react";
import upload from "../../assets/images/export.svg";

import { useMediaQuery } from "@mui/material";
import "./before6.css";
import TaskStep1 from "./TaskStep1";
import { toast } from "react-toastify";
import TaskStep2 from "./TaskStep2";

import TaskStep4 from "./TaskStep4";
import TaskStep5 from "./TaskStep5";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PreviewWhole from "./PreviewWhole/PreviewWholeChallenge";
import ChallengeType from "../MainDashBoard/type";
const Before = ({
  setStep,
  settings,
  setSettings,
  edit,
  handleSubmit,

  color,

  setShowPreviewa,

  setTaskHeader,

  newTasks,
  setNewTasks,

  taskCount,
  setTaskCount,
  loading,
  time,
  title,
  company,
  selectedFile,
  alltask,
  taskSteps,
  setTaskSteps,
  setPreview,
  preview,

  description,
  type,
}) => {
  const [addHover, setAddHover] = useState(false);
  const isNonMob1024 = useMediaQuery("(min-width:1024px)");
  const [showIcon, setShowIcon] = useState(false);
  const [indextoshow, setIndexToShow] = useState(-1);
  const isNonMob5oo = useMediaQuery("(min-width:500px)");
  const isNonMob = useMediaQuery("(min-width:768px)");
  const [taskCounter, setTaskCounter] = useState(-1);
  // const [preview] = useState(false);
  const handleTaskChange = () => {
    setTaskHeader(true);

    setNewTasks((prevTasks) => {
      const updatedTasks = [...prevTasks];
      updatedTasks.push({
        taskTitle: "",
        taskBackground: "",
        terms: null,
        task: null,
        time: 5,
        settings: {
          keyterms: false,
          files: false,
          externalLinks: false,
        },
        external: [],
        settings,
        introVideo: null,
        caseDocuments: [],
        additionalDocuments: [],
        questions: [
          {
            question: "",
            answer: "",
            description: "",
            type: "Multiple Choice Question",
            explaination: "",
            files: [],
            options: [
              {
                value: "",
                isCorrect: false,
              },
              {
                value: "",
                isCorrect: false,
              },
            ],
            isMultiSelect: false,
          },
        ],
      });
      setTaskCount(updatedTasks.length - 1);
      return updatedTasks;
    });
    setTaskSteps(1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowIcon(false);
        setIndexToShow(-1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [taskSteps]);

  const iconRef = useRef(null);
  return (
    <>
      {preview ? (
        <PreviewWhole
          company={company}
          newTasks={newTasks}
          title={title}
          setPreview={setPreview}
          color={color}
          taskCounter={taskCounter}
        />
      ) : (
        <>
          {taskSteps === 0 && (
            <div
              className="basicInforMationPADDINGS"
              style={{ width: "100vw" }}
            >
              <div className="space flex-wrap" style={{ gap: "1rem" }}>
                <div>
                  <p className="BasicInforMationHeading ">{title && title}</p>

                  <p
                    className="UnderBasicHeading"
                    style={{ marginTop: "0.25rem" }}
                  >
                    Add a minimum of <span>1 task</span> to publish this
                    challenge.{" "}
                    <span style={{ fontWeight: "500" }}>Max. 6 tasks</span>
                  </p>
                </div>
                <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                  {edit && (
                    <button
                      className="backbutton"
                      style={{ maxHeight: "40px" }}
                      onClick={() => {
                        setStep(1);
                        setTaskSteps(-1);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.49075 12.7251L7.09909 9.88344C7.18765 9.48854 7.38714 9.12715 7.67409 8.84177L13.2324 3.33344C13.7961 2.75925 14.5614 2.42742 15.3658 2.40844C15.9724 2.39974 16.5574 2.63375 16.9908 3.05844C17.9376 4.17716 17.8153 5.84779 16.7158 6.81677L11.1574 12.3751C10.872 12.6621 10.5106 12.8615 10.1158 12.9501L7.27409 13.5584H7.11575C6.90801 13.5664 6.70959 13.4719 6.58486 13.3056C6.46012 13.1393 6.42495 12.9223 6.49075 12.7251ZM8.55742 9.73344C8.44066 9.84659 8.35955 9.99143 8.32409 10.1501L7.91575 12.0918L9.85742 11.6751C10.0161 11.6396 10.1609 11.5585 10.2741 11.4418L15.8324 5.88344C16.4413 5.39596 16.5622 4.51717 16.1074 3.88344C15.9083 3.69319 15.641 3.59108 15.3658 3.6001C14.8912 3.61916 14.4428 3.82244 14.1158 4.16677L8.55742 9.73344Z"
                          fill="#475467"
                        />
                        <path
                          d="M16.5908 9.10844C16.2475 9.11293 15.9702 9.39014 15.9658 9.73344V14.4751C15.9769 15.1453 15.7185 15.792 15.2484 16.2699C14.7784 16.7478 14.1361 17.0169 13.4658 17.0168H5.52409C4.13856 16.9896 3.02835 15.8609 3.02409 14.4751V6.56677C3.04684 5.17934 4.17814 4.06658 5.56576 4.06677H10.3074C10.6526 4.06677 10.9324 3.78695 10.9324 3.44177C10.9324 3.09659 10.6526 2.81677 10.3074 2.81677H5.52409C3.44617 2.81664 1.75526 4.48898 1.73242 6.56677V14.4751C1.73242 16.5692 3.43001 18.2668 5.52409 18.2668H13.4324C15.5232 18.2622 17.2158 16.5659 17.2158 14.4751V9.73344C17.2113 9.39014 16.9341 9.11293 16.5908 9.10844Z"
                          fill="#475467"
                        />
                      </svg>{" "}
                      Edit Challenge
                    </button>
                  )}
                  <button
                    className="continuebutton"
                    style={{ maxHeight: "40px" }}
                    disabled={newTasks.length < 1 || loading === true}
                    onClick={() => {
                      setShowPreviewa(true);
                    }}
                  >
                    Preview Challenge
                  </button>
                  <button
                    className="continuebutton"
                    style={{ maxHeight: "40px" }}
                    onClick={() => handleSubmit()}
                    disabled={newTasks.length < 1 || loading === true}
                  >
                    <img src={upload} alt="upload" width="20px" height="20px" />{" "}
                    Publish Challenge
                  </button>
                </div>
              </div>
              <div className="space" style={{ marginTop: "1.875rem" }}>
                <div className="d-flex flex-wrap" style={{ gap: "1.5rem" }}>
                  {newTasks.length > 0 &&
                    newTasks.map((task, index) => (
                      <div
                        key={index}
                        className="addChallengeDiva shadowa space"
                        style={{ flexDirection: "column" }}
                      >
                        <div>
                          {" "}
                          <div
                            className="space"
                            style={{ alignItems: "center" }}
                          >
                            <p className="black14">Task {index + 1}</p>
                            <div className="d-flex">
                              <MoreVertIcon
                                onClick={() => {
                                  setIndexToShow(index);
                                  setShowIcon(true);
                                }}
                              />
                              {showIcon && indextoshow === index && (
                                <div
                                  style={{
                                    position: "absolute",
                                    background: "transparent",
                                    marginTop: "30px",
                                    marginLeft: isNonMob5oo ? "" : "-140px",
                                  }}
                                  ref={iconRef}
                                >
                                  <div
                                    className="d-flex shadowa"
                                    style={{
                                      flexDirection: "column",
                                      background: "transparent",
                                    }}
                                  >
                                    <button
                                      className="d-flex black14"
                                      style={{
                                        padding: "11px 1rem",
                                        maxHeight: "2rem",
                                        borderRadius: "5px 5px 0px 0px",
                                        gap: "10px",
                                        background: "#FFF",
                                        border: "none",
                                        width: "160px",
                                        minHeight: "42px",
                                      }}
                                      onClick={() => {
                                        setTaskCount(index);
                                        setTaskSteps(1);
                                        setShowIcon(false);
                                        setTaskHeader(true);
                                        setIndexToShow(-1);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M11.05 3L4.20829 10.2417C3.94996 10.5167 3.69996 11.0583 3.64996 11.4333L3.34162 14.1333C3.23329 15.1083 3.93329 15.775 4.89996 15.6083L7.58329 15.15C7.95829 15.0833 8.48329 14.8083 8.74162 14.525L15.5833 7.28334C16.7666 6.03334 17.3 4.60834 15.4583 2.86667C13.625 1.14167 12.2333 1.75 11.05 3Z"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M9.9082 4.20834C10.2665 6.50834 12.1332 8.26667 14.4499 8.5"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M2.5 18.3333H17.5"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      Edit
                                    </button>
                                    <button
                                      className="d-flex black14"
                                      style={{
                                        padding: "11px 1rem",
                                        maxHeight: "2rem",
                                        borderRadius: "0px 0px 5px 5px",
                                        gap: "10px",
                                        background: "#FFF",
                                        border: "none",
                                        width: "160px",
                                        minHeight: "42px",
                                        borderTop: "1px solid #d9d9d9",
                                      }}
                                      onClick={() => {
                                        setNewTasks((prevTasks) => {
                                          return prevTasks.filter(
                                            (task, i) => i !== index
                                          );
                                        });

                                        setShowIcon(false);
                                        setIndexToShow(-1);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M17.5 4.98333C14.725 4.70833 11.9333 4.56667 9.15 4.56667C7.5 4.56667 5.85 4.65 4.2 4.81667L2.5 4.98333"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.0835 4.14166L7.26683 3.05C7.40016 2.25833 7.50016 1.66666 8.9085 1.66666H11.0918C12.5002 1.66666 12.6085 2.29166 12.7335 3.05833L12.9168 4.14166"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M15.7082 7.61667L15.1665 16.0083C15.0748 17.3167 14.9998 18.3333 12.6748 18.3333H7.32484C4.99984 18.3333 4.92484 17.3167 4.83317 16.0083L4.2915 7.61667"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M8.6084 13.75H11.3834"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.9165 10.4167H12.0832"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <p
                            className="black16 text-start"
                            style={{ marginTop: "1rem", fontWeight: "600" }}
                          >
                            {task.taskTitle ? task.taskTitle : "Task Title"}
                          </p>
                        </div>
                        <div>
                          <div
                            className="space"
                            style={{ alignItems: "center" }}
                          >
                            <p className="pink16" style={{ fontSize: "12px" }}>
                              No. of questions
                            </p>
                            <p className="black12">{task.questions.length}</p>
                          </div>
                          <div
                            className="space"
                            style={{
                              marginTop: "0.5rem",
                              alignItems: "center",
                            }}
                          >
                            <p className="pink16" style={{ fontSize: "12px" }}>
                              Duration:
                            </p>
                            <p className="black12">{task.time} mins</p>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              marginTop: "1.25rem",
                              justifyContent: "end",
                            }}
                          >
                            <button
                              className="continuebutton"
                              style={{
                                padding: "0.5rem 1.5rem",
                                maxHeight: "2rem",
                                background: "transparent",
                                color: "#E31B54",
                                borderColor: "#E31B54",
                                width: "100%",
                              }}
                              onClick={() => {
                                setTaskCounter(index);
                                setPreview(true);
                              }}
                            >
                              Preview
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}

                  {newTasks.length < 6 && (
                    <div
                      className="addChallengeDiva shadowa space"
                      style={{ flexDirection: "column" }}
                      onMouseEnter={() => setAddHover(true)}
                      onMouseLeave={() => setAddHover(false)}
                      onClick={() => handleTaskChange()}
                    >
                      <div>
                        <p
                          className="text-start pink16"
                          style={{ fontSize: "0.875rem" }}
                        >
                          Add Task
                        </p>
                      </div>
                      <div
                        className="center"
                        style={{
                          height: "100%",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100"
                          height="100"
                          viewBox="0 0 100 100"
                          fill="none"
                        >
                          <path
                            d="M82.2917 45.8333H52.0833V15.625C52.0833 13.8991 50.6842 12.5 48.9583 12.5C47.2324 12.5 45.8333 13.8991 45.8333 15.625V45.8333H15.625C13.8991 45.8333 12.5 47.2324 12.5 48.9583C12.5 50.6842 13.8991 52.0833 15.625 52.0833H45.8333V82.2917C45.8333 84.0176 47.2324 85.4167 48.9583 85.4167C50.6842 85.4167 52.0833 84.0176 52.0833 82.2917V52.0833H82.2917C84.0176 52.0833 85.4167 50.6842 85.4167 48.9583C85.4167 47.2324 84.0176 45.8333 82.2917 45.8333Z"
                            fill={addHover ? "#E31B54" : "white"}
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
                {isNonMob1024 && (
                  <div
                    style={{ width: "324px" }}
                    className="whitedivrightsideaddtasks"
                  >
                    <div>
                      <p
                        className="text-start black16"
                        style={{ fontWeight: "600" }}
                      >
                        Challenge Overview
                      </p>
                      <div style={{ marginTop: "1rem" }}>
                        <p className="nextstepsright">Preview</p>

                        <div
                          className="ChallengesCardNew-Outer shadowa "
                          style={{ borderRadius: "5px", marginTop: "0.875rem" }}
                        >
                          {selectedFile && (
                            <img
                              src={
                                selectedFile instanceof File // Check if selectedFile is a File or Blob object
                                  ? URL.createObjectURL(selectedFile) // If it is, create a local URL for the file
                                  : selectedFile.includes("http")
                                  ? selectedFile
                                  : `${process.env.REACT_APP_BACKEND_URL}${selectedFile}`
                              }
                              alt="challenge image"
                              className="img-fluid"
                              style={{
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                                maxHeight: "6.875rem",
                                minHeight: "6.875rem",
                                width: "100%",
                                objectFit: "cover",
                                // ensures the image covers the container without stretching or compressing
                              }}
                            />
                          )}

                          <div className="ChallengesCardNew-Inner space flex-column">
                            <div>
                              {company && (
                                <div
                                  className="shadowa center align-items-center"
                                  style={{
                                    padding: "2px",
                                    background: "#FFF",
                                    borderRadius: "5px",
                                    height: "48px",
                                    width: "48px",
                                    position: "absolute",
                                    marginTop: "-46px",
                                  }}
                                >
                                  <img
                                    src={company.img}
                                    alt="card-bg"
                                    style={{
                                      maxHeight: "44px",
                                      maxWidth: "44px",
                                      height: "44px",
                                      borderRadius: "3px",
                                      objectFit: "cover",
                                      width: "100%",
                                    }}
                                  />
                                </div>
                              )}
                              <p
                                className="gray60011400"
                                style={{
                                  fontSize: "10px",
                                  marginTop: "0.5rem",
                                }}
                              >
                                {company?.title}
                              </p>
                              <p
                                style={{ marginTop: "0.25rem" }}
                                className="gray95016500"
                              >
                                {title}
                              </p>
                            </div>
                            <div>
                              <div className=" space align-items-center">
                                <ChallengeType type={type} />
                                <div
                                  className="d-flex align-items-center"
                                  style={{ gap: "0.25rem" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M1.54938 7.79185C1.54938 4.89236 3.89989 2.54185 6.79938 2.54185C9.69888 2.54185 12.0494 4.89236 12.0494 7.79185C12.0494 10.6913 9.69888 13.0419 6.79938 13.0419C3.89989 13.0419 1.54938 10.6913 1.54938 7.79185ZM2.42438 7.79185C2.42438 10.2081 4.38314 12.1669 6.79938 12.1669C7.95971 12.1669 9.0725 11.7059 9.89298 10.8854C10.7134 10.065 11.1744 8.95217 11.1744 7.79185C11.1744 5.37561 9.21563 3.41685 6.79938 3.41685C4.38314 3.41685 2.42438 5.37561 2.42438 7.79185Z"
                                      fill="#344054"
                                    />
                                    <path
                                      d="M5.42855 5.56935C5.25617 5.40873 4.98755 5.41347 4.82094 5.58007C4.65433 5.74668 4.64959 6.0153 4.81022 6.18769L6.36188 7.73935V10.1252C6.36188 10.3668 6.55776 10.5627 6.79938 10.5627C7.04101 10.5627 7.23688 10.3668 7.23688 10.1252V7.55852C7.23678 7.44253 7.19062 7.33132 7.10855 7.24935L5.42855 5.56935Z"
                                      fill="#344054"
                                    />
                                    <path
                                      d="M12.3586 3.41685C11.6384 2.45639 10.6818 1.69876 9.58188 1.21769C9.47454 1.16903 9.3521 1.16574 9.2423 1.20856C9.13249 1.25138 9.04461 1.3367 8.99855 1.44519C8.94818 1.55231 8.94401 1.67542 8.98702 1.78571C9.03004 1.896 9.11645 1.98378 9.22605 2.02852C10.1868 2.45123 11.0225 3.11417 11.6527 3.95352C11.7353 4.06368 11.865 4.12852 12.0027 4.12852C12.0974 4.12895 12.1897 4.0982 12.2652 4.04102C12.3624 3.97213 12.4273 3.86661 12.4449 3.74882C12.4625 3.63103 12.4313 3.51115 12.3586 3.41685Z"
                                      fill="#344054"
                                    />
                                    <path
                                      d="M1.94022 3.94185C2.57044 3.10251 3.40615 2.43956 4.36688 2.01685C4.47648 1.97211 4.5629 1.88433 4.60591 1.77404C4.64892 1.66376 4.64476 1.54064 4.59438 1.43352C4.54832 1.32503 4.46044 1.23972 4.35064 1.19689C4.24083 1.15407 4.1184 1.15736 4.01105 1.20602C2.91195 1.69121 1.95732 2.4529 1.24022 3.41685C1.11421 3.60468 1.15215 3.85759 1.32772 4.00019C1.40325 4.05737 1.49549 4.08811 1.59022 4.08769C1.72298 4.09478 1.85177 4.04112 1.94022 3.94185Z"
                                      fill="#344054"
                                    />
                                  </svg>
                                  <p className="gray75010500">
                                    {Number(
                                      alltask.reduce(
                                        (acc, task) => acc + task.time,
                                        0
                                      )
                                    ) + Number(time)}{" "}
                                    mins
                                  </p>
                                </div>
                              </div>
                              <div className="ChallengenewCarddescription ">
                                <p className="gray40012400">
                                  {description && description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      <div style={{ marginTop: "1.875rem" }}>
                        <p className="nextstepsright">Quick Tips</p>

                        <p className="black14" style={{ marginTop: "0.75rem" }}>
                          Encourage students to approach challenge tasks by
                          breaking down complex problems into smaller,
                          manageable tasks.
                        </p>
                        <p className="black12" style={{ marginTop: "1rem" }}>
                          Emphasize the importance of gathering relevant
                          information and proposing well-thought-out and
                          creative solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            className="pt-4"
            style={{
              minWidth: isNonMob ? "700px" : "",
              maxWidth: "700px",
              padding: isNonMob ? "" : "1.25rem",
            }}
          >
            {taskSteps === 1 && (
              <TaskStep1
                toast={toast}
                taskCount={taskCount}
                newTasks={newTasks}
                setNewTasks={setNewTasks}
                setTaskSteps={setTaskSteps}
              />
            )}

            {taskSteps === 2 && (
              <TaskStep2
                toast={toast}
                settings={settings}
                setSettings={setSettings}
                taskCount={taskCount}
                newTasks={newTasks}
                setNewTasks={setNewTasks}
                setTaskSteps={setTaskSteps}
              />
            )}

            {taskSteps === 3 && (
              <TaskStep4
                toast={toast}
                taskCount={taskCount}
                newTasks={newTasks}
                setNewTasks={setNewTasks}
                setTaskSteps={setTaskSteps}
              />
            )}
            {taskSteps === 4 && (
              <TaskStep5
                toast={toast}
                taskCount={taskCount}
                newTasks={newTasks}
                setNewTasks={setNewTasks}
                setTaskSteps={setTaskSteps}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Before;
