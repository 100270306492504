import EditIcon from "@mui/icons-material/Edit";
import AddTest from "./AddTest";
import TestList from "./TestList";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";

const TestScore = ({
  user,

  setWatcher,
  watcher,
  axios,
  toast,
}) => {
  const [testscore, setTestscore] = useState(
    user?.testscore ? user?.testscore : []
  );

  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (user) {
      setTestscore(user?.testscore);
    }
  }, [user, popup]);

  const [addTestscore, setAddTestscore] = useState(false);
  const [newTestscore, setNewTestscore] = useState({
    title: "",
    date: "",
    score: "",
  });

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(-1);

  const handleConfirm = () => {
    if (!newTestscore.title) {
      toast.error("Title is required");
      return;
    } else if (!newTestscore.score) {
      toast.error("Score is required");
      return;
    } else if (!newTestscore.date) {
      toast.error("Date is required");
      return;
    } else {
      setTestscore((prevExperience) => {
        const updatedExperience = [...prevExperience];
        if (index === -1) {
          updatedExperience.push(newTestscore);

          return updatedExperience;
        } else {
          updatedExperience[index] = newTestscore;

          setIndex(-1);

          return updatedExperience;
        }
      });
      setNewTestscore({
        title: "",
        date: "",
        score: "",
      });
      setAddTestscore(false);
      setIndex(-1);
    }
  };
  useEffect(() => {
    setNewTestscore({
      title: "",
      date: "",
      score: "",
    });
    setAddTestscore(false);
    setIndex(-1);
  }, [popup]);

  const handleUpdate = async () => {
    if (testscore.length === 0) {
      toast.error("Please add at least one Test.");
      return;
    }
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/update/student/testscore`,
        {
          testscore: testscore,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Test Score updated successfully");
          setPopup(false);
          setWatcher((prev) => {
            return {
              ...prev,
              testscore: res.data.testscore,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update Test Score");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className=""
      style={{
        padding: "1.25rem",
        marginTop: "1.875rem",
        background: "#FFF",
        borderRadius: "5px",
      }}
    >
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            overflowY: "auto",
            zIndex: 1001,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
          onClick={() => {
            setPopup(false);
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            style={{
              background: "#FFF",
              padding: "0.5rem",
              maxWidth: "715px",
              width: "100%",
            }}
          >
            <div
              className="  newPopUpContainer tablescroll"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "90vh",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="space align-items-center">
                <p className="gray90020400">Test Scores</p>
                <CloseIcon
                  className="hoverdarkblacksvg"
                  style={{ cursor: "pointer", height: "24px", width: "24px" }}
                  onClick={() => {
                    setPopup(false);
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  background: "#EAECF0",
                  margin: "0.75rem 0rem 1.5rem 0rem",
                }}
              />
              {addTestscore ? (
                <AddTest
                  newTestscore={newTestscore}
                  setNewTestscore={setNewTestscore}
                  addTestscore={addTestscore}
                  setAddTestscore={setAddTestscore}
                  handleConfirm={handleConfirm}
                  toast={toast}
                />
              ) : (
                <TestList
                  setAddTestscore={setAddTestscore}
                  loading={loading}
                  testscore={testscore}
                  setNewTestscore={setNewTestscore}
                  setIndex={setIndex}
                  handleUpdate={handleUpdate}
                  setTestscore={setTestscore}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="space gap-3 align-items-center flex-wrap">
        <p className="gray90020400">Test Scores</p>
        {user?._id === watcher?._id && (
          <EditIcon
            className="hoverred"
            style={{ cursor: "pointer", height: "20px", width: "20px" }}
            onClick={() => {
              setPopup(true);
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "100%",
          height: "1px",
          background: "#EAECF0",
          margin: "0.75rem 0rem 1.5rem 0rem",
        }}
      />
      <div className="d-flex flex-column gap-4">
        {user?.testscore?.map((test, index) => (
          <div key={index}>
            <p className="gray95016500">{test?.title}</p>
            <div className="d-flex flex-wrap gap-2 mt-2 align-items-center">
              <p className="a314400">{test?.score}</p>
              <div
                className=""
                style={{
                  background: "#667085",
                  height: "4px",
                  width: "4px",
                  borderRadius: "125px",
                }}
              />
              <p className="a314400">{test?.date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default TestScore;
