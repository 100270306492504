import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AddAward from "./AddAward";
import AwardList from "./AwardList";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SuitCase from "../../assets/images/icons/Suitcase";
import CompanyImage from "../../components/Company/CompanyImage";
const Awards = ({ user, isNonMobile1024, setWatcher, watcher, axios }) => {
  const [awards, setAwards] = useState(user?.awards ? user?.awards : []);

  const [popup, setPopup] = useState(false);
  useEffect(() => {
    if (user) {
      setAwards(user?.awards);
    }
  }, [user, popup]);
  const [addAward, setAddAward] = useState(false);
  const [newAward, setNewAward] = useState({
    logo: null,
    title: "",
    companyName: "",
    package: "",
    issue: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(-1);

  const handleConfirm = () => {
    if (!newAward.companyName) {
      toast.error("Please enter Institute name");
    } else if (!newAward.description) {
      toast.error("Please enter description");
    } else if (!newAward.package) {
      toast.error("Please provide information about this award/scholarship");
    } else if (!newAward.issue) {
      toast.error("Please enter issue date");
    } else if (!newAward.title) {
      toast.error("Please enter title");
    } else {
      setAwards((prevExperience) => {
        const updatedExperience = [...prevExperience];
        if (index === -1) {
          updatedExperience.push(newAward);

          return updatedExperience;
        } else {
          updatedExperience[index] = newAward;

          setIndex(-1);

          return updatedExperience;
        }
      });
      setNewAward({
        logo: null,
        title: "",
        companyName: "",
        package: "",
        issue: "",
        description: "",
      });
      setAddAward(false);
      setIndex(-1);
    }
  };
  useEffect(() => {
    setNewAward({
      logo: null,
      title: "",
      companyName: "",
      package: "",
      issue: "",
      description: "",
    });
    setAddAward(false);
    setIndex(-1);
  }, [popup]);

  const handleUpdate = async () => {
    if (awards.length === 0) {
      toast.error("Please add at least one Award or scholarship.");
      return;
    }
    const formData = new FormData();
    awards.forEach((exp, index) => {
      formData.append(`awards[${index}][title]`, exp?.title);
      formData.append(`awards[${index}][companyName]`, exp?.companyName);
      formData.append(`awards[${index}][package]`, exp?.package);
      formData.append(`awards[${index}][issue]`, exp?.issue);
      formData.append(`awards[${index}][description]`, exp?.description);
      if (exp?.logo) {
        formData.append(`awards[${index}][logo]`, exp?.logo); // Only if logo is present
      }
    });

    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/update/student/awards`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Awards & Scholarships updated successfully");
          setPopup(false);
          setWatcher((prev) => {
            return {
              ...prev,
              awards: res.data.awards,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update Awards & Scholarships");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className=""
      style={{
        padding: "1.25rem",
        marginTop: "1.875rem",
        background: "#FFF",
        borderRadius: "5px",
      }}
    >
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            overflowY: "auto",
            zIndex: 1001,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
          onClick={() => {
            setPopup(false);
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            style={{
              background: "#FFF",
              padding: "0.5rem",
              maxWidth: "715px",
              width: "100%",
            }}
          >
            <div
              className="  newPopUpContainer tablescroll"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "90vh",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="space align-items-center">
                <p className="gray90020400">Awards & Scholarships</p>
                <CloseIcon
                  className="hoverdarkblacksvg"
                  style={{ cursor: "pointer", height: "24px", width: "24px" }}
                  onClick={() => {
                    setPopup(false);
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  background: "#EAECF0",
                  margin: "0.75rem 0rem 1.5rem 0rem",
                }}
              />
              {addAward ? (
                <AddAward
                  newAward={newAward}
                  setNewAward={setNewAward}
                  addAward={addAward}
                  setAddAward={setAddAward}
                  handleConfirm={handleConfirm}
                  toast={toast}
                />
              ) : (
                <AwardList
                  setAddAward={setAddAward}
                  loading={loading}
                  awards={awards}
                  setNewAward={setNewAward}
                  setIndex={setIndex}
                  handleUpdate={handleUpdate}
                  setAwards={setAwards}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="space gap-3 align-items-center flex-wrap">
        <p className="gray90020400">Awards & Scholarships</p>
        {user?._id === watcher?._id && (
          <EditIcon
            className="hoverred"
            style={{ cursor: "pointer", height: "20px", width: "20px" }}
            onClick={() => {
              setPopup(true);
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "100%",
          height: "1px",
          background: "#EAECF0",
          margin: "0.75rem 0rem 1.5rem 0rem",
        }}
      />
      {user?.awards?.length > 0 ? (
        user?.awards?.map((cert, index) => (
          <div key={index} style={{ marginTop: "1.5rem" }}>
            <div className="d-flex  flex-wrap" style={{ gap: "1rem" }}>
              {cert?.logo && (
                <CompanyImage
                  src={
                    cert?.logo instanceof File
                      ? URL.createObjectURL(cert?.logo)
                      : cert?.logo
                  }
                />
              )}
              <div>
                {cert?.title && (
                  <p className="black16 text-start">{cert?.title}</p>
                )}
                {cert?.companyName && (
                  <p className="gray14400" style={{ marginTop: "0.5rem" }}>
                    {cert?.companyName}
                  </p>
                )}
                {cert?.package && (
                  <p
                    className="gray14400"
                    style={{ fontWeight: "600", marginTop: "0.75rem" }}
                  >
                    {cert?.package}
                  </p>
                )}
                {cert?.issue && (
                  <p className="gray14400" style={{ marginTop: "0.75rem" }}>
                    Issued date: {cert?.issue}
                  </p>
                )}
                {cert?.description && (
                  <p className="gray14400" style={{ marginTop: "0.75rem" }}>
                    {cert?.description}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>
          <div className="center">
            <SuitCase />
          </div>
          <p
            className="gray40016500 text-center my-2"
            style={{ color: "#FD6F8E" }}
          >
            No Awards & Scholarships Yet
          </p>

          <div className="center">
            <p
              className="text-center gray60011400"
              style={{ maxWidth: "340px", fontSize: "14px" }}
            >
              <span
                className="gray60011400"
                style={{ fontSize: "14px", fontWeight: 500 }}
              >
                Add your Awards & Scholarships.
              </span>{" "}
              It's for sure will help you to find Jobs.
            </p>
          </div>
          <p
            className="pink16 text-center mt-3 hovercolorback"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPopup(true);
            }}
          >
            Add Awards & Scholarships
          </p>
        </div>
      )}
    </div>
  );
};

export default Awards;
