import { useEffect, useState } from "react";
import TabButtons from "../../components/TabButtons/TabButtons";
import About from "./About";
import Activity from "./Activity";
import Connections from "./Connections";
import axios from "axios";

const UserInfo = ({
  user,
  watcher,
  isNonMobile1024,
  setWatcher,
  isNonMobile450,
  setUser,
}) => {
  const [selected, setSelected] = useState("About");
  const buttons = [
    { name: "About" },
    {
      name: "Activity",
    },
    watcher._id === user?._id && {
      name: "Connections",
    },
  ].filter((btn) => btn.name);

  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [search, setSearch] = useState("");
  const [loadingReq, setLoadingReq] = useState(false);
  const fetchRequests = async () => {
    if (user?.requests?.length > 0) {
      setLoadingReq(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/get/user/requests/${page}?search=${search}`, // Pass search parameter
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (res.data.msg === "success") {
          setRequests((prevRequests) => {
            // Combine new requests with previous requests and filter out duplicates
            const combinedRequests = [...prevRequests, ...res.data.requests];
            const uniqueRequests = combinedRequests.filter(
              (request, index, self) =>
                index === self.findIndex((r) => r._id === request._id)
            );
            return uniqueRequests;
          });

          // Check if there are more requests
          setHasMore(res.data.hasMore);
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoadingReq(false);
      }
    } else {
      setHasMore(false);
    }
  };

  const loadMoreRequests = () => {
    if (hasMore) setPage((prevPage) => prevPage + 1);
  };

  const [connects, setConnects] = useState([]);
  const [pageconnect, setPageConnect] = useState(1);
  const [hasMoreConnect, setHasMoreConnect] = useState(true);
  const [loadingCon, setLoadingCon] = useState(false);
  const fetchConnects = async () => {
    if (user?.connects?.length > 0) {
      setLoadingCon(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/get/user/connects/${pageconnect}?search=${search}`, // Pass search parameter
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (res.data.msg === "success") {
          setConnects((prevRequests) => {
            // Combine new requests with previous requests and filter out duplicates
            const combinedRequests = [...prevRequests, ...res.data.requests];
            const uniqueRequests = combinedRequests.filter(
              (request, index, self) =>
                index === self.findIndex((r) => r._id === request._id)
            );
            return uniqueRequests;
          });

          // Check if there are more requests
          setHasMoreConnect(res.data.hasMore);
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoadingCon(false);
      }
    } else {
      setHasMoreConnect(false);
    }
  };

  useEffect(() => {
    setPage(1); // Reset to first page on new search
    setRequests([]); // Clear previous results
    fetchRequests();
  }, [page, user, search]);

  useEffect(() => {
    setPageConnect(1);
    setConnects([]);
    fetchConnects();
  }, [pageconnect, user, search]);

  const loadMoreConnects = () => {
    if (hasMoreConnect) setPageConnect((prevPage) => prevPage + 1);
  };

  return (
    <div>
      <div className="d-flex">
        <TabButtons
          buttons={buttons}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        {selected === "About" && (
          <About
            user={user}
            isNonMobile1024={isNonMobile1024}
            watcher={watcher}
            setWatcher={setWatcher}
            isNonMobile450={isNonMobile450}
          />
        )}
        {selected === "Activity" && (
          <Activity
            user={user}
            watcher={watcher}
            isNonMobile1024={isNonMobile1024}
            isNonMobile450={isNonMobile450}
          />
        )}
        {user?._id === watcher?._id && selected === "Connections" && (
          <Connections
            user={user}
            loadMoreRequests={loadMoreRequests}
            requests={requests}
            hasMore={hasMore}
            watcher={watcher}
            setWatcher={setWatcher}
            setUser={setUser}
            setRequests={setRequests}
            loadMoreConnects={loadMoreConnects}
            connects={connects}
            setConnects={setConnects}
            hasMoreConnect={hasMoreConnect}
            setSearch={setSearch}
            search={search}
            loadingCon={loadingCon}
            loadingReq={loadingReq}
          />
        )}
      </div>
    </div>
  );
};

export default UserInfo;
