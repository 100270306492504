import { useEffect, useRef, useState } from "react";

import France from "../../assets/images/countries/France.png";
import Switzerland from "../../assets/images/countries/Switzerland.png";
import Germany from "../../assets/images/countries/Germany.png";
import UnitedKingdom from "../../assets/images/countries/United Kingdom.png";
import Estonia from "../../assets/images/countries/Estonia.png";
import SchoolIcon from "@mui/icons-material/School";
import Romania from "../../assets/images/countries/Romania.png";
import Finland from "../../assets/images/countries/Finland.png";
import Georgia from "../../assets/images/countries/Georgia.png";
import Greece from "../../assets/images/countries/Greece.png";
import Hungary from "../../assets/images/countries/Hungary.png";

import Ireland from "../../assets/images/countries/Ireland.png";
import Italy from "../../assets/images/countries/Italy.png";

import Latvia from "../../assets/images/countries/Latvia.png";
import Liechtenstein from "../../assets/images/countries/Liechtenstein.png";
import Lithuania from "../../assets/images/countries/Lithuania.png";
import Luxembourg from "../../assets/images/countries/Luxembourg.png";
import Malta from "../../assets/images/countries/Malta.png";
import Moldova from "../../assets/images/countries/Moldova.png";
import Netherlands from "../../assets/images/countries/Netherlands.png";

import NorthMacedonia from "../../assets/images/countries/North Macedonia.png";
import Norway from "../../assets/images/countries/Norway.png";

import Poland from "../../assets/images/countries/Poland.png";
import Portugal from "../../assets/images/countries/Portugal.png";

import Slovakia from "../../assets/images/countries/Slovakia.png";
import Slovenia from "../../assets/images/countries/Slovenia.png";

import Spain from "../../assets/images/countries/Spain.png";
import Sweden from "../../assets/images/countries/Sweden.png";

import Turkey from "../../assets/images/countries/Turkey.png";
import { useMediaQuery } from "@mui/material";
import { FixedSizeList as List } from "react-window";
import uniicon from "../../assets/images/uniicon.png";
import { setUni } from "../../state";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import HomeThirdSection from "../../components/Home/HomeThirdSection";
import HomeFooter from "../../components/Home/HomeFooter";

const UniSelect = ({
  button = true,
  setSelectedUni,
  selectedUni,
  Section = false,
  fullwidth = false,
}) => {
  const isNonMobile450 = useMediaQuery("(min-width:450px)");

  const isNonMobile1024 = useMediaQuery("(min-width:1025px)");
  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width:768px)");

  const [unilist, setUniList] = useState([]);
  const [country, setCountry] = useState("");
  const [checker, setChecker] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [changeLocation, ShowChangeLocation] = useState(country ? false : true);

  const Countries = [
    {
      icon: France,
      name: "France",
    },

    {
      icon: Germany,
      name: "Germany",
    },
    {
      icon: UnitedKingdom,
      name: "United Kingdom",
    },
    {
      icon: Switzerland,
      name: "Switzerland",
    },
    {
      icon: Estonia,
      name: "Estonia",
    },

    {
      icon: Finland,
      name: "Finland",
    },

    {
      icon: Georgia,
      name: "Georgia",
    },

    {
      icon: Greece,
      name: "Greece",
    },

    {
      icon: Hungary,
      name: "Hungary",
    },

    {
      icon: Ireland,
      name: "Ireland",
    },

    {
      icon: Italy,
      name: "Italy",
    },

    {
      icon: Latvia,
      name: "Latvia",
    },

    {
      icon: Liechtenstein,
      name: "Liechtenstein",
    },

    {
      icon: Lithuania,
      name: "Lithuania",
    },

    {
      icon: Luxembourg,
      name: "Luxembourg",
    },

    {
      icon: Malta,
      name: "Malta",
    },

    {
      icon: Moldova,
      name: "Moldova",
    },

    {
      icon: Netherlands,
      name: "Netherlands",
    },
    {
      icon: NorthMacedonia,
      name: "North Macedonia",
    },

    {
      icon: Norway,
      name: "Norway",
    },

    {
      icon: Poland,
      name: "Poland",
    },

    {
      icon: Portugal,
      name: "Portugal",
    },
    {
      icon: Romania,
      name: "Romania",
    },
    {
      icon: Slovakia,
      name: "Slovakia",
    },

    {
      icon: Slovenia,
      name: "Slovenia",
    },

    {
      icon: Spain,
      name: "Spain",
    },

    {
      icon: Sweden,
      name: "Sweden",
    },

    {
      icon: Turkey,
      name: "Turkey",
    },
  ];

  const [showUni, setShowUni] = useState(false);
  const [search, setSearch] = useState("");
  const unilistinputdivRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        unilistinputdivRef.current &&
        !unilistinputdivRef.current.contains(event.target)
      ) {
        setShowUni(false); // Close showUni when clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUniClick = () => {
    setShowUni(!showUni);
  };
  const [searchList, setSearchList] = useState(unilist);
  useEffect(() => {
    setSearchList(unilist);
  }, [unilist]);

  const [countrySearchList, SetCountriesList] = useState(Countries);
  const filterCountries = () => {
    if (!search) {
      SetCountriesList(Countries); // Reset searchList if search input is empty
    } else {
      const filteredUniversities = Countries.filter((uni) =>
        uni.name.toLowerCase().includes(search.toLowerCase())
      );
      SetCountriesList(filteredUniversities);
    }
  };

  // Update searchList whenever search state changes
  useEffect(() => {
    filterCountries();
  }, [search]);

  const filterUniversities = () => {
    if (!search) {
      setSearchList(unilist); // Reset searchList if search input is empty
    } else {
      const filteredUniversities = unilist.filter((uni) =>
        uni.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchList(filteredUniversities);
    }
  };

  // Update searchList whenever search state changes
  useEffect(() => {
    filterUniversities();
  }, [search]);

  const isValidImage = async (base64String) => {
    try {
      await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve();
        img.onerror = () => reject();
        img.src = `${base64String}`;
      });
      return true; // Image loaded successfully
    } catch (error) {
      return false; // Error occurred while loading image
    }
  };

  const ValidateLogo = async (base64String) => {
    return await isValidImage(base64String);
  };

  const renderUniversityItem = ({ index, style }) => {
    const uni = searchList[index];
    return (
      <div
        key={index}
        className="d-flex unilists "
        style={{
          ...style,
          gap: "0.5rem",
          width: "97%",

          alignItems: "center",
        }}
        onClick={() => {
          setSelectedUni(uni);
          setShowUni(false);
          dispatch(setUni({ Uni: uni }));
        }}
      >
        {uni.logo && ValidateLogo(uni.logo) ? (
          <img
            src={`${uni.logo}`}
            alt="University Logo"
            style={{ width: "24px", height: "24px", objectFit: "contain" }}
            className="Interest_img_icon shadowa rounded-5"
          />
        ) : (
          <>
            <img
              src={uniicon}
              alt="University Logo"
              style={{ width: "24px", height: "24px", objectFit: "contain" }}
              className="Interest_img_icon shadowa rounded-5"
            />
          </>
        )}
        <p
          className="gray90014400 d-flex"
          style={{ alignItems: "center", fontWeight: 500 }}
        >
          {uni.name}
        </p>
      </div>
    );
  };

  const renderUniversityList = () => (
    <List
      height={250}
      itemCount={searchList.length}
      itemSize={isNonMobile ? 55 : 70}
      width={"100%"}
      className="overflowx whitescrollbar"
    >
      {renderUniversityItem}
    </List>
  );

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch(
          "https://apiip.net/api/check?accessKey=5576a302-6489-4661-8958-67430c4fccee"
        );
        const data = await response.json();

        const userCountry = data.countryName || "Unknown";

        const matchingCountry = Countries.find(
          (country) => country.name.toLowerCase() === userCountry.toLowerCase()
        );

        if (matchingCountry) {
          setCountry(matchingCountry);
          setChecker(true);
        } else {
          setCountry(null);
        }
      } catch (error) {
        console.error("Error fetching country:", error.message);
      }
    };

    fetchCountry();
  }, []);

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (country) {
        setLoading(true);
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/get/university`, {
            name: country.name,
          })
          .then((res) => {
            if (res.data.msg === "success") {
              setUniList(res.data.university);
              setLoading(false);
            } else {
              console.log(res.data);
            }
          })
          .catch((err) => {
            setLoading(false);
          });

        setLoading(false);
      }
    };
    fetchData();
  }, [country]);

  if (Section) {
    return (
      <div className="marginsleftrightCompany ">
        <HomeThirdSection
          unilist={unilist}
          selectedUni={selectedUni}
          setSelectedUni={setSelectedUni}
          country={country}
          setCountry={setCountry}
          Countries={Countries}
          loading={loading}
          checker={checker}
          setChecker={setChecker}
          truncateString={truncateString}
        />
      </div>
    );
  }

  return (
    <span
      style={{
        display: "flex",
        flexWrap: "wrap",
        position: "relative",
        justifyContent: fullwidth ? "space-between" : "",
        gap: "1rem",

        alignItems: "center",
      }}
    >
      <div
        className="unilistinputdiv d-flex"
        ref={unilistinputdivRef}
        onClick={handleUniClick}
        style={{
          cursor: "pointer",
          width: fullwidth ? "100%" : "",
          maxWidth: fullwidth ? "100%" : "",
        }}
      >
        <div className="uniselect">
          {selectedUni && selectedUni.logo && ValidateLogo(selectedUni.logo) ? (
            <img
              src={`${selectedUni.logo}`}
              className="rounded-3"
              alt={uniicon}
              style={{
                objectFit: "contain",
                maxWidth: isNonMobile1440 ? "24px" : "24px",
                maxHeight: isNonMobile1440 ? "24px" : "24px",
              }}
            />
          ) : (
            <div
              className={`rounded-5 center align-items-center ${
                isNonMobile1440 ? "p-2" : "p-1"
              } notselecteduni `}
              style={{
                height: isNonMobile1440 ? "24px" : "24px",
                width: isNonMobile1440 ? "24px" : "24px",
                backgroundColor: country ? "#FFF" : "",
              }}
            >
              {" "}
              {country && country.icon ? (
                <img
                  src={country.icon}
                  alt={uniicon}
                  className="rounded-3"
                  style={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              ) : (
                <SchoolIcon
                  style={{
                    color: "white",
                    height: "20px",
                    width: "20px",
                  }}
                />
              )}
            </div>
          )}

          <p className="gray90014400" style={{ fontWeight: 500 }}>
            {selectedUni
              ? isNonMobile1024
                ? truncateString(`${selectedUni.name}`, 35)
                : truncateString(selectedUni.name, 35)
              : "Select your university to register"}
          </p>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke="#1D2939"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {showUni && (
        <div
          ref={unilistinputdivRef}
          style={{
            position: "absolute",
            overflow: "hidden",
            top: "0",
            left: 0,
            zIndex: 10,
            width: fullwidth ? "100%" : "",
          }}
          className="showUnilistmodal "
        >
          <div className="paddingsinsearchlist">
            <div className="searchinput">
              <div style={{ minHeight: "20px", minWidth: "20px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
                    stroke="#475467"
                    strokeWidth="1.70603"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search"
                className="searchinputuni"
              />
            </div>
            <hr style={{ width: "97%" }} />
            <div
              className=""
              style={{ overflow: "auto", maxHeight: "20.438rem" }}
            >
              {changeLocation && !checker ? (
                countrySearchList.map((con, index) => (
                  <div
                    key={index} // Make sure to add a unique key when mapping
                    className="d-flex unilists "
                    style={{
                      gap: "0.5rem",
                      marginTop: "1.25rem",
                    }}
                    onClick={() => {
                      setCountry(con);
                      ShowChangeLocation(false);
                      setSearch(""); // Assuming 'con' is the selected university in this context
                    }}
                  >
                    <img
                      src={con.icon}
                      alt="uni logo"
                      height="24px"
                      width="24px"
                      className="Interest_img_icon rounded-5"
                    />
                    <p
                      className="uniselecttext d-flex"
                      style={{ alignItems: "center" }}
                    >
                      {con.name}
                    </p>
                  </div>
                ))
              ) : (
                <>
                  {loading ? (
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1rem",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                        }}
                      >
                        Fetching Universities
                      </p>
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <>{renderUniversityList()}</>
                  )}
                </>
              )}
              <div
                className="d-flex unilists"
                style={{ gap: "0.5rem", marginTop: "1.25rem" }}
                onClick={() => {
                  setSelectedUni({ name: "other" });
                  navigate("/register?other=true");

                  setShowUni(false);
                }}
              >
                <p
                  className="uniselecttext d-flex"
                  style={{ alignItems: "center" }}
                >
                  other
                </p>
              </div>
            </div>
            <hr style={{ width: "97%" }} />
          </div>
          <div className="bottomsearchlist">
            <div
              className="d-flex"
              style={{
                justifyContent: "space-between",
                // width: "85%",
              }}
            >
              <div className="countrydisplay">
                {country ? (
                  <>
                    <img
                      src={country.icon}
                      alt="country flag"
                      width="16px"
                      height="16px"
                    />
                    {country.name}
                  </>
                ) : (
                  "select country"
                )}
              </div>
              <button
                className="changelocationbutton btn p-0"
                onClick={() => {
                  ShowChangeLocation(true);
                  setChecker(false);
                }}
              >
                {" "}
                Change Location
              </button>
            </div>
          </div>
        </div>
      )}
      {button && (
        <div
          className="d-flex"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <button
            className="continuebutton "
            style={{
              height: "50px",
              position: "relative",
              maxHeight: isNonMobile1440 ? "" : "48px",
              border: "none",
              width: isNonMobile450 ? "100%" : "90vw",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/register");
            }}
          >
            Join now
          </button>
        </div>
      )}
    </span>
  );
};

export default UniSelect;
