import { useEffect, useState } from "react";
import AddCertificate from "./AddCertificate";
import CertificateList from "./CertificateList";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SuitCase from "../../assets/images/icons/Suitcase";
import { toast } from "react-toastify";
import CompanyImage from "../../components/Company/CompanyImage";
const Certificates = ({ user, setWatcher, watcher, axios }) => {
  const [certificates, setCertificates] = useState(
    user?.certificates ? user?.certificates : []
  );

  const [popup, setPopup] = useState(false);
  useEffect(() => {
    if (user) {
      setCertificates(user?.certificates);
    }
  }, [user, popup]);

  const [newCertificate, setNewCertificate] = useState({
    logo: null,
    name: "",
    issued: "",
    credentialId: "",
    link: "",
  });

  const [addCertificate, setAddCertificate] = useState(false);

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(-1);

  const handleUpdateEducation = async () => {
    if (certificates.length === 0) {
      toast.error("Please add at least one Certificate.");
      return;
    }
    const formData = new FormData();
    certificates.forEach((exp, index) => {
      formData.append(`certificates[${index}][name]`, exp?.name);
      formData.append(`certificates[${index}][issued]`, exp?.issued);
      formData.append(
        `certificates[${index}][credentialId]`,
        exp?.credentialId
      );
      formData.append(`certificates[${index}][link]`, exp?.link);
      if (exp?.logo) {
        formData.append(`certificates[${index}][logo]`, exp?.logo); // Only if logo is present
      }
    });
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/update/student/certificates`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Certificates updated successfully");
          setPopup(false);
          setWatcher((prev) => {
            return {
              ...prev,
              certificates: res.data.certificates,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update Certificates");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleConfirm = () => {
    if (!newCertificate.credentialId) {
      toast.error("Please enter credential id");
    } else if (!newCertificate.link) {
      toast.error("Please enter link");
    } else if (!newCertificate.name) {
      toast.error("Please enter title");
    } else if (!newCertificate.issued) {
      toast.error("Please select issued date");
    } else {
      setCertificates((prevExperience) => {
        const updatedExperience = [...prevExperience];
        if (index === -1) {
          updatedExperience.push(newCertificate);

          return updatedExperience;
        } else {
          updatedExperience[index] = newCertificate;

          setIndex(-1);

          return updatedExperience;
        }
      });
      setNewCertificate({
        logo: null,
        name: "",
        issued: "",
        credentialId: "",
        link: "",
      });
      setAddCertificate(false);
      setIndex(-1);
    }
  };
  useEffect(() => {
    setNewCertificate({
      logo: null,
      name: "",
      issued: "",
      credentialId: "",
      link: "",
    });
    setAddCertificate(false);
    setIndex(-1);
  }, [popup]);

  return (
    <div
      className=""
      style={{
        padding: "1.25rem",
        marginTop: "1.875rem",
        background: "#FFF",
        borderRadius: "5px",
      }}
    >
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            overflowY: "auto",
            zIndex: 1001,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
          onClick={() => {
            setPopup(false);
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            style={{
              background: "#FFF",
              padding: "0.5rem",
              maxWidth: "715px",
              width: "100%",
            }}
          >
            <div
              className="  newPopUpContainer tablescroll"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "90vh",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="space align-items-center">
                <p className="gray90020400">Certificates</p>
                <CloseIcon
                  className="hoverdarkblacksvg"
                  style={{ cursor: "pointer", height: "24px", width: "24px" }}
                  onClick={() => {
                    setPopup(false);
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  background: "#EAECF0",
                  margin: "0.75rem 0rem 1.5rem 0rem",
                }}
              />
              {addCertificate ? (
                <AddCertificate
                  newCertificate={newCertificate}
                  setNewCertificate={setNewCertificate}
                  addCertificate={addCertificate}
                  setAddCertificate={setAddCertificate}
                  handleConfirm={handleConfirm}
                />
              ) : (
                <CertificateList
                  setAddCertificate={setAddCertificate}
                  loading={loading}
                  certificates={certificates}
                  setNewCertificate={setNewCertificate}
                  setIndex={setIndex}
                  handleUpdateEducation={handleUpdateEducation}
                  setCertificates={setCertificates}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="space gap-3 align-items-center flex-wrap">
        <p className="gray90020400">Certificates</p>
        {user?._id === watcher?._id && (
          <EditIcon
            className="hoverred"
            style={{ cursor: "pointer", height: "20px", width: "20px" }}
            onClick={() => {
              setPopup(true);
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "100%",
          height: "1px",
          background: "#EAECF0",
          margin: "0.75rem 0rem 1.5rem 0rem",
        }}
      />
      {user?.certificates?.length > 0 ? (
        user?.certificates?.map((cert, index) => (
          <div key={index} style={{ marginTop: "1.5rem" }}>
            <div className="space flex-wrap col-md-12 col-12">
              <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                {cert?.logo && (
                  <CompanyImage
                    src={
                      cert?.logo instanceof File
                        ? URL.createObjectURL(cert?.logo)
                        : cert?.logo
                    }
                  />
                )}
                <div>
                  {cert?.name && (
                    <p className="black16 text-start">{cert?.name}</p>
                  )}
                  <p className="gray14" style={{ marginTop: "0.75rem" }}>
                    Issued Date: {cert?.issued}
                    <br />
                    Credential ID: {cert?.credentialId}
                  </p>
                </div>
              </div>
              <div className="center" style={{ alignItems: "center" }}>
                <button
                  className="backbutton"
                  onClick={() =>
                    window.open(
                      `${cert?.link.startsWith("http") ? "" : "https://"}${
                        cert?.link
                      }`,
                      "_blank"
                    )
                  }
                >
                  Show Credential
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>
          <div className="center">
            <SuitCase />
          </div>
          <p
            className="gray40016500 text-center my-2"
            style={{ color: "#FD6F8E" }}
          >
            No Certificates Yet
          </p>

          <div className="center">
            <p
              className="text-center gray60011400"
              style={{ maxWidth: "340px", fontSize: "14px" }}
            >
              <span
                className="gray60011400"
                style={{ fontSize: "14px", fontWeight: 500 }}
              >
                Add your Certificates.
              </span>{" "}
              It's for sure will help you to find Jobs.
            </p>
          </div>
          <p
            className="pink16 text-center mt-3 hovercolorback"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPopup(true);
            }}
          >
            Add Certificates
          </p>
        </div>
      )}
    </div>
  );
};

export default Certificates;
