import { useMediaQuery } from "@mui/material";

import First from "./First";
import SecondSec from "../Second";
import vid from "../../../assets/images/ani2.webm";
import "./main.css";
import Third from "./Third";
import EventTypes from "./EventsTypes";
import Chart from "../../../assets/images/icons/Chart.png";
import Clock from "../../../assets/images/icons/Clock.png";
import Clip from "../../../assets/images/icons/Clip.png";
import Medal from "../../../assets/images/icons/Medal.png";
import Book from "../../../assets/images/icons/Book.png";
import Track from "../../../assets/images/icons/Track.png";
import office from "../../../assets/images/icons/office.png";
import videoicon from "../../../assets/images/icons/videoicon.png";

import women from "../../../assets/images/women.png";
import image1 from "../../../assets/images/eventhome1.png";
import image2 from "../../../assets/images/eventhome2.png";
import image3 from "../../../assets/images/eventhome3.png";
import SameSection from "../SameSection";

import HomeFooter from "../../../components/Home/HomeFooter";
import NewComp from "../../Home/NewComp";
import TitleDescMarquee from "../../CompanyHome/MarqueeSection";
import CompanyAction from "../../CompanyHome/Marquee";
const EventsHome = () => {
  const isNon1024 = useMediaQuery("(min-width:1024px)");

  const isNon530 = useMediaQuery("(min-width:530px)");

  const items = [
    {
      icon: Clock,
      text: "30-45 min",
    },
    {
      icon: Track,
      text: "Target by Faculty",
    },
    {
      icon: women,
      text: "DE&I Focus",
    },
    {
      icon: Book,
      text: "Add ressources",
    },

    {
      icon: Clip,
      text: "Link job posts",
    },
    {
      icon: Medal,
      text: "Branded & graded certificate",
    },
  ];

  const physicalitems = [
    {
      icon: Clock,
      text: "Self-paced",
    },
    {
      icon: Track,
      text: "Self-assessed",
    },
    {
      icon: office,
      text: "Office tour",
    },
    {
      icon: videoicon,
      text: "Video reply from students",
    },
    {
      icon: Book,
      text: "Add ressources",
    },
    {
      icon: Clip,
      text: "Attach job posts",
    },
    {
      icon: Medal,
      text: "Branded certificate",
    },
  ];

  const jobitems = [
    {
      icon: Clock,
      text: "Time constrained",
    },
    {
      icon: Track,
      text: "Track student performance",
    },
    {
      icon: Chart,
      text: "Track relative performance",
    },

    {
      icon: Book,
      text: "Add ressources",
    },
    {
      icon: Clip,
      text: "Link job posts",
    },
    {
      icon: Medal,
      text: "Branded & graded certificate",
    },
  ];

  const samesections = [
    {
      icon: <EventTypes type={"Live Stream"} TRUE={true} />,
      title: "Live Stream",
      description:
        "Live events offer a unique opportunity to enrich the candidate journey. By hosting interactive sessions, you can connect with early talent. Showcase your company culture, job opportunities, application process and other insights. ",
      img: image1,
      chips: items,
    },
    {
      icon: <EventTypes type={"Physical Event"} TRUE={true} />,
      title: "Physical Events",
      description:
        "Post your in-person events, such as office tours or networking sessions, on our platform to attract more students and give them the chance to register and engage with your company firsthand.",

      img: image2,
      chips: physicalitems,
    },
    {
      icon: <EventTypes type={"Job Closings"} TRUE={true} />,
      title: "Job Closings",
      description:
        "Keep students informed by displaying job and internship openings and closing dates in their calendar. Ensure top talent stays engaged and submits applications before the deadline.",

      img: image3,
      chips: jobitems,
    },
  ];

  return (
    <NewComp>
      <div
        style={{
          maxWidth: "100vw",
          minWidth: "100vw",
        }}
      >
        <div style={{ position: "relative" }}>
          <First isNon530={isNon530} />
          <SecondSec classes={"secondEventsHome"} vid={vid} />
          <Third />
          <div style={{ background: "rgba(242, 244, 247, 0.8)" }}>
            <div className={`marginsleftrightCompany marginsStudent`}>
              {samesections.map((same, index) => (
                <div key={index}>
                  <SameSection
                    isNonMobile1000={isNon1024}
                    icon={same.icon}
                    title={same.title}
                    description={same.description}
                    chips={same.chips}
                    img={same?.img}
                  />
                </div>
              ))}
            </div>
          </div>
          {/* <Fifthnew />
          <Second isNon={isNon} /> */}
          <div
            style={{
              background: "rgba(242, 244, 247, 0.8)",
              padding: "80px 0px",
            }}
          >
            <TitleDescMarquee toRemove={"Events"} />
          </div>
          <CompanyAction />

          <div className="" style={{ background: "#0C111D" }}>
            <div className="marginsleftrightCompany  pt-5">
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <div className="headerCompany">
                  <HomeFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewComp>
  );
};

export default EventsHome;
