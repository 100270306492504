import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Header from "../Challenge/PreviewWhole/Header";
import FirstSection from "../Challenge/PreviewWhole/FirstSection";
import Loader from "../../components/Loader/Loader";

const StudentChallengeMain = ({ }) => {
  const { uniqueId } = useParams();

  const navigate = useNavigate();
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [challenge, setChallenge] = useState(null);
  const [whitescreen, setWhiteScreen] = useState(false);
  const [taskCount, setTaskCount] = useState(0);
  const [Test, setTest] = useState(false);
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile768 = useMediaQuery("(min-width:768px)");
  const referenceDiv2 = useRef(null);
  const [strike, setStrike] = useState(0);
  const [time, setTime] = useState(10);

  const [progress, setProgress] = useState(null);

  useEffect(() => {
    if (progress) {
      setStrike(Number(progress.strike));
    }
  }, [progress]);

  const UpdateRecord = async (question, answer, show, files, timeleft) => {
    const formData = new FormData();
    formData.append("taskCount", taskCount);
    formData.append("question", question);
    formData.append("answer", JSON.stringify(answer));
    formData.append("show", JSON.stringify(show));
    formData.append("timeleft", timeleft);
    formData.append("strike", strike);
    formData.append("uniqueId", uniqueId);
    formData.append("files", JSON.stringify(files));

    // Append files with associated metadata
    files.forEach((fileObj, index) => {
      formData.append(`file_${index}`, fileObj.file); // Actual file
      formData.append(`file_${index}_taskCount`, fileObj.taskCount); // Associated taskCount
      formData.append(`file_${index}_question`, fileObj.question); // Associated question
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/update/progress`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const SubmitDone = async (answer, show, files, timeleft) => {
    const formData = new FormData();
    setLoading(true);
    setCompleted(true);
    formData.append("answer", JSON.stringify(answer));
    formData.append("show", JSON.stringify(show));
    formData.append("timeleft", timeleft);

    formData.append("uniqueId", uniqueId);
    formData.append("files", JSON.stringify(files));
    // Append files with associated metadata
    files.forEach((fileObj, index) => {
      formData.append(`file_${index}`, fileObj.file); // Actual file
      formData.append(`file_${index}_taskCount`, fileObj.taskCount); // Associated taskCount
      formData.append(`file_${index}_question`, fileObj.question); // Associated question
    });

    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/challenge/complete`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            navigate(`/challenge/result/${res.data.newuniqueId}`);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error Completing Challenge");
        });
      setLoading(false);
      setCompleted(false);
    } catch (error) {
      console.error(error);
    }
  };

  const blocked = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/block`,
        {
          uniqueId: uniqueId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        navigate("/student/challenges");
        toast.error("You have been disqualified from this challenge");
      })
      .catch((err) => {
        console.log(err);
        navigate("/student/challenges");
      });
  };

  useEffect(() => {
    let timer;

    if (whitescreen) {
      if (time > 0) {
        // Start the countdown if whitescreen is enabled and time is greater than 0
        timer = setInterval(() => {
          setTime((prevTime) => {
            const newTime = prevTime - 1;
            if (newTime <= 0) {
              clearInterval(timer);
              blocked();
              setWhiteScreen(false); // Stop the whitescreen after being banned
            }
            return newTime >= 0 ? newTime : 0;
          });
        }, 1000);
      }
    } else {
      // Reset the timer when whitescreen is disabled
      setTime(10);
    }

    return () => {
      clearInterval(timer); // Cleanup interval on unmount or when whitescreen changes
    };
  }, [whitescreen, time]);

  // Store the original content and style

  const fetchChallenge = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/get/test/challenge`, {
        params: { uniqueId: uniqueId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.msg === "success") {
          setChallenge(res.data.challenge);
          setProgress(res.data.progress);
        } else if (res?.data?.msg === "Already started") {
          toast.error("You can only take part in Fast-Track Challenges once");

          navigate(-1);
        } else {
          navigate(-1);
        }
      })
      .catch((err) => {
        console.log(err);
        navigate(-1);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (uniqueId) {
      fetchChallenge();
    }
  }, [uniqueId]);

  useEffect(() => {
    let isDevToolsOpen = false;
    const threshold = 160;

    const blockPrintScreen = (event) => {
      if (event.key === "PrintScreen") {
        navigator.clipboard.writeText("");
        toast.warning("Screenshots are not allowed!");
      }
    };

    const handleContextMenu = (e) => {
      e.preventDefault();
      toast.warning("Right-click is disabled to protect content.");
    };

    const detectDevTools = () => {
      if (
        window.outerWidth - window.innerWidth > threshold ||
        window.outerHeight - window.innerHeight > threshold
      ) {
        if (!isDevToolsOpen) {
          isDevToolsOpen = true;
          setStrike((prev) => {
            const newStrike = prev + 1;
            setWhiteScreen(true);
            if (newStrike > 1) {
              toast.error(`Developer tools detected. Strike ${newStrike}/3`);
            } else {
              toast.warning(`Developer tools detected. Strike ${newStrike}/3`);
            }
            if (newStrike >= 3) {
              blocked();
            }
            return newStrike;
          });

          setWhiteScreen(true);
        }
      } else if (isDevToolsOpen) {
        isDevToolsOpen = false;
        setWhiteScreen(false);
      }
    };

    // Add event listeners
    window.addEventListener("keyup", blockPrintScreen);
    document.addEventListener("contextmenu", handleContextMenu);
    window.addEventListener("resize", detectDevTools);
    window.addEventListener("scroll", detectDevTools);

    // Cleanup event listeners
    return () => {
      window.removeEventListener("keyup", blockPrintScreen);
      document.removeEventListener("contextmenu", handleContextMenu);
      window.removeEventListener("resize", detectDevTools);
      window.removeEventListener("scroll", detectDevTools);
    };
  }, []);

  return (
    <div className="allnonselect" style={{ width: "100vw", minHeight: "90vh" }}>
      {loading ? (
        <div
          className=" center align-items-center flex-column "
          style={{ height: "100vh", width: "100vw" }}
        >
          {completed && (
            <p className="text-center m-3 black16">
              Congragulations, Challenge Completed,
              <br /> Please wait, analysing answers ...
            </p>
          )}
          <Loader />
        </div>
      ) : (
        challenge && (
          <div>
            {whitescreen && (
              <div
                className="center align-items-center justify-content-center screenshot-overlay"
                style={{
                  height: "100vh",
                  position: "sticky",
                  background: "#FFF",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  zIndex: 1000,
                }}
              >
                <p
                  className="black20 text-center"
                  style={{ fontFamily: "Poppins" }}
                >
                  {" "}
                  Please close the developers mode in {time} seconds or you will
                  be disqualified <br />
                  OR
                  <br /> if you get 3 strikes you will never be able to take
                  part in this challenge.
                </p>
              </div>
            )}
            <Header
              company={JSON.parse(challenge?.company)}
              title={challenge?.title}
              isNonMobile1024={isNonMobile1024}
              setTest={setTest}
              Test={Test}
              type={challenge.type}
              actual={true}
              referenceDiv2={referenceDiv2}
            />

            <FirstSection
              Test={Test}
              setTest={setTest}
              newTasks={challenge?.alltask}
              taskCount={taskCount}
              UpdateRecord={UpdateRecord}
              color={
                JSON.parse(challenge?.company)?.color
                  ? JSON.parse(challenge?.company)?.color
                  : "#E31b54"
              }
              setTaskCount={setTaskCount}
              actual={true}
              type={challenge.type}
              frombefore={progress}
              isNonMobile1024={isNonMobile1024}
              isNonMobile768={isNonMobile768}
              taskCounter={-1}
              referenceDiv2={referenceDiv2}
              SubmitDone={SubmitDone}
            />
          </div>
        )
      )}
    </div>
  );
};
export default StudentChallengeMain;
