import { Confetti } from "./Success";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Step6 = () => {
  return (
    <div style={{ position: "relative" }}>
      <CheckCircleIcon
        style={{ height: "40px", width: "40px", color: "green" }}
      />
      <div>
        <p className="sectionheadingclamped mt-4">Successful</p>
        <p className="mt-2 gray70014400">Thank you for your registration. </p>
      </div>
      <p className="pink16 mt-4">
        We are currently onboarding your University.
      </p>
      <p className="gray70014400 mt-2">
        We will let you know shortly by sending an email.
      </p>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          pointerEvents: "none",
        }}
      >
        <Confetti manualstart={false} />
      </div>
    </div>
  );
};

export default Step6;
