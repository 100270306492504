import TuneIcon from "@mui/icons-material/Tune";
import { useState, useEffect, useRef } from "react";
import "./filter.css";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import France from "../../assets/images/countries/France.png";
import Switzerland from "../../assets/images/countries/Switzerland.png";
import Germany from "../../assets/images/countries/Germany.png";
import UnitedKingdom from "../../assets/images/countries/United Kingdom.png";
const Filter = ({
  bulkAction,
  setBulkAction,
  isNonMob768,
  engageselect,
  setEngageSelect,
  locationfilter,
  setLocationFilter,
  languagefilter,
  setLanguageFilter,
  studyLevel,
  setStudyLevels,
  graduationFilter,
  setGraduationFilter,
  academicFilter,
  setAcademicFilter,
  setApplyFilter,
  handleBulkAction,
  searchFilter,
  setSearchFilter,
  TRUE,
}) => {
  const [filterPopup, setFilterPopup] = useState(false);
  const [engagement, setEngagement] = useState(false);
  const [study, setStudy] = useState(false);
  const [Language, setLanguage] = useState(false);
  const [studylevel, setStudyLevel] = useState(false);
  const [graduation, setGraduation] = useState(false);
  const [academic, setAcademic] = useState(false);

  const [popup, setPopUp] = useState(false);
  const filterRef = useRef(null);
  const iconRef = useRef();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setPopUp(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Countries = [
    {
      icon: France,
      name: "France",
    },

    {
      icon: Germany,
      name: "Germany",
    },
    {
      icon: UnitedKingdom,
      name: "United Kingdom",
    },
    {
      icon: Switzerland,
      name: "Switzerland",
    },
  ];

  const bulkOptions = [TRUE ? "Shortlist" : false, "Remove"];

  return (
    <div className="d-flex flex-wrap space" style={{ gap: "1.5rem" }}>
      <div className="d-flex flex-wrap" style={{ gap: "1.5rem" }}>
        <div>
          {" "}
          <div
            className="forminput space"
            style={{ cursor: "pointer", width: "190px" }}
            onClick={() => setPopUp(!popup)}
          >
            {bulkAction ? bulkAction : "Select Bulk Action"}
            <KeyboardArrowDownIcon />
          </div>
          {popup && (
            <div
              className="w-100"
              style={{ position: "relative", zIndex: 2, marginTop: "2px" }}
              ref={iconRef}
            >
              <div className="w-100" style={{ position: "absolute" }}>
                <div
                  className="d-flex shadowa w-100"
                  style={{
                    flexDirection: "column",
                    background: "transparent",
                    boxShadow: "2px solid #000",
                    borderRadius: "5px",
                  }}
                >
                  {bulkOptions.map(
                    (option, index) =>
                      option && (
                        <button
                          className="d-flex popupbuttontext btn"
                          style={{
                            padding: "11px 1rem",
                            maxHeight: "2rem",
                            borderRadius:
                              index === 0
                                ? "5px 5px 0px 0px"
                                : index === bulkOptions.length - 1
                                ? "0px 0px 5px 5px"
                                : "0px",
                            gap: "10px",
                            border: "none",
                            borderTop:
                              index !== 0 ? "1px solid #DEE3E6" : "none",
                            background: "#FFF",

                            width: "100%",
                            minHeight: "42px",
                          }}
                          onClick={() => {
                            setBulkAction(option);
                            setPopUp(false);
                          }}
                        >
                          {option}
                        </button>
                      )
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <button
          className="continuebutton"
          disabled={!bulkAction}
          onClick={() => handleBulkAction()}
        >
          Apply
        </button>
      </div>
      <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
        <div className="forminput">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
              stroke="#475467"
              strokeWidth="1.70603"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            type="search"
            placeholder="Search"
            className="searchinputuni"
          />
        </div>
      </div>
    </div>
  );
};
export default Filter;
