import { useMediaQuery } from "@mui/material";

// Custom hook to manage multiple breakpoints
const NonMobile = () => {
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile768 = useMediaQuery("(min-width:768px)");
  const isNonMobile500 = useMediaQuery("(min-width:500px)");
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  const isNonMobile1250 = useMediaQuery("(min-width:1250px)");
  const isNonMobile810 = useMediaQuery("(min-width:810px)");
  const isNonMobile1271 = useMediaQuery("(min-width:1271px)");
  const isNonMobile1190 = useMediaQuery("(min-width:1190px)");
  const isNonMobile1100 = useMediaQuery("(min-width:1100px)");

  return {
    isNonMobile1024,
    isNonMobile768,
    isNonMobile500,
    isNonMobile450,
    isNonMobile1440,
    isNonMobile1250,
    isNonMobile810,
    isNonMobile1271,
    isNonMobile1190,
    isNonMobile1100,
  };
};

export default NonMobile;
