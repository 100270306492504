import { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import menuPic from "../../assets/images/menuPic.png";
import { Spiral as Hamburger } from "hamburger-react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MegaMenu from "../NewPages/MegaMenu";
import NonMobile from "../../components/NonMobile/NonMobile";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Navigations from "./Navigations";
const SecondHeader = ({
  selected,
  setSelected,
  solution1,
  setSolution1,
  path,
  selected2,
  setSelected2,
}) => {
  const [Test, setTest] = useState(false);
  const { buttons } = Navigations();

  const [selectedButtonGroup, setButton] = useState(
    buttons.find((btn) => btn.name === selected)
  );

  useEffect(() => {
    setButton(buttons.find((btn) => btn.name === selected));
  }, [selected]);

  const { isNonMobile1024, isNonMobile500, isNonMobile768 } = NonMobile();
  const [solution, setSolution] = useState(false);

  useEffect(() => {
    let found = false;

    buttons.map((button) => {
      if (found) return; // Skip further processing if the item is already found

      button.buttons.map((btn) => {
        if (path.toLowerCase() === btn.link) {
          setSelected2(btn.name);
          found = true; // Set the flag to break out of the outer loop
          return;
        }
      });
    });
  }, [path]);

  return (
    <div style={{ position: "sticky", zIndex: 1000, top: 0 }}>
      <div
        className="newsecondheader"
        onMouseEnter={() => {
          setSolution1(false);
        }}
      >
        <div className="marginsleftrightCompany">
          <div className="center">
            <div className="headerCompany space gap-4 align-items-center ">
              <img
                src={logo}
                alt="logo"
                className="logoimage "
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.location.href = "/";
                  window.scrollTo(0, 0);
                }}
              />
              {isNonMobile1024 && (
                <div className="d-flex gap-4">
                  {selectedButtonGroup?.buttons?.map((button) => (
                    <a
                      key={button?.name}
                      href={button.link}
                      className="gray95014500button gap-2"
                      style={{
                        textDecoration: "none",
                        color: selected2 === button.name ? "#E31b54" : "",
                      }}
                      target={button.link.includes("http") ? "_blank" : ""}
                      onMouseEnter={(e) => {
                        e.stopPropagation();
                        if (button.name === "Solutions") {
                          setSolution1(true);
                        } else {
                          setSolution1(false);
                        }
                      }}
                    >
                      {button?.name}
                      {button.name === "Solutions" && (
                        <ArrowDropDownIcon
                          style={{ height: "20px", width: "20px" }}
                        />
                      )}
                    </a>
                  ))}
                </div>
              )}

              {isNonMobile1024 && (
                <div className="d-flex gap-3">
                  <button
                    className="backbutton"
                    style={{ maxHeight: "36px", fontWeight: 500 }}
                  >
                    {selectedButtonGroup?.buttons2[0]?.name}
                  </button>
                  <button
                    className="continuebutton"
                    style={{ maxHeight: "36px", fontWeight: 500 }}
                  >
                    {selectedButtonGroup?.buttons2[1]?.name}
                  </button>
                </div>
              )}
              {!isNonMobile1024 && (
                <Hamburger
                  toggled={Test}
                  onToggle={() => setTest(!Test)}
                  size={24}
                  direction="right"
                />
              )}
              {!isNonMobile1024 && (
                <div
                  className={`newmenu   ${Test ? "activee" : "inactive"} ${
                    isNonMobile500 ? "shadowa" : ""
                  }`}
                  style={{
                    height: "calc(100vh - 65px)",
                    background: "#F9FAFB",
                    zIndex: 100,
                    overflowX: "clip",
                    top: "65px",

                    position: "fixed",
                  }}
                >
                  <div
                    className="marginsleftrightCompany"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <div className="center" style={{ height: "100%" }}>
                      <div
                        className="headerCompany d-flex gap-5"
                        style={{ height: "100%" }}
                      >
                        <div
                          className={` innermenunew px-0 ${
                            solution ? "activeee" : "inactiveee"
                          }  `}
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <div
                            className="d-flex gap-5"
                            style={{ height: "100%", width: "100%" }}
                          >
                            {isNonMobile768 && (
                              <img
                                src={menuPic}
                                alt="menu"
                                className="img-fluid"
                                style={{
                                  maxWidth: "360px",
                                  padding: "40px 0px",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                            <div
                              className="tablescroll d-flex flex-column justify-content-between gap-4"
                              style={{
                                height: "100%",
                                overflowX: "clip",
                                padding: "40px 0px",
                                overflowY: "auto",
                                width: "100%",
                              }}
                            >
                              <div>
                                <p className=" menutextinmenu ">MENU</p>
                                <p
                                  className="pink20"
                                  style={{
                                    marginTop: "1.5rem",
                                    fontSize: "22px",
                                  }}
                                >
                                  {selected}
                                </p>
                                <div
                                  className="d-flex flex-column "
                                  style={{ gap: "22px", marginTop: "22px" }}
                                >
                                  {selectedButtonGroup?.buttons?.map((button) =>
                                    button.name === "Solutions" ? (
                                      <div
                                        key={button?.name}
                                        className="gray95014400 hoverred gap-2 space"
                                        onClick={() => {
                                          setSolution(true);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Solutions
                                        <ArrowForwardIosIcon
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <a
                                        key={button?.name}
                                        href={button.link}
                                        target={
                                          button.link.includes("http")
                                            ? "_blank"
                                            : ""
                                        }
                                        className="gray95014400 hoverred"
                                        style={{
                                          textDecoration: "none",
                                          fontWeight: 500,
                                          color:
                                            selected2 === button.name
                                              ? "#E31b54"
                                              : "",
                                        }}
                                      >
                                        {button?.name}
                                      </a>
                                    )
                                  )}
                                </div>
                                <div
                                  className="d-flex   gap-3"
                                  style={{
                                    marginTop: "1.5rem",
                                    flexDirection: isNonMobile500
                                      ? "row"
                                      : "column",
                                  }}
                                >
                                  <button
                                    className="backbutton p-2 "
                                    style={{ width: "100%" }}
                                  >
                                    {selectedButtonGroup?.buttons2[0]?.name}
                                  </button>
                                  <button
                                    className="continuebutton p-2"
                                    style={{ width: "100%" }}
                                  >
                                    {selectedButtonGroup?.buttons2[1]?.name}
                                  </button>
                                </div>
                              </div>
                              <div>
                                <p className=" menutextinmenu ">
                                  SWITCH PLATFORM
                                </p>
                                <div
                                  className="d-flex flex-column "
                                  style={{ gap: "22px", marginTop: "22px" }}
                                >
                                  {buttons
                                    ?.filter(
                                      (button) => button.name !== selected
                                    )
                                    .map((button) => (
                                      <p
                                        key={button?.name}
                                        className="gray95014400 hoverred"
                                        style={{
                                          textDecoration: "none",
                                          fontWeight: 500,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setSelected(button.name);
                                        }}
                                      >
                                        {button?.name}
                                      </p>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`innersolutionsdiv ${
                            solution ? "activeeee" : "inactiveeee"
                          }`}
                        >
                          <div
                            className="d-flex gap-2 menutextinmenu hoverred align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSolution(false)}
                          >
                            <ArrowBackIosNewIcon
                              style={{ height: "14px", width: "14px" }}
                            />{" "}
                            Back
                          </div>
                          <div
                            className="tablescroll"
                            style={{
                              marginTop: "1.5rem",
                              position: "relative",
                              width: "100%",
                              height: "90%",
                              overflowY: "auto",
                            }}
                          >
                            <MegaMenu
                              megaMenu={solution}
                              setMegaMenu={solution}
                              isNon={isNonMobile1024}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isNonMobile1024 && (
        <div style={{ position: "absolute" }}>
          <MegaMenu
            megaMenu={solution1}
            setMegaMenu={setSolution1}
            fullview={true}
            isNon={isNonMobile768}
          />
        </div>
      )}
    </div>
  );
};
export default SecondHeader;
