const MediaType = ({ media, fullView = false, videoRef, isNon850 = false }) => {
  const isImage = (url) => /\.(jpeg|jpg|gif|png)$/i.test(url);
  const isVideo = (url) => /\.(mp4|webm|ogg)$/i.test(url);
  const isPdf = (url) => /\.pdf$/i.test(url);

  const renderMedia = () => {
    if (isImage(media)) {
      return (
        <img
          src={media}
          alt="media"
          style={fullView ? fullViewStyles : thumbnailStyles}
        />
      );
    } else if (isVideo(media)) {
      return (
        <video
          ref={videoRef}
          src={media}
          controls
          muted={fullView ? false : true}
          style={{
            backgroundColor: "black", // Add black background
            minWidth: "100%", // Set a minimum width for the controls
            // Set a minimum height for the controls
            ...(fullView ? { width: "100%", height: "100%" } : thumbnailStyles),
          }}
        />
      );
    } else if (isPdf(media)) {
      return (
        <div
          className="media-pdf"
          style={fullView ? pdfFullViewStyles : pdfThumbnailStyles}
        >
          {fullView ? (
            <embed src={media} type="application/pdf" style={pdfEmbedStyles} />
          ) : (
            <>
              <img src="/pdf-icon.png" alt="PDF" style={pdfIconStyles} />
              <span>PDF</span>
            </>
          )}
        </div>
      );
    } else {
      return (
        <div className="media-other">
          <img
            src="/file-icon.png"
            alt="file"
            style={fullView ? fullViewStyles : fileIconStyles}
          />
          <span>{fullView ? "Unsupported file type" : "File"}</span>
        </div>
      );
    }
  };

  // Styles for full view (modal)
  const fullViewStyles = {
    // Fill the vertical space of the viewport
    maxWidth: "100%",
    maxHeight: isNon850 ? "70vh" : "35vh",
    objectFit: "contain", // Ensure the media fits within the container
  };

  // Styles for thumbnails (grid)
  const thumbnailStyles = {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    aspectRatio: "inherit",
    borderRadius: "5px",
    // Fill the container while cropping excess
  };

  // PDF-specific styles for the full view
  const pdfFullViewStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh", // Make the PDF fill the screen in full view
  };

  const pdfEmbedStyles = {
    width: "100%",
    height: "100%",
  };

  const pdfThumbnailStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%", // Same as thumbnail for images and videos
    width: "100%",
  };

  const pdfIconStyles = {
    width: "50px",
    height: "50px",
  };

  // File icon for unsupported files
  const fileIconStyles = {
    width: "50px",
    height: "50px",
  };

  return renderMedia();
};

export default MediaType;
