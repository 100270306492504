import sandclock from "../../assets/images/sandclock.svg";
const JobCard = ({ challe, TRUE, checker, navigate }) => {
  const getTimeElapsed = (createdAt) => {
    const currentDate = new Date();
    const creationDate = new Date(createdAt);

    const timeDifference = currentDate - creationDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  };

  const MAX_LENGTH = 80;

  const truncatedTitle =
    challe.title.length > MAX_LENGTH
      ? `${challe.title.substring(0, MAX_LENGTH)}...`
      : challe.title;

  return (
    <div
      className="Challengescardouterwhitediv"
      style={{
        boxShadow: TRUE ? "0px 0px 10px rgba(0, 0, 0, 0.1)" : "",
        cursor: checker ? "pointer" : "",
      }}
      onClick={() => {
        if (checker) {
          navigate(`/dashboard/job/details/${challe.uniqueId}`);
        }
      }}
    >
      <div
        className="Challengescardinnergreydiv"
        style={{ overflow: "hidden" }}
      >
        <div style={{ padding: "1.5rem 0.75rem" }}>
          <p className=" text-start black10">
            {challe.createdAt ? getTimeElapsed(challe.createdAt) : null}
          </p>
          <p className="pink12" style={{ marginTop: "0.75rem" }}>
            {JSON.parse(challe.company).title}
          </p>
          <p
            className="jobecardChallengeTitle"
            style={{ marginTop: "0.625rem" }}
          >
            {truncatedTitle}
          </p>
        </div>
      </div>
      <div className="space ">
        <div
          className="center shadowa"
          style={{
            height: "48px",
            maxHeight: "48px",
            width: "48px",
            maxWidth: "48px",
            padding: "6px",
            background: "#FFF",
            alignItems: "center",
            borderRadius: "6px",
            position: "relative",
            marginTop: "-28px",
            marginLeft: "8px",
          }}
        >
          <img
            src={JSON.parse(challe.company).img}
            alt="company"
            style={{ maxWidth: "42px", maxHeight: "42px" }}
          />
        </div>
        <div className="jobCardBlueChip">
          <img src={sandclock} alt="sand clock" height="14px" width="14px" />
          <p className="black12">{challe.position}</p>
        </div>
      </div>
    </div>
  );
};
export default JobCard;
