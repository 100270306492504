const ChatIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.36863 1.66797H14.6353C16.7462 1.74042 18.4008 3.5068 18.3353 5.61797V11.0513C18.3667 12.0709 17.9904 13.061 17.2897 13.8023C16.589 14.5437 15.6217 14.9752 14.602 15.0013H6.66863C6.11962 15.0127 5.59898 15.2474 5.22696 15.6513L3.25196 17.7596C3.08464 17.9421 2.84952 18.0476 2.60196 18.0513C2.34628 18.0448 2.10381 17.9363 1.92851 17.7501C1.75322 17.5638 1.65965 17.3152 1.66863 17.0596V5.61797C1.6031 3.5068 3.25769 1.74042 5.36863 1.66797ZM14.6353 13.7513C16.0561 13.6794 17.1514 12.4724 17.0853 11.0513V5.61797C17.1514 4.1969 16.0561 2.98986 14.6353 2.91797H5.36863C3.94784 2.98986 2.85256 4.1969 2.91863 5.61797V16.2846L4.27696 14.8013C4.89815 14.1405 5.76176 13.7614 6.66863 13.7513H14.6353Z"
      fill={color || "#FF2D2E"}
    />
    <path
      d="M6.87696 7.29297H11.0436C11.3888 7.29297 11.6686 7.01315 11.6686 6.66797C11.6686 6.32279 11.3888 6.04297 11.0436 6.04297H6.87696C6.53178 6.04297 6.25196 6.32279 6.25196 6.66797C6.25196 7.01315 6.53178 7.29297 6.87696 7.29297Z"
      fill={color || "#FF2D2E"}
    />
    <path
      d="M13.127 9.3763H6.87696C6.53178 9.3763 6.25196 9.65612 6.25196 10.0013C6.25196 10.3465 6.53178 10.6263 6.87696 10.6263H13.127C13.4721 10.6263 13.752 10.3465 13.752 10.0013C13.752 9.65612 13.4721 9.3763 13.127 9.3763Z"
      fill={color || "#FF2D2E"}
    />
  </svg>
);

export default ChatIcon;
