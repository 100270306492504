const Warningsvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19093 5.08011C8.87785 3.79737 10.2158 2.99765 11.6709 3.00011C13.1518 2.98901 14.5176 3.79694 15.2209 5.10011L20.8609 15.5401C21.53 16.7797 21.4978 18.2798 20.7761 19.4895C20.0544 20.6992 18.7496 21.4401 17.3409 21.4401H6.00093C4.58808 21.4404 3.27986 20.6954 2.55931 19.4801C1.83875 18.2648 1.81276 16.7596 2.49093 15.5201L8.19093 5.08011ZM13.8709 5.81011C13.4371 4.99937 12.5904 4.49518 11.6709 4.50011C10.765 4.5064 9.93389 5.00427 9.50093 5.80011L3.81093 16.2201C3.38594 16.9928 3.40133 17.9326 3.85141 18.691C4.30148 19.4493 5.11909 19.913 6.00093 19.9101H17.3409C18.2196 19.9048 19.0315 19.4403 19.4815 18.6855C19.9315 17.9308 19.954 16.9957 19.5409 16.2201L13.8709 5.81011Z"
        fill="#F97066"
      />
      <path
        d="M11.6709 15.0002C11.2567 15.0002 10.9209 15.336 10.9209 15.7502C10.9209 16.1644 11.2567 16.5002 11.6709 16.5002C11.8707 16.5029 12.063 16.4248 12.2043 16.2836C12.3455 16.1423 12.4236 15.95 12.4209 15.7502C12.4209 15.5522 12.3416 15.3625 12.2007 15.2234C12.0597 15.0843 11.8689 15.0075 11.6709 15.0102V15.0002Z"
        fill="#F97066"
      />
      <path
        d="M11.6709 13.6302C11.259 13.6248 10.9263 13.2922 10.9209 12.8802V9.78022C10.9209 9.36601 11.2567 9.03022 11.6709 9.03022C12.0851 9.03022 12.4209 9.36601 12.4209 9.78022V12.8802C12.4209 13.2944 12.0851 13.6302 11.6709 13.6302Z"
        fill="#F97066"
      />
    </svg>
  );
};

export default Warningsvg;
