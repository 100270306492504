import React, { useState } from "react";
import logo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      className="RegisterOrganizationHeader d-flex"
      style={{ justifyContent: "space-between", marginBottom: "2rem" }}
    >
      <div className="justifySpace">
        <div className="d-flex" style={{ alignItems: "center" }}>
          <img
            src={logo}
            alt="logo"
            className="logoimage "
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="welcomemenucontainer">
          <button
            className="newSupportButton "
            style={{ background: "transparent" }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
              navigate("/employer/support");
              window.scrollTo(0, 0);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9.8263 6.69935C9.5067 6.73619 9.2655 7.0068 9.2655 7.32852C9.2655 7.65024 9.5067 7.92084 9.8263 7.95768C9.99422 7.96001 10.1558 7.89376 10.2738 7.77423C10.3917 7.65469 10.4559 7.49222 10.4513 7.32435C10.4468 6.98105 10.1696 6.70384 9.8263 6.69935Z"
                fill={hover ? "#E31B54" : "#101828"}
              />
              <path
                d="M9.8263 9.09935C9.65986 9.09708 9.49957 9.1622 9.38186 9.27991C9.26416 9.39761 9.19904 9.5579 9.2013 9.72435V12.3243C9.2013 12.6695 9.48112 12.9493 9.8263 12.9493C10.1715 12.9493 10.4513 12.6695 10.4513 12.3243V9.74102C10.4558 9.57239 10.3919 9.4091 10.2742 9.28826C10.1565 9.16742 9.99499 9.09929 9.8263 9.09935Z"
                fill={hover ? "#E31B54" : "#101828"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.8263 1.66602C5.32248 1.67061 1.67256 5.32053 1.66797 9.82435C1.66797 14.3301 5.32058 17.9827 9.8263 17.9827C14.332 17.9827 17.9846 14.3301 17.9846 9.82435C17.98 5.32053 14.3301 1.67061 9.8263 1.66602ZM9.8263 16.7327C6.01093 16.7327 2.91797 13.6397 2.91797 9.82435C2.91797 6.00898 6.01093 2.91602 9.8263 2.91602C13.6417 2.91602 16.7346 6.00898 16.7346 9.82435C16.73 13.6378 13.6398 16.7281 9.8263 16.7327Z"
                fill={hover ? "#E31B54" : "#101828"}
              />
            </svg>
            Support
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
