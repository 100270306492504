import "./home.css";

import FirstSection from "../../components/CompanyHome/FirstSection";
import animation from "../../assets/images/Animation1.mp4";
import animation2 from "../../assets/images/animation2prototype.mp4";
import animation3 from "../../assets/images/Animation3.mp4";
import HomeFooter from "../../components/Home/HomeFooter";
import NewThirdSection from "../../components/CompanyHome/NewThirdSection";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NewSecondSection from "../../components/CompanyHome/NewSecondSection";
import ShortListTalent from "../../components/CompanyHome/ShortlistTalent";

import NewSection from "../../components/CompanyHome/NewSection";

import NewComp from "../Home/NewComp";
import EventIcon from "../../assets/images/icons/EventIcon";
import JobIcon from "../../assets/images/icons/JobsIcon";
import ChallengeIcon from "../../assets/images/icons/ChallengeIcon";
import FeedIcon from "../../assets/images/icons/Feed";
import ChatIcon from "../../assets/images/icons/ChatIcon";
import TitleDescMarquee from "./MarqueeSection";
import CompanyAction from "./Marquee";
const CompanyHome = () => {
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const isNonMobile700 = useMediaQuery("(min-width:700px)");
  const isNonMobile1000 = useMediaQuery("(min-width: 1001px)");
  const isNonMobile1180 = useMediaQuery("(min-width: 1181px)");

  const isNonMobile500 = useMediaQuery("(min-width: 500px)");
  const navigate = useNavigate();

  const sections = [
    {
      title: (
        <>
          {" "}
          Spark interest
          <br /> with challenges
        </>
      ),
      desc: (
        <>
          Engage early talent in{" "}
          <span className="gray95016500 " style={{ fontWeight: 600 }}>
            a new and exciting way
          </span>{" "}
          by showcasing real-world case studies. Design challenges and give
          potential candidates the opportunity to{" "}
          <span className="gray95016500 " style={{ fontWeight: 600 }}>
            explore their future career at your company
          </span>
          .
        </>
      ),
      desc2: "Explore Challenge Types",
      chips: [],
      video: animation,
    },
    {
      video: animation2,
      title: (
        <>
          Scale your
          <br />
          campus recruiting
        </>
      ),
      desc: (
        <>
          {" "}
          Build an interactive funnel that guides interested students from{" "}
          <span className="gray95016500 " style={{ fontWeight: 600 }}>
            completing their first challenge
          </span>
          , participating in a{" "}
          <span className="gray95016500 " style={{ fontWeight: 600 }}>
            {" "}
            Q&A online event
          </span>
          , and progressing all the way to their job application and
          <span className="gray95016500 " style={{ fontWeight: 600 }}>
            {" "}
            on-platform interview
          </span>
          .
        </>
      ),
      desc2: "Explore solutions",
      chips: [
        {
          text: "Events",
          icon: <EventIcon color={"#FAA24B"} />,
          link: "/employer/events",
        },
        {
          text: "Jobs",
          icon: <JobIcon color={"#383874"} />,
          link: "/employer/job-posts",
        },
        {
          text: "Challenges",
          icon: <ChallengeIcon color={"#E31b54"} />,
          link: "/employer/solutions-challenges",
        },
      ],
    },
    {
      video: animation3,
      title: (
        <>
          {" "}
          Discover and shortlist
          <br />
          the right talent
        </>
      ),
      desc: (
        <>
          {" "}
          Leverage proprietary data—such as engagement levels, field of study,
          academic year, degree and skills—to enrich your{" "}
          <span className="gray95016500 " style={{ fontWeight: 600 }}>
            talent pipeline
          </span>{" "}
          with qualified and motivated students.
        </>
      ),
      desc2: "Explore solutions",
      chips: [
        {
          text: "Explore Analytics",
          icon: <FeedIcon color={"#079455"} />,
          link: "/employer/analytics",
        },
        {
          text: "Messenger",
          icon: <ChatIcon />,
          link: "/employer/messenger",
        },
      ],
    },
  ];

  return (
    <NewComp>
      <div style={{ width: "100vw", overflowX: "clip" }}>
        <>
          <div
            className="NewbackgroundCompany"
            style={{ paddingBottom: "6.25rem" }}
          >
            <div className="marginsleftrightCompany">
              <FirstSection navigate={navigate} />
            </div>
          </div>

          <NewSecondSection
            isNonMobile1000={isNonMobile1000}
            isNonMobile1180={isNonMobile1180}
            isNonMobile500={isNonMobile500}
          />

          <div
            className=""
            style={{
              width: "100vw",
              marginTop: "16.125rem",
              marginBottom: "8.125rem",
            }}
          >
            <div className="marginsleftrightCompany ">
              <NewThirdSection
                navigate={navigate}
                isNonMobile450={isNonMobile450}
                isNonMobile700={isNonMobile700}
              />
            </div>
          </div>

          <div className="newHomeSectionCOmpany">
            <div className="marginsleftrightCompany ">
              <div style={{ paddingTop: "8.125rem", paddingBottom: "5rem" }}>
                <div>
                  <ShortListTalent
                    section={sections[0]}
                    isNonMobile1000={isNonMobile1000}
                  />
                </div>

                <div style={{ marginTop: "130px" }}>
                  <NewSection />
                </div>
                {sections.slice(1, 3).map((section, index) => (
                  <div key={index} style={{ paddingTop: "60px" }}>
                    <ShortListTalent
                      section={section}
                      isNonMobile1000={isNonMobile1000}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div style={{ background: "", padding: "80px 0px" }}>
              <TitleDescMarquee />
            </div>
            <CompanyAction />
          </div>

          <div className="" style={{ background: "#0C111D" }}>
            <div className="marginsleftrightCompany ">
              <div className="center">
                <div className="headerCompany">
                  <HomeFooter />
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </NewComp>
  );
};

export default CompanyHome;
