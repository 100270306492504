import { useEffect, useRef, useState } from "react";
import "./jobpage.css";

import inviteteam from "../../assets/images/inviteteam.png";

import WorkIcon from "@mui/icons-material/Work";
import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp";
import TurnedInNotOutlinedIcon from "@mui/icons-material/TurnedInNotOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { toast } from "react-toastify";
import axios from "axios";
import OpenInNew from "../../assets/images/icons/OpeninNew";
const JobPage = ({
  job,
  handleNumChange,
  calculatedHeight,
  isNonMobile,
  TRUE,
  handleSaveJob,
  savedIds,
  setData,
  setPop,
  pop,
}) => {
  const [company, setCompany] = useState(() => {
    try {
      return job?.company ? JSON.parse(job.company) : null;
    } catch (error) {
      console.error("Failed to parse company data:", error);
      return null;
    }
  });
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (pop) {
      setPopup(true);
    }
  }, [pop]);

  useEffect(() => {
    if (!popup && pop) {
      setPop(false);
    }
  }, [popup]);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_FRONTEND_URL}/student/job/${job?.uniqueId}`
    );
    toast.success("Link Copied!");
  };

  useEffect(() => {
    setCompany(job?.company ? JSON.parse(job.company) : null);
  }, [job]);

  const handleApply = async () => {
    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/applyjob`,
        { uniqueId: job?.uniqueId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Applied Successfully");
          setData((prev) => !prev);
        } else if (res.data.msg === "Already applied") {
          toast.warning("Already Applied");
        } else {
          toast.error("Failed to apply");
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to apply");
      });
    setLoading(false);
  };

  const popupref = useRef(null);

  return (
    <div className="d-flex">
      <div
        style={{
          width: "100%",
          position: "relative",
          height:
            !isNonMobile && !TRUE
              ? "calc(100vh - 192px)"
              : calculatedHeight
              ? calculatedHeight
              : "fit-content",
          maxHeight:
            !isNonMobile && !TRUE ? "calc(100vh - 192px)" : calculatedHeight,
          overflowY: "scroll",
        }}
        className="jobpage tablescroll"
      >
        {popup && (
          <div
            className="center zindex1000"
            style={{
              width: "100%",
              height: "100%",

              position: "fixed",
              alignItems: "center",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 11,
              backgroundColor: "rgba(45, 50, 130, 0.30)",
              backdropFilter: "blur(3px)",
              padding: "1rem",
            }}
          >
            <div
              className=" shadowa newPopUpContainer"
              ref={popupref}
              style={{ maxWidth: "400px", padding: "0rem" }}
            >
              <div className="d-flex flex-column align-items-center" style={{}}>
                <div className="center">
                  <img
                    src={inviteteam}
                    alt="inviteteam"
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
                <div style={{ padding: "1.5rem" }}>
                  <p className="gray90018500 text-center ">Did you Apply?</p>
                  <p
                    className="text-center gray70020px"
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Did you actually apply or just browsed around?
                  </p>
                </div>
                <div
                  className="center"
                  style={{ padding: "0rem 1.5rem", width: "100%" }}
                >
                  <button
                    className="backbutton w-100"
                    style={{ marginTop: "1.25rem" }}
                    onClick={() => {
                      handleApply();
                      setPopup(false);
                    }}
                  >
                    Yeah, I applied!!!
                  </button>
                </div>
                <div
                  style={{
                    margin: "2rem 0rem 1.5rem 0rem",
                    height: "1px",
                    width: "100%",
                    background: "#EAECF0",
                  }}
                />
                <div
                  className=" d-flex gap-2"
                  style={{
                    width: "100%",
                    padding: "1.5rem",
                    paddingTop: "0rem",
                  }}
                >
                  <button
                    className="backbutton"
                    style={{ width: "50%" }}
                    onClick={() => setPopup(false)}
                  >
                    Nope!
                  </button>
                  <button
                    className="continuebutton"
                    style={{ width: "50%" }}
                    onClick={() => {
                      handleSaveJob();
                      setPopup(false);
                    }}
                  >
                    {" "}
                    {savedIds &&
                    savedIds.some(
                      (savedJob) => savedJob?.uniqueId === job?.uniqueId
                    )
                      ? "Remove from saved"
                      : "Save for later"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="space w-100" style={{ alignItems: "center" }}>
          {company?.img && (
            <div
              style={{
                padding: "2px",
                alignItems: "center",
                height: "40px",
                width: "40px",
                borderRadius: "5px",
              }}
              className="shadowa  center"
            >
              <img
                className="jobcard__logo rounded-2"
                src={company?.img}
                height="36px"
                width="36px"
                style={{ borderRadius: "5px" }}
              />
            </div>
          )}
          {!TRUE && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(`/student/job/${job?.uniqueId}`, "_blank");
              }}
            >
              <OpenInNew />
            </div>
          )}
        </div>
        <div style={{ marginTop: "1.25rem" }}>
          <p className="black16 text-start">{job?.title}</p>
          <p style={{ marginTop: "0.4rem" }} className="pink12">
            {company?.title}
          </p>
        </div>

        <div
          className="d-flex flex-wrap"
          style={{ marginTop: "1.25rem", gap: "0.75rem" }}
        >
          <div className="jobskillwrapper">
            <WorkIcon
              style={{ height: "16px", width: "16px", color: "#696969" }}
            />
            {job?.position}
          </div>
          <div className="jobskillwrapper">
            <AccessTimeSharpIcon
              style={{ height: "16px", width: "16px", color: "#696969" }}
            />
            {job?.timeRequirement}
          </div>
          {job?.selectedItem?.map((tag, index) => (
            <div key={index} className="jobskillwrapper">
              <img
                src={tag?.icon}
                alt="ski.name"
                height={"16px"}
                width={"16px"}
              />
              {tag?.text}
            </div>
          ))}
          {job?.country?.map((con, index) => (
            <div key={index} className="jobskillwrapper">
              {con?.City?.map((city, index) => city?.name)}
              <div>{con?.Country?.flag}</div>
            </div>
          ))}
        </div>
        <div style={{ marginTop: "0.75rem" }}>
          <div className="resultintaketable d-flex flex-wrap">
            {company?.industry}
            {company?.country?.map((con, index) => (
              <div key={index} className="d-flex">
                {con?.City?.map((city, i) => (
                  <p className="resultintaketable" key={i}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="4"
                      height="5"
                      viewBox="0 0 4 5"
                      fill="none"
                      style={{ marginLeft: "6px", marginRight: "6px" }}
                    >
                      <circle cx="2" cy="2.06268" r="2" fill="#D9D9D9" />
                    </svg>
                    {city?.name}, {con?.Country?.name}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            marginTop: "1.5rem",

            display: "flex",
            flexDirection: "column",
            height: "auto",
            overflow: "hidden",
          }}
        >
          <div
            className="allpoppins tablescroll"
            style={{
              fontFamily: "'Poppins', sans-serif",
              fontSize: "14px",

              // Allow the description to take up the remaining available space
              // Ensure it respects the parent's height
              padding: "1rem", // Add some padding for better readability
              paddingBottom: isNonMobile ? "5rem" : "8rem",
            }}
            dangerouslySetInnerHTML={{
              __html: job?.jobDescription,
            }}
          />
        </div>

        <div className="center">
          <div
            style={{ position: "fixed", bottom: isNonMobile ? "40px" : "80px" }}
            className="jobpagebuttonwrapper"
          >
            <button
              className="continuebutton"
              style={{ width: isNonMobile ? "180px" : "100px" }}
              onClick={() => {
                if (job?.method === "On varyance.io") {
                  handleApply();
                } else if (job?.url) {
                  if (job?.url.includes("http")) {
                    window.open(job.url, "_blank", "noopener,noreferrer");
                  } else {
                    window.open(
                      `https://${job.url}`,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }
                  setPopup(true);
                } else {
                  handleApply();
                }
              }}
              disabled={loading}
            >
              {loading ? "Loading..." : "Apply"}
            </button>

            {console.log({ job })}
            <button
              className="buttonwrappers"
              onClick={() => {
                handleSaveJob();
              }}
            >
              {savedIds &&
              savedIds.some(
                (savedJob) => savedJob?.uniqueId === job?.uniqueId
              ) ? (
                <TurnedInIcon
                  style={{ width: "20px", height: "20px", color: "#E31b54" }}
                />
              ) : (
                <TurnedInNotOutlinedIcon
                  className="hoverred"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              )}
            </button>
            <button
              className="buttonwrappers hoverred"
              onClick={() => {
                handleCopy();
              }}
            >
              <ShareOutlinedIcon style={{ width: "20px", height: "20px" }} />
            </button>
            {!TRUE && (
              <button
                className="buttonwrappers hoverred"
                onClick={() => handleNumChange(-1)}
              >
                <KeyboardArrowLeftOutlinedIcon
                  style={{ width: "20px", height: "20px" }}
                />
              </button>
            )}

            {!TRUE && (
              <button
                className="buttonwrappers hoverred"
                onClick={() => handleNumChange(1)}
              >
                <KeyboardArrowRightOutlinedIcon
                  style={{ width: "20px", height: "20px" }}
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPage;
