import { useState } from "react";
import CropFreeIcon from "@mui/icons-material/CropFree";

import ChallengeType from "../../pages/MainDashBoard/type";
const ShortListTalent = ({ isNonMobile1000, section }) => {
  const [test, setTest] = useState(false);
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent right-click context menu
  };
  const [hover, setHover] = useState(-1);
  const types = ["Fast-Track", "Virtual Experience", "Insights"];
  return (
    <div
      className="d-flex transition"
      style={{
        justifyContent: "center",
      }}
    >
      <div className="headerCompany">
        <div
          className="d-flex"
          style={{
            background: "#FFF",
            flexDirection: isNonMobile1000 ? "row" : "column",
          }}
        >
          <div
            className={`ShortListTalentouter ${test && "activepadding"}`}
            style={{
              width: isNonMobile1000 ? (test ? "30%" : "50%") : "100%",
            }}
          >
            <div>
              <p className="ShortListTalenttitle">{section.title}</p>
              <p
                className="gray95016500 text-start"
                style={{ marginTop: "1.5rem", fontWeight: 400 }}
              >
                {section.desc}
              </p>
              <div className="divider" style={{ margin: "1.5rem 0rem" }} />
              <p className="gray95014400" style={{ fontWeight: 500 }}>
                {section?.desc2}
              </p>
              {section?.desc2 === "Explore Challenge Types" ? (
                <div
                  className="d-flex align-items-center gap-2"
                  style={{ marginTop: "1.25rem" }}
                >
                  {types.map((type, index) => (
                    <a
                      href={`/employer/solutions-challenges#${
                        type === "Virtual Experience"
                          ? "Virtual-Experience"
                          : type
                      }`}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        key={index}
                        className="hiddennames"
                        onMouseEnter={() => {
                          setHover(index);
                        }}
                        onMouseLeave={() => {
                          setHover(-1);
                        }}
                      >
                        <ChallengeType
                          type={type}
                          notest={hover === index ? false : true}
                        />
                      </div>
                    </a>
                  ))}
                </div>
              ) : (
                <div
                  className="d-flex flex-wrap"
                  style={{ gap: "1rem", marginTop: "1.25rem" }}
                >
                  {section.chips.map((chip, index) => (
                    <a
                      key={index}
                      href={chip.link}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="newdashboardsidechips">
                        {chip.icon}
                        {chip.text}
                      </div>
                    </a>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              width: isNonMobile1000 ? (test ? "70%" : "50%") : "100%",
            }}
          >
            <div style={{ position: "relative" }}>
              {isNonMobile1000 && (
                <button
                  className=""
                  style={{
                    position: "absolute",
                    bottom: "1.5rem",
                    right: "1.5rem",
                    background: "transparent",
                    border: "none",
                    zIndex: 1,
                  }}
                  onClick={() => {
                    setTest(!test);
                  }}
                >
                  <CropFreeIcon
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "#FFF",
                      animation: "pulsate 2s infinite",
                    }}
                  />
                </button>
              )}
              <video
                src={section?.video}
                autoPlay
                loop
                muted
                playsInline
                controls={false}
                className="img-fluid"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover", // Ensure video covers the entire area
                  display: "block", // Remove any default inline space
                }}
                onContextMenu={handleContextMenu}
              />
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};
export default ShortListTalent;
