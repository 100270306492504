const JobDetailsRight = ({ job, handleApply, loading2, setPop }) => {
  return (
    <div
      style={{
        width: "100%",
        background: "#FFF",
        height: "fit-content",
        padding: "1rem",
        borderRadius: "5px",
      }}
    >
      <div
        className="d-flex flex-column align-items-center "
        style={{ width: "100%" }}
      >
        <div
          style={{
            padding: "0.5rem",
            alignItems: "center",
            height: "56px",
            width: "56px",
          }}
          className="shadowa rounded-3 center"
        >
          {job && (
            <img
              className="jobcard__logo rounded-2"
              src={JSON.parse(job?.company)?.img}
              height="20px"
              width="20px"
            />
          )}
        </div>
        <p
          className="text-center gray90018500"
          style={{ marginTop: "1rem", fontSize: "1rem", fontWeight: 600 }}
        >
          {JSON.parse(job?.company)?.title}
        </p>

        <button
          className="continuebutton"
          style={{ width: "100%", marginTop: "1rem" }}
          onClick={() => {
            if (job?.method === "On varyance.io") {
              handleApply();
            } else if (job?.url) {
              // Fixing the typo "uncludes" to "includes"
              if (job?.url.includes("http")) {
                window.open(job.url, "_blank", "noopener,noreferrer");
              } else {
                window.open(
                  `https://${job.url}`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }
              setPop(true);
            } else {
              handleApply();
            }
          }}
          disabled={loading2}
        >
          Apply
        </button>
      </div>
      <div
        style={{
          height: "1px",
          width: "100%",
          background: "#EAECF0",
          margin: "1.5rem 0rem",
        }}
      />
      <div className="d-flex flex-column" style={{ gap: "1.25rem" }}>
        <div className="d-flex flex-column" style={{ gap: "0.5rem" }}>
          {" "}
          <p className="gray50012500">Job Type</p>
          <p className="gray70014400" style={{ fontWeight: 500 }}>
            {job.position}
          </p>
        </div>
        <div className="d-flex flex-column" style={{ gap: "0.5rem" }}>
          <p className="gray50012500">Date Posted</p>
          <p className="gray70014400" style={{ fontWeight: 500 }}>
            {job.createdAt.split("T")[0]}
          </p>
        </div>
        {job.applicationCloseDate && (
          <div className="d-flex flex-column" style={{ gap: "0.5rem" }}>
            <p className="gray50012500">Application Deadline </p>
            <p className="gray70014400" style={{ fontWeight: 500 }}>
              {job.applicationCloseDate.split("T")[0]}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobDetailsRight;
