import AdjustRoundedIcon from "@mui/icons-material/AdjustRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
const Navbar = ({ questions, steps, questionNumber }) => {
  const step = [
    {
      name: "Task Overview",
      value: "A few details about your company",
    },
    {
      name: "Instructions ",
      value:
        "Which of the following venues is NOT classified as on-premise sales?",
    },
    {
      name: "Resources ",
      value:
        "Which of the following venues is NOT classified as on-premise sales?",
    },
  ];

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };
  return (
    <div>
      {step.map((ste, index) => (
        <div key={index} className="d-flex" style={{ gap: "0.75rem" }}>
          <div>
            <div>
              {steps > index ? (
                <CheckCircleOutlineRoundedIcon style={{ color: "#177B57" }} />
              ) : (
                <AdjustRoundedIcon style={{ color: "#177B57" }} />
              )}
            </div>
            <div
              className="center"
              style={{ alignItems: "center", position: "relative" }}
            >
              <div
                style={{
                  width: "2px", // Adjust the width to set the thickness of the line
                  height: "1.75rem", // Set the height to cover the entire height of its container
                  background: steps > index ? "#177B57" : "#EAECF0",
                  display: "inline-block", // Ensure the div is displayed as a block element
                }}
              ></div>
            </div>
          </div>
          <div>
            <p
              className="black14"
              style={{ color: steps > index ? "#177B57" : "" }}
            >
              {ste.name}
            </p>
            <p
              className="black12"
              style={{ color: steps > index ? "#177B57" : "" }}
            >
              {ste.value}
            </p>
          </div>
        </div>
      ))}
      {questions.map((ste, index) => (
        <div key={index} className="d-flex" style={{ gap: "0.75rem" }}>
          <div>
            <div>
              {steps === 3 && questionNumber > index ? (
                <CheckCircleOutlineRoundedIcon style={{ color: "#177B57" }} />
              ) : (
                <>
                  {steps === 4 ? (
                    <CheckCircleOutlineRoundedIcon
                      style={{ color: "#177B57" }}
                    />
                  ) : (
                    <AdjustRoundedIcon style={{ color: "#177B57" }} />
                  )}
                </>
              )}
            </div>
            <div
              className="center"
              style={{ alignItems: "center", position: "relative" }}
            >
              <div
                style={{
                  width: "2px", // Adjust the width to set the thickness of the line
                  height: "1.75rem", // Set the height to cover the entire height of its container
                  background:
                    steps === 3 && questionNumber > index
                      ? "#177B57"
                      : steps === 4
                      ? "#177B57"
                      : "#EAECF0",
                  display: "inline-block", // Ensure the div is displayed as a block element
                }}
              ></div>
            </div>
          </div>
          <div>
            <p
              className="black14"
              style={{
                color:
                  steps === 3 && questionNumber > index
                    ? "#177B57"
                    : steps === 4
                    ? "#177B57"
                    : "",
              }}
            >
              Question {index + 1}
            </p>
            <p
              className="black12"
              style={{
                color:
                  steps === 3 && questionNumber > index
                    ? "#177B57"
                    : steps === 4
                    ? "#177B57"
                    : "",
              }}
            >
              {truncateString(ste.question, 50)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Navbar;
