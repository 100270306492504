import axios from "axios";
import { useEffect, useState } from "react";
import CompanyImage from "../../components/Company/CompanyImage";
import ChallengeType from "../MainDashBoard/type";
import EastIcon from "@mui/icons-material/East";
const RecommendChal = ({ setEmptyChal }) => {
  const [challenges, setChallenges] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchChal = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/getchallenges`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: { limit: 5 },
      })
      .then((res) => {
        if (res.data.msg === "success") {
          setChallenges(res.data.challenges);
          setEmptyChal(res.data?.challenges?.length > 0 ? false : true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchChal();
  }, []);

  return (
    <div style={{ background: "#FFF", padding: "1rem", borderRadius: "5px" }}>
      <p className="gray95016500">Recommended Challenges</p>
      {loading ? (
        <div className="center mt-3">
          <div className="loader" />{" "}
        </div>
      ) : (
        <div
          className="d-flex flex-column"
          style={{ marginTop: "1.5rem", gap: "1.5rem" }}
        >
          {challenges?.map((chal, index) => (
            <div key={index}>
              <div className="d-flex " style={{ gap: "0.75rem" }}>
                <CompanyImage src={JSON.parse(chal?.company)?.img} />
                <div>
                  <p className="gray60011400" style={{ fontSize: "10px" }}>
                    {JSON.parse(chal?.company)?.title}
                  </p>
                  <a
                    href={`/challenge/${chal?.uniqueId}`}
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <p
                      className="mt-1 gray95014400 hoverdarkred"
                      style={{ fontWeight: 500, cursor: "pointer" }}
                    >
                      {chal?.title}
                    </p>{" "}
                  </a>
                  <div className="d-flex" style={{ marginTop: "0.75rem" }}>
                    <ChallengeType type={chal?.type} />
                  </div>
                </div>
              </div>

              {index !== challenges?.length - 1 && (
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    background: "#EAECF0",
                    marginTop: "0.75rem",
                  }}
                />
              )}
            </div>
          ))}
        </div>
      )}
      {challenges.length > 4 && (
        <a style={{ textDecoration: "none" }} href={`/student/Challenges`}>
          <div
            className="d-flex gap-1 pink14 hoverdarkred align-items-center"
            style={{ fontWeight: 500, marginTop: "1.25rem" }}
          >
            View All
            <EastIcon style={{ height: "20px", width: "30px" }} />
          </div>
        </a>
      )}
    </div>
  );
};
export default RecommendChal;
