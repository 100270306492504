import React, { useState, useEffect, useRef } from "react";

import "./resulttable.css";
import EmptySpace from "../../../assets/images/EmptyState.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Filter from "./Filter";
import SelectedBox from "../../../assets/images/icons/selectedbox";
import UnselectedBox from "../../../assets/images/icons/unselectedBox";
const ResultTable = ({
  results,
  livechallengesLength,
  selectedStudents,
  setSelectedStudent,
  DATA,
  setData,
  company,
  user,
  TRUE,
  challenges,
  bulkAction,
  setBulkAction,
  bulkOptions,
  handleBulkAction,
  searchFilter,
  setSearchFilter,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;
  const [challenge, setChallenge] = useState(results);
  const [showIcon, setShowIcon] = useState(false);
  const [Index, setIndex] = useState(-1);
  const [oldData, setOldData] = useState([]);

  let indexOfLastEntry = currentPage * entriesPerPage;
  let indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const [currentEntries, setCurrentEntries] = useState(
    challenge.slice(indexOfFirstEntry, indexOfLastEntry)
  );

  let totalEntries = challenge.length;
  let totalPages = Math.ceil(totalEntries / entriesPerPage);

  useEffect(() => {
    let filteredData = [...oldData];
    console.log(filteredData);
    if (searchFilter) {
      filteredData = filteredData.filter((entry) =>
        `${entry?.user?.firstName} ${entry?.user?.LastName}`
          .toLowerCase()
          .includes(searchFilter.toLowerCase())
      );
      setChallenge(filteredData);
    } else {
      setChallenge(oldData);
    }

    setCurrentPage(1);
  }, [searchFilter]);

  useEffect(() => {
    setChallenge(results);
    setOldData(results);
    indexOfLastEntry = currentPage * entriesPerPage;
    indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    setCurrentEntries(challenge.slice(indexOfFirstEntry, indexOfLastEntry));
    totalEntries = challenge.length;
    totalPages = Math.ceil(totalEntries / entriesPerPage);
  }, [results]);

  const handleShortList = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Challenge",
          owner: user?._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Added to Shortlist");
          setData((prev) => !prev);
        }
      });
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentEntries(challenge.slice(indexOfFirstEntry, indexOfLastEntry));
  }, [currentPage, challenge, indexOfFirstEntry, indexOfLastEntry]);

  const handleIconClick = (id) => {
    setSelectedStudent((prevSelectedStudents) => {
      if (prevSelectedStudents.includes(id)) {
        return prevSelectedStudents.filter((studentId) => studentId !== id);
      } else {
        return [...prevSelectedStudents, id];
      }
    });
  };

  const iconRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowIcon(false);
        setIndex(-1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRemove = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/blocked/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Challenge",
          owner: user?._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Removed");
          setData((prev) => !prev);
        }
      });
  };

  const handleRemoveShortlist = async (ida) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/remove/shortlist/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Challenge",
          owner: user?._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Removed");
          setData((prev) => !prev);
        }
      });
  };

  const renderPageButtons = () => {
    const maxButtons = 5;
    const buttons = [];

    let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxButtons - 1);

    if (endPage - startPage + 1 < maxButtons) {
      if (currentPage <= Math.floor(maxButtons / 2) + 1) {
        endPage = Math.min(totalPages, maxButtons);
      } else {
        startPage = Math.max(1, totalPages - maxButtons + 1);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          style={
            i === currentPage
              ? { backgroundColor: "#E31B54", color: "#FFF" }
              : { color: "#000", backgroundColor: "transparent" } // Adjust colors as needed
          }
          onClick={() => handlePageChange(i)}
          className={`btn normalbuttons ${i === currentPage && " rounded-3"}`}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  const checkChallenges = (user) => {
    let count = 0;

    if (challenges?.length > 0) {
      challenges.forEach((challenge) => {
        if (challenge?.countDates?.some((res) => res.userId === user?._id)) {
          return count++;
        }
      });
    }

    return `${count} / ${livechallengesLength}`;
  };

  return (
    <div>
      <Filter
        bulkAction={bulkAction}
        setBulkAction={setBulkAction}
        bulkOptions={bulkOptions}
        handleBulkAction={handleBulkAction}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
      />
      <div
        className="tablescroll maindashboardtopcards"
        style={{ marginTop: "0.75rem", overflowX: "auto" }}
      >
        <table
          className="tableofchallenges"
          style={{ width: "100%", overflowX: "scroll" }}
        >
          <thead className="thead">
            <tr className="theadtr">
              <th className="theadth ">Name </th>
              <th className="theadth"> University</th>
              <th className="theadth ">Course</th>
              <th className="theadth ">Location</th>
              <th className="theadth ">Completed Challenges</th>

              <th className="theadth text-center">Action</th>
            </tr>
          </thead>
          <tbody className="tbody">
            {currentEntries && currentEntries.length > 0 && (
              <>
                {currentEntries.map((challenge, index) => (
                  <tr key={index} className="tbodytr">
                    <td className="tbodytrtd">
                      <div className="d-flex" style={{ gap: "0.75rem" }}>
                        <div
                          className="center"
                          style={{ alignItems: "center", cursor: "pointer" }}
                          onClick={() => handleIconClick(challenge?.user?._id)}
                        >
                          {selectedStudents.includes(challenge?.user?._id) ? (
                            <SelectedBox style={{ color: "#E31b54" }} />
                          ) : (
                            <UnselectedBox
                              style={{ color: "#475467", cursor: "pointer" }}
                            />
                          )}
                        </div>
                        <div className="d-flex" style={{ gap: "11px" }}>
                          <img
                            src={challenge?.user?.profilePhoto}
                            alt="user image"
                            height="48px"
                            className="shadowa"
                            style={{ borderRadius: "125px" }}
                            width="48px"
                          />
                          <div>
                            <p
                              className="resultuserName hoverred"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  `/dashboard/user/${challenge?.user?.uniqueId}`
                                )
                              }
                            >
                              {challenge?.user?.firstName}{" "}
                              {challenge?.user?.LastName}
                            </p>
                            <p className="resultintaketable">
                              Intake {challenge?.user?.intake?.Year}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="tbodytrtd">
                      <p
                        className="resultuniname"
                        style={{ maxWidth: "14.375rem" }}
                      >
                        {challenge?.user?.uni?.name}
                      </p>
                    </td>

                    <td className="tbodytrtd ">
                      <p
                        className="resultuniname"
                        style={{ maxWidth: "14.375rem", fontWeight: "400" }}
                      >
                        {" "}
                        {challenge?.user?.levelofstudy} of{" "}
                        {challenge?.user?.study}
                      </p>
                    </td>
                    <td className="tbodytrtd ">
                      <div className="countryholder center">
                        {challenge?.user?.uni?.area}
                      </div>
                    </td>
                    <td className="tbodytrtd ">
                      {checkChallenges(challenge?.user)}
                    </td>
                    <td className="textofthecount tbodytrtd ">
                      <div className="center" style={{}}>
                        <div style={{ position: "absolute" }}>
                          {showIcon && Index === index && (
                            <div
                              style={{
                                position: "absolute",
                                background: "transparent",
                                zIndex: 10,

                                marginLeft: "-140px",
                              }}
                              ref={iconRef}
                            >
                              <div
                                className="d-flex shadowa"
                                style={{
                                  flexDirection: "column",
                                  background: "transparent",
                                }}
                              >
                                <button
                                  className="d-flex popuptext"
                                  style={{
                                    padding: "11px 1rem",
                                    maxHeight: "2rem",
                                    borderRadius: "5px 5px 0px 0px",
                                    gap: "10px",
                                    background: "#FFF",
                                    border: "none",
                                    width: "160px",
                                    minHeight: "42px",
                                  }}
                                  onClick={() => {
                                    setShowIcon(false);

                                    navigate(
                                      `/dashboard/chat?id=${challenge?.user?.uniqueId}`
                                    );

                                    setIndex(-1);
                                  }}
                                >
                                  Message
                                </button>
                                {TRUE && (
                                  <button
                                    className="d-flex popuptext"
                                    style={{
                                      padding: "11px 1rem",
                                      maxHeight: "2rem",
                                      borderRadius: "0px 0px 5px 5px",
                                      gap: "10px",
                                      background: "#FFF",
                                      border: "none",
                                      width: "160px",
                                      minHeight: "42px",
                                      borderTop: "1px solid #d9d9d9",
                                    }}
                                    onClick={() => {
                                      setShowIcon(false);
                                      setIndex(-1);

                                      handleShortList(challenge?.user?._id);
                                    }}
                                  >
                                    Short List
                                  </button>
                                )}
                                <button
                                  className="d-flex popuptext"
                                  style={{
                                    padding: "11px 1rem",
                                    maxHeight: "2rem",
                                    borderRadius: "0px 0px 5px 5px",
                                    gap: "10px",
                                    background: "#FFF",
                                    border: "none",
                                    width: "160px",
                                    minHeight: "42px",
                                    borderTop: "1px solid #d9d9d9",
                                  }}
                                  onClick={() => {
                                    setShowIcon(false);
                                    setIndex(-1);
                                    if (TRUE) {
                                      handleRemove(challenge?.user?._id);
                                    } else {
                                      handleRemoveShortlist(
                                        challenge?.user?._id
                                      );
                                    }
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          )}
                        </div>

                        <MoreVertIcon
                          className="hoverred"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIndex(index);
                            setShowIcon(true);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {currentEntries.length > 0 && currentEntries.length < 5 && (
              <>
                {Array.from({ length: 5 - currentEntries.length }).map(
                  (_, index) => (
                    <tr
                      key={index}
                      className="tbodytr"
                      style={{ border: "none" }}
                    >
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                    </tr>
                  )
                )}
              </>
            )}
          </tbody>
        </table>

        {currentEntries.length === 0 && (
          <div
            className="center col-md-12 col-12"
            style={{
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "400px",
              background: "#FFF",
            }}
          >
            <img
              src={EmptySpace}
              alt="Emptyspace"
              height="225px"
              width="180px"
              className="img-fluid"
            />
            <p className="pink16">No Data yet</p>
            <p className="black14" style={{ fontWeight: "500" }}>
              <span className="black14">Please come back later.</span> The first
              students will engage with your company in no time!
            </p>
          </div>
        )}
      </div>
      <div
        className="d-flex"
        style={{ marginTop: "1rem", justifyContent: "end" }}
      >
        <div
          className="d-flex"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          {renderPageButtons()}
          {indexOfLastEntry < challenge.length && (
            <button
              onClick={handleNextPage}
              disabled={indexOfLastEntry >= challenge.length}
              className="backbutton"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultTable;
