import axios from "axios";
import { useEffect, useState } from "react";
import CompanyImage from "../../components/Company/CompanyImage";
import EventTypes from "../NewPages/Events/EventsTypes";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GetTimeRange from "../StudentEventFlow/getTimeRange";
import { HiMiniUsers } from "react-icons/hi2";
import GetDayOfWeek from "../StudentEventFlow/GetDayOfWeek";
import GetDayOfMonth from "../StudentEventFlow/getDayOfMonth";

const UpcomingEvents = ({ setEventEmpty }) => {
  const [upcoming, setUpcoming] = useState([]);

  const [loading, setLoading] = useState(false);

  const [todaysevent, setTodaysEvent] = useState(null);

  const SortEvents = (evt) => {
    const now = new Date();
    const todayDate = now.toLocaleDateString("en-CA"); // Extract just the date portion in UTC

    // Filter events happening today by comparing only the date part
    const todayEvents = evt.filter((event) => {
      const eventDate = new Date(event.datetime).toLocaleDateString("en-CA");

      return eventDate === todayDate;
    });

    if (todayEvents.length > 0) {
      // Sort today's events by time (ascending order)
      todayEvents.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));

      // Find the closest upcoming event (or the last event if none are upcoming)
      const closestUpcomingEvent = todayEvents.find(
        (event) => new Date(event.datetime) >= now
      );

      // If there's an upcoming event, set it; otherwise, set the last event today
      if (closestUpcomingEvent) {
        setTodaysEvent(closestUpcomingEvent);
      } else {
        setTodaysEvent(todayEvents[todayEvents.length - 1]);
      }
    } else {
      setTodaysEvent(null); // No events today
    }
  };

  const fetchEvents = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/get/student/upcoming/events`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.msg === "success") {
          setUpcoming(res.data.event);
          SortEvents(res.data.event);

          setEventEmpty(res.data.event.length > 0 ? false : true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const isWithinNextThreeDays = (eventDatetime) => {
    const eventDate = new Date(eventDatetime);
    const now = new Date();

    // Get the difference in time
    const timeDiff = eventDate.getTime() - now.getTime();

    // Convert time difference from milliseconds to days
    const dayDiff = timeDiff / (1000 * 3600 * 24);

    return dayDiff >= 0 && dayDiff <= 3;
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  return (
    <div style={{ background: "#FFF", padding: "1rem", borderRadius: "5px" }}>
      {todaysevent ? (
        <div>
          <p className="gray95016500">Today's Event</p>
          <div style={{ marginTop: "1.5rem" }}>
            <CompanyImage src={JSON.parse(todaysevent?.company)?.img} />
          </div>
          <p style={{ marginTop: "1rem" }} className="gray95016400">
            {todaysevent?.title}
          </p>
          <div className="d-flex mt-2">
            <EventTypes
              noback={true}
              type={
                todaysevent?.type === "varyance Livestream"
                  ? "Live Stream"
                  : todaysevent?.type === "Job Schedule"
                  ? "Job Closings"
                  : todaysevent.type
              }
            />
          </div>
          <div className="d-flex flex-wrap align-items-center gap-3 mt-3">
            <div className="d-flex gap-2 align-items-center">
              <AccessTimeIcon style={{ height: "20px", width: "20px" }} />
              <p className="headersmalltext">
                <GetTimeRange
                  datetime={todaysevent?.datetime}
                  duration={todaysevent?.duration}
                />
              </p>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <HiMiniUsers height={20} width={20} />
              <p className="headersmalltext">
                {todaysevent?.participants?.length}
              </p>
            </div>
          </div>
          <a
            style={{ textDecoration: "none" }}
            href={`/student/event/${todaysevent?.link}`}
            target="_blank"
          >
            {" "}
            <button className="continuebutton mt-3" style={{ width: "100%" }}>
              Attend
            </button>
          </a>
        </div>
      ) : (
        <div>
          <p className="gray95016500">Your Upcoming Events</p>
          {upcoming.splice(0, 4).map((event, index) => (
            <div key={index} style={{ marginTop: "1.5rem" }}>
              <div
                className="d-flex"
                style={{
                  gap: "12px",
                  flexDirection: "row",
                }}
              >
                <div
                  className={`${"d-flex flex-column justify-content-center"}`}
                >
                  <p
                    className="upcomingDay text-center"
                    style={{
                      color: isWithinNextThreeDays(event?.datetime)
                        ? "#F97066"
                        : "inherit",
                      fontSize: "0.75rem",
                    }}
                  >
                    <GetDayOfWeek datetime={event?.datetime} />
                  </p>
                  <p
                    className="upcomingDate text-center"
                    style={{
                      color: isWithinNextThreeDays(event?.datetime)
                        ? "#F97066"
                        : "inherit",
                      fontSize: "1.5rem",
                    }}
                  >
                    <GetDayOfMonth datetime={event?.datetime} />
                  </p>
                </div>
                <div className="center align-items-center">
                  <div
                    style={{
                      height: "100%",
                      width: "1px",
                      background: "#D0D5DD",
                    }}
                  />
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ gap: "0.5rem", width: "100%" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      flexDirection: "column",
                    }}
                  >
                    <p className="gray95014400" style={{ fontWeight: 500 }}>
                      {truncateString(event?.title, 17)}
                    </p>

                    {event?.company && (
                      <div className="d-flex gap-2 mt-2 align-items-center">
                        <CompanyImage src={JSON.parse(event.company).img} />
                        <p
                          className="gray70014400"
                          style={{ fontSize: "12px" }}
                        >
                          {truncateString(
                            JSON.parse(event?.company)?.title,
                            17
                          )}
                        </p>
                      </div>
                    )}
                  </div>

                  <a
                    style={{ textDecoration: "none" }}
                    href={`/student/event/${event?.link}`}
                    target="_blank"
                  >
                    <div
                      className="d-flex pink14 hoverdarkred align-items-center"
                      style={{
                        cursor: "pointer",
                        fontWeight: 500,
                      }}
                    >
                      View Details
                    </div>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {loading && (
        <div className="center mt-3">
          <div className="loader" />
        </div>
      )}
    </div>
  );
};

export default UpcomingEvents;
