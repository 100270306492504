import deleteicon from "../../assets/images/warning.png";
import CloseIcon from "@mui/icons-material/Close";

const Report = ({
  reportloading,
  setPopup,
  handleReportPost,
  setText,
  text,
  selectedReportOption,
  setSelectedOption,
  reportOptions,
  isNon384,
  setData,
  setReportPopup,
}) => {
  return (
    <div
      className="center zindex1000"
      style={{
        width: "100%",
        height: "100%",

        position: "fixed",
        alignItems: "center",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 11,
        backgroundColor: "rgba(45, 50, 130, 0.30)",
        backdropFilter: "blur(3px)",
        padding: "1rem",
        zIndex: 9999,
      }}
      onClick={() => {
        setReportPopup(false);
        setData(null);
      }}
    >
      <div
        className=" shadowa newPopUpContainer"
        style={{ width: "600px", overflow: "auto" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="space " style={{ gap: "1rem" }}>
          <div
            className="d-flex flex-wrap align-items-start "
            style={{ gap: "1.5rem" }}
          >
            <div className="circle-background">
              <img src={deleteicon} alt="delete" height="48px" width="48px" />
            </div>
            <div>
              <p className="gray90018500 mt-2">Report</p>
            </div>
          </div>
          <CloseIcon
            className="hoverdarkblacksvg"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setReportPopup(false);
              setData(null);
            }}
          />
        </div>
        <div
          className="d-flex flex-column"
          style={{
            marginTop: "1.75rem",
            marginLeft: isNon384 ? "4rem" : "0rem",
            gap: "1rem",
          }}
        >
          {reportOptions.map((option, index) => (
            <div
              key={index}
              className="d-flex"
              style={{ gap: "0.825rem", cursor: "pointer" }}
              onClick={() => {
                setSelectedOption(option);
              }}
            >
              {selectedReportOption === option ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 18.4166C5.35164 18.4166 1.58337 14.6483 1.58337 9.99992C1.58337 5.35152 5.35164 1.58325 10 1.58325C14.6484 1.58325 18.4167 5.35152 18.4167 9.99992C18.4167 14.6483 14.6484 18.4166 10 18.4166Z"
                    stroke="#E31B54"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M5 10C5 12.7614 7.23858 15 10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10Z"
                    fill="#E31B54"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1063_20955)">
                    <path
                      d="M2.16663 10.0001C2.16663 5.67622 5.6761 2.16675 9.99996 2.16675C14.3238 2.16675 17.8333 5.67622 17.8333 10.0001C17.8333 14.3239 14.3238 17.8334 9.99996 17.8334C5.6761 17.8334 2.16663 14.3239 2.16663 10.0001ZM2.83329 10.0001C2.83329 13.9596 6.04048 17.1667 9.99996 17.1667C13.9594 17.1667 17.1666 13.9596 17.1666 10.0001C17.1666 6.04061 13.9594 2.83341 9.99996 2.83341C6.04048 2.83341 2.83329 6.04061 2.83329 10.0001Z"
                      fill="#667085"
                      stroke="#667085"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1063_20955">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
              <p className="gray60011400" style={{ fontSize: "14px" }}>
                {option}
              </p>
            </div>
          ))}
          {selectedReportOption === "Something Else" && (
            <textarea
              className="newinputs col-md-12 col-12"
              value={text}
              placeholder="Type your reason here"
              onChange={(e) => setText(e.target.value)}
            />
          )}
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "2rem", gap: "0.75rem" }}
        >
          <button
            className="continuebutton"
            onClick={() => {
              handleReportPost();
              setPopup(false);
            }}
            disabled={reportloading}
          >
            Submit Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default Report;
