import { useEffect, useState } from "react";
import TabButtons from "../../../components/TabButtons/TabButtons";
import Search from "../../../components/Search/Search";
import "./main.css";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Explore from "./Explore";
import { useMediaQuery } from "@mui/material";

import MyChallenges from "./myChallenges";
import RecommendChal from "../../SocialFeed/RecommendedChallenges";
import ChallengeType from "../../MainDashBoard/type";
import LearnMoreChallenge from "./LearnMore";
const NewStudentChallenges = ({ student }) => {
  const buttons = [{ name: "Explore" }, { name: "My Challenges" }];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page");
  const [selected, setSelected] = useState(
    page ? buttons[page].name : "Explore"
  );

  useEffect(() => {
    setSelected(page ? buttons[page].name : "Explore");
  }, [page]);
  const [search, setSearch] = useState("");
  const [challenges, setChallenges] = useState([]);

  const [loading, setLoading] = useState(false);
  const [startChallenge, setStartChallenge] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [blocked, setBlocked] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const isNonMobile1330 = useMediaQuery("(min-width:1024px)");
  const isNon1240 = useMediaQuery("(min-width:1240px)");

  const [challengestoShow, setChallengesToShow] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setChallengesToShow(challenges);
    if (search) {
      setChallengesToShow(
        challenges.filter((challenge) =>
          challenge.title.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, challenges]);

  const fetchChallenges = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/getchallenges`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          setChallenges(res.data.challenges);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchMyChallenges = async () => {
    setLoading2(true);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/getMychallenges`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          setStartChallenge(res.data.checkStart);
          setCompleted(res.data.checkComplete);
          setBlocked(res.data.checkBlocked);
        }

        setLoading2(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading2(false);
      });
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      fetchChallenges();
      fetchMyChallenges();
    }
  }, []);

  const [emptyChal, setEmptyChal] = useState(false);

  const [popup, setPopup] = useState(false);

  return (
    <>
      <div
        className="marginsleftrightCompany"
        style={{ paddingTop: "1.875rem" }}
      >
        <div className="center">
          <div className="headerCompany d-flex" style={{ gap: "1.875rem" }}>
            <div style={{ width: isNon1240 ? "calc(100% - 330px)" : "100%" }}>
              <p className="studentHeading">Challenges</p>
              <p
                className="gray60011400"
                style={{ fontSize: "14px", marginTop: "0.25rem" }}
              >
                Master your next challenges and advance skills relevant to your
                future career.
              </p>
              <div
                className="space flex-wrap align-items-center"
                style={{ marginTop: "1.25rem", gap: "1rem" }}
              >
                <TabButtons
                  buttons={buttons}
                  selected={selected}
                  setSelected={setSelected}
                  link={`/student/Challenges`}
                />

                <Search search={search} setSearch={setSearch} />
              </div>
              <div style={{ marginTop: "1.5rem" }}>
                {selected === "Explore" ? (
                  <Explore
                    challenges={challengestoShow}
                    navigate={navigate}
                    loading={loading}
                    isNonMobile1330={isNonMobile1330}
                  />
                ) : (
                  <MyChallenges
                    startChallenge={startChallenge}
                    completed={completed}
                    navigate={navigate}
                    search={search}
                    loading2={loading2}
                    blocked={blocked}
                  />
                )}
              </div>
            </div>
            {popup && <LearnMoreChallenge popup={popup} setPopup={setPopup} />}
            {isNon1240 && (
              <div
                className="d-flex flex-column "
                style={{ gap: "1.5rem", marginTop: "70px" }}
              >
                <div
                  className="shadowa"
                  style={{
                    width: "300px",
                    minWidth: "300px",
                    maxWidth: "300px",
                    background: "#FFF",
                    borderRadius: "8px",
                    padding: "1.25rem",
                  }}
                >
                  <p className="gray95016500" style={{ maxWidth: "150px" }}>
                    Learn more about Challenge types.
                  </p>
                  <div
                    style={{ marginTop: "0.75rem", gap: "10px" }}
                    className="d-flex"
                  >
                    <ChallengeType type={"Fast-Track"} notest={true} />
                    <ChallengeType type={"Virtual Experience"} notest={true} />
                    <ChallengeType type={"Insights"} notest={true} />
                  </div>
                  <p className="gray80014400" style={{ marginTop: "14px" }}>
                    Discover the different{" "}
                    <span className="gray80014400" style={{ fontWeight: 500 }}>
                      challenge types
                    </span>{" "}
                    to find the best fit for your career goals.
                  </p>
                  <button
                    className="continuebutton"
                    style={{ marginTop: "1.25rem", width: "100%" }}
                    onClick={() => setPopup(true)}
                  >
                    Learn More
                  </button>
                </div>
                {!emptyChal && (
                  <RecommendChal
                    emptyChal={emptyChal}
                    setEmptyChal={setEmptyChal}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewStudentChallenges;
