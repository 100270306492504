import PrimaryFooter from "../../layouts/components/Footer/PrimaryFooter";
import Header from "../../components/Company/field/Header";
import Find from "../../components/Company/Find/FindCompany";
const FindCompany = () => {
  return (
    <div className="backgroundimg">
      <div className="marginsleftrightCompany">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <Header />
          </div>
        </div>
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <Find />
          </div>
        </div>
      </div>
      <div className="marginsleftrightCompany">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <PrimaryFooter />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FindCompany;
