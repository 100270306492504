import Slider from "react-slick";
import NonMobile from "../../components/NonMobile/NonMobile";

import user from "../../assets/images/user.png";
import medal from "../../assets/images/icons/Medal.png";
import rocket from "../../assets/images/icons/rocket.png";
import letter from "../../assets/images/icons/letter.png";
import Section2Component from "../../components/CompanyHome/section2component";
const SliderSec = () => {
  const {
    isNonMobile768,
    isNonMobile1024,
    isNonMobile500,
    isNonMobile1271,
    isNonMobile1250,
    isNonMobile1190,
    isNonMobile1100,
  } = NonMobile();

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: isNonMobile1271
      ? "15%"
      : isNonMobile1250
      ? "11%"
      : isNonMobile1190
      ? "9%"
      : isNonMobile1100
      ? "5%"
      : "0%",
  };

  const slides = [
    {
      class: "studentslidercard1",
      title: (
        <>Leave generic {!isNonMobile1024 && <br />} job descriptions behind</>
      ),
      desc: "Tired of endless job boards? Instead, jump into real-life tasks. Challenges go beyond the job description, giving you a true sense of the role and the skills you'll need.",
      icon: user,
    },
    {
      class: "studentslidercard2",
      icon: medal,
      title: (
        <>
          Real equity - {!isNonMobile1024 && <br />} through real opportunities
        </>
      ),
      desc: "No matter who you are, where you stand or what you study - challenges let you prove yourself and stand out. Talent is everywhere - here's your chance to show it.",
    },
    {
      class: "studentslidercard3",
      icon: rocket,
      title: (
        <>Boost your CV {!isNonMobile1024 && <br />} with some real data.</>
      ),
      desc: "Your resumes only tell half the story. Forget generic bullet points.  Challenges give you real data and hands-on experience to make your CV stand out.",
    },
    {
      class: "studentslidercard4",
      icon: letter,
      title: (
        <>Skip the standard {!isNonMobile1024 && <br />} application process</>
      ),
      desc: "No time for endless applications and waiting games? Earn skill points that prove your expertise. Go beyond CVs and meaningless cover letters—show what you're truly capable of.",
    },
  ];

  return (
    <div className="newHomeSlider-container overflowshown">
      <div className="marginsleftrightCompany marginsStudent">
        <div className="center">
          <div className="headerCompany">
            <div style={{ padding: isNonMobile500 ? "0rem" : "0rem 1.25rem" }}>
              <div className="d-flex">
                <p className="mt-0 itsearly">Stand out</p>
              </div>
              <p
                className="gray80016500"
                style={{
                  marginTop: "1.5rem",
                  fontWeight: 400,
                  maxWidth: "668px",
                }}
              >
                With varyance, you skip the fluff and get straight to real
                insights. Dive into authentic challenges designed by
                top-employers. Experience actual work scenarios and gain skills
                that truly prepare you for your career. No empty words - just
                practical experience to get you ready for what’s next.
              </p>
            </div>
            <div style={{ marginTop: "3.75rem", position: "relative" }}>
              {isNonMobile1024 ? (
                <Slider {...settings} className="dsadasasd ">
                  {slides.map((slide, index) => (
                    <div key={index} className="d-flex " style={{}}>
                      <div
                        className={`${slide.class} img-fluid `}
                        style={{ position: "relative" }}
                      >
                        <div
                          className="space flex-column gap-4"
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: isNonMobile1024 ? "2rem" : "1.25rem",
                          }}
                        >
                          <div className="d-flex">
                            {slide?.icon && (
                              <div
                                className="center align-items-center"
                                style={{
                                  background: "#F2F4F7",
                                  borderRadius: "50%",
                                  padding: "10px",
                                }}
                              >
                                <img
                                  src={slide.icon}
                                  alt={`${index}-slide-icon`}
                                  style={{ height: "20px", width: "20px" }}
                                />
                              </div>
                            )}
                          </div>
                          <div>
                            <p
                              className={`  ${
                                index === 1 ? "slidesheading2" : "slidesheading"
                              }`}
                              style={{
                                maxWidth: "304px",
                                color: index === 2 ? "#FFF" : "",
                              }}
                            >
                              {slide.title}
                            </p>
                            <p
                              className={`${
                                isNonMobile768 ? "gray95016400" : "gray95014400"
                              }`}
                              style={{
                                marginTop: "1rem",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                maxWidth: "484px",
                                color: index === 2 ? "#FFF" : "",
                              }}
                            >
                              {slide.desc}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "1rem" }}></div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <Section2Component
                  slides={slides}
                  noimages={true}
                  isNonMobile500={isNonMobile500}
                  maxHeight={"400px"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderSec;
