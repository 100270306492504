// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.white24400 {
  color: var(--White, #fff);

  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}
.white14400 {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.gray5016400 {
  color: var(--Secondary-Color-Gray-50, #f9fafb);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.gray30014400 {
  color: var(--Secondary-Color-Gray-300, #d0d5dd);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;;EAEzB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC;AACA;EACE,WAAW;EACX,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC;AACA;EACE,8CAA8C;EAC9C,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;AACA;EACE,+CAA+C;EAC/C,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC","sourcesContent":[".white24400 {\n  color: var(--White, #fff);\n\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 32px; /* 133.333% */\n}\n.white14400 {\n  color: #fff;\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px; /* 157.143% */\n}\n.gray5016400 {\n  color: var(--Secondary-Color-Gray-50, #f9fafb);\n  font-family: Poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 150% */\n}\n.gray30014400 {\n  color: var(--Secondary-Color-Gray-300, #d0d5dd);\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 171.429% */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
