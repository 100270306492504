import StudentShortProfile from "./StudentShortProfile";

const RequestList = ({
  requests,
  loadMoreRequests,
  hasMore,
  handleConnect,
  loading,
  loadingReq,
}) => {
  return (
    <div>
      <div
        className="d-flex flex-column "
        style={{
          gap: "1.25rem",
        }}
      >
        {loadingReq ? (
          <div className="center">
            <div className="loader" />
          </div>
        ) : requests.length > 0 ? (
          requests?.map((req, index) => (
            <div
              className="space gap-3 flex-wrap align-items-center"
              key={index}
              style={{
                paddingBottom: "1.25rem",
                borderBottom:
                  index !== requests.length - 1 ? "1px solid #EAECF0" : "",
              }}
            >
              <div>
                <StudentShortProfile student={req} />
              </div>
              <div className="d-flex " style={{ gap: "1.25rem" }}>
                <button
                  className="backbutton"
                  style={{
                    width: "120px",
                    padding: "8px 20px",
                    maxHeight: "40px",
                  }}
                  onClick={() => handleConnect("ignorereq", req)}
                  disabled={loading}
                >
                  Ignore
                </button>
                <button
                  className="continuebutton"
                  style={{
                    background: "none",
                    color: "#E31b54",
                    border: "1px solid #E31b54",
                    width: "120px",
                    padding: "8px 20px",
                    maxHeight: "40px",
                  }}
                  onClick={() => handleConnect("acceptreq", req)}
                  disabled={loading}
                >
                  Accept
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center black14">No Requests atm.</p>
        )}
      </div>
      {hasMore && (
        <p
          className="pink14 mt-2"
          onClick={() => {
            loadMoreRequests();
          }}
        >
          See more
        </p>
      )}
    </div>
  );
};
export default RequestList;
