import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const TestList = ({
  setAddTestscore,
  loading,
  testscore,
  setNewTestscore,
  setIndex,
  handleUpdate,
  setTestscore,
}) => {
  return (
    <div>
      {testscore?.map((test, index) => (
        <div key={index}>
          <div className="d-flex justify-content-end" style={{ gap: "1rem" }}>
            <EditIcon
              className="hoverred"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIndex(index);
                setNewTestscore(test);
                setAddTestscore(true);
              }}
            />
            <DeleteIcon
              className="hoverred"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setTestscore(testscore.filter((_, i) => i !== index));
              }}
            />
          </div>
          <p className="gray95016500">{test?.title}</p>
          <div className="d-flex flex-wrap gap-2 mt-2 align-items-center">
            <p className="a314400">Score {test?.score}</p>
            <div
              className=""
              style={{
                background: "#667085",
                height: "4px",
                width: "4px",
                borderRadius: "125px",
              }}
            />
            <p className="a314400">{test?.date}</p>
          </div>
        </div>
      ))}
      <div className="mt-3">
        <div className="d-flex">
          <p
            className=" text-start backbutton hoverred mt-3"
            onClick={() => setAddTestscore(true)}
            style={{ cursor: "pointer" }}
          >
            Add New
          </p>
        </div>
        <hr className="mt-5" />
        <button
          className="continuebutton mt-2"
          onClick={() => handleUpdate()}
          disabled={loading}
        >
          Update Test Scores
        </button>
      </div>
    </div>
  );
};
export default TestList;
