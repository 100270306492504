import InfiniteScroll from "react-infinite-scroll-component";
import PostContainer from "./PostContainer";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick/lib/slider";
import CloseIcon from "@mui/icons-material/Close";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import MediaType from "./MediaType";
import { useMediaQuery } from "@mui/material";
import PostingTab from "./PostingTab";
const AllPost = ({
  posts,
  fetchMorePosts,
  hasMore,
  user,
  setPosts,
  isNonMobile450,
  isNonMobile1024,
  SinglePost = false,
}) => {
  const [selected, setSelectedPost] = useState(null);
  const isNon1024 = useMediaQuery("(min-width:1024px)");
  const videoRefs = useRef([]);
  const isNon850 = useMediaQuery("(min-width:850px)");
  const isNon414 = useMediaQuery("(min-width:414px)");
  const isNon500 = useMediaQuery("(min-width:500px)");
  const isNon384 = useMediaQuery("(min-width:384px)");
  const [edit, setEdit] = useState(null);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="p-2 "
        style={{
          ...style,
          left: "0",
          zIndex: 1,
          position: "absolute",
          top: "50%",
          left: "0%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#555",
          borderRadius: "100px",
          border: "1.5px solid var(--Gray-800, #1D2939)",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={24} color="#FFF" />
      </div>
    );
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className=" p-2 r "
        style={{
          ...style,
          right: "0",
          zIndex: 1,
          position: "absolute",
          direction: "rtl",
          top: "50%",
          right: "0%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#555",
          borderRadius: "100px",
          border: "1.5px solid var(--Gray-800, #1D2939)",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={24} color="#FFF" />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    onload: (currentIndex) => {
      // Play the video on the active slide
      if (videoRefs.current[currentIndex]) {
        videoRefs.current[currentIndex].play();
      }
    },
    beforeChange: (oldIndex, newIndex) => {
      // Pause the video on the old index
      if (videoRefs.current[oldIndex]) {
        videoRefs.current[oldIndex].pause();
      }
    },
    afterChange: (currentIndex) => {
      // Play the video on the active slide
      if (videoRefs.current[currentIndex]) {
        videoRefs.current[currentIndex].play();
      }
    },
  };

  useEffect(() => {
    if (selected) {
      // Play the video on the first slide when the component loads
      if (videoRefs.current[0]) {
        videoRefs.current[0].play();
      }
    }
  }, [selected]);

  const getTimeElapsed = (createdAt) => {
    const currentDate = new Date();
    const creationDate = new Date(createdAt);

    const timeDifference = currentDate - creationDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return `${years}yr ago`;
    } else if (months > 0) {
      return `${months}mon ago`;
    } else if (days > 0) {
      return `${days}d ago`;
    } else if (hours > 0) {
      return `${hours}h ago`;
    } else if (minutes > 0) {
      return `${minutes} min ago`;
    } else {
      return `${seconds} sec ago`;
    }
  };

  const [popup, setPopup] = useState(false);

  return (
    <div>
      {edit && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.2)", // Darkened background a bit for better visibility
            zIndex: 1012, // Ensure it stays on top of all other content
          }}
          onClick={() => {
            setPopup(true);
          }}
        >
          <div
            style={{
              minWidth: isNon1024 ? "50vw" : isNon500 ? "84vw" : "100vw",
              maxWidth: isNon850 ? "90vw" : "100vw",
              maxHeight: "90vh",
              overflowY: "auto",
              borderRadius: "5px",
              background: "#FFF",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="space gap-2" style={{ margin: "1.25rem" }}>
              <p className="postUserName">Edit Post</p>
              <CloseIcon
                className="hoverdarkblacksvg"
                style={{ cursor: "pointer" }}
                onClick={() => setPopup(true)}
              />
            </div>

            <PostingTab
              photo={user?.profilePhoto}
              isNonMobile450={isNonMobile450}
              isNonMobile1024={isNonMobile1024}
              setPosts={setPosts}
              post={edit}
              setEdit={setEdit}
            />
          </div>
        </div>
      )}

      {popup && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.6)", // Darkened background a bit for better visibility
            zIndex: 1012, // Ensure it stays on top of all other content
          }}
        >
          <div
            style={{
              minWidth: "40vw",
              maxWidth: isNon850 ? "80vw" : "90vw",
              maxHeight: "90vh",
              overflowY: "auto",
              borderRadius: "5px",
              background: "#FFF",
              padding: "1.5rem",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="space gap-3">
              <div>
                <p className="gray90018500">Unsaved Changes</p>
                <p className="mt-1 gray70014400">
                  Changes you made will not be saved.
                </p>
              </div>
              <CloseIcon
                className="hoverdarkblacksvg"
                style={{ cursor: "pointer" }}
                onClick={() => setPopup(false)}
              />
            </div>
            <div className="mt-4 d-flex flex-wrap justify-content-between justify-content-md-end gap-3">
              <button
                className="backbutton"
                onClick={() => setPopup(false)}
                style={{ width: isNon414 ? "" : "100%" }}
              >
                Keep Editing
              </button>
              <button
                className="continuebutton"
                onClick={() => {
                  setEdit(null);
                  setPopup(false);
                }}
                style={{ width: isNon414 ? "" : "100%" }}
              >
                Discard Changes
              </button>
            </div>
          </div>
        </div>
      )}

      {selected && (
        <div
          className="d-flex   justify-content-center align-items-center"
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.6)", // Darkened background a bit for better visibility
            zIndex: 9999, // Ensure it stays on top of all other content
          }}
          onClick={() => {
            setSelectedPost(null);
          }}
        >
          <div className="marginsleftrightCompany marginsStudent">
            <div className="center">
              <div className="headerCompany">
                <div
                  className="d-flex PostPopup-view"
                  style={{
                    minHeight: "70vh",
                    maxHeight: "70vh",
                    width: "100%",
                    flexDirection: isNon850 ? "row" : "column",
                    position: "relative",
                    // Background to make it more readable
                    overflow: "hidden", // Prevent overflow of content
                    borderRadius: "8px", // Rounded corners
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div
                    className="center align-items-center"
                    style={{
                      position: "absolute",
                      top: "20px",
                      left: isNon850 ? "20px" : "",
                      right: isNon850 ? "" : "20px",
                      background: "#787E85",
                      borderRadius: "50%",
                      height: "30px",
                      width: "30px",
                      zIndex: 9999,
                    }}
                  >
                    <CloseIcon
                      className="threedot"
                      style={{ cursor: "pointer", color: "#FFF" }}
                      onClick={() => setSelectedPost(null)}
                    />
                  </div>
                  <div
                    className="custom-sliderdiv"
                    style={{
                      position: "relative",
                      maxWidth: isNon850 ? "calc(70vw * 0.815)" : "100vw",
                      width: isNon850 ? "61.5%" : "100vw",
                      height: isNon850 ? "100%" : "50%", // Ensure slider takes full height of its container
                      maxHeight: isNon850 ? "70vh" : "50%",
                    }}
                  >
                    <Slider {...settings} style={{}}>
                      {selected.post.media.map((media, index) => (
                        <div
                          key={index}
                          className="d-flex justify-content-center align-items-center testingpopuppost"
                          style={{
                            width: "100%",
                            background: "black",
                            maxHeight: "70vh",
                          }}
                        >
                          <MediaType
                            media={media}
                            fullView={true}
                            isNon850={isNon850}
                            videoRef={(ref) => (videoRefs.current[index] = ref)}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>

                  <div
                    className="tablescroll"
                    style={{
                      width: isNon850 ? "36%" : "100vw",
                      overflowY: "auto",
                      position: "relative",

                      height: isNon850 ? "calc(70vh)" : "50%",
                    }}
                  >
                    <div>
                      <div style={{ marginTop: "0rem" }}>
                        <PostContainer
                          post={selected.post}
                          student={user}
                          setSelectedPost={setSelectedPost}
                          selected={selected}
                          getTimeElapsed={getTimeElapsed}
                          setEdit={setEdit}
                          noMedia={true}
                          setPosts={setPosts}
                          isNon384={isNon384}
                          isNon850={isNon850}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <InfiniteScroll
        dataLength={posts.length}
        next={fetchMorePosts}
        hasMore={hasMore}
        loader={<p className="black16 text-center">Loading...</p>}
        endMessage={
          <p className="black16 text-center">
            {SinglePost ? "" : "No more posts to show"}
          </p>
        }
      >
        {posts.length > 0 &&
          posts.map((post, index) => (
            <div key={index} style={{ marginBottom: "1.5rem" }}>
              <PostContainer
                post={post}
                student={user}
                setSelectedPost={setSelectedPost}
                selected={selected}
                setEdit={setEdit}
                getTimeElapsed={getTimeElapsed}
                setPosts={setPosts}
                isNon384={isNon384}
              />
            </div>
          ))}
      </InfiniteScroll>
    </div>
  );
};

export default AllPost;
