import UniSelect from "./UniSelect";
import img from "../../assets/images/newhomecenter.png";
const First = ({ setSelectedUni, selectedUni }) => {
  return (
    <div
      className="dummyherocontainer"
      style={{
        position: "relative",

        paddingTop: "10.25rem",
      }}
    >
      <div className="marginsleftrightCompany">
        <div className="center">
          <div className="headerCompany" style={{ position: "relative" }}>
            <div className="center">
              <p
                className="text-center"
                style={{
                  maxWidth: "411px",
                  color: "var(--Secondary-Color-Gray-950, #0C111D)",
                  textAlign: "center",

                  fontSize: "36px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "44px",
                }}
              >
                Get real insights into your{" "}
                <span
                  style={{
                    color: "#E31b54",
                    textAlign: "center",

                    fontSize: "36px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "44px",
                  }}
                >
                  future career
                </span>
              </p>
            </div>
            <div className="center" style={{ marginTop: "28px" }}>
              <p
                className="gray95016500 text-center"
                style={{
                  maxWidth: "623px",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                Take-on{" "}
                <span
                  className="gray95016500"
                  style={{ fontSize: "18px", fontWeight: 500 }}
                >
                  virtual challenges
                </span>{" "}
                designed by investment banks, big law, consulting firms,
                founders & other industry leaders.
              </p>
            </div>
            <div className="center mt-3">
              <UniSelect
                setSelectedUni={setSelectedUni}
                selectedUni={selectedUni}
              />
            </div>
            <div className="center" style={{ marginTop: "3.75rem" }}>
              <img
                src={img}
                alt="sample"
                style={{ maxWidth: "800px", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
