import { useState } from "react";

import First from "./First";
import Second from "./Second";
import "./Main.css";
import Third from "./Third";
import { useMediaQuery } from "@mui/material";
import Fourth from "./Fourth";
import Fifth from "./Fifth";
import { default as Onboard } from "../Home/Fifth";
import HomeFooter from "../../../components/Home/HomeFooter";
import { useNavigate } from "react-router-dom";
import NewComp from "../../Home/NewComp";
const Toolkit = () => {
  const isNon = useMediaQuery("(min-width:768px)");
  const navigate = useNavigate();
  return (
    <NewComp>
      <div>
        <div className="university-first ">
          <First navigate={navigate} />
        </div>
        <Second isNon={isNon} />
        <div
          className="overflowshown"
          style={{
            padding: "5rem 0rem 0rem 0rem",
            margin: "5rem 0rem 0rem 0rem",
          }}
        >
          <Third navigate={navigate} />
        </div>
        <div className=" ">
          <Fourth navigate={navigate} />
        </div>
        <div className=" ">
          <Fifth navigate={navigate} />
        </div>
        <div
          style={{
            paddingTop: "3.75rem",
            background: "rgba(242, 243, 247, 0.80)",
          }}
        >
          <Onboard text={"Onboard your institution"} />
        </div>
        <div className="" style={{ background: "#0C111D" }}>
          <div className="marginsleftrightCompany  pt-5">
            {/* <FourthSection /> */}
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <div className="headerCompany">
                <HomeFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewComp>
  );
};
export default Toolkit;
