import { useEffect, useState } from "react";
import Comment from "./Comments";

const Replies = ({
  replies,
  getTimeElapsed,
  student,
  post,
  token,
  ID,
  setCommentsToShow,
  setId,
}) => {
  const [show, setShow] = useState(false);
  const [rreplies, setReplies] = useState([]);

  useEffect(() => {
    if (replies.replies && replies.replies.length !== rreplies.length) {
      setReplies(replies.replies);
    }
  }, [replies]);

  return (
    <div>
      {!show ? (
        <p
          className="gray50012500 hoverred"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setShow(!show);
          }}
        >
          Show {rreplies?.length} replies
        </p>
      ) : (
        <>
          {rreplies && (
            <div className="d-flex flex-column" style={{ gap: "1.25rem" }}>
              {rreplies.map((comment, index) => (
                <div key={index}>
                  <Comment
                    comment={comment}
                    getTimeElapsed={getTimeElapsed}
                    student={student}
                    token={token}
                    post={post}
                    ID={ID}
                    setComments={setReplies}
                    REPLY={true}
                    setId={setId}
                    setCommentsToShow={setCommentsToShow}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Replies;
