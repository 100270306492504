const UnSelectedCircle = () => {
  return (
    <div
      className="center align-items-center"
      style={{ width: "20px", height: "20px" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <mask
          id="mask0_2604_12896"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2604_12896)">
          <path
            d="M10.0008 18.3327C8.84803 18.3327 7.7647 18.1139 6.75081 17.6764C5.73692 17.2389 4.85498 16.6452 4.10498 15.8952C3.35498 15.1452 2.76123 14.2632 2.32373 13.2493C1.88623 12.2355 1.66748 11.1521 1.66748 9.99935C1.66748 8.84657 1.88623 7.76324 2.32373 6.74935C2.76123 5.73546 3.35498 4.85352 4.10498 4.10352C4.85498 3.35352 5.73692 2.75977 6.75081 2.32227C7.7647 1.88477 8.84803 1.66602 10.0008 1.66602C11.1536 1.66602 12.2369 1.88477 13.2508 2.32227C14.2647 2.75977 15.1466 3.35352 15.8966 4.10352C16.6466 4.85352 17.2404 5.73546 17.6779 6.74935C18.1154 7.76324 18.3341 8.84657 18.3341 9.99935C18.3341 11.1521 18.1154 12.2355 17.6779 13.2493C17.2404 14.2632 16.6466 15.1452 15.8966 15.8952C15.1466 16.6452 14.2647 17.2389 13.2508 17.6764C12.2369 18.1139 11.1536 18.3327 10.0008 18.3327ZM10.0008 16.666C11.8619 16.666 13.4383 16.0202 14.73 14.7285C16.0216 13.4368 16.6675 11.8605 16.6675 9.99935C16.6675 8.13824 16.0216 6.56185 14.73 5.27018C13.4383 3.97852 11.8619 3.33268 10.0008 3.33268C8.1397 3.33268 6.56331 3.97852 5.27165 5.27018C3.97998 6.56185 3.33415 8.13824 3.33415 9.99935C3.33415 11.8605 3.97998 13.4368 5.27165 14.7285C6.56331 16.0202 8.1397 16.666 10.0008 16.666Z"
            fill="#344054"
          />
        </g>
      </svg>
    </div>
  );
};

export default UnSelectedCircle;
