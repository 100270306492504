import Guideline from "../../components/Guideline/Body";
import Header from "../../components/Role/component/Header";

const Guide = () => {
  return (
    <div>
      <div
        className="createcompany d-flex "
        style={{
          position: "relative",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <Header />
          </div>
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany mt-5 pt-5">
              <Guideline />
            </div>
          </div>
        </div>
        <div className="marginsleftrightCompany">
          <div className="d-flex" style={{ justifyContent: "center" }}></div>{" "}
        </div>
      </div>
    </div>
  );
};
export default Guide;
