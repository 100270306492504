import "./jobcard.css";

import { useState } from "react";

const JobCard = ({ job, selectedJob }) => {
  const [company, setCompany] = useState(() => {
    try {
      return job?.company ? JSON.parse(job.company) : null;
    } catch (error) {
      console.error("Failed to parse company data:", error);
      return null;
    }
  });

  const getTime = (dateString) => {
    if (!dateString) return "Unknown time";

    const date = new Date(dateString);
    if (isNaN(date)) return "Invalid date";

    const now = new Date();
    const diffInMs = now - date;
    const diffInSec = Math.floor(diffInMs / 1000);
    const diffInMin = Math.floor(diffInSec / 60);
    const diffInHours = Math.floor(diffInMin / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInMonths / 12);

    if (diffInYears > 0) return `${diffInYears}yr ago`;
    if (diffInMonths > 0) return `${diffInMonths}mon ago`;
    if (diffInDays > 0) return `${diffInDays}d ago`;
    if (diffInHours > 0) return `${diffInHours}h ago`;
    if (diffInMin > 0) return `${diffInMin}min${diffInMin > 1 ? "s" : ""} ago`;
    if (diffInSec > 0) return `${diffInSec}sec ago`;

    return "just now";
  };

  return (
    <div
      className={`jobcard ${
        selectedJob && selectedJob?._id === job?._id ? "shadowa" : ""
      }`}
    >
      <div className="d-flex " style={{ gap: "0.75rem" }}>
        {company?.img && (
          <div
            style={{
              padding: "2px",
              alignItems: "center",
              height: "36px",
              width: "36px",
              borderRadius: "5px",
            }}
            className="shadowa center"
          >
            <img
              className="jobcard__logo"
              src={company?.img}
              height="32px"
              width="32px"
            />
          </div>
        )}
        <div>
          <p
            className="black16 text-start"
            style={{
              color: selectedJob
                ? job._id === selectedJob?._id
                  ? "#E31b54"
                  : ""
                : "",
            }}
          >
            {job.title}
          </p>
          <p className="resultintaketable" style={{ marginTop: "0.5rem" }}>
            {getTime(job?.createdAt)}{" "}
            {company?.title && (
              <>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="5"
                  viewBox="0 0 4 5"
                  fill="none"
                  style={{ marginLeft: "6px", marginRight: "6px" }}
                >
                  <circle cx="2" cy="2.06268" r="2" fill="#D9D9D9" />
                </svg>{" "}
                {company?.title}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
export default JobCard;
