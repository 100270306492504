import AllPost from "../../SocialFeed/AllPosts";
import PostingTab from "../../SocialFeed/PostingTab";
import EmptySpace from "../../../assets/images/EmptyState.png";

const Activity = ({
  group,
  student,
  isNon450,
  isNon1024,
  posts,
  setPosts,
  hasMore,
  isMember,
  fetchMorePosts,
}) => {
  return (
    <div>
      {isMember(student?._id) && (
        <div style={{ marginBottom: "1.5rem" }}>
          <PostingTab
            photo={student?.profilePhoto}
            isNonMobile450={isNon450}
            isNonMobile1024={isNon1024}
            hasMore={hasMore}
            setPosts={setPosts}
            groupID={group?._id}
          />
        </div>
      )}
      <div>
        {posts && posts.length > 0 ? (
          <AllPost
            posts={posts}
            fetchMorePosts={fetchMorePosts}
            hasMore={hasMore}
            isNonMobile450={isNon450}
            isNonMobile1024={isNon1024}
            user={student}
            setPosts={setPosts}
          />
        ) : (
          <div
            style={{ background: "#FFF", borderRadius: "5px", padding: "2rem" }}
          >
            <div className="center">
              <img
                src={EmptySpace}
                alt="Emptyspace"
                style={{ maxWidth: "200px" }}
                className="img-fluid"
              />
            </div>
            <p className="pink14 text-center ">No Posts Yet</p>
            <p
              className="pb-5 mt-3 text-center gray60011400"
              style={{ fontSize: "14px" }}
            >
              <span
                className="gray60011400"
                style={{ fontWeight: 500, fontSize: "14px" }}
              >
                {isMember(student?._id)
                  ? "Create your first Post."
                  : "Join the group to create your first Post."}
              </span>{" "}
              It's for sure will increase engagement with students.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default Activity;
