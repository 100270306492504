import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const ScrollToTop = ({ student, setStudent }) => {
  const location = useLocation();
  const path = location.pathname.toLowerCase();

  const UserLogin = localStorage.getItem("userId");
  const navigate = useNavigate();
  const fetchStudent = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/getuser`,
        {
          uniqueId: UserLogin, // Send the uniqueId of the user
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Attach the token in the headers
          },
        }
      );

      if (response.data.msg === "success") {
        setStudent(response.data.user);
      } else {
        console.log("Error fetching user data:", response.data);
        navigate("/login");
      }
    } catch (err) {
      console.error(
        "Error fetching user:",
        err.response ? err.response.data : err.message
      );
      navigate("/login");
    }
  };

  useEffect(() => {
    if (UserLogin) {
      if (path.includes("/student") || path.includes("/challenge/")) {
        fetchStudent();
      }
    }
  }, [UserLogin, path]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

export default ScrollToTop;
