import { useEffect, useState } from "react";
import PostingTab from "../SocialFeed/PostingTab";
import AllPost from "../SocialFeed/AllPosts";
import axios from "axios";

const Activity = ({ user, watcher, isNonMobile1024, isNonMobile450 }) => {
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchPosts = async () => {
    if (loading || !localStorage.getItem("token") || !hasMore) return;
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/get/posts/specific`,
        { page, limit: 15, userID: user?._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.msg === "success") {
        const newPosts = response.data.posts;

        setPosts((prevPosts) => [...prevPosts, ...newPosts]);

        if (newPosts.length < 15) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [page]);

  const fetchMorePosts = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div className="">
      {user?._id === watcher?._id && (
        <PostingTab
          photo={watcher?.profilePhoto}
          isNonMobile450={isNonMobile450}
          isNonMobile1024={isNonMobile1024}
          hasMore={hasMore}
          setPosts={setPosts}
        />
      )}
      {posts ? (
        <div style={{ marginTop: "1.875rem" }}>
          <AllPost
            posts={posts}
            hasMore={hasMore}
            isNonMobile450={isNonMobile450}
            isNonMobile1024={isNonMobile1024}
            user={user}
            setPosts={setPosts}
            fetchMorePosts={fetchMorePosts}
          />
        </div>
      ) : (
        <p className="black14 mt-4 text-center">No Posts</p>
      )}
    </div>
  );
};

export default Activity;
