import ChallengeIcon from "../../assets/images/icons/ChallengeIcon";
import ChatIcon from "../../assets/images/icons/ChatIcon";
import EventIcon from "../../assets/images/icons/EventIcon";
import FeedIcon from "../../assets/images/icons/Feed";
import JobIcon from "../../assets/images/icons/JobsIcon";
import rightimg from "../../assets/images/megamenuright.png";
import MegaIconText from "./MenuIconTest";

const MegaMenu = ({ megaMenu, setMegaMenu, fullview = false, isNon }) => {
  const icon = [
    {
      title: "Events",
      link: "/employer/events",
      icon: (
        <div style={{ minWidth: "20px" }}>
          <EventIcon color={"#FAA24B"} />
        </div>
      ),
      text: "Host events where students engage, learn, and connect with your company.",
    },
    {
      title: "Analytics",
      link: "/employer/analytics",
      icon: (
        <div style={{ minWidth: "20px" }}>
          <FeedIcon color={"#079455"} />
        </div>
      ),
      text: "Capture student engagement data across touchpoints to understand their full journey.",
    },
    {
      title: "Job Posts",
      link: "/company/job-posts",
      icon: (
        <div style={{ minWidth: "20px" }}>
          <JobIcon color={"#383874"} />{" "}
        </div>
      ),
      text: "Post jobs that connect top talent with your company’s opportunities.",
    },
    {
      title: "Messenger",
      text: "Chat with students instantly to build connections and answer questions.",
      icon: (
        <div style={{ minWidth: "20px" }}>
          <ChatIcon />
        </div>
      ),
    },
  ];

  return (
    <div
      className={`${
        fullview ? `megaMenu  ${megaMenu ? "megamenuactive" : ""}` : ""
      } `}
      style={{ backdropFilter: fullview ? "blur(10px)" : "" }}
    >
      <div
        style={{
          background: fullview ? "#FFF" : "",
        }}
      >
        <div
          className={`${fullview ? "marginsleftrightCompany" : ""}`}
          onMouseLeave={() => {
            if (fullview) {
              setMegaMenu(false);
            }
          }}
        >
          <div className={`${fullview ? "center" : ""}`}>
            <div className={`${fullview ? "headerCompany" : ""}`}>
              <div
                className=" space"
                style={{
                  gap: "3.375rem",
                  padding: "1.5rem 0rem",
                  flexDirection: isNon ? "row" : "column",
                }}
              >
                <div style={{}}>
                  <p className="gray40012400">FEATURED</p>
                  <div
                    style={{
                      height: "1px",
                      background: "#EAECF0",
                      width: "100%",
                      margin: "0.5rem 0rem 1.25rem 0rem",
                    }}
                  />
                  <div style={{ maxWidth: "564px" }}>
                    <MegaIconText
                      icon={
                        <div style={{ minWidth: "20px" }}>
                          <ChallengeIcon />
                        </div>
                      }
                      title={"Challenges"}
                      text={
                        "Engage early talent in a new and exciting way by offering real-world challenges. Design interactive tasks and give potential candidates the opportunity to explore their future career at your company."
                      }
                      link={"/employer/solutions-challenges"}
                    />
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{ marginTop: "2.5rem", maxWidth: "100%" }}
                  >
                    <p className="gray40012400">MORE TOOLS</p>
                    <div
                      style={{
                        height: "1px",
                        background: "#EAECF0",
                        width: "100%",
                        margin: "0.5rem 0rem 1.25rem 0rem",
                      }}
                    />
                    <div
                      className="d-grid"
                      style={{
                        gap: "1rem",
                        gridTemplateColumns: `repeat(auto-fill, minmax(310px, 1fr))`,
                      }}
                    >
                      {icon.map((ico, index) => (
                        <div key={index}>
                          <MegaIconText
                            icon={ico.icon}
                            title={ico.title}
                            text={ico.text}
                            link={ico.link}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: isNon ? "250px" : "100%",
                    minWidth: isNon ? "250px" : "",
                    paddingBottom: isNon ? "" : "2rem",
                  }}
                >
                  <p className="gray40012400">REQUEST DEMO</p>
                  <div
                    style={{
                      height: "1px",
                      background: "#EAECF0",

                      margin: "0.5rem 0rem 1.25rem 0rem",
                    }}
                  />
                  <div
                    style={{
                      marginTop: "1.25rem",
                      borderRadius: "8px",
                      background: "#F9FAFB",
                      padding: "1rem",
                    }}
                  >
                    <p className="gray90014400">
                      Explore how our solutions help you{" "}
                      <span
                        className="gray90014400"
                        style={{ fontWeight: 500 }}
                      >
                        engage
                      </span>{" "}
                      and{" "}
                      <span
                        className="gray90014400"
                        style={{ fontWeight: 500 }}
                      >
                        attract
                      </span>{" "}
                      early talent.
                    </p>

                    <img
                      className="img-fluid"
                      src={rightimg}
                      alt="Mega Menu"
                      style={{
                        borderRadius: "6px",
                        marginTop: "0.5rem",
                        maxHeight: fullview ? "" : "300px",
                        width: fullview ? "" : "100%",
                        objectFit: "cover",
                      }}
                    />
                    <a href="/" style={{ textDecoration: "none" }}>
                      <p className="pink14" style={{ marginTop: "0.75rem" }}>
                        Let's talk
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MegaMenu;
