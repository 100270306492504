import Body from "../../components/Role/Body";
import Header from "../../components/Role/component/Header";

import "./role.css";
const Role = () => {
  return (
    <>
      <div className="wrapper">
        <div
          className="createcompany d-flex "
          style={{
            position: "relative",
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="marginsleftrightCompany">
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <Header />
            </div>
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <div className="headerCompany mt-5 pt-5">
                <Body />
              </div>
            </div>
          </div>
          <div className="marginsleftrightCompany">
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <div className="headerCompany MarginTopFooter">
                {/* <Footer /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Role;
