import "./tab.css";
import { useNavigate } from "react-router-dom";

const TabButtons = ({ buttons, setSelected, selected, link = null }) => {
  const navigate = useNavigate();
  return (
    <div className="newTabOuter">
      {buttons.map((btn, index) => (
        <button
          key={index}
          className={`newTabButton ${selected === btn.name && "enabled"}`}
          onClick={() => {
            setSelected(btn.name);
            if (link) {
              navigate(`${link}?page=${index}`);
            }
          }}
        >
          {btn.name}
        </button>
      ))}
    </div>
  );
};
export default TabButtons;
