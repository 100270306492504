import Header from "../../components/Company/field/Header";
import Center from "../../components/Company/sent/center";
import PrimaryFooter from "../../layouts/components/Footer/PrimaryFooter";

const EmailPage = () => {
  return (
    <div className="backgroundimg">
      <div className="marginsleftrightCompany">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <Header />
          </div>
        </div>
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <Center />
          </div>
        </div>
      </div>
      <div className="marginsleftrightCompany mb-5">
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <div className="headerCompany">
            <PrimaryFooter />
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailPage;
