import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setId } from "../../state";
const LoginLinkedin = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    // Function to extract code from URL
    const getCodeFromURL = async () => {
      // Get the current URL
      const urlParams = new URLSearchParams(window.location.search);
      // Extract the 'code' parameter
      const code = urlParams.get("code");
      console.log(code);
      if (!code) {
        navigate("/employer/login");
      }
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/linkedin/redirect/login`,
          {
            code: code,
          }
        )
        .then((res) => {
          if (res.data?.Err) {
            navigate("/comapany/login");
          } else {
            axios
              .post(
                `${process.env.REACT_APP_BACKEND_URL}/organisation/login/linkedin`,
                {
                  data: res.data,
                }
              )
              .then((res) => {
                if (res.data.msg === "success") {
                  dispatch(setId({ uniqueId: res.data.user.uniqueId }));
                  navigate("/dashboard");
                } else {
                  toast.error("Not registered,Please Register instead");
                  navigate("/employer/login");
                }
              })
              .catch((err) => {
                console.log(err);
                navigate("/employer/login");
              });
          }
        })
        .catch((err) => {
          navigate("/employer/login");
          console.log(err);
        });
    };

    getCodeFromURL();
  }, []);

  return (
    <div
      className="d-flex"
      style={{
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="loader"></div>
    </div>
  );
};

export default LoginLinkedin;
