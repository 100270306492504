import React from "react";
const AddAward = ({
  newAward,
  setNewAward,

  handleConfirm,
  toast,
}) => {
  const fileInputRef = React.createRef();

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      setNewAward((prev) => {
        return {
          ...prev,
          logo: file,
        };
      });
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div>
      <p className="black14 mt-4">Institute Name:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newAward?.companyName}
        placeholder="Enter Institute Name"
        onChange={(e) => {
          setNewAward((prev) => {
            return {
              ...prev,
              companyName: e.target.value,
            };
          });
        }}
      />

      <p className="black14 mt-4">Institute Logo(optional):</p>
      <div className="mt-3 border p-4 rounded-3">
        {newAward?.logo && (
          <div className="center">
            <img
              src={
                newAward?.logo instanceof File
                  ? URL.createObjectURL(newAward?.logo)
                  : newAward?.logo
              }
              alt="selectefFile"
              className="rounded-2"
              style={{ maxHeight: "200px", maxWidth: "200px" }}
            />
          </div>
        )}

        <div style={{ gap: "5px", justifyContent: "center" }}>
          <p
            className="text-center clicktoupload"
            onClick={openFileInput}
            style={{ cursor: "pointer" }}
          >
            {newAward?.logo ? "Click to change Image" : "Click to upload Image"}
          </p>
          <p className="svgpngtext">SVG, PNG, JPG or GIF (max. 12MB)</p>
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
      </div>
      <p className="black14 mt-4">Award Title</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newAward?.title}
        placeholder="Enter Award title"
        onChange={(e) => {
          setNewAward((prev) => {
            return {
              ...prev,
              title: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Issued Date:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newAward?.issue}
        type="date"
        placeholder="Enter Issued Date"
        onChange={(e) => {
          setNewAward((prev) => {
            return {
              ...prev,
              issue: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Award Position:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newAward?.package}
        type="text"
        placeholder="Provide info about level of Achievement"
        onChange={(e) => {
          setNewAward((prev) => {
            return {
              ...prev,
              package: e.target.value,
            };
          });
        }}
      />
      <p className="black14 mt-4">Description:</p>
      <input
        className="col-md-12 col-12 forminput mt-3"
        value={newAward?.description}
        type="text"
        placeholder="Explain the achievement"
        onChange={(e) => {
          setNewAward((prev) => {
            return {
              ...prev,
              description: e.target.value,
            };
          });
        }}
        maxLength={200}
      />
      <button
        className="continuebutton mt-4 mb-5"
        onClick={() => handleConfirm()}
      >
        Confirm
      </button>
    </div>
  );
};

export default AddAward;
