import EventIcon from "../../../assets/images/icons/EventIcon";

const First = ({ isNon530 }) => {
  return (
    <div className="marginsleftrightCompany">
      <div className="center">
        <div className="headerCompany" style={{ padding: "5rem 0rem" }}>
          <div className="d-flex">
            <div
              className="newdashboardsidechips"
              style={{ background: "rgba(249, 250, 251, 0.30)" }}
            >
              <EventIcon color={"#FAA24B"} />
              Engage
            </div>
          </div>
          <div style={{ marginTop: "1.875rem" }}>
            <h3
              className="mainheadingcompany text-start"
              style={{ maxWidth: "872px" }}
            >
              Elevate the candidate journey with{" "}
              <span className="gradientvirtual">live events</span>
            </h3>
            <p
              className="gray95016500"
              style={{
                margin: "1.5rem 0rem",
                fontWeight: 400,
                maxWidth: "670px",
              }}
            >
              Engage early talent at key stages of their journey through
              interactive, real-time events that allow them gain valuable
              insights into potential career paths.
            </p>
            <div className="d-flex flex-wrap" style={{ gap: "1.25rem" }}>
              <a href="/" style={{ textDecoration: "none" }}>
                <button className="continuebutton">Get a demo</button>
              </a>
              <a href="/" style={{ textDecoration: "none" }}>
                <button className="backbutton">
                  Start Onboarding {isNon530 && "(Free)"}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default First;
