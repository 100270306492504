import tick from "../../assets/images/Tick.svg";
const Completed = () => {
  return (
    <div className="center" style={{ alignItems: "center", height: "100&" }}>
      <div
        className="center"
        style={{ flexDirection: "column", alignItems: "center" }}
      >
        {" "}
        <img
          src={tick}
          alt="tick"
          height="72px"
          width="72px"
          className="shadowa "
          style={{ borderRadius: "125px" }}
        />
        <p className="registeration" style={{ marginTop: "2.5rem" }}>
          Challenge Completed
        </p>
        <p className="black14" style={{ marginTop: "0.75rem" }}>
          Continue to view results
        </p>
      </div>
    </div>
  );
};

export default Completed;
