import { useParams, useNavigate } from "react-router-dom";
import First from "./FirstSection";
import "./main.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import Second from "./Second";
import Third from "./Third";
import Forth from "./Fourth";
import { toast } from "react-toastify";
import Fifth from "./Fifth";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import uservip from "../../assets/images/uservip.png";
const NewProfile = () => {
  const { uniqueId } = useParams();
  const navigate = useNavigate();
  const id = useSelector((state) => state.uniqueId);
  const isNonMob1024 = useMediaQuery("(min-width:1024px)");
  const isNonMob768 = useMediaQuery("(min-width:768px)");
  const [company, setCompany] = useState();
  const [organizer, setOrganizer] = useState(null);
  const [followers, setFollowers] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [similar, setSimilar] = useState([]);
  const [jobposts, setJobposts] = useState([]);
  const [engaged, setEngaged] = useState(0);
  const analytics = "12 months";

  const processDateData = (dates, currentDay) => {
    const currentMonth = new Date().getMonth() + 1;
    const data = Array.from({ length: currentDay }, (_, index) => {
      const day = index + 1;

      const count = dates.filter(
        (date) =>
          new Date(date.date).getDate() === day &&
          new Date(date.date).getMonth() + 1 === currentMonth
      ).length;
      return count;
    });

    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/get/similar/companies`, {
            id: company?.uniqueId,
          })
          .then((res) => {
            if (res.data.msg === "success") {
              setSimilar(res.data.similarCompanies);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      } catch (err) {
        console.log(err.message);
      }
    };

    if (organizer?.company !== company?.uniqueId) {
      fetchData();
    }
  }, [company]);

  useEffect(() => {
    if (organizer?.company === company?.uniqueId) {
      if (challenges.length > 0 || jobposts.length > 0) {
        let totalClicks = 0;
        let totalImpressions = 0;
        let totalCount = 0;
        const ids = [];

        const today = new Date();
        const currentDay = today.getDate();
        const check = [...challenges, ...jobposts] || [];

        const impressionDates = check.reduce((allDates, challenge) => {
          return [...allDates, ...challenge.impressionDates];
        }, []);

        const clickDates = check.reduce((allDates, challenge) => {
          return [...allDates, ...challenge.clickDates];
        }, []);

        const data = processDateData(
          [...impressionDates, ...clickDates],
          currentDay
        );

        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(
          startDate.getDate() -
            (analytics === "7 days" ? 7 : analytics === "30 days" ? 30 : 365)
        );

        challenges.forEach((challenge) => {
          const filteredClicks = challenge.clickDates
            .filter((date) => new Date(date.date) >= startDate)
            .map((click) => click.userId)
            .filter(Boolean); // Filter out undefined values

          totalClicks += filteredClicks.length;
          ids.push(...filteredClicks);

          const filteredImpressions = challenge.impressionDates
            .filter((date) => new Date(date.date) >= startDate)
            .map((impression) => impression.userId)
            .filter(Boolean); // Filter out undefined values

          totalImpressions += filteredImpressions.length;

          const filteredCount = challenge.countDates
            .filter((date) => new Date(date.date) >= startDate)
            .map((count) => count.userId)
            .filter(Boolean); // Filter out undefined values

          totalCount += filteredCount.length;
          ids.push(...filteredCount);
        });

        jobposts.forEach((jobpost) => {
          const filteredClicks = jobpost.clickDates
            .filter((date) => new Date(date.date) >= startDate)
            .map((click) => click.userId)
            .filter(Boolean); // Filter out undefined values

          totalClicks += filteredClicks.length;

          const filteredImpressions = jobpost.impressionDates
            .filter((date) => new Date(date.date) >= startDate)
            .map((impression) => impression.userId)
            .filter(Boolean); // Filter out undefined values

          totalImpressions += filteredImpressions.length;
        });

        setEngaged(totalClicks + totalCount);
      } else {
        setEngaged(0);
      }
    }
  }, [challenges, jobposts, analytics, company, organizer]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setOrganizer(userInfoResponse.data.user);
        } else {
          return;
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo/link`,
          { id: uniqueId }
        );

        if (companyInfoResponse.data.message === "Logout") {
          navigate(-1);
          return;
        }

        setCompany(companyInfoResponse.data.user);
        setFollowers(companyInfoResponse.data.followers);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [uniqueId, company]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/get/company/jobs`,
        {
          uniqueId: company?.uniqueId,
        }
      );
      if (response.data.msg === "success") {
        setJobposts(response.data.challenges);
      } else if (response.data.msg === "Record not found") {
        console.log("");
      } else {
        toast.error("Error Fetching Job posts");
      }

      const responsea = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/get/company/challenges`,
        {
          uniqueId: company?.uniqueId,
        }
      );
      if (responsea.data.msg === "success") {
        setChallenges(responsea.data.challenges);
      } else if (responsea.data.msg === "Record not found") {
        console.log("No record");
      } else {
        toast.error("Error Fetching Challenges");
      }
    };

    if (company && company?.uniqueId) {
      fetchData();
    }
  }, [company]);

  const calculate = (value) => {
    if (value <= 0) return 0; // Handle edge cases

    let divisor = 10;
    while (divisor < value) {
      divisor *= 10;
    }

    return (value * 100) / divisor;
  };

  const fillPercentage = company?.followers?.length
    ? calculate(company.followers.length)
    : 0;

  const handleFollow = (userid, companyid) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/follow/company`, {
        userid,
        companyid,
      })
      .then((res) => {
        const { msg, company } = res.data;
        if (msg === "followed" || msg === "unfollowed") {
          toast.success(`Company has been ${msg}`);
          setSimilar((prev) => {
            return prev.map((c) => (c._id === company._id ? company : c));
          });
          if (company?.link === uniqueId) {
            setCompany(company);
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [checker, setChecker] = useState(false);

  useEffect(() => {
    if (organizer && company) {
      if (organizer?.company !== company?.uniqueId) {
        if (similar.length === 0) {
          setChecker(true);
        } else {
          setChecker(false);
        }
      }
    }
  }, [organizer, company, similar]);

  return (
    <div className="paddingmaincontent thatone">
      {company ? (
        <div
          className="space "
          style={{
            gap: "1rem",
            flexDirection: isNonMob1024 ? "row" : "column",
          }}
        >
          <div
            style={{
              width: isNonMob1024 ? (checker ? "100%" : "68.9473%") : "100%",
            }}
          >
            <First
              company={company}
              followers={followers}
              isNonMob768={isNonMob768}
              organizer={organizer}
              navigate={navigate}
              handleFollow={handleFollow}
            />
            <Second company={company} />
            {company?.gallery?.length > 0 && <Third company={company} />}
            {challenges && challenges.length > 0 && (
              <Forth
                company={company}
                challenges={challenges}
                navigate={navigate}
                color={company?.color ? company?.color : "#E31b54"}
                organizer={organizer}
              />
            )}
            {jobposts && jobposts.length > 0 && (
              <Fifth
                jobposts={jobposts}
                color={company?.color ? company?.color : "#E31b54"}
                navigate={navigate}
                company={company}
                organizer={organizer}
              />
            )}
          </div>
          {(!checker || organizer?.company === company?.uniqueId) && (
            <div style={{ width: isNonMob1024 ? "28.4211%" : "100%" }}>
              {organizer?.company === company?.uniqueId ? (
                <div className="selectedEvent-inner shadowa">
                  <p className="gray95016500" style={{ fontWeight: 600 }}>
                    Engagment Analytics
                  </p>
                  <p
                    style={{ marginTop: "1.5rem", fontSize: "14px" }}
                    className="gray60011400"
                  >
                    Here, you can track key metrics giving you valuable insights
                    into your brand's reach and the interest of potential
                    candidates.
                  </p>
                  <div style={{ marginTop: "3.25rem" }}>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "0.75rem" }}
                    >
                      <div
                        className="shadowa"
                        style={{
                          height: "46px",
                          width: "46px",
                          background: "#FFF",
                          borderRadius: "5px",
                          padding: "0.75rem",
                        }}
                      >
                        <RemoveRedEyeOutlinedIcon
                          style={{ color: "#E31b54" }}
                        />
                      </div>
                      <div style={{ width: "80%" }}>
                        <p
                          className="gray60011400"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          Followers
                        </p>
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "0.375rem" }}
                        >
                          <p
                            className="gray95020400"
                            style={{ fontWeight: 600 }}
                          >
                            {company?.followers?.length}
                          </p>
                          <div style={{ width: "100%" }}>
                            {company ? (
                              <div
                                className=""
                                style={{
                                  width: "90%",
                                  height: "4px",
                                  borderRadius: "100px",
                                  background:
                                    fillPercentage > 90
                                      ? `linear-gradient(to right, #5FDCB3 0%, #079455 ${fillPercentage}%, #DBDFF1 ${fillPercentage}%)`
                                      : `linear-gradient(to right, #8098F9 0%, #C01048 ${fillPercentage}%, #DBDFF1 ${fillPercentage}%)`,
                                }}
                              />
                            ) : (
                              <p>Loading...</p> // Display a loading indicator while waiting for the data
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "0.75rem", marginTop: "1.5rem" }}
                  >
                    <div
                      className="shadowa"
                      style={{
                        height: "46px",
                        width: "46px",
                        background: "#FFF",
                        borderRadius: "5px",
                        padding: "0.75rem",
                      }}
                    >
                      <img
                        src={uservip}
                        alt="uservip"
                        style={{ height: "24px", width: "24px" }}
                      />
                    </div>
                    <div style={{ width: "80%" }}>
                      <p
                        className="gray60011400"
                        style={{ fontSize: "14px", fontWeight: 500 }}
                      >
                        Engaged Talent
                      </p>
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "0.375rem" }}
                      >
                        <p className="gray95020400" style={{ fontWeight: 600 }}>
                          {engaged}
                        </p>
                        <div style={{ width: "100%" }}>
                          {company ? (
                            <div
                              className=""
                              style={{
                                width: "90%",
                                height: "4px",
                                borderRadius: "100px",
                                background:
                                  calculate(engaged) > 90
                                    ? `linear-gradient(to right, #5FDCB3 0%, #079455 ${calculate(
                                        engaged
                                      )}%, #DBDFF1 ${calculate(engaged)}%)`
                                    : `linear-gradient(to right, #8098F9 0%, #C01048 ${calculate(
                                        engaged
                                      )}%, #DBDFF1 ${calculate(engaged)}%)`,
                              }}
                            />
                          ) : (
                            <p>Loading...</p> // Display a loading indicator while waiting for the data
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="selectedEvent-inner shadowa">
                  <p className="gray95016500" style={{ fontWeight: 600 }}>
                    Similar Companies
                  </p>
                  {console.log(similar)}
                  {similar &&
                    similar?.map((use, index) => (
                      <div
                        key={index}
                        className="d-flex "
                        style={{
                          marginTop: "1.5rem",
                          gap: "0.75rem",
                          borderBottom:
                            index !== similar.length - 1 && "1px solid #EAECF0",
                          paddingBottom: "0.75rem",
                        }}
                      >
                        <div
                          className="center p-2 align-items-center rounded-2 shadowa"
                          style={{
                            background: "#FFF",
                            maxHeight: "50px",
                            maxWidth: "50px",
                            height: "35px",
                            width: "35px",
                          }}
                        >
                          <img
                            src={use?.img}
                            alt="img"
                            style={{
                              width: "30px",
                              height: "30px",
                              objectFit: "cover",
                              borderRadius: "3px",
                            }}
                          />
                        </div>
                        <div>
                          <p
                            className={`gray95014400 hoverred`}
                            style={{ fontWeight: 600, cursor: "pointer" }}
                            onClick={() => {
                              if (use?.link) {
                                navigate(`/dashboard/company/${use?.link}`);
                              }
                            }}
                          >
                            {use?.title}
                          </p>
                          {use?.tagline && (
                            <p
                              style={{ marginTop: "0.25rem" }}
                              className="gray50012500"
                            >
                              {use?.tagline}
                            </p>
                          )}
                          <p
                            style={{ marginTop: "6px", cursor: "pointer" }}
                            className="text-start navrounddivtextactive"
                            onClick={() => {
                              handleFollow(organizer?.uniqueId, use?.uniqueId);
                            }}
                          >
                            {use?.followers?.some(
                              (fol) => fol.userid === organizer?.uniqueId
                            )
                              ? "Unfollow"
                              : "Follow"}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <p className="black14">Loading </p>
      )}
    </div>
  );
};
export default NewProfile;
