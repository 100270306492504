import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EmptySpace from "../../assets/images/EmptyState.svg";
import { toast } from "react-toastify";
import { setEditJob } from "../../state";
import deleteicon from "../../assets/images/delete.png";
const Table = ({ challenges }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;

  const [challenge, setChallenge] = useState(
    [...challenges].sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (titleA < titleB) return -1;
      if (titleA > titleB) return 1;
      return 0;
    })
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const [currentEntries, setCurrentEntries] = useState(
    challenge.slice(indexOfFirstEntry, indexOfLastEntry)
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalEntries = challenge.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  useEffect(() => {
    setCurrentEntries(challenge.slice(indexOfFirstEntry, indexOfLastEntry));
  }, [currentPage, challenge, indexOfFirstEntry, indexOfLastEntry]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const popupref = useRef(null);
  const [popup, setPopup] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleSort = () => {
    setChallenge(challenge.reverse());
    setCurrentEntries(challenge.slice(indexOfFirstEntry, indexOfLastEntry));
  };

  const handleDeleteChallenge = async (id) => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/delete/job`, { id: id })
      .then((res) => {
        if (res.data.msg === "Job deleted successfully") {
          toast.success("Job post deleted successfully");
          window.location.reload();
        } else if (res.data.msg === "Job not found") {
          window.location.reload();
        } else {
          toast.error("Error Deleting Job post");
        }
      });
  };

  const [Index, setIndex] = useState(-1);
  const [showIcon, setShowIcon] = useState(false);

  const iconRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowIcon(false);
        setIndex(-1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderPageButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <button
          key={i}
          style={
            i === currentPage
              ? { backgroundColor: "#E31B54", color: "#FFF" }
              : { color: "#000", backgroundColor: "transparent" } // Adjust colors as needed
          }
          onClick={() => handlePageChange(i)}
          className={`btn normalbuttons ${i === currentPage && " rounded-3"}`}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setPopup(false);
        setSelected("");
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      {popup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 11,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
          }}
        >
          <div
            className="shadowa newPopUpContainer"
            ref={popupref}
            style={{
              maxWidth: "90vw",
              maxHeight: "80vh",

              maxWidth: "700px",
              backgroundColor: "",
              padding: "1.5rem",
            }}
          >
            <div className="d-flex " style={{ gap: "1rem" }}>
              <div className="circle-background">
                <img src={deleteicon} alt="delete" height="48px" width="48px" />
              </div>
              <div>
                <p className="gray90018500">Delete job post</p>
                <p className="gray70014400">
                  Are you sure you want to delete this job post?
                  <br /> This action cannot be undone.
                </p>
              </div>
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "2rem", gap: "0.75rem" }}
            >
              <button
                className="backbutton"
                onClick={() => {
                  setPopup(false);
                  setSelected("");
                }}
              >
                Cancel
              </button>
              <button
                className="continuebutton"
                onClick={() => {
                  let id = selected;
                  handleDeleteChallenge(id);
                  setPopup(false);
                  setSelected("");
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className="tablescroll"
        style={{ marginTop: "0.6rem", overflowX: "auto" }}
      >
        <table
          className="tableofchallenges"
          style={{ width: "100%", overflowX: "scroll" }}
        >
          <thead className="thead">
            <tr className="theadtr">
              <th className="theadth ">
                Job Title{" "}
                <div className="btn" onClick={() => handleSort()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21.8413 13.01L17.2013 8.36C16.8989 8.08169 16.4337 8.08169 16.1313 8.36L11.5213 13.01C11.3332 13.3001 11.3678 13.6812 11.6051 13.9327C11.8423 14.1842 12.2207 14.2409 12.5213 14.07L15.7413 10.85V19.75C15.7413 20.1642 16.0771 20.5 16.4913 20.5C16.9055 20.5 17.2413 20.1642 17.2413 19.75V10.56L20.7513 14.07C21.0441 14.3625 21.5185 14.3625 21.8113 14.07C22.1122 13.7855 22.1256 13.311 21.8413 13.01Z"
                      fill="#475467"
                    />
                    <path
                      d="M11.5213 9.22C11.2285 8.92755 10.7541 8.92755 10.4613 9.22L7.27129 12.44V3.75C7.27129 3.33579 6.93551 3 6.52129 3C6.10708 3 5.77129 3.33579 5.77129 3.75V12.75L2.26129 9.22C1.96578 8.94464 1.50528 8.95277 1.21967 9.23838C0.934059 9.52399 0.925934 9.98449 1.20129 10.28L5.84129 14.93C6.13753 15.2236 6.61505 15.2236 6.91129 14.93L11.5213 10.28C11.8137 9.98718 11.8137 9.51282 11.5213 9.22Z"
                      fill="#475467"
                    />
                  </svg>
                </div>
              </th>
              <th className="theadth"> Status</th>
              <th className="theadth ">Job Type / Contract</th>
              <th className="theadth ">Application Closing Date</th>
              <th className="theadth ">Applications</th>

              <th className="theadth text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="tbody">
            {currentEntries &&
              currentEntries.map((challenge, index) => (
                <tr key={challenge.id} className="tbodytr">
                  <td className="tbodytrtd hoverdarkred">
                    <p
                      className="hoverdarkred gray95016500"
                      style={{
                        cursor: "pointer",
                        fontWeight: 400,
                      }}
                      onClick={() => {
                        navigate(
                          `/dashboard/job/details/${challenge.uniqueId}`
                        );
                      }}
                    >
                      {challenge.title}
                    </p>
                  </td>

                  <td className="tbodytrtd ">
                    <div className="livediv ">
                      {challenge.draft ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                          >
                            <circle cx="4" cy="4" r="3" fill="#F97066" />
                          </svg>
                          Draft
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                          >
                            <circle cx="4" cy="4" r="3" fill="#17B26A" />
                          </svg>
                          Live
                        </>
                      )}
                    </div>
                  </td>
                  <td className="tbodytrtd ">
                    <div className="">
                      <div className="typediv  ">
                        {challenge.position ? challenge.position : "N/A"}
                      </div>
                    </div>
                  </td>
                  <td className="tbodytrtd ">
                    <div className="livediv">
                      {challenge.applicationCloseDate
                        ? challenge.applicationCloseDate
                        : "Rolling"}
                    </div>
                  </td>
                  <td className="textofthecount tbodytrtd ">
                    {challenge.count}
                  </td>
                  <td className="textofthecount tbodytrtd ">
                    <div className="center">
                      <div style={{ position: "absolute" }}>
                        {showIcon && Index === index && (
                          <div
                            style={{
                              position: "absolute",
                              background: "transparent",
                              zIndex: 10,

                              marginLeft: "-140px",
                            }}
                            ref={iconRef}
                          >
                            <div
                              className="d-flex shadowa"
                              style={{
                                flexDirection: "column",
                                background: "transparent",
                                borderRadius: "5px",
                              }}
                            >
                              <button
                                className="d-flex popuptext"
                                style={{
                                  padding: "11px 1rem",
                                  maxHeight: "2rem",
                                  borderRadius: "5px 5px 0px 0px",
                                  gap: "10px",
                                  background: "#FFF",
                                  border: "none",
                                  width: "160px",
                                  minHeight: "42px",
                                }}
                                onClick={() => {
                                  dispatch(setEditJob({ editJob: challenge }));
                                  navigate("/edit/job");
                                }}
                              >
                                Edit
                              </button>

                              <button
                                className="d-flex popuptext"
                                style={{
                                  padding: "11px 1rem",
                                  maxHeight: "2rem",
                                  borderRadius: "0px 0px 5px 5px",
                                  gap: "10px",
                                  background: "#FFF",
                                  border: "none",
                                  width: "160px",
                                  minHeight: "42px",
                                  borderTop: "1px solid #d9d9d9",
                                }}
                                onClick={() => {
                                  setSelected(challenge._id);
                                  setPopup(true);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      <MoreVertIcon
                        className="hoverred gray700"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIndex(index);
                          setShowIcon(true);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            {currentEntries.length > 0 && currentEntries.length < 5 && (
              <>
                {Array.from({ length: 5 - currentEntries.length }).map(
                  (_, index) => (
                    <tr
                      key={index}
                      className="tbodytr"
                      style={{ border: "none" }}
                    >
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                      <td className="tbodytrtd"></td>
                    </tr>
                  )
                )}
              </>
            )}
          </tbody>
        </table>
        {currentEntries.length === 0 && (
          <div
            className="center col-md-12 col-12"
            style={{
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "400px",
              background: "#FFF",
            }}
          >
            <img
              src={EmptySpace}
              alt="Emptyspace"
              height="225px"
              width="180px"
              className="img-fluid"
            />
            <p className="pink16">No Data yet</p>
            <p className="black14" style={{ fontWeight: "500" }}>
              <span className="black14">Please come back later.</span> The first
              students will engage with your company in no time!
            </p>
          </div>
        )}
      </div>
      <div
        className="d-flex"
        style={{ marginTop: "1rem", justifyContent: "end" }}
      >
        <div
          className="d-flex"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          {renderPageButtons()}
          {indexOfLastEntry < challenge.length && (
            <button
              onClick={handleNextPage}
              disabled={indexOfLastEntry >= challenge.length}
              className="backbutton"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default Table;
