import React, { useEffect, useState } from "react";

import { Country, City } from "country-state-city";
import TextArea from "../../components/Company/textarea";
import upload from "../../assets/images/icons/upload.png";
import { toast } from "react-toastify";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import { setId } from "../../state";
import { useDispatch } from "react-redux";
import ImageCropper from "../../components/Company/create/ImageCropper";
const Details = ({ user, company }) => {
  const [loading, setLoading] = useState(false);

  const isNonMobile = useMediaQuery("(min-width:768px)");
  const [firstName, setFirstName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [department, setDepartment] = useState("");
  const [phone, setPhone] = useState("");
  const [search, setSearch] = useState("");
  const [lastName, setLastName] = useState("");
  const [id, setUniqueId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedimage, setcroppedImage] = useState(null);
  const [isChangeImage, setChangeImage] = useState(false);
  const [tagline, setTagline] = useState("");

  const dispatch = useDispatch();

  const [country, setCountry] = useState({ Country: "", City: "" });
  const [citySearch, setCitySearch] = useState("");
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  const allCountries = Country.getAllCountries();

  useEffect(() => {
    setFirstName(user.firstName ?? "");
    setLastName(user.lastName ?? "");
    setcroppedImage(user.profilephoto ?? null);
    setSelectedFile(user.profilephoto ?? null);
    setTagline(user.tagline ?? "");
    setJobTitle(user.jobTitle ?? "");
    setPhone(user.phone ?? "");
    setCountry(user.country ?? { Country: "", City: "" });
    setUniqueId(user._id);
    setDepartment(user.department ?? "");
  }, [user]);

  const handleSaveChanges = () => {
    if (id) {
      const payload = {
        firstName,
        lastName,
        jobTitle,
        department,
        phone,
        country,
        selectedFile: croppedimage,
        tagline,
        id,
      };
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/edit/profile/user`, payload)
        .then((res) => {
          if (res.data.message === "Created") {
            setIds(res.data.savedUser.uniqueId);
            console.log(res.data.savedUser.uniqueId);
            toast.success("Changes saved successfully");

            window.location.reload();
            setLoading(false);
          } else if (res.data.msg === "NotFound") {
            toast.error("Profile Not Found");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.message);
          toast.error("Error updating details");
          setLoading(false);
        });
    }
  };

  const handleCountrySearch = (e) => {
    setSearch(e.target.value);
    const filteredCountries = allCountries.filter((country) =>
      country.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredLocations(filteredCountries);
  };

  const setIds = async (uniqueId) => {
    dispatch(setId({ uniqueId: uniqueId }));
  };

  const handleSelectCountry = (location) => {
    setCountry((prevCountry) => {
      const updatedCountry = prevCountry;
      updatedCountry.Country = location;
      updatedCountry.City = "";

      return updatedCountry;
    });
    setSearch("");
  };

  const handleSelectCity = (location) => {
    setCountry((prevCountry) => {
      const updatedCountry = prevCountry;
      updatedCountry.City = location;

      return updatedCountry;
    });
    setCitySearch("");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const img = new Image();
      img.onload = function () {
        if (img.width >= 300 && img.height >= 300) {
          setSelectedFile(file);
          setChangeImage(true);
        } else {
          toast.error("Image dimensions must be at least 300x300px.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return;
        }
      };
      img.src = URL.createObjectURL(file);
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileChange({ target: { files: event.dataTransfer.files } });
  };

  const handleCitySearch = (e) => {
    setCitySearch(e.target.value);
    const filtered = City.getCitiesOfCountry(country.Country.isoCode).filter(
      (country) =>
        country.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredCities(filtered);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const fileInputRef = React.createRef();

  return (
    <div style={{ marginTop: "1.25rem" }}>
      <div className=" d-flex">
        <div
          className="col-md-6 col-12"
          style={{
            maxWidth: isNonMobile ? "50vw" : "",
            width: isNonMobile ? "50%" : "100%",
          }}
        >
          <div className="row">
            <div>
              <p className="formtext">First Name *</p>

              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="forminput col-md-12 col-12 mt-2"
              />
            </div>
            <div>
              <p className="formtext " style={{ marginTop: "1rem" }}>
                Last Name *
              </p>

              <input
                value={lastName}
                placeholder=""
                onChange={(e) => setLastName(e.target.value)}
                className="forminput col-md-12 col-12 mt-2"
              />
            </div>
            <div>
              <p className="formtext" style={{ marginTop: "1rem" }}>
                Job Title
              </p>

              <input
                value={jobTitle}
                placeholder=""
                onChange={(e) => setJobTitle(e.target.value)}
                className="forminput col-md-12 col-12 mt-2"
              />
            </div>
            <div>
              <p className="formtext" style={{ marginTop: "1rem" }}>
                Department *
              </p>

              <input
                value={department}
                placeholder=""
                onChange={(e) => setDepartment(e.target.value)}
                className="forminput col-md-12 col-12 mt-2"
              />
            </div>
            <div>
              <p className="formtext" style={{ marginTop: "1rem" }}>
                Phone Number
              </p>

              <input
                value={phone}
                // type="text"
                placeholder=""
                onChange={(e) => {
                  const input = e.target.value;
                  const regex = /^[0-9\b]+$/; // Only allows numeric input or backspace (\b)
                  if (regex.test(input) || input === "") {
                    setPhone(e.target.value);
                  }
                }}
                className="forminput col-md-12 col-12 mt-2"
              />
            </div>
            <div>
              <p className="formtext " style={{ marginTop: "1rem" }}>
                Country *
              </p>
              <input
                value={search}
                placeholder=""
                onChange={(e) => handleCountrySearch(e)}
                className="forminput col-md-12 col-12 mt-2"
              />
              {country.Country?.name && (
                <div className="d-flex" style={{ marginTop: "0.75rem" }}>
                  <p
                    className="black14 p-2 d-flex gap-3 rounded-5 shadowa"
                    style={{ background: "#FFF" }}
                  >
                    <span> {country.Country?.flag}</span>
                    {country.Country.name}
                  </p>
                </div>
              )}
              {filteredLocations.length > 0 && search && (
                <div
                  className="rounded-2 d-flex flex-column tablescroll shadowa"
                  style={{
                    position: "absolute",
                    background: "#FFF",
                    gap: "1rem",
                    minWidth: "300px",
                    maxHeight: "200px",
                    padding: "1rem",
                    overflow: "auto",
                    zIndex: 3,
                    marginTop: "0.75rem",
                  }}
                >
                  {filteredLocations.map((location) => (
                    <p
                      className="d-flex gap-3 black14"
                      key={location.name}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSelectCountry(location)}
                    >
                      <span> {location.flag}</span>
                      {location.name}
                    </p>
                  ))}
                </div>
              )}
              {country.Country?.name !== "" && (
                <>
                  <p className="formtext " style={{ marginTop: "1rem" }}>
                    City *
                  </p>
                  <input
                    value={citySearch}
                    placeholder=""
                    onChange={(e) => handleCitySearch(e)}
                    className="forminput col-md-12 col-12 mt-2"
                  />
                  {country.City?.name && (
                    <div className="d-flex" style={{ marginTop: "0.75rem" }}>
                      <p
                        className="black14 p-2 d-flex gap-3 rounded-5 shadowa"
                        style={{ background: "#FFF" }}
                      >
                        {country.City.name}
                      </p>
                    </div>
                  )}
                  {filteredCities.length > 0 && citySearch && (
                    <div
                      className="rounded-2 d-flex flex-column tablescroll shadowa"
                      style={{
                        position: "absolute",
                        background: "#FFF",
                        gap: "1rem",
                        minWidth: "300px",
                        maxHeight: "200px",
                        padding: "1rem",
                        overflow: "auto",
                        zIndex: 3,
                        marginTop: "0.75rem",
                      }}
                    >
                      {filteredCities.map((location) => (
                        <p
                          className="d-flex gap-3 black14"
                          key={location.name}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSelectCity(location)}
                        >
                          <span> {location.flag}</span>
                          {location.name}
                        </p>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>

            <>
              <div>
                <hr style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }} />
                <p className="formtext">Profile Photo </p>
                <div
                  className="drop-area mt-2"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={isChangeImage ? null : openFileInput}
                >
                  {selectedFile && isChangeImage && (
                    <>
                      <ImageCropper
                        selectedFile={selectedFile}
                        setcroppedImage={setcroppedImage}
                        setChangeImage={setChangeImage}
                      />
                    </>
                  )}

                  {croppedimage && !isChangeImage && (
                    <img
                      src={croppedimage}
                      alt="croped image"
                      style={{ maxWidth: "150px", maxHeight: "150px" }}
                    />
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  {!selectedFile && (
                    <>
                      <img
                        src={upload}
                        alt="upload image"
                        style={{ maxHeight: "40px", maxWidth: "40px" }}
                      />
                      <div className="d-flex" style={{ gap: "5px" }}>
                        <p
                          style={{
                            color: "#E31B54",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Click to upload
                        </p>
                        <p
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          or drag and drop
                        </p>
                      </div>{" "}
                    </>
                  )}
                  {selectedFile && !isChangeImage && (
                    <p
                      className="pink16 hoverdarkred"
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Click to change
                    </p>
                  )}
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#475467",
                    }}
                  >
                    SVG, PNG, JPG, or GIF
                  </p>
                </div>
              </div>

              <>
                <div>
                  <p className="formtext" style={{ marginTop: "1rem" }}>
                    Tagline
                  </p>

                  <TextArea tagline={tagline} setTagline={setTagline} />
                </div>
                <div
                  className="d-flex mt-2"
                  style={{ justifyContent: "space-between" }}
                >
                  <p
                    className="col-md-10 "
                    style={{
                      color: "#667085",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Use your tagline to briefly describe what your role is. This
                    can be changed later
                  </p>

                  <p
                    className="col-md-2 text-end"
                    style={{
                      color: "#667085",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {tagline.length}/120
                  </p>
                </div>
                <hr style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }} />
                <div className="d-flex" style={{ justifyContent: "end" }}>
                  <button
                    className="continuebutton"
                    style={{ maxHeight: "40px" }}
                    onClick={() => handleSaveChanges()}
                    disabled={loading}
                  >
                    Save Changes
                  </button>
                </div>
              </>
            </>
          </div>
        </div>
        {isNonMobile && (
          <div
            className="d-flex "
            style={{ justifyContent: "end", width: "50vw" }}
          >
            <div
              className="companyCardtoDisplay shadowa"
              style={{ overflow: "hidden", display: "block" }}
            >
              <div
                className=" m-5 mt-4 mb-0 d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div className="profilephoto" style={{ justifyContent: "end" }}>
                  <svg
                    className=""
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    viewBox="0 0 116 116"
                    fill="none"
                  >
                    <rect
                      width="115.95"
                      height="115.95"
                      rx="57.975"
                      fill="#FFF5F6"
                    />
                    {croppedimage && (
                      <>
                        <image
                          href={croppedimage}
                          x="0"
                          y="0"
                          width="100%"
                          height="100%"
                          clipPath="url(#rounded-clip)"
                        />
                        <defs>
                          <clipPath id="rounded-clip">
                            <rect width="100%" height="100%" rx="57.975" />
                          </clipPath>
                        </defs>{" "}
                      </>
                    )}
                  </svg>
                </div>
              </div>
              <p className="profileName m-5 mt-3 mb-0">
                {firstName ? firstName : "John"} {lastName ? lastName : "Smith"}
              </p>
              {company && (
                <p className="m-5 mt-0 mb-0 CreateProfileCompanyName">
                  {company.title}
                </p>
              )}
              <p className="m-5 mb-0 mt-3 tagline" style={{ display: "block" }}>
                {tagline ? tagline : "Tagline"}
              </p>
              <div
                className="d-flex m-5 mt-3 mb-3"
                style={{ flexWrap: "wrap" }}
              >
                {country?.Country?.name && (
                  <div className={`tagscard  `}>
                    {" "}
                    {country?.Country && country?.Country?.flag}
                    <p className="itemstexts">{country?.Country?.name}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Details;
