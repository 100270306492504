import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import WriteComment from "./WriteComment";
import Replies from "./AllReplies";
import { toast } from "react-toastify";
import Warningsvg from "../../assets/images/Warning";

const Comment = ({
  comment,
  getTimeElapsed,
  student,
  token,
  post,
  setComments,
  REPLY = null,
  ID = null,
  setCommentsToShow,
  setId,
  setTotalComments,
  handleReportComment,
}) => {
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Comment, setComment] = useState(comment ? comment : null);

  const [showReplyWrite, setShowReplyWrite] = useState(false);
  const [popup, setPopup] = useState(false);
  useEffect(() => {
    if (comment) {
      setComment(comment);
    }
  }, [comment]);

  const handleCommentReply = async (cmttxt) => {
    if (cmttxt.trim().length > 0 && !loading) {
      setLoading(true);

      const data = {
        reply: cmttxt,
        commentId: Comment?.uniqueId,
        postId: post?._id,
        REPLY: REPLY,
        ID: ID?.uniqueId,
      };

      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/comment/reply`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.msg === "success") {
            const newComment = {
              text: cmttxt,
              user: {
                _id: student?._id,
                name: student?.firstName + " " + student?.LastName,
                profilePhoto: student?.profilePhoto,
                link: student?.link,
              },
              uniqueId: res?.data?.newReply?.uniqueId,
              time: res?.data?.newReply?.time,
              replies: [],
              likes: [],
            };
            if (REPLY) {
              setId((prev) => {
                return {
                  ...prev,
                  replies: [...prev.replies, newComment],
                };
              });
              // ID.replies.push(newComment);
            } else {
              Comment.replies.push(newComment);
            }

            setShowReplyWrite(false);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Comment.likes) {
      setLikes(Comment.likes);
    }
  }, [Comment]);

  useEffect(() => {
    if (Comment && student?._id) {
      likes?.includes(student?._id) ? setLiked(true) : setLiked(false);
    }
  }, [Comment, likes]);

  const HandleLikeComment = async () => {
    let newLikeStatus = !liked;
    setLiked(newLikeStatus);
    if (newLikeStatus) {
      setLikes((prevLikes) => [...prevLikes, student?._id]);
    } else {
      setLikes((prevLikes) =>
        prevLikes?.filter((like) => like !== student?._id)
      );
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/comment/like`,
        {
          postId: post._id,
          commentId: Comment?.uniqueId,
          newLikeStatus,
          REPLY: REPLY,
          ID: ID?.uniqueId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const popupref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [deleteloading, setDeleteLoading] = useState(false);
  const handleDeleteComment = async () => {
    if (!deleteloading) {
      setDeleteLoading(true);
      const data = {
        postId: post._id,
        commentId: Comment?.uniqueId,
        REPLY: REPLY,
        ID: ID?.uniqueId,
      };

      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/comment/delete`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.msg === "success") {
            toast.success("Deleted Successfully");
            if (REPLY) {
              setId((prev) => {
                return {
                  ...prev,
                  replies: prev.replies.filter(
                    (reply) => reply.uniqueId !== data.commentId
                  ), // Remove the reply by filtering
                };
              });
            } else {
              setComments((prev) => {
                return prev.filter(
                  (comment) => comment.uniqueId !== data.commentId
                );
              });
              setTotalComments((prev) => prev - 1);
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      setDeleteLoading(false);
      setPopup(false);
    }
  };

  const handleReport = () => {
    const data = {
      postId: post._id,
      commentId: Comment?.uniqueId,
      REPLY: REPLY,
      ID: ID?.uniqueId,
    };
    handleReportComment(data);
  };

  const [edit, setEdit] = useState(false);

  return (
    <div>
      {edit ? (
        <WriteComment
          post={post}
          user={student}
          token={localStorage.getItem("token")}
          setComments={setComments}
          setTotalComments={setTotalComments}
          setLoading={setLoading}
          loading={loading}
          Comment={Comment}
          Data={{
            postId: post._id,
            commentId: Comment?.uniqueId,
            REPLY: REPLY,
            ID: ID?.uniqueId,
          }}
          setEdit={setEdit}
        />
      ) : (
        <>
          <div className="d-flex" style={{ gap: "0.75rem" }}>
            <img
              src={Comment?.user?.profilePhoto}
              alt="profile"
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                objectFit: "contain", // Ensure the image doesn't distort
              }}
            />
            <div style={{ width: "100%" }}>
              <div className="commenttextwrapper" style={{ width: "100%" }}>
                <div className="space align-items-center">
                  <div
                    className="d-flex flex-wrap align-items-center"
                    style={{ gap: "0.75rem" }}
                  >
                    <a
                      href={`/student/user/${Comment?.user?.link}`}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <p
                        className="gray80014400 hoverdarkred"
                        style={{ fontWeight: 600, cursor: "pointer" }}
                      >
                        {Comment?.user?.name}
                      </p>
                    </a>
                    <p className="gray60011400" style={{}}>
                      {getTimeElapsed(Comment?.time)}
                    </p>
                    {Comment.edited && <p className="gray60011400">Edited</p>}
                  </div>
                  <div className="">
                    {post.owner !== student?._id &&
                    Comment.user._id !== student?._id ? (
                      <div
                        style={{ minWidth: "24px", cursor: "pointer" }}
                        onClick={() => {
                          handleReport();
                          setPopup(false);
                        }}
                        disabled={deleteloading}
                      >
                        <Warningsvg />
                      </div>
                    ) : (
                      <MoreVertIcon
                        className="hoverred"
                        style={{
                          cursor: "pointer",
                          height: "20px",
                          width: "20px",
                        }}
                        onClick={() => {
                          setPopup(true);
                        }}
                      />
                    )}
                    {popup && (
                      <div style={{ position: "relative" }}>
                        <div
                          className="shadowa"
                          style={{
                            position: "absolute",
                            right: "0",
                            minWidth: "180px",
                          }}
                          ref={popupref}
                        >
                          {" "}
                          {Comment?.user?._id === student?._id && (
                            <button
                              className="dropdowntopbutton"
                              onClick={() => {
                                setEdit(true);
                                setPopup(false);
                              }}
                              disabled={deleteloading}
                            >
                              Edit Comment
                            </button>
                          )}
                          {(Comment?.user?._id === student?._id ||
                            post.owner === student?._id) && (
                            <button
                              className="dropdowntopbutton"
                              onClick={() => {
                                handleDeleteComment();
                              }}
                              style={{ position: "relative" }}
                              disabled={deleteloading}
                            >
                              {deleteloading && (
                                <div
                                  className="d-flex justify-content-end align-items-center"
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 2,
                                    background: "rgba(255,255,255,0.2)",
                                    top: 0,
                                    left: 0,
                                  }}
                                >
                                  <div
                                    className="line-loader"
                                    style={{ maxWidth: "20%" }}
                                  ></div>
                                </div>
                              )}
                              Delete Comment
                            </button>
                          )}
                          {Comment?.user?._id !== student?._id && (
                            <button
                              className="dropdownbottombutton"
                              onClick={() => {
                                handleReport();
                                setPopup(false);
                              }}
                              disabled={deleteloading}
                            >
                              Report
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {Comment?.text && (
                  <p
                    className="gray60011400"
                    style={{ marginTop: "0.75rem", fontSize: "14px" }}
                  >
                    {Comment?.text}
                  </p>
                )}
              </div>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "0.75rem", gap: "0.5rem" }}
              >
                <div
                  className="d-flex  align-items-center"
                  style={{ gap: "0.5rem" }}
                >
                  <p
                    className="gray50012500 hoverred"
                    style={{ cursor: "pointer", color: liked ? "#E31b54" : "" }}
                    onClick={() => HandleLikeComment()}
                  >
                    {liked ? "Liked" : "Like"}
                  </p>
                  <div
                    className="d-flex gray50012500 align-items-center"
                    style={{ gap: "0.25rem" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill={liked ? "#E31b54" : "none"}
                    >
                      <path
                        d="M12.62 20.8101C12.28 20.9301 11.72 20.9301 11.38 20.8101C8.48 19.8201 2 15.6901 2 8.6901C2 5.6001 4.49 3.1001 7.56 3.1001C9.38 3.1001 10.99 3.9801 12 5.3401C13.01 3.9801 14.63 3.1001 16.44 3.1001C19.51 3.1001 22 5.6001 22 8.6901C22 15.6901 15.52 19.8201 12.62 20.8101Z"
                        stroke={liked ? "#E31b54" : "#475467"}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {likes ? likes.length : 0}
                  </div>
                </div>
                <div
                  style={{
                    height: "14px",
                    width: "1px",
                    background: "#667085",
                  }}
                />
                <p
                  className="gray50012500 hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowReplyWrite(!showReplyWrite)}
                >
                  Reply
                </p>
              </div>
              {showReplyWrite && !REPLY && (
                <div style={{ marginTop: "1.25rem" }}>
                  <WriteComment
                    post={post}
                    user={student}
                    token={token}
                    setComments={setComments}
                    comments={[]}
                    reply={true}
                    handleCommentReply={handleCommentReply}
                    name={Comment?.user?.name}
                    setLoading={setLoading}
                    loading={loading}
                  />
                </div>
              )}

              {Comment?.replies && Comment?.replies?.length > 0 && (
                <div style={{ marginTop: "1rem" }}>
                  <Replies
                    replies={Comment}
                    getTimeElapsed={getTimeElapsed}
                    student={student}
                    post={post}
                    token={token}
                    ID={Comment}
                    setId={setComment}
                    setCommentsToShow={setCommentsToShow}
                  />
                </div>
              )}
            </div>
          </div>
          {showReplyWrite && REPLY && (
            <div style={{ marginTop: "1.25rem" }}>
              <WriteComment
                post={post}
                user={student}
                token={token}
                setComments={setComments}
                comments={[]}
                reply={true}
                handleCommentReply={handleCommentReply}
                name={comment?.user?.name}
                setLoading={setLoading}
                loading={loading}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Comment;
