const StudentShortProfile = ({ student }) => {
  return (
    <div className="d-flex " style={{ gap: "0.75rem" }}>
      <a
        href={`/student/user/${student?.link}`}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <img
          src={student?.profilePhoto}
          alt="profile photo"
          style={{
            height: "48px",
            width: "48px",
            border: "1px solid #FFF",
            borderRadius: "50%",
          }}
        />
      </a>

      <div className="d-flex flex-column gap-2">
        <div className="d-flex">
          <a
            href={`/student/user/${student?.link}`}
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            <p className="gray95016500 hoverred" style={{ cursor: "pointer" }}>
              {student?.firstName} {student?.LastName}
            </p>
          </a>
        </div>
        <p
          className="gray60011400"
          style={{ fontSize: "14px", maxWidth: "300px" }}
        >
          {student?.uni?.name}
        </p>
      </div>
    </div>
  );
};

export default StudentShortProfile;
