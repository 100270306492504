import React, { useState } from "react";
import { toast } from "react-toastify";
import upload from "../../assets/images/icons/upload.png";

import ImageCropper from "../../components/Company/create/ImageCropper";

const Step8 = ({
  isNonMobile,
  setStep,
  HandleSendData,
  profilePhoto,
  setProfilePhoto,
  croppedimage,
  setcroppedImage,
  loading,
}) => {
  const handleNext = () => {
    if (!profilePhoto) {
      toast.error("Please upload profile image");
    } else {
      HandleSendData();
    }
  };
  const [isChangeImage, setChangeImage] = useState(false);
  const handleDrop = (event) => {
    event.preventDefault();
    handleFileChange({ target: { files: event.dataTransfer.files } });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const img = new Image();
      img.onload = function () {
        setProfilePhoto(file);
        setChangeImage(true);
      };
      img.src = URL.createObjectURL(file);
    } else {
      toast.error("Please select a valid image file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const fileInputRef = React.createRef();
  return (
    <div className={`${isNonMobile ? "m-4 p-4" : "m-2 p-2"} `}>
      <button
        className="btn d-flex"
        style={{ justifyContent: "center", alignItems: "center" }}
        onClick={() => {
          setStep(6);
        }}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M10.6669 14.3329C10.5342 14.3335 10.4068 14.2807 10.3135 14.1862L4.98021 8.85291C4.78524 8.65769 4.78524 8.34145 4.98021 8.14624L10.3135 2.81291C10.5106 2.62933 10.8176 2.63475 11.008 2.82516C11.1984 3.01557 11.2038 3.32257 11.0202 3.51957L6.04021 8.49957L11.0202 13.4796C11.2152 13.6748 11.2152 13.991 11.0202 14.1862C10.927 14.2807 10.7996 14.3335 10.6669 14.3329Z"
              fill="black"
            />
          </svg>
        </div>
        <p className="backbuttonstudent">Back</p>
      </button>
      <p className="mainheading">Upload Profile Photo</p>
      <div className="mt-4">
        <div>
          <div
            className="drop-area mt-2"
            onDrop={handleDrop}
            onClick={isChangeImage ? null : openFileInput}
            onDragOver={handleDragOver}
          >
            {profilePhoto && isChangeImage && (
              <>
                <ImageCropper
                  selectedFile={profilePhoto}
                  setcroppedImage={setcroppedImage}
                  setChangeImage={setChangeImage}
                />
              </>
            )}
            {croppedimage && !isChangeImage && (
              <img
                src={croppedimage}
                alt="croped image"
                style={{ maxWidth: "150px", maxHeight: "150px" }}
              />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            {!profilePhoto && (
              <>
                <img
                  src={upload}
                  alt="upload image"
                  style={{ maxHeight: "40px", maxWidth: "40px" }}
                />
                <div className="d-flex" style={{ gap: "5px" }}>
                  <p
                    style={{
                      color: "#E31B54",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Click to upload
                  </p>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    or drag and drop
                  </p>
                </div>{" "}
              </>
            )}
            {profilePhoto && (
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#E31B54",
                }}
              >
                Click to change
              </p>
            )}
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: "400",
                color: "#475467",
              }}
            >
              SVG, PNG, JPG, or GIF
            </p>
          </div>
        </div>
      </div>
      <button
        className="continuebutton mt-5"
        onClick={() => handleNext()}
        disabled={loading === true}
      >
        Next
      </button>
    </div>
  );
};
export default Step8;
