import { motion, useTransform, useScroll } from "framer-motion";
import React, { useRef } from "react";

import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
// Wrap the component with React.memo
const HorizontalScrollCarousel = React.memo(
  ({ isNonMobile1000, navigate, text, TRUE }) => {
    const targetRef = useRef();

    const { scrollYProgress } = useScroll({
      target: targetRef,
      offset: ["start end", "end start"], // Adjust the offsets to trigger the animation at the right time
    });
    const width = useTransform(scrollYProgress, [0, 0.4], ["82%", "100vw"]);
    const height = useTransform(
      scrollYProgress,
      [0, 0.3],
      [isNonMobile1000 ? "300px" : "600px", isNonMobile1000 ? "458px" : "600px"]
    );
    const borderRadius = useTransform(
      scrollYProgress,
      [0, 0.3],
      [isNonMobile1000 ? "10px" : "20px", "0px"]
    );

    return (
      <>
        <section
          ref={targetRef}
          style={{ height: "fit-content", position: "relative" }}
          className="center align-items-center"
        >
          <div style={{ position: "relative" }}>
            <motion.div
              className="d-flex contactusBackground"
              style={{
                width,
                height,
                borderRadius,
                paddingTop: isNonMobile1000 ? "3rem" : "",
                paddingBottom: isNonMobile1000 ? "3rem" : "",
              }}
            >
              <div className="marginsleftrightCompany">
                <div className="center">
                  <div className="d-flex headerCompany">
                    <div
                      className={`d-flex  space gap-5 w-100 ${
                        isNonMobile1000 ? "pt-5" : ""
                      }`}
                      style={{
                        padding: "1.25px",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <h2 className="filmo48400 text-center">
                          {text ? text : "Let’s get started"}
                        </h2>
                        <p
                          className="white20500 text-center"
                          style={{ marginTop: "1.5rem" }}
                        >
                          {TRUE
                            ? "Discover early talent with varyance."
                            : "We are happy to have you onboard!"}
                        </p>
                        <div
                          className="center"
                          style={{
                            width: "100%",
                            position: "relative",
                            marginTop: "3.75rem",
                          }}
                        >
                          <div
                            className="d-flex flex-wrap"
                            style={{ gap: "1.25rem" }}
                          >
                            <button
                              className="continuebutton border-0"
                              onClick={() => {
                                navigate("/career-center/contact");
                              }}
                            >
                              Get a demo
                            </button>
                            <button
                              className="backbutton"
                              onClick={() => {
                                navigate("/career-center/register");
                              }}
                            >
                              Start Onboarding{isNonMobile1000 && "(Free)"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </section>
      </>
    );
  }
);

const Fifth = ({ text, TRUE }) => {
  const isNonMobile1000 = useMediaQuery("(min-width: 1001px)");
  const navigate = useNavigate();
  return (
    <div
      className="d-flex"
      style={{
        justifyContent: "center",
      }}
    >
      <div className="headerCompany">
        <HorizontalScrollCarousel
          isNonMobile1000={isNonMobile1000}
          navigate={navigate}
          text={text}
          TRUE={TRUE}
        />
      </div>
    </div>
  );
};
export default Fifth;
