import { useEffect, useState } from "react";
import TabButtons from "../../../components/TabButtons/TabButtons";
import Details from "./Details";
import { useMediaQuery } from "@mui/material";
import Activity from "./Activity";
import axios from "axios";
import Members from "./Members";
const Information = ({
  group,
  isMember,
  student,
  setPosts,
  posts,
  members,
  setMembers,
}) => {
  const [selected, setSelected] = useState("Details");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchPosts = async () => {
    if (group?._id && (isMember(student?._id) || group?.type === "Public")) {
      if (!localStorage.getItem("token") || !hasMore) return;
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/posts`,
          { page, limit: 15, groupId: group?._id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.msg === "success") {
          const newPosts = response.data.posts;
          setPosts((prevPosts) => [...prevPosts, ...newPosts]);

          if (newPosts.length < 15) {
            setHasMore(false);
          }
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
        setHasMore(false);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (group?._id && (isMember(student?._id) || group?.type === "Public")) {
      fetchPosts();
    }
  }, [page]);

  const fetchMorePosts = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page number to fetch more posts
    }
  };

  const buttons = [
    { name: "Details" },
    {
      name: "Activity",
    },
    {
      name: "Members",
    },
  ];

  const isNon = useMediaQuery("(min-width:768px)");
  const isNon450 = useMediaQuery("(min-width:450px)");
  const isNon1024 = useMediaQuery("(min-width:1024px)");

  const [hasMoreMembers, setHasMoreMembers] = useState(true);
  const [pageMember, setPageMember] = useState(1);
  const [loadingMember, setLoadingMember] = useState(false);

  const fetchMembers = async () => {
    if (group?._id && (isMember(student?._id) || group?.type === "Public")) {
      if (!localStorage.getItem("token") || !hasMoreMembers) return;
      setLoadingMember(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/get/members`,
          { page: pageMember, limit: 15, groupId: group?._id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.msg === "success") {
          setMembers((prevPosts) => [...prevPosts, ...response.data.members]);
          if (response.data.members.length < 15) {
            setHasMoreMembers(false);
          }
        } else {
          setHasMoreMembers(false);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
        setHasMoreMembers(false);
      }
      setLoadingMember(false);
    }
  };

  const fetchMoreMembers = () => {
    if (!loadingMember && hasMoreMembers) {
      setPageMember((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (group?._id && (isMember(student?._id) || group?.type === "Public")) {
      fetchMembers();
    }
  }, [pageMember]);

  const [search, setSearch] = useState("");

  return (
    <div>
      {(group?.type === "Public" || isMember(student?._id)) && (
        <div className={`${isNon ? "d-flex" : ""}`}>
          <div className="lesswidth">
            <TabButtons
              buttons={buttons}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </div>
      )}
      <div style={{ marginTop: "1.5rem" }}>
        {selected === "Details" && <Details group={group} />}
        {selected === "Activity" &&
          (group.type === "Public" || isMember(student?._id)) && (
            <Activity
              group={group}
              student={student}
              isNon450={isNon450}
              isNon1024={isNon1024}
              isMember={isMember}
              setPosts={setPosts}
              posts={posts}
              hasMore={hasMore}
              fetchMorePosts={fetchMorePosts}
            />
          )}
        {selected === "Members" && (
          <Members
            members={members}
            group={group}
            loadingMember={loadingMember}
            hasMoreMembers={hasMoreMembers}
            fetchMoreMembers={fetchMoreMembers}
            search={search}
            setSearch={setSearch}
          />
        )}
      </div>
    </div>
  );
};

export default Information;
